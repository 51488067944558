import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  MarkTenderStatusAsFavoriteAsAdmin,
  MarkTenderStatusAsFavoriteAsAdminVariables,
} from './__generated__/MarkTenderStatusAsFavoriteAsAdmin';

export const MARK_TENDER_STATUS_AS_FAVORITE_AS_ADMIN = gql`
  mutation MarkTenderStatusAsFavoriteAsAdmin(
    $input: ChangeTenderStatusAsAdminInput!
  ) {
    markTenderStatusAsFavoriteAsAdmin(input: $input) {
      tender {
        id
      }
    }
  }
`;

export const useMarkTenderStatusAsFavoriteAsAdminMutation = (
  options?: MutationHookOptions<
    MarkTenderStatusAsFavoriteAsAdmin,
    MarkTenderStatusAsFavoriteAsAdminVariables
  >,
) =>
  useMutation<
    MarkTenderStatusAsFavoriteAsAdmin,
    MarkTenderStatusAsFavoriteAsAdminVariables
  >(MARK_TENDER_STATUS_AS_FAVORITE_AS_ADMIN, options);

export default MARK_TENDER_STATUS_AS_FAVORITE_AS_ADMIN;
