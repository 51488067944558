import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  AssignAdminToClient,
  AssignAdminToClientVariables,
} from './__generated__/AssignAdminToClient';

const ASSIGN_ADMIN_TO_CLIENT = gql`
  mutation AssignAdminToClient($input: [AssignAdminToClientInput!]!) {
    assignAdminToClient(input: $input) {
      id
      client {
        id
        email
      }
      admin {
        id
        email
      }
      region {
        id
        name
      }
    }
  }
`;

export const useAssignAdminToClientMutation = (
  options?: MutationHookOptions<
    AssignAdminToClient,
    AssignAdminToClientVariables
  >,
) =>
  useMutation<AssignAdminToClient, AssignAdminToClientVariables>(
    ASSIGN_ADMIN_TO_CLIENT,
    options,
  );

export default ASSIGN_ADMIN_TO_CLIENT;
