import React from 'react';
import { format } from 'date-fns-tz';
import Loading from 'src/components/Loading';
import Pill from 'src/components/Pill';
import ADMIN_TENDER_NOTIFICATIONS, {
  useAdminTenderNotificationsQuery,
} from 'src/graphql/queries/AdminTenderNotifications';
import { NotificationProcessor } from 'src/__generated__/globalTypes';

const PAGE_SIZE = 4;
const processorNamesMap: Record<NotificationProcessor, string> = {
  PUSH_NOTIFICATION: 'PUSH',
  SMS: 'SMS',
  EMAIL: 'EMAIL',
};

const NotificationsCard: React.FC<{ tenderId: string }> = ({ tenderId }) => {
  const { loading, data, fetchMore } = useAdminTenderNotificationsQuery({
    variables: {
      first: PAGE_SIZE,
      filters: {
        tenderIds: {
          contains: [tenderId],
        },
      },
    },
  });

  const handleLoadMore = () =>
    fetchMore({
      query: ADMIN_TENDER_NOTIFICATIONS,
      variables: {
        first: PAGE_SIZE,
        filters: {
          tenderIds: {
            contains: [tenderId],
          },
        },
        after: data?.adminTenderNotifications.pageInfo.endCursor,
      },
    });

  return (
    <div className="bg-background-surface mr-4 mb-4 mb-4 rounded px-4 py-6">
      <p className="text-preset-3 text-ink-dark mb-6 font-medium">Messages</p>
      <div>
        {data?.adminTenderNotifications.pageInfo.totalCount === 0 && (
          <p className="text-preset-6 text-ink-not-as-dark">
            No tender notifications found.
          </p>
        )}
        {data?.adminTenderNotifications.edges.length &&
          data?.adminTenderNotifications.edges.map(({ node }) => (
            <div
              key={node.id}
              className="text-preset-6P text-ink-not-as-dark border-support-line mb-4 border-b pb-4"
            >
              <div className="mb-2 flex items-center">
                <p className="mr-2">
                  {format(new Date(node.sentAt), 'MM/dd/yyyy, H:mm')}
                </p>
                <Pill className="bg-purple-100 py-0.5">
                  <span className="text-preset-7 text-purple-800">
                    {processorNamesMap[node.processor]}
                  </span>
                </Pill>
              </div>
              <p>
                {node.title && `${node.title} - `}
                {node.body}
              </p>
            </div>
          ))}
        {data?.adminTenderNotifications.pageInfo.hasNextPage && !loading && (
          <div className="mt-8 mb-2 cursor-pointer" onClick={handleLoadMore}>
            <p className="text-preset-6 text-primary pl-2 font-medium">
              Load more
            </p>
          </div>
        )}
        {loading && <Loading />}
      </div>
    </div>
  );
};

export default NotificationsCard;
