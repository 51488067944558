import { WatchQueryFetchPolicy } from '@apollo/client';
import { Transition } from '@headlessui/react';
import {
  addMinutes,
  differenceInMilliseconds,
  differenceInMinutes,
  differenceInSeconds,
  endOfDay,
  format,
  startOfDay,
} from 'date-fns';
import pluralize from 'pluralize';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import ChevronDown from 'src/components/Icons/ChevronDown';
import ChevronUp from 'src/components/Icons/ChevronUp';
import StatsCard from 'src/components/StatsCard';
import { StatsCardProps } from 'src/components/StatsCard/StatsCard';
import { AdminProfileContext } from 'src/contexts/AdminProfileContext';
import { useLazyDayOfStatsQuery } from 'src/graphql/queries/DayOfStats';
import {
  DayOfStats as IDayOfStas,
  DayOfStats_dayOfStats,
} from 'src/graphql/queries/__generated__/DayOfStats';

const DAY_OF_STATS_UPDATE_INTERVAL_MIN = 1; // expected minutes between each update (should be in sync with the BE task)
const DAY_OF_STATS_RETRY_INTERVAL_MIN = 0.5; // retry interval when the previous update was further back in time than expected
const DAY_OF_STATS_UPDATE_OFFSET_SECS = 3; // offset in seconds to avoid issues with backend and network latency
const DAY_OF_STATS_UPDATE_ELAPSED_INTERVAL_SECS = 5; // interval for updating the elapsed time since last update
const DAY_OF_STATS_SAFETY_OFFSET_MS = 300; // safe offset to compare now with the expected next update time (processing time is ~20ms on my machine)
const DAY_OF_STATS_DRIVING_DISTANCE_OFFSET_MIN = 20; // offset to consider a shift as "out of time" for the driving distance, from last update
const DAY_OF_STATS_UNCONFIRMED_1ST_RANGE_OFFSET_MIN = 30; // offset to consider a shift as "unconfirmed", from last update

interface DayOfStatsProps {
  onToggle?: (status: boolean) => void;
}

interface ElapsedTime {
  label: string;
  time: number;
}

const prepareDayOfStatData = (
  stats?: IDayOfStas,
): Record<string, StatsCardProps> | null => {
  if (!stats) {
    return null;
  }

  const { dayOfStats } = stats;
  const preparedStats: Record<string, StatsCardProps> = {};
  const currentURL = new URL(window.location.href);
  const baseFilterURL = currentURL.origin + currentURL.pathname;

  const statsData: Record<
    string,
    {
      filter?: () => void;
      group: string | null;
      label?: string;
      order: number;
      subtitle?: string;
      title: string;
    }
  > = {
    total: {
      filter: () => {
        const shiftStartDate = startOfDay(new Date()).toISOString();
        const shiftEndDate = endOfDay(new Date()).toISOString();

        window.open(
          `${baseFilterURL}?shiftStartDate=${shiftStartDate}&shiftEndDate=${shiftEndDate}`,
          '_blank',
        );
      },
      group: null,
      order: 1,
      title: 'Total Shifts Today',
    },
    remaining: {
      filter: () => {
        const shiftStartDate = new Date().toISOString();
        const shiftEndDate = endOfDay(new Date()).toISOString();

        window.open(
          `${baseFilterURL}?shiftStartDate=${shiftStartDate}&shiftEndDate=${shiftEndDate}&exactDates=true`,
          '_blank',
        );
      },
      group: null,
      order: 2,
      title: 'Remaining Shifts',
    },
    notStaffed: {
      filter: () => {
        const shiftStartDate = new Date().toISOString();
        const shiftEndDate = endOfDay(new Date()).toISOString();

        window.open(
          `${baseFilterURL}?shiftStartDate=${shiftStartDate}&shiftEndDate=${shiftEndDate}&status=notStaffed`,
          '_blank',
        );
      },
      group: null,
      order: 3,
      title: 'Not Staffed',
    },
    pending: {
      filter: () => {
        const shiftStartDate = new Date().toISOString();
        const shiftEndDate = endOfDay(new Date()).toISOString();

        window.open(
          `${baseFilterURL}?shiftStartDate=${shiftStartDate}&shiftEndDate=${shiftEndDate}&exactDates=true&applicantStatus=PENDING`,
          '_blank',
        );
      },
      group: null,
      order: 4,
      title: 'Pending',
    },
    unconfirmedLessThan30: {
      filter: () => {
        const shiftStartDate = new Date(dayOfStats.createdAt).toISOString();
        const shiftEndDate = addMinutes(
          new Date(dayOfStats.createdAt),
          DAY_OF_STATS_UNCONFIRMED_1ST_RANGE_OFFSET_MIN,
        ).toISOString();

        window.open(
          `${baseFilterURL}?shiftStartDate=${shiftStartDate}&shiftEndDate=${shiftEndDate}&exactDates=true&applicantStatus=PENDING`,
          '_blank',
        );
      },
      group: 'unconfirmed',
      label: '< 30 min',
      order: 5,
      title: 'Unconfirmed',
    },
    unconfirmedBetween30and60: {
      group: 'unconfirmed',
      label: '< 1h',
      order: 6,
      title: 'Unconfirmed',
    },
    unconfirmedBetween60and180: {
      group: 'unconfirmed',
      label: '< 3h',
      order: 7,
      title: 'Unconfirmed',
    },
    tendersOutOfTime: {
      filter: () => {
        const shiftStartDate = new Date(dayOfStats.createdAt).toISOString();
        const shiftEndDate = addMinutes(
          new Date(dayOfStats.createdAt),
          DAY_OF_STATS_DRIVING_DISTANCE_OFFSET_MIN,
        ).toISOString();

        window.open(
          `${baseFilterURL}?shiftStartDate=${shiftStartDate}&shiftEndDate=${shiftEndDate}&exactDates=true&hasTendersOutOfTime=true`,
          '_blank',
        );
      },
      group: null,
      order: 8,
      subtitle: 'Shifts starts < 2hrs',
      title: 'Driving distance',
    },
    unpublished: {
      filter: () => {
        const shiftStartDate = new Date().toISOString();
        const shiftEndDate = endOfDay(new Date()).toISOString();

        window.open(
          `${baseFilterURL}?shiftStartDate=${shiftStartDate}&shiftEndDate=${shiftEndDate}&isJobUnpublished=true`,
          '_blank',
        );
      },
      group: null,
      order: 9,
      title: 'Unpublished',
    },
  };

  for (const stat in statsData) {
    const delta = dayOfStats[stat as keyof DayOfStats_dayOfStats].delta;
    const group = statsData[stat as keyof typeof statsData].group;
    const type = delta === 0 ? 'none' : delta < 0 ? 'positive' : 'negative';

    if (!group) {
      preparedStats[stat] = {
        order: statsData[stat as keyof typeof statsData].order,
        stats: [
          {
            onClick: statsData[stat as keyof typeof statsData].filter,
            value: dayOfStats[stat as keyof DayOfStats_dayOfStats]?.value,
            delta: { value: Math.abs(delta).toString(), type },
          },
        ],
        subtitle: statsData[stat as keyof typeof statsData].subtitle,
        title: statsData[stat as keyof typeof statsData].title,
      };
    } else {
      if (!preparedStats[group]) {
        preparedStats[group] = {
          order: statsData[stat as keyof typeof statsData].order,
          stats: [],
          title: statsData[stat as keyof typeof statsData].title,
        };
      }

      preparedStats[group].stats.push({
        label: statsData[stat as keyof typeof statsData].label,
        onClick: statsData[stat as keyof typeof statsData].filter,
        value: dayOfStats[stat as keyof DayOfStats_dayOfStats]?.value,
      });
    }
  }

  return preparedStats;
};

const getElapsedTime = (since: string): ElapsedTime => {
  if (DAY_OF_STATS_UPDATE_INTERVAL_MIN > 1) {
    return {
      label: 'minutes',
      time: differenceInMinutes(new Date(), new Date(since)) || 0,
    };
  }

  return {
    label: 'seconds',
    time: differenceInSeconds(new Date(), new Date(since)) || 0,
  };
};

const DayOfStats: React.FC<DayOfStatsProps> = ({ onToggle }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const updateIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const updateElapsedTimelRef = useRef<NodeJS.Timeout | null>(null);
  const { profile } = useContext(AdminProfileContext);

  const [getDayOfStats, { data: dayOfStats, loading: loadingDayOfStats }] =
    useLazyDayOfStatsQuery({
      fetchPolicy: 'network-only' as WatchQueryFetchPolicy,
      nextFetchPolicy: 'network-only' as WatchQueryFetchPolicy,
    });

  const [timeSinceLastUpdate, setTimeSinceLastUpdate] = useState<ElapsedTime>();

  const updateElapsedTime = useCallback(
    (elapsedTime: ElapsedTime) => {
      setTimeSinceLastUpdate(elapsedTime);
    },
    [setTimeSinceLastUpdate],
  );

  const getNextUpdateExpectedOn = useCallback(() => {
    if (!dayOfStats) {
      return new Date();
    }

    return addMinutes(
      new Date(dayOfStats?.dayOfStats?.createdAt),
      DAY_OF_STATS_UPDATE_INTERVAL_MIN,
    );
  }, [dayOfStats]);

  const statsData = prepareDayOfStatData(dayOfStats);
  const sortedStatsData = Object.values(statsData || {}).sort(
    (a, b) => a.order - b.order,
  );

  useEffect(() => {
    // fetch initial data
    getDayOfStats();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // handle elapsed time updates (every 30 seconds)
    updateElapsedTime(getElapsedTime(dayOfStats?.dayOfStats?.createdAt));

    updateElapsedTimelRef.current = setInterval(() => {
      updateElapsedTime(getElapsedTime(dayOfStats?.dayOfStats?.createdAt));
    }, DAY_OF_STATS_UPDATE_ELAPSED_INTERVAL_SECS * 1000);

    return () => {
      clearInterval(updateElapsedTimelRef.current as NodeJS.Timeout);
    };
  }, [dayOfStats, updateElapsedTime]);

  useEffect(() => {
    if (!dayOfStats) {
      return;
    }

    const now = new Date();
    const offsetMS = DAY_OF_STATS_UPDATE_OFFSET_SECS * 1000;

    const remainingMS =
      differenceInMilliseconds(getNextUpdateExpectedOn(), now) + offsetMS;

    const nextUpdateIsInFuture =
      differenceInMilliseconds(getNextUpdateExpectedOn(), now) >
      DAY_OF_STATS_SAFETY_OFFSET_MS;

    // if the expected next update is in the future, schedule the update
    if (nextUpdateIsInFuture) {
      updateIntervalRef.current = setInterval(() => {
        getDayOfStats();

        clearInterval(updateIntervalRef.current as NodeJS.Timeout);
      }, remainingMS);
    }

    // retry in X minutes if the previous update was further back in time than expected.
    // this could indicate the BE task is not running or the network is down
    if (!nextUpdateIsInFuture) {
      updateIntervalRef.current = setInterval(() => {
        getDayOfStats();

        clearInterval(updateIntervalRef.current as NodeJS.Timeout);
      }, DAY_OF_STATS_RETRY_INTERVAL_MIN * 60 * 1000);
    }

    return () => clearInterval(updateIntervalRef.current as NodeJS.Timeout);
  }, [getDayOfStats, dayOfStats, getNextUpdateExpectedOn]);

  useEffect(() => {
    // handle changes in the admin profile preferences
    setIsExpanded(profile?.preferences?.showDayOfStats || false);
  }, [profile?.preferences?.showDayOfStats]);

  const handleToggle = useCallback(() => {
    setIsExpanded(!isExpanded);
    onToggle?.(!isExpanded);
  }, [isExpanded, onToggle]);

  return (
    <section
      className="border-support-line rounded border px-4 py-5"
      data-testid="day-of-stats"
      data-cy="day-of-stats"
    >
      <header
        className="flex justify-between"
        data-testid="day-of-stats-header"
        data-cy="day-of-stats-header"
      >
        <div className="flex items-center gap-2">
          <h2 className="text-preset-4 text-ink-dark font-bold">
            Day Of Summary
          </h2>

          {!!timeSinceLastUpdate?.time && (
            <p
              className="text-preset-6 text-ink-not-as-dark"
              data-testid="day-of-stats-elapsed"
              data-cy="day-of-stats-elapsed"
              title={`Next updated expected on ${format(
                new Date(getNextUpdateExpectedOn()),
                'MMM d, hh:mm:ssaaa (z)',
              )}`}
            >
              Last updated{' '}
              {pluralize(
                timeSinceLastUpdate.label,
                timeSinceLastUpdate.time,
                true,
              )}{' '}
              ago
            </p>
          )}
        </div>

        <div className="flex items-center gap-2">
          <span className="text-preset-6 text-ink-not-as-dark">
            {isExpanded ? 'Hide' : 'Show'} Summary
          </span>

          <button
            className="border-support-line-darker bg-support-line text-ink-not-as-dark text-preset-7 h-[22px] w-[22px] rounded border p-1 text-center"
            data-cy="day-of-stats-toggle"
            data-testid="day-of-stats-toggle"
            onClick={handleToggle}
          >
            {isExpanded ? (
              <ChevronUp className="h-3 w-3" />
            ) : (
              <ChevronDown className="h-3 w-3" />
            )}
          </button>
        </div>
      </header>

      <Transition
        show={isExpanded && !!dayOfStats}
        enter="transition ease-out duration-200 transform"
        enterFrom="opacity-0 translate-y-[-10%]"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-200 transform"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-[-10%]"
      >
        <div
          className="mt-3 grid grid-cols-7 gap-2"
          data-testid="day-of-stats-content"
          data-cy="day-of-stats-content"
        >
          {sortedStatsData.map((stat) => (
            <StatsCard
              group={stat.group}
              isLoading={loadingDayOfStats}
              key={stat.title}
              order={stat.order}
              stats={stat.stats}
              subtitle={stat.subtitle}
              title={stat.title}
            />
          ))}
        </div>
      </Transition>
    </section>
  );
};

export default DayOfStats;
