import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';

import ConfirmationDialog from 'src/components/ConfirmationDialog';
import Download from 'src/components/Icons/Download';
import Pencil from 'src/components/Icons/Pencil';
import Loading from 'src/components/Loading';
import Toggle from 'src/components/Toggle';
import { useHideJobAsAdminMutation } from 'src/graphql/mutations/HideFromOpenJobs';
import { usePublishJobAsAdminMutation } from 'src/graphql/mutations/PublishJob';
import { useSetJobVisibilityForClientMutation } from 'src/graphql/mutations/SetJobVisibilityForClients';
import { useShowAtOpenJobsMutation } from 'src/graphql/mutations/ShowFromOpenJobs';
import { useUnpublishJobAsAdminMutation } from 'src/graphql/mutations/UnpublishJob';
import { useUpdateJobBasicInfo } from 'src/graphql/mutations/UpdateJobBasicInfo';
import { useGetJobQuery } from 'src/graphql/queries/GetJob';
import { GetJob_job as Job } from 'src/graphql/queries/__generated__/GetJob';
import { calculateDateTimes } from 'src/utils/dates';
import { formatMoney } from 'src/utils/formatMoney';
import { calculateTip, calculateTotalRate } from 'src/utils/rates';
import { JobBasicInfoInput } from 'src/__generated__/globalTypes';
import { BookingDetailTabs } from './BookingDetailTabs';
import { RETOOL_MESSAGING_APP_URL } from '../../utils/constants';

const EditJobBasicInfoModal: React.FC<{
  open: boolean;
  jobName: string;
  jobDescription: string | null;
  jobId: string;
  onClose: () => void;
}> = ({ open, onClose, jobName, jobDescription, jobId }) => {
  const [updateJobBasicInfoMutation, { loading }] = useUpdateJobBasicInfo();
  const { handleSubmit, register, errors } = useForm<{
    name: string;
    description: string | null;
  }>({
    defaultValues: {
      name: jobName,
      description: jobDescription,
    },
    mode: 'all',
  });

  const submitForm = async (input: JobBasicInfoInput) => {
    await updateJobBasicInfoMutation({
      variables: {
        input,
        jobId,
      },
    });
    onClose();
  };

  return (
    <ConfirmationDialog
      title="Edit Job Details"
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit(submitForm)}
      submitText={loading ? 'Saving...' : 'Save'}
    >
      <div className="w-96">
        <label
          htmlFor="name"
          className="text-preset-6 text-ink-dark mb-4 font-medium"
        >
          Job Name
        </label>
        <input
          name="name"
          type="text"
          ref={register({ required: true })}
          className={`bg-background-app focus:ring-primary focus:border-primary block h-12 w-full rounded px-4 ${
            errors.name
              ? 'ring-status-destructive-light border-status-destructive'
              : ''
          }`}
          placeholder="Enter job name"
        />
        <label
          htmlFor="description"
          className="text-preset-6 text-ink-dark mt-10 mb-4 font-medium"
        >
          Description
        </label>
        <textarea
          name="description"
          ref={register}
          className="bg-background-app focus:ring-primary focus:border-primary block h-64 w-full rounded px-4"
          placeholder="Enter job description"
        />
      </div>
    </ConfirmationDialog>
  );
};

const BookingDetailHeader: React.FC<{ job: Job }> = ({ job }) => {
  const history = useHistory();
  const [editJobDetailsModalOpen, setEditJobDetailsModalOpen] = useState(false);
  const [publishJobMutation] = usePublishJobAsAdminMutation({
    variables: { jobId: job.id },
  });
  const [unPublishJobMutation] = useUnpublishJobAsAdminMutation({
    variables: { jobId: job.id },
  });
  const [showAtOpenJobsMutation] = useShowAtOpenJobsMutation({
    variables: { jobId: job.id },
  });
  const [hideFromOpenJobsMutation] = useHideJobAsAdminMutation({
    variables: { jobId: job.id },
  });
  const [setJobVisibilityForClientMutation] =
    useSetJobVisibilityForClientMutation();

  const isCancelled = job.status === 'CANCELLED';

  const handlePublishJob = useCallback(
    async (published: boolean): Promise<void> => {
      if (published) {
        await unPublishJobMutation();
      } else {
        await publishJobMutation();
      }
    },
    [publishJobMutation, unPublishJobMutation],
  );

  const handleShowJob = useCallback(
    async (hiddenFromOpenJobs: boolean): Promise<void> => {
      if (hiddenFromOpenJobs) {
        await showAtOpenJobsMutation();
      } else {
        await hideFromOpenJobsMutation();
      }
    },
    [showAtOpenJobsMutation, hideFromOpenJobsMutation],
  );

  const handleArchivedStatus = useCallback(
    async (value: boolean): Promise<void> => {
      await setJobVisibilityForClientMutation({
        variables: {
          jobId: job.id,
          isVisible: value,
        },
      });
    },
    [setJobVisibilityForClientMutation, job],
  );

  const handleArchive = useCallback(async () => {
    await handleArchivedStatus(job.hiddenFromClient);
  }, [handleArchivedStatus, job]);

  const jobDateString = calculateDateTimes(
    job.startDateTime,
    job.endDateTime,
    job.venue?.address.timezone,
  );

  return (
    <div className="bg-background-surface border-support-line mx-autox mx-auto mb-6 max-w-screen-xl flex-col flex-wrap items-stretch justify-between border px-4 py-5 md:flex">
      <EditJobBasicInfoModal
        open={editJobDetailsModalOpen}
        jobName={job.name}
        jobDescription={job.description}
        jobId={job.id}
        onClose={() => setEditJobDetailsModalOpen(false)}
      />

      <div className="flex">
        <span
          className="text-preset-3 text-ink-link mr-1 cursor-pointer"
          onClick={() => {
            history.push('/bookings');
          }}
        >
          Bookings
        </span>
        <p className="text-preset-3 text-ink-dark font-bold"> / {job.name}</p>
        <div
          onClick={() => setEditJobDetailsModalOpen(true)}
          className="text-primary ml-4 flex cursor-pointer items-center"
        >
          <Pencil className="h-4" />
          <p className="preset-5">Edit Name</p>
        </div>

        {isCancelled && (
          <span className="bg-status-destructive text-preset-6 ml-6 rounded-full px-2  py-1 font-bold text-white">
            Canceled by Client
          </span>
        )}
      </div>

      <div className="flex">
        <div className=" text-ink-dark text-preset-4 mt-4 p-1 ">
          {job.client?.email}
        </div>
      </div>
      <div className="bg-slate-200x mt-4 grow items-center justify-between md:flex">
        <div className="border-support-line flex w-min border py-3 px-4">
          <p className="text-ink-not-as-dark preset-5 w-max">{jobDateString}</p>
        </div>

        <div className="mt-4 flex md:mt-0">
          <Toggle
            id={`${job.id}-published`}
            label="Published"
            checked={job.published}
            onClick={async () => {
              await handlePublishJob(job.published);
            }}
            className="ml-6"
          />
          <Toggle
            id={`${job.id}-open-jobs`}
            label="Hide from open jobs"
            checked={job.hiddenFromOpenJobs}
            onClick={async () => {
              await handleShowJob(job.hiddenFromOpenJobs);
            }}
            className="ml-6"
          />
          <Toggle
            id={`${job.id}-client`}
            label={'Archived'}
            checked={job.hiddenFromClient}
            onClick={handleArchive}
            className="ml-6"
          />
        </div>
      </div>
    </div>
  );
};

const BookingDetailInfo: React.FC<{ job: Job }> = ({ job }) => {
  const flags = useFlags();
  let paymentSubtotal = 0;
  let paymentTotalTips = 0;
  const [open, setOpen] = useState(false);

  job.shifts?.forEach((shift) => {
    const unpaidBreakMinutes = shift.unpaidBreakMinutes || undefined;
    const quantity = shift.quantity || undefined;
    const tipAmount = shift.tip?.amount || undefined;
    const startDateTime = new Date(shift.startDateTime);
    const endDateTime = new Date(shift.endDateTime);

    paymentSubtotal += calculateTotalRate(
      shift.rate,
      startDateTime,
      endDateTime,
      unpaidBreakMinutes,
      quantity,
      0,
    );

    paymentTotalTips += calculateTip(
      startDateTime,
      endDateTime,
      unpaidBreakMinutes,
      quantity,
      tipAmount,
    );
  });

  const hasAttachments = job.attachments && job.attachments?.length > 0;

  return (
    <div className="bg-background-surface border-support-line h-max border px-4 py-5 md:mr-2 md:w-1/3">
      <EditJobBasicInfoModal
        open={open}
        jobName={job.name}
        jobDescription={job.description}
        jobId={job.id}
        onClose={() => setOpen(false)}
      />
      <div className="items-center justify-between md:flex">
        <p className="text-preset-3 text-ink-dark mb-6 font-medium">
          Job Details
        </p>
        <a
          className="text-primary text-preset-6 hover:bg-primary-light hover:text-brand-50 rounded px-4 py-1.5"
          href={`${RETOOL_MESSAGING_APP_URL}#jobId= ${job.id}`}
          target="_blank"
          rel="external noreferrer"
        >
          Message tenders in job
        </a>
      </div>
      <div className="border-support-lines border px-4 py-6">
        <div className="flex items-center justify-between">
          <p className="text-preset-4 text-ink-dark font-medium">
            Booking Description
          </p>
          <div>
            <div
              className="text-primary flex cursor-pointer items-center justify-between"
              onClick={() => {
                setOpen(true);
              }}
            >
              <Pencil className="h-4" />
              <p className="text-preset-5 font-medium">Edit Description</p>
            </div>
          </div>
        </div>
        {job.description ? (
          <p className="text-preset-5P text-ink-dark mt-8 whitespace-pre-line">
            {job.description}
          </p>
        ) : (
          <p className="text-preset-5P text-ink-not-as-dark mt-8">
            Add a description to the booking
          </p>
        )}
      </div>

      <div className="border-support-lines mt-6 border px-4 py-6">
        <p className="text-preset-4 text-ink-dark font-medium">Payment</p>
        <div className="mt-8 flex items-center justify-between">
          <p className="text-preset-5 text-ink-dark font-medium">Subtotal</p>
          <p className="text-preset-5 text-ink-dark">
            {formatMoney(paymentSubtotal)}
          </p>
        </div>
        <div className="mt-4 flex items-center justify-between">
          <p className="text-preset-5 text-ink-dark font-medium">Tips</p>
          <p className="text-preset-5 text-ink-dark">
            {formatMoney(paymentTotalTips)}
          </p>
        </div>
        <div className="border-support-lines mt-4 flex items-center justify-between border-t pt-4">
          <p className="text-preset-3 text-ink-dark font-medium">Total</p>
          <p className="text-preset-3 text-ink-dark">
            {formatMoney(paymentSubtotal + paymentTotalTips)}
          </p>
        </div>
      </div>

      <div className="border-support-lines mt-6 border px-4 py-6">
        <div className="flex items-center justify-between">
          <p className="text-preset-4 text-ink-dark font-medium">
            Flexpool Staffing Notes
          </p>
        </div>
        {job.staffingNotes ? (
          <p className="text-preset-5P text-ink-dark mt-8 whitespace-pre-line">
            {job.staffingNotes}
          </p>
        ) : (
          <p className="text-preset-5P text-ink-not-as-dark mt-8">
            No staffing notes
          </p>
        )}
      </div>

      {flags.jobAttachments && hasAttachments && (
        <div className="border-support-lines mt-6 border px-4 py-6">
          <p className="text-preset-4 text-ink-dark font-medium">Attachments</p>

          <ul className="mt-8 flex flex-col gap-4">
            {job.attachments?.map((attachment, index) => (
              <li key={index}>
                <a
                  href={attachment.url}
                  download
                  className="text-primary align-center flex gap-2"
                  title={`Download: ${attachment.fileName}`}
                >
                  <Download className="text-preset-5 w-4 shrink-0" />
                  <span className="block truncate">{attachment.fileName}</span>
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

const BookingDetailView: React.FC = () => {
  const { jobId } = useParams<{ jobId: string }>();

  const {
    data: jobData,
    loading,
    error,
  } = useGetJobQuery({
    variables: { id: jobId },
    fetchPolicy: 'cache-and-network',
  });

  if (loading) {
    return <Loading />;
  }

  if (!jobData) {
    return <p>Error, {error?.message}</p>;
  }

  const { job } = jobData;

  return (
    <div
      className="text-align-right bg-background-app min-h-full min-w-full object-center pt-5 pb-8 sm:px-6 lg:px-8"
      data-cy="view-booking-detail"
    >
      <BookingDetailHeader job={job} />

      <div className="mx-auto max-w-screen-xl justify-between md:flex">
        <BookingDetailInfo job={job} />
        <BookingDetailTabs job={job} />
      </div>
    </div>
  );
};

export default BookingDetailView;
