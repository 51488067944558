import { Switch } from '@headlessui/react';
import React, { useState } from 'react';
import TenderSideBarCard from 'src/components/TenderSideBarCard';
import { useCreateJWTForTenderImpersonationMutation } from 'src/graphql/mutations/CreateJWTForTenderImpersonation';
import { useToggleTenderImpersonatorRoleMutation } from 'src/graphql/mutations/ToggleTenderImpersonatorRole';
import ConfirmationDialog from './ConfirmationDialog';

const ImpersonateTenderCard: React.FC<{
  tenderId: string;
  fullName: string;
  isImpersonator: boolean;
}> = ({ tenderId, fullName, isImpersonator }) => {
  const [tokenSuccessOpen, setTokenSuccessOpen] = useState(false);
  const [markAsImpersonatorConfirmation, setMarkAsImpersonatorConfirmation] =
    useState(false);
  const [toggleTenderImpersonatorRole, { loading: loadingToggleRole }] =
    useToggleTenderImpersonatorRoleMutation({
      variables: { id: tenderId },
    });
  const [createJWTForTenderImpersonation, { loading: loadingToken }] =
    useCreateJWTForTenderImpersonationMutation({
      variables: { id: tenderId },
      onCompleted: () => {
        setTokenSuccessOpen(true);
      },
    });

  return (
    <TenderSideBarCard title="Impersonation">
      <ConfirmationDialog
        open={markAsImpersonatorConfirmation}
        title={`Are you sure you what to mark ${fullName} as an impersonator?`}
        cancelText="Close"
        submitText="Mark this tender as impersonator"
        onSubmit={() => {
          toggleTenderImpersonatorRole();
          setMarkAsImpersonatorConfirmation(false);
        }}
        onClose={() => {
          setMarkAsImpersonatorConfirmation(false);
        }}
      >
        This tender will not be able to see the list of jobs or any other screen
        on the mobile app other than the settings screen.
      </ConfirmationDialog>
      <ConfirmationDialog
        open={tokenSuccessOpen}
        title="Check your email inbox"
        cancelText="Close"
        onClose={() => setTokenSuccessOpen(false)}
      >
        The access token has been sent to your email.
      </ConfirmationDialog>
      <div
        className={`text-preset-6 text-ink-not-as-dark border-support-lines  ${
          !isImpersonator && 'mb-4 border-b pb-4'
        }`}
      >
        <p className="mb-4">
          Tender Impersonation allows you to access the account of any tender,
          using the tenders app. Useful for resolving support tickets, or
          debugging in general.
        </p>
        <div className="mb-4 flex w-full items-center">
          <span className="text-preset-6 text-ink-not-as-dark flex-1 font-bold">
            Mark this tender as impersonator
          </span>
          <Switch
            checked={isImpersonator}
            disabled={loadingToggleRole}
            onChange={() => {
              if (isImpersonator) {
                toggleTenderImpersonatorRole();
              } else {
                setMarkAsImpersonatorConfirmation(true);
              }
            }}
            className={`${
              isImpersonator ? 'bg-brand-50' : 'bg-background-app'
            } border-primary focus:ring-primary relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer items-center rounded-full border-2 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2`}
          >
            <span className="sr-only">Mark this tender as impersonator</span>
            <span
              aria-hidden="true"
              className={`${
                isImpersonator ? 'translate-x-5' : 'translate-x-0'
              } ring-primary pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white ring-2 transition duration-200 ease-in-out`}
            />
          </Switch>
        </div>
        <p>
          {isImpersonator ? (
            <>
              <span className="font-bold">This tender is an impersonator</span>,
              use the tenders app to access as this tender to be able to
              impersonate other tenders.
              <br />
              <br />
              If you want to impersonate this tender instead, click on the "Mark
              this tender as impersonator" toggle above to turn it off and be
              able to create an access token to impersonate them.
            </>
          ) : (
            <>
              <span className="font-bold">This tender can be impersonated</span>
              , Click the "Create access token" button below to generate an
              access token.
              <br />
              <br />
              If you rather mark this tender as an impersonator, click on the
              "Mark this tender as impersonator" toggle above.
            </>
          )}
        </p>
      </div>
      {!isImpersonator && (
        <form
          className="mt-4"
          onSubmit={(evt) => {
            evt.preventDefault();
            createJWTForTenderImpersonation();
          }}
        >
          <p className="text-preset-6 text-ink-not-as-dark mb-4">
            An access token is a string of characters that, once logged as an
            impersonator, can be copied and pasted into the tenders app, look
            for an input with a "Tender token" placeholder in it, once submitted
            the tenders app will reload, but now logged as this impersonated
            tender.
          </p>
          <button
            className="bg-primary text-ink-clear w-full rounded-md py-2 px-4"
            type="submit"
            disabled={loadingToggleRole || loadingToken}
          >
            Create access token
          </button>
        </form>
      )}
    </TenderSideBarCard>
  );
};

export default ImpersonateTenderCard;
