import React from 'react';

const QRIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={className}
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.00012207 1C0.00012207 0.447715 0.447837 0 1.00012 0H4.00012C4.55241 0 5.00012 0.447715 5.00012 1V4C5.00012 4.55228 4.55241 5 4.00012 5H1.00012C0.447837 5 0.00012207 4.55228 0.00012207 4V1ZM2.00012 3V2H3.00012V3H2.00012Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.00012207 10C0.00012207 9.44771 0.447837 9 1.00012 9H4.00012C4.55241 9 5.00012 9.44772 5.00012 10V13C5.00012 13.5523 4.55241 14 4.00012 14H1.00012C0.447837 14 0.00012207 13.5523 0.00012207 13V10ZM2.00012 12V11H3.00012V12H2.00012Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0001 0C9.44784 0 9.00012 0.447715 9.00012 1V4C9.00012 4.55228 9.44784 5 10.0001 5H13.0001C13.5524 5 14.0001 4.55228 14.0001 4V1C14.0001 0.447715 13.5524 0 13.0001 0H10.0001ZM11.0001 2V3H12.0001V2H11.0001Z"
      fill="currentColor"
    />
    <path
      d="M8.00012 1C8.00012 0.447715 7.55241 0 7.00012 0C6.44784 0 6.00012 0.447715 6.00012 1V2C6.00012 2.55228 6.44784 3 7.00012 3C7.55241 3 8.00012 2.55228 8.00012 2V1Z"
      fill="currentColor"
    />
    <path
      d="M7.00012 4C7.55241 4 8.00012 4.44772 8.00012 5V6H10.0001C10.5524 6 11.0001 6.44772 11.0001 7C11.0001 7.55228 10.5524 8 10.0001 8H7.00012C6.44784 8 6.00012 7.55228 6.00012 7V5C6.00012 4.44772 6.44784 4 7.00012 4Z"
      fill="currentColor"
    />
    <path
      d="M13.0001 6C12.4478 6 12.0001 6.44772 12.0001 7C12.0001 7.55228 12.4478 8 13.0001 8C13.5524 8 14.0001 7.55228 14.0001 7C14.0001 6.44772 13.5524 6 13.0001 6Z"
      fill="currentColor"
    />
    <path
      d="M6.00012 10C6.00012 9.44771 6.44784 9 7.00012 9H8.00012C8.55241 9 9.00012 9.44771 9.00012 10C9.00012 10.5523 8.55241 11 8.00012 11V13C8.00012 13.5523 7.55241 14 7.00012 14C6.44784 14 6.00012 13.5523 6.00012 13V10Z"
      fill="currentColor"
    />
    <path
      d="M4.00012 8C4.55241 8 5.00012 7.55228 5.00012 7C5.00012 6.44772 4.55241 6 4.00012 6H1.00012C0.447837 6 0.00012207 6.44771 0.00012207 7C0.00012207 7.55228 0.447837 8 1.00012 8H4.00012Z"
      fill="currentColor"
    />
    <path
      d="M14.0001 10C14.0001 10.5523 13.5524 11 13.0001 11H11.0001C10.4478 11 10.0001 10.5523 10.0001 10C10.0001 9.44771 10.4478 9 11.0001 9H13.0001C13.5524 9 14.0001 9.44772 14.0001 10Z"
      fill="currentColor"
    />
    <path
      d="M13.0001 14C13.5524 14 14.0001 13.5523 14.0001 13C14.0001 12.4477 13.5524 12 13.0001 12H10.0001C9.44784 12 9.00012 12.4477 9.00012 13C9.00012 13.5523 9.44784 14 10.0001 14H13.0001Z"
      fill="currentColor"
    />
  </svg>
);

export default QRIcon;
