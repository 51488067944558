import React, { useState } from 'react';
import CloseIcon from 'src/components/Icons/Close';
import Plus from 'src/components/Icons/Plus';
import ViewContainer from 'src/components/ViewContainer';
import RostersSelectionToolbar from './RostersSelectionToolbar';
import CreateRostersModal from './CreateRostersModal';
import RostersTable from './RostersTable';

const FIVE_SECONDS_IN_MS = 5000;

const Rosters: React.FC = () => {
  const [alertOpen, setAlertOpen] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [newRosterIds, setNewRosterIds] = useState<string[]>([]);
  const [createRostersModalOpen, setCreateRostersModalOpen] = useState(false);

  return (
    <>
      <RostersSelectionToolbar />
      <ViewContainer className="mt-6 mb-6">
        {alertOpen && (
          <div
            className="bg-ink-dark text-ink-clear absolute left-1/3 flex w-1/3 justify-between rounded px-4 py-3"
            role="alert"
          >
            <p className="text-preset-6">
              {`${newRosterIds.length} New Roster${
                newRosterIds.length > 1 ? 's' : ''
              } ha${newRosterIds.length === 1 ? 's' : 've'} been created.`}
            </p>
            <button
              onClick={() => {
                setAlertOpen(false);
              }}
            >
              <CloseIcon />
            </button>
          </div>
        )}
        <div className="bg-background-surface border-support-line border px-3 py-4 sm:rounded-lg">
          <h3 className="text-preset-3 text-ink-dark mt-1.5 inline-block font-normal">
            {totalCount} Created Rosters
          </h3>
          <button
            onClick={() => setCreateRostersModalOpen(true)}
            className="text-preset-5 text-ink-clear bg-brand-tend hover:bg-primary-active float-right inline-flex items-center rounded-md px-6 py-2 font-normal"
            data-cy="create-roster-button"
          >
            <Plus className="mr-2" />
            Create Roster
          </button>
        </div>
        <RostersTable
          setTotalCount={setTotalCount}
          newRosterIds={newRosterIds}
        />
        <CreateRostersModal
          open={createRostersModalOpen}
          onClose={() => setCreateRostersModalOpen(false)}
          onCreate={(rosterIds) => {
            setNewRosterIds(rosterIds);
            setAlertOpen(true);
            setTimeout(() => {
              setAlertOpen(false);
            }, FIVE_SECONDS_IN_MS);
          }}
        />
      </ViewContainer>
    </>
  );
};

export default Rosters;
