import React, { createContext, Dispatch, useReducer } from 'react';
import { StaffingListItem } from 'src/graphql/fragments/__generated__/StaffingListItem';
import { GetShifts_shifts_edges_node } from 'src/graphql/queries/__generated__/GetShifts';
export type StaffingId = StaffingListItem['id'];
export type ShiftId = GetShifts_shifts_edges_node['id'];
export type SelectedStaffingsSet = Set<StaffingId>;
type Action = {
  type: 'add' | 'remove' | 'clear';
  id?: StaffingId;
  shiftId?: GetShifts_shifts_edges_node['id'];
};
type SelectedStaffingsMap = Map<ShiftId, SelectedStaffingsSet>;

export const ApprovedStaffingSelectionContext = createContext<
  | {
      state: SelectedStaffingsMap;
      selectionCount?: number;
      dispatch: Dispatch<Action>;
    }
  | undefined
>(undefined);

const reducer = (state: SelectedStaffingsMap, action: Action) => {
  const shiftSet = state.get(action.shiftId ?? '');

  switch (action.type) {
    case 'add':
      if (!action.id || !action.shiftId) {
        throw new Error('shift and staffing ids are required for selecting');
      }

      if (shiftSet) {
        shiftSet.add(action.id);
      } else {
        state.set(action.shiftId, new Set([action.id]));
      }

      return new Map(state);
    case 'remove':
      if (!action.id || !action.shiftId) {
        throw new Error('shift and staffing ids are required for deselecting');
      }

      shiftSet?.delete(action.id);
      return new Map(state);
    case 'clear':
      return new Map<ShiftId, SelectedStaffingsSet>();
    default:
      throw new Error('Unknown action for selected staffings reducer');
  }
};

export const ApprovedStaffingSelectionContextProvider: React.FC = ({
  children,
}) => {
  const [selectedStaffings, dispatch] = useReducer(
    reducer,
    new Map<ShiftId, SelectedStaffingsSet>(),
  );
  const selectionCount = Array.from(selectedStaffings?.values() ?? []).reduce(
    (acc, set) => acc + set.size,
    0,
  );

  return (
    <ApprovedStaffingSelectionContext.Provider
      value={{ state: selectedStaffings, selectionCount, dispatch }}
    >
      {children}
    </ApprovedStaffingSelectionContext.Provider>
  );
};
