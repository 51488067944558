import React, { SyntheticEvent, useState } from 'react';

import Loading from 'src/components/Loading';
import TableHeaderCell from 'src/components/TableHeaderCell';
import Tab from 'src/components/Tab';
import Pagination from 'src/components/Pagination';
import Ban from 'src/components/Icons/Ban';
import Plus from 'src/components/Icons/Plus';
import Heart from 'src/components/Icons/Heart';
import ThumbDown from 'src/components/Icons/ThumbDown';
import { useGetClientTendersForAdminQuery } from 'src/graphql/queries/GetClientTenders';
import { ClientTenderStatus } from 'src/__generated__/globalTypes';
import ClientPreferenceModal from './ClientPreferenceModal';

const Cell: React.FC<{ className?: string }> = ({ children, className }) => (
  <td
    className={`text-preset-6 text-ink-dark bg-background-surface px-4 ${
      className || ''
    }`}
  >
    {children}
  </td>
);

type TabType = 'FAVORITED' | 'BLOCKED' | 'LESS_PREFERRED';

const ClientPreferenceCard: React.FC<{
  tenderId: string;
}> = ({ tenderId }) => {
  const [selectedTab, setSelectedTab] = useState<TabType>('FAVORITED');
  const [page, setPage] = useState<number>(1);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const { data: favoritedData, loading: favoritedLoading } =
    useGetClientTendersForAdminQuery({
      variables: {
        tenderId,
        status: ClientTenderStatus.FAVORITE,
        pagination: {
          page,
          limit: 5,
        },
      },
    });
  const { data: blockedData, loading: blockedLoading } =
    useGetClientTendersForAdminQuery({
      variables: {
        tenderId,
        status: ClientTenderStatus.BLOCKED,
        pagination: {
          page,
          limit: 5,
        },
      },
    });
  const { data: lessPreferredData, loading: lessPreferredLoading } =
    useGetClientTendersForAdminQuery({
      variables: {
        tenderId,
        status: ClientTenderStatus.LESS_PREFERRED,
        pagination: {
          page,
          limit: 5,
        },
      },
    });

  const totalFavorited =
    favoritedData?.tenderClientByOffset?.meta.totalItems ?? 0;
  const totalBlocked = blockedData?.tenderClientByOffset?.meta.totalItems ?? 0;
  const totalLessPreferred =
    lessPreferredData?.tenderClientByOffset?.meta.totalItems ?? 0;

  const getData = () => {
    switch (selectedTab) {
      case 'BLOCKED':
        return blockedData;
      case 'LESS_PREFERRED':
        return lessPreferredData;
      case 'FAVORITED':
      default:
        return favoritedData;
    }
  };
  const data = getData();
  const totalPages = Math.ceil(
    (data?.tenderClientByOffset.meta.totalItems ?? 0) / 5,
  );
  const loading = favoritedLoading || blockedLoading || lessPreferredLoading;
  const hasPreference = !!data?.tenderClientByOffset.meta.totalItems;

  const handleCloseModal = (e?: SyntheticEvent) => {
    if (e) {
      e.preventDefault();
    }
    setModalOpen(false);
  };

  return (
    <div className="bg-background-surface mr-4 mb-4 rounded">
      <div className="px-4 py-6">
        <div className="flex items-baseline justify-between">
          <p className="text-preset-3 text-ink-dark mb-4 font-medium">
            Client Preference
          </p>
          <button
            className="text-primary flex font-medium"
            onClick={() => setModalOpen(true)}
          >
            <Plus className="text-primary font-small mr-2 mt-1" /> Add &#47;
            Edit Preferences
          </button>
        </div>
        <ul className="flex flex-auto">
          <Tab
            className="flex-grow"
            onClick={() => {
              setPage(1);
              setSelectedTab('FAVORITED');
            }}
            selected={selectedTab === 'FAVORITED'}
            label={`Favorited (${totalFavorited})`}
            icon={<Heart className="w-4" />}
          />
          <Tab
            className="flex-grow"
            onClick={() => {
              setPage(1);
              setSelectedTab('LESS_PREFERRED');
            }}
            selected={selectedTab === 'LESS_PREFERRED'}
            label={`Less Preferred (${totalLessPreferred})`}
            icon={<ThumbDown className="w-4" />}
          />
          <Tab
            className="flex-grow"
            onClick={() => {
              setPage(1);
              setSelectedTab('BLOCKED');
            }}
            selected={selectedTab === 'BLOCKED'}
            label={`Blocked (${totalBlocked})`}
            icon={<Ban className="w-4" />}
          />
          <div className="border-support-lines flex-grow border-b-2" />
        </ul>
        {hasPreference ? (
          <table className="mt-4 min-w-full table-auto">
            <thead className="bg-background-app">
              <tr className="top-0 z-10 h-12">
                <TableHeaderCell className="bg-background-app">
                  Client Name
                </TableHeaderCell>
                <TableHeaderCell className="bg-background-app">
                  Client email
                </TableHeaderCell>
              </tr>
            </thead>
            <tbody className="bg-white">
              {data?.tenderClientByOffset.items.map((clientPreference) => {
                return (
                  <tr
                    key={clientPreference.id}
                    className="border-support-line h-14 border-b"
                  >
                    <Cell className="border-support-lines border-b">
                      <p className="text-preset-6 text-ink-dark">
                        {clientPreference.client.firstName}{' '}
                        {clientPreference.client.lastName}
                      </p>
                    </Cell>
                    <Cell className="border-support-lines border-b">
                      <p className="text-preset-6 text-ink-dark">
                        {clientPreference.client.email}
                      </p>
                    </Cell>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p className="text-preset-6 text-ink-not-as-dark flex pt-4">
            This tender hasn&apos;t been marked as "{selectedTab}" yet
          </p>
        )}
        {loading && (
          <div className="flex h-72 justify-center">
            <Loading />
          </div>
        )}
        {hasPreference && (
          <div className="flex justify-end py-4">
            <Pagination
              currentPage={page}
              pageCount={totalPages || 0}
              onPageChange={setPage}
            />
          </div>
        )}
        <ClientPreferenceModal
          modalOpen={modalOpen}
          handleCloseModal={handleCloseModal}
          tenderId={tenderId}
          page={page}
        />
      </div>
    </div>
  );
};

export default ClientPreferenceCard;
