import React, { Fragment, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { format } from 'date-fns-tz';

import ConfirmationDialog from 'src/components/ConfirmationDialog';
import Loading from 'src/components/Loading';
import Modal from 'src/components/Modal';
import DotsHorizontal from 'src/components/Icons/DotsHorizontal';
import TrashIcon from 'src/components/Icons/Trash';
import { useDeleteTenderNoteMutation } from 'src/graphql/mutations/DeleteTenderNote';
import { useTenderNotesQuery } from 'src/graphql/queries/GetTenderNotes';
import { useAddTenderNoteMutation } from 'src/graphql/mutations/AddTenderNote';

const NoteSubMenu: React.FC<{ id: string; refetch: () => void }> = ({
  id,
  refetch,
}) => {
  const [deleteNote] = useDeleteTenderNoteMutation({
    variables: {
      id,
    },
  });
  const [open, setOpen] = useState(false);
  return (
    <>
      <ConfirmationDialog
        open={open}
        title="Remove Note?"
        description="The note will be deleted and you won’t be able to recover it unless you type it again"
        submitText="Remove Note"
        submitClassName="text-status-destructive"
        onSubmit={async () => {
          await deleteNote();
          setOpen(false);
          await refetch();
        }}
        onClose={() => {
          setOpen(false);
        }}
      />
      <Menu as="div" className="relative">
        <Menu.Button
          className="text-ink-not-as-dark"
          data-cy="more-options-button"
        >
          <DotsHorizontal className="text-ink-primary w-4" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="bg-background-surface shadow-floating-menu text-ink-dark absolute right-0 mt-2 rounded py-2">
            <Menu.Item as={Fragment}>
              <button
                className="flex items-center whitespace-nowrap px-4 py-2"
                onClick={() => {
                  setOpen(true);
                }}
                data-cy="delete-tender-note-button"
              >
                <p className="text-status-destructive text-preset-6">
                  <TrashIcon className="mr-2 inline-block h-4 w-4" /> Remove
                  Note
                </p>
              </button>
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

const AddNewNoteModal: React.FC<{
  open: boolean;
  onClose: () => void;
  tenderId: string;
}> = ({ open, onClose, tenderId }) => {
  const [note, setNote] = useState('');
  const [addNote, { loading }] = useAddTenderNoteMutation();
  const onSubmit = async () => {
    await addNote({ variables: { tenderId, note } });
    onClose();
  };
  return (
    <Modal open={open} onClose={onClose} className="p-4">
      <p className="text-preset-3 text-ink-dark font-medium">Add Tender Note</p>
      <p className="text-preset-6 text-ink-dark mt-10 mb-4 font-medium">
        New Note
      </p>
      <textarea
        className="text-preset-6P active:border-ink-primary h-36 w-96 rounded"
        placeholder="Type your note here"
        onChange={(event) => setNote(event.currentTarget.value)}
        data-cy="tender-note-input"
      />
      <div className="border-ink-dark-200 -mx-4 mt-6 h-1 border-t border-solid" />
      <div className="text-ink-dark flex justify-end py-2">
        <button
          className="border-ink-dark rounded-md border py-2 px-4"
          onClick={onClose}
          data-cy="cancel-tender-note-button"
        >
          Cancel
        </button>
        <button
          className="bg-primary text-ink-clear ml-4 rounded-md py-2 px-4"
          onClick={onSubmit}
          disabled={loading}
          data-cy="submit-tender-note-button"
        >
          Add Note
        </button>
      </div>
    </Modal>
  );
};

const NotesCard: React.FC<{ tenderId: string }> = ({ tenderId }) => {
  const { data, loading, refetch } = useTenderNotesQuery({
    variables: {
      id: tenderId,
    },
  });
  const [open, setOpen] = useState(false);

  return (
    <div className="bg-background-surface mr-4 mb-4 rounded">
      <AddNewNoteModal
        open={open}
        onClose={async () => {
          setOpen(false);
          await refetch();
        }}
        tenderId={tenderId}
      />
      <div className="mb-4 px-4 py-6">
        <div className="flex justify-between">
          <p className="text-preset-3 text-ink-dark mb-4 font-medium">
            Tender Notes
          </p>
          <button className="h-0" onClick={() => setOpen(true)}>
            <p
              className="text-preset-5 text-ink-primary"
              data-cy="add-tender-note-button"
            >
              + Add Note
            </p>
          </button>
        </div>
        <div className="flex flex-col-reverse">
          {loading && <Loading />}
          {data?.tender?.notes.length === 0 && (
            <p className="text-preset-6 text-ink-not-as-dark">
              There are no tender notes so far
            </p>
          )}
          {data?.tender?.notes.map((tenderNote, index) => {
            if (!data.tender?.notes) {
              return null;
            }
            return (
              <div
                key={tenderNote.id}
                className={`border-support-lines flex justify-between ${
                  index !== 0 && 'mb-6 border-b'
                } `}
              >
                <div className="w-11/12">
                  <p className="text-preset-6 text-ink-not-as-dark mb-2">
                    {format(
                      new Date(tenderNote.createdAt),
                      'MM/dd/yyyy, hh:mm',
                    )}
                  </p>
                  <p className="text-preset-6P text-ink-not-as-dark mb-4">
                    {tenderNote.note}
                  </p>
                </div>
                <NoteSubMenu id={tenderNote.id} refetch={refetch} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default NotesCard;
