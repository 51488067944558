import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  MarkTenderStatusAsBlockedAsAdmin,
  MarkTenderStatusAsBlockedAsAdminVariables,
} from './__generated__/MarkTenderStatusAsBlockedAsAdmin';

export const MARK_TENDER_STATUS_AS_BLOCKED_AS_ADMIN = gql`
  mutation MarkTenderStatusAsBlockedAsAdmin(
    $input: ChangeTenderStatusAsAdminInput!
  ) {
    markTenderStatusAsBlockedAsAdmin(input: $input) {
      tender {
        id
      }
    }
  }
`;

export const useMarkTenderStatusAsBlockedAsAdminMutation = (
  options?: MutationHookOptions<
    MarkTenderStatusAsBlockedAsAdmin,
    MarkTenderStatusAsBlockedAsAdminVariables
  >,
) =>
  useMutation<
    MarkTenderStatusAsBlockedAsAdmin,
    MarkTenderStatusAsBlockedAsAdminVariables
  >(MARK_TENDER_STATUS_AS_BLOCKED_AS_ADMIN, options);

export default MARK_TENDER_STATUS_AS_BLOCKED_AS_ADMIN;
