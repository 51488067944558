import React, { FC, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { InvoicingStatus } from 'src/__generated__/globalTypes';
import ChevronDown from 'src/components/Icons/ChevronDown';

interface Props {
  status: InvoicingStatus | undefined;
  onChange: (status: InvoicingStatus | undefined) => void;
}

const getStatusLabel = (status?: InvoicingStatus) => {
  switch (status) {
    case InvoicingStatus.PENDING:
      return 'No Invoice';
    case InvoicingStatus.LIBERATED:
      return 'Liberated';
    default:
      return 'All Status';
  }
};

const dropdownOptions: (InvoicingStatus | undefined)[][] = [
  [undefined],
  [InvoicingStatus.PENDING, InvoicingStatus.LIBERATED],
];

const InvoicingStatusFilter: FC<Props> = ({ status, onChange }) => (
  <Menu as="div" className="relative inline-block text-left">
    <div>
      <Menu.Button className="border-support-line-darker text-preset-5 text-ink-dark inline-flex h-11 w-40 items-center justify-between rounded border bg-white px-4 font-medium shadow-sm hover:bg-gray-50">
        {getStatusLabel(status)}
        <ChevronDown className="-mr-1 ml-2 w-3" aria-hidden="true" />
      </Menu.Button>
    </div>

    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items className="divide-support-line absolute left-0 w-40 origin-top-left divide-y rounded-md bg-white shadow-lg focus:outline-none">
        {dropdownOptions.map((group, groupIndex) => (
          <div className="py-1" key={groupIndex}>
            {group.map((option, optionIndex) => (
              <Menu.Item key={`${groupIndex}-${optionIndex}`}>
                {({ active }) => (
                  <button
                    onClick={() => onChange(option)}
                    className={`text-ink-dark block w-full px-4 py-2 text-left text-sm ${
                      active ? 'bg-gray-100' : ''
                    }`}
                  >
                    {getStatusLabel(option)}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        ))}
      </Menu.Items>
    </Transition>
  </Menu>
);

export default InvoicingStatusFilter;
