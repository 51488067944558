import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import {
  GetRostersForAdmin,
  GetRostersForAdminVariables,
} from './__generated__/GetRostersForAdmin';

const GET_ROSTER_TENDERS = gql`
  query GetRostersForAdmin($after: String) {
    rosters(first: 10, after: $after) {
      edges {
        cursor
        node {
          __typename
          createdAt
          id
          job {
            id
            name
            tendersAmount
            endDateTime
            startDateTime
            shifts {
              quantity
              staffedSlots
            }
            venue {
              name
              address {
                neighborhood
                city
                state
                country
                timezone
              }
            }
          }
          client {
            id
            email
            firstName
            lastName
          }
        }
      }
      pageInfo {
        totalCount
        endCursor
        hasNextPage
      }
    }
  }
`;

export const useGetRostersForAdminQuery = (
  options?: QueryHookOptions<GetRostersForAdmin, GetRostersForAdminVariables>,
) =>
  useQuery<GetRostersForAdmin, GetRostersForAdminVariables>(
    GET_ROSTER_TENDERS,
    options,
  );

export default GET_ROSTER_TENDERS;
