import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  AdminUpdateTenderAddress,
  AdminUpdateTenderAddressVariables,
} from './__generated__/AdminUpdateTenderAddress';

const UPDATE_TENDER_ADDRESS = gql`
  mutation AdminUpdateTenderAddress($input: AddressInput!, $tenderId: ID!) {
    updateTenderAddress(input: $input, tenderId: $tenderId) {
      id
    }
  }
`;

export const useAdminUpdateTenderAddressMutation = (
  options?: MutationHookOptions<
    AdminUpdateTenderAddress,
    AdminUpdateTenderAddressVariables
  >,
) =>
  useMutation<AdminUpdateTenderAddress, AdminUpdateTenderAddressVariables>(
    UPDATE_TENDER_ADDRESS,
    options,
  );

export default UPDATE_TENDER_ADDRESS;
