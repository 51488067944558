import React from 'react';

const Search: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={className}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#search-clip)">
      <path
        d="M6.34041 8.68654C8.31867 7.8458 9.24082 5.56055 8.40008 3.58229C7.55933 1.60403 5.27408 0.681886 3.29582 1.52263C1.31756 2.36337 0.395422 4.64862 1.23616 6.62688C2.0769 8.60514 4.36215 9.52728 6.34041 8.68654Z"
        stroke="currentColor"
        strokeWidth="1.28832"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.56982 7.85645L10.5884 10.8754"
        stroke="currentColor"
        strokeWidth="1.28832"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="search-clip">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Search;
