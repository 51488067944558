import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  MarkTenderPayoutStatus,
  MarkTenderPayoutStatusVariables,
} from './__generated__/MarkTenderPayoutStatus';

const MARK_TENDER_PAYOUT_AS_SENT = gql`
  mutation MarkTenderPayoutAsSent($id: ID!) {
    markTenderPayoutAsSent(id: $id) {
      id
      status
    }
  }
`;

export const useMarkTenderPayoutAsSent = (
  options?: MutationHookOptions<
    MarkTenderPayoutStatus,
    MarkTenderPayoutStatusVariables
  >,
) =>
  useMutation<MarkTenderPayoutStatus, MarkTenderPayoutStatusVariables>(
    MARK_TENDER_PAYOUT_AS_SENT,
    options,
  );

export default MARK_TENDER_PAYOUT_AS_SENT;
