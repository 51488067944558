import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  ConfirmTenderCertification,
  ConfirmTenderCertificationVariables,
} from './__generated__/ConfirmTenderCertification';

const CONFIRM_TENDER_CERTIFICATION = gql`
  mutation ConfirmTenderCertification($id: ID!) {
    confirmTenderCertification(id: $id) {
      id
      type {
        name
      }
      tenderId
      issueDate
      expirationDate
      certificateNumber
      updatedAt
      createdAt
      status
    }
  }
`;

export const useConfirmTenderCertificationMutation = (
  options?: MutationHookOptions<
    ConfirmTenderCertification,
    ConfirmTenderCertificationVariables
  >,
) =>
  useMutation<ConfirmTenderCertification, ConfirmTenderCertificationVariables>(
    CONFIRM_TENDER_CERTIFICATION,
    options,
  );

export default CONFIRM_TENDER_CERTIFICATION;
