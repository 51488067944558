import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  UnpublishJobAsAdmin,
  UnpublishJobAsAdminVariables,
} from './__generated__/UnpublishJobAsAdmin';

const UNPUBLISH_JOB = gql`
  mutation UnpublishJobAsAdmin($jobId: ID!) {
    unpublishJob(jobId: $jobId) {
      __typename
      id
      published
    }
  }
`;

export const useUnpublishJobAsAdminMutation = (
  options?: MutationHookOptions<
    UnpublishJobAsAdmin,
    UnpublishJobAsAdminVariables
  >,
) =>
  useMutation<UnpublishJobAsAdmin, UnpublishJobAsAdminVariables>(
    UNPUBLISH_JOB,
    options,
  );

export default UNPUBLISH_JOB;
