import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import {
  GetTenderStaffingsForAdmin,
  GetTenderStaffingsForAdminVariables,
} from './__generated__/GetTenderStaffingsForAdmin';

const GET_TENDER_STAFFINGS = gql`
  query GetTenderStaffingsForAdmin(
    $pagination: PaginationOptions
    $filters: StaffingFilters
  ) {
    staffingsPaginatedByOffset(filters: $filters, pagination: $pagination) {
      meta {
        currentPage
        totalItems
        totalPages
      }
      items {
        id
        status
        tenderPayments {
          id
          displayDate
          type
          amount
          status
          sentAt
          description
          estimatedArrivalDate
        }
        staffedShift {
          id
          actualTenderPayout
          invoicingStatus
          paymentStatus
        }
        shift {
          startDateTime
          endDateTime
          position {
            name
          }
          workerClassification
          job {
            id
            name
            venue {
              name
              address {
                lat
                lng
              }
            }
          }
        }
      }
    }
  }
`;

export const useGetTenderStaffingsForAdminQuery = (
  options?: QueryHookOptions<
    GetTenderStaffingsForAdmin,
    GetTenderStaffingsForAdminVariables
  >,
) =>
  useQuery<GetTenderStaffingsForAdmin, GetTenderStaffingsForAdminVariables>(
    GET_TENDER_STAFFINGS,
    options,
  );

export default GET_TENDER_STAFFINGS;
