import { OperationVariables, SubscribeToMoreOptions } from '@apollo/client';
import gql from 'graphql-tag';
import { OnShiftUpdated } from 'src/views/Staffings/__generated__/OnShiftUpdated';
import { GetShifts } from '../queries/__generated__/GetShifts';

export const SHIFTS_SUBSCRIPTION = gql`
  subscription OnShiftUpdated {
    shiftUpdated {
      id
      applicantsQuantity
      quantity
      removedQuantity
      staffedSlots
      shiftRequestQuantity
      unreadNotes
      notes {
        id
        note
        updatedAt
        type
        creator {
          email
        }
      }
    }
  }
`;

export const subscribeToMoreShiftsOptions: SubscribeToMoreOptions<
  GetShifts,
  OperationVariables,
  OnShiftUpdated
> = {
  document: SHIFTS_SUBSCRIPTION,
  updateQuery: (prev, { subscriptionData }) => {
    if (!subscriptionData.data) {
      return prev;
    }
    const subscriptionPayload = subscriptionData.data as OnShiftUpdated;

    const newShiftEdges = prev.shifts.edges.map((edge) =>
      edge.node.id === subscriptionPayload.shiftUpdated.id
        ? {
            ...edge,
            node: { ...edge.node, ...subscriptionPayload.shiftUpdated },
          }
        : edge,
    );

    return {
      shifts: {
        ...prev.shifts,
        edges: newShiftEdges,
      },
    };
  },
};
