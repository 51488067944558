import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  CreateJWTForTenderImpersonation,
  CreateJWTForTenderImpersonationVariables,
} from './__generated__/CreateJWTForTenderImpersonation';

const CREATE_JWT_FOR_TENDER_IMPERSONATION = gql`
  mutation CreateJWTForTenderImpersonation($id: ID!) {
    createJWTForTenderImpersonation(tenderId: $id) {
      token
    }
  }
`;

export const useCreateJWTForTenderImpersonationMutation = (
  options?: MutationHookOptions<
    CreateJWTForTenderImpersonation,
    CreateJWTForTenderImpersonationVariables
  >,
) =>
  useMutation<
    CreateJWTForTenderImpersonation,
    CreateJWTForTenderImpersonationVariables
  >(CREATE_JWT_FOR_TENDER_IMPERSONATION, options);

export default CREATE_JWT_FOR_TENDER_IMPERSONATION;
