import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  AdminCreateTenderPosition,
  AdminCreateTenderPositionVariables,
} from './__generated__/AdminCreateTenderPosition';

const CREATE_TENDER_POSITIONS = gql`
  mutation AdminCreateTenderPosition($input: TenderPositionsInput!) {
    createTenderPositions(input: $input) {
      __typename
      id
      positions(onlyDefaultPositions: true) {
        __typename
        id
        name
      }
    }
  }
`;

export const useCreateTenderPositionMutation = (
  options?: MutationHookOptions<
    AdminCreateTenderPosition,
    AdminCreateTenderPositionVariables
  >,
) =>
  useMutation<AdminCreateTenderPosition, AdminCreateTenderPositionVariables>(
    CREATE_TENDER_POSITIONS,
    options,
  );

export default CREATE_TENDER_POSITIONS;
