import React, { FC } from 'react';

const TableCell: FC<{ className?: string }> = ({ children, className }) => (
  <td
    className={`text-preset-6 text-ink-dark bg-background-surface px-4
      ${className || ''}`}
  >
    <p className="truncate">{children}</p>
  </td>
);

export default TableCell;
