import React, { createContext, useCallback, useState } from 'react';

type PageTitleContextValue = {
  title: string;
  setTitle: (title: string) => void;
  clearTitle: () => void;
};

export const PageTitleContext = createContext<PageTitleContextValue>({
  title: 'Admin Panel',
  setTitle: () => {
    /* noop */
  },
  clearTitle: () => {
    /* noop */
  },
});

export const PageTitleContextProvider: React.FC = ({ children }) => {
  const [title, setTitle] = useState('Admin Panel');
  const clearTitle = useCallback(() => setTitle('Admin Panel'), []);

  return (
    <PageTitleContext.Provider
      value={{
        title,
        setTitle,
        clearTitle,
      }}
    >
      {children}
    </PageTitleContext.Provider>
  );
};
