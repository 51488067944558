import React from 'react';

interface BadgeWrapperProps {
  badge?: string | number | null;
  badgeClasses?: string;
}

const BadgeWrapper: React.FC<BadgeWrapperProps> = ({
  badge,
  children,
  badgeClasses,
  ...props
}) => {
  const hasBadge = badge !== undefined && badge !== null && badge !== '';

  return (
    <div className="relative" data-testid="badge-wrapper" {...props}>
      {children}
      {hasBadge && (
        <span
          className={`text-preset-7 absolute top-0 right-0 block -translate-y-1 translate-x-[6px] rounded-full bg-red-500 px-1 py-0.5 text-center font-semibold leading-none text-white drop-shadow ${badgeClasses}`}
          data-testid="badge-wrapper-badge"
        >
          {badge}
        </span>
      )}
    </div>
  );
};

export default BadgeWrapper;
