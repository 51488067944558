import React from 'react';

const HumanPrevious: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={className}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.9931 9.67436C19.9509 8.5022 19.678 7.34987 19.19 6.28328C18.7021 5.21669 18.0086 4.25676 17.1494 3.4584C16.2901 2.66004 15.2818 2.03892 14.1823 1.63055C13.0828 1.22219 11.9135 1.03461 10.7414 1.07852C8.9501 1.15631 7.2224 1.76528 5.77821 2.82795C4.33401 3.89062 3.23863 5.35893 2.63145 7.04602C2.61579 7.08863 2.58663 7.12496 2.54842 7.14948C2.51022 7.174 2.46504 7.18537 2.41978 7.18186L0.448114 7.03019C0.370511 7.02576 0.293163 7.0425 0.224327 7.0786C0.155492 7.11471 0.0977554 7.16883 0.057281 7.23519C0.0177476 7.30258 -0.00204344 7.37972 0.00016682 7.45782C0.00237708 7.53591 0.0264996 7.61181 0.0697811 7.67686L2.62645 11.5102C2.66205 11.5634 2.70944 11.6077 2.76494 11.6397C2.82043 11.6716 2.88254 11.6903 2.94645 11.6944H2.97395C3.03317 11.6942 3.09168 11.6814 3.14556 11.6568C3.19945 11.6322 3.24748 11.5965 3.28645 11.5519L6.26978 8.14269C6.32076 8.08449 6.35453 8.01326 6.3673 7.93695C6.38008 7.86065 6.37136 7.7823 6.34212 7.71067C6.31288 7.63904 6.26428 7.57697 6.20175 7.53141C6.13923 7.48584 6.06525 7.45859 5.98811 7.45269L4.60061 7.34936C4.56721 7.34675 4.53492 7.33612 4.50649 7.31838C4.47807 7.30063 4.45434 7.27629 4.43733 7.24742C4.42032 7.21855 4.41053 7.186 4.40878 7.15253C4.40703 7.11907 4.41337 7.08568 4.42728 7.05519C4.98692 5.81694 5.87927 4.75832 7.00496 3.99721C8.13065 3.23611 9.44551 2.80239 10.8031 2.74436C12.7223 2.68318 14.5876 3.38446 15.9912 4.69483C17.3948 6.00521 18.2223 7.81804 18.2929 9.73692C18.3635 11.6558 17.6714 13.5245 16.368 14.9345C15.0645 16.3445 13.2558 17.181 11.3373 17.261C11.1163 17.265 10.9059 17.3566 10.7524 17.5157C10.599 17.6748 10.515 17.8883 10.5189 18.1094C10.5229 18.3304 10.6145 18.5408 10.7736 18.6942C10.9327 18.8477 11.1463 18.9317 11.3673 18.9277H11.3989C12.5711 18.8854 13.7235 18.6123 14.79 18.1242C15.8566 17.6361 16.8165 16.9425 17.6147 16.083C18.4129 15.2236 19.0338 14.2151 19.442 13.1155C19.8501 12.0158 20.0374 10.8465 19.9931 9.67436Z"
      fill="currentColor"
    />
    <path
      d="M11.1501 10.3011C12.4157 10.3011 13.4417 9.27509 13.4417 8.00944C13.4417 6.74379 12.4157 5.71777 11.1501 5.71777C9.88441 5.71777 8.8584 6.74379 8.8584 8.00944C8.8584 9.27509 9.88441 10.3011 11.1501 10.3011Z"
      fill="currentColor"
    />
    <path
      d="M7.57258 13.7821C7.56276 13.8132 7.56044 13.8462 7.5658 13.8783C7.57116 13.9105 7.58405 13.9409 7.60341 13.9671C7.62286 13.993 7.64803 14.0139 7.67695 14.0284C7.70587 14.0429 7.73775 14.0504 7.77008 14.0505H14.5267C14.5593 14.0505 14.5914 14.0428 14.6205 14.0282C14.6495 14.0135 14.6748 13.9922 14.6941 13.9661C14.7135 13.9399 14.7265 13.9095 14.732 13.8775C14.7375 13.8454 14.7354 13.8124 14.7259 13.7813C14.4917 13.0151 14.0178 12.3443 13.3737 11.8677C12.7297 11.391 11.9496 11.1338 11.1484 11.1338C10.3472 11.1338 9.56715 11.391 8.92311 11.8677C8.27907 12.3443 7.80508 13.0151 7.57091 13.7813L7.57258 13.7821Z"
      fill="currentColor"
    />
  </svg>
);

export default HumanPrevious;
