import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { GetTender, GetTenderVariables } from './__generated__/GetTender';

const GET_TENDER = gql`
  query GetTender($id: ID!) {
    tender(id: $id) {
      __typename
      id
      avatarURL
      firstName
      lastName
      tags(onlyWhitelisted: true)
      email
      phoneNumber
      positions(onlyDefaultPositions: true) {
        id
        name
      }
      shiftsWorked
      lastWorkedShift {
        startDateTime
        endDateTime
        position {
          name
          id
        }
        job {
          id
          client {
            id
            firstName
            lastName
          }
        }
      }
      applicationsAfterLastShiftWorked
      shiftsFaultedCount
      shiftsCalledOutCount
      favoritedCount
      lessPreferredCount
      blockedCount
      unpaidShiftsCount
    }
  }
`;

export const useGetTenderQuery = (
  options?: QueryHookOptions<GetTender, GetTenderVariables>,
) => useQuery<GetTender, GetTenderVariables>(GET_TENDER, options);

export default GET_TENDER;
