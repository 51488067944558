import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  DeleteSuperRegion,
  DeleteSuperRegionVariables,
} from './__generated__/DeleteSuperRegion';

const REMOVE_STAFFING_REGION = gql`
  mutation DeleteSuperRegion($id: ID!) {
    deleteSuperRegion(id: $id) {
      id
      name
    }
  }
`;

export const useRemoveSuperRegionMutation = (
  options?: MutationHookOptions<DeleteSuperRegion, DeleteSuperRegionVariables>,
) =>
  useMutation<DeleteSuperRegion, DeleteSuperRegionVariables>(
    REMOVE_STAFFING_REGION,
    options,
  );

export default REMOVE_STAFFING_REGION;
