import { gql, QueryHookOptions, useLazyQuery } from '@apollo/client';
import { GetShift, GetShiftVariables } from './__generated__/GetShift';

const GET_SHIFT = gql`
  query GetShift($id: ID!, $workedByTenderId: ID) {
    shift(shiftId: $id, workedByTenderId: $workedByTenderId) {
      __typename
      id
      position {
        name
      }
      job {
        id
        name
        client {
          email
        }
      }
    }
  }
`;

export const useLazyGetShiftQuery = (
  options?: QueryHookOptions<GetShift, GetShiftVariables>,
) => useLazyQuery<GetShift, GetShiftVariables>(GET_SHIFT, options);

export default GET_SHIFT;
