import React, { FC, useEffect, useState } from 'react';
import { format, addDays } from 'date-fns';
import { RadioGroup } from '@headlessui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { Filter, NetDOption } from 'src/__generated__/globalTypes';
import Combobox from 'src/components/Combobox';
import ChevronDown from 'src/components/Icons/ChevronDown';
import Loading from 'src/components/Loading';
import Modal from 'src/components/Modal';
import { useCreateInvoiceMutation } from 'src/graphql/mutations/CreateInvoice';
import { useInvoiceTotalEstimationQuery } from 'src/graphql/queries/InvoiceTotalEstimation';
import { formatMoney } from 'src/utils/formatMoney';
import styles from './InvoiceCreate.module.css';

interface Props {
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
  totalItems?: number;
  staffingIds?: string[];
  notStaffingIds?: string[];
  staffingFilters?: Filter[];
}

type CreateInvoiceValues = {
  customId: string;
  adjustmentDescription?: string;
  adjustment?: number;
  clientName?: string;
  streetAddress?: string;
  state?: string;
  zipCode?: string;
  useAlternativeAddress?: boolean;
  netDOption: { id: NetDOption; label: string };
};

const createInvoiceSchema = Yup.object().shape({
  customId: Yup.string().required('This field is required'),
  adjustmentDescription: Yup.string().when('adjustment', {
    is: (val: number | string) =>
      typeof val === 'number' || !isNaN(parseFloat(val)),
    then: Yup.string().required(
      'You must enter a description or remove the amount.',
    ),
    otherwise: Yup.string(),
  }),
  adjustment: Yup.lazy((val) => (val === '' ? Yup.string() : Yup.number())),
  clientName: Yup.string().default(null),
  streetAddress: Yup.string(),
  state: Yup.string(),
  zipCode: Yup.string(),
  useAlternativeAddress: Yup.boolean(),
});

const netDIntervalMap = {
  [NetDOption.NET_7]: 7,
  [NetDOption.NET_10]: 10,
  [NetDOption.NET_15]: 15,
  [NetDOption.NET_30]: 30,
  [NetDOption.NET_45]: 45,
  [NetDOption.NET_60]: 60,
};

const CreateInvoiceModal: FC<Props> = ({
  open,
  onSubmit,
  onClose,
  totalItems,
  staffingIds,
  notStaffingIds,
  staffingFilters,
}) => {
  const [adjustment, setAdjustment] = useState<number>();
  const [
    getEstimation,
    {
      loading: loadingEstimation,
      data: dataEstimation,
      error: errorEstimation,
    },
  ] = useInvoiceTotalEstimationQuery();
  const [createInvoice, { loading, error }] = useCreateInvoiceMutation();

  const { register, errors, handleSubmit, control, watch } =
    useForm<CreateInvoiceValues>({
      mode: 'onBlur',
      resolver: yupResolver(createInvoiceSchema),
    });

  useEffect(() => {
    if (open) {
      getEstimation({
        variables: {
          staffingIds,
          notStaffingIds,
          staffingFilters,
        },
      });
    }
  }, [open, getEstimation, staffingIds, notStaffingIds, staffingFilters]);

  const onSubmitForm = async (values: CreateInvoiceValues) => {
    const result = await createInvoice({
      variables: {
        input: {
          customId: values.customId,
          adjustmentDescription: values.adjustmentDescription || undefined,
          adjustment: values.adjustment
            ? Math.round(values.adjustment * 100)
            : undefined,
          clientName: values.clientName || undefined,
          streetAddress: values.streetAddress || undefined,
          state: values.state || undefined,
          zipCode: values.zipCode || undefined,
          useAlternativeAddress: values.useAlternativeAddress,
          netDOption: values.netDOption.id,
          staffingIds,
          notStaffingIds,
          staffingFilters,
        },
      },
    });
    if (!result.errors) {
      onSubmit();
    }
  };

  const today = new Date();

  const totalText =
    typeof dataEstimation?.invoiceTotalEstimation === 'number'
      ? formatMoney(
          dataEstimation.invoiceTotalEstimation +
            (adjustment ? adjustment * 100 : 0),
        )
      : '';

  const items = (Object.keys(NetDOption) as Array<keyof typeof NetDOption>).map(
    (key) => ({
      label: key.replace('_', ' '),
      id: key,
    }),
  );
  items.sort((a, b) => {
    const aNumber = parseInt(a.label.split(' ')[1], 10);
    const bNumber = parseInt(b.label.split(' ')[1], 10);
    return aNumber > bNumber ? 1 : -1;
  });
  const defaultNetDOption = NetDOption.NET_30;
  const defaultNetDOptionItem = items.find(
    (item) => item.id === defaultNetDOption,
  );
  const netDOption = watch('netDOption');

  return (
    <Modal open={open} onClose={onClose}>
      <form
        onSubmit={handleSubmit(onSubmitForm)}
        className={`p-10 ${styles.createModal}`}
      >
        <h3 className="text-preset-4 mb-4">Create Invoice</h3>
        <p className="text-preset-5 text-ink-dark mb-6">
          Review the list of items and select billing address to create the PDF.
        </p>
        <p className="text-preset-4 mb-2.5 font-medium">Invoice Information</p>
        <div className="mb-6 flex">
          <div className="w-48">
            <p className="text-preset-6 text-ink-dark mb-2 font-medium">Date</p>
            <p className="text-ink-not-as-dark">
              {format(today, 'MM-dd-yyyy')}
            </p>
          </div>
          <div className="w-48">
            <p className="text-preset-6 text-ink-dark mb-2 font-medium">
              Due Date
            </p>
            <p className="text-ink-not-as-dark">
              {format(
                addDays(
                  today,
                  netDIntervalMap[netDOption?.id || defaultNetDOption],
                ),
                'MM-dd-yyyy',
              )}
            </p>
          </div>
          <div className="w-48">
            <p className="text-preset-6 text-ink-dark mb-2 font-medium">
              Terms
            </p>
            <Controller
              name="netDOption"
              control={control}
              defaultValue={defaultNetDOptionItem}
              render={({ onChange, value }) => (
                <Combobox
                  id="netDOption"
                  label=""
                  items={items}
                  loadingItems={false}
                  renderLoadingComponent={() => <Loading />}
                  renderNoResultsComponent={() => <p>No results</p>}
                  renderItemComponent={(component) => (
                    <div className="hover:bg-background-app cursor-pointer py-2 px-4">
                      <p className="text-preset-6 text-ink-dark font-medium">
                        {component.item.label}
                      </p>
                    </div>
                  )}
                  onChange={onChange}
                  onInputChange={() => null}
                  renderTrailingComponent={() => (
                    <ChevronDown className="text-ink-not-as-dark h-3 w-3" />
                  )}
                  inputValue={value.label}
                  inputReadOnly
                  openMenuOnFocus
                />
              )}
            />
          </div>
        </div>
        <p className="text-preset-4 mb-2.5 font-medium">Select Address</p>
        <Controller
          name="useAlternativeAddress"
          control={control}
          defaultValue={false}
          render={({ onChange, value }) => (
            <RadioGroup value={value} onChange={onChange}>
              <div className="mb-6 flex flex-row space-x-6">
                <RadioGroup.Option
                  value={false}
                  className="relative block cursor-pointer rounded-lg bg-white px-6 py-4 focus:outline-none"
                >
                  {({ checked }) => (
                    <>
                      <RadioGroup.Label
                        as="div"
                        className="mb-3 flex flex-row text-sm"
                      >
                        <span
                          className="border-ink-dark mr-3 flex h-5 w-5 cursor-pointer items-center justify-center rounded-full border-2"
                          aria-hidden="true"
                        >
                          <span
                            className={`h-3 w-3 rounded-full ${
                              checked ? 'bg-primary' : 'bg-white'
                            }`}
                          />
                        </span>
                        <span
                          className={
                            checked ? 'text-ink-primary' : 'text-ink-dark'
                          }
                        >
                          Default Address
                        </span>
                      </RadioGroup.Label>
                      <RadioGroup.Description
                        as="div"
                        className="text-ink-dark text-sm"
                      >
                        <p className="sm:inline">
                          Tend Exchange Subsidiary, LLC
                          <br />
                          6701 S Center Drive W Suite 425
                          <br />
                          Los Angeles, CA 90045
                          <br />
                          accounting@hiretend.com
                        </p>
                      </RadioGroup.Description>
                      <div
                        className={`pointer-events-none absolute -inset-px rounded-lg border-2 ${
                          checked ? 'border-primary' : 'border-transparent'
                        }`}
                        aria-hidden="true"
                      />
                    </>
                  )}
                </RadioGroup.Option>
                <RadioGroup.Option
                  value={true}
                  className="relative block cursor-pointer rounded-lg bg-white px-6 py-4 focus:outline-none"
                >
                  {({ checked }) => (
                    <>
                      <RadioGroup.Label
                        as="div"
                        className="text-ink-primary mb-3 flex flex-row text-sm"
                      >
                        <span
                          className="border-ink-dark mr-3 flex h-5 w-5 cursor-pointer items-center justify-center rounded-full border-2"
                          aria-hidden="true"
                        >
                          <span
                            className={`h-3 w-3 rounded-full ${
                              checked ? 'bg-primary' : 'bg-white'
                            }`}
                          />
                        </span>
                        <span
                          className={
                            checked ? 'text-ink-primary' : 'text-ink-dark'
                          }
                        >
                          Alternative Address
                        </span>
                      </RadioGroup.Label>
                      <RadioGroup.Description
                        as="div"
                        className="text-ink-dark text-sm"
                      >
                        <p className="sm:inline">
                          Delaware Tender Staffing, LLC
                          <br />
                          6701 S Center Drive W Suite 425
                          <br />
                          Los Angeles, CA 90045
                          <br />
                          accounting@hiretend.com
                        </p>
                      </RadioGroup.Description>
                      <div
                        className={`pointer-events-none absolute -inset-px rounded-lg border-2 ${
                          checked ? 'border-primary' : 'border-transparent'
                        }`}
                        aria-hidden="true"
                      />
                    </>
                  )}
                </RadioGroup.Option>
              </div>
            </RadioGroup>
          )}
        />
        <label className="text-preset-6 mb-2.5 block" htmlFor="customId">
          Add invoice number
        </label>
        <input
          ref={register}
          type="text"
          name="customId"
          id="customId"
          className={`bg-background-app mb-2 block h-12 w-full rounded ${
            errors.customId
              ? 'focus:ring-status-destructive-light focus:border-status-destructive'
              : 'focus:ring-primary focus:border-primary'
          }`}
          required
        />
        {errors.customId && (
          <p className="text-preset-7 text-status-destructive mb-2">
            * {errors.customId.message}
          </p>
        )}
        <p className="text-preset-6 text-ink-not-as-dark mb-6">
          Use Quickbook invoice number
        </p>
        <p className="text-preset-4 mb-1 font-medium">Add Invoice Adjustment</p>
        <p className="text-preset-6 text-primary mb-6">*Optional</p>
        <div className="mb-5 flex">
          <div className="w-10/12 pr-4">
            <label
              className="text-preset-6 mb-2.5 block"
              htmlFor="adjustmentDescription"
            >
              Description
            </label>
            <input
              ref={register}
              type="text"
              name="adjustmentDescription"
              id="adjustmentDescription"
              className={`bg-background-app mb-2 block h-12 w-full rounded ${
                errors.adjustmentDescription
                  ? 'focus:ring-status-destructive-light focus:border-status-destructive'
                  : 'focus:ring-primary focus:border-primary'
              }`}
            />
            {errors.adjustmentDescription && (
              <p className="text-preset-7 text-status-destructive mb-2">
                * {errors.adjustmentDescription.message}
              </p>
            )}
          </div>
          <div className="w-2/12">
            <label className="text-preset-6 mb-2.5 block" htmlFor="adjustment">
              Amount
            </label>
            <input
              ref={register}
              type="text"
              name="adjustment"
              id="adjustment"
              className={`bg-background-app mb-2 block h-12 w-full rounded ${
                errors.adjustment
                  ? 'focus:ring-status-destructive-light focus:border-status-destructive'
                  : 'focus:ring-primary focus:border-primary'
              }`}
              onChange={(e) =>
                setAdjustment(
                  isNaN(parseFloat(e.currentTarget.value))
                    ? undefined
                    : parseFloat(e.currentTarget.value),
                )
              }
            />
            {errors.adjustment && (
              <p className="text-preset-7 text-status-destructive mb-2">
                * Enter a valid number or leave empty
              </p>
            )}
          </div>
        </div>
        <p className="text-preset-4 mb-1 font-medium">Review Items</p>
        <div className="border-support-line mb-2 border-b-2 pb-1 pt-5">
          <div className="mb-4 flex justify-between px-7">
            <p className="text-preset-4">Total Tenders:</p>
            <p className="text-preset-4">{totalItems}</p>
          </div>
          {!!adjustment && (
            <div className="mb-4 flex justify-between px-7">
              <p className="text-preset-4 text-ink-dark">Payment Adjustment:</p>
              <p className="text-preset-4 text-ink-not-as-dark">
                {formatMoney(adjustment * 100)}
              </p>
            </div>
          )}
        </div>
        <div className="border-support-line mb-6 flex justify-between border-b-2 px-7 pb-7 pt-5">
          <p className="text-preset-4 font-bold">Total Cost:</p>
          <p className="text-preset-4 font-bold">
            {loadingEstimation ? (
              <Loading />
            ) : errorEstimation ? (
              'Could not estimate'
            ) : (
              totalText
            )}
          </p>
        </div>
        <p className="text-preset-4 mb-1 font-medium">Add Billing Address</p>
        <p className="text-preset-6 text-primary mb-6">*Optional</p>
        <label className="text-preset-6 mb-2.5 block" htmlFor="clientName">
          Client name
        </label>
        <input
          ref={register}
          type="text"
          name="clientName"
          id="clientName"
          className="bg-background-app focus:ring-primary focus:border-primary mb-6 block h-12 w-full rounded"
        />
        <label className="text-preset-6 mb-2.5 block" htmlFor="streetAddress">
          Street Address
        </label>
        <input
          ref={register}
          type="text"
          name="streetAddress"
          id="streetAddress"
          className="bg-background-app focus:ring-primary focus:border-primary mb-6 block h-12 w-full rounded"
        />
        <div className="flex">
          <div className="w-8/12 pr-4">
            <label className="text-preset-6 mb-2.5 block" htmlFor="state">
              State
            </label>
            <input
              ref={register}
              type="text"
              name="state"
              id="state"
              className="bg-background-app focus:ring-primary focus:border-primary mb-6 block h-12 w-full rounded"
            />
          </div>
          <div className="w-4/12">
            <label className="text-preset-6 mb-2.5 block" htmlFor="zipCode">
              ZIP Code
            </label>
            <input
              ref={register}
              type="text"
              name="zipCode"
              id="zipCode"
              className="bg-background-app focus:ring-primary focus:border-primary mb-6 block h-12 w-full rounded"
            />
          </div>
        </div>
        <div className="text-right">
          {loading ? (
            <div className="mt-0.5 inline-block px-7 py-1">
              <Loading />
            </div>
          ) : (
            <button
              type="submit"
              className="border-ink-primary text-preset-5 font text-ink-clear bg-primary hover:bg-primary-active rounded-md border-2 px-4 py-1 focus:outline-none"
            >
              Create Invoice
            </button>
          )}
        </div>
        {error && (
          <p className="text-preset-7 text-status-destructive mb-2">
            {error.message}
          </p>
        )}
      </form>
    </Modal>
  );
};

export default CreateInvoiceModal;
