export const getTenderStatus = (tender: {
  status: string;
  blockedAt: Date;
}): string => {
  if (tender.blockedAt) {
    return 'Blocked';
  }
  if (tender.status === 'PROFILE_COMPLETED') {
    return 'Active';
  }
  return (
    tender.status[0] +
    tender.status.substring(1).toLowerCase().replace('_', ' ')
  );
};
