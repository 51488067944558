import { useState } from 'react';
import { OrderByDirectionEnum } from 'src/__generated__/globalTypes';

const useSort = <T = string>(options: {
  defaultSortColumn?: T;
  defaultSortDirection?: OrderByDirectionEnum;
  onSort?: (
    column?: T,
    direction?: OrderByDirectionEnum,
  ) => Promise<unknown> | unknown;
}): [
  (columnName: T) => () => void,
  {
    sortedColumn?: T;
    sortDirection?: OrderByDirectionEnum;
    resetSorting: () => void;
  },
] => {
  const [sortedColumn, setSortedColumn] = useState<T | undefined>(
    options.defaultSortColumn,
  );
  const [sortDirection, setSortDirection] = useState<
    OrderByDirectionEnum | undefined
  >(options.defaultSortDirection);

  const handler = (columnName: T) => () => {
    let nextdirection;

    if (columnName !== sortedColumn || !sortDirection) {
      nextdirection = OrderByDirectionEnum.ASC;
    } else {
      nextdirection =
        sortDirection === OrderByDirectionEnum.ASC
          ? OrderByDirectionEnum.DESC
          : undefined;
    }

    const nextColumn = nextdirection === undefined ? undefined : columnName;

    setSortedColumn(nextColumn);
    setSortDirection(nextdirection);

    options.onSort?.(nextColumn, nextdirection);
  };

  const resetSorting = () => {
    setSortDirection(options.defaultSortDirection);
    setSortedColumn(options.defaultSortColumn);
    options.onSort?.(options.defaultSortColumn, options.defaultSortDirection);
  };

  return [handler, { sortedColumn, sortDirection, resetSorting }];
};

export default useSort;
