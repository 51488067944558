import { SVGProps } from 'react';

const StatDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 13 12"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.771 7.8a.6.6 0 1 0 0 1.2h3a.6.6 0 0 0 .6-.6v-3a.6.6 0 1 0-1.2 0v1.551L7.596 4.376a.6.6 0 0 0-.849 0L5.371 5.75 2.796 3.176a.6.6 0 1 0-.849.848l3 3a.6.6 0 0 0 .849 0L7.17 5.65 9.323 7.8H7.77Z"
      clipRule="evenodd"
    />
  </svg>
);
export default StatDown;
