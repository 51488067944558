import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  RemoveTenderFromRoster,
  RemoveTenderFromRosterVariables,
} from './__generated__/RemoveTenderFromRoster';

const REMOVE_TENDER_FROM_ROSTER = gql`
  mutation RemoveTenderFromRoster($rosterTenderId: ID!) {
    removeTenderFromRoster(rosterTenderId: $rosterTenderId) {
      __typename
      id
    }
  }
`;

export const useRemoveTenderFromRosterMutation = (
  options?: MutationHookOptions<
    RemoveTenderFromRoster,
    RemoveTenderFromRosterVariables
  >,
) =>
  useMutation<RemoveTenderFromRoster, RemoveTenderFromRosterVariables>(
    REMOVE_TENDER_FROM_ROSTER,
    {
      update(cache, { data }) {
        const normalizedId = cache.identify({
          id: data?.removeTenderFromRoster.id,
          __typename: 'RosterTender',
        });
        cache.evict({ id: normalizedId });
        cache.gc();
      },
      ...options,
    },
  );

export default REMOVE_TENDER_FROM_ROSTER;
