import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import apolloClient from 'src/ApolloClient';
import debounce from 'lodash.debounce';
import Combobox from 'src/components/Combobox';
import Search from 'src/components/Icons/Search';
import Loading from 'src/components/Loading';
import Pill from 'src/components/Pill';
import GET_ORGANIZATIONS_PAGINATED_QUERY, {
  useGetOrganizationsPaginatedQuery,
} from 'src/graphql/queries/GetOrganizationsPaginated';
import { GetOrganizationsPaginated_getOrganizationsPaginated_items as Organization } from 'src/graphql/queries/__generated__/GetOrganizationsPaginated';

const OrganizationResultComponent: FC<{
  item: Organization;
  index: number;
  highlighted: boolean;
}> = ({ item, highlighted }) => (
  <div
    className={`py-2 px-4 ${highlighted ? 'bg-background-app' : ''}`}
    data-cy={`organization-result-${item.id}`}
  >
    <p className="text-preset-6 text-ink-dark font-medium">{item.name}</p>
    <p className="text-preset-6 text-ink-not-as-dark">{item.id}</p>
  </div>
);

type Props = {
  selectedOrganizationsIds: string[];
  onChange: (selectedOrganizationsIds: string[]) => void;
  className?: string;
};

const DefaultOrganizationsInput: FC<Props> = ({
  selectedOrganizationsIds,
  onChange,
  className,
}) => {
  const [getOrganizationsPaginated, { data, loading }] =
    useGetOrganizationsPaginatedQuery();
  const [selectedOrganizations, setSelectedOrganizations] = useState<
    Organization[]
  >([]);
  const getAllSelectedOrganizations = useCallback(async () => {
    const organizations = await Promise.all(
      selectedOrganizationsIds.map(async (organizationId) => {
        const { data: organizationData } = await apolloClient.query({
          query: GET_ORGANIZATIONS_PAGINATED_QUERY,
          variables: {
            paginationOptions: {
              limit: 1,
              page: 1,
            },
            queryOptions: {
              searchTerm: organizationId,
            },
          },
        });
        return organizationData?.getOrganizationsPaginated.items[0];
      }),
    );
    setSelectedOrganizations(organizations);
  }, [selectedOrganizationsIds]);
  useEffect(() => {
    getAllSelectedOrganizations();
  }, [getAllSelectedOrganizations]);

  const onAddOrganization = (organization: Organization) => {
    if (
      selectedOrganizations.some((selected) => selected.id === organization.id)
    ) {
      return;
    }
    setSelectedOrganizations([...selectedOrganizations, organization]);
    onChange([...selectedOrganizationsIds, organization.id]);
  };
  const onRemoveOrganization = (organization: Organization) => {
    const removedIndex = selectedOrganizationsIds.indexOf(organization.id);
    selectedOrganizationsIds.splice(removedIndex, 1);
    selectedOrganizations.splice(removedIndex, 1);
    setSelectedOrganizations([...selectedOrganizations]);
    onChange([...selectedOrganizationsIds]);
  };
  const onInputChange = useRef(
    debounce((value) => {
      if (!value) {
        return;
      }
      getOrganizationsPaginated({
        variables: {
          paginationOptions: {
            limit: 10,
            page: 1,
          },
          queryOptions: {
            searchTerm: value,
          },
        },
      });
    }, 300),
  ).current;
  return (
    <div className={className}>
      <Combobox
        id="defaultOrganizationsCombobox"
        label="Organizations"
        description="Search by Organization ID or Organization Name"
        items={data?.getOrganizationsPaginated.items || []}
        loadingItems={loading}
        placeholder="Search by ID or name"
        renderLoadingComponent={() => (
          <div className="flex justify-center">
            <Loading />
          </div>
        )}
        renderNoResultsComponent={() => (
          <p className="py-2 px-4">No Organizations found</p>
        )}
        renderLeadingComponent={() => (
          <Search className="text-ink-not-as-dark h-3 w-3" />
        )}
        onReset={() => null}
        renderItemComponent={OrganizationResultComponent}
        onChange={(selectedItem) =>
          selectedItem && onAddOrganization(selectedItem)
        }
        onInputChange={onInputChange}
        data-cy="organization-filter"
      />
      <div className="selectedOrganizations flex flex-wrap">
        {selectedOrganizations.map((organization) => {
          return (
            <Pill
              key={organization.id}
              onClose={() => onRemoveOrganization(organization)}
              className="mr-2 mb-2"
              toolTipText={organization.id}
              data-cy={`pill-${organization.name}`}
            >
              {organization.name}
            </Pill>
          );
        })}
      </div>
    </div>
  );
};

export default DefaultOrganizationsInput;
