import { useContext, useEffect, useState } from 'react';
import { PageTitleContext } from 'src/contexts/PageTitleContext';

/**
 * This hook assumes that no nested component on the tree will change the title,
 * meaning that only one call at the time to set the title is supported
 */
export default function usePageTitle(newTitle?: string) {
  const { title, setTitle, clearTitle } = useContext(PageTitleContext);
  const [isTitleApplied, setIsTitleApplied] = useState(false);

  useEffect(() => {
    if (newTitle && !isTitleApplied) {
      setTitle(newTitle);
      setIsTitleApplied(true);
    }

    return () => {
      isTitleApplied && clearTitle();
    };
  }, [clearTitle, isTitleApplied, newTitle, setTitle]);

  return title;
}
