import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 2a3 3 0 0 0-3 3v4a5 5 0 0 0 10 0V5a1 1 0 1 1 2 0v4A7 7 0 1 1 0 9V5a5 5 0 0 1 10 0v4a3 3 0 1 1-6 0V5a1 1 0 0 1 2 0v4a1 1 0 0 0 2 0V5a3 3 0 0 0-3-3Z"
      fill="currentColor"
    />
  </svg>
);

const Memo = memo(SvgComponent);
export default Memo;
