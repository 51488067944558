import { endOfDay, startOfDay } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useHistory } from 'react-router-dom';
import ClientsInput from 'src/components/ClientsInput';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import DateRangeInput from 'src/components/DateRangeInput';
import InvoicingStatusFilter from 'src/components/Filters/InvoicingStatus';
import Loading from 'src/components/Loading';
import { useInvoiceStaffingsQuery } from 'src/graphql/queries/InvoiceStaffings';
import { AdminJobs_adminJobs_items as JobItem } from 'src/graphql/queries/__generated__/AdminJobs';
import { Clients_getClients_items as ClientItem } from 'src/graphql/queries/__generated__/Clients';
import {
  Filter,
  FilterOperator,
  InvoicingStatus,
  OrderByDirectionEnum,
} from 'src/__generated__/globalTypes';
import AdvancedFilters from './AdvancedFilters';
import CreateInvoiceModal from './CreateInvoiceModal';
import InvoiceStaffingsTable, { SelectionMode } from './InvoiceStaffingsTable';

const NoStaffingsNotice = () => (
  <div className="mx-auto mt-16 max-w-lg text-center">
    <h2 className="text-ink-not-as-dark text-preset-2 mb-4">
      There are no staffings to show
    </h2>
    <p className="text-ink-placeholder text-preset-5">
      Staffings that can be selected for invoicing will be shown here according
      to the filters set. Please check your filters if you were expecting to
      find information here.
    </p>
  </div>
);

const InvoiceCreate = () => {
  const history = useHistory();
  const defaultStartDate = startOfDay(new Date());
  const defaultEndDate = endOfDay(new Date());
  const [startDate, setStartDate] = useState<Date | undefined>(
    defaultStartDate,
  );
  const [endDate, setEndDate] = useState<Date | undefined>(defaultEndDate);
  const [orderByField, setOrderByField] = useState('shift.startDateTime');
  const [orderByDirection, setOrderByDirection] =
    useState<OrderByDirectionEnum>(OrderByDirectionEnum.ASC);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [advancedFiltersOpen, setAdvancedFiltersOpen] = useState(false);

  const [selectedJobs, setSelectedJobs] = useState<JobItem[] | undefined>();
  const [selectedClients, setSelectedClients] = useState<
    ClientItem[] | undefined
  >();
  const [invoicingStatus, setInvoicingStatus] = useState<InvoicingStatus>();
  const [selectedRegions, setSelectedRegions] = useState<string[]>();

  const [filters, setFilters] = useState<Filter[]>([
    {
      property: 'date',
      operator: FilterOperator.between,
      values: [
        (startDate || defaultStartDate).toISOString(),
        (endDate || defaultEndDate).toISOString(),
      ],
    },
  ]);

  const [selectionMode, setSelectionMode] = useState<SelectionMode>(
    SelectionMode.WHITELIST,
  );
  const [flippedStaffings, setFlippedStaffings] = useState<
    Record<string, boolean>
  >({});
  const [historicalDialogOpen, setHistoricalDialogOpen] = useState(false);

  const [createInvoiceModalOpen, setCreateInvoiceModalOpen] = useState(false);

  const { data, previousData, loading, error, refetch } =
    useInvoiceStaffingsQuery({
      variables: {
        paginationOptions: {
          limit: 20,
          page: pageNumber,
        },
        filters,
        orderByField,
        orderByDirection,
      },
    });

  useEffect(() => {
    const newFilters: Filter[] = [];
    if (startDate && endDate) {
      newFilters.push({
        property: 'date',
        operator: FilterOperator.between,
        values: [
          startOfDay(startDate).toISOString(),
          endOfDay(endDate).toISOString(),
        ],
      });
    }
    if (selectedJobs) {
      newFilters.push({
        property: 'job.id',
        operator: FilterOperator.any,
        values: selectedJobs.map((job) => job.id),
      });
    }
    if (selectedClients) {
      newFilters.push({
        property: 'client.id',
        operator: FilterOperator.any,
        values: selectedClients.map((client) => client.id),
      });
    }
    if (invoicingStatus) {
      newFilters.push({
        property: 'invoicingStatus',
        operator: FilterOperator.any,
        values: [invoicingStatus],
      });
    }
    if (selectedRegions) {
      newFilters.push({
        property: 'region.id',
        operator: FilterOperator.any,
        values: selectedRegions,
      });
    }
    setSelectionMode(SelectionMode.WHITELIST);
    setFlippedStaffings({});
    setFilters(newFilters);
  }, [
    startDate,
    endDate,
    selectedClients,
    selectedJobs,
    selectedRegions,
    invoicingStatus,
    setFilters,
    setSelectionMode,
    setFlippedStaffings,
  ]);

  const totalItems = (data ?? previousData)?.invoiceStaffings.meta.totalItems;

  const onFlipStaffing = (id: string) => {
    if (flippedStaffings[id]) {
      const { [id]: value, ...flipped } = flippedStaffings;
      setFlippedStaffings(flipped);
    } else {
      setFlippedStaffings({
        ...flippedStaffings,
        [id]: true,
      });
    }
  };

  const unselectAll = () => {
    setSelectionMode(SelectionMode.WHITELIST);
    setFlippedStaffings({});
  };

  const flippedAmount = Object.keys(flippedStaffings).length;
  const selectedAmount =
    selectionMode === SelectionMode.WHITELIST
      ? flippedAmount
      : (totalItems || 0) - flippedAmount;

  const ActionsPortal = ({ children }: { children: React.ReactNode }) => {
    return createPortal(
      children ? children : null,
      document.getElementById('actions-portal-placeholder') || document.body,
    );
  };

  return (
    <div className="flex h-full flex-col">
      {loading && !previousData ? (
        <Loading width={60} className="m-auto mt-10 text-center" />
      ) : error ? (
        <p className="m-10">There was an error when requesting your data.</p>
      ) : (
        <>
          <div className="bg-background-surface border-support-line flex h-20 flex-shrink-0 items-center justify-between border-b px-4">
            <div className="py-7">
              <p className="text-preset-3 text-ink-dark">
                {totalItems} Staffing{totalItems !== 1 ? 's' : ''}
              </p>
            </div>
            <div className="filters z-30 flex items-center space-x-4">
              <DateRangeInput
                startDate={startDate}
                endDate={endDate}
                onChange={(start, end) => {
                  setStartDate(start);
                  setEndDate(end);
                }}
                allowAnyDate
              />
              <InvoicingStatusFilter
                status={invoicingStatus}
                onChange={(status) => setInvoicingStatus(status)}
              />
              <ClientsInput
                selectedClients={selectedClients}
                onChange={(clients?: ClientItem[]) =>
                  setSelectedClients(clients)
                }
              />
              <button
                className="border-ink-dark text-preset-5 text-ink-dark hover:border-ink-not-as-dark hover:text-ink-not-as-dark inline-flex h-11 items-center rounded-md border-2 px-4 font-medium"
                onClick={() => setAdvancedFiltersOpen(true)}
              >
                Advanced Filters
              </button>
            </div>
          </div>

          {!totalItems ? (
            <NoStaffingsNotice />
          ) : (
            <>
              <InvoiceStaffingsTable
                data={data ?? previousData}
                pageNumber={pageNumber}
                orderByField={orderByField}
                orderByDirection={orderByDirection}
                selectionMode={selectionMode}
                flippedStaffings={flippedStaffings}
                onPageChange={(p) => setPageNumber(p)}
                onSortChange={(field, direction) => {
                  setOrderByField(field);
                  setOrderByDirection(direction);
                }}
                onFlipStaffing={onFlipStaffing}
                onSelectAll={() => {
                  setSelectionMode(SelectionMode.BLACKLIST);
                  setFlippedStaffings({});
                }}
                onUnselectAll={unselectAll}
                selectedAmount={selectedAmount}
                PaginatorContainer={ActionsPortal}
              />

              <div className="fixed bottom-0 z-20 w-full items-center">
                <div id="actions-portal-placeholder" />

                {selectedAmount > 0 && (
                  <div className="footer-actions bg-background-surface border-support-line flex h-14 w-full items-center justify-between border-t border-b px-6">
                    <div className="flex items-center space-x-6">
                      <p className="text-preset-6 text-ink-not-as-dark">
                        {selectedAmount} Selected
                      </p>
                      <button
                        className="border-ink-dark text-preset-5 text-ink-dark hover:border-ink-not-as-dark hover:text-ink-not-as-dark rounded-md border-2 px-4 py-1 font-medium"
                        onClick={unselectAll}
                      >
                        Unselect All
                      </button>
                    </div>
                    <button
                      className="border-ink-primary text-preset-5 font text-ink-clear bg-primary hover:bg-primary-active rounded-md border-2 px-4 py-1"
                      onClick={() => setCreateInvoiceModalOpen(true)}
                    >
                      Create Invoice
                    </button>
                  </div>
                )}
              </div>
            </>
          )}
        </>
      )}
      <AdvancedFilters
        open={advancedFiltersOpen}
        selectedJobs={selectedJobs}
        selectedRegionIds={selectedRegions || []}
        onClose={() => setAdvancedFiltersOpen(false)}
        onChange={(jobs, regions) => {
          setSelectedJobs(jobs);
          setSelectedRegions(regions);
        }}
      />
      <CreateInvoiceModal
        open={createInvoiceModalOpen}
        totalItems={selectedAmount}
        onSubmit={() => {
          setCreateInvoiceModalOpen(false);
          setHistoricalDialogOpen(true);
        }}
        onClose={() => setCreateInvoiceModalOpen(false)}
        staffingIds={
          selectionMode === SelectionMode.WHITELIST &&
          Object.keys(flippedStaffings).length
            ? Object.keys(flippedStaffings)
            : undefined
        }
        notStaffingIds={
          selectionMode === SelectionMode.BLACKLIST &&
          Object.keys(flippedStaffings).length
            ? Object.keys(flippedStaffings)
            : undefined
        }
        staffingFilters={filters}
      />
      <ConfirmationDialog
        title="Go to Historical"
        description="Do you want to be directed to the Invoices Historical for downloading the created PDF or remain in this page?"
        open={historicalDialogOpen}
        onClose={() => {
          setHistoricalDialogOpen(false);
          unselectAll();
          refetch();
        }}
        onSubmit={() => history.push('/invoices')}
        submitText="Go to Historical"
        submitClassName="border border-ink-dark"
        cancelText="Remain in this page"
      />
    </div>
  );
};

export default InvoiceCreate;
