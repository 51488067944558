import { gql, LazyQueryHookOptions, useLazyQuery } from '@apollo/client';
import {
  SearchTendersForShift,
  SearchTendersForShiftVariables,
} from './__generated__/SearchTendersForShift';
import SEARCH_TENDERS_FOR_SHIFT_ITEM from '../fragments/SearchTendersForShiftItem';

const SEARCH_TENDERS_FOR_SHIFT = gql`
  query SearchTendersForShift(
    $paginationOptions: PaginationOptions!
    $searchTerm: NonEmptyString!
    $shiftId: NonEmptyString!
    $excludeBlockedTendersByClients: Boolean
    $countries: [String!]
    $excludeTendersWithoutAgreements: Boolean
  ) {
    searchTendersForShift(
      paginationOptions: $paginationOptions
      searchTerm: $searchTerm
      shiftId: $shiftId
      excludeBlockedTendersByClients: $excludeBlockedTendersByClients
      countries: $countries
      excludeTendersWithoutAgreements: $excludeTendersWithoutAgreements
    ) {
      items {
        ...SearchTendersForShiftItem
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
  ${SEARCH_TENDERS_FOR_SHIFT_ITEM}
`;

export const useLazySearchTendersForShiftQuery = (
  options?: LazyQueryHookOptions<
    SearchTendersForShift,
    SearchTendersForShiftVariables
  >,
) =>
  useLazyQuery<SearchTendersForShift, SearchTendersForShiftVariables>(
    SEARCH_TENDERS_FOR_SHIFT,
    options,
  );

export default SEARCH_TENDERS_FOR_SHIFT;
