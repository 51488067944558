import React from 'react';

type Props = {
  id: string;
  label: string;
  checked: boolean;
  onClick: (checked: boolean) => void;
  className?: string;
};

const Toggle: React.FC<Props> = ({
  id,
  label,
  checked,
  onClick,
  className,
}) => {
  return (
    <label
      htmlFor={id}
      className={`flex cursor-pointer items-center ${className}`}
    >
      <p className="text-ink-not-as-dark">{label}</p>
      <div className="relative ml-2">
        <input
          checked={checked}
          type="checkbox"
          id={id}
          className="sr-only"
          onClick={() => {
            onClick(!checked);
          }}
          readOnly
        />
        <div
          className={`border-primary block h-5 w-10 rounded-full border-2 transition ${
            checked ? 'bg-primary' : 'bg-white'
          }`}
        />
        <div
          className={`border-primary absolute top-0.5 h-4 w-4 transform rounded-full border-2 bg-white transition ${
            checked ? 'right-0.5' : 'left-0.5'
          }`}
        />
      </div>
    </label>
  );
};

export default Toggle;
