import { Transition } from '@headlessui/react';
import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import PreferenceMatchFull from 'src/components/Icons/PreferenceMatchFull';
import PreferenceMatchEmpty from 'src/components/Icons/PreferenceMatchEmpty';
import PreferenceMatchNone from 'src/components/Icons/PreferenceMatchNone';
import PreferenceMatchPartial from 'src/components/Icons/PreferenceMatchPartial';
import { StaffingListItem_tagGroups } from 'src/graphql/fragments/__generated__/StaffingListItem';
import Tag from '../Tag';
import DataCell from './DataCell';

interface ClientPreferenceMatchProps {
  positionTagGroups?: (StaffingListItem_tagGroups | null)[] | null;
  tenderTagGroups?: (StaffingListItem_tagGroups | null)[] | null;
}

const preferenceMatchConfig = {
  full: {
    icon: () => (
      <PreferenceMatchFull
        data-match-type="full"
        role="img"
        className="h-7 w-7"
      />
    ),
  },
  partial: {
    icon: () => (
      <PreferenceMatchPartial
        data-match-type="partial"
        role="img"
        className="h-7 w-7"
      />
    ),
  },
  none: {
    icon: () => (
      <PreferenceMatchNone
        data-match-type="none"
        role="img"
        className="h-7 w-7"
      />
    ),
  },
  empty: {
    icon: () => (
      <PreferenceMatchEmpty
        data-match-type="empty"
        role="img"
        className="h-7 w-7"
      />
    ),
  },
};

const ClientPreferenceMatch: React.FC<ClientPreferenceMatchProps> = ({
  positionTagGroups,
  tenderTagGroups,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [referenceElement, setReferenceElement] =
    React.useState<HTMLElement | null>();
  const [popperElement, setPopperElement] = React.useState<HTMLElement | null>(
    null,
  );
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
      {
        name: 'flip',
        options: {
          padding: 60,
        },
      },
    ],
  });
  let matchType: keyof typeof preferenceMatchConfig;

  // calculate preference match
  const matchingTagGroups = positionTagGroups?.filter((positionTagGroup) =>
    tenderTagGroups?.some(
      (tenderTagGroup) => tenderTagGroup?.id === positionTagGroup?.id,
    ),
  );

  if (positionTagGroups?.length === 0) {
    matchType = 'none';
  } else {
    matchType =
      matchingTagGroups?.length === 0
        ? 'empty'
        : matchingTagGroups?.length === positionTagGroups?.length
        ? 'full'
        : 'partial';
  }

  const portalRoot = document.querySelector('#portal-root');

  const Icon = preferenceMatchConfig[matchType].icon;

  return (
    <DataCell data-testid="client-preference-match-cell">
      <div className="flex items-center justify-center">
        <button
          className="cursor-default"
          ref={setReferenceElement}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          {Icon ? Icon() : null}
        </button>

        {matchType !== 'none' &&
          portalRoot &&
          createPortal(
            <Transition
              show={isHovering}
              enter="transition-opacity duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                className="absolute top-0 left-0 w-52 rounded-lg bg-white p-4 shadow-lg"
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
              >
                <p className="text-preset-7 mb-2">Staffing Tags</p>

                <ul className="flex flex-wrap gap-1">
                  {positionTagGroups?.map((tagGroup) => {
                    const isActive = matchingTagGroups?.some(
                      (matchingTagGroup) =>
                        matchingTagGroup?.id === tagGroup?.id,
                    );
                    return (
                      tagGroup && (
                        <li key={tagGroup.id}>
                          <Tag isDisabled={!isActive}>{tagGroup.name}</Tag>
                        </li>
                      )
                    );
                  })}
                </ul>
              </div>
            </Transition>,
            portalRoot,
          )}
      </div>
    </DataCell>
  );
};

export default ClientPreferenceMatch;
