import React, { FC, Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import ChevronDown from 'src/components/Icons/ChevronDown';

type Value = undefined | string;

type Option = {
  value: Value;
  label: string;
};

const options: Option[] = [
  { value: undefined, label: 'Any Staffing Status' },
  { value: 'staffed', label: 'Staffed' },
  { value: 'notStaffed', label: 'Not Staffed' },
];

interface Props {
  value?: string;
  onChange?: (value: Value) => void;
}

const findOption = (value: Value) => options.find((o) => o.value === value);

const StaffedInput: FC<Props> = ({ value, onChange }) => {
  const selectedOption = findOption(value) || options[0];

  return (
    <Listbox
      value={selectedOption}
      onChange={(option) => onChange?.(option.value)}
    >
      {({ open }) => (
        <div className="relative">
          <Listbox.Button
            className={`text-preset-5 text-ink-dark relative h-11 w-48 cursor-default rounded py-2 pl-3 pr-10 text-left font-medium shadow-sm focus:outline-none
              ${
                open
                  ? 'bg-primary-light border-brand-50 border-3'
                  : 'bg-background-surface border-support-line-darker border'
              }
            `}
          >
            <span className="block truncate">{selectedOption.label}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDown className="w-3" aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="bg-background-surface absolute z-10 max-h-60 w-full overflow-auto rounded-md py-1 shadow-lg focus:outline-none">
              {options.map((option, i) => (
                <Listbox.Option
                  key={i}
                  className={({ active }) =>
                    `text-ink-dark text-preset-6 relative cursor-default select-none py-2.5 pl-3 pr-9 focus:outline-none ${
                      active ? 'bg-background-app' : ''
                    }`
                  }
                  value={option}
                >
                  {({ selected, active }) => (
                    <>
                      <span className="block truncate">{option.label}</span>
                      {selected ? (
                        <span
                          className={`absolute inset-y-0 right-0 flex items-center pr-4 ${
                            active ? 'text-white' : 'text-indigo-600'
                          }`}
                        />
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
};

export default StaffedInput;
