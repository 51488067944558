import { gql, MutationHookOptions, useMutation } from '@apollo/client';

import {
  RequestTenderPayoutsCSV,
  RequestTenderPayoutsCSVVariables,
} from './__generated__/RequestTenderPayoutsCSV';

const REQUEST_TENDER_PAYOUTS_CSV = gql`
  mutation RequestTenderPayoutsCSV(
    $filters: TenderPaymentFilters!
    $options: RequestTenderPayoutsDataDumpOptions
  ) {
    requestTenderPayoutsDataDump(
      format: CSV
      filters: $filters
      options: $options
    ) {
      id
      success
    }
  }
`;

export const useRequestTenderPayoutsCSVMutation = (
  options?: MutationHookOptions<
    RequestTenderPayoutsCSV,
    RequestTenderPayoutsCSVVariables
  >,
) =>
  useMutation<RequestTenderPayoutsCSV, RequestTenderPayoutsCSVVariables>(
    REQUEST_TENDER_PAYOUTS_CSV,
    options,
  );

export default REQUEST_TENDER_PAYOUTS_CSV;
