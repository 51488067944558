import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import {
  PayableStaffing,
  PayableStaffingVariables,
} from './__generated__/PayableStaffing';

const GET_PAYABLE_STAFFING = gql`
  query PayableStaffing($staffingId: ID!) {
    payableStaffing(staffingId: $staffingId) {
      tenderComment
      tenderShiftRating
      tenderClockOutTime
    }
  }
`;

export const usePayableStaffingQuery = (
  options?: QueryHookOptions<PayableStaffing, PayableStaffingVariables>,
) =>
  useQuery<PayableStaffing, PayableStaffingVariables>(
    GET_PAYABLE_STAFFING,
    options,
  );

export default GET_PAYABLE_STAFFING;
