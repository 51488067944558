import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import apolloClient from 'src/ApolloClient';
import debounce from 'lodash.debounce';
import Combobox from 'src/components/Combobox';
import Search from 'src/components/Icons/Search';
import Loading from 'src/components/Loading';
import Pill from 'src/components/Pill';
import CLIENTS_QUERY, { useClientsQuery } from 'src/graphql/queries/Clients';
import { Clients_getClients_items as Client } from 'src/graphql/queries/__generated__/Clients';

const ClientResultComponent: FC<{
  item: Client;
  index: number;
  highlighted: boolean;
}> = ({ item, highlighted }) => (
  <div
    className={`py-2 px-4 ${highlighted ? 'bg-background-app' : ''}`}
    data-cy={`client-result-${item.email?.split('@')[0]}`}
  >
    <p className="text-preset-6 text-ink-dark font-medium">
      {item.firstName} {item.lastName}
    </p>
    <p className="text-preset-6 text-ink-not-as-dark">{item.email}</p>
  </div>
);

type Props = {
  selectedClientsIds: string[];
  onChange: (selectedClientsIds: string[]) => void;
  className?: string;
};

const DefaultClientsInput: FC<Props> = ({
  selectedClientsIds,
  onChange,
  className,
}) => {
  const [getClients, { data, loading }] = useClientsQuery();
  const [selectedClients, setSelectedClients] = useState<Client[]>([]);
  const getAllSelectedClients = useCallback(async () => {
    const clients = await Promise.all(
      selectedClientsIds.map(async (clientId) => {
        const { data: clientData } = await apolloClient.query({
          query: CLIENTS_QUERY,
          variables: {
            paginationOptions: {
              limit: 1,
              page: 1,
            },
            queryOptions: {
              searchTerm: clientId,
            },
          },
        });
        return clientData?.getClients.items[0];
      }),
    );
    setSelectedClients(clients);
  }, [selectedClientsIds]);
  useEffect(() => {
    getAllSelectedClients();
  }, [getAllSelectedClients]);

  const addClient = (client: Client) => {
    if (selectedClients.some((selected) => selected.id === client.id)) {
      return;
    }
    setSelectedClients([...selectedClients, client]);
    onChange([...selectedClientsIds, client.id]);
  };
  const removeClient = (client: Client) => {
    const removedIndex = selectedClientsIds.indexOf(client.id);
    selectedClientsIds.splice(removedIndex, 1);
    selectedClients.splice(removedIndex, 1);
    setSelectedClients([...selectedClients]);
    onChange([...selectedClientsIds]);
  };
  const inputChange = useRef(
    debounce((value) => {
      if (!value) {
        return;
      }
      getClients({
        variables: {
          paginationOptions: {
            limit: 10,
            page: 1,
          },
          queryOptions: {
            searchTerm: value,
          },
        },
      });
    }, 300),
  ).current;
  return (
    <div className={className}>
      <Combobox
        id="defaultClientsCombobox"
        label="Clients"
        description="Search by Client ID, Client Email or Client Org Name"
        items={data?.getClients.items || []}
        loadingItems={loading}
        placeholder="Search by ID, email or organization name"
        renderLoadingComponent={() => (
          <div className="flex justify-center">
            <Loading />
          </div>
        )}
        renderNoResultsComponent={() => (
          <p className="py-2 px-4">No clients found</p>
        )}
        renderLeadingComponent={() => (
          <Search className="text-ink-not-as-dark h-3 w-3" />
        )}
        onReset={() => null}
        renderItemComponent={ClientResultComponent}
        onChange={(selectedItem) => selectedItem && addClient(selectedItem)}
        onInputChange={inputChange}
        data-cy="client-filter"
      />
      <div className="selectedClients flex flex-wrap">
        {selectedClients.map((client) => {
          return (
            <Pill
              key={client.id}
              onClose={() => removeClient(client)}
              className="mr-2 mb-2"
              toolTipText={client.id}
              data-cy={`pill-${client.email?.split('@')[0]}`}
            >
              {client.email}
            </Pill>
          );
        })}
      </div>
    </div>
  );
};

export default DefaultClientsInput;
