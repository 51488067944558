import React from 'react';

const Identification: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={className}
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00012 0C7.44784 0 7.00012 0.447715 7.00012 1V2C7.00012 2.55228 7.44784 3 8.00012 3C8.55241 3 9.00012 2.55228 9.00012 2V1C9.00012 0.447715 8.55241 0 8.00012 0Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.00012 2H5.00012C5.00012 3.65685 6.34327 5 8.00012 5C9.65698 5 11.0001 3.65685 11.0001 2H14.0001C15.1047 2 16.0001 2.89543 16.0001 4V13C16.0001 14.1046 15.1047 15 14.0001 15H2.00012C0.895553 15 0.00012207 14.1046 0.00012207 13V4C0.00012207 2.89543 0.895553 2 2.00012 2ZM4.50012 9C5.32855 9 6.00012 8.32843 6.00012 7.5C6.00012 6.67157 5.32855 6 4.50012 6C3.67169 6 3.00012 6.67157 3.00012 7.5C3.00012 8.32843 3.67169 9 4.50012 9ZM6.9506 13C6.9834 12.8384 7.00061 12.6712 7.00061 12.5C7.00061 11.1193 5.88132 10 4.50061 10C3.1199 10 2.00061 11.1193 2.00061 12.5C2.00061 12.6712 2.01783 12.8384 2.05062 13H6.9506ZM10.0001 7C9.44784 7 9.00012 7.44772 9.00012 8C9.00012 8.55228 9.44784 9 10.0001 9H13.0001C13.5524 9 14.0001 8.55228 14.0001 8C14.0001 7.44772 13.5524 7 13.0001 7H10.0001ZM9.00012 11C9.00012 10.4477 9.44784 10 10.0001 10H12.0001C12.5524 10 13.0001 10.4477 13.0001 11C13.0001 11.5523 12.5524 12 12.0001 12H10.0001C9.44784 12 9.00012 11.5523 9.00012 11Z"
      fill="currentColor"
    />
  </svg>
);

export default Identification;
