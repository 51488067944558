import React, { useContext } from 'react';
import { Redirect, Route, RouteProps, useHistory } from 'react-router-dom';
import { validateAuthState } from 'src/auth';
import AdminProfileContextProvider from 'src/contexts/AdminProfileContext';
import { authContext } from 'src/contexts/AuthContext';

const PrivateRoute: React.FC<RouteProps> = (props) => {
  const auth = useContext(authContext);
  const history = useHistory();
  if (validateAuthState(history.location, auth.authToken)) {
    return (
      <AdminProfileContextProvider>
        <Route {...props} />;
      </AdminProfileContextProvider>
    );
  }
  return <Redirect to="/signin" />;
};

export default PrivateRoute;
