import * as React from 'react';
import { SVGProps } from 'react';

const Gift = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M8 5.333V14m0-8.667V4a1.333 1.333 0 1 1 1.333 1.333H8Zm0 0V3.667a1.667 1.667 0 1 0-1.667 1.666H8ZM3.333 8h9.334M3.333 8a1.333 1.333 0 1 1 0-2.667h9.334a1.333 1.333 0 0 1 0 2.667M3.333 8v4.667c0 .736.597 1.333 1.334 1.333h6.666c.737 0 1.334-.597 1.334-1.333V8"
    />
  </svg>
);

export default Gift;
