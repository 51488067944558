import React from 'react';
import { format, utcToZonedTime } from 'date-fns-tz';

import { GetRosterForAdmin_roster_job_shifts as Shifts } from 'src/graphql/queries/__generated__/GetRosterForAdmin';
import GET_ROSTER_TENDERS, {
  useGetRosterTendersForAdminQuery,
} from 'src/graphql/queries/GetTendersInRoster';
import { useRemoveShiftFromRosterMutation } from 'src/graphql/mutations/RemoveShiftFromRoster';
import { useAddShiftToRosterMutation } from 'src/graphql/mutations/AddShiftToRoster';
import { TenderRosterTypeEnum } from 'src/__generated__/globalTypes';

const RosterShift: React.FC<{
  shift: Shifts;
  timeZone?: string;
  onClick: () => void;
  buttonLabel: string;
}> = ({ shift, onClick, buttonLabel, timeZone = 'America/Los_Angeles' }) => {
  return (
    <div className="border-support-line border-t py-5">
      <div className="flex justify-between">
        <p className="text-preset-5 text-ink-dark mb-2">
          {shift.position.name}
        </p>
        {shift.staffedSlots > 0 && (
          <button
            className="text-preset-6 text-ink-primary"
            onClick={onClick}
            data-cy="add-or-remove-button"
          >
            {buttonLabel}
          </button>
        )}
      </div>
      <p className="text-preset-7P text-ink-not-as-dark mb-4">{shift.id}</p>
      <p className="text-preset-6P text-ink-not-as-dark mb-5">
        {format(
          utcToZonedTime(shift.startDateTime, timeZone),
          'MMM dd hh:mmaaa zzz - ',
          {
            timeZone,
          },
        )}
        {format(
          utcToZonedTime(shift.endDateTime, timeZone),
          'MMM dd hh:mmaaa zzz',
          {
            timeZone,
          },
        )}
      </p>
      <p className="text-preset-6P text-ink-not-as-dark">
        Required {shift.quantity} staffing
        {shift.quantity > 1 ? 's' : ''}
      </p>
      {shift.staffedSlots === 0 && (
        <p className="text-preset-6P text-status-destructive mt-5">
          No valid staffings
        </p>
      )}
    </div>
  );
};

const RosterShiftsCard: React.FC<{
  shifts?: Shifts[] | null;
  timeZone?: string;
  rosterId: string;
}> = ({ shifts, timeZone, rosterId }) => {
  const { data } = useGetRosterTendersForAdminQuery({
    variables: { rosterId },
    // The query to the backend is already made by the tenders table.
    // Using the cache is enough to get the data needed for this component.
    // If changed or removed, please adapt the ApolloClient.ts file
    // accordingly to avoid duplicated rows in the tender roster table.
    fetchPolicy: 'cache-only',
  });
  const [removeShiftFromRosterMutation] = useRemoveShiftFromRosterMutation();
  const [addShiftToRosterMutation] = useAddShiftToRosterMutation({
    refetchQueries: [
      {
        query: GET_ROSTER_TENDERS,
        variables: {
          rosterId,
        },
      },
      {
        query: GET_ROSTER_TENDERS,
        variables: {
          rosterId,
          filters: {
            tenderRosterType: { equals: TenderRosterTypeEnum.TENDER },
          },
        },
      },
    ],
  });
  return (
    <div className="border-support-line rounded border px-4 py-5">
      <p className="text-preset-4 text-ink-dark mb-6 font-medium">
        Shift
        {shifts && shifts.length > 1 ? 's' : ''}
      </p>
      {shifts?.map((shift) => {
        const rosterHasShift = data?.tenderRosters.edges.some(
          (tenderRoster) => tenderRoster.node.shift?.id === shift.id,
        );
        const handleClick = async () => {
          if (rosterHasShift) {
            await removeShiftFromRosterMutation({
              variables: {
                rosterId,
                shiftId: shift.id,
              },
            });
          } else {
            await addShiftToRosterMutation({
              variables: {
                rosterId,
                shiftId: shift.id,
              },
            });
          }
        };
        return (
          <RosterShift
            key={shift.id}
            shift={shift}
            timeZone={timeZone}
            buttonLabel={rosterHasShift ? 'Remove shift' : 'Add Shift'}
            onClick={handleClick}
          />
        );
      })}
    </div>
  );
};

export default RosterShiftsCard;
