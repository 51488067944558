import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SIGNIN_ROUTE } from 'src/routes';
import { authContext } from 'src/contexts/AuthContext';
import {
  formLabelClass,
  formInputClass,
  primaryButtonClass,
} from 'src/components/Forms';
import AuthScreenShell from 'src/components/AuthScreenShell';
import {
  wrongCredentialsMessage,
  serviceOutageMessage,
  unexpectedErrorMessage,
} from 'src/utils/errorDisplay';

type FormValues = {
  email: string;
  password: string;
};

const signinSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Please enter your email'),
  password: Yup.string().required('Please enter your password'),
});

const Form: React.FC = () => {
  const [submitError, setSubmitError] = useState(false);
  const [submitErrorMessage, setSubmitErrorMessage] = useState('');
  const { register, errors, handleSubmit, formState } = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: yupResolver(signinSchema),
  });
  const auth = useContext(authContext);
  const onSubmit = async (values: FormValues) => {
    setSubmitError(false);
    try {
      const rawResponse = await fetch(SIGNIN_ROUTE, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });

      if (rawResponse.ok) {
        const content = await rawResponse.json();
        auth.setAuthToken(content.authToken);
      } else {
        switch (rawResponse.status) {
          case 401: {
            const content = await rawResponse.json();
            console.error(content);
            setSubmitError(true);
            setSubmitErrorMessage(wrongCredentialsMessage);
            break;
          }
          case 502: {
            setSubmitError(true);
            setSubmitErrorMessage(serviceOutageMessage);
            break;
          }
          default: {
            setSubmitError(true);
            setSubmitErrorMessage(unexpectedErrorMessage);
            break;
          }
        }
      }
    } catch (e) {
      setSubmitError(true);
      setSubmitErrorMessage(unexpectedErrorMessage);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      <div>
        <label htmlFor="email" className={formLabelClass}>
          Email address
        </label>
        <div className="mt-1">
          <input
            ref={register}
            tabIndex={2}
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            className={formInputClass}
            data-cy="email-input"
          />
        </div>
        {errors.email?.message && (
          <div className="text-preset-5 mt-3 block h-12 text-red-700">
            {formState.errors.email?.message}
          </div>
        )}
      </div>

      <div className="space-y-1">
        <div className="flex items-center justify-between">
          <label htmlFor="password" className={formLabelClass}>
            Password
          </label>

          <div className="text-preset-5">
            <NavLink
              tabIndex={5}
              to="/forgot-password"
              className="hover:text-brand-50 font-medium text-gray-400"
            >
              Forgot your password?
            </NavLink>
          </div>
        </div>
        <div className="mt-1">
          <input
            ref={register}
            tabIndex={3}
            id="password"
            name="password"
            type="password"
            autoComplete="current-password"
            className={formInputClass}
            data-cy="password-input"
          />

          {errors.password?.message && (
            <div className="text-preset-5 mt-3 block h-12 text-red-700">
              {formState.errors.password?.message}
            </div>
          )}
        </div>
      </div>

      <div>
        <button
          tabIndex={4}
          type="submit"
          className={primaryButtonClass}
          disabled={formState.isSubmitting}
          data-cy="submit-button"
        >
          Sign in
        </button>
      </div>

      <div className="text-preset-5 block h-12 text-red-700">
        {submitError && submitErrorMessage}
      </div>
    </form>
  );
};

const LoginView: React.FC = () => (
  <AuthScreenShell label="Admin">
    <div>
      <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
        Sign in to your account
      </h2>
    </div>

    <div className="mt-8">
      <div className="mt-6">
        <Form />
      </div>
    </div>
  </AuthScreenShell>
);

export default LoginView;
