import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import {
  InvoiceStaffings,
  InvoiceStaffingsVariables,
} from './__generated__/InvoiceStaffings';

const INVOICE_STAFFINGS_QUERY = gql`
  query InvoiceStaffings(
    $paginationOptions: PaginationOptions!
    $filters: [Filter!]
    $orderByField: String
    $orderByDirection: OrderByDirectionEnum
  ) {
    invoiceStaffings(
      paginationOptions: $paginationOptions
      filters: $filters
      orderByField: $orderByField
      orderByDirection: $orderByDirection
    ) {
      meta {
        itemCount
        itemsPerPage
        totalItems
        totalPages
        currentPage
      }
      items {
        id
        tenderName
        tenderEmail
        tenderAvatarURL
        jobName
        jobAddress
        jobTimezone
        dateTime
        positionName
        workedHours
        tip
        tendAdjustment
        client {
          id
          email
        }
        clientRate
        clientTotal
        invoicingStatus
      }
    }
  }
`;

export const useInvoiceStaffingsQuery = (
  options?: QueryHookOptions<InvoiceStaffings, InvoiceStaffingsVariables>,
) =>
  useQuery<InvoiceStaffings, InvoiceStaffingsVariables>(
    INVOICE_STAFFINGS_QUERY,
    options,
  );

export default INVOICE_STAFFINGS_QUERY;
