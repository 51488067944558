import React from 'react';
import ChevronDown from 'src/components/Icons/ChevronDown';
import ChevronUp from 'src/components/Icons/ChevronUp';
import { OrderByDirectionEnum } from 'src/__generated__/globalTypes';

const TableHeaderCell: React.FC<{
  sortDirection?: OrderByDirectionEnum;
  onClick?: () => void;
  className?: string;
}> = ({ children, className, sortDirection, onClick }) => {
  const SortIcon =
    sortDirection &&
    (sortDirection === OrderByDirectionEnum.ASC ? ChevronUp : ChevronDown);

  return (
    <th
      scope="col"
      className={`text-preset-7 text-ink-not-as-dark select-none whitespace-nowrap px-4 py-3 text-left font-medium uppercase tracking-wider
        ${className || ''} ${onClick ? 'cursor-pointer' : ''}`}
      onClick={onClick}
    >
      {children}
      {SortIcon && <SortIcon className="-mt-0.5 ml-1 inline h-3 w-3" />}
    </th>
  );
};

export default TableHeaderCell;
