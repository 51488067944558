import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  RequestStaffingsCSV,
  RequestStaffingsCSVVariables,
} from './__generated__/RequestStaffingsCSV';

const REQUEST_STAFFINGS_CSV = gql`
  mutation RequestStaffingsCSV($shiftFilters: ShiftFilters!) {
    requestStaffingDataDump(
      format: CSV
      filterOptions: { shiftFilters: $shiftFilters }
    ) {
      id
      success
    }
  }
`;

export const useRequestStaffingsCSVMutation = (
  options?: MutationHookOptions<
    RequestStaffingsCSV,
    RequestStaffingsCSVVariables
  >,
) =>
  useMutation<RequestStaffingsCSV, RequestStaffingsCSVVariables>(
    REQUEST_STAFFINGS_CSV,
    options,
  );

export default REQUEST_STAFFINGS_CSV;
