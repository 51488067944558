import React, { FC } from 'react';
import { Moment } from 'moment';
import RCTimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import './TimeInput.css';

interface Props {
  value: Moment;
  onChange: (newTime: Moment) => void;
  onOpen: () => void;
  onClose: () => void;
  minuteStep?: number;
}

const TimeInput: FC<Props> = ({
  value,
  onChange,
  onOpen,
  onClose,
  minuteStep = 15,
}) => {
  return (
    <RCTimePicker
      showSecond={false}
      format="h:mm a"
      popupClassName="z-4"
      use12Hours
      inputReadOnly
      minuteStep={minuteStep}
      clearIcon={<div className="hidden" />}
      value={value}
      onChange={onChange}
      onOpen={onOpen}
      onClose={onClose}
    />
  );
};

export default TimeInput;
