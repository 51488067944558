import React, { FC, useState } from 'react';
import { Options } from 'react-select';
import { FetchResult, MutationHookOptions } from '@apollo/client';

import {
  AssignAdminToClient,
  AssignAdminToClientVariables,
} from 'src/graphql/mutations/__generated__/AssignAdminToClient';
import {
  UnassignAdminFromClient,
  UnassignAdminFromClientVariables,
} from 'src/graphql/mutations/__generated__/UnassignAdminFromClient';

import Select from 'src/components/Select';
import Check from 'src/components/Icons/Check';
import Human from 'src/components/Icons/Human';
import Ban from 'src/components/Icons/Ban';

import {
  AssignmentRow,
  SelectOption,
} from 'src/views/ClientAssignments/ClientAssignments';

type CellProps = {
  className?: string;
};
const Cell: FC<CellProps> = ({ children, className }) => (
  <td
    className={`text-preset-6 text-ink-dark bg-background-surface px-4 ${className}`}
  >
    {children}
  </td>
);

type ClientAssignmentRowProps = {
  assignAdminToClient: (
    options?: MutationHookOptions<
      AssignAdminToClient,
      AssignAdminToClientVariables
    >,
  ) => Promise<FetchResult>;
  assignAdminOptions: Options<SelectOption>;
  disabled?: boolean;
  loading?: boolean;
  regionOptions: Options<SelectOption>;
  row: AssignmentRow;
  unassignAdminFromClient: (
    options?: MutationHookOptions<
      UnassignAdminFromClient,
      UnassignAdminFromClientVariables
    >,
  ) => Promise<FetchResult>;
};
const ClientAssignmentRow: FC<ClientAssignmentRowProps> = ({
  assignAdminToClient,
  assignAdminOptions,
  disabled,
  loading,
  regionOptions,
  row,
  unassignAdminFromClient,
}) => {
  const [errorRegionMessage, setErrorRegionMessage] = useState<string | null>(
    null,
  );
  const [errorAdminMessage, setErrorAdminMessage] = useState<string | null>(
    null,
  );

  const handleErrorMessage = (
    setFunction: React.Dispatch<React.SetStateAction<string | null>>,
  ) => {
    const message = 'Region already defined';
    setFunction(message);
    setTimeout(function () {
      setFunction(null);
    }, 3000);
  };

  const [newAdminId, setNewAdminId] = useState<string | undefined>(undefined);
  const [newRegionId, setNewRegionId] = useState<string | null | undefined>(
    undefined,
  );

  const handleNewAdminSubmit = async (clientId: string) => {
    if (!newAdminId || newRegionId === undefined) {
      return;
    }

    setNewAdminLoading(true);

    try {
      await assignAdminToClient({
        variables: {
          input: [{ adminId: newAdminId, clientId, regionId: newRegionId }],
        },
        refetchQueries: ['GetAssignments'],
      });
    } catch (e) {
      handleErrorMessage(setErrorAdminMessage);
    }
    setNewAdminLoading(false);
  };

  const [newAdminLoading, setNewAdminLoading] = useState(false);
  const [regionLoading, setRegionLoading] = useState(false);
  const [regionSuccess, setRegionSuccess] = useState(false);

  const handleRegionSuccess = () => {
    setErrorRegionMessage(null);
    setRegionSuccess(true);
    setTimeout(function () {
      setRegionSuccess(false);
    }, 3000);
  };

  const defaultAssigmentOption = { value: 'unassign', label: '* Unassign *' };
  const assignAdminOptionsWithUnassign = [
    defaultAssigmentOption,
    ...assignAdminOptions,
  ];

  const defaultRegionOption = {
    value: 'default',
    label: '* Default *',
  };
  const regionOptionsWithDefault = [defaultRegionOption, ...regionOptions];

  return (
    <tr
      className={`border-support-line h-14 border-b ${
        !row.adminId ? 'border-t-8' : ''
      }`}
    >
      <Cell className={`w-1/2 ${row.adminId ? 'bg-slate-50' : ''}`}>
        {!row.adminId ? (
          <div className="flex items-center justify-between">
            <span className="w-1/2 truncate">
              {[row.organization?.name, row.email].filter(Boolean).join(' | ')}
            </span>
            <Select
              isDisabled={!!disabled || newAdminLoading}
              isLoading={!!loading || newAdminLoading}
              isClearable={false}
              isSearchable={true}
              name={`assign-admin-${row.id}`}
              onChange={(option) => {
                if (option?.value) {
                  setNewAdminId(option?.value);
                }
              }}
              options={assignAdminOptions}
              placeholder={'Assign an admin to this client'}
              className="w-5/12"
            />
          </div>
        ) : (
          <div className="flex flex-row">
            <Select
              className="w-1/2"
              value={assignAdminOptions.find(
                (option) => row.adminId === option.value,
              )}
              isDisabled={!!disabled || regionLoading}
              isLoading={!!loading || regionLoading}
              isClearable={false}
              isSearchable={true}
              name={`assign-admin-${row.id}`}
              onChange={async (option) => {
                setRegionLoading(true);
                if (option?.value === 'unassign') {
                  await unassignAdminFromClient({
                    variables: {
                      id: row.id,
                    },
                    refetchQueries: ['GetAssignments'],
                  });
                } else if (option) {
                  await assignAdminToClient({
                    variables: {
                      input: [
                        {
                          id: row.id,
                          adminId: option?.value,
                          clientId: row.clientId,
                        },
                      ],
                    },
                    refetchQueries: ['GetAssignments'],
                  });
                }
                setRegionLoading(false);
              }}
              options={assignAdminOptionsWithUnassign}
            />
            {!row.region && (
              <span title="Default admin">
                <Human className="text-primary ml-3 mt-2.5 h-5 w-5" />
              </span>
            )}
          </div>
        )}
      </Cell>
      <Cell className={`w-2/6 ${row.adminId ? 'bg-slate-50' : ''}`}>
        {!row.adminId ? (
          <div>
            <div className="flex flex-row">
              <Select
                className="w-1/2"
                isDisabled={!!disabled || newAdminLoading}
                isLoading={!!loading || newAdminLoading}
                isClearable={false}
                isSearchable={true}
                name={`assign-new-admin-region-${row.id}`}
                options={regionOptionsWithDefault}
                onChange={(option) => {
                  let regionId = null;
                  if (option && option?.value !== 'default') {
                    regionId = option.value;
                  }

                  setNewRegionId(regionId);
                }}
              />
              <button
                className={`bg-primary text-ink-clear ml-4 rounded-md py-2 px-4 ${
                  !newAdminId || newRegionId === undefined
                    ? 'cursor-not-allowed opacity-50'
                    : ''
                }`}
                type="submit"
                disabled={!newAdminId || newRegionId === undefined}
                onClick={() => handleNewAdminSubmit(row.clientId)}
              >
                Assign admin
              </button>
            </div>
            {errorAdminMessage && (
              <span className="text-red-500">{errorAdminMessage}</span>
            )}
          </div>
        ) : (
          <div className="flex flex-row">
            <Select
              className="w-1/2"
              value={
                regionOptions.find(
                  (option) => row.region?.id === option.value,
                ) || defaultRegionOption
              }
              isDisabled={!!disabled || regionLoading}
              isLoading={!!loading || regionLoading}
              isClearable={false}
              isSearchable={true}
              name={`assign-admin-region-${row.id}`}
              onChange={async (option) => {
                if (row.adminId) {
                  setRegionLoading(true);
                  try {
                    await assignAdminToClient({
                      variables: {
                        input: [
                          {
                            id: row.id,
                            adminId: row.adminId,
                            clientId: row.clientId,
                            regionId:
                              !option || option.value === 'default'
                                ? null
                                : option.value,
                          },
                        ],
                      },
                      refetchQueries: ['GetAssignments'],
                    });
                    handleRegionSuccess();
                  } catch (e) {
                    setRegionSuccess(false);
                    handleErrorMessage(setErrorRegionMessage);
                  }
                  setRegionLoading(false);
                }
              }}
              options={regionOptionsWithDefault}
            />
            {regionSuccess && <Check className="text-primary ml-4 w-5" />}
            {errorRegionMessage && (
              <>
                <Ban className="ml-4 w-5 text-red-500" />
                <span className="ml-2 mt-3 text-red-500">
                  Region already defined
                </span>
              </>
            )}
          </div>
        )}
      </Cell>
    </tr>
  );
};

export default ClientAssignmentRow;
