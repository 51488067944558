import React from 'react';

const Heart: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={className}
    viewBox="0 0 15 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.76321 12.8841L2.17021 7.05077C1.67865 6.55961 1.35422 5.92614 1.24292 5.24022C1.13161 4.55431 1.2391 3.85076 1.55012 3.22936C1.78465 2.76043 2.12725 2.35394 2.54969 2.04338C2.97213 1.73281 3.46231 1.52707 3.97985 1.44311C4.4974 1.35914 5.02748 1.39934 5.52644 1.56041C6.0254 1.72148 6.47894 1.9988 6.84971 2.36953L7.76321 3.28244L8.67671 2.36953C9.04747 1.9988 9.50101 1.72148 9.99997 1.56041C10.4989 1.39934 11.029 1.35914 11.5466 1.44311C12.0641 1.52707 12.5543 1.73281 12.9767 2.04338C13.3992 2.35394 13.7418 2.76043 13.9763 3.22936C14.2869 3.85052 14.3942 4.55365 14.283 5.23918C14.1718 5.9247 13.8478 6.55788 13.3568 7.04902L7.76321 12.8841Z"
      fill="currentColor"
    />
  </svg>
);

export default Heart;
