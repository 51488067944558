import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  BulkDeleteRosters,
  BulkDeleteRostersVariables,
} from './__generated__/BulkDeleteRosters';

const BULK_DELETE_ROSTER = gql`
  mutation BulkDeleteRosters($rosterIds: [ID!]!) {
    bulkDeleteRosters(rosterIds: $rosterIds) {
      id
      __typename
    }
  }
`;

export const useBulkDeleteRosterAsAdminMutation = (
  options?: MutationHookOptions<BulkDeleteRosters, BulkDeleteRostersVariables>,
) =>
  useMutation<BulkDeleteRosters, BulkDeleteRostersVariables>(
    BULK_DELETE_ROSTER,
    {
      update(cache, { data }) {
        data?.bulkDeleteRosters.forEach((deletedRoster) => {
          const normalizedId = cache.identify({
            id: deletedRoster.id,
            __typename: 'Roster',
          });
          cache.evict({ id: normalizedId });
          cache.gc();
        });
      },
      ...options,
    },
  );

export default BULK_DELETE_ROSTER;
