import { ApolloError } from '@apollo/client';
import debounce from 'lodash.debounce';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ConflictsIndicator from 'src/components/ConflictsIndicator';
import Loading from 'src/components/Loading';
import Popover from 'src/components/Popover';
import { useCreateShiftRequestsMutation } from 'src/graphql/mutations/CreateShiftRequests';
import { useLazySearchTendersForShiftQuery } from 'src/graphql/queries/SearchTendersForShift';
import SHIFT_REQUESTS from 'src/graphql/queries/ShiftRequests';
import { SearchTendersForShift_searchTendersForShift_items as TenderItem } from 'src/graphql/queries/__generated__/SearchTendersForShift';
import { STAFFINGS_DEFAULT_PAGE_SIZE } from 'src/utils/constants';
import ClearableInput from '../ClearableInput';
import Pill from '../Pill/Pill';

type RequestTenderPopoverProps = {
  shiftId: string;
  country: string | undefined;
};

const TenderCard: React.FC<{
  isSelected?: boolean;
  onSelect?: (tender: TenderItem) => void;
  tender: TenderItem;
}> = ({ isSelected = false, onSelect, tender }) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    onSelect && onSelect(tender);
  };

  const isDisabled = !!tender.hardConflicts;
  const hasConflict = !!tender.hardConflicts || !!tender.softConflicts;

  return (
    <button
      className="text-ink-dark text-preset-6 border-support-line hover:border-primary disabled:hover:border-support-line flex min-h-[86px] items-center rounded border p-3"
      data-cy={`tender-result-${tender.id}`}
      data-testid="request-tender-tender-card"
      onClick={handleClick}
      type="button"
      disabled={isDisabled}
    >
      <div className="flex grow gap-2 self-start text-left">
        <input
          type="checkbox"
          checked={isSelected}
          className="text-brand-50 border-ink-dark border-1 disabled:bg-support-line disabled:border-support-line-darker h-4 w-4 rounded focus:ring-0"
          data-cy="select-tender-checkbox"
          disabled={isDisabled}
          readOnly
        />

        <div className="text-ink-not-as-dark">
          <span
            className={`text-preset-6 ${
              isDisabled ? 'text-ink-not-as-dark' : 'text-ink-dark'
            } mb-1 block font-medium `}
          >
            {tender.firstName} {tender.lastName}
          </span>

          <span className="text-preset-7 text-ink-not-as-dark mb-2 block font-semibold">
            {tender.address?.city}
            {tender.address?.state && `, ${tender.address?.state}`}
          </span>

          {tender.email && (
            <div className="text-preset-7 text-ink-not-as-dark w-48 shrink truncate">
              <span title={tender.email}>{tender.email}</span>
            </div>
          )}
        </div>
      </div>

      {hasConflict && (
        <div className="w-12 shrink-0">
          <ConflictsIndicator
            type={
              tender.hardConflicts
                ? 'hard'
                : tender.softConflicts
                ? 'soft'
                : undefined
            }
          />
        </div>
      )}

      {tender.score && (
        <div
          className="text-preset-7 text-ink-not-as-dark w-12 shrink-0"
          data-testid="request-tender-score"
        >
          {tender.score}
        </div>
      )}
    </button>
  );
};

const SearchInput: React.FC<{
  onChange?: (term?: string) => void;
  onClear?: () => void;
}> = ({ onClear, onChange, ...rest }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(e.target.value);
      onChange && onChange(e.target.value);
    },
    [onChange],
  );

  const handleClear = useCallback(() => {
    setSearchTerm('');
    onChange && onChange('');
    onClear && onClear();
  }, [onChange, onClear]);

  useEffect(() => {
    // focus the input when the popover is open preventing the page from scrolling to the top
    if (inputRef.current) {
      inputRef.current.focus({ preventScroll: true });
    }
  }, []);

  return (
    <ClearableInput
      className="border-support-line-darker rounded"
      onClear={handleClear}
    >
      <input
        className="focus:ring-primary focus:border-primary sm:text-preset-6 border-support-line-darker block w-full rounded-md px-8"
        onChange={handleOnChange}
        ref={inputRef}
        type="text"
        value={searchTerm}
        {...rest}
      />
    </ClearableInput>
  );
};

const RequestTenderPopover: React.FC<RequestTenderPopoverProps> = ({
  shiftId,
  country,
}) => {
  const [selectedTenders, setSelectedTenders] = useState<TenderItem[]>([]);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [triggerElement, setTriggerElement] =
    useState<HTMLButtonElement | null>(null);
  const panelElementRef = useRef<HTMLDivElement>(null);
  const [hasQuery, setHasQuery] = useState(false);

  const handleQuery = (searchTerm?: string) => {
    if (!searchTerm) {
      setHasQuery(false);
      return;
    }

    setHasQuery(true);

    return (
      searchTenders({
        variables: {
          paginationOptions: {
            limit: 5,
            page: 1,
          },
          searchTerm: searchTerm,
          shiftId: shiftId,
          ...(country && { countries: [country] }),
          excludeTendersWithoutAgreements: true,
        },
      }),
      300
    );
  };

  const handleQueryRef = useRef(handleQuery);

  const debouncedQuery = useMemo(() => {
    const func = (value?: string) => {
      handleQueryRef.current?.(value);
    };

    return debounce(func, 500);
  }, []);

  const handleQueryClear = () => {
    setHasQuery(false);
  };

  const [searchTenders, { data, loading: loadingSearch }] =
    useLazySearchTendersForShiftQuery({
      fetchPolicy: 'network-only',
    });

  const hasTenders = !!data?.searchTendersForShift.items.length;

  const [
    createShiftRequestMutation,
    { loading: loadingCreateShiftRequest, error: createShiftRequestError },
  ] = useCreateShiftRequestsMutation();

  const handlePopoverClose = useCallback(() => {
    setIsPopoverOpen(false);
    handleQueryClear();
    setSelectedTenders([]);
  }, []);

  const handleSelect = useCallback(
    (tender: TenderItem) => {
      if (selectedTenders.some((t) => t.id === tender.id)) {
        setSelectedTenders((prev) => prev.filter((t) => t.id !== tender.id));
      } else {
        setSelectedTenders((prev) => [...prev, tender]);
      }
    },
    [selectedTenders],
  );

  const handleRemoveSelectedTender = useCallback(
    (tender: TenderItem) => {
      setSelectedTenders((prev) => prev.filter((t) => t.id !== tender.id));
    },
    [setSelectedTenders],
  );

  const handleCloseOnEsc = useCallback(
    (evt) => {
      if (evt.key === 'Escape' && isPopoverOpen) {
        handlePopoverClose();
      }
    },
    [handlePopoverClose, isPopoverOpen],
  );

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (evt) => {
    evt.preventDefault();

    if (!selectedTenders) {
      return;
    }

    const refetchQueries = [
      {
        query: SHIFT_REQUESTS,
        variables: {
          pagination: { limit: STAFFINGS_DEFAULT_PAGE_SIZE, page: 1 },
          filters: {
            shiftId,
          },
        },
      },
    ];

    const selectedTendersIds = selectedTenders.map((t) => t.id);

    try {
      await createShiftRequestMutation({
        variables: {
          input: {
            shiftId: shiftId,
            tenderIds: selectedTendersIds,
          },
        },
        refetchQueries,
      });

      handlePopoverClose();
    } catch (e) {
      if (e instanceof ApolloError) {
        // We should do something here in terms of general error handling
      } else {
        throw e;
      }
    }
  };

  // clean up debounced query on unmount
  useEffect(() => {
    return () => {
      debouncedQuery.cancel();
    };
  }, [debouncedQuery]);

  return (
    <div className="-my-2" tabIndex={0} onKeyDown={handleCloseOnEsc}>
      <button
        ref={setTriggerElement}
        type="button"
        className="border-ink-dark text-preset-6 text-ink-dark bg-background-surface inline-flex items-center rounded-md border px-2.5 py-2 font-normal focus:outline-none"
        onClick={(evt) => {
          setIsPopoverOpen(!isPopoverOpen);
          evt.stopPropagation();
        }}
        data-cy="request-tender-button"
        data-testid="request-tender-button"
      >
        Request Tenders
      </button>

      <Popover
        isOpen={isPopoverOpen}
        triggerElement={triggerElement}
        panelElementRef={panelElementRef}
        onDismiss={handlePopoverClose}
        data-cy="request-tender-popover"
        data-testid="request-tender-popover"
      >
        <form
          aria-label="form"
          className="relative w-96 pt-8 pb-4"
          data-testid="request-tender-form"
          onSubmit={handleSubmit}
        >
          <div className="px-4">
            <label className="text-preset-5 text-ink-dark mb-2 block font-medium">
              Request Tenders
            </label>

            <SearchInput onChange={debouncedQuery} onClear={handleQueryClear} />
          </div>

          <hr className="my-4" />

          {!!selectedTenders.length && (
            <>
              <div
                className="flex min-h-[25px] flex-wrap gap-2 px-4"
                data-testid="request-tender-selection"
              >
                {selectedTenders.map((tender) => (
                  <Pill
                    key={tender.id}
                    onClose={() => handleRemoveSelectedTender(tender)}
                    toolTipText={tender.email || ''}
                    data-cy="request-tender-selection-pill"
                    data-testid="request-tender-selection-pill"
                  >
                    {tender.firstName} {tender.lastName}
                  </Pill>
                ))}
              </div>

              <hr className="my-4" />
            </>
          )}

          <div
            className="max-h-[465px] overflow-auto px-4"
            data-testid="request-tender-search-results"
          >
            {loadingSearch && (
              <div
                className="flex w-full justify-center"
                data-testid="request-tender-loading"
              >
                <Loading />
              </div>
            )}

            {!loadingSearch && hasQuery && hasTenders && (
              <div className="flex flex-col gap-2">
                {data?.searchTendersForShift.items.map((tender) => (
                  <TenderCard
                    key={tender.id}
                    tender={tender}
                    onSelect={handleSelect}
                    isSelected={selectedTenders.some((t) => t.id === tender.id)}
                  />
                ))}
              </div>
            )}
          </div>

          <div className="border-support-line-darker mt-4 w-full border-t px-4 pt-4">
            {createShiftRequestError && (
              <div className="mb-4">
                <p className="text-preset-6P text-status-destructive">
                  There was an error requesting tenders.
                </p>
              </div>
            )}

            <div className="inline-flex w-full items-center justify-end">
              <button
                type="button"
                disabled={loadingCreateShiftRequest}
                className="border-ink-dark text-preset-6 text-ink-dark focus:ring-ink-dark mr-4 inline-flex items-center rounded-md border bg-white px-3 py-2 font-normal focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50"
                onClick={handlePopoverClose}
              >
                Cancel
              </button>

              <button
                type="submit"
                disabled={
                  loadingCreateShiftRequest || selectedTenders.length === 0
                }
                className="text-preset-6 bg-brand-tend focus:ring-ransparent inline-flex items-center rounded-md border px-3 py-2 font-normal text-white focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50"
                data-cy="confirm-request-tender-button"
              >
                Request Tenders
              </button>
            </div>
          </div>
        </form>
      </Popover>
    </div>
  );
};

export default RequestTenderPopover;
