import React, { useState } from 'react';

import Navigation from 'src/components/Navigation';
import NavigationUserMenu from 'src/components/NavigationUserMenu';
import HamburgerIcon from 'src/components/Icons/Hamburger';

import logo from 'src/images/logo-tend.svg';
import usePageTitle from 'src/hooks/pageTitle';

const Shell: React.FC = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const sideBarText = usePageTitle();

  return (
    <div className="flex h-screen bg-white">
      <div className="mx-auto flex w-0 flex-1 flex-col">
        <div className="text-preset-5 fixed z-20 flex h-16 w-full flex-shrink-0 items-center border-b border-gray-200 bg-white">
          <button
            type="button"
            className="h-16 border-gray-200 px-4 text-gray-500 focus:outline-none"
            onClick={() => setSidebarOpen(true)}
            data-cy="main-navigation-button"
          >
            <span className="sr-only">Open sidebar</span>
            <HamburgerIcon />
          </button>
          <div className="text-preset-5 font-medium">{sideBarText}</div>
          <div className="flex flex-1 justify-between px-4 md:px-4">
            <div className="flex flex-1 items-center">
              <img className="mx-auto h-8 w-auto" src={logo} alt="Tend" />
            </div>
            <div className="ml-4 flex items-center md:ml-6">
              <NavigationUserMenu />
            </div>
          </div>
        </div>

        <main className="mt-16 flex-1 focus:outline-none" tabIndex={0}>
          {children}
        </main>
      </div>
      <Navigation sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
    </div>
  );
};

export default Shell;
