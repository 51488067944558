import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  CreateInvoice,
  CreateInvoiceVariables,
} from './__generated__/CreateInvoice';

const CREATE_INVOICE_MUTATION = gql`
  mutation CreateInvoice($input: CreateInvoiceInput!) {
    createInvoice(input: $input) {
      id
    }
  }
`;

export const useCreateInvoiceMutation = (
  options?: MutationHookOptions<CreateInvoice, CreateInvoiceVariables>,
) =>
  useMutation<CreateInvoice, CreateInvoiceVariables>(
    CREATE_INVOICE_MUTATION,
    options,
  );

export default CREATE_INVOICE_MUTATION;
