import { gql, QueryHookOptions, useLazyQuery } from '@apollo/client';
import { AdminJobs, AdminJobsVariables } from './__generated__/AdminJobs';

export const ADMIN_JOBS_QUERY = gql`
  query AdminJobs($pagination: PaginationOptions!, $options: JobsQueryOptions) {
    adminJobs(pagination: $pagination, options: $options) {
      items {
        id
        name
        startDateTime
        venue {
          address {
            city
            state
          }
        }
      }
    }
  }
`;

export const useAdminJobsQuery = (
  options?: QueryHookOptions<AdminJobs, AdminJobsVariables>,
) => useLazyQuery<AdminJobs, AdminJobsVariables>(ADMIN_JOBS_QUERY, options);

export default ADMIN_JOBS_QUERY;
