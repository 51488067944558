import React from 'react';
import { ShiftRequestStatus } from 'src/__generated__/globalTypes';

type StatusStyle = { color: string; backgroundColor: string };

const statusStyles = {
  primary: {
    color: 'text-ink-primary',
    backgroundColor: 'bg-status-positive-light',
  },
  secondary: {
    color: 'text-ink-secondary',
    backgroundColor: 'bg-status-info-light',
  },
  destructive: {
    color: 'text-status-destructive',
    backgroundColor: 'bg-status-destructive-light',
  },
  neutral: {
    color: 'text-ink-dark',
    backgroundColor: 'bg-support-line',
  },
};

const statusMap = new Map<
  ShiftRequestStatus | 'default',
  { label: string } & StatusStyle
>([
  [ShiftRequestStatus.ACCEPTED, { label: 'Accepted', ...statusStyles.primary }],
  [ShiftRequestStatus.PENDING, { label: 'Pending', ...statusStyles.secondary }],
  [
    ShiftRequestStatus.DECLINED,
    { label: 'Declined', ...statusStyles.destructive },
  ],
  [
    ShiftRequestStatus.CANCELLED,
    { label: 'Cancelled', ...statusStyles.destructive },
  ],
  [ShiftRequestStatus.EXPIRED, { label: 'Expired', ...statusStyles.neutral }],
  ['default', { label: '', ...statusStyles.neutral }],
]);

const RequestStatusBadge: React.FC<{
  status: ShiftRequestStatus;
}> = ({ status }) => {
  const statusConfig = statusMap.get(status) || statusMap.get('default');

  return (
    <span
      className={`text-preset-6 inline-flex items-center whitespace-nowrap rounded-full px-3 py-1.5 font-medium capitalize ${statusConfig?.backgroundColor} ${statusConfig?.color}`}
      data-testid="request-status-badge"
    >
      {statusConfig?.label || status.replace('_', ' ').toLowerCase()}
    </span>
  );
};

export default RequestStatusBadge;
