import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  UpdateShiftReliabilityPrediction,
  UpdateShiftReliabilityPredictionVariables,
} from './__generated__/UpdateShiftReliabilityPrediction';

const UPDATE_SHIFT_STAFFING_RELIABILITY_PREDICTION = gql`
  mutation UpdateShiftReliabilityPrediction($id: ID!) {
    updateShiftReliabilityPrediction(id: $id) {
      reliabilityPrediction
      reliabilityPredictionTimestamp
    }
  }
`;

export const useUpdateShiftStaffingReliabilityPredictionMutation = (
  options: MutationHookOptions<
    UpdateShiftReliabilityPrediction,
    UpdateShiftReliabilityPredictionVariables
  >,
) => {
  return useMutation<
    UpdateShiftReliabilityPrediction,
    UpdateShiftReliabilityPredictionVariables
  >(UPDATE_SHIFT_STAFFING_RELIABILITY_PREDICTION, options);
};

export default UPDATE_SHIFT_STAFFING_RELIABILITY_PREDICTION;
