import { ApolloError } from '@apollo/client';
import debounce from 'lodash.debounce';
import React, { useCallback, useRef, useState } from 'react';
import { FilterOperator } from 'src/__generated__/globalTypes';
import Combobox, { ComboboxRef } from 'src/components/Combobox';
import Search from 'src/components/Icons/Search';
import Loading from 'src/components/Loading';
import Popover from 'src/components/Popover';
import SpacedDot from 'src/components/SpacedDot';
import { useApplyForTenderMutation } from 'src/graphql/mutations/ApplyForTender';
import { useApproveTenderApplicationsMutation } from 'src/graphql/mutations/ApproveTenderApplications';
import APPROVED_JOB_APPLICATIONS from 'src/graphql/queries/ApprovedJobApplicationsPaginated';
import { useLazyTendersQuery } from 'src/graphql/queries/GetTenders';
import PENDING_JOB_APPLICATIONS from 'src/graphql/queries/PendingJobApplicationsPaginated';
import REMOVED_JOB_APPLICATIONS from 'src/graphql/queries/RemovedJobApplicationsPaginated';
import { AdminTenders_tenders_items as TenderItem } from 'src/graphql/queries/__generated__/AdminTenders';
import { STAFFINGS_DEFAULT_PAGE_SIZE } from 'src/utils/constants';

const ComboboxItemContainer: React.FC<{
  highlighted?: boolean;
  className?: string;
}> = ({ highlighted, className = '', children }) => (
  <div
    className={`relative cursor-default select-none py-2 px-4 ${
      highlighted && 'text-ink-not-as-dark bg-background-app'
    } ${className}`}
  >
    {children}
  </div>
);

const TenderCard: React.FC<{
  tender: TenderItem;
}> = ({ tender }) => (
  <div
    className="text-ink-dark text-preset-6"
    data-cy={`tender-result-${tender.id}`}
  >
    <div className="block truncate font-normal">
      {tender.firstName} {tender.lastName}
    </div>
    <div className="text-ink-not-as-dark leading-6">
      <span>
        {tender.address?.city}
        {tender.address?.state && `, ${tender.address?.state}`}
        {tender.regionName && `, ${tender.regionName}`}
      </span>
      {tender.email && (
        <>
          {tender.address && <SpacedDot />}
          <span>{tender.email}</span>
        </>
      )}
    </div>
  </div>
);

const Container: React.FC<{ className?: string }> = ({
  className = '',
  children,
}) => <div className={`px-4 ${className}`}>{children}</div>;

type ApplyForTenderPopoverProps = {
  shiftId: string;
  positionId: string;
  country: string | undefined;
};
const ApplyForTenderPopover: React.FC<ApplyForTenderPopoverProps> = ({
  shiftId,
  positionId,
  country,
}) => {
  const [selectedTender, setSelectedTender] = useState<TenderItem>();
  const [autoApprove, setAutoApprove] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [searchingTenders, setSearchingTenders] = useState(false);
  const [triggerElement, setTriggerElement] =
    useState<HTMLButtonElement | null>(null);
  const panelElementRef = useRef<HTMLDivElement>(null);
  const tendersComboboxRef = useRef<ComboboxRef>(null);
  const debouncedSave = useRef(
    debounce(
      (searchTerm: string) =>
        searchTenders({
          variables: {
            paginationOptions: {
              limit: 20,
              page: 1,
            },
            filters: [
              {
                property: 'notStaffedInShifts',
                operator: FilterOperator.not,
                values: [shiftId],
              },
              {
                property: 'searchTerm',
                operator: FilterOperator.equals,
                values: [searchTerm],
              },
              {
                property: 'positions',
                operator: FilterOperator.any,
                values: [positionId],
              },
              {
                property: 'countries',
                operator: FilterOperator.any,
                values: country ? [country] : [],
              },
            ],
            excludeTendersWithoutAgreements: true,
          },
        }),
      300,
    ),
  ).current;

  const [searchTenders, { data }] = useLazyTendersQuery({
    onCompleted: () => {
      setSearchingTenders(false);
    },
    onError: () => {
      setSearchingTenders(false);
    },
    fetchPolicy: 'network-only',
  });

  const [
    applyForTenderMutation,
    { loading: loadingApplyForTender, error: applyForTenderError },
  ] = useApplyForTenderMutation();
  const [
    approveTenderMutation,
    {
      loading: loadingApproveTenderApplication,
      error: approveTenderApplicationError,
    },
  ] = useApproveTenderApplicationsMutation();

  const handlePopoverClose = useCallback(() => {
    tendersComboboxRef.current?.reset();
    setIsPopoverOpen(false);
    setSelectedTender(undefined);
  }, []);
  const handleTenderSearch = (value?: string) => {
    if (!value) {
      return;
    }

    setSearchingTenders(true);
    debouncedSave(value);
  };
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (evt) => {
    evt.preventDefault();
    if (!selectedTender) {
      return;
    }

    const refetchQueries = [
      {
        query: APPROVED_JOB_APPLICATIONS,
        variables: {
          shiftId,
          pagination: { limit: STAFFINGS_DEFAULT_PAGE_SIZE, page: 1 },
        },
      },
      {
        query: PENDING_JOB_APPLICATIONS,
        variables: {
          shiftId,
          pagination: { limit: STAFFINGS_DEFAULT_PAGE_SIZE, page: 1 },
        },
      },
      {
        query: REMOVED_JOB_APPLICATIONS,
        variables: {
          shiftId,
          pagination: { limit: STAFFINGS_DEFAULT_PAGE_SIZE, page: 1 },
        },
      },
    ];

    try {
      const result = await applyForTenderMutation({
        variables: {
          tenderId: selectedTender.id,
          shiftId,
          markedForApproval: autoApprove,
        },
        refetchQueries: !autoApprove ? refetchQueries : undefined,
      });

      if (autoApprove && result.data) {
        await approveTenderMutation({
          variables: { staffingIds: [result.data.staffing.id] },
          refetchQueries,
        });
      }
      handlePopoverClose();
    } catch (e) {
      if (e instanceof ApolloError) {
        // We should do something here in terms of general error handling
      } else {
        throw e;
      }
    }
  };

  return (
    <div
      className="-my-2"
      tabIndex={0}
      onKeyDown={useCallback(
        (evt) => {
          if (
            evt.key === 'Escape' &&
            isPopoverOpen &&
            !tendersComboboxRef.current?.isOpen
          ) {
            handlePopoverClose();
          }
        },
        [handlePopoverClose, isPopoverOpen],
      )}
    >
      <button
        ref={setTriggerElement}
        type="button"
        className="border-ink-dark text-preset-6 text-ink-dark bg-background-surface inline-flex items-center rounded-md border px-2.5 py-2 font-normal focus:outline-none"
        onClick={(evt) => {
          setIsPopoverOpen(!isPopoverOpen);
          evt.stopPropagation();
        }}
        data-cy="apply-for-tender-button"
      >
        Apply for Tender
      </button>

      <Popover
        isOpen={isPopoverOpen}
        triggerElement={triggerElement}
        panelElementRef={panelElementRef}
        onDismiss={handlePopoverClose}
        data-cy="apply-for-tender-popover"
      >
        <form className="relative w-96 pt-8 pb-6" onSubmit={handleSubmit}>
          <Container>
            <Combobox
              autoFocus
              id="search-tender"
              refObject={tendersComboboxRef}
              label="Tender by name"
              items={data?.tenders.items ?? []}
              error={
                applyForTenderError?.message ||
                approveTenderApplicationError?.message
              }
              onChange={(tender) => tender && setSelectedTender(tender)}
              loadingItems={searchingTenders}
              onInputChange={handleTenderSearch}
              onReset={() => {
                setSelectedTender(undefined);
              }}
              renderLeadingComponent={() => (
                <Search
                  className="text-ink-not-as-dark h-3 w-3"
                  aria-hidden="true"
                />
              )}
              renderLoadingComponent={() => (
                <ComboboxItemContainer className="flex justify-center">
                  <Loading />
                </ComboboxItemContainer>
              )}
              renderNoResultsComponent={() => (
                <ComboboxItemContainer>No results found.</ComboboxItemContainer>
              )}
              renderItemComponent={({ item: tender, highlighted }) => (
                <ComboboxItemContainer highlighted={highlighted}>
                  <TenderCard tender={tender} />
                </ComboboxItemContainer>
              )}
              data-cy="search-for-tender-input"
            />
            {selectedTender && (
              <div className="rounded border px-6 py-4">
                <TenderCard tender={selectedTender} />
              </div>
            )}
          </Container>
          <Container className="border-support-line-darker mt-6 inline-flex w-full items-center border-t pt-4">
            <div className="relative flex flex-1 items-center">
              <div className="text-preset-5 mr-2">
                <label
                  htmlFor="auto-approve"
                  className="text-ink-dark font-medium"
                >
                  Auto-approve
                </label>
              </div>
              <div className="flex h-5 items-center">
                <input
                  id="auto-approve"
                  name="auto-approve"
                  type="checkbox"
                  checked={autoApprove}
                  onChange={() => setAutoApprove(!autoApprove)}
                  className="text-brand-50 border-ink-dark h-6 w-6 rounded border-2 focus:ring-0"
                  data-cy="auto-approve-checkbox"
                />
              </div>
            </div>
            <button
              type="button"
              disabled={
                loadingApplyForTender || loadingApproveTenderApplication
              }
              className="border-ink-dark text-preset-6 text-ink-dark focus:ring-ink-dark mr-4 inline-flex items-center rounded-md border bg-white px-3 py-2 font-normal focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50"
              onClick={handlePopoverClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={
                loadingApplyForTender || loadingApproveTenderApplication
              }
              className="text-preset-6 bg-brand-tend focus:ring-ransparent inline-flex items-center rounded-md border px-3 py-2 font-normal text-white focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50"
              data-cy="confirm-tender-button"
            >
              Apply
            </button>
          </Container>
        </form>
      </Popover>
    </div>
  );
};

export default ApplyForTenderPopover;
