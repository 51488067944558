import * as React from 'react';
import { SVGProps } from 'react';

const PreferenceMatchFull = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 28 28"
    width="1em"
    height="1em"
    {...props}
  >
    <circle cx={14} cy={14} r={14} fill="#045445" />
  </svg>
);

export default PreferenceMatchFull;
