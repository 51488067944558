import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { endOfDay, startOfDay, subMonths } from 'date-fns';
import DateRangeInput from 'src/components/DateRangeInput';
import Button from 'src/components/Button';
import {
  OrderByDirectionEnum,
  PayableStaffingFilters,
} from 'src/__generated__/globalTypes';
import Loading from 'src/components/Loading';
import { usePaidStaffedShiftsQuery } from 'src/graphql/queries/PaidStaffedShifts';
import Drawer from 'src/components/Drawer';
import AdvancedFilters from './AdvancedFilters';
import PaidStaffingsTable from './PaidStaffingsTable';

export const getStaffedShiftDescription = (
  description: string | null | undefined,
  truncate = false,
) => {
  return description
    ? truncate && description.length > 20
      ? description?.substring(0, 20) + '...'
      : description
    : null;
};
const getFilters = (searchParams: URLSearchParams): PayableStaffingFilters => {
  const staffingIdsParam = searchParams.get('staffingIds');
  const shiftIdsParam = searchParams.get('shiftIds');
  const jobIdsParam = searchParams.get('jobIds');
  const clientIdsParam = searchParams.get('clientIds');
  const tenderIdsParam = searchParams.get('tenderIds');
  const beforeParam = searchParams.get('before');
  const afterParam = searchParams.get('after');

  return {
    staffingIds:
      staffingIdsParam !== null
        ? { contains: staffingIdsParam.split(',') }
        : null,
    shiftIds:
      shiftIdsParam !== null ? { contains: shiftIdsParam.split(',') } : null,
    jobIds: jobIdsParam !== null ? { contains: jobIdsParam.split(',') } : null,
    clientIds:
      clientIdsParam !== null ? { contains: clientIdsParam.split(',') } : null,
    tenderIds:
      tenderIdsParam !== null ? { contains: tenderIdsParam.split(',') } : null,
    shift: {
      dates: {
        greaterThanOrEqual: afterParam && startOfDay(new Date(afterParam)),
        lessThanOrEqual: beforeParam && endOfDay(new Date(beforeParam)),
      },
    },
  };
};

const PaidStaffings: React.FC = () => {
  const [advancedFiltersOpen, setAdvancedFiltersOpen] = useState(false);
  const history = useHistory();
  const searchParams = useMemo(
    () => new URLSearchParams(history.location.search),
    [history.location.search],
  );
  const handleFilters = useCallback(
    (newFilters: Record<string, string | string[] | null>) => {
      Object.entries(newFilters).forEach(([filterName, filterValue]) => {
        searchParams.delete(filterName);

        if (Array.isArray(filterValue)) {
          if (filterValue.length) {
            searchParams.set(filterName, filterValue.join(','));
          }
        } else if (filterValue) {
          searchParams.set(filterName, filterValue);
        }
      });

      history.replace({
        search: `?${searchParams}`,
      });
    },
    [history, searchParams],
  );
  const handleDateChange = useCallback(
    (startDate?: Date, endDate?: Date) => {
      if (startDate && endDate) {
        handleFilters({
          after: startDate.toISOString(),
          before: endDate.toISOString(),
        });
      }
    },
    [handleFilters],
  );

  const [orderByField, setOrderByField] = useState('updatedAt');
  const [orderByDirection, setOrderByDirection] =
    useState<OrderByDirectionEnum>(OrderByDirectionEnum.DESC);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const filters = getFilters(searchParams);
  const hasDateFilters =
    !!filters.shift?.dates?.greaterThanOrEqual &&
    !!filters.shift?.dates?.lessThanOrEqual;
  const {
    data: paidStaffingsData,
    previousData,
    refetch,
    loading,
    error,
  } = usePaidStaffedShiftsQuery({
    variables: {
      paginationOptions: {
        limit: 20,
        page: pageNumber,
      },
      queryOptions: {
        filters,
        orderByField,
        orderByDirection,
      },
    },
  });

  useEffect(() => {
    const now = new Date();
    const defaultStartDate = subMonths(now, 1);
    if (!hasDateFilters) {
      handleDateChange(defaultStartDate, now);
    }
  }, [handleDateChange, hasDateFilters]);

  return (
    <div className="flex h-full flex-col overflow-auto">
      {loading && !previousData ? (
        <Loading width={60} className="m-auto mt-10 text-center" />
      ) : error ? (
        <p className="m-10">There was an error when requesting your data.</p>
      ) : (
        <>
          <div className="sticky top-0 z-10 overflow-x-visible">
            <div className="inline-block min-w-full align-middle">
              <div className="bg-background-surface flex items-center py-5 px-6">
                <h3 className="text-ink-dark flex-1 text-2xl font-light leading-7">
                  {paidStaffingsData?.paidStaffedShifts.meta.totalItems} Paid
                  Staffings
                </h3>
                {hasDateFilters && (
                  <DateRangeInput
                    className="mr-6"
                    onChange={handleDateChange}
                    startDate={filters.shift?.dates?.greaterThanOrEqual}
                    endDate={filters.shift?.dates?.lessThanOrEqual}
                  />
                )}
                <Button
                  className="border-ink-dark text-preset-5 text-ink-dark hover:border-ink-not-as-dark hover:text-ink-not-as-dark inline-flex h-11 items-center rounded-md border-2 px-4 font-medium"
                  onClick={() => setAdvancedFiltersOpen(true)}
                  data-cy="advanced-filters-button"
                >
                  Advanced Filters
                </Button>
              </div>
            </div>
          </div>
          {!(paidStaffingsData ?? previousData)?.paidStaffedShifts.meta
            .totalItems ? (
            <div>No items</div>
          ) : (
            <PaidStaffingsTable
              paidStaffingsData={paidStaffingsData ?? previousData}
              pageNumber={pageNumber}
              orderByField={orderByField}
              orderByDirection={orderByDirection}
              onSortChange={(field, direction) => {
                setOrderByField(field);
                setOrderByDirection(direction);
              }}
              onPageChange={(p) => setPageNumber(p)}
              onStaffingChanged={refetch}
            />
          )}
          <Drawer
            open={advancedFiltersOpen}
            onClose={() => setAdvancedFiltersOpen(false)}
            title="Advanced Filters"
            data-cy="advanced-filters-title"
          >
            <AdvancedFilters
              onChange={(newFilters) => {
                handleFilters(newFilters);
                setAdvancedFiltersOpen(false);
              }}
            />
          </Drawer>
        </>
      )}
    </div>
  );
};

export default PaidStaffings;
