import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ApolloError } from '@apollo/client';
import APPROVED_JOB_APPLICATIONS from 'src/graphql/queries/ApprovedJobApplicationsPaginated';
import REMOVED_JOB_APPLICATIONS from 'src/graphql/queries/RemovedJobApplicationsPaginated';
import { Await } from 'src/utilityTypes';
import { STAFFINGS_DEFAULT_PAGE_SIZE } from 'src/utils/constants';
import { useRemoveTenderApplicationsMutation } from 'src/graphql/mutations/RemoveTenderApplications';
import { ApprovedStaffingSelectionContext } from 'src/contexts/ApprovedStaffingsSelectionContext';
import { StaffingSelectionContext } from 'src/contexts/StaffingsSelectionContext';
import ConfirmationDialog from './ConfirmationDialog';
import ViewContainer from './ViewContainer';
import Button from './Button';

const ApprovedStaffingsSelectionToolbar: React.FC = () => {
  const selectedStaffingsContext = useContext(StaffingSelectionContext);
  const [activeDialog, setActiveDialog] = useState<'removeAllConfirmation'>();
  const closeDialog = () => setActiveDialog(undefined);
  const approvedSelectedStaffingsContext = useContext(
    ApprovedStaffingSelectionContext,
  );
  const {
    state: selectedStaffingsState,
    selectionCount,
    dispatch: selectedStaffingsDispatch,
  } = approvedSelectedStaffingsContext || {};
  const { selectionCount: staffingSelectionCount } =
    selectedStaffingsContext || {};
  const [bulkOperationErrors, setBulkOperationErrors] = useState<string[]>();
  const refetchQueries = useMemo(
    () =>
      Array.from(selectedStaffingsState?.keys() ?? [])
        .map((shiftId) => [
          {
            query: APPROVED_JOB_APPLICATIONS,
            variables: {
              shiftId,
              pagination: { limit: STAFFINGS_DEFAULT_PAGE_SIZE, page: 1 },
            },
          },
          {
            query: REMOVED_JOB_APPLICATIONS,
            variables: {
              shiftId,
              pagination: { limit: STAFFINGS_DEFAULT_PAGE_SIZE, page: 1 },
            },
          },
        ])
        .flat(),
    [selectedStaffingsState],
  );
  const [removeTenderStaffings, { loading }] =
    useRemoveTenderApplicationsMutation({
      refetchQueries,
      onCompleted: closeDialog,
    });
  const handleOperationInBulk = async (
    operation: typeof removeTenderStaffings,
  ) => {
    if (!selectedStaffingsState) {
      return;
    }
    setActiveDialog(undefined);

    let result: Await<ReturnType<typeof operation>> | undefined;
    let exception: ApolloError | undefined;

    try {
      result = await operation({
        variables: {
          staffingIds: Array.from(selectedStaffingsState.values())
            .map((staffingIdsSet) => Array.from(staffingIdsSet))
            .flat(),
        },
      });
    } catch (err) {
      if (err instanceof ApolloError) {
        exception = err;
      } else {
        throw err;
      }
    } finally {
      const mutationErrors =
        result?.data?.mutationPayload.errors?.map(
          (mutationError) => mutationError.message,
        ) ?? [];
      const requestErrors =
        exception?.graphQLErrors?.map((requestError) => requestError.message) ??
        [];
      const errors = mutationErrors.concat(requestErrors);
      setBulkOperationErrors(errors.length ? errors : undefined);

      selectedStaffingsDispatch?.({ type: 'clear' });
    }
  };

  /**
   * clear selected staffings when moving out of the view
   */
  useEffect(
    () => () => selectedStaffingsDispatch?.({ type: 'clear' }),
    [selectedStaffingsDispatch],
  );

  if (!selectionCount) {
    return null;
  }

  const bottomClassDistance =
    staffingSelectionCount !== undefined && staffingSelectionCount > 0 ? 51 : 0;

  return (
    <div
      className={
        'bg-background-surface border-support-line shadow-bottom-toolbar fixed bottom-0 w-full border-t'
      }
      style={{ bottom: bottomClassDistance }}
    >
      <ViewContainer className="py-2">
        <div className="flex">
          <div className="flex flex-1 items-center">
            <div className="text-ink-not-as-dark text-preset-6 ml-2 mr-6">
              {selectionCount} APPROVED Selected
            </div>
            <Button
              onClick={() => selectedStaffingsDispatch?.({ type: 'clear' })}
              disabled={loading}
            >
              Unselect all
            </Button>
          </div>
          <div className="flex">
            <Button
              destructive
              noBackground
              onClick={() => setActiveDialog('removeAllConfirmation')}
              disabled={loading}
            >
              Remove all
            </Button>
          </div>
        </div>
      </ViewContainer>

      <ConfirmationDialog
        title="Operation Errors"
        description={
          bulkOperationErrors
            ?.map((errorMessage) => `- ${errorMessage}`)
            .join('\n') ?? ''
        }
        open={!loading && Boolean(bulkOperationErrors?.length)}
        cancelText="Close"
        onClose={() => setBulkOperationErrors(undefined)}
      />
      <ConfirmationDialog
        title="Remove Tenders?"
        description="Remove all approved Tenders."
        open={activeDialog === 'removeAllConfirmation'}
        submitText="Remove all"
        onSubmit={() => handleOperationInBulk(removeTenderStaffings)}
        submitClassName="text-status-destructive"
        onClose={closeDialog}
      />
    </div>
  );
};

export default ApprovedStaffingsSelectionToolbar;
