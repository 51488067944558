import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  RemoveTenderTag,
  RemoveTenderTagVariables,
} from './__generated__/RemoveTenderTag';

const REMOVE_TENDER_TAG = gql`
  mutation RemoveTenderTag($tenderId: ID!, $tag: String!, $skillTag: Boolean) {
    removeTenderTag(tenderId: $tenderId, tag: $tag) {
      __typename
      id
      tags(onlyWhitelisted: true, skillTags: $skillTag)
    }
  }
`;

export const useRemoveTenderTagMutation = (
  options?: MutationHookOptions<RemoveTenderTag, RemoveTenderTagVariables>,
) =>
  useMutation<RemoveTenderTag, RemoveTenderTagVariables>(
    REMOVE_TENDER_TAG,
    options,
  );

export default REMOVE_TENDER_TAG;
