import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import apolloClient from 'src/ApolloClient';
import debounce from 'lodash.debounce';
import Combobox from 'src/components/Combobox';
import Search from 'src/components/Icons/Search';
import Loading from 'src/components/Loading';
import Pill from 'src/components/Pill';
import { AdminTenders_tenders_items as TenderItem } from 'src/graphql/queries/__generated__/AdminTenders';
import { useLazyTendersQuery } from 'src/graphql/queries/GetTenders';
import GET_TENDER from 'src/graphql/queries/GetTender';
import { FilterOperator } from 'src/__generated__/globalTypes';

const TenderResult: FC<{
  item: TenderItem;
  index: number;
  highlighted: boolean;
}> = ({ item, highlighted }) => {
  return (
    <div className={`py-2 px-4 ${highlighted ? 'bg-background-app' : ''}`}>
      <p className="text-preset-6 text-ink-dark font-medium">
        {item.firstName} {item.lastName}
      </p>
      <p className="text-preset-6 text-ink-not-as-dark">{item.id}</p>
    </div>
  );
};

type Props = {
  selectedTenderIds: string[];
  onChange: (selectedTenderIds: string[]) => void;
  className: string;
  label?: string;
  customPill?: React.FC<{ tender: TenderItem }>;
  errorMessage?: string;
  onInputChange?: (value: string | undefined) => void;
  inputValue?: string;
};

const TenderInput: FC<Props> = ({
  selectedTenderIds,
  onChange,
  className,
  label,
  customPill,
  errorMessage,
}) => {
  const [getTenders, { data: tendersData, loading: tendersLoading }] =
    useLazyTendersQuery();
  const [selectedTenders, setSelectedTenders] = useState<TenderItem[]>([]);
  const getAllSelectedTenders = useCallback(async () => {
    const tenders =
      (await Promise.all(
        selectedTenderIds
          .filter((id) => !!id)
          .map(async (tenderId) => {
            const { data } = await apolloClient.query({
              query: GET_TENDER,
              variables: {
                id: tenderId,
              },
            });
            return data?.tender;
          }),
      )) || [];
    setSelectedTenders(tenders);
  }, [selectedTenderIds]);
  useEffect(() => {
    getAllSelectedTenders();
  }, [getAllSelectedTenders]);

  const addTender = (tender: TenderItem) => {
    if (selectedTenders.some((selected) => selected.id === tender.id)) {
      return;
    }
    setSelectedTenders([...selectedTenders, tender]);
    onChange([...selectedTenderIds, tender.id]);
  };
  const removeTender = (tender: TenderItem) => {
    const removedIndex = selectedTenderIds.indexOf(tender.id);
    selectedTenderIds.splice(removedIndex, 1);
    selectedTenders.splice(removedIndex, 1);
    setSelectedTenders([...selectedTenders]);
    onChange([...selectedTenderIds]);
  };
  const tenderInputChange = useRef(
    debounce((value) => {
      if (!value) {
        return;
      }
      getTenders({
        variables: {
          paginationOptions: {
            limit: 25,
            page: 1,
          },
          filters: [
            {
              property: 'searchTerm',
              operator: FilterOperator.equals,
              values: [value],
            },
          ],
        },
      });
    }, 300),
  ).current;

  return (
    <div className={className}>
      <Combobox
        id="tendersCombobox"
        label={label ?? 'Tenders'}
        description="Search by Tender ID or Tender Name"
        items={tendersData?.tenders.items ?? []}
        loadingItems={tendersLoading}
        placeholder="Search by ID or name"
        renderLoadingComponent={() => (
          <div className="flex justify-center">
            <Loading />
          </div>
        )}
        renderNoResultsComponent={() => (
          <p className="py-2 px-4">No tenders found</p>
        )}
        renderLeadingComponent={() => (
          <Search className="text-ink-not-as-dark h-3 w-3" />
        )}
        onReset={() => null}
        renderItemComponent={TenderResult}
        onChange={(selectedItem) => selectedItem && addTender(selectedItem)}
        onInputChange={(value) => {
          tenderInputChange(value);
        }}
        data-cy="tender-filter"
      />
      <div className="selectedTenders flex flex-wrap">
        {selectedTenders.map((tender) => {
          return customPill ? (
            customPill({ tender })
          ) : (
            <Pill
              key={tender.id}
              onClose={() => removeTender(tender)}
              className="mr-2 mb-2"
              toolTipText={tender.id}
              data-cy={`pill-${tender.id}`}
            >
              {tender.firstName} {tender.lastName}
            </Pill>
          );
        })}
      </div>
      {errorMessage && (
        <p className="text-preset-7 text-status-destructive -mt-4 mb-2">
          * {errorMessage}
        </p>
      )}
    </div>
  );
};

export default TenderInput;
