import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import { Popover } from '@headlessui/react';
import TenderCard from 'src/components/TenderCard';

const TenderCell: React.FC<{
  tenderId: string;
  avatarURL?: string | null;
  firstName: string;
  lastName: string;
  detailText?: string;
  className?: string;
  truncateText?: boolean;
  hasAutoApprovalMatch?: boolean;
}> = ({
  tenderId,
  avatarURL,
  firstName,
  lastName,
  detailText,
  className,
  truncateText,
  hasAutoApprovalMatch,
  ...props
}) => {
  const [referenceElement, setReferenceElement] =
    useState<HTMLElement | null>();
  const [popperElement, setPopperElement] = useState<HTMLElement | null>();
  const { styles, attributes, update } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: 'right-end',
    },
  );
  const portalRoot = document.querySelector('#portal-root');
  return (
    <td className={className} data-testid="tender-cell" {...props}>
      <div className="flex items-center text-left">
        <Popover className="text-ink-dark relative px-4 py-2">
          <Popover.Button
            ref={setReferenceElement}
            className="focus:bg-background-app w-full focus:outline-none"
          >
            <div
              className="h-9 w-9 overflow-hidden rounded-full"
              data-cy="open-tender-card-button"
            >
              {avatarURL ? (
                <img
                  className="inline-block h-full w-full "
                  src={avatarURL}
                  alt="avatar"
                />
              ) : (
                <div className="bg-primary-light text-ink-primary flex h-full w-full flex-col justify-center text-center">
                  {firstName[0].toUpperCase()}
                  {lastName[0]?.toUpperCase()}
                </div>
              )}
            </div>
            {portalRoot &&
              createPortal(
                <Popover.Panel
                  ref={setPopperElement}
                  style={styles.popper}
                  {...attributes.popper}
                  className="z-30"
                >
                  <TenderCard tenderId={tenderId} popperUpdate={update} />
                </Popover.Panel>,
                portalRoot,
              )}
          </Popover.Button>
        </Popover>
        <div>
          <p
            className={`text-preset-6 mb-1 font-normal ${
              truncateText ? 'truncate' : ''
            }`}
          >
            {firstName} {lastName}
          </p>
          <p
            className={`text-preset-7 text-ink-not-as-dark font-normal ${
              truncateText ? 'truncate' : ''
            }`}
          >
            <span>{detailText}</span>
          </p>
          {hasAutoApprovalMatch && (
            <p className={'text-preset-7 text-ink-not-as-dark font-normal'}>
              <span>Auto approval match</span>
            </p>
          )}
        </div>
      </div>
    </td>
  );
};

export default TenderCell;
