import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  AddShiftToRosterMutation,
  AddShiftToRosterMutationVariables,
} from './__generated__/AddShiftToRosterMutation';

const ADD_SHIFT_TO_ROSTER = gql`
  mutation AddShiftToRosterMutation($rosterId: ID!, $shiftId: ID!) {
    addShiftToRoster(rosterId: $rosterId, shiftId: $shiftId) {
      __typename
      id
      placeHolderName
      tender {
        id
        firstName
        lastName
        email
        avatarURL
      }
      shift {
        id
        startDateTime
        endDateTime
        position {
          name
        }
      }
      roster {
        id
        job {
          id
          venue {
            address {
              timezone
            }
          }
        }
      }
    }
  }
`;

export const useAddShiftToRosterMutation = (
  options?: MutationHookOptions<
    AddShiftToRosterMutation,
    AddShiftToRosterMutationVariables
  >,
) =>
  useMutation<AddShiftToRosterMutation, AddShiftToRosterMutationVariables>(
    ADD_SHIFT_TO_ROSTER,
    options,
  );

export default ADD_SHIFT_TO_ROSTER;
