import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  DeleteShiftAsAdmin,
  DeleteShiftAsAdminVariables,
} from './__generated__/DeleteShiftAsAdmin';

const DELETE_SHIFT = gql`
  mutation DeleteShiftAsAdmin($id: ID!) {
    deleteShift(id: $id) {
      __typename
      id
    }
  }
`;

export const useDeleteShiftAsAdminMutation = (
  options?: MutationHookOptions<
    DeleteShiftAsAdmin,
    DeleteShiftAsAdminVariables
  >,
) =>
  useMutation<DeleteShiftAsAdmin, DeleteShiftAsAdminVariables>(DELETE_SHIFT, {
    update(cache, { data }) {
      const normalizedId = cache.identify({
        id: data?.deleteShift.id,
        __typename: 'Shift',
      });
      cache.evict({ id: normalizedId });
      cache.gc();
    },
    ...options,
  });

export default DELETE_SHIFT;
