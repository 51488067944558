import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  AddTenderNote,
  AddTenderNoteVariables,
} from './__generated__/AddTenderNote';

const ADD_TENDER_NOTE = gql`
  mutation AddTenderNote($tenderId: ID!, $note: String!) {
    addTenderNote(tenderId: $tenderId, note: $note) {
      tenderNote {
        id
        createdAt
        note
      }
    }
  }
`;

export const useAddTenderNoteMutation = (
  options?: MutationHookOptions<AddTenderNote, AddTenderNoteVariables>,
) =>
  useMutation<AddTenderNote, AddTenderNoteVariables>(ADD_TENDER_NOTE, options);

export default ADD_TENDER_NOTE;
