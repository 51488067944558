import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import DotsHorizontal from 'src/components/Icons/DotsHorizontal';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import TrashIcon from 'src/components/Icons/Trash';
import { useDeleteRosterAsAdminMutation } from 'src/graphql/mutations/DeleteRoster';

const RosterMoreOptionsMenu: React.FC<{
  className?: string;
  rosterId: string;
}> = ({ className, rosterId }) => {
  const [isDeleteRosterOpen, setIsDeleteRosterOpen] = useState(false);
  const [deleteMutation, { loading: deleteLoading }] =
    useDeleteRosterAsAdminMutation();
  const history = useHistory();

  const handleSubmit = async () => {
    await deleteMutation({ variables: { rosterId } });
    setIsDeleteRosterOpen(false);
    history.replace('/rosters');
  };

  return (
    <>
      <Menu as="div" className="relative">
        <Menu.Button className={`text-ink-not-as-dark ${className}`}>
          <DotsHorizontal className="h-6 w-6" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="bg-background-surface shadow-floating-menu text-ink-dark absolute right-0 mt-2 rounded py-2">
            <Menu.Item as={Fragment}>
              {({ active }) => (
                <button
                  onClick={() => setIsDeleteRosterOpen(true)}
                  className={`${active ? 'bg-background-app' : ''} w-full`}
                >
                  <div className="text-preset-6 text-status-destructive flex h-10 items-center whitespace-nowrap px-4 py-2">
                    <TrashIcon className="mr-2 w-4" />
                    <p>Delete Roster</p>
                  </div>
                </button>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
      <ConfirmationDialog
        open={isDeleteRosterOpen}
        title="Delete Roster"
        description="The roster and all the assigned tenders will be deleted permanently."
        cancelText="Cancel"
        submitText="Delete"
        submitClassName={`text-status-destructive ${
          deleteLoading ? 'opacity-50' : ''
        }`}
        onSubmit={handleSubmit}
        onClose={() => setIsDeleteRosterOpen(false)}
      />
    </>
  );
};

export default RosterMoreOptionsMenu;
