import React, { useEffect, useState } from 'react';
import { useForm, Controller, ErrorOption, Control } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Modal from 'src/components/Modal';
import TenderFilter from 'src/components/Filters/TenderFilter';
import { formInputClass, formLabelClass } from 'src/components/Forms';
import ClearableInput from 'src/components/ClearableInput';
import { AdminTenders_tenders_items as TenderItem } from 'src/graphql/queries/__generated__/AdminTenders';
import { validateUUID } from 'src/utils/uuid';
import { useLazyGetShiftQuery } from 'src/graphql/queries/GetShift';
import { useCreateBonusPayoutMutation } from 'src/graphql/mutations/CreateBonusPayout';

type FormValues = {
  tenderId: string;
  shiftId?: string | null;
  hasShiftAttached?: 'true' | 'false' | '';
  amount: number;
  clientAmount?: number;
  description?: string | null;
};
const formSchema = Yup.object().shape({
  tenderId: Yup.string().required('Select a Tender'),
  shiftId: Yup.string().when('hasShiftAttached', {
    is: 'true',
    then: Yup.string().required(
      'Select a shift or change to the no shift attached option',
    ),
  }),
  description: Yup.string()
    .required('Add a description')
    .max(100, 'Please input a description of less than 100 chars'),
  hasShiftAttached: Yup.string()
    .oneOf(['true', 'false'], 'Select an option')
    .required('Select an option'),
  amount: Yup.number().min(1, "Amount can't be zero").required(),
  clientAmount: Yup.number().min(0),
});

const MoneyInput: React.FC<{
  className?: string;
  control: Control;
  name: string;
  label: string;
}> = ({ className, control, name, label }) => {
  return (
    <div className={className}>
      <label htmlFor={name} className="text-preset-5 text-ink mb-2 block">
        {label}
      </label>
      <div className="relative mt-1 rounded-md shadow-sm">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <span className="text-ink-not-as-dark sm:text-sm">$</span>
        </div>
        <Controller
          control={control}
          name={name}
          render={(props) => (
            <input
              {...props}
              type="number"
              min="0"
              name={name}
              id={name}
              className={`text-ink-not-as-dark block w-full pl-7 pr-12 ${formInputClass}`}
              placeholder="0.00"
              aria-describedby="price-currency"
            />
          )}
        />
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <span className="text-gray-500 sm:text-sm" id="price-currency">
            USD
          </span>
        </div>
      </div>
    </div>
  );
};

const TenderData: React.FC<{ tender: TenderItem }> = ({ tender }) => {
  const { id, firstName, lastName, avatarURL, email, address } = tender;
  const { city, state } = address || { city: '', state: '' };
  return (
    <div
      key={id}
      className="border-support-line-darker bg-background-app flex w-full items-center rounded-lg border p-6 pt-4"
    >
      <div className="mr-3 h-12 w-12 overflow-hidden rounded-full">
        {avatarURL ? (
          <img
            className="inline-block h-full w-full "
            src={avatarURL}
            alt="avatar"
          />
        ) : (
          <div className="bg-primary-light text-ink-primary text-preset-4 flex h-full w-full flex-col justify-center text-center font-semibold">
            {firstName && firstName[0]}
            {lastName && lastName[0]}
          </div>
        )}
      </div>
      <div>
        <p className="text-ink text-preset-6 mt-3 font-medium">
          {firstName} {lastName} {city || state ? '•' : ''}{' '}
          {city ? `${city}, ` : ''} {state ? state : ''}
        </p>
        {email && (
          <p className="text-ink-not-as-dark text-preset-6 mt-1.5">{email}</p>
        )}
        <p className="text-ink-not-as-dark text-preset-6 mt-1.5 mb-1">{id}</p>
      </div>
    </div>
  );
};

const ShiftData: React.FC<{
  setValue: (name: 'shiftId', value: string) => void;
  setError: (name: 'shiftId', error: ErrorOption) => void;
  clearErrors: (name: 'shiftId') => void;
  shiftId?: string;
  tenderId?: string;
}> = ({ setValue, setError, clearErrors, shiftId, tenderId }) => {
  const [getShift, { data: shiftData, error }] = useLazyGetShiftQuery();

  useEffect(() => {
    if (shiftId && !validateUUID(shiftId)) {
      return setError('shiftId', { message: 'Not a valid UUID' });
    }

    if (shiftId && tenderId && shiftId.length > 0) {
      getShift({
        variables: {
          id: shiftId,
          workedByTenderId: tenderId,
        },
      });
    }
  }, [shiftId, tenderId, getShift, setError]);

  useEffect(() => {
    if (error) {
      setError('shiftId', {
        message: 'A shift for selected tender was not found.',
      });
    } else if (shiftData) {
      clearErrors('shiftId');
      setValue('shiftId', shiftData.shift.id);
    }
  }, [error, setError, shiftData, setValue, clearErrors]);

  const infoText = !tenderId
    ? 'You must select a tender.'
    : !shiftId || !shiftData
    ? 'Select a shift to attach this bonus payment'
    : undefined;

  return (
    <div className="border-support-line-darker bg-background-app mt-4 flex w-full items-center rounded-lg border p-6">
      {infoText || !shiftData ? (
        <p className="text-ink-not-as-dark text-preset-6">{infoText}</p>
      ) : (
        <div>
          <p className="text-ink text-preset-6 font-medium">
            {shiftData.shift.position.name}
          </p>
          <p className="text-ink-not-as-dark text-preset-6 mt-2">
            {shiftData.shift.id}
          </p>
          <p className="text-ink-not-as-dark text-preset-6 mt-1.5 mb-1">
            {shiftData.shift.job.name} • {shiftData.shift.job.id}
          </p>
          {shiftData.shift.job.client?.email && (
            <p className="text-ink-not-as-dark text-preset-6 mt-1.5 mb-1">
              {shiftData.shift.job.client.email}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

const CreateTenderPayoutModal: React.FC<{
  modalOpen: boolean;
  onPayoutCreated: () => void;
  setModalOpen: (modalOpen: boolean) => void;
}> = ({ setModalOpen, modalOpen, onPayoutCreated }) => {
  const [shiftIdSearch, setShiftIdSearch] = useState<string>('');
  const [createBonusPayoutMutation, { loading }] =
    useCreateBonusPayoutMutation();

  const {
    register,
    errors,
    handleSubmit,
    control,
    setError,
    setValue,
    clearErrors,
    reset,
    watch,
  } = useForm<FormValues>({
    mode: 'onBlur',
    resolver: yupResolver(formSchema),
    defaultValues: {
      amount: 0,
      description: '',
      hasShiftAttached: '',
      clientAmount: 0,
      shiftId: '',
      tenderId: '',
    },
  });
  const handleReset = () => {
    reset({
      hasShiftAttached: '',
      amount: 0,
      description: '',
      shiftId: '',
      tenderId: '',
    });
    setShiftIdSearch('');
  };
  const watchHasShiftAttached = watch('hasShiftAttached');
  const watchDescription = watch('description');
  const watchTenderId = watch('tenderId');

  register('shiftId');
  return (
    <Modal
      open={modalOpen}
      onClose={() => {
        handleReset();
        setModalOpen(false);
      }}
      className="sm:w-4/5 md:w-1/2 lg:w-2/5"
    >
      <div className="border-support-line border-b px-6 py-4">
        <h2 className="text-preset-3 text-ink mb-4">Create Bonus Payout</h2>
        <p className="text-preset-5P text-ink mb-2">
          Select Tender and fill the information for the bonus payment
        </p>
      </div>
      <form className="h-96 w-full overflow-auto px-6 py-4">
        <Controller
          control={control}
          name="tenderId"
          defaultValue={undefined}
          render={({ ref, ...props }) => (
            <TenderFilter
              {...props}
              onChange={(tenderIds) => {
                const filteredTenderIds = tenderIds.filter((id) => !!id);
                const tenderId =
                  filteredTenderIds[filteredTenderIds.length - 1];
                props.onChange(tenderId);
              }}
              className={
                errors.tenderId
                  ? 'focus:ring-status-destructive-light focus:border-status-destructive'
                  : 'focus:ring-primary focus:border-primary'
              }
              errorMessage={errors.tenderId?.message}
              label="Tender"
              selectedTenderIds={[watchTenderId]}
              customPill={TenderData}
            />
          )}
        />
        <div className="border-support-line -mx-6 mb-3 border-b pt-2" />
        <div className="flex items-center">
          <p className="text-preset-5 text-ink my-6">
            Is it attached to a shift?
          </p>
          <Controller
            control={control}
            name="hasShiftAttached"
            render={(props) => (
              <>
                <input
                  {...props}
                  className="ml-4 mr-2"
                  type="radio"
                  id="attachedShiftYes"
                  value="true"
                />
                <label htmlFor="attachedShiftYes">Yes</label>
                <input
                  {...props}
                  className="ml-4 mr-2"
                  type="radio"
                  id="attachedShiftNo"
                  value="false"
                />
                <label htmlFor="attachedShiftNo">No</label>
              </>
            )}
          />
        </div>
        {errors.hasShiftAttached && (
          <p className="text-preset-7 text-status-destructive -my-6 mb-2">
            * {errors.hasShiftAttached.message}
          </p>
        )}
        <div className="jobs border-support-line -mx-6 mb-3 border-b pt-2" />
        {watchHasShiftAttached === 'true' && (
          <>
            <div className="">
              <label htmlFor="shiftIdSearch" className={formLabelClass}>
                Shift id
              </label>
              <div className="text-preset-5 mt-1 mb-4 font-medium text-gray-700">
                <ClearableInput
                  onClear={() => {
                    setShiftIdSearch('');
                  }}
                >
                  <input
                    id="shiftIdSearch"
                    type="text"
                    onChange={(event) => setShiftIdSearch(event.target.value)}
                    value={shiftIdSearch}
                    className={`px-8 ${formInputClass}`}
                  />
                </ClearableInput>
                <ShiftData
                  setValue={setValue}
                  setError={setError}
                  clearErrors={clearErrors}
                  shiftId={shiftIdSearch}
                  tenderId={watchTenderId}
                />
                {errors.shiftId && (
                  <p className="text-preset-7 text-status-destructive mb-2 mt-2">
                    * {errors.shiftId.message}
                  </p>
                )}
              </div>
              <MoneyInput
                className="mb-4 w-48"
                control={control}
                name="clientAmount"
                label="Bill Client for"
              />
              {errors.clientAmount && (
                <p className="text-preset-7 text-status-destructive mb-2">
                  * {errors.clientAmount.message}
                </p>
              )}
            </div>
            <div className="border-support-line -mx-6 mb-3 border-b pt-2" />
          </>
        )}
        <MoneyInput
          className="mb-4 w-48"
          control={control}
          label="Amount"
          name="amount"
        />
        {errors.amount && (
          <p className="text-preset-7 text-status-destructive mb-2">
            * {errors.amount.message}
          </p>
        )}
        <div className="border-support-line -mx-6 mb-3 border-b pt-2" />
        <div>
          <label htmlFor="description">Description</label>
          <textarea
            ref={register}
            name="description"
            id="description"
            className={formInputClass}
          />
          <div className="flex justify-between">
            {errors.description ? (
              <p className="text-preset-7 text-status-destructive mb-2">
                * {errors.description.message}
              </p>
            ) : (
              <p />
            )}
            <p className="text-preset-7 text-ink-not-as-dark">
              {watchDescription?.length ?? 0}/100
            </p>
          </div>
        </div>
      </form>
      <div className="border-support-line flex w-full justify-end border-t p-4">
        <button
          type="button"
          className="border-support-line rounded-md px-4 py-2"
          onClick={() => {
            handleReset();
            setModalOpen(false);
          }}
        >
          Cancel
        </button>
        <button
          type="button"
          disabled={loading}
          onClick={handleSubmit(async ({ hasShiftAttached: _, ...input }) => {
            await createBonusPayoutMutation({
              variables: {
                input: {
                  ...input,
                  amount: (input.amount ?? 0) * 100,
                  chargeToClient: !!input.clientAmount,
                  clientAmount: (input.clientAmount ?? 0) * 100,
                },
              },
            });
            onPayoutCreated();
            setModalOpen(false);
            handleReset();
          })}
          className="text-preset-5 bg-primary text-ink-clear hover:bg-primary-active h-9 rounded px-5 font-medium"
        >
          Create Bonus
        </button>
      </div>
    </Modal>
  );
};

export default CreateTenderPayoutModal;
