import React, { useState } from 'react';

import { Transition } from '@headlessui/react';
import { format as formatDate } from 'date-fns-tz';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import {
  PaymentStatus,
  TenderPaymentStatus,
  TenderPaymentType,
} from 'src/__generated__/globalTypes';
import Adjustment from 'src/components/Icons/Adjustment';
import Gift from 'src/components/Icons/Gift';
import Pill from 'src/components/Pill';
import {
  GetTenderStaffingsForAdmin_staffingsPaginatedByOffset_items_tenderPayments,
  GetTenderStaffingsForAdmin_staffingsPaginatedByOffset_items as Staffing,
} from 'src/graphql/queries/__generated__/GetTenderStaffingsForAdmin';
import { formatMoney } from 'src/utils/formatMoney';

const getStaffingPaymentDetails = (staffing: Staffing) => {
  const { staffedShift: staffedShiftData, tenderPayments } = staffing;

  if (!staffedShiftData) {
    return null;
  }

  const filteredPayments =
    tenderPayments
      ?.filter(
        (payment) =>
          payment.status === TenderPaymentStatus.SENT &&
          (payment.type === TenderPaymentType.STANDARD ||
            payment.type === TenderPaymentType.BONUS) &&
          payment.amount &&
          payment.amount > 0,
      )
      .sort((a, b) => {
        return new Date(a.sentAt) < new Date(b.sentAt) ? -1 : 1;
      }) || [];

  return {
    status: staffedShiftData.paymentStatus,
    amount: staffedShiftData.actualTenderPayout,
    payments: filteredPayments,
  };
};

const getStaffingPaymentPillStatus = (status: PaymentStatus) => {
  switch (status) {
    case PaymentStatus.PAID:
      return 'positive';
    case PaymentStatus.VERIFIED:
      return 'info';
    case PaymentStatus.UNVERIFIED:
      return 'warning';
    case PaymentStatus.WONTPAY:
      return 'destructive';
    case PaymentStatus.ONHOLD:
    default:
      return 'none';
  }
};

const getStaffingPaymentPillText = (status: PaymentStatus) => {
  switch (status) {
    case PaymentStatus.PAID:
      return 'Paid';
    case PaymentStatus.VERIFIED:
      return 'Verified';
    case PaymentStatus.UNVERIFIED:
      return 'Unverified';
    case PaymentStatus.ONHOLD:
      return 'On Hold';
    case PaymentStatus.WONTPAY:
      return 'Won’t Pay';
    default:
      return status;
  }
};

interface PaymentStatusPopoverProps {
  staffing: Staffing;
}

const PaymentStatusPopover: React.FunctionComponent<PaymentStatusPopoverProps> =
  ({ staffing }) => {
    const paymentDetails = getStaffingPaymentDetails(staffing);
    const { status, amount = 0, payments } = paymentDetails || {};
    const [isHovering, setIsHovering] = useState(false);
    const [referenceElement, setReferenceElement] =
      React.useState<HTMLElement | null>();
    const [popperElement, setPopperElement] =
      React.useState<HTMLElement | null>(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
      placement: 'bottom-start',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
        {
          name: 'flip',
          options: {
            padding: 32,
          },
        },
      ],
    });

    type TenderPayment =
      GetTenderStaffingsForAdmin_staffingsPaginatedByOffset_items_tenderPayments;

    interface Details {
      adjustmentsCount: number;
      bonusesCount: number;
      basePayment?: TenderPayment;
      baseLabel: string;
      otherPayments?: TenderPayment[];
      hasPopover: boolean;
      isSingle: boolean;
      totalPaymentAmount: number;
      totalPaymentLabel: string;
    }

    // For PAID staffings, the first payment is the base payment
    // this is actually the only way to determine this.
    // From Michael Powell notes in the requirements ticket:
    // "...rank over the tender payments by staffing and in order of sent_at date
    // for sent payments, the first payment in the series if type standard is the ORIGINAL payment"
    const basePayment = payments && payments[0];

    const details: Details = {
      adjustmentsCount:
        payments && payments.length > 1
          ? payments.filter(
              (payment) => payment.type === TenderPaymentType.STANDARD,
            ).length - 1
          : 0,
      baseLabel: '',
      basePayment: basePayment,
      bonusesCount:
        (payments &&
          payments.filter((payment) => payment.type === TenderPaymentType.BONUS)
            .length) ||
        0,
      hasPopover: false,
      isSingle: false,
      otherPayments:
        (payments && payments.length > 1 && payments?.slice(1)) || undefined,
      totalPaymentAmount: 0,
      totalPaymentLabel: '',
    };

    switch (status) {
      case PaymentStatus.PAID:
        details.baseLabel = 'Shift Payment';
        details.hasPopover = true;
        details.totalPaymentAmount = payments
          ? payments.reduce((acc, payment) => acc + (payment.amount || 0), 0)
          : 0;
        details.totalPaymentLabel = 'Total Paid:';
        break;
      case PaymentStatus.VERIFIED:
        details.hasPopover = true;
        details.isSingle = true;
        details.totalPaymentAmount = amount;
        details.totalPaymentLabel = 'Amount:';
        break;
      case PaymentStatus.UNVERIFIED:
        details.hasPopover = true;
        details.isSingle = true;
        details.totalPaymentAmount = amount;
        details.totalPaymentLabel = 'Est. Amount:';
        break;
      case PaymentStatus.ONHOLD:
      case PaymentStatus.WONTPAY:
        details.basePayment = undefined;
        details.bonusesCount =
          payments?.filter(
            (payment) => payment.type === TenderPaymentType.BONUS,
          ).length || 0;
        details.adjustmentsCount =
          payments?.filter(
            (payment) => payment.type === TenderPaymentType.STANDARD,
          ).length || 0;
        details.otherPayments = payments;
        details.hasPopover = Array.isArray(payments) && payments.length > 0;
        details.totalPaymentAmount = payments
          ? payments.reduce((acc, payment) => acc + (payment.amount || 0), 0)
          : 0;
        details.totalPaymentLabel = 'Total Paid:';
        break;

      default:
        break;
    }

    if (!paymentDetails) {
      return null;
    }

    const portalRoot = document.querySelector('#portal-root');

    const Row = ({
      label,
      payment,
      isAdditional = false,
    }: {
      label: string;
      payment: GetTenderStaffingsForAdmin_staffingsPaginatedByOffset_items_tenderPayments;
      isAdditional?: boolean;
    }) => (
      <div className="mb-3">
        <div className="text-ink-dark mb-1 flex items-center justify-between">
          {!isAdditional && (
            <span className="text-preset-7 block font-medium">{label}:</span>
          )}

          {isAdditional && payment.type === TenderPaymentType.STANDARD && (
            <div className="text-ink-primary flex gap-0.5">
              <Adjustment className="mr-0.5 h-4 w-4" />
              <span className="text-preset-7 block font-medium">
                Additional:
              </span>
            </div>
          )}

          {isAdditional && payment.type === TenderPaymentType.BONUS && (
            <div className="text-ink-primary flex gap-0.5">
              <Gift className="mr-0.5 h-4 w-4" />
              <span className="text-preset-7 block font-medium">Bonus:</span>
            </div>
          )}

          {isAdditional ? (
            <span className="text-preset-6 block font-medium text-green-600">
              +{formatMoney(payment.amount || 0)}
            </span>
          ) : (
            <span className="text-preset-6 block font-medium">
              {formatMoney(payment.amount || 0)}
            </span>
          )}
        </div>

        {payment.displayDate && (
          <span className="text-preset-7 text-ink-not-as-dark block">
            {formatDate(new Date(payment.displayDate), 'MM/dd/yyyy')}
          </span>
        )}
      </div>
    );

    return (
      <div
        className="flex flex-wrap gap-1 py-1"
        ref={setReferenceElement}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        {paymentDetails?.status && (
          <Pill status={getStaffingPaymentPillStatus(paymentDetails?.status)}>
            {getStaffingPaymentPillText(paymentDetails?.status)}
          </Pill>
        )}

        {details.adjustmentsCount > 0 ? (
          <Pill status="positive">
            <span className="flex flex-nowrap">
              <Adjustment className="mr-0.5 h-4 w-4" />
              {details.adjustmentsCount}
            </span>
          </Pill>
        ) : null}

        {details.bonusesCount > 0 && (
          <Pill status="positive">
            <span className="flex flex-nowrap">
              <Gift className="mr-0.5 h-4 w-4" />
              {details.bonusesCount}
            </span>
          </Pill>
        )}

        {details.hasPopover &&
          portalRoot &&
          createPortal(
            <Transition
              show={isHovering}
              enter="transition-opacity duration-300"
              enterFrom="opacity-0"
              enterTo={'opacity-100'}
              leave="transition-opacity duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                className="border-support-line-darker absolute top-0 left-0 z-50 w-56 cursor-default rounded-lg border border-solid bg-white p-4 shadow-lg"
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
              >
                {details.basePayment && (
                  <Row
                    label={details.baseLabel}
                    payment={details.basePayment}
                  />
                )}

                {details.otherPayments &&
                  details.otherPayments.map((payment) => (
                    <Row
                      label="Shift Payment:"
                      payment={payment}
                      isAdditional
                    />
                  ))}

                {(details.basePayment || details.otherPayments) && (
                  <hr className="border-support-line my-3" />
                )}

                {details.totalPaymentAmount > 0 && details.totalPaymentLabel && (
                  <div className={`${!details.isSingle && 'mt-2'}`}>
                    <div className="text-ink-dark flex items-center justify-between">
                      <span
                        className={`text-preset-6 font-medium ${
                          details.isSingle
                            ? 'text-ink-not-as-dark'
                            : 'text-ink-dark'
                        }`}
                      >
                        {details.totalPaymentLabel}
                      </span>

                      <span
                        className={`
                      text-preset-6 ${
                        details.isSingle ? 'font-normal' : 'font-bold'
                      }`}
                      >
                        {formatMoney(details.totalPaymentAmount)}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </Transition>,
            portalRoot,
          )}
      </div>
    );
  };

export default PaymentStatusPopover;
