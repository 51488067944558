import React, { FC, useState } from 'react';
import { usePopper } from 'react-popper';

const statusBgMap = {
  info: 'bg-status-info-light',
  positive: 'bg-status-positive-light',
  destructive: 'bg-status-destructive-light',
  warning: 'bg-status-warning-light',
  none: 'bg-gray-100',
};
const statusTextMap = {
  info: 'text-status-info',
  positive: 'text-status-positive',
  destructive: 'text-status-destructive',
  warning: 'text-ink-dark',
  none: 'text-gray-800',
};

interface Props {
  toolTipText?: string;
  className?: string;
  onClose?: () => void;
  status?: 'info' | 'positive' | 'destructive' | 'warning' | 'none';
  bgColor?: string;
  textColor?: string;
  'data-cy'?: string;
  'data-testid'?: string;
}

const Pill: FC<Props> = ({
  children,
  className,
  onClose,
  toolTipText,
  status = 'info',
  bgColor,
  textColor,
  ...props
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipTriggerElement, setTooltipTriggerElement] =
    useState<HTMLDivElement | null>(null);
  const [tooltipElement, setTooltipElement] = useState<HTMLDivElement | null>(
    null,
  );
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(
    tooltipTriggerElement,
    tooltipElement,
    {
      placement: 'top',
      modifiers: [
        { name: 'offset', options: { offset: [0, 6] } },
        { name: 'arrow', options: { element: arrowElement } },
      ],
    },
  );

  return (
    <>
      <div
        className={`relative inline-block max-w-full rounded-full px-2 py-1 ${
          bgColor || statusBgMap[status]
        } ${className || ''}`}
        ref={setTooltipTriggerElement}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <div
          className="flex content-center"
          data-cy={props['data-cy']}
          data-testid={props['data-testid']}
        >
          <p
            className={`text-preset-6 ${
              textColor || statusTextMap[status]
            } overflow-hidden overflow-ellipsis whitespace-nowrap font-medium`}
          >
            {children}
          </p>
          {onClose && (
            <button
              type="button"
              className="text-preset-6 text-ink-secondary ml-2 font-medium"
              onClick={onClose}
              data-cy={`${props['data-cy']}-close`}
            >
              x
            </button>
          )}
        </div>
      </div>
      {showTooltip && toolTipText && (
        <div
          ref={setTooltipElement}
          className={
            'bg-ink-dark pointer-events-none z-10 rounded py-1 px-2 shadow-md'
          }
          style={styles.popper}
          {...attributes.popper}
        >
          <p className="text-ink-clear text-preset-6 w-max">{toolTipText}</p>
          <div ref={setArrowElement} style={styles.arrow}>
            <div className="bg-ink-dark block h-2 w-2 rotate-45 transform" />
          </div>
        </div>
      )}
    </>
  );
};

export default Pill;
