const tokenKey = 'authToken';
let cachedToken = '';

export const getToken = (): string => {
  if (cachedToken === '') {
    cachedToken = localStorage.getItem(tokenKey) || '';
  }
  return cachedToken;
};

export const setToken = (token: string): void => {
  localStorage.setItem(tokenKey, token);
  cachedToken = token;
};

export const removeToken = (): void => {
  localStorage.removeItem(tokenKey);
  cachedToken = '';
};
