import React from 'react';
import { format } from 'date-fns-tz';

import Loading from 'src/components/Loading';
import Calendar from 'src/components/Icons/Calendar';
import MarkerIcon from 'src/components/Icons/MarkerIcon';
import { formatAddress } from 'src/utils/address';
import { GetRosterForAdmin_roster as Roster } from 'src/graphql/queries/__generated__/GetRosterForAdmin';

const RosterJobCard: React.FC<{ roster?: Roster; timeZone?: string }> = ({
  roster,
  timeZone,
}) => {
  if (!roster) {
    return <Loading />;
  }
  return (
    <div className="border-support-line mb-6 rounded border px-4 py-5">
      <p className="text-preset-4 text-ink-dark mb-6 font-medium">
        Job Information
      </p>
      <p className="text-preset-5P text-ink-dark mb-1">{roster.job.name}</p>
      <p className="text-preset-6P text-ink-dark mb-6">
        {roster.client.firstName} {roster.client.lastName}
      </p>
      {roster.job.venue && (
        <div className="text-preset-6 text-ink-not-as-dark mb-4 flex">
          <MarkerIcon />
          <p className="ml-3">{formatAddress(roster.job.venue.address)}</p>
        </div>
      )}
      <div className="text-preset-6 text-ink-not-as-dark flex">
        <Calendar className="w-4" />
        <p className="ml-3">
          {format(
            new Date(roster.job.startDateTime),
            'MMM dd hh:mmaaa zzz - ',
            { timeZone },
          )}
          {format(new Date(roster.job.endDateTime), 'MMM dd hh:mmaaa zzz', {
            timeZone,
          })}
        </p>
      </div>
    </div>
  );
};

export default RosterJobCard;
