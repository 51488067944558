import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  CreateShiftRequests,
  CreateShiftRequestsVariables,
} from './__generated__/CreateShiftRequests';

const CREATE_SHIFT_REQUESTS = gql`
  mutation CreateShiftRequests($input: CreateShiftRequestsInput!) {
    createShiftRequests(input: $input) {
      shiftRequests {
        id
        shiftId
        tenderId
        tenderNotificationId
        createdAt
        updatedAt
        status
        expiresAt
      }
      errors {
        shiftId
        tenderId
        error
      }
    }
  }
`;

export const useCreateShiftRequestsMutation = (
  options?: MutationHookOptions<
    CreateShiftRequests,
    CreateShiftRequestsVariables
  >,
) =>
  useMutation<CreateShiftRequests, CreateShiftRequestsVariables>(
    CREATE_SHIFT_REQUESTS,
    options,
  );

export default CREATE_SHIFT_REQUESTS;
