import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  AdminRemoveTenderPosition,
  AdminRemoveTenderPositionVariables,
} from './__generated__/AdminRemoveTenderPosition';

const REMOVE_TENDER_POSITIONS = gql`
  mutation AdminRemoveTenderPosition($input: TenderPositionsInput!) {
    deleteTenderPositions(input: $input) {
      __typename
      id
      positions(onlyDefaultPositions: true) {
        __typename
        id
        name
      }
    }
  }
`;

export const useRemoveTenderPositionMutation = (
  options?: MutationHookOptions<
    AdminRemoveTenderPosition,
    AdminRemoveTenderPositionVariables
  >,
) =>
  useMutation<AdminRemoveTenderPosition, AdminRemoveTenderPositionVariables>(
    REMOVE_TENDER_POSITIONS,
    options,
  );

export default REMOVE_TENDER_POSITIONS;
