import React, { FC } from 'react';
import Combobox from 'src/components/Combobox';
import ChevronDown from 'src/components/Icons/ChevronDown';
import { TenderPaymentType } from 'src/__generated__/globalTypes';

type TenderPaymentTypeOption = {
  label: string;
  id: TenderPaymentType | 'any';
};

const ResultComponent: FC<{
  item: TenderPaymentTypeOption;
  index: number;
  highlighted: boolean;
}> = ({ item }) => (
  <div className="hover:bg-background-app cursor-pointer py-2 px-4">
    <p className="text-preset-6 text-ink-dark font-medium">{item.label}</p>
  </div>
);

type Props = {
  tenderPaymentType?: string;
  onChange: (tenderPaymentType: string) => void;
  className?: string;
};

const tenderPaymentTypeList: TenderPaymentTypeOption[] = [
  {
    label: 'Any',
    id: 'any',
  },
  {
    label: 'Standard',
    id: TenderPaymentType.STANDARD,
  },
  {
    label: 'Bonus',
    id: TenderPaymentType.BONUS,
  },
];

const TenderPaymentTypeInput: FC<Props> = ({
  tenderPaymentType,
  onChange,
  className,
}) => {
  const defaultItem =
    tenderPaymentTypeList.find((type) => type.id === tenderPaymentType) ||
    tenderPaymentTypeList[0];

  return (
    <div className={className}>
      <Combobox
        id="tenderPaymentTypeCombobox"
        inputValue={defaultItem.label}
        inputClassName="focus:ring-primary focus:border-primary sm:text-preset-6 border-support-line-darker rounded cursor-pointer"
        inputReadOnly
        itemToString={() => defaultItem.label}
        items={tenderPaymentTypeList}
        label="Tender payment type"
        loadingItems={false}
        onChange={(selectedItem?: TenderPaymentTypeOption | null) => {
          selectedItem && onChange(selectedItem.id);
        }}
        onInputChange={() => null}
        onReset={() => onChange('any')}
        openMenuOnFocus
        renderItemComponent={ResultComponent}
        renderLoadingComponent={() => null}
        renderNoResultsComponent={() => null}
        renderTrailingComponent={() => (
          <ChevronDown className="text-ink-not-as-dark h-3 w-3" />
        )}
      />
    </div>
  );
};

export default TenderPaymentTypeInput;
