import { gql, LazyQueryHookOptions, useLazyQuery } from '@apollo/client';
import {
  AdjustPaidStaffingEstimate,
  AdjustPaidStaffingEstimateVariables,
} from './__generated__/AdjustPaidStaffingEstimate';

const ADJUST_PAID_STAFFING_ESTIMATE_QUERY = gql`
  query AdjustPaidStaffingEstimate($input: AdjustPaidStaffingInput!) {
    adjustPaidStaffingEstimate(input: $input) {
      tenderPayoutDifference
      clientPaymentDifference
      clientTotalEstimate
    }
  }
`;

export const useAdjustPaidStaffingEstimateQuery = (
  options?: LazyQueryHookOptions<
    AdjustPaidStaffingEstimate,
    AdjustPaidStaffingEstimateVariables
  >,
) =>
  useLazyQuery<AdjustPaidStaffingEstimate, AdjustPaidStaffingEstimateVariables>(
    ADJUST_PAID_STAFFING_ESTIMATE_QUERY,
    options,
  );

export default ADJUST_PAID_STAFFING_ESTIMATE_QUERY;
