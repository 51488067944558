import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import {
  GetRosterTendersForAdmin,
  GetRosterTendersForAdminVariables,
} from './__generated__/GetRosterTendersForAdmin';

const GET_ROSTER_TENDERS = gql`
  query GetRosterTendersForAdmin(
    $rosterId: ID!
    $after: String
    $filters: TenderRosterFilters
  ) {
    tenderRosters(
      first: 10
      rosterId: $rosterId
      after: $after
      filters: $filters
    ) {
      edges {
        cursor
        node {
          __typename
          id
          placeHolderName
          tender {
            id
            firstName
            lastName
            email
            avatarURL
          }
          shift {
            id
            startDateTime
            endDateTime
            position {
              name
            }
          }
          roster {
            id
            job {
              id
              venue {
                address {
                  timezone
                }
              }
            }
          }
        }
      }
      pageInfo {
        totalCount
        endCursor
        hasNextPage
      }
    }
  }
`;

export const useGetRosterTendersForAdminQuery = (
  options?: QueryHookOptions<
    GetRosterTendersForAdmin,
    GetRosterTendersForAdminVariables
  >,
) =>
  useQuery<GetRosterTendersForAdmin, GetRosterTendersForAdminVariables>(
    GET_ROSTER_TENDERS,
    options,
  );

export default GET_ROSTER_TENDERS;
