import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { GetJob, GetJobVariables } from './__generated__/GetJob';
import SHIFT_FOR_JOB_DETAILS from '../fragments/ShiftForJobDetails';
import INSTRUCTIONS_FOR_JOB_DETAILS from '../fragments/InstructionForJobDetails';

const GET_JOB = gql`
  query GetJob($id: ID!) {
    job(id: $id) {
      __typename
      id
      name
      client {
        lastName
      }
      description
      staffingNotes
      startDateTime
      endDateTime
      mealProvided
      useBackDoor
      requiresAlcohol
      total
      published
      hiddenFromOpenJobs
      hiddenFromClient
      mealProvided
      useBackDoor
      isFlexpoolOrder
      locationContact {
        id
        name
        phoneNumber
      }
      status
      venue {
        id
        name
        type
        parkingType
        parking {
          value
          label
        }
        parkingInstructions
        otherInstructions
        address {
          streetName
          streetNumber
          interiorNumber
          neighborhood
          city
          state
          country
          zip
          lat
          lng
          timezone
        }
      }
      instructions {
        ...InstructionsForJobDetails
      }
      shifts {
        ...ShiftForJobDetails
        hiddenFromOpenShifts
        unpaidBreakMinutes
        job {
          isFlexpoolOrder
        }
        hasAutoApprove
      }
      attachments {
        fileName
        url
      }
      client {
        email
      }
    }
  }
  ${SHIFT_FOR_JOB_DETAILS}
  ${INSTRUCTIONS_FOR_JOB_DETAILS}
`;

export const useGetJobQuery = (
  options?: QueryHookOptions<GetJob, GetJobVariables>,
) => useQuery<GetJob, GetJobVariables>(GET_JOB, options);

export default GET_JOB;
