import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  ToggleTenderImpersonatorRole,
  ToggleTenderImpersonatorRoleVariables,
} from './__generated__/ToggleTenderImpersonatorRole';

const TOGGLE_TENDER_IMPERSONATOR_ROLE = gql`
  mutation ToggleTenderImpersonatorRole($id: ID!) {
    toggleTenderImpersonatorRole(tenderId: $id) {
      tender {
        id
        isImpersonator
      }
    }
  }
`;

export const useToggleTenderImpersonatorRoleMutation = (
  options?: MutationHookOptions<
    ToggleTenderImpersonatorRole,
    ToggleTenderImpersonatorRoleVariables
  >,
) =>
  useMutation<
    ToggleTenderImpersonatorRole,
    ToggleTenderImpersonatorRoleVariables
  >(TOGGLE_TENDER_IMPERSONATOR_ROLE, options);

export default TOGGLE_TENDER_IMPERSONATOR_ROLE;
