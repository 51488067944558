import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Menu, RadioGroup, Transition } from '@headlessui/react';
import CurrencyInput from 'react-currency-input-field';
import { Controller, useForm } from 'react-hook-form';
import moment, { Moment } from 'moment-timezone';

import Combobox from 'src/components/Combobox';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import DotsHorizontal from 'src/components/Icons/DotsHorizontal';
import Pencil from 'src/components/Icons/Pencil';
import Eye from 'src/components/Icons/Eye';
import EyeOff from 'src/components/Icons/EyeOff';
import TrashIcon from 'src/components/Icons/Trash';
import DateInput from 'src/components/DateInput';
import Modal from 'src/components/Modal';
import TimeInput from 'src/components/TimeInput';
import RadioOption from 'src/components/RadioOption';
import { useToggleShiftVisibilityInOpenJobsMutation } from 'src/graphql/mutations/ToggleShiftVisibilityInOpenJobs';
import { useGetPositionRatesForAdminQuery } from 'src/graphql/queries/GetPositionRates';
import { GetPositionRatesForAdmin_getPositionRatesForAdmin as PositionRate } from 'src/graphql/queries/__generated__/GetPositionRatesForAdmin';
import { useUpdateShiftAsAdminMutation } from 'src/graphql/mutations/UpdateShift';
import { useDeleteShiftAsAdminMutation } from 'src/graphql/mutations/DeleteShift';
import { GetJob_job_shifts as Shift } from 'src/graphql/queries/__generated__/GetJob';
import {
  PricingSource,
  TipType,
  UpdateShiftInput,
} from 'src/__generated__/globalTypes';
import Check from 'src/components/Icons/Check';
import Ban from 'src/components/Icons/Ban';
import { useUpdateShiftAutoApproveMutation } from 'src/graphql/mutations/UpdateShiftAutoApprove';

type TipTypeOption = { label: string; id: TipType };
const tipTypeOptions: TipTypeOption[] = [
  {
    label: 'No Tip',
    id: TipType.NO_TIP,
  },
  {
    label: 'Include Tip',
    id: TipType.INCLUDE_TIP,
  },
  {
    label: 'On-site',
    id: TipType.ON_SITE,
  },
  {
    label: 'Allow Jar',
    id: TipType.ALLOW_JAR,
  },
];

const EditPriceModal: React.FC<{
  shift: Shift;
  jobId: string;
  open: boolean;
  onClose: () => void;
}> = ({ shift, jobId, open, onClose }) => {
  const [isCustom, setIsCustom] = useState(false);
  const [pricingSource, setPricingSource] = useState<string>();
  const [positionRate, setPositionRate] = useState<PositionRate>();
  const [selectedTipType, setSelectedTipType] = useState<
    TipTypeOption | undefined
  >(
    tipTypeOptions.find((option) => option.id === shift.tip?.type) || undefined,
  );
  const { data } = useGetPositionRatesForAdminQuery({
    variables: { input: { jobId } },
  });
  const [updateShiftMutation] = useUpdateShiftAsAdminMutation();
  const { register, handleSubmit, errors, reset, control } = useForm<
    UpdateShiftInput & { unpaidBreakMinutes: string }
  >({
    defaultValues: {
      margin: shift.margin / 100,
      tipType: shift.tip?.type,
      tipAmount: shift.tip?.amount ? shift.tip.amount / 100 : 0,
      rate: shift.rate / 100,
    },
    mode: 'onBlur',
  });
  useEffect(() => {
    const newPositionRate = data?.getPositionRatesForAdmin.find(
      (pr: PositionRate) => pr.id === shift.position.id,
    );
    setPositionRate(newPositionRate);
    if (
      newPositionRate?.rate !== shift.rate ||
      newPositionRate?.margin !== shift.margin
    ) {
      setIsCustom(true);
      setPricingSource('Custom');
    } else {
      setIsCustom(false);
      setPricingSource(newPositionRate.pricingSource);
    }
  }, [data, shift]);
  if (!data) {
    return null;
  }
  const onSubmit = async (
    input: UpdateShiftInput & { unpaidBreakMinutes: string },
  ) => {
    const parsedInput = {
      id: shift.id,
      tipType: input.tipType,
      unpaidBreakMinutes: input.unpaidBreakMinutes
        ? parseInt(input.unpaidBreakMinutes, 10)
        : undefined,
      pricingSource: isCustom ? PricingSource.CUSTOM : undefined,
      rate: input.rate ? Math.trunc(input.rate * 100) : undefined,
      tipAmount:
        input.tipType === TipType.INCLUDE_TIP && input.tipAmount
          ? Math.trunc(input.tipAmount * 100)
          : 0,
      margin: input.margin ? Math.trunc(input.margin * 100) : undefined,
    };
    await updateShiftMutation({
      variables: {
        input: parsedInput,
      },
    });
    onClose();
  };
  return (
    <Modal onClose={onClose} open={open} className="px-6 py-4">
      <p className="text-preset-3 bolder text-ink-dark mb-3">Edit Price</p>
      <p className="text-preset-5 text-ink-not-as-dark mb-8">
        Edit the price rate of this booking. This price can be editable until 24
        hrs before the event.
      </p>
      <p className="text-preset-7 text-ink-not-as-dark mb-5">
        ** Pricing comes from: {pricingSource || 'default'} value **
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex">
          <p className="text-preset-5P bold text-ink-not-as-dark w-44">Rate</p>
          <RadioGroup
            className="flex"
            value={isCustom}
            onChange={(value) => {
              if (!value) {
                positionRate &&
                  reset({
                    margin: positionRate.margin / 100,
                    rate: positionRate.rate / 100,
                  });
              } else {
                reset();
              }
              setIsCustom(value);
            }}
          >
            <RadioOption value={false} label="Default" className="mr-10" />
            <RadioOption value={true} label="Custom" />
          </RadioGroup>
        </div>
        <div className="mt-2 flex items-center">
          <p className="text-preset-5P bold text-ink-not-as-dark w-44">
            Price Rate
          </p>
          <div>
            <Controller
              name="rate"
              control={control}
              render={({ onChange, value, ref }) => (
                <CurrencyInput
                  ref={ref}
                  id="rate"
                  allowNegativeValue={false}
                  prefix="$"
                  disabled={!isCustom}
                  placeholder="Please enter a rate"
                  decimalsLimit={2}
                  onValueChange={onChange}
                  value={value}
                  className={`border-support-line-darker block rounded ${
                    !isCustom ? 'bg-support-line ' : ' '
                  } ${
                    errors.rate
                      ? 'focus:ring-status-destructive-light focus:border-status-destructive'
                      : 'focus:ring-primary focus:border-primary'
                  }`}
                />
              )}
            />
            {errors.rate && (
              <p className="text-preset-7 text-status-destructive mb-2">
                * Enter a valid number
              </p>
            )}
          </div>
          <p className="text-preset-5P bold text-ink-not-as-dark ml-2 w-44">
            / hr each
          </p>
        </div>
        <div className="mt-2 flex items-center">
          <p className="text-preset-5P bold text-ink-not-as-dark w-44">
            Margin
          </p>
          <div>
            <Controller
              name="margin"
              control={control}
              render={({ onChange, value, ref }) => (
                <CurrencyInput
                  ref={ref}
                  id="margin"
                  allowNegativeValue={false}
                  prefix="$"
                  disabled={!isCustom}
                  placeholder="Please enter a margin"
                  decimalsLimit={2}
                  onValueChange={onChange}
                  value={value}
                  className={`border-support-line-darker block rounded ${
                    !isCustom ? 'bg-support-line ' : ' '
                  } ${
                    errors.rate
                      ? 'focus:ring-status-destructive-light focus:border-status-destructive'
                      : 'focus:ring-primary focus:border-primary'
                  }`}
                />
              )}
            />
            {errors.rate && (
              <p className="text-preset-7 text-status-destructive mb-2">
                * Enter a valid number
              </p>
            )}
          </div>
        </div>
        <div className="mt-2 flex items-center">
          <p className="text-preset-5P bold text-ink-not-as-dark w-44">
            Break minutes
          </p>
          <div>
            <input
              ref={register}
              type="text"
              name="unpaidBreakMinutes"
              defaultValue={shift.unpaidBreakMinutes ?? 0}
              className={`border-support-line-darker block rounded ${
                errors.unpaidBreakMinutes
                  ? 'focus:ring-status-destructive-light focus:border-status-destructive'
                  : 'focus:ring-primary focus:border-primary'
              }`}
            />
            {errors.unpaidBreakMinutes && (
              <p className="text-preset-7 text-status-destructive mb-2">
                * Enter a valid number
              </p>
            )}
          </div>
        </div>
        <div className="flex items-center">
          <p className="text-preset-5P bold text-ink-not-as-dark w-44">
            Tip Type
          </p>
          <Controller
            name="tipType"
            control={control}
            defaultValue={selectedTipType}
            render={({ onChange }) => (
              <Combobox
                id="tipType"
                label=""
                items={tipTypeOptions}
                loadingItems={false}
                renderLoadingComponent={() => null}
                renderNoResultsComponent={() => null}
                renderItemComponent={({ item }) => (
                  <div className="hover:bg-background-app cursor-pointer py-2 px-4">
                    <p className="text-preset-6 text-ink-dark font-medium">
                      {item.label}
                    </p>
                  </div>
                )}
                onChange={(item) => {
                  item && setSelectedTipType(item);
                  onChange(item?.id);
                }}
                onInputChange={() => null}
                openMenuOnFocus
                inputReadOnly
                inputValue={selectedTipType?.label}
                inputClassName="mb-0 focus:ring-primary focus:border-primary border-support-line-darker rounded"
                className=""
              />
            )}
          />
        </div>
        {selectedTipType?.id === TipType.INCLUDE_TIP ? (
          <div className="mb-16 flex items-center">
            <p className="text-preset-5P bold text-ink-not-as-dark w-44">
              Tip Amount
            </p>
            <div>
              <Controller
                name="tipAmount"
                control={control}
                render={({ onChange, value, ref }) => (
                  <CurrencyInput
                    ref={ref}
                    id="tipAmount"
                    allowNegativeValue={false}
                    prefix="$"
                    placeholder="Please enter a tip amount"
                    decimalsLimit={2}
                    onValueChange={onChange}
                    value={value}
                    className={`border-support-line-darker block rounded ${
                      errors.rate
                        ? 'focus:ring-status-destructive-light focus:border-status-destructive'
                        : 'focus:ring-primary focus:border-primary'
                    }`}
                  />
                )}
              />
              {errors.tipAmount && (
                <p className="text-preset-7 text-status-destructive mb-2">
                  * Enter a valid number
                </p>
              )}
            </div>
          </div>
        ) : (
          <div className="h-24" />
        )}
        <div className="text-ink-dark border-ink-dark-200 -mx-6 flex justify-end border-t border-solid pr-6 pt-4">
          <button
            className="border-ink-dark rounded-md border-2 py-2 px-4"
            onClick={(event) => {
              event.preventDefault();
              onClose();
            }}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="bg-primary text-ink-clear hover:bg-primary-active ml-4 rounded-md py-2 px-4"
          >
            Save
          </button>
        </div>
      </form>
    </Modal>
  );
};

const timezoneDateString = (date: string, timeZone: string): Moment => {
  return moment.tz(new Date(date), timeZone);
};

type UpdateShiftDateInput = {
  date: Moment;
  startDateTime: Moment;
  endDateTime: Moment;
  quantity: number;
};

const EditDetailsModal: React.FC<{
  shift: Shift;
  timezone: string;
  open: boolean;
  onClose: () => void;
}> = ({ shift, open, onClose, timezone }) => {
  const initialFocusElement = useRef(null);

  const [updateShiftMutation, { loading, error }] =
    useUpdateShiftAsAdminMutation({
      refetchQueries: ['GetJob'],
    });

  const [pickerOpen, setPickerOpen] = useState(false);

  const { handleSubmit, formState, control, reset } =
    useForm<UpdateShiftDateInput>({
      mode: 'onBlur',
    });

  useEffect(() => {
    // Reset form when shift changes (initial state and after update mutation)
    reset({
      date: timezoneDateString(shift.startDateTime, timezone).startOf('day'),
      startDateTime: timezoneDateString(shift.startDateTime, timezone),
      endDateTime: timezoneDateString(shift.endDateTime, timezone),
      quantity: shift.quantity,
    });
  }, [shift, reset, timezone]);

  const onSubmit = async (input: UpdateShiftDateInput) => {
    const diffStartMinutes = input.startDateTime
      .clone()
      .diff(input.startDateTime.clone().startOf('day'), 'minutes');
    const diffEndMinutes = input.endDateTime
      .clone()
      .diff(input.endDateTime.clone().startOf('day'), 'minutes');
    const startDateTime = input.date.clone().add(diffStartMinutes, 'minutes');
    const endDateTime = input.date.clone().add(diffEndMinutes, 'minutes');

    if (endDateTime <= startDateTime) {
      endDateTime.add(1, 'days');
    }

    const parsedInput = {
      endDateTime: endDateTime,
      id: shift.id,
      startDateTime: startDateTime,
      ...(!shift.job.isFlexpoolOrder
        ? { quantity: Number(input.quantity) || 0 }
        : {}),
    };

    await updateShiftMutation({
      variables: {
        input: parsedInput,
      },
    });

    onClose();
  };

  const formIsDirty =
    formState.dirtyFields.startDateTime ||
    formState.dirtyFields.endDateTime ||
    formState.dirtyFields.date ||
    formState.dirtyFields.quantity;

  return (
    <Modal
      onClose={() => {
        !pickerOpen && onClose();
      }}
      open={open}
      className="w-1/2 overflow-visible px-6 py-4"
      initialFocusRef={initialFocusElement}
    >
      <p className="text-preset-3 bolder text-ink-dark mb-3">Edit Shift</p>

      {shift.job.isFlexpoolOrder && (
        <div className="bg-background-app mb-4 mt-1 rounded-md p-2">
          <p className="text-preset-5P text-status-warning font-medium">
            This is a Talent/Flexpool order. Quantity editing has been disabled
            to avoid issues with the synced order.
          </p>
        </div>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="my-6 flex items-center gap-4">
          <label
            htmlFor="shift.quantity"
            className={`text-preset-6 shrink-0 font-medium ${
              shift.job.isFlexpoolOrder
                ? 'text-ink-not-as-dark'
                : 'text-ink-dark'
            }`}
          >
            Number of Tenders
          </label>

          <Controller
            name="quantity"
            control={control}
            render={({ value, onChange }) => (
              <input
                className="focus:ring-primary focus:border-primary sm:text-preset-6 border-support-line-darker disabled:border-support-line disabled:text-ink-not-as-dark block w-20 rounded"
                disabled={loading || shift.job.isFlexpoolOrder}
                min={1}
                name="quantity"
                onChange={onChange}
                placeholder="Enter the shift quantity"
                type="number"
                value={value}
                pattern="[0-9]"
              />
            )}
          />
        </div>

        <p className="text-preset-5 text-ink-dark mb-5">Event Time</p>

        <div className="flex flex-col lg:flex-row">
          <div className="mb-4 lg:mb-40">
            <p className="text-preset-6 bold text-ink-dark mb-3">Date</p>
            <Controller
              name="date"
              control={control}
              disabled={loading}
              render={({ value, onChange }) => (
                <div className="w-40">
                  <DateInput date={value} onDateChange={onChange} />
                </div>
              )}
            />
          </div>

          <div className="ml-0 lg:ml-4">
            <p className="text-preset-6 bold text-ink-dark mb-3">Start Time</p>
            <div className="h-12">
              <Controller
                name="startDateTime"
                control={control}
                disabled={loading}
                render={({ value, onChange }) => (
                  <TimeInput
                    value={value}
                    onChange={onChange}
                    onOpen={() => setPickerOpen(true)}
                    onClose={() => setPickerOpen(false)}
                  />
                )}
              />
            </div>
          </div>

          <div className="ml-0 lg:ml-6">
            <p className="text-preset-6 bold text-ink-dark mb-3">End Time</p>
            <div className="h-12">
              <Controller
                name="endDateTime"
                control={control}
                disabled={loading}
                render={({ value, onChange }) => (
                  <TimeInput
                    value={value}
                    onChange={onChange}
                    onOpen={() => setPickerOpen(true)}
                    onClose={() => setPickerOpen(false)}
                  />
                )}
              />
            </div>
          </div>
        </div>

        <div className="text-ink-dark border-ink-dark-200 -mx-6 flex items-center justify-end border-t border-solid pr-6 pt-4">
          {error && (
            <div className="justify-self-start px-6">
              <p className="text-preset-6P text-status-destructive">
                There was an error saving the shift details.
                <br />
                Check the data and try again.
              </p>
            </div>
          )}

          <button
            className="border-ink-dark ml-auto rounded-md border-2 py-2 px-4"
            disabled={loading}
            onClick={onClose}
            type="button"
            ref={initialFocusElement}
          >
            Cancel
          </button>

          <button
            disabled={!formIsDirty || loading}
            type="submit"
            className={`bg-primary text-ink-clear hover:bg-primary-active ml-4 rounded-md py-2 px-4 ${
              !formIsDirty ? 'cursor-not-allowed opacity-50' : ''
            }`}
          >
            Save
          </button>
        </div>
      </form>
    </Modal>
  );
};

const ShiftMoreOptionsMenu: React.FC<{
  className?: string;
  shift: Shift;
  jobId: string;
  timezone: string;
}> = ({ className, shift, jobId, timezone }) => {
  const [openEditPrice, setOpenEditPrice] = useState(false);
  const [isDeleteShiftOpen, setIsDeleteShiftOpen] = useState(false);
  const [openEditDetails, setOpenEditDetails] = useState(false);
  const [toggleShiftVisibilityInOpenJobs] =
    useToggleShiftVisibilityInOpenJobsMutation({
      variables: {
        shiftId: shift.id,
        isHidden: shift.hiddenFromOpenShifts,
      },
    });
  const [updateShiftAutoApprove] = useUpdateShiftAutoApproveMutation({
    variables: {
      id: shift.id,
      hasAutoApprove: !shift.hasAutoApprove,
    },
  });
  const [deleteShiftMutation] = useDeleteShiftAsAdminMutation({
    variables: { id: shift.id },
  });

  return (
    <>
      <Menu as="div" className="relative">
        <Menu.Button className={`text-ink-not-as-dark ${className}`}>
          <DotsHorizontal className="h-6 w-6" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="bg-background-surface shadow-floating-menu text-ink-dark absolute right-0 mt-2 rounded py-2">
            <Menu.Item as={Fragment}>
              {({ active }) => (
                <button
                  className={`${
                    active ? 'bg-background-app' : ''
                  } text-preset-6 text-ink-dark border-support-lines flex h-10 w-full items-center whitespace-nowrap border-b px-4 py-2`}
                  onClick={() => setOpenEditPrice(true)}
                >
                  <Pencil className="mr-2 w-4" />
                  <p>Edit Price Rate</p>
                </button>
              )}
            </Menu.Item>
            <Menu.Item as={Fragment}>
              {({ active }) => (
                <button
                  onClick={() => setOpenEditDetails(true)}
                  className={`${
                    active ? 'bg-background-app' : ''
                  } text-preset-6 text-ink-dark flex h-10 w-full items-center whitespace-nowrap px-4 py-2`}
                >
                  <Pencil className="mr-2 w-4" />
                  <p>Edit Shift</p>
                </button>
              )}
            </Menu.Item>
            <Menu.Item as={Fragment}>
              {({ active }) => {
                return (
                  <button
                    onClick={() => toggleShiftVisibilityInOpenJobs()}
                    className={`${active ? 'bg-background-app' : ''} w-full`}
                  >
                    <div className="text-preset-6 text-ink-dark flex h-10 items-center whitespace-nowrap px-4 py-2">
                      {shift.hiddenFromOpenShifts ? (
                        <>
                          <Eye className="mr-2 w-4" />
                          <p className="text-preset-6">Show in Jobs</p>
                        </>
                      ) : (
                        <>
                          <EyeOff className="mr-2 w-4" />
                          <p className="text-preset-6">Hide from Jobs</p>
                        </>
                      )}
                    </div>
                  </button>
                );
              }}
            </Menu.Item>
            <Menu.Item as={Fragment}>
              {({ active }) => (
                <button
                  onClick={() => setIsDeleteShiftOpen(true)}
                  className={`${active ? 'bg-background-app' : ''} w-full`}
                >
                  <div className="text-preset-6 text-status-destructive flex h-10 items-center whitespace-nowrap px-4 py-2">
                    <TrashIcon className="mr-2 w-4" />
                    <p>Delete Shift</p>
                  </div>
                </button>
              )}
            </Menu.Item>
            <Menu.Item as={Fragment}>
              {({ active }) => {
                return (
                  <button
                    onClick={() => updateShiftAutoApprove()}
                    className={`${active ? 'bg-background-app' : ''} w-full`}
                  >
                    <div className="text-preset-6 text-ink-dark flex h-10 items-center whitespace-nowrap px-4 py-2">
                      {shift.hasAutoApprove ? (
                        <>
                          <Ban className="mr-2 h-4 w-4" />
                          <p className="text-preset-6">
                            Deactivate auto-approve
                          </p>
                        </>
                      ) : (
                        <>
                          <Check className="mr-2 w-4" />
                          <p className="text-preset-6">Activate auto-approve</p>
                        </>
                      )}
                    </div>
                  </button>
                );
              }}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
      <ConfirmationDialog
        open={isDeleteShiftOpen}
        title="Delete shift"
        description="You will permanently delete this shift and its related information"
        cancelText="Close"
        submitText="Delete"
        submitClassName="text-status-destructive py-2 px-4 ml-4"
        onSubmit={async () => {
          setIsDeleteShiftOpen(false);
          await deleteShiftMutation();
        }}
        onClose={() => setIsDeleteShiftOpen(false)}
        closeOnEscape
      />
      <EditPriceModal
        jobId={jobId}
        shift={shift}
        open={openEditPrice}
        onClose={() => setOpenEditPrice(false)}
      />
      <EditDetailsModal
        shift={shift}
        timezone={timezone}
        open={openEditDetails}
        onClose={() => setOpenEditDetails(false)}
      />
    </>
  );
};

export default ShiftMoreOptionsMenu;
