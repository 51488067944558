import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import {
  GetAdminTenderNotifications,
  GetAdminTenderNotificationsVariables as Variables,
} from 'src/graphql/queries/__generated__/GetAdminTenderNotifications';

const ADMIN_TENDER_NOTIFICATIONS = gql`
  query GetAdminTenderNotifications(
    $first: Int!
    $after: String
    $filters: AdminTenderNotificationFilters
  ) {
    adminTenderNotifications(first: $first, after: $after, filters: $filters) {
      pageInfo {
        totalCount
        endCursor
        hasNextPage
        remainingEdges
      }
      edges {
        node {
          id
          title
          body
          sentAt
          processor
        }
      }
    }
  }
`;

export const useAdminTenderNotificationsQuery = (
  options?: QueryHookOptions<GetAdminTenderNotifications, Variables>,
) =>
  useQuery<GetAdminTenderNotifications, Variables>(
    ADMIN_TENDER_NOTIFICATIONS,
    options,
  );

export default ADMIN_TENDER_NOTIFICATIONS;
