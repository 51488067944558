import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  CreateRosterAsAdmin,
  CreateRosterAsAdminVariables,
} from './__generated__/CreateRosterAsAdmin';

const CREATE_ROSTER_MUTATION = gql`
  mutation CreateRosterAsAdmin($input: CreateRosterInput!) {
    createRosters(input: $input) {
      __typename
      id
      job {
        id
        name
        startDateTime
        endDateTime
        shifts {
          id
          startDateTime
          endDateTime
          quantity
          staffedSlots
          position {
            name
          }
        }
        venue {
          name
          address {
            timezone
            streetName
            streetNumber
            interiorNumber
            neighborhood
            city
            state
            country
            zip
          }
        }
      }
      client {
        id
        firstName
        lastName
      }
    }
  }
`;

export const useCreateRosterAsAdminMutation = (
  options?: MutationHookOptions<
    CreateRosterAsAdmin,
    CreateRosterAsAdminVariables
  >,
) =>
  useMutation<CreateRosterAsAdmin, CreateRosterAsAdminVariables>(
    CREATE_ROSTER_MUTATION,
    {
      update(cache, { data }) {
        const newRosters = data?.createRosters.map((newRoster) => ({
          node: newRoster,
        }));
        cache.modify({
          fields: {
            rosters(exitingRosters = []) {
              return {
                ...exitingRosters,
                edges: [
                  ...(newRosters || []),
                  ...(exitingRosters?.edges || []),
                ],
              };
            },
          },
        });
      },
      ...options,
    },
  );

export default CREATE_ROSTER_MUTATION;
