import React from 'react';

const ViewContainer: React.FC<{ className?: string }> = ({
  className,
  children,
}) => (
  <div className={`mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ${className}`}>
    <div className="mx-auto max-w-7xl">
      <div className="flex flex-col">{children}</div>
    </div>
  </div>
);

export default ViewContainer;
