import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  MarkTenderPayoutAsCancelled,
  MarkTenderPayoutAsCancelledVariables,
} from './__generated__/MarkTenderPayoutAsCancelled';

const MARK_TENDER_PAYOUT_AS_CANCELLED = gql`
  mutation MarkTenderPayoutAsCancelled($id: ID!) {
    markTenderPayoutAsCancelled(id: $id) {
      id
      status
    }
  }
`;

export const useMarkTenderPayoutAsCancelled = (
  options?: MutationHookOptions<
    MarkTenderPayoutAsCancelled,
    MarkTenderPayoutAsCancelledVariables
  >,
) =>
  useMutation<
    MarkTenderPayoutAsCancelled,
    MarkTenderPayoutAsCancelledVariables
  >(MARK_TENDER_PAYOUT_AS_CANCELLED, options);

export default MARK_TENDER_PAYOUT_AS_CANCELLED;
