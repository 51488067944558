import React, { Fragment, useCallback, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import { useCombobox } from 'downshift';
import XCircleFilled from 'src/components/Icons/XCircleFilled';

export type ComboboxRef = {
  isOpen: boolean;
  reset: () => void;
};

export const Reset: React.FC<{ onClick?: () => void }> = ({
  onClick,
  ...rest
}) => (
  <button
    type="button"
    className="text-support-line-darker focus:outline-none"
    onClick={onClick}
    aria-hidden="true"
    title="Reset button"
    {...rest}
  >
    <XCircleFilled className="h-4 w-4" />
  </button>
);

const Combobox = <T extends { id: string }>(props: {
  id: string;
  /**
   * Using "refObject" instead of just "ref" because, with the latter, React
   * will explicitly ignore that prop and always send "undefined" as its value
   */
  refObject?: React.MutableRefObject<ComboboxRef | null>;
  label: string;
  description?: string;
  placeholder?: string;
  inputClassName?: string;
  className?: string;
  items: T[];
  loadingItems: boolean;
  error?: string;
  /**
   * Useful for rendering the selected item inside the combobox input, disabled
   * by default
   */
  itemToString?: (item: T | null) => string;
  renderLeadingComponent?: () => React.ReactNode;
  renderTrailingComponent?: () => React.ReactNode;
  renderLoadingComponent: () => React.ReactNode;
  renderNoResultsComponent: () => React.ReactNode;
  renderItemComponent: (props: {
    item: T;
    index: number;
    highlighted: boolean;
  }) => React.ReactNode;
  onReset?: () => void;
  onChange: (selectedItem?: T | null) => void;
  onInputChange: (value: string | undefined) => void;
  openMenuOnFocus?: boolean;
  inputValue?: string;
  inputReadOnly?: boolean;
  autoFocus?: boolean;
  'data-cy'?: string;
}) => {
  const {
    isOpen,
    highlightedIndex,
    reset,
    getInputProps,
    getComboboxProps,
    getLabelProps,
    getMenuProps,
    getItemProps,
    openMenu,
    setHighlightedIndex,
  } = useCombobox({
    items: props.items,
    itemToString: props.itemToString ?? (() => ''),
    onInputValueChange: ({ inputValue }) => {
      props.onInputChange(inputValue);
    },
    onSelectedItemChange: ({ selectedItem }) => props.onChange(selectedItem),
    inputValue: props.inputValue,
  });

  const callbackRef = useCallback(
    (inputElement: HTMLInputElement) => {
      if (inputElement && props.autoFocus) {
        inputElement.focus({ preventScroll: true });
      }
    },
    [props.autoFocus],
  );

  const handleReset = () => {
    reset();
    props.onReset?.();
  };

  useEffect(() => {
    if (highlightedIndex === -1) {
      setHighlightedIndex(0);
    }
  }, [highlightedIndex, setHighlightedIndex]);

  useEffect(() => {
    if (props.refObject) {
      props.refObject.current = { isOpen, reset };
    }
    return () => {
      if (props.refObject) {
        props.refObject.current = null;
      }
    };
  }, [isOpen, props.refObject, reset]);

  return (
    <div className={props.className ?? 'mb-4'}>
      <label
        htmlFor={props.id}
        className="text-preset-5 text-ink-dark mb-2 block font-medium"
        {...getLabelProps()}
      >
        {props.label}
      </label>
      {props.description && (
        <p className="text-preset-6 text-ink-not-as-dark mb-2">
          {props.description}
        </p>
      )}
      <div className="relative mt-1">
        <div className="relative" {...getComboboxProps()}>
          {props.renderLeadingComponent && (
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              {props.renderLeadingComponent()}
            </div>
          )}
          <input
            /**
             * seems that the "autofocus" prop and Downshift can't play
             * well together and whenever this input is rendered with
             * "autofocus", the page auto scrolls to the top, I did not
             * find any reference of the issue on Downshift's repo.
             * The way around this was to create the callbackRef to manually
             * focus the input when the input is created in the DOM
             */
            // autoFocus={props.autoFocus}
            type="text"
            readOnly={props.inputReadOnly}
            name={props.id}
            id={props.id}
            className={`block w-full ${
              props.renderLeadingComponent ? 'pl-8' : ''
            } ${props.renderTrailingComponent || props.onReset ? 'pr-8' : ''} ${
              props.inputClassName ||
              'focus:ring-primary focus:border-primary sm:text-preset-6 border-support-line-darker rounded'
            }`}
            placeholder={props.placeholder}
            {...getInputProps({
              onClick: () => {
                if (props.openMenuOnFocus && !isOpen) {
                  openMenu();
                }
              },
              value: props.inputValue,
              ref: callbackRef,
            })}
            data-cy={props['data-cy']}
          />
          {(props.renderTrailingComponent || props.onReset) && (
            <div className="absolute inset-y-0 right-0 flex items-center pr-3">
              {props.renderTrailingComponent?.() || (
                <Reset onClick={handleReset} />
              )}
            </div>
          )}
        </div>
        <p className="text-preset-6 text-status-destructive mt-2">
          {props.error}
        </p>

        {/* NOTE: workaround needed to avoid the "downshift: The ref prop "ref"
        from getMenuProps was not applied correctly on your element." error.
        This is caused by the `getMenuProps` called conditionally to hide the
        dropdown menu under a Transition that depends on `isOpen` value.
        Reference: https://github.com/downshift-js/downshift/issues/1167 */}
        <div {...getMenuProps()}>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            show={isOpen}
          >
            <div className="text-preset-5 sm:text-preset-6 absolute z-10 mt-1 max-h-64 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              {props.loadingItems
                ? props.renderLoadingComponent()
                : props.items.length
                ? props.items.map((item, index) => (
                    <div key={index} {...getItemProps({ item, index })}>
                      {props.renderItemComponent({
                        item,
                        index,
                        highlighted: highlightedIndex === index,
                      })}
                    </div>
                  ))
                : props.renderNoResultsComponent()}
            </div>
          </Transition>
        </div>
      </div>
    </div>
  );
};

const HighlightableItem: React.FC<{
  highlighted?: boolean;
  className?: string;
}> = ({ highlighted, className = '', children }) => (
  <div
    className={`relative cursor-default select-none py-2 px-4 ${
      highlighted && 'text-ink-not-as-dark bg-background-app'
    } ${className}`}
  >
    {children}
  </div>
);

Combobox.HighlightableItem = HighlightableItem;

export default Combobox;
