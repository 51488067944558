import React, { Fragment, ReactNode, useMemo, useState } from 'react';
import { OrderByDirectionEnum } from 'src/__generated__/globalTypes';
import ChevronDown from 'src/components/Icons/ChevronDown';
import ChevronUp from 'src/components/Icons/ChevronUp';
import Pagination from 'src/components/Pagination';
import TableHeaderCell from 'src/components/TableHeaderCell';
import { Can } from 'src/contexts/AbilityContext';
import {
  ShiftRequests_shiftRequests_meta as PaginationMeta,
  ShiftRequests_shiftRequests_items as ShiftRequestItem,
  ShiftRequests_shiftRequests_items_tagGroups,
} from 'src/graphql/queries/__generated__/ShiftRequests';
import useSort from 'src/hooks/useSort';
import RequestStatusBadge from '../RequestStatusBadge';
import ClientPreferenceCell from './ClientPreferenceCell';
import ClientPreferenceMatchCell from './ClientPreferenceMatchCell';
import ConflictsCell from './ConflictsCell';
import DataCell from './DataCell';
import DrivingDistanceCell from './DrivingDistanceCell';
import ScoreCell from './ScoreCell';
import TenderCell from './TenderCell';

interface Column {
  displayName: string;
  sortable: boolean;
  renderComponent: ReactNode;
}

interface ColumnConfig {
  [key: string]: Column;
}

export type ColumnName = keyof ColumnConfig;

const getColumnsConfig = ({
  positionTagGroups,
  request,
}: {
  positionTagGroups?: ShiftRequests_shiftRequests_items_tagGroups[];
  request?: ShiftRequestItem;
}): ColumnConfig | undefined => {
  if (!request) {
    return;
  }

  return {
    tender: {
      displayName: 'Tender',
      sortable: true,
      renderComponent: (
        <TenderCell
          tenderId={request.tender.id}
          avatarURL={request.tender.avatarURL}
          firstName={request.tender.firstName || ''}
          lastName={request.tender.lastName || ''}
          detailText={request.tender.phoneNumber}
        />
      ),
    },
    preference: {
      displayName: 'Preference',
      sortable: true,
      renderComponent: (
        <ClientPreferenceCell preference={request.clientTenderStatus} />
      ),
    },
    tagMatch: {
      displayName: 'Tag Match',
      sortable: true,
      renderComponent: (
        <ClientPreferenceMatchCell
          positionTagGroups={positionTagGroups}
          tenderTagGroups={request.tagGroups}
        />
      ),
    },
    drivingDistance: {
      displayName: 'Driving Distance',
      sortable: true,
      renderComponent: (
        <DrivingDistanceCell
          distanceInMiles={request.drivingDistance || 0}
          duration={request.drivingTime}
        />
      ),
    },
    status: {
      displayName: 'Status',
      sortable: true,
      renderComponent: (
        <DataCell data-testid="request-status-cell">
          <RequestStatusBadge status={request.status} />
        </DataCell>
      ),
    },
    conflicts: {
      displayName: 'Conflicts',
      sortable: true,
      renderComponent: (
        <ConflictsCell
          type={
            request.hardConflicts
              ? 'hard'
              : request.softConflicts
              ? 'soft'
              : undefined
          }
        />
      ),
    },
    score: {
      displayName: 'Score',
      sortable: true,
      renderComponent: <ScoreCell score={request.tender.score} />,
    },
  };
};

// NOTE: this component is a simplified version of <StaffingList>. If in the future
// we need to add more features to this component (like row selections ), we
// should consider refactoring it to use StaffingList instead of duplicating code.
const RequestsList: React.FC<{
  title: string;
  count: number;
  shiftId: string;
  requests?: ShiftRequestItem[];
  defaultSortColumn: keyof ColumnConfig;
  defaultSortDirection: OrderByDirectionEnum;
  emptyStateLabel?: string;
  loading?: boolean;
  warning?: boolean;
  backgroundClass?: string;
  actionComponent?: ReactNode;
  positionTagGroups?: ShiftRequests_shiftRequests_items_tagGroups[];
  onCancelShiftRequest: (id: string) => void;
  onToggle?: (isExpanded: boolean) => Promise<void> | void;
  onSort?: (
    column?: keyof ColumnConfig,
    direction?: OrderByDirectionEnum,
  ) => Promise<unknown> | unknown;
  onPageChange?: (
    page: number,
    column?: keyof ColumnConfig,
    direction?: OrderByDirectionEnum,
  ) => void;
  paginationMeta?: PaginationMeta;
}> = ({
  backgroundClass,
  count,
  defaultSortColumn,
  defaultSortDirection,
  emptyStateLabel,
  loading = false,
  onCancelShiftRequest,
  onPageChange,
  onSort,
  onToggle,
  paginationMeta,
  positionTagGroups,
  requests,
  title,
  warning,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const containerBgClass = warning
    ? 'bg-status-warning-light'
    : backgroundClass || 'bg-white';

  const [handleSort, { sortedColumn, sortDirection, resetSorting }] = useSort<
    keyof ColumnConfig
  >({
    onSort,
    defaultSortColumn,
    defaultSortDirection,
  });

  const requestRows = useMemo(
    () =>
      requests?.map((request) => ({
        request,
        tableData: {
          ...getColumnsConfig({
            positionTagGroups,
            request,
          }),
        },
      })),
    [positionTagGroups, requests],
  );

  const columns = useMemo(() => {
    const colNames =
      requestRows && requestRows.length
        ? Object.keys(requestRows[0].tableData)
        : [];

    return colNames.map((colName) => {
      const isSortable =
        requestRows && requestRows[0]?.tableData[colName]?.sortable;

      return (
        <TableHeaderCell
          key={colName}
          sortDirection={sortedColumn === colName ? sortDirection : undefined}
          onClick={isSortable ? handleSort(colName) : undefined}
        >
          {requestRows && requestRows[0]?.tableData[colName]?.displayName}
        </TableHeaderCell>
      );
    });
  }, [handleSort, sortDirection, sortedColumn, requestRows]);

  const handleToggle = () => {
    onToggle?.(isExpanded);
    setIsExpanded(!isExpanded);

    if (isExpanded) {
      resetSorting();
    }
  };

  const handleCancel = (id: string) => {
    onCancelShiftRequest(id);
  };

  return (
    <>
      <div
        className={`${containerBgClass} border-support-line-darker flex cursor-pointer items-center border-b px-4 py-3`}
        onClick={handleToggle}
        data-testid={'shift-requests-list'}
      >
        <h5 className="text-preset-7 text-ink-dark flex-1 font-medium ">
          {title} ({count})
          {loading ? (
            <span
              className="text-ink-not-as-dark"
              data-testid="shift-requests-label-loading"
            >
              {' '}
              Loading...
            </span>
          ) : null}
        </h5>

        <div className="border-support-line-darker bg-support-line text-ink-not-as-dark text-preset-7 ml-4 rounded border p-1 text-center">
          {isExpanded ? (
            loading ? (
              <div
                className="h-3 w-3"
                data-testid="shift-requests-panel-loading"
              >
                ...
              </div>
            ) : (
              <ChevronUp
                className="h-3 w-3"
                data-testid="shift-requests-collapse-indicator-up"
              />
            )
          ) : (
            <ChevronDown
              className="h-3 w-3"
              data-testid="shift-requests-collapse-indicator-down"
            />
          )}
        </div>
      </div>

      {isExpanded && requests ? (
        <>
          <table className="border-support-line min-w-full border-b">
            <thead className="bg-background-app">
              <tr>
                {columns.map((column) => column)}

                <Can I="update" a="Staffing">
                  <TableHeaderCell key="action">action</TableHeaderCell>
                </Can>
              </tr>
            </thead>

            <tbody className="divide-y divide-gray-200">
              {requestRows &&
                requestRows.map((row) => (
                  <tr
                    key={row.request.id}
                    className={`${
                      loading ? 'opacity-50' : 'opacity-100'
                    } bg-background-surface transition duration-100`}
                    data-testid="shift-requests-row"
                  >
                    {Object.entries(row.tableData).map(
                      ([columnName, columnCell]) => (
                        <Fragment key={columnName}>
                          {columnCell.renderComponent}
                        </Fragment>
                      ),
                    )}

                    <Can I="update" a="Staffing">
                      {row.request.status === 'PENDING' && (
                        <DataCell className="whitespace-nowrap">
                          <button
                            className="text-status-destructive px-2"
                            onClick={() => handleCancel(row.request.id)}
                            data-cy="cancel-request-button"
                            data-testid="shift-requests-cancel-button"
                          >
                            Cancel Request
                          </button>
                        </DataCell>
                      )}
                    </Can>
                  </tr>
                ))}

              {!requests.length && !loading && (
                <tr>
                  <DataCell
                    className="text-preset-6 text-ink-not-as-dark font-normal"
                    colSpan={3}
                  >
                    {emptyStateLabel}
                  </DataCell>
                </tr>
              )}
            </tbody>
          </table>

          {paginationMeta && paginationMeta.totalPages > 1 && (
            <div className="mt-0.5 flex h-12 flex-col items-end pr-4">
              <Pagination
                currentPage={paginationMeta?.currentPage}
                pageCount={paginationMeta?.totalPages || 0}
                onPageChange={(page) =>
                  onPageChange?.(page, sortedColumn, sortDirection)
                }
              />
            </div>
          )}
        </>
      ) : null}
    </>
  );
};

export default RequestsList;
