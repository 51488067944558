import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import {
  AdminTenderTags,
  AdminTenderTagsVariables,
} from './__generated__/AdminTenderTags';

const GET_TENDER = gql`
  query AdminTenderTags($id: ID!, $skillTags: Boolean) {
    tender(id: $id) {
      __typename
      id
      tags(onlyWhitelisted: true, skillTags: $skillTags)
    }
  }
`;

export const useTenderTagsQuery = (
  options?: QueryHookOptions<AdminTenderTags, AdminTenderTagsVariables>,
) => useQuery<AdminTenderTags, AdminTenderTagsVariables>(GET_TENDER, options);

export default GET_TENDER;
