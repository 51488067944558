import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import SHIFT_FOR_STAFFING_MUTATION from '../fragments/ShiftForStaffingMutation';
import {
  ApproveTenderApplications,
  ApproveTenderApplicationsVariables,
} from './__generated__/ApproveTenderApplications';

const APPROVE_TENDER_APPLICATIONS = gql`
  mutation ApproveTenderApplications($staffingIds: [ID!]!) {
    mutationPayload: approveTenderApplications(staffingIds: $staffingIds) {
      modifiedStaffings {
        id
        status
        shift {
          ...ShiftForStaffingMutation
        }
      }
      errors {
        staffingId
        message
      }
    }
  }
  ${SHIFT_FOR_STAFFING_MUTATION}
`;

export const useApproveTenderApplicationsMutation = (
  options?: MutationHookOptions<
    ApproveTenderApplications,
    ApproveTenderApplicationsVariables
  >,
) =>
  useMutation<ApproveTenderApplications, ApproveTenderApplicationsVariables>(
    APPROVE_TENDER_APPLICATIONS,
    options,
  );

export default APPROVE_TENDER_APPLICATIONS;
