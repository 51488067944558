import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import {
  GetRosterForAdmin,
  GetRosterForAdminVariables,
} from './__generated__/GetRosterForAdmin';

const GET_ROSTER = gql`
  query GetRosterForAdmin($id: ID!) {
    roster(id: $id) {
      __typename
      id
      job {
        id
        name
        startDateTime
        endDateTime
        shifts {
          id
          startDateTime
          endDateTime
          quantity
          staffedSlots
          position {
            name
          }
        }
        venue {
          name
          address {
            timezone
            streetName
            streetNumber
            interiorNumber
            neighborhood
            city
            state
            country
            zip
          }
        }
      }
      client {
        id
        firstName
        lastName
      }
    }
  }
`;

export const useGetRosterForAdminQuery = (
  options?: QueryHookOptions<GetRosterForAdmin, GetRosterForAdminVariables>,
) =>
  useQuery<GetRosterForAdmin, GetRosterForAdminVariables>(GET_ROSTER, options);

export default GET_ROSTER;
