import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  UpdateAttireAsAdmin,
  UpdateAttireAsAdminVariables,
} from './__generated__/UpdateAttireAsAdmin';

const UPDATE_ATTIRE = gql`
  mutation UpdateAttireAsAdmin($input: UpdateJobInstructionInput!) {
    updateJobInstructions(input: $input) {
      __typename
      id
      attire
      blackApron
      blackNonSlipShoes
      barKit
      knifeKit
      maskRequired
    }
  }
`;

export const useUpdateAttireAsAdminMutation = (
  options?: MutationHookOptions<
    UpdateAttireAsAdmin,
    UpdateAttireAsAdminVariables
  >,
) =>
  useMutation<UpdateAttireAsAdmin, UpdateAttireAsAdminVariables>(
    UPDATE_ATTIRE,
    options,
  );

export default UPDATE_ATTIRE;
