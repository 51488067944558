import React from 'react';
import { NavLink } from 'react-router-dom';
import AuthScreenShell from 'src/components/AuthScreenShell';

const PasswordResetSuccessView: React.FC = () => (
  <AuthScreenShell label="Admin">
    <div>
      <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
        New Password set!
      </h2>
    </div>

    <div className="mt-8">
      <div className="mt-6">
        <div>Use your email and password to sign into the admin portal.</div>
        <NavLink
          to="/signin"
          className="text-primary my-8 block rounded-md bg-white py-2"
        >
          Go back to Sign in
        </NavLink>
      </div>
    </div>
  </AuthScreenShell>
);

export default PasswordResetSuccessView;
