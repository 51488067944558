export const transformErrorMessage = (original: string) => {
  return original.replace('[@Cyberpunk]:', '').trim();
};

export const wrongCredentialsMessage =
  'Your email and password did not match any records in our database. Please double check your email and password are correct.';

export const serviceOutageMessage =
  'Our service is having a problem, our team is already working on it.';

export const unexpectedErrorMessage =
  'An unexpected error occurred, our team has been notified. Please check back in a moment!';
