import React from 'react';

const CameraIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    viewBox="0 0 14 12"
    fill="currentColor"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.71429 1.71429V0H2.85714V1.71429H4.57143V2.85714H2.85714V4.57143H1.71429V2.85714H0V1.71429H1.71429ZM3.42857 5.14286V3.42857H5.14286V1.71429H9.14286L10.1886 2.85714H12C12.6286 2.85714 13.1429 3.37143 13.1429 4V10.8571C13.1429 11.4857 12.6286 12 12 12H2.85714C2.22857 12 1.71429 11.4857 1.71429 10.8571V5.14286H3.42857ZM7.42857 10.2857C9.00571 10.2857 10.2857 9.00571 10.2857 7.42857C10.2857 5.85143 9.00571 4.57143 7.42857 4.57143C5.85143 4.57143 4.57143 5.85143 4.57143 7.42857C4.57143 9.00571 5.85143 10.2857 7.42857 10.2857ZM5.6 7.42857C5.6 8.44 6.41714 9.25714 7.42857 9.25714C8.44 9.25714 9.25714 8.44 9.25714 7.42857C9.25714 6.41714 8.44 5.6 7.42857 5.6C6.41714 5.6 5.6 6.41714 5.6 7.42857Z"
      fill="currentColor"
    />
  </svg>
);

export default CameraIcon;
