import { gql, QueryHookOptions, useLazyQuery } from '@apollo/client';
import { CustomPositionsV2 } from './__generated__/CustomPositionsV2';

export const CUSTOM_POSITIONS_QUERY_V2 = gql`
  query CustomPositionsV2(
    $searchTerm: String
    $includeByIds: [String]
    $excludeByIds: [String]
  ) {
    customPositionsV2(
      searchTerm: $searchTerm
      includeByIds: $includeByIds
      excludeByIds: $excludeByIds
    ) {
      id
      name
      client {
        id
        email
        firstName
        lastName
      }
    }
  }
`;

export const useCustomPositionV2Query = (
  options?: QueryHookOptions<CustomPositionsV2>,
) => useLazyQuery<CustomPositionsV2>(CUSTOM_POSITIONS_QUERY_V2, options);

export default CUSTOM_POSITIONS_QUERY_V2;
