import React from 'react';

const Eye: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    width="18"
    height="14"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M11.5003 6.99984C11.5003 8.38055 10.381 9.49984 9.00029 9.49984C7.61957 9.49984 6.50029 8.38055 6.50029 6.99984C6.50029 5.61913 7.61957 4.49984 9.00029 4.49984C10.381 4.49984 11.5003 5.61913 11.5003 6.99984Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.04883 6.99981C2.11072 3.6189 5.26929 1.1665 9.00065 1.1665C12.732 1.1665 15.8906 3.61893 16.9525 6.99987C15.8906 10.3808 12.732 12.8332 9.00067 12.8332C5.26929 12.8332 2.11071 10.3807 1.04883 6.99981Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Eye;
