import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import parsePhoneNumber from 'libphonenumber-js';

import ChevronUp from 'src/components/Icons/ChevronUp';
import ClearableInput from 'src/components/ClearableInput';
import Loading from 'src/components/Loading';
import Pill from 'src/components/Pill';
import MailIcon from 'src/components/Icons/Mail';
import MarkerIcon from 'src/components/Icons/MarkerIcon';
import PhoneIcon from 'src/components/Icons/Phone';
import QRIcon from 'src/components/Icons/QR';
import { useTendersQuery } from 'src/graphql/queries/GetTenderList';
import { AdminTenderList_tenders_items as Tender } from 'src/graphql/queries/__generated__/AdminTenderList';
import { FilterOperator } from 'src/__generated__/globalTypes';
import { getTenderStatus } from 'src/utils/tender';

const ITEMS_PER_PAGE = 25;

const TenderList: React.FC<{ tenders?: Tender[] }> = ({ tenders }) => {
  const history = useHistory();
  if (!tenders) {
    return null;
  }
  return (
    <ul className="shadow-sm">
      {tenders.map((tender, index) => (
        <li
          className={`border-color-gray-200 flex cursor-pointer items-center justify-between ${
            index > 0 ? ' border-t' : ''
          }`}
          onClick={() => {
            history.push(`tender/${tender.id}`);
          }}
          key={tender.id}
        >
          <div className="m-5 flex items-center">
            <div className="mr-5 h-9 w-9 overflow-hidden rounded-full">
              {tender.avatarURL ? (
                <img
                  className="inline-block h-full w-full "
                  src={tender.avatarURL}
                  alt="avatar"
                />
              ) : (
                <div className="bg-primary-light text-ink-primary flex h-full w-full flex-col justify-center text-center">
                  {tender.firstName?.[0]}
                  {tender.lastName?.[0]}
                </div>
              )}
            </div>
            <div className="flex flex-col">
              <div className="text-preset-5 text-ink-dark">
                {tender.firstName} {tender.lastName}{' '}
                {tender.preferredName && `(${tender.preferredName})`}
                {tender.hasCompletedOnboardingW2 && (
                  <Pill className="ml-2" status="none">
                    W-2
                  </Pill>
                )}
                {tender.hasCompletedOnboarding1099 && (
                  <Pill className="ml-2" status="none">
                    1099
                  </Pill>
                )}
                <Pill
                  className="ml-2"
                  status={tender.blockedAt ? 'destructive' : 'positive'}
                >
                  {getTenderStatus(tender)}
                </Pill>
              </div>
              <div className="text-preset-6 text-ink-not-as-dark mt-2 flex items-center">
                <QRIcon className="mr-1.5" />
                <p>{tender.id}</p>
                <PhoneIcon className="mr-1.5 ml-4" />
                <p>{parsePhoneNumber(tender.phoneNumber)?.formatNational()}</p>
                {tender.email && (
                  <>
                    <MailIcon className="mr-1.5 ml-4" />
                    <p>{tender.email}</p>
                  </>
                )}
                {tender.regionName && (
                  <>
                    <MarkerIcon className="mr-1.5 ml-4" />
                    <p>{tender.regionName}</p>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="cursor-pointer" data-cy={`${tender.id}-details`}>
            <ChevronUp className="mt-1 mr-8 h-3 w-3 rotate-90 transform text-gray-400" />
          </div>
        </li>
      ))}
    </ul>
  );
};

const TendersView: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const { data, loading, fetchMore, error } = useTendersQuery({
    variables: {
      filters: searchTerm
        ? [
            {
              property: 'searchTerm',
              operator: FilterOperator.equals,
              values: [searchTerm],
            },
          ]
        : undefined,
      paginationOptions: {
        page: 1,
        limit: ITEMS_PER_PAGE,
      },
    },
  });
  const currentPage = data?.tenders.meta.currentPage || 1;
  const totalPages = data?.tenders.meta.totalPages || 0;
  const hasNextPage = currentPage < totalPages;
  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () => {
      return fetchMore({
        variables: {
          filters: searchTerm
            ? [
                {
                  property: 'searchTerm',
                  operator: FilterOperator.equals,
                  values: [searchTerm],
                },
              ]
            : undefined,
          paginationOptions: {
            page: currentPage + 1,
            limit: ITEMS_PER_PAGE,
          },
        },
      });
    },
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: !!error,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: '0px 0px 400px 0px',
  });
  const totalTenders = data?.tenders.meta.totalItems || 0;
  const parsedTotalTenders =
    totalTenders > 1000
      ? `${parseFloat((totalTenders / 1000).toFixed(1))}k`
      : totalTenders;

  return (
    <div className="text-align-right bg-background-app min-h-full min-w-full object-center pt-5 pb-8 sm:px-6 lg:px-8">
      <div className="bg-background-surface border-support-line mx-auto mb-6 flex max-w-screen-lg items-center justify-between rounded border px-4 py-5">
        <p className="text-preset-3 text-ink-dark">
          {parsedTotalTenders} Tender{totalTenders !== 1 ? 's' : ''}
        </p>
        <div className="w-96">
          <ClearableInput
            className="rounded border"
            onClear={() => setSearchTerm('')}
          >
            <input
              className="w-full rounded px-8 py-1 outline-none"
              value={searchTerm}
              onChange={(event) => setSearchTerm(event.target.value)}
              placeholder="Search by Name, Phone or ID"
              data-cy="search-tender-input"
            />
          </ClearableInput>
        </div>
      </div>
      <div className="text-align-left w-100% bg-background-surface border-support-line mx-auto mt-5 max-w-screen-lg rounded border">
        <TenderList tenders={data?.tenders.items} />
        {(loading || hasNextPage) && (
          <div ref={sentryRef}>
            <Loading className="h-5 w-full" />
          </div>
        )}
      </div>
    </div>
  );
};

export default TendersView;
