import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import {
  AdminTenderNotes,
  AdminTenderNotesVariables,
} from './__generated__/AdminTenderNotes';

const GET_TENDER_NOTES = gql`
  query AdminTenderNotes($id: ID!) {
    tender(id: $id) {
      __typename
      id
      notes {
        note
        id
        createdAt
      }
    }
  }
`;

export const useTenderNotesQuery = (
  options?: QueryHookOptions<AdminTenderNotes, AdminTenderNotesVariables>,
) =>
  useQuery<AdminTenderNotes, AdminTenderNotesVariables>(
    GET_TENDER_NOTES,
    options,
  );

export default GET_TENDER_NOTES;
