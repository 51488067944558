import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  AdminUpdateTenderPhotoId,
  AdminUpdateTenderPhotoIdVariables,
} from './__generated__/AdminUpdateTenderPhotoId';

const UPDATE_TENDER = gql`
  mutation AdminUpdateTenderPhotoId(
    $tenderId: ID!
    $input: AdminTenderPhotoIdInput!
  ) {
    updateTenderPhotoId(tenderId: $tenderId, input: $input) {
      __typename
      id
      frontPhotoIdURL
    }
  }
`;

export const useUpdateTenderPhotoIdMutation = (
  options?: MutationHookOptions<
    AdminUpdateTenderPhotoId,
    AdminUpdateTenderPhotoIdVariables
  >,
) =>
  useMutation<AdminUpdateTenderPhotoId, AdminUpdateTenderPhotoIdVariables>(
    UPDATE_TENDER,
    options,
  );

export default UPDATE_TENDER;
