import React from 'react';
import { StaffingStatus } from 'src/__generated__/globalTypes';
import { calculateRelativeTime } from 'src/utils/dates';

type StatusStyle = { color: string; backgroundColor: string };

const statusStyles = {
  primary: {
    color: 'text-ink-primary',
    backgroundColor: 'bg-status-positive-light',
  },
  secondary: {
    color: 'text-ink-secondary',
    backgroundColor: 'bg-status-info-light',
  },
  destructive: {
    color: 'text-status-destructive',
    backgroundColor: 'bg-status-destructive-light',
  },
  destructiveDark: {
    color: 'text-ink-dark',
    backgroundColor: 'bg-status-destructive-light',
  },
  warningDark: {
    color: 'text-ink-dark',
    backgroundColor: 'bg-status-warning-light',
  },
  dark: { color: 'text-ink-clear', backgroundColor: 'bg-ink-dark' },
};
const statusMap = new Map<
  StaffingStatus | 'default',
  { label: string } & StatusStyle
>([
  [StaffingStatus.APPROVED, { label: 'Approved', ...statusStyles.primary }],
  [StaffingStatus.STAFFED, { label: 'Approved', ...statusStyles.primary }],
  [StaffingStatus.CONFIRMED, { label: 'Confirmed', ...statusStyles.secondary }],
  [StaffingStatus.PENDING, { label: 'Applied', ...statusStyles.dark }],
  [StaffingStatus.UNCONFIRMED, { label: 'Applied', ...statusStyles.dark }],
  [StaffingStatus.DENIED, { label: 'Denied', ...statusStyles.destructive }],
  [StaffingStatus.CANCELLED, { label: 'Denied', ...statusStyles.destructive }],
  [
    StaffingStatus.WITHDRAWN,
    { label: 'Withdrawn', ...statusStyles.warningDark },
  ],
  [StaffingStatus.CALLOUT, { label: 'Call-Out', ...statusStyles.warningDark }],
  [
    StaffingStatus.FAULT,
    { label: 'Removed (F)', ...statusStyles.destructiveDark },
  ],
  [
    StaffingStatus.REMOVED,
    { label: 'Removed', ...statusStyles.destructiveDark },
  ],
  ['default', { label: '', ...statusStyles.dark }],
]);
const StaffingStatusBadge: React.FC<{
  status: StaffingStatus;
  createdAt: Date;
}> = ({ status, createdAt }) => {
  const statusConfig = statusMap.get(status) || statusMap.get('default');
  const isInAppliedStatus = [
    StaffingStatus.PENDING,
    StaffingStatus.UNCONFIRMED,
  ].includes(status);
  const relativeTimeFromApplied =
    isInAppliedStatus && createdAt
      ? ` / ${calculateRelativeTime(createdAt)}`
      : '';

  return (
    <span
      className={`text-preset-6 inline-flex items-center whitespace-nowrap rounded-full px-3 py-1.5 font-medium capitalize ${statusConfig?.backgroundColor} ${statusConfig?.color}`}
    >
      {statusConfig?.label || status.replace('_', ' ').toLowerCase()}
      {relativeTimeFromApplied}
    </span>
  );
};

export default StaffingStatusBadge;
