import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { ApolloProvider } from '@apollo/client';
import LoginView from 'src/views/Login';
import {
  AcceptInvitationView,
  ResetPasswordView,
} from 'src/views/SetNewPassword';
import PasswordResetSuccessView from 'src/views/PasswordResetSuccess';
import RequestPasswordResetView from 'src/views/RequestPasswordReset';
import Application from 'src/views/Application';
import PrivateRoute from 'src/components/PrivateRoute';
import UnauthenticatedOnlyRoute from 'src/components/UnauthenticatedOnlyRoute';
import AuthContextProvider from 'src/contexts/AuthContext';
import AbilityContextProvider from 'src/contexts/AbilityContext';
import { RosterSelectionContextProvider } from 'src/contexts/RostersSelectionContext';
import { StaffingSelectionContextProvider } from 'src/contexts/StaffingsSelectionContext';
import { PageTitleContextProvider } from 'src/contexts/PageTitleContext';
import client from 'src/ApolloClient';
import { ApprovedStaffingSelectionContextProvider } from './contexts/ApprovedStaffingsSelectionContext';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENVIRONMENT,
});

const App: React.FC = () => {
  return (
    <PageTitleContextProvider>
      <AuthContextProvider>
        <AbilityContextProvider>
          <RosterSelectionContextProvider>
            <StaffingSelectionContextProvider>
              <ApprovedStaffingSelectionContextProvider>
                <ApolloProvider client={client}>
                  <Router>
                    <Switch>
                      <UnauthenticatedOnlyRoute
                        exact
                        path="/signin"
                        component={LoginView}
                      />
                      <UnauthenticatedOnlyRoute
                        path="/accept-invitation"
                        component={AcceptInvitationView}
                      />
                      <UnauthenticatedOnlyRoute
                        path="/reset-password"
                        component={ResetPasswordView}
                      />
                      <UnauthenticatedOnlyRoute
                        exact
                        path="/forgot-password"
                        component={RequestPasswordResetView}
                      />
                      <UnauthenticatedOnlyRoute
                        path="/password-reset-success"
                        component={PasswordResetSuccessView}
                      />
                      <PrivateRoute path="/" component={Application} />
                    </Switch>
                  </Router>
                </ApolloProvider>
              </ApprovedStaffingSelectionContextProvider>
            </StaffingSelectionContextProvider>
          </RosterSelectionContextProvider>
        </AbilityContextProvider>
      </AuthContextProvider>
    </PageTitleContextProvider>
  );
};

export default App;
