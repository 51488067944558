import React from 'react';
import DataCell from './DataCell';
import ConflictsIndicator from '../ConflictsIndicator/ConflictsIndicator';

const ConflictsCell: React.FC<{ type?: 'soft' | 'hard' }> = ({
  type,
  ...props
}) => (
  <DataCell data-testid="conflicts-cell" {...props}>
    <ConflictsIndicator type={type} />
  </DataCell>
);

export default ConflictsCell;
