import React from 'react';

type StyleProps = {
  primary?: boolean;
  destructive?: boolean;
  noBackground?: boolean;
};

const Button: React.FC<
  StyleProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ children, className, primary, destructive, noBackground, ...props }) => {
  const getStyle = () => {
    if (primary && !noBackground) {
      return 'bg-primary text-ink-clear';
    } else if (destructive && noBackground) {
      return 'text-status-destructive';
    } else {
      return 'text-ink-dark border border-ink-dark';
    }
  };

  return (
    <button
      className={`text-preset-6 rounded-md py-2 px-4 ${getStyle()} ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
