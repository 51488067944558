import React, { FC, useState } from 'react';

import Combobox from 'src/components/Combobox';
import ChevronDown from 'src/components/Icons/ChevronDown';
import Plus from 'src/components/Icons/Plus';
import Loading from 'src/components/Loading';
import Pill from 'src/components/Pill';
import { useTagsQuery } from 'src/graphql/queries/Tags';

type Item = { id: string; label: string };
const SEARCH_TERM_LABEL = 'SEARCH_TERM_LABEL';

const TagComponent: FC<{
  item: Item;
  index: number;
  highlighted: boolean;
}> = ({ item, highlighted }) => {
  if (item.label === SEARCH_TERM_LABEL) {
    return (
      <div
        className={`flex cursor-pointer py-2 px-4 ${
          highlighted ? 'bg-background-app' : ''
        }`}
      >
        <Plus className="text-preset-5 text-ink-brand mr-4" />
        <p className="text-preset-6 text-ink-not-as-dark font-medium">
          Create "{item.id}" tag
        </p>
      </div>
    );
  }
  return (
    <div className={`py-2 px-4 ${highlighted ? 'bg-background-app' : ''}`}>
      <p className="text-preset-6 text-ink-dark font-medium">{item.id}</p>
    </div>
  );
};

type Props = {
  selectedTags: string[];
  onChange: (selectedTag: string) => void;
  onRemove: (selectedTag: string) => void;
  className?: string;
  title?: string;
  defaultValue?: string;
  skillTags?: boolean;
};

const getItems = (
  selectedTags: string[],
  tags?: string[],
  searchTerm?: string,
  skillTags?: boolean,
): Item[] => {
  if (!searchTerm && !skillTags) {
    return [];
  }
  const tagsMapped =
    tags
      ?.map((tag) => ({ id: tag, label: tag }))
      .filter((tag) => !selectedTags.includes(tag.label)) || [];
  if (!searchTerm) {
    return tagsMapped;
  }
  return [...tagsMapped, { id: searchTerm, label: SEARCH_TERM_LABEL }];
};

const DefaultTagsInput: FC<Props> = ({
  selectedTags,
  onChange,
  onRemove,
  className,
  skillTags,
  title = 'Tags',
}) => {
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const { data, loading } = useTagsQuery({
    variables: {
      whitelisted: true,
      searchTerm,
      skillTags,
    },
    fetchPolicy: 'cache-and-network',
  });
  const addTag = (addedTag: string) => {
    if (selectedTags.some((selected) => selected === addedTag)) {
      return;
    }
    onChange(addedTag);
  };
  const removeTag = (removedTag: string) => {
    onRemove(removedTag);
  };
  return (
    <div className={className}>
      <Combobox
        id={`${skillTags ? 'skill' : ''}tagsCombobox`}
        placeholder="Search for a tag to add"
        inputClassName="focus:ring-primary focus:border-primary sm:text-preset-6 border-support-line-darker rounded cursor-pointer"
        items={getItems(selectedTags, data?.tags, searchTerm, skillTags)}
        label={title}
        loadingItems={loading}
        onChange={(selectedItem) => selectedItem && addTag(selectedItem.id)}
        onInputChange={setSearchTerm}
        onReset={() => setSearchTerm(undefined)}
        openMenuOnFocus
        renderItemComponent={TagComponent}
        renderLoadingComponent={() => (
          <div className="flex justify-center">
            <Loading />
          </div>
        )}
        renderNoResultsComponent={() => (
          <p className="py-2 px-4">No tags found</p>
        )}
        renderTrailingComponent={() => (
          <ChevronDown className="text-ink-not-as-dark h-3 w-3" />
        )}
        data-cy={`tags-filter${skillTags ? '-skill' : ''}`}
      />
      <div className="flex flex-wrap">
        {selectedTags.map((tag) => {
          return (
            <Pill
              key={tag}
              onClose={() => removeTag(tag)}
              className="mr-2 mb-2"
              bgColor="bg-indigo-100"
              textColor="text-indigo-800"
              toolTipText={tag}
              data-cy={`pill-${tag.toLowerCase()}`}
            >
              {tag}
            </Pill>
          );
        })}
      </div>
    </div>
  );
};

export default DefaultTagsInput;
