import React from 'react';
import ReactSelect, { GroupBase, Props } from 'react-select';

// See https://react-select.com/typescript#typescript-usage for details on why we need this type wrapper.
export default function Select<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: Props<Option, IsMulti, Group>) {
  return <ReactSelect {...props} />;
}
