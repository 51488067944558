import React, { useState } from 'react';

import Modal from 'src/components/Modal';
import QRIcon from 'src/components/Icons/QR';
import { AdminTenderDetails_tender_lastDevice as Device } from 'src/graphql/queries/__generated__/AdminTenderDetails';
import { useGetTenderDevicesForAdminQuery } from 'src/graphql/queries/GetTenderDevices';
import TableHeaderCell from 'src/components/TableHeaderCell';
import Button from 'src/components/Button';

const Cell: React.FC<{ className?: string }> = ({ children, className }) => (
  <td
    className={`text-preset-6 text-ink-dark bg-background-surface px-4 ${className}`}
  >
    <p>{children}</p>
  </td>
);

const DevicesModal: React.FC<{
  open: boolean;
  onClose: () => void;
  tenderId: string;
}> = ({ open, onClose, tenderId }) => {
  const { data } = useGetTenderDevicesForAdminQuery({
    variables: { tenderId },
  });
  return (
    <Modal className="p-4" open={open} onClose={onClose}>
      <p className="text-ink-dark text-preset-3 font-medium">Devices</p>
      <table className="mt-4 min-w-full table-fixed">
        <thead className="bg-background-app">
          <tr className="h-12">
            <TableHeaderCell className="bg-background-app">OS</TableHeaderCell>
            <TableHeaderCell className="bg-background-app">
              Device ID
            </TableHeaderCell>
          </tr>
        </thead>
        <tbody>
          {data?.tenderDevices.map((device) => (
            <tr
              className="border-support-line h-14 border-b"
              key={device.deviceId}
            >
              <Cell>{device.os}</Cell>
              <Cell>{device.deviceId}</Cell>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mt-4 flex justify-end">
        <Button onClick={onClose}>Close</Button>
      </div>
    </Modal>
  );
};

const DevicesCard: React.FC<{ device: Device | null; tenderId: string }> = ({
  device,
  tenderId,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="bg-background-surface rounded">
      {open && (
        <DevicesModal
          open={open}
          onClose={() => setOpen(false)}
          tenderId={tenderId}
        />
      )}
      <div className="mb-4 px-4 pt-6 pb-2">
        <div className="flex justify-between">
          <p className="text-preset-3 text-ink-dark mb-4 font-medium">
            Devices
          </p>
          {device && (
            <div
              className="mt-2 flex cursor-pointer items-start"
              onClick={() => setOpen(true)}
            >
              <p className="text-preset-6 text-ink-primary">See all</p>
            </div>
          )}
        </div>
        {device ? (
          <>
            <div className="border-support-lines border-b">
              <p className="text-preset-6 text-ink-not-as-dark my-4 flex">
                OS: {device.os}
              </p>
            </div>
            <div>
              <p className="text-preset-6 text-ink-not-as-dark my-4 flex">
                <QRIcon className="mr-3" />
                {device.deviceId}
              </p>
            </div>
          </>
        ) : (
          <p className="text-preset-6 text-ink-not-as-dark my-4 flex">
            No device added yet
          </p>
        )}
      </div>
    </div>
  );
};

export default DevicesCard;
