import React from 'react';

const Download: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={className}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5882 4.00071H10.8235C10.6363 4.00071 10.4568 4.07508 10.3244 4.20745C10.192 4.33983 10.1176 4.51938 10.1176 4.70659C10.1176 4.8938 10.192 5.07334 10.3244 5.20572C10.4568 5.3381 10.6363 5.41247 10.8235 5.41247H12.4118C12.4586 5.41247 12.5035 5.43106 12.5365 5.46416C12.5696 5.49725 12.5882 5.54214 12.5882 5.58894V14.4125C12.5882 14.4593 12.5696 14.5042 12.5365 14.5373C12.5035 14.5703 12.4586 14.5889 12.4118 14.5889H3.58824C3.54143 14.5889 3.49655 14.5703 3.46345 14.5373C3.43036 14.5042 3.41176 14.4593 3.41176 14.4125V5.58824C3.41176 5.54143 3.43036 5.49655 3.46345 5.46345C3.49655 5.43036 3.54143 5.41176 3.58824 5.41176H5.17647C5.36368 5.41176 5.54323 5.3374 5.6756 5.20502C5.80798 5.07264 5.88235 4.89309 5.88235 4.70588C5.88235 4.51867 5.80798 4.33913 5.6756 4.20675C5.54323 4.07437 5.36368 4 5.17647 4H3.41176C3.03734 4 2.67825 4.14874 2.4135 4.4135C2.14874 4.67825 2 5.03734 2 5.41176V14.5882C2 14.9627 2.14874 15.3217 2.4135 15.5865C2.67825 15.8513 3.03734 16 3.41176 16H12.5882C12.9627 16 13.3217 15.8513 13.5865 15.5865C13.8513 15.3217 14 14.9627 14 14.5882V5.41247C14 5.03805 13.8513 4.67896 13.5865 4.4142C13.3217 4.14944 12.9627 4.00071 12.5882 4.00071Z"
      fill="currentColor"
    />
    <path
      d="M7.52892 11.787C7.65396 11.9234 7.82353 12 8.00033 12C8.17714 12 8.3467 11.9234 8.47174 11.787L10.8054 9.24141C10.8984 9.13965 10.9617 9.01013 10.9873 8.86919C11.0129 8.72824 10.9996 8.58219 10.9492 8.44946C10.8988 8.31673 10.8134 8.20326 10.704 8.12337C10.5945 8.04349 10.4658 8.00076 10.334 8.00059H9.16718C9.12297 8.00059 9.08057 7.98143 9.04931 7.94733C9.01805 7.91323 9.00049 7.86698 9.00049 7.81876V1.09099C9.00049 0.801641 8.89512 0.524144 8.70755 0.319543C8.51998 0.114943 8.26559 0 8.00033 0C7.73507 0 7.48068 0.114943 7.29311 0.319543C7.10555 0.524144 7.00017 0.801641 7.00017 1.09099V7.81876C7.00017 7.86698 6.98261 7.91323 6.95135 7.94733C6.92009 7.98143 6.87769 8.00059 6.83348 8.00059H5.66663C5.53478 8.00062 5.40589 8.04329 5.29627 8.12321C5.18664 8.20314 5.1012 8.31672 5.05075 8.4496C5.00029 8.58248 4.98709 8.7287 5.0128 8.86977C5.03852 9.01083 5.102 9.14042 5.19522 9.24213L7.52892 11.787Z"
      fill="currentColor"
    />
  </svg>
);

export default Download;
