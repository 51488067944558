import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import {
  tenderCertificationsVariables,
  tenderCertifications,
} from './__generated__/TenderCertifications';

const QUERY_TENDER_CERTIFICATIONS = gql`
  query tenderCertifications($filters: TenderCertificationFilter!) {
    tenderCertifications(filters: $filters) {
      id
      tenderId
      issueDate
      expirationDate
      certificateNumber
      updatedAt
      createdAt
      status
      attachmentUrl
      tenderFirstName
      tenderLastName
      tenderPhoneNumber
      tenderEmail
      typeReadable
    }
  }
`;

export const useGetTenderCertificationsQuery = (
  options?: QueryHookOptions<
    tenderCertifications,
    tenderCertificationsVariables
  >,
) =>
  useQuery<tenderCertifications, tenderCertificationsVariables>(
    QUERY_TENDER_CERTIFICATIONS,
    options,
  );

export default QUERY_TENDER_CERTIFICATIONS;
