import React, { SVGProps } from 'react';

const Tracking = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 129 129"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit={2}
    {...props}
  >
    <path
      d="M106.621 85.457v-1.256a22.17 22.17 0 0 0-21.497 16.734c.001-.002-57.98-.002-57.98-.002a15.476 15.476 0 1 1 0-30.953h75.294a26.354 26.354 0 0 0 22.823-39.53 26.353 26.353 0 0 0-22.823-13.176h-57.98a22.17 22.17 0 1 0 .001 10.874c-.001.002 57.981.002 57.981.002a15.483 15.483 0 0 1 10.976 4.51 15.48 15.48 0 0 1 .015 21.916 15.472 15.472 0 0 1-10.989 4.528H27.144A26.352 26.352 0 0 0 4.322 72.28a26.353 26.353 0 0 0 22.822 39.53h57.98a22.17 22.17 0 1 0 21.497-27.608v1.255Zm0 32.208a11.294 11.294 0 1 1-.002-22.587 11.294 11.294 0 0 1 .002 22.587Zm-1.109-32.18-.144.009-.002-.038c0-.063.057-.042.146.029ZM22.961 34.006a11.293 11.293 0 0 1-11.294-11.294 11.3 11.3 0 0 1 3.307-7.987 11.3 11.3 0 0 1 15.974 0 11.3 11.3 0 0 1 0 15.973 11.297 11.297 0 0 1-7.987 3.308Z"
      fill="currentColor"
    />
  </svg>
);

export default Tracking;
