import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import {
  GetTenderDevicesForAdmin,
  GetTenderDevicesForAdminVariables,
} from './__generated__/GetTenderDevicesForAdmin';

const GET_TENDER_DEVICES = gql`
  query GetTenderDevicesForAdmin($tenderId: ID!) {
    tenderDevices(tenderId: $tenderId) {
      __typename
      os
      deviceId
    }
  }
`;

export const useGetTenderDevicesForAdminQuery = (
  options?: QueryHookOptions<
    GetTenderDevicesForAdmin,
    GetTenderDevicesForAdminVariables
  >,
) =>
  useQuery<GetTenderDevicesForAdmin, GetTenderDevicesForAdminVariables>(
    GET_TENDER_DEVICES,
    options,
  );

export default GET_TENDER_DEVICES;
