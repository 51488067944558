import React, { createContext, Dispatch, useReducer } from 'react';
import { GetRosterForAdmin_roster as Roster } from 'src/graphql/queries/__generated__/GetRosterForAdmin';

export type RosterId = Roster['id'];
export type SelectedRostersSet = Set<RosterId>;
type Action = {
  type: 'add' | 'remove' | 'clear';
  id?: RosterId;
};

export const RosterSelectionContext = createContext<
  | {
      state: SelectedRostersSet;
      selectionCount?: number;
      dispatch: Dispatch<Action>;
    }
  | undefined
>(undefined);

const reducer = (state: Set<RosterId>, action: Action): Set<RosterId> => {
  switch (action.type) {
    case 'add':
      if (!action.id) {
        throw new Error('roster id is required for selecting');
      }
      state.add(action.id);
      return new Set(state);
    case 'remove':
      if (!action.id) {
        throw new Error('roster id is required for deselecting');
      }
      state.delete(action.id);
      return new Set(state);
    case 'clear':
      return new Set<RosterId>();
    default:
      throw new Error('Unknown action for selected roster reducer');
  }
};

export const RosterSelectionContextProvider: React.FC = ({ children }) => {
  const [selectedRosters, dispatch] = useReducer(reducer, new Set<RosterId>());
  const selectionCount = selectedRosters.size;

  return (
    <RosterSelectionContext.Provider
      value={{ state: selectedRosters, selectionCount, dispatch }}
    >
      {children}
    </RosterSelectionContext.Provider>
  );
};
