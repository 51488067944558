import React, { FC } from 'react';

interface Props {
  width?: number;
  className?: string;
  clear?: boolean;
}

const Loading: FC<Props> = ({
  width = 50,
  className = '',
  clear,
  ...props
}) => (
  <svg
    viewBox="0 0 50 20"
    fill={clear ? '#FFF' : '#333'}
    width={width}
    className={className}
    {...props}
  >
    <circle cx="10" cy="10" r="5">
      <animate
        attributeName="fill-opacity"
        from="1"
        to="1"
        begin="0s"
        dur="0.8s"
        values="1;.5;1"
        calcMode="linear"
        repeatCount="indefinite"
      />
    </circle>
    <circle cx="25" cy="10" r="5">
      <animate
        attributeName="fill-opacity"
        from="1"
        to="1"
        begin="0.2s"
        dur="0.8s"
        values="1;.5;1"
        calcMode="linear"
        repeatCount="indefinite"
      />
    </circle>
    <circle cx="40" cy="10" r="5">
      <animate
        attributeName="fill-opacity"
        from="1"
        to="1"
        begin="0.4s"
        dur="0.8s"
        values="1;.5;1"
        calcMode="linear"
        repeatCount="indefinite"
      />
    </circle>
  </svg>
);

export default Loading;
