import React from 'react';

const PhoneIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.00012207 1C0.00012207 0.447715 0.447837 0 1.00012 0H3.153C3.64183 0 4.05902 0.353413 4.13939 0.835601L4.8787 5.27147C4.95087 5.70451 4.73218 6.13397 4.33952 6.3303L2.79138 7.10437C3.90768 9.87832 6.1218 12.0924 8.89575 13.2087L9.66982 11.6606C9.86615 11.2679 10.2956 11.0492 10.7286 11.1214L15.1645 11.8607C15.6467 11.9411 16.0001 12.3583 16.0001 12.8471V15C16.0001 15.5523 15.5524 16 15.0001 16H13.0001C5.82042 16 0.00012207 10.1797 0.00012207 3V1Z"
      fill="currentColor"
    />
  </svg>
);

export default PhoneIcon;
