import React, { FC, useCallback, useEffect, useState } from 'react';
import Combobox from 'src/components/Combobox';
import Pill from 'src/components/Pill';
import { Regions_regions as RegionItem } from 'src/graphql/queries/__generated__/Regions';
import { useRegionsQuery } from 'src/graphql/queries/Regions';
import ChevronDown from '../Icons/ChevronDown';

const RegionResult: FC<{
  item: RegionItem;
  index: number;
  highlighted: boolean;
}> = ({ item, highlighted }) => {
  return (
    <div
      className={`py-2 px-4 ${highlighted ? 'bg-background-app' : ''}`}
      data-cy={`region-result-${item.id}`}
    >
      <p className="text-ink-dark text-sm font-medium">{item.name}</p>
      <p className="text-ink-not-as-dark text-sm">{item.id}</p>
    </div>
  );
};

type Props = {
  selectedRegionsIds: string[];
  onChange: (selectedRegionsIds: string[]) => void;
  className?: string;
};

const RegionInput: FC<Props> = ({
  selectedRegionsIds,
  onChange,
  className,
}) => {
  const { data, loading } = useRegionsQuery();
  const [selectedRegions, setSelectedRegions] = useState<RegionItem[]>([]);
  const getAllSelectedRegions = useCallback(() => {
    if (!selectedRegionsIds || !data?.regions) {
      return;
    }
    const regions: RegionItem[] = [];
    selectedRegionsIds.forEach((regionId) => {
      const regionFound = data.regions.find(
        (regionToFind) => regionToFind.id === regionId,
      );
      if (regionFound) {
        regions.push(regionFound);
      }
    });
    setSelectedRegions?.(regions);
  }, [data?.regions, selectedRegionsIds, setSelectedRegions]);
  useEffect(() => {
    getAllSelectedRegions();
  }, [getAllSelectedRegions]);

  const addRegion = (region: RegionItem) => {
    if (selectedRegions.some((selected) => selected.id === region.id)) {
      return;
    }
    setSelectedRegions([...selectedRegions, region]);
    onChange([...selectedRegionsIds, region.id]);
  };
  const removeRegion = (region: RegionItem) => {
    const removedIndex = selectedRegionsIds.indexOf(region.id);
    selectedRegionsIds.splice(removedIndex, 1);
    selectedRegions.splice(removedIndex, 1);
    setSelectedRegions([...selectedRegions]);
    onChange([...selectedRegionsIds]);
  };

  return (
    <div className={className}>
      <Combobox
        id="regionsCombobox"
        inputValue="Select your desired regions"
        inputClassName="focus:ring-primary focus:border-primary sm:text-sm border-support-line-darker rounded cursor-pointer"
        inputReadOnly
        items={data?.regions || []}
        label="Regions"
        loadingItems={loading}
        onChange={(selectedItem) => selectedItem && addRegion(selectedItem)}
        onInputChange={() => null}
        onReset={() => null}
        openMenuOnFocus
        renderItemComponent={RegionResult}
        renderLoadingComponent={() => <p className="py-2 px-4">loading...</p>}
        renderNoResultsComponent={() => (
          <p className="py-2 px-4">No regions found</p>
        )}
        renderTrailingComponent={() => (
          <ChevronDown className="text-ink-not-as-dark h-3 w-3" />
        )}
      />
      <div className="flex flex-wrap">
        {selectedRegions.map((region) => {
          return (
            <Pill
              key={region.id}
              onClose={() => removeRegion(region)}
              className="mr-2 mb-2"
              toolTipText={region.id}
              data-cy={`pill-${region.id}`}
            >
              {region.name}
            </Pill>
          );
        })}
      </div>
    </div>
  );
};

export default RegionInput;
