import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  CreateRegion,
  CreateRegionVariables,
} from './__generated__/CreateRegion';

const CREATE_REGION = gql`
  mutation CreateRegion($input: RegionInput!) {
    createRegion(input: $input) {
      id
      name
      polygon {
        lat
        lng
      }
    }
  }
`;

export const useCreateRegionMutation = (
  options?: MutationHookOptions<CreateRegion, CreateRegionVariables>,
) => useMutation<CreateRegion, CreateRegionVariables>(CREATE_REGION, options);

export default CREATE_REGION;
