import { gql, LazyQueryHookOptions, useLazyQuery } from '@apollo/client';
import {
  ShiftRequests,
  ShiftRequestsVariables,
} from './__generated__/ShiftRequests';

const SHIFT_REQUESTS = gql`
  query ShiftRequests(
    $pagination: PaginationOptions!
    $filters: ShiftRequestFilters
  ) {
    shiftRequests(pagination: $pagination, filters: $filters) {
      items {
        id
        shiftId
        tenderId
        tenderNotificationId
        createdAt
        updatedAt
        status
        expiresAt
        clientTenderStatus
        tenderPositionTagGroupMatch
        drivingDistance(unit: MILES)
        drivingTime
        softConflicts
        hardConflicts
        tender {
          avatarURL
          firstName
          id
          lastName
          phoneNumber
          score
        }
        tagGroups {
          id
          name
        }
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;

export const useLazyShiftRequestsQuery = (
  options?: LazyQueryHookOptions<ShiftRequests, ShiftRequestsVariables>,
) =>
  useLazyQuery<ShiftRequests, ShiftRequestsVariables>(SHIFT_REQUESTS, options);

export default SHIFT_REQUESTS;
