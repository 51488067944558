import H from 'history';

import jwt_decode from 'jwt-decode';

export type Audience = 'admin' | 'customer' | 'tender' | 'client' | 'nowsta';

export interface Authorization {
  sub: string;
  exp?: number;
  aud: Audience[];
  email?: string;
}

const unauthenticatedOnlyUris: ReadonlyArray<string> = [
  '/signin',
  '/forgot-password',
  '/accept-invitation',
  '/reset-password',
  '/password-reset-success',
];

const nowstaAllowedUris: ReadonlyArray<string> = [
  '/',
  '/staffing',
  ...unauthenticatedOnlyUris,
];
const tokenExpirationTolerance = 1000 * 60; // One minute

export const decodeToken = (token: string): Authorization | undefined => {
  try {
    return jwt_decode<Authorization>(token);
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export const tokenIsValid = (token: string): boolean => {
  let decoded: Authorization;
  try {
    if (!token) {
      return false;
    }
    decoded = jwt_decode<Authorization>(token);
  } catch (error) {
    console.error(error);
    return false;
  }
  if (!decoded.exp) {
    return true;
  }
  const now = Date.now();
  const exp = decoded.exp * 1000; // convert to milliseconds

  return now < exp - tokenExpirationTolerance;
};

export const validateAuthState = (
  location: H.Location,
  token: string,
): boolean => {
  const isTokenValid = token !== '' && tokenIsValid(token);
  if (!isTokenValid) {
    return false;
  }
  const decodedToken = decodeToken(token);
  if (decodedToken?.aud.includes('nowsta')) {
    return nowstaAllowedUris.includes(location.pathname);
  }
  return true;
};

export const validateUnauthState = (l: H.Location, token: string): boolean => {
  return (
    unauthenticatedOnlyUris.includes(l.pathname) && !validateAuthState(l, token)
  );
};
