import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import apolloClient from 'src/ApolloClient';
import { format } from 'date-fns-tz';
import debounce from 'lodash.debounce';
import Combobox from 'src/components/Combobox';
import Search from 'src/components/Icons/Search';
import Loading from 'src/components/Loading';
import Pill from 'src/components/Pill';
import { AdminJobs_adminJobs_items as JobItem } from 'src/graphql/queries/__generated__/AdminJobs';
import ADMIN_JOBS_QUERY, {
  useAdminJobsQuery,
} from 'src/graphql/queries/AdminJobs';

const JobResult: FC<{ item: JobItem; index: number; highlighted: boolean }> = ({
  item,
  highlighted,
}) => {
  const location =
    item.venue?.address.city && item.venue?.address.state
      ? `${item.venue.address.city}, ${item.venue.address.state}`
      : item.venue?.address.city ||
        item.venue?.address.state ||
        'Unknown location';
  const jobDate = format(new Date(item.startDateTime), 'MM/dd/yyyy');
  return (
    <div
      className={`py-2 px-4 ${highlighted ? 'bg-background-app' : ''}`}
      data-cy={`job-result-${item.id}`}
    >
      <p className="text-preset-6 text-ink-dark font-medium">{item.name}</p>
      <p className="text-preset-7 text-ink-not-as-dark">
        {jobDate} • {location} • {item.id}
      </p>
    </div>
  );
};

type Props = {
  selectedJobsIds: string[];
  onChange: (selectedJobsIds: string[]) => void;
  className: string;
};

const JobInput: FC<Props> = ({ selectedJobsIds, onChange, className }) => {
  const [getJobs, { data: jobsData, loading: jobsLoading }] =
    useAdminJobsQuery();
  const [selectedJobs, setSelectedJobs] = useState<JobItem[]>([]);
  const getAllSelectedJobs = useCallback(async () => {
    const jobs = await Promise.all(
      selectedJobsIds.map(async (jobId) => {
        const { data } = await apolloClient.query({
          query: ADMIN_JOBS_QUERY,
          variables: {
            pagination: {
              limit: 10,
              page: 1,
            },
            options: {
              searchTerm: jobId,
            },
          },
        });
        return data?.adminJobs.items[0];
      }),
    );
    setSelectedJobs(jobs);
  }, [selectedJobsIds]);
  useEffect(() => {
    getAllSelectedJobs();
  }, [getAllSelectedJobs]);

  const addJob = (job: JobItem) => {
    if (selectedJobs.some((selected) => selected.id === job.id)) {
      return;
    }
    setSelectedJobs([...selectedJobs, job]);
    onChange([...selectedJobsIds, job.id]);
  };
  const removeJob = (job: JobItem) => {
    const removedIndex = selectedJobsIds.indexOf(job.id);
    selectedJobsIds.splice(removedIndex, 1);
    selectedJobs.splice(removedIndex, 1);
    setSelectedJobs([...selectedJobs]);
    onChange([...selectedJobsIds]);
  };
  const jobInputChange = useRef(
    debounce((value) => {
      if (!value) {
        return;
      }
      getJobs({
        variables: {
          pagination: {
            limit: 10,
            page: 1,
          },
          options: {
            searchTerm: value,
          },
        },
      });
    }, 300),
  ).current;

  return (
    <div className={className}>
      <Combobox
        id="jobsCombobox"
        label="Jobs"
        description="Search by Job ID or Job Name"
        items={jobsData?.adminJobs.items || []}
        loadingItems={jobsLoading}
        placeholder="Search by ID or name"
        renderLoadingComponent={() => (
          <div className="flex justify-center">
            <Loading />
          </div>
        )}
        renderNoResultsComponent={() => (
          <p className="py-2 px-4">No jobs found</p>
        )}
        renderLeadingComponent={() => (
          <Search className="text-ink-not-as-dark h-3 w-3" />
        )}
        onReset={() => null}
        renderItemComponent={JobResult}
        onChange={(selectedItem) => selectedItem && addJob(selectedItem)}
        onInputChange={jobInputChange}
        data-cy="job-filter"
      />
      <div className="selectedJobs flex flex-wrap">
        {selectedJobs.map((job) => {
          return (
            <Pill
              key={job.id}
              onClose={() => removeJob(job)}
              className="mr-2 mb-2"
              toolTipText={job.id}
              data-cy={`pill-${job.id}`}
            >
              {job.name}
            </Pill>
          );
        })}
      </div>
    </div>
  );
};

export default JobInput;
