import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  RejectTenderCertification,
  RejectTenderCertificationVariables,
} from './__generated__/RejectTenderCertification';

const REJECT_TENDER_CERTIFICATION = gql`
  mutation RejectTenderCertification($id: ID!) {
    rejectTenderCertification(id: $id) {
      id
      type {
        name
      }
      tenderId
      issueDate
      expirationDate
      certificateNumber
      updatedAt
      createdAt
      status
    }
  }
`;

export const useRejectTenderCertificationMutation = (
  options?: MutationHookOptions<
    RejectTenderCertification,
    RejectTenderCertificationVariables
  >,
) =>
  useMutation<RejectTenderCertification, RejectTenderCertificationVariables>(
    REJECT_TENDER_CERTIFICATION,
    options,
  );

export default REJECT_TENDER_CERTIFICATION;
