import { gql, QueryHookOptions, useLazyQuery } from '@apollo/client';
import { Clients, ClientsVariables } from './__generated__/Clients';

const CLIENTS_QUERY = gql`
  query Clients(
    $paginationOptions: PaginationOptions!
    $queryOptions: GetClientsQueryOptions
  ) {
    getClients(
      paginationOptions: $paginationOptions
      queryOptions: $queryOptions
    ) {
      items {
        id
        email
        firstName
        lastName
        admins {
          id
          admin {
            id
            email
          }
          region {
            id
            name
          }
        }
      }
    }
  }
`;

export const useClientsQuery = (
  options?: QueryHookOptions<Clients, ClientsVariables>,
) => useLazyQuery<Clients, ClientsVariables>(CLIENTS_QUERY, options);

export default CLIENTS_QUERY;
