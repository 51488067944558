import { addDays, endOfDay, startOfDay } from 'date-fns';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useHistory } from 'react-router-dom';
import Button from 'src/components/Button';
import DateRangeInput from 'src/components/DateRangeInput';
import MultipleSelectionDropdown from 'src/components/MultipleSelectionDropdown';
import BooleanDropdown from 'src/components/BooleanDropdown';
import ShiftListItem from 'src/components/ShiftListItem';
import AdvancedShiftFilters from 'src/components/StaffingList/AdvancedShiftFilters';
import StaffingsMoreOptionsMenu from 'src/components/StaffingsMoreOptionsMenu';
import StaffingsSelectionToolbar from 'src/components/StaffingsSelectionToolbar';
import ViewContainer from 'src/components/ViewContainer';
import { StaffingSelectionContext } from 'src/contexts/StaffingsSelectionContext';
import { useChangeAdminPreferences } from 'src/graphql/mutations/ChangeAdminPreferences';
import { useGetShiftsQuery } from 'src/graphql/queries/GetShifts';
import { useSuperRegionsQuery } from 'src/graphql/queries/SuperRegions';
import { subscribeToMoreShiftsOptions } from 'src/graphql/subscriptions/ShiftUpdated';
import { useLocalStorage } from 'src/hooks/localStorage';
import {
  ONE_MILE_IN_METERS,
  UNASSIGNED_SUPER_REGION_OPTION,
} from 'src/utils/constants';
import { ShiftFilters, StaffingStatus } from 'src/__generated__/globalTypes';
import ApprovedStaffingsSelectionToolbar from 'src/components/ApprovedStaffingsSelectionToolbar';
import DayOfStats from '../DayOfStats';

const getFilters = (
  searchParams: URLSearchParams,
  now: Date,
  options: {
    useFlexpoolFilter: boolean | undefined;
    useStaffingRegions: boolean | undefined;
    useCancelledOrdersFilter: boolean | undefined;
  },
): ShiftFilters => {
  const applicantStatus = searchParams.get('applicantStatus');
  const cancelledParam = searchParams.get('cancelled');
  const clientIdsParam = searchParams.get('clientIds');
  const customPositionsParam = searchParams.get('cusPosIds');
  const defaultPositionsParam = searchParams.get('defPosIds');
  const endDateParam = searchParams.get('shiftEndDate');
  const exactDatesParam = searchParams.get('exactDates');
  const futureOnly = searchParams.get('futureOnly');
  const hasTendersOutOfTimeParam = searchParams.get('hasTendersOutOfTime');
  const isFlexpoolShiftParam = searchParams.get('isFlexpoolShift');
  const isJobUnpublishedParam = searchParams.get('isJobUnpublished');
  const jobIdsParam = searchParams.get('jobIds');
  const shiftIdParam = searchParams.get('shiftIds');
  const latParam = searchParams.get('lat');
  const lngParam = searchParams.get('lng');
  const organizationIdsParam = searchParams.get('organizationIds');
  const regionIdsParam = searchParams.get('regionIds');
  const searchRadiusParam = searchParams.get('distance');
  const shiftStaffingStatusParam = searchParams.get('status');
  const startDateParam = searchParams.get('shiftStartDate');
  const superRegionIdsParam = options?.useStaffingRegions
    ? searchParams.get('superRegionIds')
    : null;
  const showOnlyMyClientStaffingsParam = searchParams.get(
    'showOnlyMyClientStaffings',
  );

  // filter dates handling
  let startDate;
  let endDate;

  if (exactDatesParam === 'true') {
    startDate = startDateParam && new Date(startDateParam);
    endDate = endDateParam && new Date(endDateParam);
  } else {
    startDate =
      startDateParam &&
      (futureOnly ? now : startOfDay(new Date(startDateParam)));
    endDate = endDateParam && endOfDay(new Date(endDateParam));
  }

  const baseFilters = {
    shiftDates: {
      greaterThanOrEqual: startDate,
      lessThanOrEqual: endDate,
    },
    distance:
      latParam && lngParam && searchRadiusParam
        ? {
            lat: parseFloat(latParam),
            lng: parseFloat(lngParam),
            searchRadius: parseInt(searchRadiusParam, 10) * ONE_MILE_IN_METERS,
          }
        : null,
    jobIds: jobIdsParam !== null ? { contains: jobIdsParam.split(',') } : null,
    shiftIds: shiftIdParam !== null ? { contains: [shiftIdParam] } : null,
    defaultPositionIds:
      defaultPositionsParam !== null
        ? { contains: defaultPositionsParam.split(',') }
        : null,
    customPositionIds:
      customPositionsParam !== null
        ? { contains: customPositionsParam.split(',') }
        : null,
    clientIds:
      clientIdsParam !== null ? { contains: clientIdsParam.split(',') } : null,
    organizationIds:
      organizationIdsParam !== null
        ? { contains: organizationIdsParam.split(',') }
        : null,
    regionIds:
      regionIdsParam !== null ? { contains: regionIdsParam.split(',') } : null,
    staffingStatus: shiftStaffingStatusParam
      ? {
          contains: [shiftStaffingStatusParam],
        }
      : null,
    shiftApplicantStatus:
      applicantStatus !== null
        ? { contains: applicantStatus.split(',') as StaffingStatus[] }
        : null,
    hasTendersOutOfTime:
      hasTendersOutOfTimeParam !== null
        ? hasTendersOutOfTimeParam === 'true'
        : null,
    isJobUnpublished:
      isJobUnpublishedParam !== null ? isJobUnpublishedParam === 'true' : null,
    showOnlyMyClientStaffings:
      showOnlyMyClientStaffingsParam !== null
        ? showOnlyMyClientStaffingsParam === 'true'
        : null,
  };

  const staffingRegionsFilters = options.useStaffingRegions
    ? {
        superRegionIds:
          superRegionIdsParam !== null
            ? { contains: superRegionIdsParam.split(',') }
            : null,
      }
    : {};

  const flexPoolOrdersFilters = options.useFlexpoolFilter
    ? {
        isFlexpoolShift:
          isFlexpoolShiftParam !== null
            ? isFlexpoolShiftParam === 'true'
            : undefined,
      }
    : {};

  const statusFilters = options.useCancelledOrdersFilter
    ? {
        cancelled:
          cancelledParam === 'true'
            ? true
            : cancelledParam === 'false'
            ? false
            : undefined,
      }
    : {};

  return {
    ...baseFilters,
    ...staffingRegionsFilters,
    ...flexPoolOrdersFilters,
    ...statusFilters,
  };
};

const Staffings: React.FC = () => {
  const flags = useFlags();

  const [advancedFiltersOpen, setAdvancedFiltersOpen] = useState(false);
  const staffingsSelection = useContext(StaffingSelectionContext);
  const history = useHistory();
  const [now] = useState(new Date());

  const searchParams = useMemo(
    () => new URLSearchParams(history.location.search),
    [history.location.search],
  );

  const { data: superRegions, loading: loadingStaffingRegions } =
    useSuperRegionsQuery({
      fetchPolicy: 'network-only',
    });

  const [userStaffingRegions, setUserStaffingRegions] = useLocalStorage(
    'staffingRegions',
    [],
  );

  const [updatePreferences] = useChangeAdminPreferences();

  const handleFilters = useCallback(
    (newFilters: Record<string, string | string[] | null>) => {
      let ignoreClearSelection = false;

      if (flags.staffingRegions) {
        // NOTE: adding search params to ignoreClearSelectionWhenEqual
        // allows to avoid clearing the selection for filters that could be
        // updated from different sources other than the URL (e.g. Local Storage)
        const ignoreClearSelectionWhenEqual = ['superRegionIds'];

        ignoreClearSelectionWhenEqual.forEach((filter) => {
          const currentfilterValue = searchParams.get(filter)?.split(',') || [];
          const newFilterValue = newFilters[filter];

          if (
            Array.isArray(newFilterValue) &&
            Array.isArray(currentfilterValue)
          ) {
            ignoreClearSelection =
              newFilterValue.length === currentfilterValue.length &&
              newFilterValue.every((id) => currentfilterValue.includes(id));
          }
        });
      }

      Object.entries(newFilters).forEach(([filterName, filterValue]) => {
        searchParams.delete(filterName);

        if (Array.isArray(filterValue)) {
          if (filterValue.length) {
            searchParams.set(filterName, filterValue.join(','));
          }
        } else if (filterValue) {
          searchParams.set(filterName, filterValue);
        }
      });

      history.replace({
        search: `?${searchParams}`,
      });

      if (staffingsSelection?.selectionCount && !ignoreClearSelection) {
        staffingsSelection.dispatch({ type: 'clear' });
      }
    },
    [history, searchParams, staffingsSelection, flags.staffingRegions],
  );

  const handleDateChange = useCallback(
    (startDate?: Date, endDate?: Date) => {
      if (startDate && endDate) {
        handleFilters({
          shiftStartDate: startDate.toISOString(),
          shiftEndDate: endDate.toISOString(),
        });
      }
    },
    [handleFilters],
  );

  const handleStaffingRegionsReset = () => {
    setUserStaffingRegions([]);
  };

  const handleStaffingRegionsChange = useCallback(
    (staffingRegions: string[] | undefined) => {
      handleFilters({
        superRegionIds: staffingRegions || null,
      });
    },
    [handleFilters],
  );

  const handleDayOfToggle = (status: boolean) => {
    updatePreferences({
      variables: {
        input: {
          showDayOfStats: status,
        },
      },
      refetchQueries: ['AdminProfile'],
    });
  };

  // use useEffect hook to sync URL parameters and local storage data (source of truth)
  useEffect(() => {
    if (flags.staffingRegions) {
      handleStaffingRegionsChange(userStaffingRegions);
    }
  }, [handleStaffingRegionsChange, userStaffingRegions, flags.staffingRegions]);

  const filters = getFilters(searchParams, now, {
    useStaffingRegions: flags.staffingRegions,
    useFlexpoolFilter: flags.flexpoolFilters,
    useCancelledOrdersFilter: flags.canceledOrdersFilter,
  });

  const hasDateFilters =
    !!filters.shiftDates.greaterThanOrEqual &&
    !!filters.shiftDates.lessThanOrEqual;

  const {
    data: shiftsData,
    loading: loadingShifts,
    error,
    fetchMore: fetchMoreShifts,
    subscribeToMore: subscribeToMoreShifts,
  } = useGetShiftsQuery({
    variables: {
      filters,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    skip: !hasDateFilters,
  });

  useEffect(() => {
    const defaultEndDate = addDays(now, 3);
    if (!hasDateFilters) {
      handleDateChange(now, defaultEndDate);
    }
  }, [handleDateChange, hasDateFilters, now]);

  const [sentryRef] = useInfiniteScroll({
    loading: loadingShifts,
    hasNextPage: shiftsData?.shifts.pageInfo.hasNextPage || false,
    onLoadMore: () =>
      fetchMoreShifts({
        variables: { after: shiftsData?.shifts.pageInfo.endCursor },
      }),
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: !!error,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: '0px 0px 400px 0px',
  });

  const superRegionOptions = superRegions?.superRegions.concat(
    UNASSIGNED_SUPER_REGION_OPTION,
  ) || [UNASSIGNED_SUPER_REGION_OPTION];

  const handleCancelledChange = useCallback(
    (value: boolean | string | undefined) => {
      const filter = value !== undefined ? value.toString() : null;
      return handleFilters({ cancelled: filter });
    },
    [handleFilters],
  );

  const handleFlexpoolChange = useCallback(
    (value: boolean | string | undefined) => {
      const filter = value !== undefined ? value.toString() : null;
      return handleFilters({ isFlexpoolShift: filter });
    },
    [handleFilters],
  );

  const handleShowOnlyMyClientStaffingsChange = useCallback(
    (value: boolean | string | undefined) => {
      const filter = value !== undefined ? value.toString() : null;
      return handleFilters({ showOnlyMyClientStaffings: filter });
    },
    [handleFilters],
  );

  useEffect(() => {
    !flags.disableGraphqlSubscriptions &&
      subscribeToMoreShifts(subscribeToMoreShiftsOptions);
  }, [subscribeToMoreShifts, flags.disableGraphqlSubscriptions]);

  return (
    <>
      <ViewContainer className="mt-10 mb-16 max-w-screen-2xl">
        <div className="overflow-x-visible sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full align-middle sm:px-6 lg:px-8">
            <div className="border-support-line border sm:rounded-lg">
              <div className="bg-background-surface flex items-center px-4 pb-4 pt-6">
                <h3 className="text-ink-dark flex-1 text-2xl font-light leading-7">
                  {shiftsData?.shifts.pageInfo.totalCount} Shifts
                </h3>

                {flags.showClientsOnlyFilter && (
                  <BooleanDropdown
                    options={[
                      { value: false, label: 'All' },
                      { value: true, label: 'My Clients' },
                    ]}
                    value={!!filters?.showOnlyMyClientStaffings}
                    label={'By Client'}
                    onChange={handleShowOnlyMyClientStaffingsChange}
                  />
                )}
                <span>&nbsp;&nbsp;</span>
                {flags.flexpoolFilters && (
                  <BooleanDropdown
                    options={[
                      { value: undefined, label: 'All' },
                      { value: true, label: 'Flexpool' },
                      { value: false, label: 'Tend' },
                    ]}
                    value={
                      filters.isFlexpoolShift === undefined
                        ? undefined
                        : !!filters.isFlexpoolShift
                    }
                    label={'Source'}
                    onChange={handleFlexpoolChange}
                  />
                )}
                <span>&nbsp;&nbsp;</span>
                <BooleanDropdown
                  options={[
                    { value: undefined, label: 'All' },
                    { value: true, label: 'Cancelled' },
                    { value: false, label: 'Active' },
                  ]}
                  value={
                    filters.cancelled === undefined
                      ? undefined
                      : !!filters.cancelled
                  }
                  label={'Status'}
                  onChange={handleCancelledChange}
                  dataCy={'filter-status'}
                />

                {hasDateFilters && (
                  <div className="ml-4">
                    <DateRangeInput
                      onChange={handleDateChange}
                      startDate={filters.shiftDates.greaterThanOrEqual}
                      endDate={filters.shiftDates.lessThanOrEqual}
                    />
                  </div>
                )}

                {flags.staffingRegions && (
                  <div className="w-50 ml-2">
                    <MultipleSelectionDropdown
                      className="w-full"
                      buttonText={
                        userStaffingRegions.length
                          ? `Staffing Regions (${userStaffingRegions.length})`
                          : 'All Staffing Regions'
                      }
                      hasSelectionSummary
                      items={superRegionOptions}
                      itemsSelected={superRegionOptions.filter((sr) =>
                        userStaffingRegions.includes(sr.id),
                      )}
                      isLoadingItems={loadingStaffingRegions}
                      onItemSelect={(item) => {
                        if (userStaffingRegions.includes(item.id)) {
                          setUserStaffingRegions(
                            userStaffingRegions.filter(
                              (sr: string) => sr !== item.id,
                            ),
                          );
                        } else {
                          setUserStaffingRegions([
                            ...userStaffingRegions,
                            item.id,
                          ]);
                        }
                      }}
                      optionsHeader="Staffing Regions"
                      renderLoadingComponent={() => (
                        <div className="flex justify-center">
                          Loading regions...
                        </div>
                      )}
                      renderResetComponent={() => (
                        <button
                          className="text-preset-6 text-ink-dark border-b-support-line w-full border-b px-4 py-5 pt-2 text-left"
                          onClick={handleStaffingRegionsReset}
                        >
                          All Staffing Regions
                        </button>
                      )}
                    />
                  </div>
                )}

                <Button
                  className="border-ink-dark text-preset-5 text-ink-dark hover:border-ink-not-as-dark hover:text-ink-not-as-dark focus:ring-brand-50 ml-4 inline-flex h-11 items-center rounded-md border px-4 font-medium outline-none focus:ring-2"
                  onClick={() => setAdvancedFiltersOpen(true)}
                  data-cy="advanced-filters-button"
                >
                  Advanced Filters
                </Button>

                <StaffingsMoreOptionsMenu
                  className="ml-4"
                  shiftFilters={filters}
                />
              </div>
            </div>
          </div>
        </div>

        {flags.staffingsDayOfStats && (
          <div className="mt-3">
            <DayOfStats onToggle={handleDayOfToggle} />
          </div>
        )}

        <div className="mt-6">
          {shiftsData?.shifts.edges.map(({ node: shift }) => {
            return <ShiftListItem key={shift.id} shift={shift} />;
          })}
        </div>

        {(loadingShifts || shiftsData?.shifts.pageInfo.hasNextPage) && (
          <div ref={sentryRef}>Loading...</div>
        )}
      </ViewContainer>

      <StaffingsSelectionToolbar />
      <ApprovedStaffingsSelectionToolbar />

      <AdvancedShiftFilters
        onChange={(newFilters) => handleFilters(newFilters)}
        open={advancedFiltersOpen}
        onClose={() => setAdvancedFiltersOpen(false)}
      />
    </>
  );
};

export default Staffings;
