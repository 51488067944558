import React from 'react';

const Info: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.5 11H9C8.73478 11 8.48043 10.8946 8.29289 10.7071C8.10536 10.5196 8 10.2652 8 10V7.5C8 7.36739 7.94732 7.24022 7.85355 7.14645C7.75978 7.05268 7.63261 7 7.5 7H7"
      stroke="#353AC5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.55553 4.44444C7.46763 4.44444 7.3817 4.47051 7.30861 4.51935C7.23552 4.56818 7.17855 4.63759 7.14492 4.71881C7.11128 4.80002 7.10248 4.88938 7.11962 4.97559C7.13677 5.06181 7.1791 5.141 7.24126 5.20316C7.30341 5.26531 7.38261 5.30764 7.46882 5.32479C7.55503 5.34194 7.6444 5.33314 7.72561 5.2995C7.80682 5.26586 7.87623 5.2089 7.92507 5.13581C7.97391 5.06272 7.99997 4.97679 7.99997 4.88889C7.99997 4.77101 7.95315 4.65797 7.8698 4.57462C7.78645 4.49127 7.6734 4.44444 7.55553 4.44444Z"
      fill="#353AC5"
      stroke="#353AC5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.75 8C14.75 11.7279 11.7279 14.75 8 14.75C4.27208 14.75 1.25 11.7279 1.25 8C1.25 4.27208 4.27208 1.25 8 1.25C11.7279 1.25 14.75 4.27208 14.75 8Z"
      stroke="#353AC5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Info;
