import { addDays, subDays } from 'date-fns';
import { format } from 'date-fns-tz';
import React, { useState } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { ClientFilter } from 'src/components/ClientsInput/ClientsInput';
import DateRangeInput from 'src/components/DateRangeInput';
import Modal from 'src/components/Modal';
import { useGetJobsQuery } from 'src/graphql/queries/GetJobs';
import { Clients_getClients_items as Client } from 'src/graphql/queries/__generated__/Clients';
import { useCreateRosterAsAdminMutation } from 'src/graphql/mutations/CreateRosters';
import { JobsFilters } from 'src/__generated__/globalTypes';

type Props = {
  open: boolean;
  onClose: () => void;
  onCreate: (rosterIds: string[]) => void;
};

const CreateRostersModal: React.FC<Props> = ({ open, onClose, onCreate }) => {
  const [selectedJobIds, setSelectedJobIds] = useState<string[]>([]);
  const [selectedClient, setSelectedClient] = useState<Client | undefined>();
  const [createMutation, { loading }] = useCreateRosterAsAdminMutation({
    onCompleted: (data) => {
      onCreate(data.createRosters.map((roster) => roster.id));
    },
  });
  const [filters, setFilters] = useState<JobsFilters>({
    jobDates: {
      greaterThanOrEqual: subDays(new Date(), 7),
      lessThanOrEqual: addDays(new Date(), 7),
    },
    clientIds: {
      contains: [],
    },
  });

  const {
    data: jobsData,
    loading: loadingJobs,
    error,
    fetchMore: fetchMoreJobs,
  } = useGetJobsQuery({
    variables: {
      filters,
    },
  });

  const [sentryRef] = useInfiniteScroll({
    loading: loadingJobs,
    hasNextPage: jobsData?.jobs.pageInfo.hasNextPage || false,
    onLoadMore: () => {
      return fetchMoreJobs({
        variables: {
          after: jobsData?.jobs.pageInfo.endCursor,
          filters,
        },
      });
    },
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: !!error,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: '0px 0px 400px 0px',
  });

  const addSelectedJobId = (id: string) => {
    if (selectedJobIds.some((existingId) => existingId === id)) {
      return;
    }
    setSelectedJobIds([...selectedJobIds, id]);
  };
  const removeSelectedJobId = (id: string) => {
    setSelectedJobIds(selectedJobIds.filter((jobId) => jobId !== id));
  };

  const handleClose = () => {
    setFilters({
      jobDates: {
        greaterThanOrEqual: subDays(new Date(), 7),
        lessThanOrEqual: addDays(new Date(), 7),
      },
    });
    setSelectedJobIds([]);
    setSelectedClient(undefined);
    onClose();
  };

  const shouldEnableCreate =
    !!selectedClient?.id && !!selectedJobIds.length && !loading;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className="mt-40 w-4/6 overflow-visible px-6 py-4 align-top md:align-top"
    >
      <h3 className="text-preset-3">Create New Roster</h3>
      <p className="text-preset-5 mb-10">
        Generate a new Roster for an event. Select a Job to start creating the
        roster.
      </p>
      <div className="flex-direction-column flex">
        <div className="flex-1 border-r-2 pr-6">
          <ClientFilter
            onChange={(client) => {
              setSelectedClient(client);
              setFilters({
                ...filters,
                clientIds: { contains: [client.id] },
              });
            }}
            onReset={() => {
              setSelectedClient(undefined);
              setFilters({
                ...filters,
                clientIds: { contains: [] },
              });
            }}
          />
          <div className="border-support-lines bg-background-app mb-6 rounded border p-5">
            {selectedClient ? (
              <>
                <div className="text-preset-6  text-ink-dark font-medium">
                  {selectedClient.firstName} {selectedClient.lastName}
                </div>
                <div className="text-preset-6 text-ink-not-as-dark font-normal">
                  {selectedClient.email}
                </div>
              </>
            ) : (
              <p className="text-preset-5 text-ink-not-as-dark text-center font-normal">
                Select Client and date range to create the Roster
              </p>
            )}
          </div>

          <DateRangeInput
            className="w-80"
            onChange={(greaterThanOrEqual, lessThanOrEqual) => {
              greaterThanOrEqual &&
                lessThanOrEqual &&
                setFilters({
                  ...filters,
                  jobDates: {
                    greaterThanOrEqual,
                    lessThanOrEqual,
                  },
                });
            }}
            startDate={filters.jobDates?.greaterThanOrEqual}
            endDate={filters.jobDates?.lessThanOrEqual}
          />
        </div>
        <div className="flex-1 pl-6">
          <p className="text-preset-5 block font-medium">
            {selectedJobIds.length
              ? `${selectedJobIds.length} Selected Job${
                  selectedJobIds.length > 1 ? 's' : ''
                }`
              : 'Select Jobs'}
          </p>
          {!jobsData ? (
            ''
          ) : (
            <p className="text-preset-p text-ink-not-as-dark mb-2.5 block font-normal">
              {jobsData.jobs.pageInfo.totalCount} Jobs
            </p>
          )}

          <div className="scroll-y h-96 overflow-scroll">
            {!jobsData ? (
              <div className="border-support-lines bg-background-app mt-6 flex items-center rounded border p-5">
                <p className="text-preset-5 text-ink-not-as-dark text-center font-normal">
                  Jobs associated to the Client and date range will be shown
                  here
                </p>
              </div>
            ) : (
              jobsData.jobs.edges.map(({ node: job }) => (
                <div
                  key={job.id}
                  className="border-support-lines bg-background-app mb-6 rounded border p-5"
                >
                  <input
                    type="checkbox"
                    title="select job"
                    className="text-brand-50 border-ink-dark float-right block h-6 w-6 rounded border-2 focus:ring-0"
                    onChange={(evt) => {
                      evt.target.checked
                        ? addSelectedJobId(job.id)
                        : removeSelectedJobId(job.id);
                    }}
                    checked={selectedJobIds.indexOf(job.id) > -1}
                    data-cy={`job-checkbox-${job.id}`}
                  />
                  <div>{job.name}</div>

                  <p className="text-preset-6P text-ink-not-as-dark mb-2">
                    {job.id}
                  </p>

                  <p className="text-preset-6P text-ink-not-as-dark">
                    {format(
                      new Date(job.startDateTime),
                      'MMM dd hh:mmaaa zzz - ',
                      {
                        timeZone: job.venue?.address.timezone,
                      },
                    )}
                    {format(new Date(job.endDateTime), 'MMM dd hh:mmaaa zzz', {
                      timeZone: job.venue?.address.timezone,
                    })}
                  </p>
                </div>
              ))
            )}
            {(loadingJobs || jobsData?.jobs.pageInfo.hasNextPage) && (
              <div ref={sentryRef}>Loading...</div>
            )}
          </div>
        </div>
      </div>
      <div className="text-ink-dark border-ink-dark-200 -mx-6 mt-6 flex justify-end border-t border-solid px-6 py-4 pb-0">
        <button
          className="border-ink-dark rounded-md border py-2 px-4"
          onClick={handleClose}
        >
          Cancel
        </button>
        <button
          className={
            'bg-primary text-ink-clear ml-4 rounded-md py-2 px-4 disabled:opacity-50'
          }
          disabled={!shouldEnableCreate}
          onClick={async () => {
            if (!shouldEnableCreate) {
              return;
            }
            await createMutation({
              variables: {
                input: {
                  clientId: selectedClient?.id || '',
                  jobIds: selectedJobIds,
                },
              },
            });
            handleClose();
          }}
          data-cy="submit-create-roster"
        >
          Create New Roster
          {selectedJobIds.length > 1 ? 's' : ''}
        </button>
      </div>
    </Modal>
  );
};

export default CreateRostersModal;
