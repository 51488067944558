import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { format, utcToZonedTime } from 'date-fns-tz';

import ConfirmationDialog from 'src/components/ConfirmationDialog';
import Human from 'src/components/Icons/Human';
import { GetRostersForAdmin_rosters_edges_node as Roster } from 'src/graphql/queries/__generated__/GetRostersForAdmin';
import { formatAddress } from 'src/utils/address';
import { useCreateRosterDocumentMutation } from 'src/graphql/mutations/CreateRosterDocument';
import { useDeleteRosterAsAdminMutation } from 'src/graphql/mutations/DeleteRoster';
import { RosterSelectionContext } from 'src/contexts/RostersSelectionContext';

const RosterRow: React.FC<{ roster: Roster }> = ({ roster }) => {
  const history = useHistory();
  const selectedRosters = useContext(RosterSelectionContext);
  const [open, setIsOpen] = useState(false);
  const [maxRostersOpen, setMaxRostersOpen] = useState(false);
  const [downloadOpen, setDownloadOpen] = useState(false);
  const [deleteMutation, { loading: deleteLoading }] =
    useDeleteRosterAsAdminMutation();
  const [downloadMutation, { loading: downloadLoading }] =
    useCreateRosterDocumentMutation();

  const handleSubmit = async () => {
    await deleteMutation({ variables: { rosterId: roster.id } });
    setIsOpen(false);
  };
  const { totalQuantity, totalStaffed } = roster.job.shifts?.reduce(
    (acum, shift) => ({
      totalQuantity: shift.quantity + acum.totalQuantity,
      totalStaffed: shift.staffedSlots + acum.totalStaffed,
    }),
    { totalQuantity: 0, totalStaffed: 0 },
  ) || { totalQuantity: 0, totalStaffed: 0 };

  const MAX_ROSTERS_ALLOWED = 100;
  const timeZone = roster.job.venue?.address.timezone || 'America/Los_Angeles';
  return (
    <>
      <ConfirmationDialog
        open={open}
        title="Delete Roster"
        description="The roster and all the assigned tenders will be deleted permanently."
        cancelText="Cancel"
        submitText="Delete"
        submitClassName={`text-status-destructive ${
          deleteLoading ? 'opacity-50' : ''
        }`}
        onSubmit={() => handleSubmit()}
        onClose={() => setIsOpen(false)}
      />
      <ConfirmationDialog
        open={maxRostersOpen}
        title="Maximum number of selected rosters reached"
        description={`A maximum of ${MAX_ROSTERS_ALLOWED} rosters is allowed per transaction.`}
        cancelText="Accept"
        onClose={() => setMaxRostersOpen(false)}
      />
      <ConfirmationDialog
        open={downloadOpen}
        title="Download Roster"
        description="The roster will be sent to your email."
        cancelText="Cancel"
        submitText="Send Email"
        submitClassName={`bg-primary text-ink-clear ${
          downloadLoading ? 'opacity-50' : ''
        }`}
        onSubmit={async () => {
          await downloadMutation({
            variables: {
              rosterId: roster.id,
            },
          });
          setDownloadOpen(false);
        }}
        onClose={() => setDownloadOpen(false)}
      />
      <tr
        key={roster.id}
        className="border-support-line h-16 border-b"
        data-cy={`roster-row-${roster.job.id}`}
      >
        <td className="w-12">
          <input
            type="checkbox"
            className="text-brand-50 border-ink-dark ml-4 block h-6 w-6 cursor-pointer rounded border-2 focus:ring-0"
            onChange={(evt) => {
              if (
                selectedRosters &&
                selectedRosters?.state.size >= MAX_ROSTERS_ALLOWED
              ) {
                setMaxRostersOpen(true);
              } else {
                evt.target.checked
                  ? selectedRosters?.dispatch({ type: 'add', id: roster.id })
                  : selectedRosters?.dispatch({
                      type: 'remove',
                      id: roster.id,
                    });
              }
            }}
            checked={selectedRosters?.state.has(roster.id) ?? false}
          />
        </td>
        <td className="w-24 text-center">
          <div className="bg-ink-dark inline-block content-center rounded-full px-2">
            <Human className="text-ink-clear mb-0.5 mr-1 inline-block h-4" />
            <div className="text-ink-clear text-preset-6 bold mb-1 inline-block">
              {totalStaffed}/{totalQuantity}
            </div>
          </div>
        </td>
        <td className="w-7/12">
          <div className="text-preset-5 text-ink-dark truncate">
            {roster.job.name} |
            <span className="text-ink-not-as-dark ml-1">
              {roster.job.venue?.name ||
                `${roster.client.firstName} ${roster.client.lastName}`}
            </span>
          </div>
          <div className="text-preset-6 text-ink-not-as-dark mt-2.5 truncate">
            {roster.job.venue?.address &&
              formatAddress(roster.job.venue.address)}{' '}
            •{' '}
            {format(
              utcToZonedTime(roster.job.startDateTime, timeZone),
              'MMM d hh:mmaaa zzz - ',
              {
                timeZone,
              },
            )}
            {format(
              utcToZonedTime(roster.job.endDateTime, timeZone),
              'MMM d hh:mmaaa zzz',
              {
                timeZone,
              },
            )}
          </div>
        </td>
        <td className="w-56">
          <div className="text-preset-6 text-ink-not-as-dark mt-0.5 truncate text-left">
            Created at
            {format(new Date(roster.createdAt), ' MMM dd, HH:mmaaa z')}
          </div>
        </td>
        <td className="w-20 text-center">
          <button
            className="text-ink-primary text-preset-6"
            onClick={() => {
              setDownloadOpen(true);
            }}
          >
            Download
          </button>
        </td>
        <td className="w-20 text-center">
          <button
            className="text-ink-primary text-preset-6"
            onClick={() => history.push(`/roster/${roster.id}`)}
            data-cy={`edit-roster-${roster.job.id}`}
          >
            Edit
          </button>
        </td>
        <td className="w-20 text-center">
          <button
            className={`text-status-destructive text-preset-6 ${
              deleteLoading ? 'opacity-50' : ''
            }`}
            disabled={deleteLoading}
            onClick={() => {
              setIsOpen(true);
            }}
            data-cy={`delete-roster-${roster.job.id}`}
          >
            Delete
          </button>
        </td>
      </tr>
    </>
  );
};

export default RosterRow;
