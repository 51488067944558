import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  ToggleShiftVisibilityInOpenJobs,
  ToggleShiftVisibilityInOpenJobsVariables,
} from './__generated__/ToggleShiftVisibilityInOpenJobs';

const TOGGLE_SHIFT_VISIBILITY_IN_OPEN_JOBS = gql`
  mutation ToggleShiftVisibilityInOpenJobs($shiftId: ID!, $isHidden: Boolean!) {
    hideFromOpenShifts(id: $shiftId) @skip(if: $isHidden) {
      ...ShiftVisibility
    }
    showAtOpenShifts(id: $shiftId) @include(if: $isHidden) {
      ...ShiftVisibility
    }
  }

  fragment ShiftVisibility on Shift {
    id
    hiddenFromOpenShifts
  }
`;

export const useToggleShiftVisibilityInOpenJobsMutation = (
  options: MutationHookOptions<
    ToggleShiftVisibilityInOpenJobs,
    ToggleShiftVisibilityInOpenJobsVariables
  >,
) => {
  return useMutation<
    ToggleShiftVisibilityInOpenJobs,
    ToggleShiftVisibilityInOpenJobsVariables
  >(TOGGLE_SHIFT_VISIBILITY_IN_OPEN_JOBS, options);
};

export default TOGGLE_SHIFT_VISIBILITY_IN_OPEN_JOBS;
