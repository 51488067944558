import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  ResetTenderStatusAsAdmin,
  ResetTenderStatusAsAdminVariables,
} from './__generated__/ResetTenderStatusAsAdmin';

export const RESET_TENDER_STATUS_AS_ADMIN = gql`
  mutation ResetTenderStatusAsAdmin($input: ChangeTenderStatusAsAdminInput!) {
    resetTenderStatusAsAdmin(input: $input) {
      tender {
        id
      }
    }
  }
`;

export const useResetTenderStatusAsAdminMutation = (
  options?: MutationHookOptions<
    ResetTenderStatusAsAdmin,
    ResetTenderStatusAsAdminVariables
  >,
) =>
  useMutation<ResetTenderStatusAsAdmin, ResetTenderStatusAsAdminVariables>(
    RESET_TENDER_STATUS_AS_ADMIN,
    options,
  );

export default RESET_TENDER_STATUS_AS_ADMIN;
