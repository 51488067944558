import { gql, LazyQueryHookOptions, useLazyQuery } from '@apollo/client';
import STAFFING_LIST_ITEM from '../fragments/StaffingListItem';
import {
  ApprovedJobApplicationsPaginated,
  ApprovedJobApplicationsPaginatedVariables,
} from './__generated__/ApprovedJobApplicationsPaginated';

const APPROVED_JOB_APPLICATIONS_PAGINATED = gql`
  query ApprovedJobApplicationsPaginated(
    $shiftId: ID!
    $options: ListOptions
    $pagination: PaginationOptions
  ) {
    approvedJobApplicationsPaginated(
      shiftId: $shiftId
      listOptions: $options
      pagination: $pagination
    ) {
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
      items {
        ...StaffingListItem
      }
    }
  }
  ${STAFFING_LIST_ITEM}
`;

export const useLazyApprovedJobApplicationsPaginatedQuery = (
  options?: LazyQueryHookOptions<
    ApprovedJobApplicationsPaginated,
    ApprovedJobApplicationsPaginatedVariables
  >,
) =>
  useLazyQuery<
    ApprovedJobApplicationsPaginated,
    ApprovedJobApplicationsPaginatedVariables
  >(APPROVED_JOB_APPLICATIONS_PAGINATED, options);

export default APPROVED_JOB_APPLICATIONS_PAGINATED;
