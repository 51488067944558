import * as React from 'react';
import { SVGProps } from 'react';

const Adjustment = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M8 4V2.667M8 4a1.333 1.333 0 1 0 0 2.667M8 4a1.333 1.333 0 1 1 0 2.667M4 12a1.333 1.333 0 0 0 0-2.667M4 12a1.333 1.333 0 0 1 0-2.667M4 12v1.333m0-4V2.667m4 4v6.666M12 12a1.333 1.333 0 0 0 0-2.667M12 12a1.333 1.333 0 0 1 0-2.667M12 12v1.333m0-4V2.667"
    />
  </svg>
);

export default Adjustment;
