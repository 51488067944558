import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { GetShifts, GetShiftsVariables } from './__generated__/GetShifts';

const GET_SHIFTS = gql`
  query GetShifts($after: String, $filters: ShiftFilters!) {
    shifts(first: 10, after: $after, filters: $filters) {
      pageInfo {
        hasNextPage
        endCursor
        totalCount
      }
      edges {
        node {
          id
          startDateTime
          endDateTime
          tenderHourlyPayoutWithoutTip: tenderHourlyPayout(substractTip: true)
          quantity
          staffedSlots
          applicantsQuantity
          removedQuantity
          unpaidBreakMinutes
          expectedWorkedHours
          workerClassification
          hiddenFromOpenShifts
          status
          unreadNotes
          reliabilityPrediction {
            reliabilityPrediction
            reliabilityPredictionTimestamp
          }
          hasAutoApprove
          tip {
            type
            amount
            label
          }
          job {
            id
            name
            isFlexpoolOrder
            isNowsta2
            venue {
              id
              region {
                id
                name
              }
              address {
                city
                state
                timezone
                country
              }
            }
            organization {
              id
              name
            }
            client {
              id
              email
              firstName
              lastName
              tags {
                id
                name
              }
            }
          }
          position {
            id
            name
            tagGroups {
              id
              name
            }
          }
          notes {
            id
            note
            updatedAt
            type
            creator {
              email
            }
          }
        }
      }
    }
  }
`;

export const useGetShiftsQuery = (
  options?: QueryHookOptions<GetShifts, GetShiftsVariables>,
) => useQuery<GetShifts, GetShiftsVariables>(GET_SHIFTS, options);

export default GET_SHIFTS;
