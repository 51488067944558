import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ApolloError } from '@apollo/client';
import { StaffingSelectionContext } from 'src/contexts/StaffingsSelectionContext';
import { useApproveTenderApplicationsMutation } from 'src/graphql/mutations/ApproveTenderApplications';
import { useDenyTenderApplicationsMutation } from 'src/graphql/mutations/DenyTenderApplications';
import APPROVED_JOB_APPLICATIONS from 'src/graphql/queries/ApprovedJobApplicationsPaginated';
import PENDING_JOB_APPLICATIONS from 'src/graphql/queries/PendingJobApplicationsPaginated';
import REMOVED_JOB_APPLICATIONS from 'src/graphql/queries/RemovedJobApplicationsPaginated';
import { Await } from 'src/utilityTypes';
import { STAFFINGS_DEFAULT_PAGE_SIZE } from 'src/utils/constants';
import ConfirmationDialog from './ConfirmationDialog';
import ViewContainer from './ViewContainer';
import Button from './Button';

const StaffingsSelectionToolbar: React.FC = () => {
  const [activeDialog, setActiveDialog] = useState<
    'approveAllConfirmation' | 'denyAllConfirmation'
  >();
  const closeDialog = () => setActiveDialog(undefined);
  const selectedStaffingsContext = useContext(StaffingSelectionContext);
  const {
    state: selectedStaffingsState,
    selectionCount,
    dispatch: selectedStaffingsDispatch,
  } = selectedStaffingsContext || {};
  const [bulkOperationErrors, setBulkOperationErrors] = useState<string[]>();
  const refetchQueries = useMemo(
    () =>
      Array.from(selectedStaffingsState?.keys() ?? [])
        .map((shiftId) => [
          {
            query: APPROVED_JOB_APPLICATIONS,
            variables: {
              shiftId,
              pagination: { limit: STAFFINGS_DEFAULT_PAGE_SIZE, page: 1 },
            },
          },
          {
            query: PENDING_JOB_APPLICATIONS,
            variables: {
              shiftId,
              pagination: { limit: STAFFINGS_DEFAULT_PAGE_SIZE, page: 1 },
            },
          },
          {
            query: REMOVED_JOB_APPLICATIONS,
            variables: {
              shiftId,
              pagination: { limit: STAFFINGS_DEFAULT_PAGE_SIZE, page: 1 },
            },
          },
        ])
        .flat(),
    [selectedStaffingsState],
  );
  const [
    approveTenderApplicationsToShift,
    { loading: approveTendersLoading },
    ,
  ] = useApproveTenderApplicationsMutation({
    refetchQueries,
    onCompleted: closeDialog,
  });
  const [denyTenderApplicationsToShift, { loading: denyTendersLoading }] =
    useDenyTenderApplicationsMutation({
      refetchQueries,
      onCompleted: closeDialog,
    });
  const isLoading = approveTendersLoading || denyTendersLoading;
  const handleOperationInBulk = async (
    operation:
      | typeof approveTenderApplicationsToShift
      | typeof denyTenderApplicationsToShift,
  ) => {
    if (!selectedStaffingsState) {
      return;
    }
    setActiveDialog(undefined);

    let result: Await<ReturnType<typeof operation>> | undefined;
    let exception: ApolloError | undefined;

    try {
      result = await operation({
        variables: {
          staffingIds: Array.from(selectedStaffingsState.values())
            .map((staffingIdsSet) => Array.from(staffingIdsSet))
            .flat(),
        },
      });
    } catch (err) {
      if (err instanceof ApolloError) {
        exception = err;
      } else {
        throw err;
      }
    } finally {
      const mutationErrors =
        result?.data?.mutationPayload.errors?.map(
          (mutationError) => mutationError.message,
        ) ?? [];
      const requestErrors =
        exception?.graphQLErrors?.map((requestError) => requestError.message) ??
        [];
      const errors = mutationErrors.concat(requestErrors);
      setBulkOperationErrors(errors.length ? errors : undefined);

      selectedStaffingsDispatch?.({ type: 'clear' });
    }
  };

  /**
   * clear selected staffings when moving out of the view
   */
  useEffect(
    () => () => selectedStaffingsDispatch?.({ type: 'clear' }),
    [selectedStaffingsDispatch],
  );

  if (!selectionCount) {
    return null;
  }

  return (
    <div className="bg-background-surface border-support-line shadow-bottom-toolbar fixed bottom-0 w-full border-t">
      <ViewContainer className="py-2">
        <div className="flex">
          <div className="flex flex-1 items-center">
            <div className="text-ink-not-as-dark text-preset-6 ml-2 mr-6">
              {selectionCount} Selected
            </div>
            <Button
              onClick={() => selectedStaffingsDispatch?.({ type: 'clear' })}
              disabled={isLoading}
            >
              Unselect all
            </Button>
          </div>
          <div className="flex">
            <Button
              className="mr-4"
              primary
              onClick={() => setActiveDialog('approveAllConfirmation')}
              disabled={isLoading}
              data-cy="approve-all-button"
            >
              Approve all
            </Button>
            <Button
              destructive
              noBackground
              onClick={() => setActiveDialog('denyAllConfirmation')}
              disabled={isLoading}
            >
              Deny all
            </Button>
          </div>
        </div>
      </ViewContainer>
      <ConfirmationDialog
        title="Approve Tenders?"
        description="All selected Tenders will be notified about this and will be able to see all the job details."
        open={activeDialog === 'approveAllConfirmation'}
        submitText="Approve all"
        onSubmit={() => handleOperationInBulk(approveTenderApplicationsToShift)}
        onClose={closeDialog}
      />
      <ConfirmationDialog
        title="Deny Tenders?"
        description="Denied Tenders will see a “not selected” tag in their applications."
        open={activeDialog === 'denyAllConfirmation'}
        submitText="Deny all"
        onSubmit={() => handleOperationInBulk(denyTenderApplicationsToShift)}
        submitClassName="text-status-destructive"
        onClose={closeDialog}
      />
      <ConfirmationDialog
        title="Operation Errors"
        description={
          bulkOperationErrors
            ?.map((errorMessage) => `- ${errorMessage}`)
            .join('\n') ?? ''
        }
        open={!isLoading && Boolean(bulkOperationErrors?.length)}
        cancelText="Close"
        onClose={() => setBulkOperationErrors(undefined)}
      />
    </div>
  );
};

export default StaffingsSelectionToolbar;
