export type Address = {
  streetNumber?: string | null;
  interiorNumber?: string | null;
  streetName?: string | null;
  neighborhood?: string | null;
  city?: string | null;
  state: string;
  country: string;
  zip?: string | null;
  lat?: number;
  lng?: number;
};

export const formatAddress = (address: Address) => {
  const addressComponents: string[] = [];
  let addressStreetAndNumber: string | undefined;
  if (address.streetNumber) {
    addressStreetAndNumber = address.interiorNumber
      ? `${address.streetNumber}-${address.interiorNumber}`
      : address.streetNumber;
  }
  if (address.streetName) {
    addressStreetAndNumber
      ? (addressStreetAndNumber += ` ${address.streetName}`)
      : (addressStreetAndNumber = address.streetName);
  }
  addressStreetAndNumber && addressComponents.push(addressStreetAndNumber);
  address.city && addressComponents.push(address.city);
  addressComponents.push(address.state);
  addressComponents.push(address.country);
  address.zip && addressComponents.push(address.zip);
  return addressComponents.join(', ');
};

export const getAddress = (place: google.maps.places.PlaceResult): Address => {
  const address: Address = { city: '', state: '', country: '' };

  address.lat = place.geometry?.location?.lat();
  address.lng = place.geometry?.location?.lng();

  place.address_components?.forEach((component) => {
    const types = component.types;
    if (types.includes('street_number')) {
      address.streetNumber = component.long_name;
    } else if (types.includes('route')) {
      address.streetName = component.long_name;
    } else if (types.includes('neighborhood')) {
      address.neighborhood = component.long_name;
    } else if (types.includes('locality') || types.includes('sublocality')) {
      address.city = component.long_name;
    } else if (types.includes('administrative_area_level_1')) {
      address.state = component.short_name || component.long_name;
    } else if (types.includes('country')) {
      address.country = component.short_name || component.long_name;
    } else if (types.includes('postal_code')) {
      address.zip = component.long_name;
    }
  });

  return address;
};
