import React from 'react';

const Ban: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={className}
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#ban-clip)">
      <path
        d="M7.5 0C3.636 0 0.5 3.136 0.5 7C0.5 10.864 3.636 14 7.5 14C11.364 14 14.5 10.864 14.5 7C14.5 3.136 11.364 0 7.5 0ZM1.9 7C1.9 3.906 4.406 1.4 7.5 1.4C8.795 1.4 9.985 1.841 10.93 2.583L3.083 10.43C2.341 9.485 1.9 8.295 1.9 7ZM7.5 12.6C6.205 12.6 5.015 12.159 4.07 11.417L11.917 3.57C12.659 4.515 13.1 5.705 13.1 7C13.1 10.094 10.594 12.6 7.5 12.6Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="ban-clip">
        <rect
          width="14"
          height="14"
          fill="currentColor"
          transform="translate(0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Ban;
