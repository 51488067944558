import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import {
  AdminTenderDetails,
  AdminTenderDetailsVariables,
} from './__generated__/AdminTenderDetails';

const GET_TENDER = gql`
  query AdminTenderDetails($id: ID!) {
    tender(id: $id) {
      __typename
      id
      isImpersonator
      avatarURL
      firstName
      lastName
      preferredName
      email
      phoneNumber
      status
      hasCompletedOnboarding1099
      hasCompletedOnboardingW2
      blockedAt
      frontPhotoIdURL
      address {
        streetName
        streetNumber
        interiorNumber
        neighborhood
        city
        state
        zip
        lat
        lng
      }
      lastDevice {
        deviceId
        os
      }
      positions(onlyDefaultPositions: true) {
        id
        name
      }
      shiftsWorked
      shiftsCalledOutCount
      shiftsFaultedCount
      applicationsAfterLastShiftWorked
      unpaidShiftsCount
      score
    }
  }
`;

export const useTenderDetailsQuery = (
  options?: QueryHookOptions<AdminTenderDetails, AdminTenderDetailsVariables>,
) =>
  useQuery<AdminTenderDetails, AdminTenderDetailsVariables>(
    GET_TENDER,
    options,
  );

export default GET_TENDER;
