import React from 'react';

import Pill from 'src/components/Pill';
import TenderSideBarCard from 'src/components/TenderSideBarCard';

const WorkerClassificationCard: React.FC<{
  onboardingW2: boolean;
  onboarding1099: boolean;
}> = ({ onboardingW2, onboarding1099 }) => {
  return (
    <TenderSideBarCard title="Worker Classifications">
      {!onboarding1099 && !onboardingW2 && (
        <p className="text-preset-6 text-ink-not-as-dark my-4 flex">
          No work classification defined yet
        </p>
      )}
      {onboarding1099 && (
        <Pill className="mr-1" status="none">
          1099
        </Pill>
      )}
      {onboardingW2 && (
        <Pill className="mx-1" status="none">
          W-2
        </Pill>
      )}
    </TenderSideBarCard>
  );
};

export default WorkerClassificationCard;
