import { gql } from '@apollo/client';

const SHIFT_FOR_JOB_DETAILS = gql`
  fragment ShiftForJobDetails on Shift {
    id
    startDateTime
    endDateTime
    unpaidBreakMinutes
    quantity
    rate
    margin
    staffedSlots
    status
    tip {
      type
      amount
    }
    position {
      id
      name
      rate
      tipTypes
    }
  }
`;

export default SHIFT_FOR_JOB_DETAILS;
