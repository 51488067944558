import React, { createContext } from 'react';
import { Ability, AbilityBuilder } from '@casl/ability';
import { createContextualCan } from '@casl/react';

export const AbilityContext = createContext<Ability>(new Ability());
export const Can = createContextualCan(AbilityContext.Consumer);

export const updateAbility = (ability: Ability, permissions: string[]) => {
  const { can, cannot, rules } = new AbilityBuilder(Ability);

  for (const permission of permissions || []) {
    const [verb, action, , affectedEntity] = permission.split(':');
    const handler = verb === 'can' ? can : cannot;
    handler(action, affectedEntity);
  }
  ability.update(rules);
};

const AbilityContextProvider: React.FC = ({ children }) => {
  return (
    <AbilityContext.Provider value={new Ability()}>
      {children}
    </AbilityContext.Provider>
  );
};

export default AbilityContextProvider;
