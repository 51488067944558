import React from 'react';

const ChevronUp: React.FC<{ className?: string }> = ({
  className,
  ...props
}) => (
  <svg
    className={className}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#chevron-up-clip)">
      <path
        d="M0.75 8.25L5.75267 3.2478C5.78513 3.21529 5.82368 3.1895 5.86612 3.1719C5.90856 3.15431 5.95406 3.14525 6 3.14525C6.04594 3.14525 6.09144 3.15431 6.13388 3.1719C6.17632 3.1895 6.21487 3.21529 6.24733 3.2478L11.25 8.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="chevron-up-clip">
        <rect
          width="12"
          height="12"
          fill="white"
          transform="translate(12 12) rotate(180)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ChevronUp;
