import React, { useState } from 'react';

import Loading from 'src/components/Loading';
import TagsFilter from 'src/components/Filters/TagsFilter';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import { useTenderTagsQuery } from 'src/graphql/queries/GetTenderTags';
import { useCreateTenderTagMutation } from 'src/graphql/mutations/CreateTenderTag';
import { useRemoveTenderTagMutation } from 'src/graphql/mutations/RemoveTenderTag';

interface Props {
  tenderId: string;
  title: string;
  skillTags?: boolean;
}

const TenderTagsCard: React.FC<Props> = ({
  tenderId,
  title,
  skillTags = false,
}) => {
  const { data, loading } = useTenderTagsQuery({
    variables: { id: tenderId, skillTags },
  });
  const [open, setIsOpen] = useState(false);
  const [tagToRemove, setTagToRemove] = useState<string | undefined>(undefined);
  const [createTenderTagMutation] = useCreateTenderTagMutation();
  const [removeTenderTagMutation] = useRemoveTenderTagMutation();
  if (loading) {
    return (
      <div className="h-5 w-full">
        <Loading />
      </div>
    );
  }
  const handleAddTag = async (tag: string) => {
    await createTenderTagMutation({
      variables: {
        tenderId,
        tag,
        skillTag: skillTags,
        whitelisted: true,
      },
    });
  };
  const handleRemoveTag = async () => {
    if (tagToRemove) {
      await removeTenderTagMutation({
        variables: {
          tenderId,
          tag: tagToRemove,
          skillTag: skillTags,
        },
      });
    }
    setIsOpen(false);
  };
  const openConfirmationDialog = (tag: string) => {
    setTagToRemove(tag);
    setIsOpen(true);
  };

  return (
    <div className="bg-background-surface rounded">
      <div className="mb-4 px-4 pt-6 pb-2">
        <ConfirmationDialog
          title="Remove tag?"
          description=""
          open={open}
          submitClassName="text-status-destructive"
          submitText="Remove tag"
          onSubmit={handleRemoveTag}
          onClose={() => {
            setIsOpen(false);
          }}
        >
          <span className="text-preset-5P text-ink-dark">
            Are you sure you want to remove the{' '}
            <span className="font-medium">"{tagToRemove}"</span> tag from this
            Tender Profile?
          </span>
        </ConfirmationDialog>
        <TagsFilter
          onChange={handleAddTag}
          onRemove={openConfirmationDialog}
          selectedTags={data?.tender?.tags || []}
          skillTags={skillTags}
          title={title}
        />
        <div className="flex flex-wrap pb-2">
          {!data?.tender?.tags?.length && (
            <p className="text-preset-6 text-ink-not-as-dark flex">
              No tags assigned yet
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default TenderTagsCard;
