import React from 'react';

const ChevronDown: React.FC<{ className?: string }> = ({
  className,
  ...props
}) => (
  <svg
    className={className}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.25 3.75L6.24733 8.7522C6.21487 8.78471 6.17632 8.8105 6.13388 8.8281C6.09144 8.84569 6.04594 8.85475 6 8.85475C5.95406 8.85475 5.90856 8.84569 5.86612 8.8281C5.82368 8.8105 5.78513 8.78471 5.75267 8.7522L0.75 3.75"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ChevronDown;
