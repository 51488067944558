import React, { useState } from 'react';
import { RadioGroup } from '@headlessui/react';
import { Controller, useForm } from 'react-hook-form';

import ConfirmationDialog from 'src/components/ConfirmationDialog';
import { GetJob_job_instructions as Instruction } from 'src/graphql/queries/__generated__/GetJob';
import { useUpdateAttireAsAdminMutation } from 'src/graphql/mutations/UpdateAttire';
import { UpdateJobInstructionInput } from 'src/__generated__/globalTypes';
import RadioOption from 'src/components/RadioOption';

export const EditAttireModal: React.FC<{
  open: boolean;
  onClose: () => void;
  instruction: Instruction;
  jobId: string;
}> = ({ open, onClose, instruction, jobId }) => {
  const [updateAttireMutation, { loading }] = useUpdateAttireAsAdminMutation();
  const defaultIsCustom =
    instruction.attire !== 'White Chef Coat' &&
    instruction.attire !== 'Black Bistro';
  const [isCustom, setIsCustom] = useState(defaultIsCustom);
  const [attire, setAttire] = useState<string>(
    defaultIsCustom ? instruction.attire || '' : '',
  );

  const { handleSubmit, control, register } =
    useForm<UpdateJobInstructionInput>({
      defaultValues: {
        id: instruction.id,
        attire: defaultIsCustom ? 'custom' : instruction.attire || '',
        barKit: instruction.barKit ?? false,
        blackApron: instruction.blackApron ?? false,
        blackNonSlipShoes: instruction.blackNonSlipShoes ?? false,
        knifeKit: instruction.knifeKit ?? false,
        maskRequired: instruction.maskRequired ?? false,
      },
      mode: 'all',
    });
  register('id');
  const submitForm = async (input: UpdateJobInstructionInput) => {
    await updateAttireMutation({
      variables: {
        input: {
          ...input,
          attire: attire || input.attire,
        },
      },
    });
    onClose();
  };
  return (
    <ConfirmationDialog
      title="Edit Attire Details"
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit(submitForm)}
      submitText={loading ? 'Saving...' : 'Save'}
      className="w-11/12 max-w-2xl"
    >
      <div>
        <label
          htmlFor="attire"
          className="text-preset-6 text-ink-dark font-medium"
        >
          Attire
        </label>
        <Controller
          name="attire"
          control={control}
          defaultValue={instruction.attire}
          render={({ onChange, value }) => (
            <RadioGroup
              value={value}
              onChange={(newAttire) => {
                if (newAttire === 'custom') {
                  setIsCustom(true);
                  if (defaultIsCustom) {
                    setAttire(instruction.attire || '');
                  }
                } else {
                  setAttire('');
                  setIsCustom(false);
                }
                onChange(newAttire);
              }}
            >
              <div className="my-4 flex flex-row">
                <RadioOption
                  className="pr-6"
                  value="White Chef Coat"
                  label="White Chef Coat"
                />
                <RadioOption
                  className="pr-6"
                  value="Black Bistro"
                  label="Black Bistro"
                />
                <RadioOption value="custom" label="Custom" />
              </div>
            </RadioGroup>
          )}
        />
        {isCustom && (
          <input
            type="text"
            onChange={(event) => {
              setAttire(event.currentTarget.value);
            }}
            name="attire"
            value={attire}
            className="bg-background-app focus:ring-primary focus:border-primary mb-6 block w-full rounded px-4"
            placeholder="Enter custom attire"
          />
        )}
        <label className="text-preset-6 text-ink-dark font-medium">
          Accesories
        </label>
        <Controller
          name="blackApron"
          control={control}
          render={({ value, onChange }) => (
            <div className="mt-4 flex items-center">
              <input
                type="checkbox"
                className="text-brand-50 border-ink-dark block h-6 w-6 rounded border-2 focus:ring-0"
                onChange={(event) => onChange(event.currentTarget.checked)}
                checked={value}
              />
              <label
                htmlFor="blackApron"
                className="text-preset-6 text-ink-dark ml-4 font-medium"
              >
                Black Apron
              </label>
            </div>
          )}
        />
        <Controller
          name="blackNonSlipShoes"
          control={control}
          render={({ value, onChange }) => (
            <div className="mt-4 flex items-center">
              <input
                type="checkbox"
                className="text-brand-50 border-ink-dark block h-6 w-6 rounded border-2 focus:ring-0"
                onChange={(event) => {
                  onChange(event.currentTarget.checked);
                }}
                checked={value}
              />
              <label
                htmlFor="blackNonSlipShoes"
                className="text-preset-6 text-ink-dark ml-4 font-medium"
              >
                Black non-slip shoes
              </label>
            </div>
          )}
        />
        <Controller
          name="maskRequired"
          control={control}
          render={({ value, onChange }) => (
            <div className="mt-4 flex items-center">
              <input
                type="checkbox"
                className="text-brand-50 border-ink-dark block h-6 w-6 rounded border-2 focus:ring-0"
                onChange={(event) => onChange(event.currentTarget.checked)}
                checked={value}
              />
              <label
                htmlFor="maskRequired"
                className="text-preset-6 text-ink-dark ml-4 font-medium"
              >
                Face mask
              </label>
            </div>
          )}
        />
        <Controller
          name="barKit"
          control={control}
          render={({ value, onChange }) => (
            <div className="mt-4 flex items-center">
              <input
                type="checkbox"
                className="text-brand-50 border-ink-dark block h-6 w-6 rounded border-2 focus:ring-0"
                onChange={(event) => onChange(event.currentTarget.checked)}
                checked={value}
              />
              <label
                htmlFor="barKit"
                className="text-preset-6 text-ink-dark ml-4 font-medium"
              >
                Bar kit
              </label>
            </div>
          )}
        />
        <Controller
          name="knifeKit"
          control={control}
          render={({ value, onChange }) => (
            <div className="mt-4 flex items-center">
              <input
                type="checkbox"
                className="text-brand-50 border-ink-dark block h-6 w-6 rounded border-2 focus:ring-0"
                onChange={(event) => onChange(event.currentTarget.checked)}
                checked={value}
              />
              <label
                htmlFor="knifeKit"
                className="text-preset-6 text-ink-dark ml-4 font-medium"
              >
                Knife kit
              </label>
            </div>
          )}
        />
      </div>
    </ConfirmationDialog>
  );
};
