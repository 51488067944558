import React from 'react';
import { useHistory } from 'react-router-dom';

import Button from 'src/components/Button';
import DownloadIcon from 'src/components/Icons/Download';
import { useCreateRosterDocumentMutation } from 'src/graphql/mutations/CreateRosterDocument';
import { useGetRosterTendersForAdminQuery } from 'src/graphql/queries/GetTendersInRoster';
import RosterMoreOptionsMenu from './RosterMoreOptionsMenu';

const RosterHeader: React.FC<{
  rosterId: string;
  jobName: string;
  venueName: string;
}> = ({ rosterId, jobName, venueName }) => {
  const { data } = useGetRosterTendersForAdminQuery({
    variables: { rosterId },
    fetchPolicy: 'cache-only',
  });
  const downloadDisabled = data?.tenderRosters.pageInfo.totalCount === 0;
  const history = useHistory();
  const [createRosterDocument] = useCreateRosterDocumentMutation();

  return (
    <div className="bg-background-surface border-support-line border px-4 py-5 sm:rounded-lg">
      <div className="flex items-center justify-between">
        <h3 className="text-preset-3 text-ink-not-as-dark">
          <span
            className="text-ink-link mr-2 cursor-pointer"
            onClick={() => {
              history.replace('/rosters');
            }}
          >
            Rosters
          </span>
          /<span className="text-ink-dark"> {jobName}</span> | {venueName}
        </h3>
        <div className="flex items-center">
          <Button
            disabled={downloadDisabled}
            className={`border-ink-dark text-preset-5 text-ink-dark mr-6 inline-flex h-11 items-center rounded-md border-2 px-4 font-medium ${
              downloadDisabled
                ? 'opacity-50'
                : 'hover:border-ink-not-as-dark hover:text-ink-not-as-dark '
            }`}
            noBackground
            onClick={async () => {
              await createRosterDocument({
                variables: {
                  rosterId,
                },
              });
            }}
          >
            <DownloadIcon className="mr-2 inline-block h-4 w-4 text-black" />
            Download Roster
          </Button>
          <div className="ml-4 h-6 ">
            <RosterMoreOptionsMenu rosterId={rosterId} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RosterHeader;
