import React from 'react';
import DataCell from './DataCell';
import Refresh from '../Icons/Refresh';
import { useUpdateStaffingReliabilityPredictionMutation } from '../../graphql/mutations/UpdateStaffingReliabilityPrediction';
import { StaffingStatus } from '../../__generated__/globalTypes';

const ReliabilityPredictionCell: React.FC<{
  staffingId: string;
  staffingStatus: StaffingStatus;
  reliabilityPrediction?: number | null;
}> = ({ staffingId, staffingStatus, reliabilityPrediction, ...props }) => {
  const [updateReliabilityPrediction, { loading }, ,] =
    useUpdateStaffingReliabilityPredictionMutation({
      variables: { staffingIds: [staffingId] },
      refetchQueries: ['GetShifts'],
    });

  return (
    <DataCell data-testid="score-cell" className="min-w-fit" {...props}>
      <p className="text-preset-6 mb-1 font-normal ">
        {staffingStatus === StaffingStatus.PENDING && (
          <>
            <button
              disabled={loading}
              type="button"
              className="m-0.5 mx-2 inline-flex h-6 w-6 items-center rounded-md border border-gray-600 p-0.5 font-normal focus:outline-none"
              onClick={async () => {
                await updateReliabilityPrediction();
              }}
            >
              <Refresh
                className="fill-gray-600"
                height="18"
                loading={loading}
              />
            </button>
          </>
        )}
        {reliabilityPrediction
          ? `${Math.round(reliabilityPrediction * 10000) / 100} %` //transform from decimal to percent
          : '-'}
      </p>
    </DataCell>
  );
};

export default ReliabilityPredictionCell;
