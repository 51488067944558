import React from 'react';

const TrashIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M13.3077 15.7692H4.69232C4.3659 15.7692 4.05285 15.6396 3.82203 15.4088C3.59122 15.1779 3.46155 14.8649 3.46155 14.5385V3.46155H14.5385V14.5385C14.5385 14.8649 14.4088 15.1779 14.178 15.4088C13.9472 15.6396 13.6341 15.7692 13.3077 15.7692Z"
      stroke="currentColor"
      strokeWidth="1.11538"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.15381 12.0769V7.15381"
      stroke="currentColor"
      strokeWidth="1.11538"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.8462 12.0769V7.15381"
      stroke="currentColor"
      strokeWidth="1.11538"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 3.46155H17"
      stroke="currentColor"
      strokeWidth="1.11538"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.8462 1H7.15386C6.82744 1 6.51439 1.12967 6.28358 1.36048C6.05277 1.5913 5.9231 1.90435 5.9231 2.23077V3.46154H12.0769V2.23077C12.0769 1.90435 11.9473 1.5913 11.7165 1.36048C11.4856 1.12967 11.1726 1 10.8462 1Z"
      stroke="currentColor"
      strokeWidth="1.11538"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TrashIcon;
