import React from 'react';
import { RadioGroup } from '@headlessui/react';

const RadioOption: React.FC<{
  value: string | boolean;
  label: string;
  className?: string;
}> = ({ value, label, className = '' }) => {
  return (
    <RadioGroup.Option
      value={value}
      className={`relative block cursor-pointer rounded-lg bg-white focus:outline-none ${className}`}
    >
      {({ checked }) => (
        <RadioGroup.Label
          as="div"
          className="text-ink-primary mb-3 flex flex-row text-sm"
        >
          <span
            className="border-ink-dark mr-3 flex h-5 w-5 cursor-pointer items-center justify-center rounded-full border-2"
            aria-hidden="true"
          >
            <span
              className={`h-3 w-3 rounded-full ${
                checked ? 'bg-primary' : 'bg-white'
              }`}
            />
          </span>
          <span className={checked ? 'text-ink-primary' : 'text-ink-dark'}>
            {label}
          </span>
        </RadioGroup.Label>
      )}
    </RadioGroup.Option>
  );
};

export default RadioOption;
