import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  UpdateJobBasicInfoAsAdmin,
  UpdateJobBasicInfoAsAdminVariables,
} from './__generated__/UpdateJobBasicInfoAsAdmin';

const UPDATE_JOB_BASIC_INFO = gql`
  mutation UpdateJobBasicInfoAsAdmin($jobId: ID!, $input: JobBasicInfoInput!) {
    updateJobBasicInfo(jobId: $jobId, input: $input) {
      __typename
      id
      name
      description
    }
  }
`;

export const useUpdateJobBasicInfo = (
  options?: MutationHookOptions<
    UpdateJobBasicInfoAsAdmin,
    UpdateJobBasicInfoAsAdminVariables
  >,
) =>
  useMutation<UpdateJobBasicInfoAsAdmin, UpdateJobBasicInfoAsAdminVariables>(
    UPDATE_JOB_BASIC_INFO,
    options,
  );

export default UPDATE_JOB_BASIC_INFO;
