/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo, useRef, useEffect } from 'react';

const debounce = (
  func: (...args: any[]) => void,
  delay: number,
  { leading }: { leading?: boolean } = {},
) => {
  let timerId: ReturnType<typeof setTimeout> | undefined;

  return (...args: any[]) => {
    if (!timerId && leading) {
      func(...args);
    }
    clearTimeout(timerId as ReturnType<typeof setTimeout>);

    timerId = setTimeout(() => func(...args), delay);
  };
};

const useDebounce = (callback: () => void, delay = 1000) => {
  const ref = useRef<() => void>();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.();
    };

    return debounce(func, delay);
  }, [delay]);

  return debouncedCallback;
};

export default useDebounce;
