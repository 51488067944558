import React, { useState } from 'react';

import { AdminTenderDetails_tender_positions as Position } from 'src/graphql/queries/__generated__/AdminTenderDetails';
import DefaultPositionsInput from 'src/components/Filters/DefaultPositionsFilter';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import { DefaultPositions_defaultPositions as DefaultPosition } from 'src/graphql/queries/__generated__/DefaultPositions';
import { useRemoveTenderPositionMutation } from 'src/graphql/mutations/DeleteTenderPositions';
import { useCreateTenderPositionMutation } from 'src/graphql/mutations/AddTenderPositions';

const PositionsCard: React.FC<{
  positions: Position[] | null;
  tenderId: string;
}> = ({ tenderId, positions }) => {
  const [open, setIsOpen] = useState(false);
  const [positionToDelete, setPositionToDelete] = useState<
    DefaultPosition | undefined
  >(undefined);
  const [removePositionMutation] = useRemoveTenderPositionMutation();
  const [addPositionMutation] = useCreateTenderPositionMutation();
  const handlePositionDelete = () => {
    positionToDelete &&
      removePositionMutation({
        variables: {
          input: {
            tenderId,
            positionIds: [positionToDelete.id],
          },
        },
      });
    setIsOpen(false);
    setPositionToDelete(undefined);
  };

  return (
    <div className="bg-background-surface rounded">
      <ConfirmationDialog
        onClose={() => {
          setPositionToDelete(undefined);
          setIsOpen(false);
        }}
        description=""
        open={open}
        onSubmit={handlePositionDelete}
        title="Remove Position?"
        submitClassName="text-status-destructive"
        submitText="Remove position"
      >
        <span className="text-preset-5P text-ink-dark mb-4">
          This Tender won’t be able to see shifts that require the "
          <span className="font-bold">{positionToDelete?.name}"</span> position.
          You can still add it back later if you need.
        </span>
      </ConfirmationDialog>
      <div className="mb-4 px-4 pt-6 pb-2">
        <p className="text-preset-3 text-ink-dark mb-4 font-medium">
          Positions
        </p>
        <DefaultPositionsInput
          selectedPositionsIds={positions?.map((position) => position.id) || []}
          onAddPosition={async (positionString) => {
            await addPositionMutation({
              variables: {
                input: {
                  positionIds: [positionString],
                  tenderId,
                },
              },
            });
          }}
          onDelete={(position) => {
            setPositionToDelete(position);
            setIsOpen(true);
          }}
          title=""
          defaultValue="Search for a position to add"
        />
        {!positions?.length && (
          <p className="text-preset-6 text-ink-not-as-dark my-4 flex">
            No positions assigned yet
          </p>
        )}
      </div>
    </div>
  );
};

export default PositionsCard;
