import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Options, Props } from 'react-select';

import { AdminRole, useGetAdminsQuery } from 'src/graphql/queries/GetAdmins';
import { useGetAssignmentsLazyQuery } from 'src/graphql/queries/GetAssignments';
import { useAssignAdminToClientMutation } from 'src/graphql/mutations/AssignAdminToClient';
import { useUnassignAdminFromClientMutation } from 'src/graphql/mutations/UnassignAdminFromClient';

import Pagination from 'src/components/Pagination';
import Select from 'src/components/Select';
import TableHeaderCell from 'src/components/TableHeaderCell';
import ViewContainer from 'src/components/ViewContainer';

import getName from 'src/utils/getName';
import undefinedIfNull from 'src/utils/undefinedIfNull';
import { useRegionsQuery } from 'src/graphql/queries/Regions';
import ClientAssignmentRow from 'src/views/ClientAssignments/ClientAssignmentRow';
import useDebounce from '../../hooks/useDebounce';
import Loading from '../../components/Loading';

const PAGE_SIZE = 10;
const ALL_OPTION = 'all';
const UNASSIGNED_OPTION = 'unassigned';

export type SelectOption = {
  value: string;
  label: string;
};

export type AssignmentRow = {
  id: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  clientId: string;
  adminId?: string;
  region?: {
    id: string;
    name?: string;
  };
  organization?: {
    id: string;
    name: string;
  };
};
type AssignmentRows = AssignmentRow[];

const ClientAssignments: FC = () => {
  const [page, setPage] = useState(1);
  const {
    data: getAdminsQuery,
    loading: getAdminsLoading,
    // error: _getAdminsError,
  } = useGetAdminsQuery({
    variables: {
      paginationOptions: {
        page: 1,
        limit: 500, // TODO: We don't plan to paginate here any time soon, but perhaps a different non-paginated query might be more appropriate.
      },
      queryOptions: {
        role: AdminRole.BUSINESS,
      },
    },
  });

  const [clientSearchInput, setClientSearchInput] = useState('');
  const [clientSearchInputFilter, setClientSearchInputFilter] = useState('');

  const { data: getRegionsQuery } = useRegionsQuery();

  const [getAssignments, { data: assignments }] = useGetAssignmentsLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const debouncedSearch = useDebounce(() => {
    setPage(1);
    setClientSearchInputFilter(clientSearchInput);
  }, 400);

  const handleClientSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setClientSearchInput(event.target.value);
    debouncedSearch();
  };

  const regionOptions = useMemo(() => {
    let regionOpts: Options<SelectOption> = [];

    if (getRegionsQuery && getRegionsQuery.regions.length > 0) {
      regionOpts = getRegionsQuery.regions
        .map((region) => ({
          value: region.id,
          label: region.name,
        }))
        .sort((optionA, optionB) => (optionA.label < optionB.label ? -1 : 1));
    }

    return regionOpts;
  }, [getRegionsQuery]);

  // TODO: maybe useEffect to fetch admins and regions
  const adminOptions = useMemo(() => {
    let adminOpts: Options<SelectOption> = [];

    if (getAdminsQuery && getAdminsQuery.getAdmins.items.length > 0) {
      adminOpts = getAdminsQuery.getAdmins.items
        .map((admin) => ({
          value: admin.id,
          label:
            admin.firstName || admin.lastName
              ? getName(admin)
              : admin.email || admin.id || 'N/A',
        }))
        .sort((optionA, optionB) => (optionA.label < optionB.label ? -1 : 1));
    }

    return adminOpts;
  }, [getAdminsQuery]);

  // First table rows rendering is before having admins and regions
  const isTableReady = useMemo(() => {
    return adminOptions.length > 0 && regionOptions.length > 0;
  }, [adminOptions, regionOptions]);

  const selectAdminOptions = useMemo(() => {
    const defaultSelectOptions: Options<SelectOption> = [
      { value: ALL_OPTION, label: 'All' },
      { value: UNASSIGNED_OPTION, label: 'Unassigned' },
    ];

    return defaultSelectOptions.concat(adminOptions);
  }, [adminOptions]);

  const assignmentRows = useMemo(() => {
    let rows: AssignmentRows = [];

    if (assignments?.getAssignments.items) {
      assignments.getAssignments.items.forEach((client) => {
        rows.push({
          id: client.id,
          firstName: undefinedIfNull(client.firstName),
          lastName: undefinedIfNull(client.lastName),
          email: undefinedIfNull(client.email),
          clientId: client.id,
          organization: undefinedIfNull(client.organization),
        });

        if (client.admins) {
          rows = rows.concat(
            client.admins
              .map((assignment) => ({
                id: assignment.id,
                firstName: undefinedIfNull(assignment.admin.firstName),
                lastName: undefinedIfNull(assignment.admin.lastName),
                email: undefinedIfNull(assignment.admin.email),
                clientId: client.id,
                adminId: assignment.admin.id,
                ...(assignment.region && {
                  region: {
                    id: assignment.region.id,
                    name: undefinedIfNull(assignment.region.name),
                  },
                }),
              }))
              .sort((a, b) => {
                if (a.firstName && b.lastName) {
                  return a.firstName < b.lastName ? -1 : 1;
                } else {
                  return a.id < b.id ? -1 : 1;
                }
              }),
          );
        }
      });
    }

    return rows;
  }, [assignments]);

  const [selectedAdminOption, setSelectedAdminOption] = useState<SelectOption>(
    selectAdminOptions[0],
  );

  const onSelectAdminChange = useCallback(
    (newSelectedAdminOption) => {
      setSelectedAdminOption(newSelectedAdminOption);
      setPage(1);
    },
    [setSelectedAdminOption, setPage],
  );

  // https://dmitripavlutin.com/react-throttle-debounce/
  const fetchClientAssignments = useCallback(async () => {
    await getAssignments({
      variables: {
        paginationOptions: {
          page: page,
          limit: PAGE_SIZE,
        },
        filters: {
          ...(selectedAdminOption &&
            selectedAdminOption.value !== UNASSIGNED_OPTION &&
            selectedAdminOption.value !== ALL_OPTION && {
              adminId: selectedAdminOption.value,
            }),
          ...(selectedAdminOption &&
            selectedAdminOption.value === UNASSIGNED_OPTION && {
              unassigned: true,
            }),
          searchTerm: clientSearchInputFilter,
        },
      },
    });
  }, [getAssignments, page, selectedAdminOption, clientSearchInputFilter]);

  useEffect(() => {
    fetchClientAssignments().then();
  }, [fetchClientAssignments]);

  const selectAdminProps: Props = {
    defaultValue: selectAdminOptions[0],
    isDisabled: getAdminsLoading,
    isLoading: getAdminsLoading,
    isClearable: false,
    isSearchable: true,
    name: 'select-admin',
    onChange: onSelectAdminChange,
    options: selectAdminOptions,
  };

  const pageInfo = assignments?.getAssignments.meta;
  let showingStart, showingEnd, totalItems;
  if (pageInfo) {
    showingStart = (pageInfo.currentPage - 1) * PAGE_SIZE + 1;
    showingEnd = showingStart + pageInfo.itemCount - 1;
    totalItems = pageInfo.totalItems;
  }

  const [assignAdminToClient] = useAssignAdminToClientMutation();
  const [unassignAdminFromClient] = useUnassignAdminFromClientMutation();

  return (
    <>
      <ViewContainer className="mt-6 mb-6">
        <div className="border-support-line rounded-lg border">
          <div className="border-support-line flex flex-row rounded-t-lg rounded-r-lg border-b bg-white p-4 pt-6">
            <div className="w-1/3">
              <h2 className="text-preset-4 text-ink-dark">
                View Client Assignments For:
              </h2>
              <Select {...selectAdminProps} />
            </div>
            <div className="ml-6 w-1/3">
              <h2 className="text-preset-4 text-ink-dark">&nbsp;</h2>
              <input
                name="searchClient"
                onChange={handleClientSearchInputChange}
                type="search"
                className={
                  'border-support-line-darker block h-9 w-full rounded'
                }
                placeholder="Search client"
              />
            </div>
          </div>
          <div className="overflow-visible">
            <table className="min-w-full table-fixed">
              <thead className="bg-background-app">
                <tr className="h-12">
                  <TableHeaderCell>Name</TableHeaderCell>
                  <TableHeaderCell>Region</TableHeaderCell>
                </tr>
              </thead>
              {isTableReady && (
                <tbody className="bg-white">
                  {assignmentRows.map((row) => (
                    <ClientAssignmentRow
                      key={row.id}
                      assignAdminOptions={adminOptions}
                      assignAdminToClient={assignAdminToClient}
                      disabled={getAdminsLoading}
                      loading={getAdminsLoading}
                      regionOptions={regionOptions}
                      row={row}
                      unassignAdminFromClient={unassignAdminFromClient}
                    />
                  ))}
                </tbody>
              )}
              {!isTableReady && (
                <tbody className="bg-white">
                  <tr>
                    <td colSpan={2}>
                      <Loading className="mt-5 h-5 w-full" />
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
          <div className="flex items-center justify-between p-4">
            <p className="text-ink-dark text-preset-6">
              Showing {showingStart} to {showingEnd} of {totalItems} results
            </p>
            <Pagination
              currentPage={page}
              pageCount={pageInfo?.totalPages || 0}
              onPageChange={(p) => setPage(p)}
            />
          </div>
        </div>
      </ViewContainer>
    </>
  );
};

export default ClientAssignments;
