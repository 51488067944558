import { gql, SubscribeToMoreOptions } from '@apollo/client';
import { StaffingStatus } from 'src/__generated__/globalTypes';
import {
  ApprovedJobApplicationsPaginated,
  ApprovedJobApplicationsPaginated_approvedJobApplicationsPaginated,
} from '../queries/__generated__/ApprovedJobApplicationsPaginated';
import {
  PendingJobApplicationsPaginated,
  PendingJobApplicationsPaginated_pendingJobApplicationsPaginated,
} from '../queries/__generated__/PendingJobApplicationsPaginated';
import {
  RemovedJobApplicationsPaginated,
  RemovedJobApplicationsPaginated_removedJobApplicationsPaginated,
} from '../queries/__generated__/RemovedJobApplicationsPaginated';
import {
  jobApplicationUpdated,
  jobApplicationUpdatedVariables,
} from './__generated__/jobApplicationUpdated';

const STAFFING_UPDATED_SUBSCRIPTION = gql`
  subscription jobApplicationUpdated($shiftId: ID) {
    jobApplicationUpdated(shiftId: $shiftId) {
      id
      tenderId
      shiftId
      avatarURL
      firstName
      lastName
      email
      phoneNumber
      clientTenderStatus
      drivingDistanceInMiles: drivingDistance(unit: MILES)
      drivingTime
      status
      softConflicts
      hardConflicts
      score
      tenderHasCompletedOnboardingW2
      tenderHasCompletedOnboarding1099
      createdAt
      tenderPositionTagGroupMatch
      tagGroups {
        id
        name
      }
      reliabilityPrediction
      reliabilityPredictionTimestamp
      staffedByAutomationStepId
    }
  }
`;

const STATUSES = {
  approvedJobApplicationsPaginated: new Set([
    StaffingStatus.APPROVED,
    StaffingStatus.CONFIRMED,
    StaffingStatus.CLOCKEDIN,
    StaffingStatus.CLOCKEDOUT,
  ]),
  pendingJobApplicationsPaginated: new Set([StaffingStatus.PENDING]),
  removedJobApplicationsPaginated: new Set([
    StaffingStatus.CALLOUT,
    StaffingStatus.FAULT,
    StaffingStatus.REMOVED,
    StaffingStatus.WITHDRAWN,
    StaffingStatus.DENIED,
  ]),
};

export function subscribeToMoreJobApplicationsOptions(
  shiftId: string,
  key: 'approvedJobApplicationsPaginated',
): SubscribeToMoreOptions<
  ApprovedJobApplicationsPaginated,
  jobApplicationUpdatedVariables,
  jobApplicationUpdated
>;
export function subscribeToMoreJobApplicationsOptions(
  shiftId: string,
  key: 'pendingJobApplicationsPaginated',
): SubscribeToMoreOptions<
  PendingJobApplicationsPaginated,
  jobApplicationUpdatedVariables,
  jobApplicationUpdated
>;
export function subscribeToMoreJobApplicationsOptions(
  shiftId: string,
  key: 'removedJobApplicationsPaginated',
): SubscribeToMoreOptions<
  RemovedJobApplicationsPaginated,
  jobApplicationUpdatedVariables,
  jobApplicationUpdated
>;
export function subscribeToMoreJobApplicationsOptions(
  shiftId: string,
  key:
    | 'approvedJobApplicationsPaginated'
    | 'pendingJobApplicationsPaginated'
    | 'removedJobApplicationsPaginated',
):
  | SubscribeToMoreOptions<
      PendingJobApplicationsPaginated,
      jobApplicationUpdatedVariables,
      jobApplicationUpdated
    >
  | SubscribeToMoreOptions<
      ApprovedJobApplicationsPaginated,
      jobApplicationUpdatedVariables,
      jobApplicationUpdated
    >
  | SubscribeToMoreOptions<
      RemovedJobApplicationsPaginated,
      jobApplicationUpdatedVariables,
      jobApplicationUpdated
    > {
  return {
    document: STAFFING_UPDATED_SUBSCRIPTION,
    variables: { shiftId },
    updateQuery: (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      prev: any,
      {
        subscriptionData,
      }: { subscriptionData: { data: jobApplicationUpdated } },
    ) => {
      if (!subscriptionData.data) {
        return prev;
      }

      const prevData =
        key === 'approvedJobApplicationsPaginated'
          ? (prev[
              key
            ] as ApprovedJobApplicationsPaginated_approvedJobApplicationsPaginated)
          : key === 'removedJobApplicationsPaginated'
          ? (prev[
              key
            ] as RemovedJobApplicationsPaginated_removedJobApplicationsPaginated)
          : (prev[
              key
            ] as PendingJobApplicationsPaginated_pendingJobApplicationsPaginated);

      const existingStaffingIds = new Set(
        prevData.items.map((item) => item.id),
      );

      const updatedJobApplication = subscriptionData.data.jobApplicationUpdated;

      let newItems;
      let totalItems = prevData.meta.totalItems;

      // Staffing should be removed
      if (!STATUSES[key].has(updatedJobApplication.status)) {
        newItems = prevData.items.filter(
          (item) => item.id !== updatedJobApplication.id,
        );
        if (prevData.items.length !== newItems.length) {
          totalItems -= 1;
        }
        // Staffing should be updated
      } else if (existingStaffingIds.has(updatedJobApplication.id)) {
        newItems = prevData.items.map((item) =>
          item.id === updatedJobApplication.id
            ? {
                ...item,
                status: updatedJobApplication.status,
              }
            : item,
        );
        // Staffing should be added
      } else {
        newItems = prevData.items;
        totalItems += 1;
        newItems = prevData.items.concat([updatedJobApplication]);
      }

      return {
        [key]: {
          ...prevData,
          meta: {
            ...prevData.meta,
            totalItems,
          },
          items: newItems,
        },
      };
    },
  };
}
