import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  BulkMarkChangeTenderPayoutStatusAsCancelled,
  BulkMarkChangeTenderPayoutStatusAsCancelledVariables,
} from './__generated__/BulkMarkChangeTenderPayoutStatusAsCancelled';

const BULK_MARK_CHANGE_TENDER_PAYOUT_STATUS_AS_CANCELLED = gql`
  mutation BulkMarkChangeTenderPayoutStatusAsCancelled(
    $filters: BulkTenderPayoutFilters!
  ) {
    bulkMarkTenderPayoutAsCancelled(filters: $filters) {
      id
      status
    }
  }
`;

export const useBulkMarkChangeTenderPayoutStatusAsCancelled = (
  options?: MutationHookOptions<
    BulkMarkChangeTenderPayoutStatusAsCancelled,
    BulkMarkChangeTenderPayoutStatusAsCancelledVariables
  >,
) =>
  useMutation<
    BulkMarkChangeTenderPayoutStatusAsCancelled,
    BulkMarkChangeTenderPayoutStatusAsCancelledVariables
  >(BULK_MARK_CHANGE_TENDER_PAYOUT_STATUS_AS_CANCELLED, options);

export default BULK_MARK_CHANGE_TENDER_PAYOUT_STATUS_AS_CANCELLED;
