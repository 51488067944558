import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  DeleteTenderNote,
  DeleteTenderNoteVariables,
} from './__generated__/DeleteTenderNote';

const DELETE_TENDER_NOTE = gql`
  mutation DeleteTenderNote($id: ID!) {
    removeTenderNote(noteId: $id) {
      tenderNote {
        id
        createdAt
        note
      }
    }
  }
`;

export const useDeleteTenderNoteMutation = (
  options?: MutationHookOptions<DeleteTenderNote, DeleteTenderNoteVariables>,
) =>
  useMutation<DeleteTenderNote, DeleteTenderNoteVariables>(
    DELETE_TENDER_NOTE,
    options,
  );

export default DELETE_TENDER_NOTE;
