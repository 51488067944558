import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  UnassignAdminFromClient,
  UnassignAdminFromClientVariables,
} from './__generated__/UnassignAdminFromClient';

const UNASSIGN_ADMIN_FROM_CLIENT = gql`
  mutation UnassignAdminFromClient($id: ID!) {
    unassignAdminFromClient(id: $id) {
      id
      client {
        id
      }
      admin {
        id
      }
      region {
        id
      }
    }
  }
`;

export const useUnassignAdminFromClientMutation = (
  options?: MutationHookOptions<
    UnassignAdminFromClient,
    UnassignAdminFromClientVariables
  >,
) =>
  useMutation<UnassignAdminFromClient, UnassignAdminFromClientVariables>(
    UNASSIGN_ADMIN_FROM_CLIENT,
    options,
  );

export default UNASSIGN_ADMIN_FROM_CLIENT;
