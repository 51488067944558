import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import SHIFT_FOR_STAFFING_MUTATION from '../fragments/ShiftForStaffingMutation';
import {
  ApplyForTender,
  ApplyForTenderVariables,
} from './__generated__/ApplyForTender';

const APPLY_FOR_TENDER = gql`
  mutation ApplyForTender(
    $tenderId: ID!
    $shiftId: ID!
    $markedForApproval: Boolean!
  ) {
    staffing: applyOnBehalfOfTender(tenderId: $tenderId, shiftId: $shiftId) {
      id
      shift @skip(if: $markedForApproval) {
        ...ShiftForStaffingMutation
      }
    }
  }
  ${SHIFT_FOR_STAFFING_MUTATION}
`;

export const useApplyForTenderMutation = (
  options?: MutationHookOptions<ApplyForTender, ApplyForTenderVariables>,
) =>
  useMutation<ApplyForTender, ApplyForTenderVariables>(
    APPLY_FOR_TENDER,
    options,
  );

export default APPLY_FOR_TENDER;
