import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  CreateTenderTag,
  CreateTenderTagVariables,
} from './__generated__/CreateTenderTag';

const CREATE_TENDER_TAG = gql`
  mutation CreateTenderTag(
    $tenderId: ID!
    $tag: String!
    $skillTag: Boolean
    $whitelisted: Boolean
  ) {
    addTenderTag(tenderId: $tenderId, tag: $tag, whitelisted: $whitelisted) {
      __typename
      id
      tags(onlyWhitelisted: true, skillTags: $skillTag)
    }
  }
`;

export const useCreateTenderTagMutation = (
  options?: MutationHookOptions<CreateTenderTag, CreateTenderTagVariables>,
) =>
  useMutation<CreateTenderTag, CreateTenderTagVariables>(
    CREATE_TENDER_TAG,
    options,
  );

export default CREATE_TENDER_TAG;
