import React, { useState } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { format, utcToZonedTime } from 'date-fns-tz';

import Tab from 'src/components/Tab';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import TableHeaderCell from 'src/components/TableHeaderCell';
import TenderCell from 'src/components/StaffingList/TenderCell';
import { GetRosterTendersForAdmin_tenderRosters_edges_node as TenderRoster } from 'src/graphql/queries/__generated__/GetRosterTendersForAdmin';
import { useGetRosterTendersForAdminQuery } from 'src/graphql/queries/GetTendersInRoster';
import { useRemoveTenderFromRosterMutation } from 'src/graphql/mutations/RemoveTenderFromRoster';
import { TenderRosterTypeEnum } from 'src/__generated__/globalTypes';
import { GetRosterForAdmin_roster_job_shifts as Shift } from 'src/graphql/queries/__generated__/GetRosterForAdmin';
import CreateTenderPlaceholderModal from './CreateTenderPlaceholderModal';

type TabType = 'All' | 'Placeholder' | 'Staffed';

const TenderInRosterTableHeader: React.FC<{
  rosterId: string;
  shifts?: Shift[] | null;
  timeZone?: string;
}> = ({ timeZone, shifts, rosterId }) => {
  const [createPlaceholderModalOpen, setCreatePlaceholderModalOpen] =
    useState(false);
  return (
    <div className="mb-4 flex justify-between">
      <CreateTenderPlaceholderModal
        timeZone={timeZone}
        rosterId={rosterId}
        shifts={shifts}
        open={createPlaceholderModalOpen}
        onClose={() => setCreatePlaceholderModalOpen(false)}
      />
      <h4 className="text-preset-3 text-ink-dark font-medium">
        Staffing Management
      </h4>
      <button
        className="text-preset-5 text-primary"
        onClick={() => setCreatePlaceholderModalOpen(true)}
        data-cy="add-placeholder-button"
      >
        + Add Placeholder Tender
      </button>
    </div>
  );
};

const TendersInRosterTableTabs: React.FC<{
  setSelectedTab: (tab: TabType) => void;
  selectedTab: TabType;
  allCount?: number;
  staffedCount?: number;
  placeholderCount?: number;
}> = ({
  setSelectedTab,
  selectedTab,
  allCount = 0,
  staffedCount = 0,
  placeholderCount = 0,
}) => {
  return (
    <ul className="mb-6 inline-flex w-full px-1">
      <Tab
        onClick={() => {
          setSelectedTab('All');
        }}
        label={`All (${allCount})`}
        selected={selectedTab === 'All'}
        className="w-64"
      />
      <Tab
        onClick={() => {
          setSelectedTab('Staffed');
        }}
        label={`Staffed (${staffedCount})`}
        selected={selectedTab === 'Staffed'}
        className="w-80"
      />
      <Tab
        onClick={() => {
          setSelectedTab('Placeholder');
        }}
        label={`Placeholders (${placeholderCount})`}
        selected={selectedTab === 'Placeholder'}
        className="w-96"
      />
      <li className="border-support-lines w-full border-b-2" />
    </ul>
  );
};

const TenderRosterRow: React.FC<{
  tenderRoster: TenderRoster;
  timeZone?: string;
  onRemoveClick: () => void;
}> = ({ tenderRoster, timeZone = 'America/Los_Angeles', onRemoveClick }) => {
  return (
    <tr className="border-support-line h-14 border-b">
      {tenderRoster.tender ? (
        <TenderCell
          tenderId={tenderRoster.tender.id || ''}
          firstName={tenderRoster.tender.firstName || ''}
          lastName={tenderRoster.tender.lastName || ''}
          avatarURL={tenderRoster.tender.avatarURL}
          detailText={tenderRoster.tender.email || ''}
          truncateText
          className="overflow-hidden"
        />
      ) : (
        <TenderCell
          tenderId={tenderRoster.placeHolderName || ''}
          firstName={tenderRoster.placeHolderName || ''}
          lastName={''}
          truncateText
          className="overflow-hidden"
        />
      )}
      <td className="text-preset-6 text-ink-dark p-4 font-normal">
        {tenderRoster.shift.position.name}
      </td>
      <td className="p-4">
        <div className="text-preset-6 text-ink-dark font-normal">
          {format(
            utcToZonedTime(tenderRoster.shift.startDateTime, timeZone),
            'hh:mmaaa zzz',
            {
              timeZone,
            },
          )}
        </div>
        <div className="text-preset-7 text-ink-not-as-dark">
          {format(
            utcToZonedTime(tenderRoster.shift.startDateTime, timeZone),
            'MM/dd/yyyy',
            {
              timeZone,
            },
          )}
        </div>
      </td>
      <td className="text-preset-6 text-status-destructive p-4 font-normal">
        <button
          onClick={onRemoveClick}
          className="py-2 px-4"
          data-cy={`remove-button-${
            tenderRoster.tender?.firstName || tenderRoster.placeHolderName
          }`}
        >
          Remove
        </button>
      </td>
    </tr>
  );
};

const TendersInRosterTable: React.FC<{
  rosterId: string;
  timeZone?: string;
  shifts?: Shift[] | null;
}> = ({ rosterId, timeZone, shifts }) => {
  const [rosterTenderToDelete, setRosterTenderToDelete] = useState<
    TenderRoster | undefined
  >(undefined);
  const [selectedTab, setSelectedTab] = useState<TabType>('All');
  const [deleteTenderOpen, setDeleteTenderOpen] = useState(false);

  const {
    data: allData,
    loading: allLoading,
    fetchMore: allFetchMore,
    error: allError,
  } = useGetRosterTendersForAdminQuery({
    variables: { rosterId },
  });
  const {
    data: staffedData,
    loading: staffedLoading,
    fetchMore: staffedFetchMore,
    error: staffedError,
  } = useGetRosterTendersForAdminQuery({
    variables: {
      rosterId,
      filters: { tenderRosterType: { equals: TenderRosterTypeEnum.TENDER } },
    },
  });
  const {
    data: placeholderData,
    loading: placeholderLoading,
    fetchMore: placeholderFetchMore,
    error: placeholderError,
  } = useGetRosterTendersForAdminQuery({
    variables: {
      rosterId,
      filters: {
        tenderRosterType: { equals: TenderRosterTypeEnum.PLACEHOLDER },
      },
    },
  });
  const [removeTenderFromRosterMutation] = useRemoveTenderFromRosterMutation();

  const tabQueryMap = {
    All: {
      loading: allLoading,
      data: allData,
      fetchMore: allFetchMore,
      error: allError,
    },
    Placeholder: {
      data: placeholderData,
      loading: placeholderLoading,
      fetchMore: placeholderFetchMore,
      error: placeholderError,
    },
    Staffed: {
      data: staffedData,
      loading: staffedLoading,
      fetchMore: staffedFetchMore,
      error: staffedError,
    },
  };
  const [sentryRef] = useInfiniteScroll({
    loading: tabQueryMap[selectedTab].loading,
    hasNextPage:
      tabQueryMap[selectedTab].data?.tenderRosters.pageInfo.hasNextPage ||
      false,
    onLoadMore: () => {
      return tabQueryMap[selectedTab].fetchMore({
        variables: {
          after:
            tabQueryMap[selectedTab].data?.tenderRosters.pageInfo.endCursor,
        },
      });
    },
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: !!tabQueryMap[selectedTab].error,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: '0px 0px 400px 0px',
  });

  const handleRemoveTenderFromRoster = async () => {
    setDeleteTenderOpen(false);
    setRosterTenderToDelete(undefined);
    if (!rosterTenderToDelete) {
      return;
    }
    await removeTenderFromRosterMutation({
      variables: { rosterTenderId: rosterTenderToDelete.id },
    });
  };
  const removeTenderDescription = `Are you sure you want to remove Tender "${
    rosterTenderToDelete?.tender
      ? `${rosterTenderToDelete?.tender.firstName} ${rosterTenderToDelete?.tender.lastName}`
      : rosterTenderToDelete?.placeHolderName
  }" from Roster?`;
  return (
    <>
      <TenderInRosterTableHeader
        rosterId={rosterId}
        shifts={shifts}
        timeZone={timeZone}
      />
      <div className="bg-background-app mb-8 flex-grow overflow-auto">
        <ConfirmationDialog
          open={deleteTenderOpen}
          onClose={() => {
            setDeleteTenderOpen(false);
            setRosterTenderToDelete(undefined);
          }}
          description={removeTenderDescription}
          title="Remove Tender?"
          onSubmit={handleRemoveTenderFromRoster}
        />
        <TendersInRosterTableTabs
          setSelectedTab={setSelectedTab}
          selectedTab={selectedTab}
          allCount={allData?.tenderRosters.pageInfo.totalCount}
          staffedCount={staffedData?.tenderRosters.pageInfo.totalCount}
          placeholderCount={placeholderData?.tenderRosters.pageInfo.totalCount}
        />
        <table className="w-full table-fixed">
          <thead>
            <tr className="sticky z-10 h-12">
              <TableHeaderCell className="bg-background-app mr-4 w-6/12">
                Tender
              </TableHeaderCell>
              <TableHeaderCell className="bg-background-app w-2/12">
                Position
              </TableHeaderCell>
              <TableHeaderCell className="bg-background-app w-2/12">
                Date
              </TableHeaderCell>
              <TableHeaderCell className="bg-background-app w-2/12">
                Actions
              </TableHeaderCell>
            </tr>
          </thead>
          <tbody className="bg-white">
            {tabQueryMap[selectedTab].data?.tenderRosters.edges.map(
              (tenderRoster) => (
                <TenderRosterRow
                  key={tenderRoster.node.id}
                  tenderRoster={tenderRoster.node}
                  onRemoveClick={() => {
                    setDeleteTenderOpen(true);
                    setRosterTenderToDelete(tenderRoster.node);
                  }}
                  timeZone={timeZone}
                />
              ),
            )}
          </tbody>
        </table>
        {(tabQueryMap[selectedTab].loading ||
          tabQueryMap[selectedTab].data?.tenderRosters.pageInfo
            .hasNextPage) && <div ref={sentryRef}>Loading...</div>}
      </div>
    </>
  );
};

export default TendersInRosterTable;
