import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import SHIFT_FOR_STAFFING_MUTATION from '../fragments/ShiftForStaffingMutation';
import {
  FaultTenderApplications,
  FaultTenderApplicationsVariables,
} from './__generated__/FaultTenderApplications';

const FAULT_TENDER_APPLICATIONS = gql`
  mutation FaultTenderApplications($staffingIds: [ID!]!) {
    mutationPayload: faultTenderApplications(staffingIds: $staffingIds) {
      modifiedStaffings {
        id
        status
        shift {
          ...ShiftForStaffingMutation
        }
      }
      errors {
        staffingId
        message
      }
    }
  }
  ${SHIFT_FOR_STAFFING_MUTATION}
`;

export const useFaultTenderApplicationsMutation = (
  options?: MutationHookOptions<
    FaultTenderApplications,
    FaultTenderApplicationsVariables
  >,
) =>
  useMutation<FaultTenderApplications, FaultTenderApplicationsVariables>(
    FAULT_TENDER_APPLICATIONS,
    options,
  );

export default FAULT_TENDER_APPLICATIONS;
