import React from 'react';

interface TagProps {
  children: React.ReactNode;
  className?: string;
  isDisabled?: boolean;
}

const Tag = ({ children, className = '', isDisabled, ...props }: TagProps) => {
  const colorClasses = isDisabled
    ? 'bg-gray-200 text-ink-not-as-dark'
    : 'bg-status-info-light text-ink-secondary';

  return (
    <div
      className={`text-preset-6 inline-flex items-center whitespace-nowrap rounded-full px-3 py-1.5 font-medium ${colorClasses} ${className}`}
      {...props}
    >
      {children}
    </div>
  );
};

export default Tag;
