import React, { useEffect, useRef, useState } from 'react';
import { format } from 'date-fns-tz';

import TableHeaderCell from 'src/components/TableHeaderCell';
import TenderCell from 'src/components/StaffingList/TenderCell';
import { formatMoney } from 'src/utils/formatMoney';
import { GetTenderPayments_tenderPayments_edges as TenderPayment } from 'src/graphql/queries/__generated__/GetTenderPayments';
import { OrderByDirectionEnum } from 'src/__generated__/globalTypes';
import styles from './TenderPayouts.module.css';

type Props = {
  tenderPayments?: TenderPayment[];
  orderByField: string;
  orderByDirection: OrderByDirectionEnum;
  sort: (value: string) => void;
};

const Cell: React.FC<{ className?: string }> = ({ children, className }) => (
  <td
    className={`text-preset-6P text-ink-dark bg-background-surface px-4 ${className}`}
  >
    <p className="truncate">{children}</p>
  </td>
);

type ScrollbarsOffset = {
  x: number;
  y: number;
};

const TenderPayoutsTable: React.FC<Props> = ({
  tenderPayments,
  orderByDirection,
  orderByField,
  sort,
  children,
}) => {
  const [showStickyBorder, setShowStickyBorder] = useState<boolean>(false);
  const [scrollbarsOffset, setScrollbarsOffset] = useState<ScrollbarsOffset>({
    x: 0,
    y: 0,
  });
  const containerRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  useEffect(() => {
    const element = containerRef.current;
    if (!element) {
      return;
    }
    const listener = (e: Event) => {
      const scrolled = (e.currentTarget as Element).scrollLeft > 0;
      if (scrolled && !showStickyBorder) {
        setShowStickyBorder(true);
      } else if (!scrolled && showStickyBorder) {
        setShowStickyBorder(false);
      }
    };
    element.addEventListener('scroll', listener);
    return () => element.removeEventListener('scroll', listener);
  }, [containerRef, showStickyBorder, setShowStickyBorder]);
  useEffect(() => {
    const div = containerRef.current;
    if (!div) {
      return;
    }
    const adjustScrollbarsOffset = () => {
      const x = div.offsetWidth - div.clientWidth;
      const y = div.offsetHeight - div.clientHeight;
      if (scrollbarsOffset.x !== x || scrollbarsOffset.y !== y) {
        setScrollbarsOffset({ x, y });
      }
    };
    adjustScrollbarsOffset();
    window.addEventListener('resize', adjustScrollbarsOffset);
    return () => window.removeEventListener('resize', adjustScrollbarsOffset);
  }, [containerRef, scrollbarsOffset, setScrollbarsOffset]);
  return (
    <div className="border-support-line flex flex-col rounded-lg border">
      <div
        className="bg-background-app flex-grow overflow-auto"
        ref={containerRef}
      >
        <table className="w-full table-fixed">
          <thead>
            <tr className="h-12">
              <TableHeaderCell
                className="bg-background-app sticky top-0 left-0 w-64"
                sortDirection={
                  orderByField === 'tenderName' ? orderByDirection : undefined
                }
                onClick={() => sort('tenderName')}
              >
                Tender
              </TableHeaderCell>
              <TableHeaderCell
                className="bg-background-app sticky left-44 w-32"
                sortDirection={
                  orderByField === 'status' ? orderByDirection : undefined
                }
                onClick={() => sort('status')}
              >
                Status
              </TableHeaderCell>
              <TableHeaderCell
                className="bg-background-app sticky left-72 w-28"
                sortDirection={
                  orderByField === 'type' ? orderByDirection : undefined
                }
                onClick={() => sort('type')}
              >
                Type
              </TableHeaderCell>
              <TableHeaderCell
                className={`bg-background-app sticky left-96 w-24 ${
                  showStickyBorder && styles.rightbordershadow
                }`}
                sortDirection={
                  orderByField === 'amount' ? orderByDirection : undefined
                }
                onClick={() => sort('amount')}
              >
                Amount
              </TableHeaderCell>
              <TableHeaderCell
                className="bg-background-app w-96"
                sortDirection={
                  orderByField === 'id' ? orderByDirection : undefined
                }
                onClick={() => sort('id')}
              >
                Payment id
              </TableHeaderCell>
              <TableHeaderCell
                className="bg-background-app w-96"
                sortDirection={
                  orderByField === 'job' ? orderByDirection : undefined
                }
                onClick={() => sort('job')}
              >
                Job
              </TableHeaderCell>
              <TableHeaderCell
                className="bg-background-app w-80"
                sortDirection={
                  orderByField === 'client' ? orderByDirection : undefined
                }
                onClick={() => sort('client')}
              >
                Client
              </TableHeaderCell>
              <TableHeaderCell
                className="bg-background-app w-80"
                sortDirection={
                  orderByField === 'staffingId' ? orderByDirection : undefined
                }
                onClick={() => sort('staffingId')}
              >
                Staffing id
              </TableHeaderCell>
              <TableHeaderCell
                className="bg-background-app w-96"
                sortDirection={
                  orderByField === 'shift' ? orderByDirection : undefined
                }
                onClick={() => sort('shift')}
              >
                Shift id
              </TableHeaderCell>
              <TableHeaderCell
                className="bg-background-app w-40"
                sortDirection={
                  orderByField === 'description' ? orderByDirection : undefined
                }
                onClick={() => sort('description')}
              >
                Description
              </TableHeaderCell>
              <TableHeaderCell
                className="bg-background-app w-48"
                sortDirection={
                  orderByField === 'createdAt' ? orderByDirection : undefined
                }
                onClick={() => sort('createdAt')}
              >
                Created at
              </TableHeaderCell>
              <TableHeaderCell
                className="bg-background-app w-48"
                sortDirection={
                  orderByField === 'updatedAt' ? orderByDirection : undefined
                }
                onClick={() => sort('updatedAt')}
              >
                Updated at
              </TableHeaderCell>
            </tr>
          </thead>
          <tbody className="bg-white">
            {tenderPayments &&
              tenderPayments.map((payment) => (
                <tr
                  key={payment.node.id}
                  className="border-support-line h-14 border-b"
                >
                  <TenderCell
                    className="bg-background-surface sticky left-0 text-left"
                    lastName={payment.node.tender.lastName || ''}
                    firstName={payment.node.tender.firstName || ''}
                    tenderId={payment.node.tender.id}
                    avatarURL={payment.node.tender.avatarURL}
                    detailText={payment.node.tender.phoneNumber}
                    truncateText
                  />
                  <Cell className="sticky left-44 pr-8">
                    {payment.node.status[0] +
                      payment.node.status.substring(1).toLowerCase()}
                  </Cell>
                  <Cell className="sticky left-72 pr-8">
                    {payment.node.type[0] +
                      payment.node.type.substring(1).toLowerCase()}
                  </Cell>
                  <Cell
                    className={`sticky left-96 text-right ${
                      showStickyBorder && styles.rightbordershadow
                    }`}
                  >
                    {formatMoney(payment.node.amount)}
                  </Cell>
                  <Cell className="text-left">{payment.node.id}</Cell>
                  <Cell>
                    <span className="block text-left">
                      {payment.node.staffing?.shift?.job.name || '-'}
                    </span>
                    <span className="text-ink-not-as-dark block text-left">
                      {payment.node.staffing?.shift?.job.id}
                    </span>
                  </Cell>
                  <Cell>
                    <span className="block text-left">
                      {payment.node.staffing?.shift?.job.client?.email || '-'}
                    </span>
                    <span className="text-ink-not-as-dark block text-left">
                      {payment.node.staffing?.shift?.job.client?.id}
                    </span>
                  </Cell>
                  <Cell className="text-left">
                    {payment.node.staffing?.id || '-'}
                  </Cell>
                  <Cell className="text-left">
                    {payment.node.staffing?.shift?.id || '-'}
                  </Cell>
                  <Cell>{payment.node.description || '-'}</Cell>
                  <Cell>
                    {format(
                      new Date(payment.node.createdAt),
                      'MMM dd y hh:mmaaa',
                    )}
                  </Cell>
                  <Cell>
                    {format(
                      new Date(payment.node.updatedAt),
                      'MMM dd y hh:mmaaa',
                    )}
                  </Cell>
                </tr>
              ))}
          </tbody>
        </table>
        <span className="border-support-lines border-b" />
        {children}
      </div>
    </div>
  );
};

export default TenderPayoutsTable;
