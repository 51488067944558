import { useSelect } from 'downshift';
import React from 'react';
import ChevronDown from './Icons/ChevronDown';
import ChevronUp from './Icons/ChevronUp';

export type MultipleSelectionDropdownOption = {
  id: string | number;
  name: string;
};

type MultipleSelectionDropdownProps = {
  buttonClassName?: string;
  buttonText?: string;
  className?: string;
  containerClassName?: string;
  description?: string;
  error?: string;
  hasSelectionSummary?: boolean;
  isLoadingItems?: boolean;
  items: MultipleSelectionDropdownOption[];
  itemsSelected?: MultipleSelectionDropdownOption[];
  label?: string;
  labelClassName?: string;
  onItemSelect: (item: MultipleSelectionDropdownOption) => void;
  optionsHeader?: string;
  optionsHeaderClassName?: string;
  renderItemComponent?: (props: ItemProps) => React.ReactNode;
  renderLeadingComponent?: () => React.ReactNode;
  renderLoadingComponent: () => React.ReactNode;
  renderNoResultsComponent?: () => React.ReactNode;
  renderResetComponent?: () => React.ReactNode;
  renderTrailingComponent?: (props: TrailingComponentProps) => React.ReactNode;
};

type ItemProps = {
  item: MultipleSelectionDropdownOption;
  isSelected?: boolean;
  isHighlighted?: boolean;
};

type TrailingComponentProps = {
  isOpen?: boolean;
  onCloseMenu: () => void;
  onOpenMenu: () => void;
};

const renderDefaultItem = ({
  item,
  isSelected,
  isHighlighted,
  ...rest
}: ItemProps): React.ReactNode => (
  <li
    className={`flex cursor-pointer items-center py-2 px-4 ${
      isHighlighted && 'bg-background-app'
    }`}
    {...rest}
  >
    <input
      checked={isSelected}
      className="text-brand-50 border-ink-dark focus:ring-brand-50 mr-2 block h-6 w-6 cursor-pointer rounded border-2 focus:ring-1"
      data-cy="staffing-regions-filter-option-checkbox"
      readOnly
      type="checkbox"
    />
    <span className="text-preset-6 text-ink-dark">{item.name}</span>
  </li>
);

const renderDefaultLoadingComponent = () => (
  <div className="text-preset-6 text-ink-not-as-dark py-4 text-center">
    loading...
  </div>
);

const renderDefaultTrailingComponent: React.FC<TrailingComponentProps> = ({
  isOpen,
  onCloseMenu,
  onOpenMenu,
}) => (
  <button
    onClick={() => {
      isOpen ? onCloseMenu() : onOpenMenu();
    }}
  >
    {isOpen ? (
      <ChevronUp className="text-ink-not-as-dark ml-3 h-3 w-3 cursor-pointer" />
    ) : (
      <ChevronDown className="text-ink-not-as-dark ml-3 h-3 w-3 cursor-pointer" />
    )}
  </button>
);

const MultipleSelectionDropdown = ({
  buttonClassName = 'flex flex-1 outline-none text-preset-5 text-ink-dark font-medium items-center text-left truncate',
  buttonText = 'Select...',
  className,
  containerClassName = 'border-support-line-darker flex flex-1 h-11 items-center rounded border px-3 text-base focus-within:ring-brand-50 focus-within:ring-2 relative focus-within:bg-primary-light',
  description,
  error,
  hasSelectionSummary,
  items,
  itemsSelected,
  isLoadingItems,
  label,
  labelClassName = 'text-preset-5 text-ink-dark mb-2 block font-medium',
  onItemSelect,
  optionsHeader,
  optionsHeaderClassName = 'text-preset-7 uppercase text-normal text-ink-dark w-full px-4 mt-5 mb-2',
  renderItemComponent = renderDefaultItem,
  renderLeadingComponent,
  renderLoadingComponent = renderDefaultLoadingComponent,
  renderNoResultsComponent,
  renderTrailingComponent = renderDefaultTrailingComponent,
  renderResetComponent,
  ...rest
}: MultipleSelectionDropdownProps) => {
  const {
    closeMenu,
    getItemProps,
    getLabelProps,
    getMenuProps,
    getToggleButtonProps,
    highlightedIndex,
    isOpen,
    openMenu,
  } = useSelect({
    selectedItem: null,
    defaultHighlightedIndex: 0, // after selection, highlight the first item.
    items,
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useSelect.stateChangeTypes.MenuKeyDownEnter:
        case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true, // keep the menu open after selection.
          };
      }
      return changes;
    },
    onStateChange: ({ type, selectedItem }) => {
      switch (type) {
        case useSelect.stateChangeTypes.MenuKeyDownEnter:
        case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          if (!selectedItem) {
            return;
          }
          onItemSelect(selectedItem);
          break;
        default:
          break;
      }
    },
  });

  const selectionSummary =
    hasSelectionSummary && itemsSelected?.length
      ? `Selected: ${itemsSelected?.map((item) => item.name).join(', ')}`
      : undefined;

  return (
    <div className={className} {...rest} title={selectionSummary}>
      {label && (
        <label className={labelClassName} {...getLabelProps()}>
          {label}
        </label>
      )}

      {description && (
        <p className="text-preset-6 text-ink-not-as-dark mb-2">{description}</p>
      )}

      <div className={containerClassName}>
        {renderLeadingComponent && renderLeadingComponent()}

        <button
          className={buttonClassName}
          type="button"
          {...getToggleButtonProps()}
        >
          <span className="block truncate">{buttonText}</span>
        </button>

        {renderTrailingComponent({
          onCloseMenu: () => closeMenu(),
          onOpenMenu: () => openMenu(),
          isOpen,
        })}

        <div
          className={`text-preset-5 sm:text-preset-6 bg-background-surface absolute top-12 left-0 z-10 max-h-64 w-full overflow-auto rounded-md border py-3 shadow-lg ${
            isOpen ? 'block' : 'hidden'
          }`}
        >
          {renderResetComponent && renderResetComponent()}

          {optionsHeader && (
            <div className={optionsHeaderClassName}>{optionsHeader}</div>
          )}

          {isLoadingItems && renderLoadingComponent && renderLoadingComponent()}

          {!isLoadingItems &&
            !items.length &&
            renderNoResultsComponent &&
            renderNoResultsComponent()}

          <ul className="w-inherit ring-0 focus:ring-0" {...getMenuProps()}>
            {!isLoadingItems &&
              isOpen &&
              items.map((item, index) =>
                renderItemComponent({
                  item: item,
                  isSelected: itemsSelected?.includes(item),
                  isHighlighted: highlightedIndex === index,
                  key: `${item.id}${index}`,
                  ...getItemProps({ item, index }),
                }),
              )}
          </ul>
        </div>
      </div>

      {error && (
        <p className="text-preset-6 text-status-destructive mt-2">{error}</p>
      )}
    </div>
  );
};

export default MultipleSelectionDropdown;
