import React, { FC } from 'react';
import ReactPaginate from 'react-paginate';
import styles from './Pagination.module.css';

interface Props {
  pageCount: number;
  currentPage?: number;
  onPageChange?: (pageNumber: number) => void;
}

const Pagination: FC<Props> = ({ currentPage, pageCount, onPageChange }) => (
  <div className={styles.pagination}>
    <ReactPaginate
      pageCount={pageCount}
      pageRangeDisplayed={5}
      marginPagesDisplayed={1}
      previousLabel={<p>Previous</p>}
      nextLabel={<p>Next</p>}
      forcePage={currentPage ? currentPage - 1 : undefined}
      onPageChange={({ selected }) => onPageChange?.(selected + 1)}
      pageLinkClassName={styles.page}
      activeClassName={styles.active}
      previousClassName={styles.previous}
      nextClassName={styles.next}
      disabledClassName={styles.disabled}
    />
  </div>
);

export default Pagination;
