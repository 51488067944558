import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  CreateRosterDocument,
  CreateRosterDocumentVariables,
} from './__generated__/CreateRosterDocument';

const CREATE_ROSTER_DOCUMENT = gql`
  mutation CreateRosterDocument($rosterId: ID!) {
    createRosterDocument(rosterId: $rosterId) {
      success
    }
  }
`;

export const useCreateRosterDocumentMutation = (
  options?: MutationHookOptions<
    CreateRosterDocument,
    CreateRosterDocumentVariables
  >,
) =>
  useMutation<CreateRosterDocument, CreateRosterDocumentVariables>(
    CREATE_ROSTER_DOCUMENT,
    options,
  );

export default CREATE_ROSTER_DOCUMENT;
