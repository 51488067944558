import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import {
  LocationTrackingPoints,
  LocationTrackingPointsVariables,
} from './__generated__/LocationTrackingPoints';

const GET_LOCATION_TRACKING_POINTS = gql`
  query LocationTrackingPoints($staffingId: ID!) {
    locationTrackingPoints(staffingId: $staffingId) {
      lat
      lng
      timestamp
      isClockIn
    }
  }
`;

export const useLocationTrackingPointsQuery = (
  options?: QueryHookOptions<
    LocationTrackingPoints,
    LocationTrackingPointsVariables
  >,
) =>
  useQuery<LocationTrackingPoints, LocationTrackingPointsVariables>(
    GET_LOCATION_TRACKING_POINTS,
    options,
  );

export default GET_LOCATION_TRACKING_POINTS;
