import React, { FC, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';

import JobFilter from 'src/components/Filters/JobFilter';
import ClientsFilter from 'src/components/Filters/ClientsFilter';
import TenderFilter from 'src/components/Filters/TenderFilter';
import TenderPaymentStatusFilter from 'src/components/Filters/TenderPaymentStatusFilter';
import TenderPaymentTypeFilter from 'src/components/Filters/TenderPaymentTypeFilter';
import SingleValueUUIDComboboxField from 'src/components/SingleValueUUIDComboboxField';

export type AdvancedTenderPaymentsFiltersValues = {
  staffingIds?: string;
  shiftIds?: string;
  jobIds: string[];
  clientIds: string[];
  tenderIds: string[];
  status: string;
  type: string;
};
interface Props {
  onChange: (filter: AdvancedTenderPaymentsFiltersValues) => void;
}

const Separator = () => (
  <div className="border-support-line mb-3 border-b pt-2" />
);

const AdvancedFilters: FC<Props> = ({ onChange }) => {
  const history = useHistory();
  const queryParams = useMemo(
    () => new URLSearchParams(history.location.search),
    [history.location.search],
  );
  const staffingIdsParam = queryParams.get('staffingIds');
  const shiftIdsParam = queryParams.get('shiftIds');
  const jobIdsParam = queryParams.get('jobIds');
  const clientIdsParam = queryParams.get('clientIds');
  const tenderIdsParam = queryParams.get('tenderIds');
  const { control, handleSubmit, reset } =
    useForm<AdvancedTenderPaymentsFiltersValues>({
      mode: 'onSubmit',
    });

  const clearFilters = () => {
    reset({
      staffingIds: '',
      shiftIds: '',
      jobIds: [],
      clientIds: [],
      tenderIds: [],
      status: 'any',
      type: 'any',
    });
  };

  return (
    <form className="flex h-full max-w-md flex-col justify-between overflow-hidden">
      <div className="content flex-grow overflow-y-auto">
        <div className="jobs border-support-line border-b py-6 pb-8">
          <div className="mb-4 px-4">
            <SingleValueUUIDComboboxField
              control={control}
              name="staffingIds"
              label="Staffing ID"
              noResultLabel="Type the Staffing ID"
              defaultValue={
                staffingIdsParam ? staffingIdsParam.split(',').pop() : null
              }
            />
          </div>
          <Separator />
          <div className="mb-4 px-4">
            <SingleValueUUIDComboboxField
              control={control}
              name="shiftIds"
              label="Shift ID"
              noResultLabel="Type the Shift ID"
              defaultValue={
                shiftIdsParam ? shiftIdsParam.split(',').pop() : null
              }
            />
          </div>
          <Separator />
          <Controller
            name="jobIds"
            control={control}
            defaultValue={jobIdsParam ? jobIdsParam.split(',') : []}
            render={(field) => (
              <JobFilter
                className="px-4"
                selectedJobsIds={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Separator />
          <Controller
            name="clientIds"
            control={control}
            defaultValue={clientIdsParam ? clientIdsParam.split(',') : []}
            render={(field) => (
              <ClientsFilter
                className="px-4"
                selectedClientsIds={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Separator />
          <Controller
            name="tenderIds"
            control={control}
            defaultValue={tenderIdsParam ? tenderIdsParam.split(',') : []}
            render={(field) => (
              <TenderFilter
                className="px-4"
                selectedTenderIds={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Separator />
          <Controller
            name="status"
            control={control}
            defaultValue={queryParams.get('status') ?? 'any'}
            render={(field) => (
              <TenderPaymentStatusFilter
                className="px-4"
                tenderPaymentStatus={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Separator />
          <Controller
            name="type"
            control={control}
            defaultValue={queryParams.get('type') ?? 'any'}
            render={(field) => (
              <TenderPaymentTypeFilter
                className="px-4"
                tenderPaymentType={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </div>
      </div>
      <div className="footer space-x-4 px-4 py-4 pb-8 text-right">
        <button
          type="button"
          onClick={clearFilters}
          className="border-ink-dark text-preset-6 text-ink-dark bg-background-surface inline-flex items-center rounded-md border px-2.5 py-2 font-normal"
        >
          Clear All
        </button>
        <button
          type="button"
          onClick={handleSubmit(onChange)}
          className="text-preset-5 bg-primary text-ink-clear hover:bg-primary-active h-9 rounded px-5 font-medium"
          data-cy="apply-filters-button"
        >
          Apply
        </button>
      </div>
    </form>
  );
};

export default AdvancedFilters;
