import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import debounce from 'lodash.debounce';

import Combobox from 'src/components/Combobox';
import ChevronDown from 'src/components/Icons/ChevronDown';
import Loading from 'src/components/Loading';
import Pill from 'src/components/Pill';
import { CustomPositionsV2_customPositionsV2 as CustomPosition } from '../../graphql/queries/__generated__/CustomPositionsV2';
import { useCustomPositionV2Query } from '../../graphql/queries/GetCustomPositionsV2';

const CustomPositionComponent: FC<{
  item: CustomPosition;
  index: number;
  highlighted: boolean;
}> = ({ item, highlighted }) => {
  return (
    <div
      className={`py-2 px-4 ${highlighted ? 'bg-background-app' : ''}`}
      data-cy={`cus-positions-result-${item.name
        .toLowerCase()
        .replace(' ', '-')}`}
    >
      <p className="text-preset-6 text-ink-dark font-medium">{item.name}</p>
      <i className="text-ink-not-as-dark">{item.client?.email}</i>
    </div>
  );
};

type Props = {
  selectedPositionsIds: string[];
  onChange: (selectedPositionsIds: string[]) => void;
  className?: string;
};

const CustomPositionsInput: FC<Props> = ({
  selectedPositionsIds,
  onChange,
  className,
}) => {
  const [getCustomPositions, { data, loading }] = useCustomPositionV2Query();
  const [getCustomPositionsByIds, { data: dataByIds }] =
    useCustomPositionV2Query();
  const [selectedPositions, setSelectedPositions] = useState<CustomPosition[]>(
    [],
  );

  // search and complete pills with selected positions
  useEffect(() => {
    if (!selectedPositionsIds) {
      return;
    }
    getCustomPositionsByIds({
      variables: { includeByIds: selectedPositionsIds },
    });
  }, [getCustomPositionsByIds, selectedPositionsIds]);

  useEffect(() => {
    if (!dataByIds || !selectedPositionsIds) {
      return;
    }
    const { customPositionsV2 } = dataByIds;
    let tempPositions = selectedPositionsIds.map((positionId) => {
      return customPositionsV2.find((position) => position.id === positionId);
    });
    tempPositions = tempPositions.filter((position) => !!position);
    setSelectedPositions(tempPositions as CustomPosition[]);
  }, [dataByIds, selectedPositionsIds]);

  // search and complete dropdown excluding selected positions
  const getSelectedPositions = useCallback(async () => {
    await getCustomPositions({
      variables: {
        excludeByIds: selectedPositionsIds,
      },
    });
    // only do this for the first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getSelectedPositions();
  }, [getSelectedPositions]);

  const inputChange = useRef(
    debounce((value) => {
      getCustomPositions({
        variables: {
          searchTerm: value,
          excludeByIds: selectedPositionsIds,
        },
      });
    }, 500),
  ).current;
  const addPosition = (customPosition: CustomPosition) => {
    if (
      selectedPositions.some((selected) => selected.id === customPosition.id)
    ) {
      return;
    }
    setSelectedPositions([...selectedPositions, customPosition]);
    onChange([...selectedPositionsIds, customPosition.id]);
  };
  const removePosition = (customPosition: CustomPosition) => {
    const removedIndex = selectedPositionsIds.indexOf(customPosition.id);
    selectedPositionsIds.splice(removedIndex, 1);
    selectedPositions.splice(removedIndex, 1);
    setSelectedPositions([...selectedPositions]);
    onChange([...selectedPositionsIds]);
  };
  return (
    <div className={className}>
      <Combobox
        id="customPositionsCombobox"
        placeholder="Search position by name"
        inputClassName="focus:ring-primary focus:border-primary sm:text-preset-6 border-support-line-darker rounded cursor-pointer"
        items={data?.customPositionsV2 || []}
        label="Custom Positions"
        loadingItems={loading}
        onChange={(selectedItem) => selectedItem && addPosition(selectedItem)}
        onInputChange={inputChange}
        onReset={() => null}
        openMenuOnFocus
        renderItemComponent={CustomPositionComponent}
        renderLoadingComponent={() => (
          <div className="flex justify-center">
            <Loading />
          </div>
        )}
        renderNoResultsComponent={() => (
          <p className="py-2 px-4">No positions found</p>
        )}
        renderTrailingComponent={() => (
          <ChevronDown className="text-ink-not-as-dark h-3 w-3" />
        )}
        data-cy="custom-positions-filter"
      />
      <div className="selectedPositions flex flex-wrap">
        {selectedPositions.map((position) => {
          return (
            <Pill
              key={position.id}
              onClose={() => removePosition(position)}
              className="mr-2 mb-2"
              toolTipText={position.id}
              data-cy={`pill-${position.name
                .toLowerCase()
                .replaceAll(' ', '-')}`}
            >
              {position.name} - {position.client?.email}
            </Pill>
          );
        })}
      </div>
    </div>
  );
};

export default CustomPositionsInput;
