import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { authContext } from 'src/contexts/AuthContext';
import { AdminProfileContext } from 'src/contexts/AdminProfileContext';
import Loading from 'src/components/Loading';

type MenuItemProps = {
  to: string;
};

// TODO(rguinea): remove this disable once the Menu.Item is enabled
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MenuItem: React.FC<MenuItemProps> = ({ children, to }) => (
  <Menu.Item>
    <NavLink
      to={to}
      className="text-preset-6 block px-4 py-2 text-gray-700 hover:bg-gray-100"
      role="menuitem"
    >
      {children}
    </NavLink>
  </Menu.Item>
);

const NavigationUserMenu: React.FC = () => {
  const auth = useContext(authContext);
  const { profile, isLoading: isLoadingProfile } =
    useContext(AdminProfileContext);

  return (
    <Menu>
      {({ open }) => (
        <div className="relative ml-3">
          <div>
            <Menu.Button
              aria-expanded="false"
              aria-haspopup="true"
              className="flex max-w-xs items-center rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              data-cy="navigation-user-button"
              id="user-menu"
              type="button"
            >
              <span className="sr-only">Open user menu</span>
              {isLoadingProfile ? (
                <Loading />
              ) : (
                <span data-cy="user-menu">
                  {profile?.email || 'Invalid session'}
                </span>
              )}
            </Menu.Button>
          </div>

          <Transition
            show={open}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="user-menu"
            >
              <Menu.Item>
                <button
                  onClick={auth.removeAuthToken}
                  className="text-preset-6 block w-full px-4 py-2 text-left text-gray-700 hover:bg-gray-100"
                  role="menuitem"
                  data-cy="sign-out"
                >
                  Sign out
                </button>
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </div>
      )}
    </Menu>
  );
};

export default NavigationUserMenu;
