import React from 'react';
import { Dialog } from '@headlessui/react';

type Props = {
  description?: string;
  open: boolean;
  onClose: () => void;
  onSubmit?: () => void;
  submitClassName?: string;
  cancelClassName?: string;
  dialogClassName?: string;
  overlayClassName?: string;
  title?: string;
  closeOnEscape?: boolean;
  cancelText?: string;
  submitText?: string;
  className?: string;
  contentClassName?: string;
};

const ConfirmationDialog: React.FC<Props> = ({
  description,
  open,
  onClose,
  onSubmit,
  title,
  submitClassName = 'bg-primary text-ink-clear',
  cancelClassName = 'border border-ink-dark',
  dialogClassName = 'fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none',
  overlayClassName = 'fixed inset-0 bg-black opacity-30',
  cancelText = 'Cancel',
  submitText = 'Save',
  className = '',
  contentClassName = '',
  children,
}) => {
  return (
    <Dialog onClose={onClose} open={open} className={dialogClassName}>
      <Dialog.Overlay className={overlayClassName} />
      <div
        className={`relative my-6 mx-auto w-auto max-w-md rounded-lg border-0 bg-white shadow-lg ${className}`}
      >
        <div className="relative flex w-full flex-col px-6 pb-6 pt-4">
          <Dialog.Title className="text-ink-dark pb-10 text-2xl font-semibold">
            {title}
          </Dialog.Title>
          <Dialog.Description className="text-preset-5 text-ink-dark">
            {description}
          </Dialog.Description>
          <div className={contentClassName}>{children}</div>
        </div>
        <div className="text-ink-dark border-ink-dark-200 flex justify-end border-t border-solid px-6 py-4">
          <button
            className={`rounded-md py-2 px-4 ${cancelClassName}`}
            onClick={onClose}
            data-cy="confirmation-dialog-cancel-button"
          >
            {cancelText}
          </button>
          {onSubmit && (
            <button
              className={`ml-4 rounded-md py-2 px-4 ${submitClassName}`}
              onClick={onSubmit}
              data-cy="confirmation-dialog-submit-button"
            >
              {submitText}
            </button>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmationDialog;
