import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  AdjustPaidStaffing,
  AdjustPaidStaffingVariables,
} from './__generated__/AdjustPaidStaffing';

const ADJUST_PAID_STAFFING_MUTATION = gql`
  mutation AdjustPaidStaffing($input: AdjustPaidStaffingInput!) {
    adjustPaidStaffing(input: $input) {
      actualStartDateTime
      actualEndDateTime
      actualUnpaidBreakMinutes
      paymentStatus
      staffing {
        payout {
          expectedTenderPayout
          actualTenderPayout
          clientTotal
        }
      }
    }
  }
`;

export const useAdjustPaidStaffingMutation = (
  options?: MutationHookOptions<
    AdjustPaidStaffing,
    AdjustPaidStaffingVariables
  >,
) =>
  useMutation<AdjustPaidStaffing, AdjustPaidStaffingVariables>(
    ADJUST_PAID_STAFFING_MUTATION,
    options,
  );

export default ADJUST_PAID_STAFFING_MUTATION;
