import React, { useState } from 'react';
import { format } from 'date-fns-tz';

import Modal from 'src/components/Modal';
import Human from 'src/components/Icons/Human';
import GET_ROSTER_TENDERS from 'src/graphql/queries/GetTendersInRoster';
import { useCreatePlaceholderTenderMutation } from 'src/graphql/mutations/CreatePlaceholderTender';
import { GetRosterForAdmin_roster_job_shifts as Shift } from 'src/graphql/queries/__generated__/GetRosterForAdmin';
import { TenderRosterTypeEnum } from 'src/__generated__/globalTypes';

type Props = {
  open: boolean;
  onClose: () => void;
  rosterId: string;
  shifts?: Shift[] | null;
  timeZone?: string;
};

const CreatePlaceholderModal: React.FC<Props> = ({
  open,
  onClose,
  rosterId,
  shifts,
  timeZone,
}) => {
  const [shiftsChecked, setShiftsChecked] = useState(
    shifts?.map((shift) => shift.id),
  );
  const [placeholderName, setPlaceholderName] = useState('');
  const [createPlaceholderMutation, { loading }] =
    useCreatePlaceholderTenderMutation({
      refetchQueries: [
        {
          query: GET_ROSTER_TENDERS,
          variables: {
            rosterId,
          },
        },
        {
          query: GET_ROSTER_TENDERS,
          variables: {
            rosterId,
            filters: {
              tenderRosterType: { equals: TenderRosterTypeEnum.PLACEHOLDER },
            },
          },
        },
      ],
    });
  const savedDisabled = loading || !shiftsChecked?.length || !placeholderName;
  return (
    <Modal open={open} onClose={onClose} className="px-6 py-4">
      <p className="text-preset-3 text-ink-dark mb-2 font-medium">
        Add Placeholder Tenders
      </p>
      <p className="text-preset-5P text-ink-dark mb-10">
        Placeholder Tenders are created only for rosters. These are not approved
        staffings for the event.
      </p>
      <label className="text-preset-6 mb-2.5 block" htmlFor="placeholderName">
        Add tender name
      </label>
      <input
        type="text"
        name="placeholderName"
        id="placeholderName"
        className="bg-background-app focus:ring-primary focus:border-primary mb-6 block h-12 w-full rounded"
        value={placeholderName}
        onChange={(event) => setPlaceholderName(event.currentTarget.value)}
        data-cy="placeholder-name-input"
      />
      <label className="text-preset-6 mb-2.5 block" htmlFor="placeholderName">
        Shifts Schedule
      </label>
      {shifts?.map((shift) => {
        const isChecked = shiftsChecked?.includes(shift.id);
        return (
          <div
            key={shift.id}
            className={`border-support-lines mb-4 flex cursor-pointer items-center rounded border p-5 ${
              isChecked ? 'bg-background-app' : ''
            }`}
            onClick={() => {
              isChecked
                ? setShiftsChecked(
                    shiftsChecked?.filter((id) => id !== shift.id),
                  )
                : shiftsChecked &&
                  setShiftsChecked([...shiftsChecked, shift.id]);
            }}
          >
            <input
              checked={isChecked}
              type="checkbox"
              className="text-brand-50 border-ink-dark mr-6 h-6 w-6 cursor-pointer rounded border-2 focus:ring-0"
            />
            <div>
              <div className="flex">
                <p className="text-preset-6 text-ink-dark mb-2">
                  {shift.position.name} -
                </p>
                <p className="text-preset-6 text-ink-not-as-dark mb-2">
                  {shift.id}
                </p>
              </div>
              <p className="text-preset-6P text-ink-not-as-dark mb-2">
                {format(
                  new Date(shift.startDateTime),
                  'MMM dd hh:mmaaa zzz - ',
                  {
                    timeZone,
                  },
                )}
                {format(new Date(shift.endDateTime), 'MMM dd hh:mmaaa zzz', {
                  timeZone,
                })}
              </p>
              <div className="text-ink-not-as-dark flex items-center">
                <Human className="mr-2 inline w-4" />
                <p className="text-preset-6P">{shift.quantity}</p>
              </div>
            </div>
          </div>
        );
      })}
      <div className="text-ink-dark border-ink-dark-200 -mx-6 flex justify-end border-t border-solid px-6 py-4">
        <button
          className="border-ink-dark rounded-md border py-2 px-4"
          onClick={onClose}
        >
          Cancel
        </button>
        <button
          className={`bg-primary text-ink-clear ml-4 rounded-md py-2 px-4 ${
            savedDisabled ? 'opacity-50' : ''
          }`}
          disabled={savedDisabled}
          onClick={async () => {
            if (!shiftsChecked) {
              return;
            }
            await createPlaceholderMutation({
              variables: {
                input: {
                  rosterId,
                  shiftIds: shiftsChecked,
                  tenderName: placeholderName,
                },
              },
            });
            setPlaceholderName('');
            onClose();
          }}
          data-cy="add-tender-button"
        >
          Add Tender
        </button>
      </div>
    </Modal>
  );
};

export default CreatePlaceholderModal;
