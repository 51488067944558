import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

const NavigationLink: React.FC<NavLinkProps> = ({
  children,
  className,
  ...props
}) => (
  <NavLink
    activeClassName="text-primary"
    className="group text-preset-6 flex items-center rounded-md py-2 px-2 font-medium text-gray-900 hover:bg-gray-50"
    {...props}
  >
    {children}
  </NavLink>
);

export default NavigationLink;
