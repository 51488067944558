import React, { FC, useState } from 'react';
import { format, utcToZonedTime } from 'date-fns-tz';

import Loading from 'src/components/Loading';
import { TenderCertificationStatus } from 'src/__generated__/globalTypes';
import Pill from 'src/components/Pill';
import TableHeaderCell from 'src/components/TableHeaderCell';
import QUERY_TENDER_CERTIFICATIONS, {
  useGetTenderCertificationsQuery,
} from '../../graphql/queries/GetTenderCertifications';
import Button from '../../components/Button';
import { useConfirmTenderCertificationMutation } from '../../graphql/mutations/ConfirmTenderCertification';
import ClearableInput from '../../components/ClearableInput';
import { useRejectTenderCertificationMutation } from '../../graphql/mutations/RejectTenderCertification';
import { usePartiallyRejectTenderCertificationMutation } from '../../graphql/mutations/PartiallyRejectTenderCertification';

const Cell: FC = ({ children }) => (
  <td className="text-preset-6 text-ink-dark bg-background-surface truncate px-4">
    {children}
  </td>
);

const TenderCertifications: FC = () => {
  const [confirmTenderCertificationMutation] =
    useConfirmTenderCertificationMutation({
      refetchQueries: [QUERY_TENDER_CERTIFICATIONS],
    });
  const [rejectTenderCertificationMutation] =
    useRejectTenderCertificationMutation({
      refetchQueries: [QUERY_TENDER_CERTIFICATIONS],
    });
  const [partiallyRejectTenderCertificationMutation] =
    usePartiallyRejectTenderCertificationMutation({
      refetchQueries: [QUERY_TENDER_CERTIFICATIONS],
    });

  const [searchTerm, setSearchTerm] = useState('');

  const { data, error, loading } = useGetTenderCertificationsQuery({
    variables: {
      filters: {
        searchTerm,
      },
    },
  });

  const handleChangeStateButton = async (state: string, id: string) => {
    if (state === TenderCertificationStatus.CONFIRMED) {
      await confirmTenderCertificationMutation({
        variables: {
          id,
        },
      });
    }
    if (state === TenderCertificationStatus.INVALID) {
      await rejectTenderCertificationMutation({
        variables: {
          id,
        },
      });
    }
    if (state === TenderCertificationStatus.PARTIALLY_INVALID) {
      await partiallyRejectTenderCertificationMutation({
        variables: {
          id,
        },
      });
    }
  };

  const getStatus = (status: TenderCertificationStatus) => {
    if (status === TenderCertificationStatus.INVALID) {
      return 'destructive';
    }
    if (status === TenderCertificationStatus.PENDING) {
      return 'none';
    }
    if (status === TenderCertificationStatus.CONFIRMED) {
      return 'positive';
    }
    if (status === TenderCertificationStatus.PARTIALLY_INVALID) {
      return 'warning';
    }
    return 'info';
  };

  const totalItems = data?.tenderCertifications?.length;
  return (
    <div className="bg-background-surface h-full overflow-scroll p-10 pb-10">
      {loading ? (
        <Loading width={60} />
      ) : error ? (
        <p>There was an error when requesting your data.</p>
      ) : (
        <>
          <div className="bg-background-surface border-support-line mb-6 flex items-center justify-between border px-4 py-5">
            <p className="text-preset-2 text-ink-dark">
              {totalItems || 0} Tender Certification
              {totalItems !== 1 ? 's' : ''}
            </p>
            <div className="w-96">
              <ClearableInput
                className="rounded border"
                onClear={() => setSearchTerm('')}
              >
                <input
                  className="w-full rounded px-8 py-1 outline-none"
                  value={searchTerm}
                  onChange={(event) => setSearchTerm(event.target.value)}
                  placeholder="Search by Name, Phone or ID"
                  data-cy="search-tender-input"
                />
              </ClearableInput>
            </div>
          </div>
          <div className="border-support-line rounded-lg border">
            <div className="border-support-line rounded-t-lg rounded-r-lg border-b bg-white p-4 pt-6">
              <h2 className="text-preset-4 text-ink-dark">
                Tender Certifications
              </h2>
            </div>
            <div className="overflow-x-auto">
              <table className="min-w-full table-fixed">
                <thead className="bg-background-app">
                  <tr className="h-12">
                    <TableHeaderCell>Name</TableHeaderCell>
                    <TableHeaderCell>Email</TableHeaderCell>
                    <TableHeaderCell>Certificate Number</TableHeaderCell>
                    <TableHeaderCell>Issue Date</TableHeaderCell>
                    <TableHeaderCell>Expiration Date</TableHeaderCell>
                    <TableHeaderCell>Type</TableHeaderCell>
                    <TableHeaderCell>Status</TableHeaderCell>
                    <TableHeaderCell>Attachment</TableHeaderCell>
                    <TableHeaderCell>Actions</TableHeaderCell>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {data?.tenderCertifications?.map((certifications) => (
                    <tr
                      key={certifications.id}
                      className="border-support-line h-14 border-b"
                    >
                      <Cell>
                        {certifications.tenderFirstName}{' '}
                        {certifications.tenderLastName}
                      </Cell>
                      <Cell>{certifications.tenderEmail} </Cell>
                      <Cell>{certifications.certificateNumber} </Cell>
                      <Cell>
                        {format(
                          utcToZonedTime(
                            new Date(certifications.issueDate),
                            'America/Los_Angeles',
                          ),
                          'yyyy-MM-dd',
                        )}
                      </Cell>
                      <Cell>
                        {format(
                          utcToZonedTime(
                            new Date(certifications.expirationDate),
                            'America/Los_Angeles',
                          ),
                          'yyyy-MM-dd',
                        )}
                      </Cell>
                      <Cell>{certifications.typeReadable}</Cell>
                      <Cell>
                        <Pill status={getStatus(certifications.status)}>
                          <span className="capitalize">
                            {certifications.status.toLowerCase()}
                          </span>
                        </Pill>
                      </Cell>
                      <Cell>
                        <a
                          className="text-ink-primary font-semibold"
                          href={certifications.attachmentUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Download
                        </a>
                      </Cell>
                      <Cell>
                        <Button
                          disabled={
                            TenderCertificationStatus.CONFIRMED ===
                            certifications.status
                          }
                          className="text-status-positive font-semibold"
                          onClick={() => {
                            handleChangeStateButton(
                              TenderCertificationStatus.CONFIRMED,
                              certifications.id,
                            );
                          }}
                        >
                          Confirm
                        </Button>
                        <Button
                          disabled={
                            TenderCertificationStatus.INVALID ===
                            certifications.status
                          }
                          className="text-status-destructive ml-6 font-semibold"
                          onClick={() => {
                            handleChangeStateButton(
                              TenderCertificationStatus.INVALID,
                              certifications.id,
                            );
                          }}
                        >
                          Reject
                        </Button>
                        <Button
                          disabled={
                            TenderCertificationStatus.PARTIALLY_INVALID ===
                            certifications.status
                          }
                          className="text-status-warning ml-6 font-semibold"
                          onClick={() => {
                            handleChangeStateButton(
                              TenderCertificationStatus.PARTIALLY_INVALID,
                              certifications.id,
                            );
                          }}
                        >
                          Partial Reject
                        </Button>
                      </Cell>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TenderCertifications;
