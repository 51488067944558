import { gql, LazyQueryHookOptions, useLazyQuery } from '@apollo/client';
import { DayOfStats } from './__generated__/DayOfStats';

const DAY_OF_STATS = gql`
  query DayOfStats {
    dayOfStats {
      total {
        value
        delta
      }
      remaining {
        value
        delta
      }
      notStaffed {
        value
        delta
      }
      pending {
        value
        delta
      }
      unconfirmedLessThan30 {
        value
        delta
      }
      unconfirmedBetween30and60 {
        value
        delta
      }
      unconfirmedBetween60and180 {
        value
        delta
      }
      unpublished {
        value
        delta
      }
      tendersOutOfTime {
        value
        delta
      }
      createdAt
      updatedAt
    }
  }
`;

export const useLazyDayOfStatsQuery = (
  options?: LazyQueryHookOptions<DayOfStats>,
) => useLazyQuery<DayOfStats>(DAY_OF_STATS, options);

export default DAY_OF_STATS;
