import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import SHIFT_FOR_STAFFING_MUTATION from '../fragments/ShiftForStaffingMutation';
import {
  RemoveTenderApplications,
  RemoveTenderApplicationsVariables,
} from './__generated__/RemoveTenderApplications';

const REMOVE_TENDER_APPLICATIONS = gql`
  mutation RemoveTenderApplications($staffingIds: [ID!]!) {
    mutationPayload: removeTenderApplications(staffingIds: $staffingIds) {
      modifiedStaffings {
        id
        status
        shift {
          ...ShiftForStaffingMutation
        }
      }
      errors {
        staffingId
        message
      }
    }
  }
  ${SHIFT_FOR_STAFFING_MUTATION}
`;

export const useRemoveTenderApplicationsMutation = (
  options?: MutationHookOptions<
    RemoveTenderApplications,
    RemoveTenderApplicationsVariables
  >,
) =>
  useMutation<RemoveTenderApplications, RemoveTenderApplicationsVariables>(
    REMOVE_TENDER_APPLICATIONS,
    options,
  );

export default REMOVE_TENDER_APPLICATIONS;
