import { gql, QueryHookOptions, useLazyQuery } from '@apollo/client';
import {
  GetAssignments,
  GetAssignmentsVariables,
} from './__generated__/GetAssignments';

const GET_ASSIGNMENTS_QUERY = gql`
  query GetAssignments(
    $paginationOptions: PaginationOptions!
    $filters: GetAssignmentsFilters
  ) {
    getAssignments(paginationOptions: $paginationOptions, filters: $filters) {
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
      items {
        id
        email
        firstName
        lastName
        organization {
          id
          name
        }
        admins {
          id
          admin {
            id
            email
            firstName
            lastName
          }
          region {
            id
            name
          }
        }
      }
    }
  }
`;

export const useGetAssignmentsLazyQuery = (
  options?: QueryHookOptions<GetAssignments, GetAssignmentsVariables>,
) =>
  useLazyQuery<GetAssignments, GetAssignmentsVariables>(
    GET_ASSIGNMENTS_QUERY,
    options,
  );

export default GET_ASSIGNMENTS_QUERY;
