import React, { useCallback, useEffect, useState } from 'react';
import { RadioGroup } from '@headlessui/react';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Combobox from 'src/components/Combobox';
import LocationInput from 'src/components/LocationInput/LocationInput';
import Modal from 'src/components/Modal';
import ChevronDown from 'src/components/Icons/ChevronDown';
import RadioOption from 'src/components/RadioOption';
import { getAddress } from 'src/utils/address';
import {
  GetJob_job as Job,
  GetJob_job_venue as Venue,
  GetJob_job_venue_address as Address,
} from 'src/graphql/queries/__generated__/GetJob';
import {
  EditJobVenueInput,
  ParkingEnum,
  VenueType,
} from 'src/__generated__/globalTypes';
import { useUpdateJobVenue } from 'src/graphql/mutations/UpdateJobVenue';

type MarkerType = {
  lat: number;
  lng: number;
};

const validationSchema = Yup.object().shape({
  venue: Yup.object().shape({
    name: Yup.string().when('type', {
      is: 'COMMERCIAL',
      then: Yup.string().required('* Input a venue name'),
    }),
    address: Yup.object().required(),
  }),
});

const parkingOptions = [
  { id: 'Street Parking', label: 'Street Parking' },
  { id: 'Lot Parking', label: 'Lot Parking' },
  { id: ParkingEnum.NO_PARKING, label: 'No Parking' },
];

const containerStyle = { height: '200px', width: '100%' };

const getParkingType = (parkingType?: string) => {
  if (!parkingType) {
    return undefined;
  }
  if (parkingType.includes('STREET')) {
    return 'Street Parking';
  }
  if (parkingType.includes('LOT')) {
    return 'Lot Parking';
  }
  return 'No Parking';
};

export const EditJobLocationModal: React.FC<{
  open: boolean;
  onClose: () => void;
  job: Job;
}> = ({ open, onClose, job }) => {
  const [updateJobAsAdmin, { loading }] = useUpdateJobVenue();
  const [venueId, setVenueId] = useState<string | undefined>(job.venue?.id);
  const [parkingType, setParkingType] = useState<string | undefined>();
  const [displayVenueName, setDisplayVenueName] = useState<boolean | undefined>(
    job.venue?.type === VenueType.COMMERCIAL,
  );
  const [marker, setMarker] = useState<MarkerType | undefined>(
    job.venue?.address?.lat && job.venue?.address?.lng
      ? {
          lng: job.venue.address.lng,
          lat: job.venue.address.lat,
        }
      : undefined,
  );

  const { handleSubmit, control, register, errors, setValue } = useForm<{
    venue: {
      address: Omit<Address, '__typename'>;
      id: string;
      name?: string | null;
      parkingType: ParkingEnum;
    };
    locationContact: {
      name?: string;
      phoneNumber?: string;
    };
    mealProvided: boolean;
    useBackDoor: boolean;
  }>({
    defaultValues: {
      venue: {
        ...job.venue,
        address: {
          ...job.venue?.address,
        },
      },
      locationContact: {
        name: job.locationContact?.name,
        phoneNumber: job.locationContact?.phoneNumber || undefined,
      },
      useBackDoor: job.useBackDoor,
      mealProvided: job.mealProvided,
    },
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    setParkingType(getParkingType(job.venue?.parkingType));
  }, [job.venue?.parkingType]);

  const submitForm = async (input: EditJobVenueInput) => {
    const { venue: tempVenue } = job;
    const { __typename, parking, ...venue } = tempVenue as Venue;
    const hasLocationContact =
      input.locationContact?.name || input.locationContact?.phoneNumber;

    const dataInput = {
      useBackDoor: input.useBackDoor,
      mealProvided: input.mealProvided,
      venue: {
        ...venue,
        ...input.venue,
        parkingType:
          parkingType === ParkingEnum.NO_PARKING
            ? ParkingEnum.NO_PARKING
            : input.venue?.parkingType ?? venue.parkingType,
        name:
          input.venue?.type === VenueType.COMMERCIAL
            ? input?.venue.name
            : undefined,
        id: venueId,
      },
      ...(hasLocationContact
        ? {
            locationContact: {
              id: job.locationContact?.id,
              ...input.locationContact,
            },
          }
        : {}),
    };

    await updateJobAsAdmin({
      variables: {
        jobId: job.id,
        input: dataInput as EditJobVenueInput,
      },
      refetchQueries: ['GetJob'],
    });

    onClose();
  };

  const handleLocationChange = useCallback(
    (item: google.maps.places.PlaceResult) => {
      setVenueId(undefined);
      setValue('venue.address', getAddress(item));
    },
    [setValue],
  );

  const handleMarkerChange = useCallback((newMarker: MarkerType) => {
    setMarker(newMarker);
  }, []);

  return (
    <Modal open={open} onClose={onClose} className="w-11/12 max-w-2xl">
      <div className="h-160 overflow-y-scroll px-6 py-4">
        {job.isFlexpoolOrder && (
          <div className="bg-background-app mb-4 mt-1 rounded-md p-2">
            <p className="text-preset-5P text-status-warning font-medium">
              This is a Talent/Flexpool order. Updates made on Nowsta{' '}
              <strong>will overwrite</strong> certain values below.
            </p>
          </div>
        )}

        <label
          htmlFor="venue.type"
          className="text-preset-6 text-ink-dark font-medium"
        >
          Venue type
        </label>
        <Controller
          name="venue.type"
          control={control}
          defaultValue={job.venue?.type}
          render={({ onChange, value }) => (
            <RadioGroup
              value={value}
              onChange={(newType) => {
                onChange(newType);
                setDisplayVenueName(newType === VenueType.COMMERCIAL);
              }}
            >
              <div className="my-4 flex flex-row">
                <RadioOption
                  className="pr-6"
                  value={VenueType.RESIDENTIAL}
                  label="Residential"
                />
                <RadioOption value={VenueType.COMMERCIAL} label="Commercial" />
              </div>
            </RadioGroup>
          )}
        />
        {displayVenueName && (
          <div className="mb-6">
            <label
              htmlFor="venue.name"
              className="text-preset-6 text-ink-dark mb-4 font-medium"
            >
              Venue name
            </label>
            <input
              type="text"
              ref={register()}
              onChange={() => setVenueId(undefined)}
              name="venue.name"
              className="bg-background-app focus:ring-primary focus:border-primary block w-full rounded px-4"
              placeholder="Enter venue name"
            />
            <span className="text-preset-5 text-red-700">
              {errors.venue?.name?.message}
            </span>
          </div>
        )}
        <Controller
          name="venue.address"
          control={control}
          render={({ value, onChange }) => (
            <LocationInput
              label="Venue address"
              containerStyle={containerStyle}
              marker={marker}
              onMarkerChange={handleMarkerChange}
              onLocationChange={handleLocationChange}
              displayCircle={false}
              clearText=""
              displayMarker
            />
          )}
        />
        <Controller
          name="mealProvided"
          control={control}
          render={({ value, onChange }) => (
            <div className="mt-4 flex items-center">
              <input
                type="checkbox"
                className="text-brand-50 border-ink-dark block h-6 w-6 rounded border-2 focus:ring-0"
                onChange={(event) => onChange(event.currentTarget.checked)}
                checked={value}
              />
              <label
                htmlFor="mealProvided"
                className="text-preset-6 text-ink-dark ml-4 font-medium"
              >
                Staff meal provided
              </label>
            </div>
          )}
        />
        <Controller
          name="useBackDoor"
          control={control}
          render={({ value, onChange }) => (
            <div className="mt-4 flex items-center">
              <input
                type="checkbox"
                className="text-brand-50 border-ink-dark block h-6 w-6 rounded border-2 focus:ring-0"
                onChange={(event) => onChange(event.currentTarget.checked)}
                checked={value}
              />
              <label
                htmlFor="useBackDoor"
                className="text-preset-6 text-ink-dark ml-4 font-medium"
              >
                Use back door
              </label>
            </div>
          )}
        />
        <div className="mt-6">
          <Combobox
            id="parkingType"
            label="Parking Type"
            items={parkingOptions}
            loadingItems={parkingOptions.length === 0}
            renderTrailingComponent={() => (
              <ChevronDown className="text-ink-not-as-dark h-3 w-3" />
            )}
            renderLoadingComponent={() => <p>Loading</p>}
            renderNoResultsComponent={() => <p>No options</p>}
            renderItemComponent={({ item, highlighted }) => (
              <div
                className={`cursor-pointer py-2 px-4 ${
                  highlighted ? 'bg-background-app' : ''
                }`}
              >
                <p className="text-preset-6 text-ink-dark font-medium">
                  {item.label}
                </p>
              </div>
            )}
            onChange={(newType) => {
              if (newType?.id === ParkingEnum.NO_PARKING) {
                setParkingType(ParkingEnum.NO_PARKING);
              } else {
                setParkingType(newType?.id);
              }
            }}
            onInputChange={() => null}
            inputValue={parkingType}
            inputReadOnly
            openMenuOnFocus
          />
          {parkingType === 'Street Parking' && (
            <Controller
              name="venue.parkingType"
              control={control}
              defaultValue={job.venue?.parkingType}
              render={({ onChange, value }) => (
                <RadioGroup
                  value={value}
                  onChange={(newType) => {
                    onChange(newType);
                    setVenueId(undefined);
                  }}
                >
                  <RadioOption
                    className=""
                    value={ParkingEnum.STREET_AMPLE}
                    label="Ample street parking"
                  />
                  <RadioOption
                    className=""
                    value={ParkingEnum.STREET_LIMITED}
                    label="Very little to no street parking"
                  />
                  <RadioOption
                    className=""
                    value={ParkingEnum.STREET_FAR}
                    label="Can find street parking, but may have to walk a bit"
                  />
                </RadioGroup>
              )}
            />
          )}
          {parkingType === 'Lot Parking' && (
            <Controller
              name="venue.parkingType"
              control={control}
              defaultValue={job.venue?.parkingType}
              render={({ onChange, value }) => (
                <RadioGroup
                  value={value}
                  onChange={(newType) => {
                    onChange(newType);
                    setVenueId(undefined);
                  }}
                >
                  <RadioOption
                    className=""
                    value={ParkingEnum.LOT_TO_BE_CONFIRMED}
                    label="Paid lot nearby, host will validate"
                  />
                  <RadioOption
                    className=""
                    value={ParkingEnum.LOT_FREE}
                    label="Free private lot"
                  />
                  <RadioOption
                    className=""
                    value={ParkingEnum.LOT_NEARBY}
                    label="Paid lots nearby"
                  />
                </RadioGroup>
              )}
            />
          )}
        </div>
        <div className="mt-4">
          <label
            htmlFor="venue.parkingInstructions"
            className="text-preset-6 text-ink-dark mb-4 font-medium"
          >
            Parking Instructions
          </label>
          <textarea
            ref={register()}
            onChange={() => setVenueId(undefined)}
            name="venue.parkingInstructions"
            className="bg-background-app focus:ring-primary focus:border-primary block h-24 w-full rounded px-4"
            placeholder="Enter parking instructions"
          />
        </div>

        <div className="mt-4">
          <label
            htmlFor="venue.otherInstructions"
            className="text-preset-6 text-ink-dark mb-4 font-medium"
          >
            Other location details
          </label>
          <textarea
            ref={register()}
            onChange={() => setVenueId(undefined)}
            name="venue.otherInstructions"
            className="bg-background-app focus:ring-primary focus:border-primary block h-24 w-full rounded px-4"
            placeholder="Enter other location details"
          />
        </div>

        <div className="mt-4">
          <p className="text-preset-5 text-ink-dark mb-2 font-medium">
            Location Contact
          </p>

          <div className="mb-2 flex items-center gap-4">
            <label
              htmlFor="locationContact.name"
              className="text-preset-6 text-ink-dark w-16 font-medium"
            >
              Name
            </label>

            <input
              className="focus:ring-primary focus:border-primary sm:text-preset-6 border-support-line-darker block w-full rounded"
              name="locationContact.name"
              placeholder="Enter the contact name"
              ref={register()}
              type="text"
            />
          </div>

          <div className="flex items-center gap-4">
            <label
              htmlFor="locationContact.phoneNumber"
              className="text-preset-6 text-ink-dark w-16 font-medium"
            >
              Phone
            </label>

            <input
              className="focus:ring-primary focus:border-primary sm:text-preset-6 border-support-line-darker block w-full rounded"
              name="locationContact.phoneNumber"
              placeholder="Enter the contact phone number"
              ref={register()}
              type="text"
            />
          </div>
        </div>
      </div>

      <div className="text-ink-dark border-ink-dark-200 flex justify-end border-t border-solid px-6 py-4">
        <button
          className="rounded-md py-2 px-4"
          onClick={(event) => {
            event.preventDefault();
            onClose();
          }}
        >
          Cancel
        </button>
        <button
          disabled={loading}
          className="ml-4 rounded-md py-2 px-4"
          onClick={handleSubmit(submitForm)}
        >
          {loading ? 'Saving' : 'Save'}
        </button>
      </div>
    </Modal>
  );
};
