import React from 'react';

const DataCell: React.FC<{ className?: string; colSpan?: number }> = ({
  children,
  className = '',
  colSpan,
  ...props
}) => (
  <td
    colSpan={colSpan}
    className={`text-ink-dark px-4 py-2 ${className}`}
    {...props}
  >
    {children}
  </td>
);

export default DataCell;
