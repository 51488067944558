import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { Invoices, InvoicesVariables } from './__generated__/Invoices';

const INVOICES_QUERY = gql`
  query Invoices(
    $paginationOptions: PaginationOptions!
    $orderByField: String
    $orderByDirection: OrderByDirectionEnum
    $filters: InvoiceFilters
  ) {
    invoices(
      paginationOptions: $paginationOptions
      orderByField: $orderByField
      orderByDirection: $orderByDirection
      filters: $filters
    ) {
      meta {
        itemCount
        itemsPerPage
        totalItems
        totalPages
        currentPage
      }
      items {
        id
        createdAt
        dueDate
        customId
        total
        status
        documentURL
        clientName
      }
    }
  }
`;

export const useInvoicesQuery = (
  options?: QueryHookOptions<Invoices, InvoicesVariables>,
) => useQuery<Invoices, InvoicesVariables>(INVOICES_QUERY, options);

export default INVOICES_QUERY;
