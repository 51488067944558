import React from 'react';
import AuthScreenShell from 'src/components/AuthScreenShell';
import { RESET_PASSWORD_ROUTE } from 'src/routes';
import SetNewPasswordForm from './SetNewPassword';

const ResetPasswordView: React.FC = () => (
  <AuthScreenShell label="Admin">
    <div>
      <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
        Reset your account's password
      </h2>
    </div>

    <div className="mt-8">
      <div className="mt-6">
        <SetNewPasswordForm submitTo={RESET_PASSWORD_ROUTE} />
      </div>
    </div>
  </AuthScreenShell>
);

export default ResetPasswordView;
