import React, { useEffect, useMemo, useState } from 'react';

import { useRegionsQuery } from 'src/graphql/queries/Regions';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import Loading from 'src/components/Loading';
import Modal from 'src/components/Modal';
import SearchIcon from 'src/components/Icons/Search';
import {
  SuperRegions_superRegions,
  SuperRegions_superRegions_regions,
} from 'src/graphql/queries/__generated__/SuperRegions';
import { useCreateSuperRegionMutation } from 'src/graphql/mutations/CreateSuperRegion';
import { useForm, SubmitHandler } from 'react-hook-form';
import { SuperRegionUpdateInput } from 'src/__generated__/globalTypes';
import { useRemoveSuperRegionMutation } from 'src/graphql/mutations/DeleteStaffingRegion';
import { useUpdateStaffingRegionMutation } from 'src/graphql/mutations/UpdateStaffingRegion';

export type RegionId = SuperRegions_superRegions_regions['id'];
export type RegionName = SuperRegions_superRegions_regions['name'];
export type SelectedSuperRegionSelectedStaffingsSet = Set<RegionId>;

const StaffingRegionsManageModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  staffingRegion: SuperRegions_superRegions | null;
}> = ({ isOpen, onClose, onSuccess, staffingRegion }) => {
  const {
    data: regions,
    loading,
    refetch,
  } = useRegionsQuery({
    fetchPolicy: 'network-only',
  });

  const { handleSubmit, register, formState } = useForm<SuperRegionUpdateInput>(
    {
      mode: 'onChange',
      defaultValues: {
        id: staffingRegion?.id,
        name: staffingRegion?.name,
      },
    },
  );

  const [regionQuery, setRegionQuery] = useState<RegionName>('');
  const [selectedRegions, setSelectedRegions] =
    useState<SelectedSuperRegionSelectedStaffingsSet>(new Set());
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const [createSuperRegion] = useCreateSuperRegionMutation({
    onCompleted(data) {
      if (data.createSuperRegion) {
        refetch();
        onSuccess();
        handleCloseModal();
      }
    },
  });

  const [updateSuperRegion] = useUpdateStaffingRegionMutation({
    onCompleted(response) {
      if (response.updateSuperRegion) {
        refetch();
        onSuccess();
        handleCloseModal();
      }
    },
  });

  const [deleteSuperRegion] = useRemoveSuperRegionMutation({
    onCompleted(response) {
      if (response.deleteSuperRegion) {
        setShowDeleteConfirmation(false);
        refetch();
        onSuccess();
        handleCloseModal();
      }
    },
  });

  useEffect(() => {
    setSelectedRegions(new Set(staffingRegion?.regions.map((r) => r.id)));
  }, [staffingRegion]);

  const filteredRegions = useMemo(() => {
    return regionQuery
      ? regions?.regions.filter((region) =>
          region.name.toLowerCase().includes(regionQuery.toLowerCase()),
        )
      : regions?.regions;
  }, [regionQuery, regions]);

  const shouldAllowCreate =
    formState.isValid && formState.isDirty && selectedRegions.size;
  const isUpdateMode = !!staffingRegion;

  const handleCloseModal = () => {
    handleResetModal();
    onClose();
  };

  const handleDelete = async () => {
    if (!staffingRegion?.id) {
      return;
    }

    await deleteSuperRegion({
      variables: {
        id: staffingRegion.id,
      },
    });
  };

  const handleResetModal = () => {
    setRegionQuery('');
    setSelectedRegions(new Set());
  };

  const handleRegionToggle = (id: RegionId) => {
    if (selectedRegions.has(id)) {
      setSelectedRegions((prev) => new Set([...prev].filter((x) => x !== id)));
    } else {
      setSelectedRegions((prev) => new Set(prev.add(id)));
    }
  };

  const handleFormSubmit: SubmitHandler<SuperRegionUpdateInput> = async (
    formData,
  ) => {
    if (!formData.name || !selectedRegions.size) {
      return;
    }

    if (isUpdateMode && staffingRegion) {
      await updateSuperRegion({
        variables: {
          input: {
            id: staffingRegion.id,
            name: formData.name,
            regionsIds: [...selectedRegions],
          },
        },
      });
    } else {
      await createSuperRegion({
        variables: {
          input: {
            name: formData.name,
            regionsIds: [...selectedRegions],
          },
        },
      });
    }
  };

  const handleShowDeleteConfirm = () => {
    setShowDeleteConfirmation(true);
  };

  return (
    <Modal
      className="block w-full max-w-3xl"
      onClose={handleCloseModal}
      open={isOpen}
    >
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="px-6 py-4" data-cy="staffing-regions-modal">
          <h2 className="text-preset-3 text-ink mb-8">
            {isUpdateMode ? 'Manage' : 'Create New'} Staffing Region
          </h2>

          <div>
            <label className="text-preset-4 text-ink mb-4 block">
              Staffing region name
            </label>

            <input
              type="text"
              name="name"
              className="border-support-line-darker text-preset-6 mb-2 block w-full rounded px-2 py-2.5 leading-5"
              data-cy="staffing-regions-modal-name"
              defaultValue={staffingRegion?.name}
              ref={register({ required: true })}
              required
            />

            <h3 className="text-preset-4 mt-8">Add geographic regions</h3>
            <p className="text-preset-5 text-dark mt-4">
              Check all the Regions you want to belong to this Staffing Region.
            </p>

            <div className="mt-6">
              <div className="relative">
                <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                  <button
                    type="submit"
                    className="focus:shadow-outline p-1 focus:outline-none"
                  >
                    <SearchIcon className="text-ink-not-as-dark h-3 w-3" />
                  </button>
                </span>
                <input
                  className="border-support-line-darker text-dark text-preset-6 placeholder:text-ink-not-as-dark mb-2 block w-1/2 rounded py-2.5 pl-8 pr-2 leading-5"
                  data-cy="staffing-regions-modal-search"
                  id="query"
                  name="query"
                  onChange={(event) => {
                    setRegionQuery(event.currentTarget.value);
                  }}
                  placeholder="Search"
                  type="search"
                />
              </div>
            </div>

            <div className="border-support-line bg-background-surface mt-6 max-h-[450px] overflow-auto border shadow-sm">
              <table className="border-support-line min-w-full border-b">
                <thead className="bg-background-app">
                  <tr>
                    <th className="text-preset-7 text-ink-not-as-dark border-t-support-line w-12 select-none whitespace-nowrap border-t px-4 py-3 text-left font-medium uppercase tracking-wider">
                      &nbsp;
                    </th>
                    <th className="text-preset-7 text-ink-not-as-dark border-t-support-line w-1/2 select-none whitespace-nowrap border-t px-4 py-3 text-left font-medium uppercase tracking-wider">
                      Region
                    </th>

                    <th className="text-preset-7 text-ink-not-as-dark border-t-support-line select-none whitespace-nowrap border-t px-4 py-3 text-left font-medium uppercase tracking-wider">
                      Current staffing region
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {loading ? (
                    <Loading />
                  ) : (
                    filteredRegions?.map((region) => (
                      <tr
                        key={region.id}
                        data-cy="staffing-regions-modal-region-row"
                      >
                        <td className="text-dark text-preset-6 bg-background-surface border-b-support-line border-b px-4 py-4">
                          <input
                            type="checkbox"
                            className="text-brand-50 border-ink-dark block h-6 w-6 cursor-pointer rounded border-2 focus:ring-0"
                            checked={selectedRegions.has(region.id)}
                            data-cy="staffing-regions-modal-checkbox"
                            onChange={() => {
                              handleRegionToggle(region.id);
                            }}
                          />
                        </td>
                        <td
                          className={`text-dark text-preset-6 bg-background-surface border-b-support-line border-b px-4 py-4 ${
                            true && 'border-b-red'
                          }`}
                          data-cy="staffing-regions-modal-subregion"
                        >
                          {region.name}
                        </td>

                        <td className="text-dark text-preset-6 bg-background-surface border-b-support-line border-b px-4 py-4">
                          {region?.superRegion?.name}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="border-support-line flex w-full justify-end border-t-2 p-4">
          {isUpdateMode ? (
            <button
              type="button"
              className="border-background-surface text-preset-5 text-status-destructive bg-background-surface hover:bg-status-destructive-light mr-auto h-9 items-center rounded-md border px-2.5 font-normal focus:outline-none"
              onClick={handleShowDeleteConfirm}
              data-cy="staffing-regions-modal-delete-button"
            >
              Delete Region
            </button>
          ) : null}

          <button
            type="button"
            className="border-ink-dark text-preset-5 text-ink-dark bg-background-surface hover:text-ink-not-as-dark hover:border-ink-not-as-dark mr-2 h-9 items-center rounded-md border px-2.5 font-normal focus:outline-none"
            onClick={handleCloseModal}
            data-cy="staffing-regions-modal-cancel-button"
          >
            Cancel
          </button>

          <button
            className="text-preset-5 bg-primary text-ink-clear hover:bg-primary-active disabled:bg-support-line-darker h-9 rounded px-5 font-medium disabled:text-white"
            disabled={!shouldAllowCreate}
            data-cy="staffing-regions-modal-create-button"
            type="submit"
          >
            {isUpdateMode ? 'Save' : 'Create Region'}
          </button>
        </div>
      </form>

      {showDeleteConfirmation && (
        <ConfirmationDialog
          cancelClassName="border-ink-dark text-preset-5 text-ink-dark bg-background-surface hover:text-ink-not-as-dark hover:border-ink-not-as-dark h-9 items-center rounded-md border px-2.5 font-normal focus:outline-none"
          cancelText="Go Back"
          dialogClassName=""
          className="z-50"
          overlayClassName="fixed inset-0 bg-black opacity-30 z-40"
          onClose={() => {
            setShowDeleteConfirmation(false);
          }}
          onSubmit={handleDelete}
          open={showDeleteConfirmation}
          submitClassName="text-preset-5 bg-status-destructive text-ink-clear hover:bg-status-destructive-light hover:text-status-destructive disabled:bg-support-line-darker h-9 rounded px-5 font-medium disabled:text-white"
          submitText="Delete Staffing Region"
          title={'Remove Staffing Region?'}
        >
          All Regions will be left with no Staffing Region assigned
        </ConfirmationDialog>
      )}
    </Modal>
  );
};

export default StaffingRegionsManageModal;
