import { gql, QueryHookOptions, useLazyQuery } from '@apollo/client';
import {
  InvoiceTotalEstimation,
  InvoiceTotalEstimationVariables,
} from './__generated__/InvoiceTotalEstimation';

const INVOICE_TOTAL_ESTIMATION_QUERY = gql`
  query InvoiceTotalEstimation(
    $staffingIds: [ID!]
    $notStaffingIds: [ID!]
    $staffingFilters: [Filter!]
  ) {
    invoiceTotalEstimation(
      staffingIds: $staffingIds
      notStaffingIds: $notStaffingIds
      staffingFilters: $staffingFilters
    )
  }
`;

export const useInvoiceTotalEstimationQuery = (
  options?: QueryHookOptions<
    InvoiceTotalEstimation,
    InvoiceTotalEstimationVariables
  >,
) =>
  useLazyQuery<InvoiceTotalEstimation, InvoiceTotalEstimationVariables>(
    INVOICE_TOTAL_ESTIMATION_QUERY,
    options,
  );

export default INVOICE_TOTAL_ESTIMATION_QUERY;
