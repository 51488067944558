import { gql } from '@apollo/client';

const INSTRUCTIONS_FOR_JOB_DETAILS = gql`
  fragment InstructionsForJobDetails on JobInstruction {
    __typename
    id
    attire
    blackApron
    blackNonSlipShoes
    barKit
    knifeKit
    maskRequired
    position {
      id
      name
    }
  }
`;

export default INSTRUCTIONS_FOR_JOB_DETAILS;
