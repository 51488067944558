import React, {
  Fragment,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  OrderByDirectionEnum,
  StaffingStatus,
} from 'src/__generated__/globalTypes';
import ChevronDown from 'src/components/Icons/ChevronDown';
import ChevronUp from 'src/components/Icons/ChevronUp';
import Pagination from 'src/components/Pagination';
import StaffingStatusBadge from 'src/components/StaffingStatusBadge';
import TableHeaderCell from 'src/components/TableHeaderCell';
import { Can } from 'src/contexts/AbilityContext';
import { StaffingSelectionContext } from 'src/contexts/StaffingsSelectionContext';
import { StaffingListItem } from 'src/graphql/fragments/__generated__/StaffingListItem';
import { ApprovedJobApplicationsPaginated_approvedJobApplicationsPaginated_meta as PaginationMeta } from 'src/graphql/queries/__generated__/ApprovedJobApplicationsPaginated';
import { GetShifts_shifts_edges_node_position_tagGroups } from 'src/graphql/queries/__generated__/GetShifts';
import useSort from 'src/hooks/useSort';
import { ApprovedStaffingSelectionContext } from 'src/contexts/ApprovedStaffingsSelectionContext';
import ActionsCell from './ActionsCell';
import ClientPreferenceCell from './ClientPreferenceCell';
import ClientPreferenceMatchCell from './ClientPreferenceMatchCell';
import ConflictsCell from './ConflictsCell';
import DataCell from './DataCell';
import DrivingDistanceCell from './DrivingDistanceCell';
import ScoreCell from './ScoreCell';
import TenderCell from './TenderCell';
import ReliabilityPredictionCell from './ReliabilityPredictionCell';

interface Column {
  displayName: string;
  sortable: boolean;
  renderComponent: ReactNode;
}

interface ColumnConfig {
  [key: string]: Column;
}

export type ColumnName = keyof ColumnConfig;

type OnAction = (staffing: StaffingListItem) => void;

const getColumnsConfig = ({
  positionTagGroups,
  staffing,
  isRequested,
}: {
  positionTagGroups?: GetShifts_shifts_edges_node_position_tagGroups[];
  staffing?: StaffingListItem;
  isRequested?: boolean;
}): ColumnConfig | undefined => {
  if (!staffing) {
    return;
  }

  const detailText = `${staffing.phoneNumber}${
    isRequested ? ' · Requested' : ''
  }`;

  const hasAutoApprovalMatch = !!staffing.staffedByAutomationStepId;

  return {
    tender: {
      displayName: 'Tender',
      sortable: true,
      renderComponent: (
        <TenderCell
          tenderId={staffing.tenderId}
          avatarURL={staffing.avatarURL}
          firstName={staffing.firstName}
          lastName={staffing.lastName}
          detailText={detailText}
          hasAutoApprovalMatch={hasAutoApprovalMatch}
        />
      ),
    },
    preference: {
      displayName: 'Preference',
      sortable: true,
      renderComponent: (
        <ClientPreferenceCell preference={staffing.clientTenderStatus} />
      ),
    },
    tagMatch: {
      displayName: 'Tag Match',
      sortable: true,
      renderComponent: (
        <ClientPreferenceMatchCell
          positionTagGroups={positionTagGroups}
          tenderTagGroups={staffing.tagGroups}
        />
      ),
    },
    drivingDistance: {
      displayName: 'Driving Distance',
      sortable: true,
      renderComponent: (
        <DrivingDistanceCell
          distanceInMiles={staffing.drivingDistanceInMiles}
          duration={staffing.drivingTime}
        />
      ),
    },
    status: {
      displayName: 'Status',
      sortable: true,
      renderComponent: (
        <DataCell>
          <StaffingStatusBadge
            status={staffing.status}
            createdAt={staffing.createdAt}
          />
        </DataCell>
      ),
    },
    conflicts: {
      displayName: 'Conflicts',
      sortable: true,
      renderComponent: (
        <ConflictsCell
          type={
            staffing.hardConflicts
              ? 'hard'
              : staffing.softConflicts
              ? 'soft'
              : undefined
          }
        />
      ),
    },
    score: {
      displayName: 'Score',
      sortable: true,
      renderComponent: <ScoreCell score={staffing.score} />,
    },
    classif: {
      displayName: 'Classif.',
      sortable: false,
      renderComponent: (
        <DataCell>
          <p className="text-preset-6 mb-1 font-normal">
            {(() => {
              const classifications = [];
              staffing.tenderHasCompletedOnboardingW2 &&
                classifications.push('W2');
              staffing.tenderHasCompletedOnboarding1099 &&
                classifications.push('1099');
              return classifications.join(', ') || '-';
            })()}
          </p>
        </DataCell>
      ),
    },
    reliabilityPrediction: {
      displayName: 'Rel. Pred.',
      sortable: false,
      renderComponent: (
        <ReliabilityPredictionCell
          staffingId={staffing.id}
          staffingStatus={staffing.status}
          reliabilityPrediction={staffing.reliabilityPrediction}
        />
      ),
    },
  };
};

const StaffingList: React.FC<{
  title: string;
  count: number;
  shiftId: string;
  maxCount?: number;
  staffings?: StaffingListItem[];
  requestedTenderIds?: string[];
  defaultSortColumn: keyof ColumnConfig;
  defaultSortDirection: OrderByDirectionEnum;
  emptyStateLabel?: string;
  loading?: boolean;
  warning?: boolean;
  backgroundClass?: string;
  actionComponent?: ReactNode;
  positionTagGroups?: GetShifts_shifts_edges_node_position_tagGroups[];
  onSelectRow?: (staffing: StaffingListItem) => void;
  onDeselectRow?: (staffing: StaffingListItem) => void;
  onAction?: OnAction;
  onToggle?: (isExpanded: boolean) => Promise<void> | void;
  onSort?: (
    column?: keyof ColumnConfig,
    direction?: OrderByDirectionEnum,
  ) => Promise<unknown> | unknown;
  onPageChange?: (
    page: number,
    column?: keyof ColumnConfig,
    direction?: OrderByDirectionEnum,
  ) => void;
  paginationMeta?: PaginationMeta;
}> = ({
  title,
  count,
  shiftId,
  maxCount,
  staffings,
  defaultSortColumn,
  defaultSortDirection,
  emptyStateLabel,
  loading,
  warning,
  backgroundClass,
  actionComponent,
  onSelectRow,
  onDeselectRow,
  onAction,
  onToggle,
  onSort,
  onPageChange,
  paginationMeta,
  positionTagGroups,
  requestedTenderIds,
}) => {
  const approvedContext = useContext(ApprovedStaffingSelectionContext);
  const applicantsContext = useContext(StaffingSelectionContext);
  //since both applicants and approved lists can be expanded, need to differentiate context based on the title
  const selectedStaffings =
    title === 'APPROVED' ? approvedContext : applicantsContext;
  const [isExpanded, setIsExpanded] = useState(false);
  const [allRowsSelected, setAllRowsSelected] =
    useState<Record<number, boolean>>();
  const [handleSort, { sortedColumn, sortDirection, resetSorting }] = useSort<
    keyof ColumnConfig
  >({
    onSort,
    defaultSortColumn,
    defaultSortDirection,
  });
  const currentPage = paginationMeta?.currentPage || 1;
  const containerBgClass = warning
    ? 'bg-status-warning-light'
    : backgroundClass || 'bg-white';

  const staffingRows = useMemo(
    () =>
      staffings?.map((staffing) => ({
        staffing,
        tableData: {
          ...getColumnsConfig({
            positionTagGroups,
            staffing,
            isRequested: requestedTenderIds?.includes(staffing.tenderId),
          }),
        },
      })),
    [positionTagGroups, requestedTenderIds, staffings],
  );

  const columns = useMemo(() => {
    const colNames =
      staffingRows && staffingRows.length
        ? Object.keys(staffingRows[0].tableData)
        : [];

    return colNames.map((colName) => {
      const isSortable =
        staffingRows && staffingRows[0]?.tableData[colName]?.sortable;

      return (
        <TableHeaderCell
          key={colName}
          sortDirection={sortedColumn === colName ? sortDirection : undefined}
          onClick={isSortable ? handleSort(colName) : undefined}
        >
          {staffingRows && staffingRows[0]?.tableData[colName]?.displayName}
        </TableHeaderCell>
      );
    });
  }, [handleSort, sortDirection, sortedColumn, staffingRows]);

  const handleToggle = () => {
    onToggle?.(isExpanded);
    setIsExpanded(!isExpanded);
    if (isExpanded) {
      resetSorting();
    }
  };

  const handleSelectAll = () => {
    setAllRowsSelected({
      ...allRowsSelected,
      [currentPage]: true,
    });
    staffings?.forEach((staffing) => {
      onSelectRow?.(staffing);
    });
  };

  const handleDeselectAll = () => {
    setAllRowsSelected({
      ...allRowsSelected,
      [currentPage]: false,
    });
    staffings?.forEach((staffing) => {
      onDeselectRow?.(staffing);
    });
  };

  // Function to count staffings by status and sort them alphabetically
  const countStaffingsByStatus = (staffingList: StaffingListItem[]) => {
    // Create an object to store the counts
    const statusCounts:
      | Record<StaffingStatus, number>
      | Record<string, number> = {};

    // Count staffings by status
    staffingList?.forEach((staffing) => {
      const { status } = staffing;
      if (statusCounts[status]) {
        statusCounts[status]++;
      } else {
        statusCounts[status] = 1;
      }
    });

    // Sort the status counts alphabetically
    const sortedStatusCounts = Object.entries(statusCounts).sort((a, b) =>
      a[0].localeCompare(b[0]),
    );

    return sortedStatusCounts;
  };

  return (
    <>
      <div
        className={`${containerBgClass} border-support-line-darker flex cursor-pointer items-center border-b px-4 py-3`}
        onClick={handleToggle}
      >
        <h5 className="text-preset-7 text-ink-dark flex-1 font-medium ">
          {title} ({count})
          {maxCount && count < maxCount ? ` - ${maxCount - count} missing` : ''}
          <span>&nbsp;&nbsp;</span>
          {staffings &&
            countStaffingsByStatus(staffings).map(([status, statusCount]) => (
              <span key={status}>
                |&nbsp;&nbsp;{status}: {statusCount}
              </span>
            ))}
          {loading ? (
            <span className="text-ink-not-as-dark"> Loading ...</span>
          ) : null}
        </h5>

        {actionComponent}

        <div className="border-support-line-darker bg-support-line text-ink-not-as-dark text-preset-7 ml-4 rounded border p-1 text-center">
          {isExpanded ? (
            loading ? (
              <div className="h-3 w-3">...</div>
            ) : (
              <ChevronUp className="h-3 w-3" />
            )
          ) : (
            <ChevronDown className="h-3 w-3" />
          )}
        </div>
      </div>

      {isExpanded && staffings ? (
        <>
          <table className="border-support-line min-w-full border-b">
            <thead className="bg-background-app">
              <tr>
                <Can I="update" a="Staffing">
                  {(onSelectRow || onDeselectRow) && Boolean(staffings.length) && (
                    <TableHeaderCell>
                      <input
                        type="checkbox"
                        title="select all staffings"
                        className="text-brand-50 border-ink-dark block h-6 w-6 rounded border-2 focus:ring-0"
                        onChange={(evt) => {
                          evt.target.checked
                            ? handleSelectAll()
                            : handleDeselectAll();
                        }}
                        checked={allRowsSelected?.[currentPage] || false}
                      />
                    </TableHeaderCell>
                  )}
                </Can>

                {columns.map((column) => column)}

                <Can I="update" a="Staffing">
                  <TableHeaderCell key="action">action</TableHeaderCell>
                </Can>
              </tr>
            </thead>

            <tbody className="divide-y divide-gray-200">
              {staffingRows &&
                staffingRows.map((row) => (
                  <tr
                    key={row.staffing.id}
                    className={`${
                      loading ? 'opacity-50' : 'opacity-100'
                    } bg-background-surface transition duration-100`}
                  >
                    <Can I="update" a="Staffing">
                      {(onSelectRow || onDeselectRow) && (
                        <DataCell>
                          <input
                            type="checkbox"
                            title={`select ${row.staffing.email}`}
                            className="text-brand-50 border-ink-dark block h-6 w-6 rounded border-2 focus:ring-0"
                            onChange={(evt) => {
                              allRowsSelected?.[currentPage] &&
                                setAllRowsSelected({
                                  ...allRowsSelected,
                                  [currentPage]: false,
                                });
                              evt.target.checked
                                ? onSelectRow?.(row.staffing)
                                : onDeselectRow?.(row.staffing);
                            }}
                            checked={
                              selectedStaffings?.state
                                .get(shiftId)
                                ?.has(row.staffing.id) ?? false
                            }
                            data-cy="bulk-checkbox"
                          />
                        </DataCell>
                      )}
                    </Can>

                    {Object.entries(row.tableData).map(
                      ([columnName, columnCell]) => (
                        <Fragment key={columnName}>
                          {columnCell.renderComponent}
                        </Fragment>
                      ),
                    )}

                    <Can I="update" a="Staffing">
                      <ActionsCell
                        tenderName={row.staffing.firstName}
                        status={row.staffing.status}
                        staffingId={row.staffing.id}
                        shiftId={shiftId}
                        onAction={() => onAction?.(row.staffing)}
                      />
                    </Can>
                  </tr>
                ))}

              {!staffings.length && !loading && (
                <tr>
                  <DataCell
                    className="text-preset-6 text-ink-not-as-dark font-normal"
                    colSpan={3}
                  >
                    {emptyStateLabel}
                  </DataCell>
                </tr>
              )}
            </tbody>
          </table>

          {paginationMeta && paginationMeta.totalPages > 1 && (
            <div className="mt-0.5 flex h-12 flex-col items-end pr-4">
              <Pagination
                currentPage={paginationMeta?.currentPage}
                pageCount={paginationMeta?.totalPages || 0}
                onPageChange={(page) =>
                  onPageChange?.(page, sortedColumn, sortDirection)
                }
              />
            </div>
          )}
        </>
      ) : null}
    </>
  );
};

export default StaffingList;
