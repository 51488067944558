import React from 'react';
import Search from 'src/components/Icons/Search';
import { Reset } from 'src/components/Combobox';

const ClearableInput: React.FC<{
  onClear: () => void;
  className?: string;
}> = ({ children, onClear, className }) => {
  return (
    <div className={`relative ${className}`}>
      <div
        className="pointer-events-none absolute top-1/2 left-0 -translate-y-1.5 pl-3"
        data-testid="clearable-input-icon"
      >
        <Search className="text-ink-not-as-dark h-3 w-3" aria-hidden="true" />
      </div>
      {children}
      <div className="absolute top-1/2 right-0 -translate-y-2.5 pr-3">
        <Reset onClick={onClear} data-testid="clearable-input-clear" />
      </div>
    </div>
  );
};

export default ClearableInput;
