import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  UpdateStaffingReliabilityPrediction,
  UpdateStaffingReliabilityPredictionVariables,
} from './__generated__/UpdateStaffingReliabilityPrediction';

const UPDATE_STAFFING_RELIABILITY_PREDICTION = gql`
  mutation UpdateStaffingReliabilityPrediction($staffingIds: [ID!]!) {
    updateStaffingReliabilityPrediction(staffingIds: $staffingIds) {
      id
      reliabilityPrediction
      reliabilityPredictionTimestamp
    }
  }
`;

export const useUpdateStaffingReliabilityPredictionMutation = (
  options: MutationHookOptions<
    UpdateStaffingReliabilityPrediction,
    UpdateStaffingReliabilityPredictionVariables
  >,
) => {
  return useMutation<
    UpdateStaffingReliabilityPrediction,
    UpdateStaffingReliabilityPredictionVariables
  >(UPDATE_STAFFING_RELIABILITY_PREDICTION, options);
};

export default UPDATE_STAFFING_RELIABILITY_PREDICTION;
