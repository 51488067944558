import React from 'react';

const EyeOff: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M2.50028 2.5L5.49125 5.49097M17.5003 17.5L14.5096 14.5093M11.5627 15.6872C11.0567 15.7831 10.5346 15.8333 10.0007 15.8333C6.26929 15.8333 3.11071 13.3809 2.04883 9.99997C2.33791 9.07958 2.78237 8.22801 3.35148 7.47598M8.23252 8.23223C8.68493 7.77982 9.30993 7.5 10.0003 7.5C11.381 7.5 12.5003 8.61929 12.5003 10C12.5003 10.6904 12.2205 11.3154 11.7681 11.7678M8.23252 8.23223L11.7681 11.7678M8.23252 8.23223L5.49125 5.49097M11.7681 11.7678L5.49125 5.49097M11.7681 11.7678L14.5096 14.5093M5.49125 5.49097C6.7911 4.65295 8.33909 4.16667 10.0006 4.16667C13.732 4.16667 16.8906 6.61909 17.9525 10C17.3634 11.8756 16.129 13.4654 14.5096 14.5093"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default EyeOff;
