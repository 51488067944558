import { format, utcToZonedTime } from 'date-fns-tz';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'src/components/Modal';
import { useMarkShiftNotesAsRead } from 'src/graphql/mutations/MarkShiftNotesAsRead';
import { ShiftNoteType } from 'src/__generated__/globalTypes';
import ClearableInput from '../../components/ClearableInput';
import SpacedDot from '../../components/SpacedDot';
import { useCreateShiftNote } from '../../graphql/mutations/CreateShiftNote';
import {
  GetShifts_shifts_edges_node,
  GetShifts_shifts_edges_node_notes,
} from '../../graphql/queries/__generated__/GetShifts';

const DATE_FORMAT = 'MMM d hh:mmaaa zzz';
const NOTES_DATE_FORMAT = 'MMM d, yyy - hh:mmaaa';

const Note: React.FC<{
  note: GetShifts_shifts_edges_node_notes;
}> = ({ note }) => {
  const isNoteFromFlexpool = note.type === ShiftNoteType.FLEXPOOL;
  const isNoteFromLocationContact =
    note.type === ShiftNoteType.LOCATION_CONTACT;
  const isHighlighted = isNoteFromFlexpool || isNoteFromLocationContact;

  return (
    <div
      key={note.id}
      className={`text-preset-7 ${
        isHighlighted ? 'bg-primary-light' : 'bg-background-app'
      } relative rounded p-3`}
    >
      {isHighlighted && (
        <span className="bg-primary-active absolute top-0 left-0 h-full w-[3px] rounded-bl rounded-tl" />
      )}

      <div className="text-ink-dark text-preset-7P">
        <p className="whitespace-pre-wrap">{note.note}</p>
      </div>

      <div className="text-ink-not-as-dark mt-2 text-justify">
        <p>
          {isNoteFromFlexpool && <span>Flexpool Order Note - </span>}
          {isNoteFromLocationContact && <span>Location Contact - </span>}
          {note.creator?.email && <span>{note.creator?.email} - </span>}
          {format(new Date(note.updatedAt), NOTES_DATE_FORMAT)}
        </p>
      </div>
    </div>
  );
};

export const NotesModal: React.FC<{
  open: boolean;
  onClose: () => void;
  shift: GetShifts_shifts_edges_node;
  organizationName: string;
  unpaidBreakMinutes?: number;
}> = ({ open, onClose, shift, organizationName, unpaidBreakMinutes }) => {
  const venue = shift.job.venue;
  const positionName = shift.position.name;
  const {
    id: shiftId,
    startDateTime: startDate,
    endDateTime: endDate,
    expectedWorkedHours: totalShiftHours,
    notes,
  } = shift;
  const inputRef = useRef<HTMLInputElement>(null);
  const { name: regionName } = venue?.region ?? {};
  const { timezone, city, state } = venue?.address ?? {};
  const startDateUTC = new Date(startDate);
  const zonedStartDate = timezone
    ? utcToZonedTime(startDateUTC, timezone)
    : startDateUTC;
  const formattedStartDate = format(zonedStartDate, DATE_FORMAT, {
    timeZone: timezone,
  });
  const endDateUTC = new Date(endDate);
  const zonedEndDate = timezone
    ? utcToZonedTime(endDateUTC, timezone)
    : endDateUTC;
  const formattedEndDate = format(zonedEndDate, DATE_FORMAT, {
    timeZone: timezone,
  });
  const totalHours = Math.floor(totalShiftHours);
  const remainingMinutes = Math.round((totalShiftHours * 60) % 60);
  const hasUnreadNotes = shift.unreadNotes > 0;

  const [noteFilter, setNoteFilter] = useState<string | undefined>();

  const [createShiftNote, { loading }] = useCreateShiftNote();

  const [markShiftNotesAsRead] = useMarkShiftNotesAsRead();

  const filteredNotes: GetShifts_shifts_edges_node_notes[] | null | undefined =
    noteFilter
      ? notes?.filter((note) =>
          note.note.toLowerCase().includes(noteFilter.toLowerCase()),
        )
      : notes;

  const sortedNotes = filteredNotes?.length
    ? [...filteredNotes].sort(
        (a, b) =>
          new Date(a.updatedAt as string).getTime() -
          new Date(b.updatedAt as string).getTime(),
      )
    : filteredNotes;

  const [error, setError] = useState<string | undefined>();

  const { getValues, setValue, formState, handleSubmit, register } = useForm<{
    note?: string;
  }>({
    defaultValues: {
      note: '',
    },
    mode: 'onSubmit',
  });

  const onSubmitNote = async () => {
    const { note } = getValues();

    if (note) {
      const saveResponse = await createShiftNote({
        variables: { input: { note, shiftId } },
      });

      if (saveResponse.errors && saveResponse.errors.length) {
        setError(saveResponse.errors[0].message);
        return;
      }

      setValue('note', '');
    }
  };

  const handleClearFilter = useCallback((): void => {
    setNoteFilter('');
  }, [setNoteFilter]);

  const handleFilterChange = useCallback(
    (event?): void => {
      setNoteFilter(event.currentTarget.value);
    },
    [setNoteFilter],
  );

  const handleCancelModal = useCallback(
    (event?): void => {
      event.preventDefault();
      onClose();
    },
    [onClose],
  );

  useEffect(() => {
    // mark notes as read when the modal is open
    if (open && hasUnreadNotes) {
      markShiftNotesAsRead({
        variables: { shiftId },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, hasUnreadNotes]);

  return (
    <Modal
      className="w-11/12 max-w-3xl"
      data-testid="shift-notes-modal"
      initialFocusRef={inputRef}
      onClose={onClose}
      open={open}
    >
      <div className="h-160 overflow-y-scroll px-6 py-4">
        <div className="flex justify-between">
          <div className="text-preset-4 text-ink-dark font-large mb-2">
            Note Log for {positionName}
            {organizationName && (
              <div className="text-ink-not-as-dark ml-2">
                <SpacedDot />
                <span className="text-ink-dark capitalize">
                  {organizationName}
                </span>
                <SpacedDot />
              </div>
            )}
          </div>
        </div>

        <div className="text-preset-6 text-ink-not-as-dark mr-4 flex flex-1 font-normal tracking-wide">
          {city}, {state}, {regionName || 'No Region'}
          <SpacedDot />
          {formattedStartDate} - {formattedEndDate}
          <SpacedDot />
          <span title="Total hours minus the unpaid break time">
            {totalHours}h {remainingMinutes}m
          </span>
          {unpaidBreakMinutes ? (
            <>
              <SpacedDot />
              {unpaidBreakMinutes}m Unpaid break
            </>
          ) : null}
        </div>

        <div className="mt-8 w-96 bg-yellow-50">
          <ClearableInput
            className="border-support-line-darker rounded"
            onClear={handleClearFilter}
          >
            <input
              className="focus:ring-primary focus:border-primary sm:text-preset-6 border-support-line-darker block w-full rounded-md px-8 py-3"
              onChange={handleFilterChange}
              placeholder="Search on notes"
              type="text"
            />
          </ClearableInput>
        </div>

        <div className="my-4 flex flex-col gap-4">
          {sortedNotes?.map((note) => (
            <Note note={note} key={note.id} />
          ))}
        </div>

        <hr />

        <div className="mt-4">
          <label
            htmlFor="saveNote"
            className="text-preset-5 text-ink-dark mb-4 block font-medium"
          >
            Leave your Note
          </label>

          <textarea
            name="note"
            className="bg-background-app focus:ring-primary focus:border-primary text-preset-6 block h-24 w-full rounded px-4 py-6"
            placeholder="Enter your note here"
            ref={register()}
          />
        </div>
      </div>

      <div className="text-ink-dark border-ink-dark-200 flex justify-end border-t border-solid px-6 py-4">
        <button
          className="border-ink-dark rounded-md border border-solid py-2 px-4 "
          onClick={handleCancelModal}
        >
          Cancel
        </button>

        <button
          disabled={loading || !formState.isDirty}
          className="bg-primary text-ink-clear ml-4 rounded-md py-2 px-4"
          onClick={handleSubmit(onSubmitNote)}
        >
          {loading ? 'Saving' : 'Save'}
        </button>

        {error && (
          <div className="justify-self-start px-6">
            <p className="text-preset-6P text-status-destructive">
              There was an error saving the shift note. {error}
              <br />
              Check the data and try again.
            </p>
          </div>
        )}
      </div>
    </Modal>
  );
};
