import React from 'react';
import logoWhite from 'src/images/logo-tend-white.svg';

type Props = {
  label: string;
};

const AuthScreenShell: React.FC<Props> = ({ label, children }) => (
  <div className="flex min-h-screen bg-white">
    <div className="bg-brand-50 relative hidden w-0 flex-1 lg:block">
      <img className="absolute top-16 left-12" src={logoWhite} alt="" />
      <div className="absolute bottom-16 left-12 text-2xl text-white">
        {label}
      </div>
    </div>
    <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-64">
      <div className="mx-auto w-full max-w-sm lg:w-96">{children}</div>
    </div>
  </div>
);

export default AuthScreenShell;
