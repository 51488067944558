import React, { FC, useCallback, useEffect, useState } from 'react';
import Combobox from 'src/components/Combobox';
import ChevronDown from 'src/components/Icons/ChevronDown';
import Loading from 'src/components/Loading';
import Pill from 'src/components/Pill';
import { useDefaultPositionQuery } from 'src/graphql/queries/GetDefaultPositions';
import { DefaultPositions_defaultPositions as DefaultPosition } from 'src/graphql/queries/__generated__/DefaultPositions';

const DefaultPositionComponent: FC<{
  item: DefaultPosition;
  index: number;
  highlighted: boolean;
}> = ({ item, highlighted }) => {
  return (
    <div
      className={`py-2 px-4 ${highlighted ? 'bg-background-app' : ''}`}
      data-cy={`def-positions-result-${item.name.toLowerCase()}`}
    >
      <p className="text-preset-6 text-ink-dark font-medium">{item.name}</p>
    </div>
  );
};

type Props = {
  selectedPositionsIds: string[];
  onChange?: (selectedPositionsIds: string[]) => void;
  onAddPosition?: (addedPositionId: string) => void;
  className?: string;
  title?: string;
  defaultValue?: string;
  onDelete?: (position: DefaultPosition) => void;
};

const DefaultPositionsInput: FC<Props> = ({
  selectedPositionsIds,
  onChange,
  className,
  onDelete,
  onAddPosition,
  title = 'Default Positions',
  defaultValue = 'Select your desired positions',
}) => {
  const [getDefaultPositions, { data, loading }] = useDefaultPositionQuery();
  const [selectedPositions, setSelectedPositions] = useState<DefaultPosition[]>(
    [],
  );
  const getSelectedPositions = useCallback(async () => {
    await getDefaultPositions();
    // only do this for the first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getSelectedPositions();
  }, [getSelectedPositions]);
  useEffect(() => {
    if (!data || !selectedPositionsIds) {
      return;
    }
    const { defaultPositions } = data;
    let tempPositions = selectedPositionsIds.map((positionId) => {
      return defaultPositions.find((position) => position.id === positionId);
    });
    tempPositions = tempPositions.filter((position) => !!position);
    setSelectedPositions(tempPositions as DefaultPosition[]);
  }, [data, selectedPositionsIds]);
  const addPosition = (defaultPosition: DefaultPosition) => {
    if (
      selectedPositions.some((selected) => selected.id === defaultPosition.id)
    ) {
      return;
    }
    setSelectedPositions([...selectedPositions, defaultPosition]);
    onChange && onChange([...selectedPositionsIds, defaultPosition.id]);
    onAddPosition && onAddPosition(defaultPosition.id);
  };
  const removePosition = (defaultPosition: DefaultPosition) => {
    onDelete && onDelete(defaultPosition);
    const removedIndex = selectedPositionsIds.indexOf(defaultPosition.id);
    selectedPositionsIds.splice(removedIndex, 1);
    selectedPositions.splice(removedIndex, 1);
    setSelectedPositions([...selectedPositions]);
    onChange && onChange([...selectedPositionsIds]);
  };
  return (
    <div className={className}>
      <Combobox
        id="defaultPositionsCombobox"
        inputValue={defaultValue}
        inputClassName="focus:ring-primary focus:border-primary sm:text-preset-6 border-support-line-darker rounded cursor-pointer"
        inputReadOnly
        items={data?.defaultPositions || []}
        label={title}
        loadingItems={loading}
        onChange={(selectedItem) => selectedItem && addPosition(selectedItem)}
        onInputChange={() => null}
        onReset={() => null}
        openMenuOnFocus
        renderItemComponent={DefaultPositionComponent}
        renderLoadingComponent={() => (
          <div className="flex justify-center">
            <Loading />
          </div>
        )}
        renderNoResultsComponent={() => (
          <p className="py-2 px-4">No positions found</p>
        )}
        renderTrailingComponent={() => (
          <ChevronDown className="text-ink-not-as-dark h-3 w-3" />
        )}
        data-cy="def-positions-filter"
      />
      <div className="selectedPositions flex flex-wrap">
        {selectedPositions.map((position) => {
          return (
            <Pill
              key={position.id}
              onClose={() => removePosition(position)}
              className="mr-2 mb-2"
              toolTipText={position.id}
              data-cy={`pill-${position.name.toLowerCase()}`}
            >
              {position.name}
            </Pill>
          );
        })}
      </div>
    </div>
  );
};

export default DefaultPositionsInput;
