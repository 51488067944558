import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  CancelInvoice,
  CancelInvoiceVariables,
} from './__generated__/CancelInvoice';

const CANCEL_INVOICE_MUTATION = gql`
  mutation CancelInvoice($id: ID!) {
    cancelInvoice(id: $id) {
      id
      status
    }
  }
`;

export const useCancelInvoiceMutation = (
  options?: MutationHookOptions<CancelInvoice, CancelInvoiceVariables>,
) =>
  useMutation<CancelInvoice, CancelInvoiceVariables>(
    CANCEL_INVOICE_MUTATION,
    options,
  );

export default CANCEL_INVOICE_MUTATION;
