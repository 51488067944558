import { gql, QueryHookOptions, useLazyQuery } from '@apollo/client';
import { DefaultPositions } from './__generated__/DefaultPositions';

export const DEFAULT_POSITIONS_QUERY = gql`
  query DefaultPositions {
    defaultPositions {
      id
      name
      description
      rate
      margin
      tipTypes
    }
  }
`;

export const useDefaultPositionQuery = (
  options?: QueryHookOptions<DefaultPositions>,
) => useLazyQuery<DefaultPositions>(DEFAULT_POSITIONS_QUERY, options);

export default DEFAULT_POSITIONS_QUERY;
