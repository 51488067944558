import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import {
  GetPositionRatesForAdmin,
  GetPositionRatesForAdminVariables,
} from './__generated__/GetPositionRatesForAdmin';

const GET_POSITION_RATES = gql`
  query GetPositionRatesForAdmin($input: JobPricingInput!) {
    getPositionRatesForAdmin(input: $input) {
      id
      name
      rate
      margin
      tipTypes
      pricingSource
    }
  }
`;

export const useGetPositionRatesForAdminQuery = (
  options?: QueryHookOptions<
    GetPositionRatesForAdmin,
    GetPositionRatesForAdminVariables
  >,
) =>
  useQuery<GetPositionRatesForAdmin, GetPositionRatesForAdminVariables>(
    GET_POSITION_RATES,
    options,
  );

export default GET_POSITION_RATES;
