import React from 'react';
import { ClientTenderStatus } from 'src/__generated__/globalTypes';
import Heart from 'src/components/Icons/Heart';
import ThumbDown from 'src/components/Icons/ThumbDown';
import Ban from 'src/components/Icons/Ban';
import DataCell from './DataCell';

const preferenceConfig = {
  [ClientTenderStatus.FAVORITE]: {
    backgroundColor: 'bg-primary',
    icon: ({ className = '' }) => (
      <Heart className={`text-white ${className}`} />
    ),
  },
  [ClientTenderStatus.BLOCKED]: {
    backgroundColor: 'bg-status-destructive',
    icon: ({ className = '' }) => <Ban className={`text-white ${className}`} />,
  },
  [ClientTenderStatus.LESS_PREFERRED]: {
    backgroundColor: 'bg-ink-dark',
    icon: ({ className = '' }) => (
      <ThumbDown className={`text-white ${className}`} />
    ),
  },
};

const ClientPreferenceCell: React.FC<{
  preference?: ClientTenderStatus | null;
}> = ({ preference, ...props }) => {
  const Icon = preference ? preferenceConfig[preference].icon : null;

  return (
    <DataCell data-testid="client-preference-cell" {...props}>
      <div className="flex items-center">
        {preference && Icon ? (
          <div
            className={`rounded-full p-2 ${preferenceConfig[preference].backgroundColor} `}
          >
            <Icon className="h-4 w-4" />
          </div>
        ) : (
          '-'
        )}
      </div>
    </DataCell>
  );
};

export default ClientPreferenceCell;
