import * as React from 'react';
import { SVGProps } from 'react';

const PreferenceMatchPartial = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    fillRule="evenodd"
    clipRule="evenodd"
    viewBox="0 0 29 28"
    width="1em"
    height="1em"
    {...props}
  >
    <circle
      cx={14.5}
      cy={14}
      r={13.4}
      fill="none"
      stroke="#045445"
      strokeWidth={1.2}
    />
    <path
      fill="#045445"
      d="M14.5.6v26.8C7.104 27.4 1.1 21.396 1.1 14 1.1 6.604 7.104.6 14.5.6Z"
    />
  </svg>
);

export default PreferenceMatchPartial;
