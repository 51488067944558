import {
  Circle,
  GoogleMap,
  Marker,
  useJsApiLoader,
} from '@react-google-maps/api';
import { addMinutes, differenceInDays, differenceInMinutes } from 'date-fns';
import { format } from 'date-fns-tz';
import React, { ChangeEvent, useEffect, useState } from 'react';

import {
  ShiftWorkerClassification,
  StaffingStatus,
} from 'src/__generated__/globalTypes';
import Ban from 'src/components/Icons/Ban';
import Check from 'src/components/Icons/Check';
import Stopwatch from 'src/components/Icons/Stopwatch';
import Tracking from 'src/components/Icons/Tracking';
import Loading from 'src/components/Loading';
import { googleMapStyles } from 'src/components/LocationInput/mapStyles';
import Modal from 'src/components/Modal';
import Pagination from 'src/components/Pagination';
import Pill from 'src/components/Pill';
import Tab from 'src/components/Tab';
import TableHeaderCell from 'src/components/TableHeaderCell';
import { useLocationTrackingPointsQuery } from 'src/graphql/queries/GetLocationTrackingPoints';
import { usePayableStaffingQuery } from 'src/graphql/queries/GetPayableStaffing';
import { useGetTenderStaffingsForAdminQuery } from 'src/graphql/queries/GetTenderStaffings';
import { GetTenderStaffingsForAdmin_staffingsPaginatedByOffset_items as Staffing } from 'src/graphql/queries/__generated__/GetTenderStaffingsForAdmin';
import { LocationTrackingPoints_locationTrackingPoints as LocationTrackingPoint } from 'src/graphql/queries/__generated__/LocationTrackingPoints';
import AngryFace from 'src/images/AngryFace.svg';
import GrayMarkerIcon from 'src/images/GrayMarker.svg';
import GreenMarkerIcon from 'src/images/GreenMarker.svg';
import HappyFace from 'src/images/HappyFace.svg';
import { GOOGLE_MAPS_API_LIBRARIES } from 'src/utils/constants';
import { getRoundedImage } from 'src/utils/images';
import PaymentStatusPopover from 'src/views/PaymentStatusPopover/PaymentStatusPopover';

type TabType = 'staffed' | 'denied';

const VerticalLineWithText: React.FC<{ text: string; left: number }> = ({
  text,
  left,
}) => {
  return (
    <div
      className="text-ink-not-as-dark absolute bottom-11 text-left"
      style={{
        left: `${left}px`,
      }}
    >
      <div
        className="bg-primary absolute -top-2 -left-0.5 w-0.5"
        style={hardCodedHeight}
      />
      <p className="text-preset-7">{text}</p>
    </div>
  );
};

const ChronometerWithTime: React.FC<{
  left: number;
  time: string;
  color?: string;
  iconLast?: boolean;
}> = ({ left, time, color = 'text-ink-dark', iconLast = false }) => {
  return (
    <div
      className="absolute bottom-2 flex text-left"
      style={{
        left: `${left}px`,
      }}
    >
      {!iconLast && <Stopwatch className={`h-4 w-4 ${color}`} />}
      <p className="text-preset-7 text-ink-dark ml-1">{time}</p>
      {iconLast && <Stopwatch className={`h-4 w-4 ${color}`} />}
    </div>
  );
};

const Timeline: React.FC<{
  points: LocationTrackingPoint[];
  setIndex: (index: number) => void;
  startDate: Date;
  endDate: Date;
  tenderClockInTime?: Date;
  tenderClockOutTime?: Date;
}> = ({
  points,
  setIndex,
  startDate,
  endDate,
  tenderClockInTime,
  tenderClockOutTime,
}) => {
  const [localIndex, setLocalIndex] = useState(0);
  const firstDate = new Date(
    Math.min(
      new Date(points[0].timestamp).getTime(),
      new Date(startDate).getTime(),
    ),
  );
  const lastDate = new Date(
    Math.max(
      new Date(points[points.length - 1].timestamp).getTime(),
      new Date(endDate).getTime(),
      tenderClockOutTime ? new Date(tenderClockOutTime).getTime() : 0,
    ),
  );
  const timelineMax = differenceInMinutes(lastDate, firstDate) + 1;
  const startTimeIndex = differenceInMinutes(new Date(startDate), firstDate);
  const endTimeIndex = differenceInMinutes(new Date(endDate), firstDate);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value, 10);
    setLocalIndex(newValue);
    const newSelectedDateTime = new Date(addMinutes(firstDate, newValue));
    const newIndex = points.findIndex((point) => {
      return new Date(point.timestamp) >= newSelectedDateTime;
    });
    if (newIndex > -1) {
      if (newIndex > 0) {
        setIndex(newIndex - 1);
      } else {
        setIndex(0);
      }
    } else {
      setIndex(points.length - 1);
    }
  };

  return (
    <div
      className="h-18 bg-background-app mx-auto mt-5 px-2 pt-8"
      style={hardCodedWidth}
    >
      <div className="relative">
        <input
          className="bg-brand-50 slider slider-thumb relative z-10 mb-6 h-1.5 w-full rounded-full"
          type="range"
          min={'0'}
          max={timelineMax.toString()}
          value={localIndex}
          onChange={handleChange}
        />
        <VerticalLineWithText
          text={format(new Date(startDate), 'hh:mmaaa')}
          left={getLeftPosition(startTimeIndex, timelineMax)}
        />
        <VerticalLineWithText
          text={format(new Date(endDate), 'hh:mmaaa')}
          left={getLeftPosition(endTimeIndex, timelineMax)}
        />
        <div
          id="shiftDurationGreenBg"
          className="bg-primary absolute bottom-0 bg-opacity-20"
          style={{
            ...hardCodedHeight,
            width: `${getLeftPosition(
              endTimeIndex - startTimeIndex,
              timelineMax,
            )}px`,
            left: `${getLeftPosition(startTimeIndex, timelineMax)}px`,
          }}
        />
        <div
          id="timeSelected"
          className="absolute bottom-16 mb-0.5 h-4"
          style={{
            left: `${getLeftPosition(localIndex || 0, timelineMax) - 15}px`,
          }}
        >
          <p className="bg-ink-dark text-ink-clear text-preset-7 mt-4 rounded-full p-0.5">
            {format(addMinutes(firstDate, localIndex), 'hh:mmaaa')}
          </p>
        </div>
        {tenderClockInTime && (
          <ChronometerWithTime
            left={getLeftPosition(
              differenceInMinutes(new Date(tenderClockInTime), firstDate),
              timelineMax,
            )}
            time={format(new Date(tenderClockInTime), 'hh:mmaaa')}
          />
        )}
        {tenderClockOutTime && (
          <ChronometerWithTime
            left={
              getLeftPosition(
                differenceInMinutes(new Date(tenderClockOutTime), firstDate),
                timelineMax,
              ) - 60
            }
            time={format(new Date(tenderClockOutTime), 'hh:mmaaa')}
            color="text-status-destructive"
            iconLast
          />
        )}
      </div>
    </div>
  );
};

const Cell: React.FC<{ className?: string }> = ({ children, className }) => (
  <td
    className={`text-preset-6 text-ink-dark bg-background-surface px-2 ${
      className || ''
    }`}
  >
    {children}
  </td>
);

const defaultContainerStyle = {
  width: '824px',
  height: '500px',
};

const modalStyles = {
  width: '824px',
  height: '750px',
};
const hardCodedWidth = { width: '700px' };
const hardCodedHeight = { height: '68px' };
const widthFactor = 665;

const getLeftPosition = (index: number, totalLength: number) => {
  return (index * widthFactor) / totalLength;
};

const StaffingComment: React.FC<{
  tenderComment?: string | null;
  tenderShiftRating?: number | null;
}> = ({ tenderComment, tenderShiftRating }) => {
  const hasClockOutComment = tenderShiftRating !== null || !!tenderComment;
  if (!hasClockOutComment) {
    return null;
  }
  return (
    <>
      <p className="text-preset-6 text-ink-dark py-5 font-medium">
        Tender Comment
      </p>
      <div className="flex">
        {tenderShiftRating === 0 && (
          <img className="self-start pr-4" src={AngryFace} alt="angry face" />
        )}
        {tenderShiftRating === 1 && (
          <img className="self-start pr-4" src={HappyFace} alt="angry face" />
        )}
        {tenderComment && (
          <p className="text-preset-6P text-ink-not-as-dark">{tenderComment}</p>
        )}
      </div>
    </>
  );
};

const LocationTrackingPoints: React.FC<{
  tenderInitials: string;
  staffingId: string;
  startDate: Date;
  endDate: Date;
  venueMarker: {
    lat: number;
    lng: number;
  };
  avatarUrl: string | null;
}> = ({
  staffingId,
  startDate,
  endDate,
  venueMarker,
  avatarUrl,
  tenderInitials,
}) => {
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [canvas, setCanvas] = useState('');
  const [image, setImage] = useState<HTMLImageElement>();
  const [first, setFirst] = useState<number>();
  const [last, setLast] = useState<number>();

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
    libraries: GOOGLE_MAPS_API_LIBRARIES,
  });

  useEffect(() => {
    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.onload = () => {
      setCanvas(getRoundedImage(img));
    };
    img.src = (avatarUrl || '').replace(/^https:\/\//i, 'http://');
    // the replace is needed because neither Chrome nor Safari will
    // be able to resolve the https image. You can find more info here
    // https://zyst.io/how-to-fix-aws-s3-chrome-and-safari-cors-on-images;
    if (!avatarUrl) {
      setCanvas(getRoundedImage(null, tenderInitials));
    }
    setImage(img);
  }, [avatarUrl, tenderInitials]);
  const { data, loading } = useLocationTrackingPointsQuery({
    variables: { staffingId },
    onCompleted: (completedData) => {
      const newFirst = completedData.locationTrackingPoints.findIndex(
        (point) => {
          return new Date(point.timestamp) >= new Date(startDate);
        },
      );
      let newLast = completedData.locationTrackingPoints.findIndex((point) => {
        return new Date(point.timestamp) >= new Date(endDate);
      });
      if (newLast === -1) {
        newLast = completedData.locationTrackingPoints.length;
      }
      setFirst(newFirst);
      setLast(newLast);
    },
  });
  const { data: staffingCommentData } = usePayableStaffingQuery({
    variables: {
      staffingId,
    },
  });
  if (loading || !endDate || !startDate || !data) {
    return <Loading />;
  }

  const locationPoints = data.locationTrackingPoints;
  if (!locationPoints) {
    return null;
  }
  const avatar: google.maps.Icon = {
    url: canvas || '',
    size: new google.maps.Size(
      image?.naturalWidth || 300,
      image?.naturalHeight || 300,
    ),
    scaledSize: new google.maps.Size(40, 40),
    origin: new google.maps.Point(0, 0),
    anchor: new google.maps.Point(20, 30),
    labelOrigin: new google.maps.Point(20, 40),
  };
  if (
    !endDate ||
    !startDate ||
    !data?.locationTrackingPoints.length ||
    first === undefined ||
    last === undefined
  ) {
    return (
      <p className="text-preset-5 text-ink-dark ml-6">
        There is no location data for this staffing
      </p>
    );
  }
  const clockInPointIndex = locationPoints.findIndex(
    (point) => point.isClockIn,
  );
  let clockOutPointIndex = locationPoints.findIndex(
    (point) =>
      new Date(point.timestamp) >
      new Date(staffingCommentData?.payableStaffing.tenderClockOutTime),
  );
  if (clockOutPointIndex === -1 || clockOutPointIndex === 0) {
    clockOutPointIndex = locationPoints.length - 1;
  }
  const startDateTime = new Date(startDate);
  const endDateTime = new Date(endDate);
  return (
    <>
      <div className="-ml-6">
        {isLoaded && (
          <GoogleMap
            clickableIcons={false}
            options={{
              streetViewControl: false,
              fullscreenControl: false,
              mapTypeControlOptions: {
                mapTypeIds: [],
              },
              styles: googleMapStyles,
              scrollwheel: false,
            }}
            center={{
              lat:
                selectedIndex !== undefined
                  ? locationPoints[selectedIndex].lat
                  : venueMarker.lat,
              lng:
                selectedIndex !== undefined
                  ? locationPoints[selectedIndex].lng
                  : venueMarker.lng,
            }}
            mapContainerStyle={defaultContainerStyle}
            zoom={15}
          >
            {locationPoints.map((point, index) => {
              const highlighted = selectedIndex === index;
              let icon = GreenMarkerIcon;
              if (
                new Date(point.timestamp) < startDateTime ||
                new Date(point.timestamp) > endDateTime
              ) {
                icon = GrayMarkerIcon;
              }
              return (
                <div
                  key={`${index}-${point.timestamp}`}
                  className={`${highlighted && 'scale-200 transform'}`}
                >
                  <Marker
                    zIndex={highlighted ? locationPoints.length : index}
                    icon={highlighted ? avatar : icon}
                    opacity={highlighted ? 1 : 0.8}
                    label={
                      highlighted
                        ? {
                            text: format(new Date(point.timestamp), 'hh:mmaaa'),
                            className:
                              'bg-ink-dark text-ink-clear rounded-full py-0.5 px-1 text-preset-7 mt-4',
                            color: '#fff',
                            fontSize: '10px',
                            fontFamily: 'Helvetica, Arial, Sans-Serif',
                          }
                        : undefined
                    }
                    position={{ lat: point.lat, lng: point.lng }}
                  />
                </div>
              );
            })}
            <Circle
              options={{
                strokeColor: '#FF9C00',
                strokeOpacity: 0,
                fillColor: '#FF9C00',
                fillOpacity: 0.4,
                zIndex: locationPoints.length,
              }}
              center={venueMarker}
              radius={100}
            />
          </GoogleMap>
        )}
      </div>
      <Timeline
        points={locationPoints}
        setIndex={setSelectedIndex}
        startDate={startDate}
        endDate={endDate}
        tenderClockInTime={locationPoints[clockInPointIndex]?.timestamp}
        tenderClockOutTime={locationPoints[clockOutPointIndex].timestamp}
      />
      <p className="text-ink-not-as-dark text-preset-7 mt-1 text-right">
        *There might be some lapses of time without location information.
      </p>
      <StaffingComment
        tenderComment={staffingCommentData?.payableStaffing.tenderComment}
        tenderShiftRating={
          staffingCommentData?.payableStaffing.tenderShiftRating
        }
      />
    </>
  );
};

const getStaffingStatusPillColor = (status: StaffingStatus) => {
  switch (status) {
    case StaffingStatus.APPROVED:
    case StaffingStatus.CONFIRMED:
      return 'positive';
    case StaffingStatus.CLOCKEDIN:
    case StaffingStatus.CLOCKEDOUT:
      return 'info';
    case StaffingStatus.PENDING:
      return 'warning';
    case StaffingStatus.CALLOUT:
    case StaffingStatus.FAULT:
    case StaffingStatus.REMOVED:
    case StaffingStatus.WITHDRAWN:
      return 'destructive';
    default:
      return 'info';
  }
};

const getValidStatusForLocationTracking = (status: StaffingStatus): boolean => {
  switch (status) {
    case StaffingStatus.APPROVED:
    case StaffingStatus.CONFIRMED:
    case StaffingStatus.CLOCKEDIN:
    case StaffingStatus.CLOCKEDOUT:
    case StaffingStatus.PENDING:
      return true;
    case StaffingStatus.CALLOUT:
    case StaffingStatus.FAULT:
    case StaffingStatus.REMOVED:
    case StaffingStatus.WITHDRAWN:
    default:
      return false;
  }
};

const getWorkerClassification = (type?: ShiftWorkerClassification) => {
  switch (type) {
    case ShiftWorkerClassification.WC_1099:
      return '1099';
    case ShiftWorkerClassification.WC_W2:
      return 'W-2';
    default:
      return '';
  }
};

const StaffingCard: React.FC<{
  tenderId: string;
  avatarUrl: string | null;
  tenderInitials: string;
}> = ({ tenderId, avatarUrl, tenderInitials }) => {
  const [selectedTab, setSelectedTab] = useState<TabType>('staffed');
  const [totalStaffed, setTotalStaffed] = useState(0);
  const [open, setOpen] = useState(false);
  const [selectedStaffing, setSelectedStaffing] = useState<Staffing>();
  const [totalDenied, setTotalDenied] = useState(0);
  const [page, setPage] = useState(1);

  const statusMap = {
    staffed: [
      StaffingStatus.APPROVED,
      StaffingStatus.CALLOUT,
      StaffingStatus.CLOCKEDIN,
      StaffingStatus.CLOCKEDOUT,
      StaffingStatus.CONFIRMED,
      StaffingStatus.FAULT,
      StaffingStatus.PENDING,
      StaffingStatus.REMOVED,
      StaffingStatus.WITHDRAWN,
    ],
    denied: [StaffingStatus.DENIED],
  };

  const { data: staffingData, loading: staffingLoading } =
    useGetTenderStaffingsForAdminQuery({
      variables: {
        filters: {
          tenderId: { contains: [tenderId] },
          status: { contains: statusMap.staffed },
        },
        pagination: {
          page,
          limit: 5,
        },
      },
      onCompleted: (onCompletedData) => {
        setTotalStaffed(
          onCompletedData.staffingsPaginatedByOffset.meta.totalItems,
        );
      },
    });

  const { data: deniedData, loading: deniedLoading } =
    useGetTenderStaffingsForAdminQuery({
      variables: {
        filters: {
          tenderId: { contains: [tenderId] },
          status: {
            contains: statusMap.denied,
          },
        },
        pagination: {
          page,
          limit: 5,
        },
      },
      onCompleted: (onCompletedData) => {
        setTotalDenied(
          onCompletedData.staffingsPaginatedByOffset.meta.totalItems,
        );
      },
    });

  const isStaffedTab = selectedTab === 'staffed';
  const data = isStaffedTab ? staffingData : deniedData;
  const totalPages = Math.ceil(
    isStaffedTab ? totalStaffed / 5 : totalDenied / 5,
  );
  const loading = staffingLoading || deniedLoading;
  const hasShifts = data?.staffingsPaginatedByOffset.items.length;

  useEffect(() => {
    // Reset page to 1 when tab changes between Staffed and Denied
    setPage(1);
  }, [selectedTab]);

  const handleOpenModal = (staffing: Staffing) => {
    setSelectedStaffing(staffing);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setSelectedStaffing(undefined);
  };

  return (
    <div className="bg-background-surface mr-4 mb-4 rounded">
      <div className="px-4 py-6">
        <Modal open={open} onClose={handleCloseModal}>
          <div>
            <div className="overflow-y-auto" style={modalStyles}>
              <div className="mt-4 px-6">
                <p className="text-preset-3 text-ink-dark mb-4 font-medium">
                  Tender Location Tracking
                </p>
                <p className="text-preset-5P text-ink-dark mb-4">
                  Here, you will be able to see all the location tracking points
                  that the Tender follows during the time around a shift.
                </p>
                {selectedStaffing && (
                  <LocationTrackingPoints
                    tenderInitials={tenderInitials}
                    avatarUrl={avatarUrl}
                    venueMarker={{
                      lat:
                        selectedStaffing?.shift?.job?.venue?.address.lat || 0,
                      lng:
                        selectedStaffing?.shift?.job?.venue?.address.lng || 0,
                    }}
                    staffingId={selectedStaffing.id}
                    endDate={selectedStaffing.shift?.endDateTime}
                    startDate={selectedStaffing.shift?.startDateTime}
                  />
                )}
              </div>
            </div>
            <div className="text-ink-dark border-support-lines-darker mt-5 mb-4 flex justify-end border-t px-6">
              <button
                className="border-ink-dark mt-4 rounded-md border py-2 px-5"
                onClick={handleCloseModal}
              >
                Close
              </button>
            </div>
          </div>
        </Modal>

        <p className="text-preset-3 text-ink-dark mb-4 font-medium">
          Staffing Applications
        </p>

        <ul className="flex">
          <Tab
            className="w-44"
            onClick={() => setSelectedTab('staffed')}
            selected={isStaffedTab}
            label={`Staffed (${totalStaffed})`}
            icon={
              <Check
                className="text-ink-clear w-4"
                circleColor={`${isStaffedTab ? '#045445' : '#9CA3AF'}`}
              />
            }
          />
          <Tab
            className="w-44"
            onClick={() => setSelectedTab('denied')}
            selected={selectedTab === 'denied'}
            label={`Denied (${totalDenied})`}
            icon={<Ban className="w-4" />}
          />
          <div className="border-support-lines w-8/12 flex-grow-0 border-b-2" />
        </ul>

        {hasShifts ? (
          <table className="mt-4 min-w-full table-auto">
            <thead className="bg-background-app">
              <tr className="top-0 z-10 h-12">
                <TableHeaderCell className="bg-background-app">
                  &nbsp;
                </TableHeaderCell>

                <TableHeaderCell className="bg-background-app w-80">
                  Job
                </TableHeaderCell>
                <TableHeaderCell className="bg-background-app w-32">
                  Start Date
                </TableHeaderCell>
                <TableHeaderCell className="bg-background-app w-32">
                  Position
                </TableHeaderCell>
                <TableHeaderCell className="bg-background-app">
                  Type
                </TableHeaderCell>
                {isStaffedTab && (
                  <TableHeaderCell className="bg-background-app">
                    Status
                  </TableHeaderCell>
                )}
                <TableHeaderCell className="bg-background-app w-40">
                  Payment
                </TableHeaderCell>
              </tr>
            </thead>

            <tbody className="bg-white">
              {data?.staffingsPaginatedByOffset.items.map((staffedShift) => {
                const shiftDate = new Date(staffedShift.shift?.startDateTime);
                const dateDiff = differenceInDays(shiftDate, new Date());
                const dateDiffText =
                  dateDiff < 0
                    ? `${dateDiff * -1} days ago`
                    : `In ${dateDiff} days`;
                const isValidStatus = getValidStatusForLocationTracking(
                  staffedShift.status,
                );
                const hasStartedTrackingLocation = dateDiff < 1;
                const canDisplayModal =
                  isStaffedTab && isValidStatus && hasStartedTrackingLocation;

                const jobName = `${
                  staffedShift.shift?.job.name || 'No name job'
                } @ ${
                  staffedShift.shift?.job.venue?.name || 'Venue without name'
                }`;

                return (
                  <tr
                    key={staffedShift.id}
                    className="border-support-line h-14 border-b"
                  >
                    <Cell className="border-support-lines border-b">
                      <button
                        data-cy="button-open-location-tracking"
                        onClick={() => {
                          if (canDisplayModal) {
                            handleOpenModal(staffedShift);
                          }
                        }}
                        title="See Tender Location Tracking"
                        type="button"
                      >
                        <Tracking className="text-brand-tend h-4 w-4" />
                        <span className="sr-only">
                          See Tender Location Tracking
                        </span>
                      </button>
                    </Cell>

                    <Cell className="border-support-lines border-b">
                      <a
                        className={`clamp-2-lines text-preset-6 max-w-80 ${
                          canDisplayModal
                            ? 'text-ink-link cursor-pointer'
                            : 'text-ink-dark'
                        }`}
                        data-cy="link-job-datails-page"
                        href={`/bookings/${staffedShift.shift?.job.id}`}
                        rel="noopener noreferrer"
                        target="_blank"
                        title={`Open Job in New Tab: ${jobName}`}
                      >
                        {jobName}
                      </a>
                    </Cell>

                    <Cell className="border-support-lines border-b">
                      <p className="text-preset-6 text-ink-dark">
                        {format(shiftDate, 'MM/dd/yyyy')}
                      </p>
                      <p className="text-preset-7 text-ink-not-as-dark">
                        {dateDiffText}
                      </p>
                    </Cell>

                    <Cell className="border-support-lines border-b">
                      <p
                        className="text-preset-6 text-ink-dark w-40 overflow-hidden text-ellipsis"
                        title={staffedShift.shift?.position.name}
                      >
                        {staffedShift.shift?.position.name}
                      </p>
                    </Cell>

                    <Cell className="border-support-lines border-b">
                      <p className="text-preset-6 text-ink-dark">
                        {getWorkerClassification(
                          staffedShift.shift?.workerClassification,
                        )}
                      </p>
                    </Cell>

                    {isStaffedTab && (
                      <Cell className="border-support-lines border-b">
                        <Pill
                          status={getStaffingStatusPillColor(
                            staffedShift.status,
                          )}
                        >
                          {staffedShift.status[0] +
                            staffedShift.status.substring(1).toLowerCase()}
                        </Pill>
                      </Cell>
                    )}

                    <Cell className="border-support-lines border-b">
                      <PaymentStatusPopover staffing={staffedShift} />
                    </Cell>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p className="text-preset-6 text-ink-not-as-dark flex pt-4">
            {isStaffedTab
              ? "This Tender hasn't been staffed on any shift yet"
              : "This Tender hasn't been denied a shift application just yet"}
          </p>
        )}
        {loading && (
          <div className="flex h-72 justify-center">
            <Loading />
          </div>
        )}
        {!!hasShifts && (
          <div className="flex justify-end py-4">
            <Pagination
              currentPage={page}
              pageCount={totalPages || 0}
              onPageChange={setPage}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default StaffingCard;
