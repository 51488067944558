import React from 'react';

const XCircleFilled: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={className}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 8C16 12.4183 12.4183 16 8 16C3.58171 16 0 12.4183 0 8C0 3.58171 3.58171 0 8 0C12.4183 0 16 3.58171 16 8Z"
      fill="currentColor"
    />
    <path
      d="M8.85474 7.94386L10.7197 9.80885L10.0038 10.5248L8.13879 8.6598L6.36219 10.4364L5.64624 9.72046L7.42285 7.94386L5.55786 6.07886L6.2738 5.36292L8.13879 7.22791L9.9154 5.45131L10.6313 6.16725L8.85474 7.94386Z"
      fill="white"
    />
  </svg>
);

export default XCircleFilled;
