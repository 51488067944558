import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  UnblockTenderAsAdmin,
  UnblockTenderAsAdminVariables,
} from './__generated__/UnblockTenderAsAdmin';

const UNBLOCK_TENDER = gql`
  mutation UnblockTenderAsAdmin($id: ID!) {
    unblockTender(id: $id) {
      __typename
      id
      blockedAt
    }
  }
`;

export const useUnblockTenderAsAdminMutation = (
  options?: MutationHookOptions<
    UnblockTenderAsAdmin,
    UnblockTenderAsAdminVariables
  >,
) =>
  useMutation<UnblockTenderAsAdmin, UnblockTenderAsAdminVariables>(
    UNBLOCK_TENDER,
    options,
  );

export default UNBLOCK_TENDER;
