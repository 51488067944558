import { gql, LazyQueryHookOptions, useLazyQuery } from '@apollo/client';
import STAFFING_LIST_ITEM from '../fragments/StaffingListItem';
import {
  RemovedJobApplicationsPaginated,
  RemovedJobApplicationsPaginatedVariables,
} from './__generated__/RemovedJobApplicationsPaginated';

const REMOVED_JOB_APPLICATIONS_PAGINATED = gql`
  query RemovedJobApplicationsPaginated(
    $shiftId: ID!
    $options: ListOptions
    $pagination: PaginationOptions
  ) {
    removedJobApplicationsPaginated(
      shiftId: $shiftId
      listOptions: $options
      pagination: $pagination
    ) {
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
      items {
        ...StaffingListItem
      }
    }
  }
  ${STAFFING_LIST_ITEM}
`;

export const useLazyRemovedJobApplicationsPaginatedQuery = (
  options?: LazyQueryHookOptions<
    RemovedJobApplicationsPaginated,
    RemovedJobApplicationsPaginatedVariables
  >,
) =>
  useLazyQuery<
    RemovedJobApplicationsPaginated,
    RemovedJobApplicationsPaginatedVariables
  >(REMOVED_JOB_APPLICATIONS_PAGINATED, options);

export default REMOVED_JOB_APPLICATIONS_PAGINATED;
