import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { endOfDay, startOfDay, subMonths } from 'date-fns';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import DateRangeInput from 'src/components/DateRangeInput';
import Button from 'src/components/Button';
import {
  OrderByDirectionEnum,
  TenderPaymentFilters,
  TenderPaymentStatus,
  TenderPaymentType,
} from 'src/__generated__/globalTypes';
import { useGetTenderPaymentsQuery } from 'src/graphql/queries/GetTenderPayouts';
import Drawer from 'src/components/Drawer';
import AdvancedFilters from './AdvancedFilters';
import NewBonusPayoutModal from './NewBonusPayoutModal';
import TenderPayoutsTable from './TenderPayoutsTable';

const getFilters = (searchParams: URLSearchParams): TenderPaymentFilters => {
  const staffingIdsParam = searchParams.get('staffingIds');
  const shiftIdsParam = searchParams.get('shiftIds');
  const jobIdsParam = searchParams.get('jobIds');
  const clientIdsParam = searchParams.get('clientIds');
  const tenderIdsParam = searchParams.get('tenderIds');
  const statusParam = searchParams.get('status');
  const typeParam = searchParams.get('type');
  const beforeParam = searchParams.get('before');
  const afterParam = searchParams.get('after');

  return {
    staffingIds:
      staffingIdsParam !== null
        ? { contains: staffingIdsParam.split(',') }
        : null,
    shiftIds:
      shiftIdsParam !== null ? { contains: shiftIdsParam.split(',') } : null,
    jobIds: jobIdsParam !== null ? { contains: jobIdsParam.split(',') } : null,
    clientIds:
      clientIdsParam !== null ? { contains: clientIdsParam.split(',') } : null,
    tenderIds:
      tenderIdsParam !== null ? { contains: tenderIdsParam.split(',') } : null,
    status:
      statusParam !== null && statusParam !== 'any'
        ? { contains: statusParam.split(',') as TenderPaymentStatus[] }
        : null,
    type:
      typeParam !== null && typeParam !== 'any'
        ? { contains: typeParam.split(',') as TenderPaymentType[] }
        : null,
    createDateTime:
      afterParam && beforeParam
        ? {
            greaterThanOrEqual: startOfDay(new Date(afterParam)),
            lessThanOrEqual: endOfDay(new Date(beforeParam)),
          }
        : null,
  };
};

const TenderPayouts: React.FC = () => {
  const [advancedFiltersOpen, setAdvancedFiltersOpen] = useState(false);
  const history = useHistory();
  const searchParams = useMemo(
    () => new URLSearchParams(history.location.search),
    [history.location.search],
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [orderByField, setOrderByField] = useState('updatedAt');
  const [orderByDirection, setOrderByDirection] =
    useState<OrderByDirectionEnum>(OrderByDirectionEnum.DESC);
  const [defaultFiltersValidated, setDefaultFiltersValidated] = useState(false);
  const sort = (field: string) => {
    setOrderByDirection(
      orderByField === field
        ? orderByDirection === OrderByDirectionEnum.ASC
          ? OrderByDirectionEnum.DESC
          : OrderByDirectionEnum.ASC
        : OrderByDirectionEnum.ASC,
    );
    setOrderByField(field);
  };
  const handleFilters = useCallback(
    (newFilters: Record<string, string | string[] | null>) => {
      Object.entries(newFilters).forEach(([filterName, filterValue]) => {
        searchParams.delete(filterName);

        if (Array.isArray(filterValue)) {
          if (filterValue.length) {
            searchParams.set(filterName, filterValue.join(','));
          }
        } else if (filterValue) {
          searchParams.set(filterName, filterValue);
        }
      });

      history.replace({
        search: `?${searchParams}`,
      });
    },
    [history, searchParams],
  );
  const handleDateChange = useCallback(
    (startDate?: Date, endDate?: Date) => {
      if (startDate && endDate) {
        handleFilters({
          after: startDate.toISOString(),
          before: endDate.toISOString(),
        });
      } else {
        handleFilters({ before: null, after: null });
      }
    },
    [handleFilters],
  );

  const filters = useMemo(() => getFilters(searchParams), [searchParams]);

  const {
    data: tenderPaymentsData,
    fetchMore,
    error,
    loading,
    refetch: refetchTenderPayouts,
  } = useGetTenderPaymentsQuery({
    skip: !defaultFiltersValidated,
    variables: {
      filters,
      orderByField,
      orderByDirection,
    },
  });
  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage:
      tenderPaymentsData?.tenderPayments.pageInfo.hasNextPage || false,
    onLoadMore: () =>
      fetchMore({
        variables: {
          after: tenderPaymentsData?.tenderPayments.pageInfo.endCursor,
          orderByField,
          orderByDirection,
        },
      }),
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: !!error,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: '0px 0px 400px 0px',
  });

  useEffect(() => {
    if (
      !defaultFiltersValidated &&
      !Object.values(filters).find((filter) => !!filter)
    ) {
      const today = startOfDay(new Date());
      const defaultStartDate = subMonths(today, 1);
      handleDateChange(defaultStartDate, today);
    }

    setDefaultFiltersValidated(true);
  }, [defaultFiltersValidated, filters, handleDateChange, tenderPaymentsData]);

  const paymentsCount = tenderPaymentsData?.tenderPayments.pageInfo.totalCount;

  return (
    <div className="flex h-full flex-col overflow-y-scroll">
      <div className="top-0 z-10 overflow-x-visible">
        <div className="inline-block min-w-full align-middle">
          <div className="border-support-line border sm:rounded-lg">
            <div className="bg-background-surface flex items-center py-5">
              <h3 className="text-ink-dark flex-1 pl-6 text-2xl font-light leading-7">
                {`${paymentsCount ?? 0} Payment${
                  Number(paymentsCount) > 1 ? 's' : ''
                }`}
              </h3>
              <button
                type="button"
                className="text-preset-5 bg-primary text-ink-clear hover:bg-primary-active mr-5 h-11 rounded py-2.5 px-5 font-medium"
                onClick={() => {
                  setModalOpen(true);
                }}
              >
                Create Bonus
              </button>
              <DateRangeInput
                className="mr-6"
                onChange={handleDateChange}
                startDate={filters.createDateTime?.greaterThanOrEqual}
                endDate={filters.createDateTime?.lessThanOrEqual}
                allowAnyDate
              />
              <Button
                className="border-ink-dark text-preset-5 text-ink-dark hover:border-ink-not-as-dark hover:text-ink-not-as-dark mr-6 inline-flex h-11 items-center rounded-md border-2 px-4 font-medium"
                onClick={() => setAdvancedFiltersOpen(true)}
                data-cy="advanced-filters-button"
              >
                Advanced Filters
              </Button>
            </div>
          </div>
        </div>
      </div>
      <TenderPayoutsTable
        sort={sort}
        orderByDirection={orderByDirection}
        orderByField={orderByField}
        tenderPayments={tenderPaymentsData?.tenderPayments.edges}
      />
      {(loading || tenderPaymentsData?.tenderPayments.pageInfo.hasNextPage) && (
        <div ref={sentryRef}>Loading more...</div>
      )}
      <Drawer
        open={advancedFiltersOpen}
        onClose={() => setAdvancedFiltersOpen(false)}
        title="Advanced Filters"
        data-cy="advanced-filters-title"
      >
        <AdvancedFilters
          onChange={(newFilters) => {
            handleFilters(newFilters);
            setAdvancedFiltersOpen(false);
          }}
        />
      </Drawer>
      <NewBonusPayoutModal
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
        onPayoutCreated={() => {
          refetchTenderPayouts();
        }}
      />
    </div>
  );
};

export default TenderPayouts;
