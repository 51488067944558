import { addDays, endOfDay, startOfDay } from 'date-fns';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useCallback, useEffect, useMemo } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useHistory } from 'react-router-dom';
import { JobsFilters, ShiftStatus } from 'src/__generated__/globalTypes';
import Button from 'src/components/Button';
import { WrappedClientsInput as ClientsInput } from 'src/components/ClientsInput';
import DateRangeInput from 'src/components/DateRangeInput';
import Copy from 'src/components/Icons/Copy';
import Human from 'src/components/Icons/Human';
import PaperClip from 'src/components/Icons/PaperClip';
import MultipleSelectionDropdown from 'src/components/MultipleSelectionDropdown';
import OriginInput from 'src/components/OriginInput';
import Pill from 'src/components/Pill';
import PublishedInput from 'src/components/PublishedInput';
import StaffedInput from 'src/components/StaffedInput';
import Toggle from 'src/components/Toggle';
import ViewContainer from 'src/components/ViewContainer';
import { useSetJobVisibilityForClientMutation } from 'src/graphql/mutations/SetJobVisibilityForClients';
import { useGetJobsQuery } from 'src/graphql/queries/GetJobs';
import { useSuperRegionsQuery } from 'src/graphql/queries/SuperRegions';
import { Clients_getClients_items as ClientItem } from 'src/graphql/queries/__generated__/Clients';
import { GetJobs_jobs_edges_node as Job } from 'src/graphql/queries/__generated__/GetJobs';
import { useLocalStorage } from 'src/hooks/localStorage';
import {
  RETOOL_MESSAGING_APP_URL,
  UNASSIGNED_SUPER_REGION_OPTION,
} from 'src/utils/constants';
import { calculateDateTimes } from 'src/utils/dates';
import { usePublishJobAsAdminMutation } from 'src/graphql/mutations/PublishJob';
import { useUnpublishJobAsAdminMutation } from 'src/graphql/mutations/UnpublishJob';
import { useShowAtOpenJobsMutation } from 'src/graphql/mutations/ShowFromOpenJobs';
import { useHideJobAsAdminMutation } from 'src/graphql/mutations/HideFromOpenJobs';

const getFilters = (
  searchParams: URLSearchParams,
  options: {
    useFlexpoolFilter: boolean | undefined;
    useStaffingRegions: boolean | undefined;
    useCancelledOrdersFilter: boolean | undefined;
    useAttachmentsFilter: boolean | undefined;
  },
): JobsFilters => {
  const now = new Date();
  const startDateParam = searchParams.get('shiftStartDate');
  const endDateParam = searchParams.get('shiftEndDate');
  const clientIdsParam = searchParams.get('clientIds');
  const staffingStatusParam = searchParams.get('staffingStatus');
  const published = searchParams.get('published');
  const superRegionIdsParam = searchParams.get('superRegionIds');
  const isFlexpoolOrderParam = searchParams.get('isFlexpoolOrder');
  const cancelledParam = searchParams.get('cancelled');
  const hasAttachmentsParam = searchParams.get('hasAttachments');
  const hiddenFromClientParam = searchParams.get('hiddenFromClient');

  const baseFilters = {
    jobDates: {
      greaterThanOrEqual:
        startDateParam && startOfDay(new Date(startDateParam)),
      lessThanOrEqual: endDateParam && endOfDay(new Date(endDateParam)),
      endsAfter: startOfDay(now),
    },
    clientIds:
      clientIdsParam !== null ? { contains: clientIdsParam.split(',') } : null,
    staffingStatus: staffingStatusParam
      ? {
          contains: [staffingStatusParam],
        }
      : null,
    published: published !== null ? published === 'true' : null,
    hiddenFromClient: hiddenFromClientParam !== null ? true : null,
  };

  const staffingRegionsFilters = options.useStaffingRegions
    ? {
        superRegionIds:
          superRegionIdsParam !== null
            ? { contains: superRegionIdsParam.split(',') }
            : null,
      }
    : {};

  const flexPoolOrdersFilters = options.useFlexpoolFilter
    ? {
        isFlexpoolOrder:
          isFlexpoolOrderParam !== null
            ? isFlexpoolOrderParam === 'true'
            : null,
      }
    : {};

  const otherFilters = {
    ...(options.useCancelledOrdersFilter
      ? {
          cancelled: cancelledParam !== null ? true : null,
        }
      : {}),
    ...(options.useAttachmentsFilter
      ? {
          hasAttachments: hasAttachmentsParam !== null ? true : null,
        }
      : {}),
  };

  return {
    ...baseFilters,
    ...staffingRegionsFilters,
    ...flexPoolOrdersFilters,
    ...otherFilters,
  };
};

interface JobCardProps {
  job: Job;
}

const JobCard: React.FC<JobCardProps> = ({ job }) => {
  const history = useHistory();
  const flags = useFlags();
  const { startDateTime, endDateTime, venue, client } = job;
  const timezone = venue?.address.timezone;
  const isFlexpoolOrder = job.isFlexpoolOrder === true;
  const isNowsta2 = job.isNowsta2 === true;
  const isCancelled = job.status === 'CANCELLED';
  const hasAttachments = job.attachments && job.attachments?.length > 0;
  const [setJobVisibilityForClientMutation] =
    useSetJobVisibilityForClientMutation();

  const [publishJobMutation] = usePublishJobAsAdminMutation({
    variables: { jobId: job.id },
  });
  const [unPublishJobMutation] = useUnpublishJobAsAdminMutation({
    variables: { jobId: job.id },
  });

  const handlePublishJob = useCallback(
    async (published: boolean): Promise<void> => {
      if (published) {
        await unPublishJobMutation();
      } else {
        await publishJobMutation();
      }
    },
    [publishJobMutation, unPublishJobMutation],
  );

  const [showAtOpenJobsMutation] = useShowAtOpenJobsMutation({
    variables: { jobId: job.id },
  });
  const [hideFromOpenJobsMutation] = useHideJobAsAdminMutation({
    variables: { jobId: job.id },
  });

  const handleShowJob = useCallback(
    async (hiddenFromOpenJobs: boolean): Promise<void> => {
      if (hiddenFromOpenJobs) {
        await showAtOpenJobsMutation();
      } else {
        await hideFromOpenJobsMutation();
      }
    },
    [showAtOpenJobsMutation, hideFromOpenJobsMutation],
  );

  const { totalQuantity, totalStaffed } = job.shifts?.reduce(
    (previous, shift) => {
      return {
        totalQuantity: previous.totalQuantity + shift.quantity,
        totalStaffed: previous.totalStaffed + shift.staffedSlots,
      };
    },
    { totalQuantity: 0, totalStaffed: 0 },
  ) || { totalQuantity: 0, totalStaffed: 0 };

  const clientAndLocationComponents: string[] = [];

  if (client) {
    if (client.firstName || client.lastName) {
      clientAndLocationComponents.push(
        `${client?.firstName || ''} ${client?.lastName || ''}`.trim(),
      );
    }
    if (client.email) {
      clientAndLocationComponents.push(client.email);
    }
  }

  if (venue) {
    const venueComponents: string[] = [];
    if (venue.name) {
      venueComponents.push(venue.name);
    }
    if (venue.address.city) {
      venueComponents.push(venue.address.city);
    }
    clientAndLocationComponents.push(venueComponents.join(', '));
  }

  const renderData = {
    testIds: [
      ...(isFlexpoolOrder
        ? ['job-card-type-flexpool']
        : ['job-card-type-tend']),
      ...(isCancelled && flags.canceledOrdersFilter
        ? ['job-card-status-cancelled']
        : []),
    ],
    selectionBg:
      isCancelled && flags.canceledOrdersFilter
        ? 'selection:bg-status-destructive-light'
        : isNowsta2
        ? 'selection:bg-brand-nowsta2'
        : isFlexpoolOrder
        ? 'selection:bg-brand-nowsta'
        : 'selection:bg-brand-50',
    backgroundClasses:
      isCancelled && flags.canceledOrdersFilter
        ? 'bg-status-destructive'
        : isNowsta2
        ? 'bg-brand-nowsta2'
        : isFlexpoolOrder
        ? 'bg-brand-nowsta'
        : 'bg-brand-tend',
  };

  const handleArchivedStatus = useCallback(
    async (hiddenFromClient: boolean): Promise<void> => {
      await setJobVisibilityForClientMutation({
        variables: {
          jobId: job.id,
          isVisible: hiddenFromClient,
        },
      });
    },
    [setJobVisibilityForClientMutation, job.id],
  );

  const handleArchive = useCallback(async () => {
    await handleArchivedStatus(job.hiddenFromClient);
  }, [handleArchivedStatus, job.hiddenFromClient]);

  return (
    <div
      data-cy={renderData.testIds.join(':')}
      className={`${renderData.selectionBg} mb-4 w-full border border-gray-200 p-6`}
    >
      <div className="mb-1 flex justify-between">
        <div className="flex items-center space-x-2">
          <div
            className={`${renderData.backgroundClasses} flex items-center rounded-full px-2 py-0.5`}
            title={
              isNowsta2
                ? 'Nowsta 2 Booking'
                : isFlexpoolOrder
                ? 'Flexpool Booking'
                : 'Tend Booking'
            }
          >
            <Human className="text-ink-clear mr-1 h-3" />

            <p className="text-ink-clear bold text-preset-6">
              {totalStaffed}/{totalQuantity}
            </p>
          </div>

          <p className="text-ink-dark text-lg">{job.name}</p>
          <div className="bg-background-app text-ink-not-as-dark flex h-5 items-center pr-0.5">
            <span
              className="mr-1 h-5 cursor-pointer pl-0.5 pt-1 pr-1 opacity-80 hover:opacity-100 active:opacity-20"
              onClick={(ev) => navigator.clipboard.writeText(job.id)}
            >
              <Copy className="text-ink-dark h-4" />
            </span>
            <p className="text-preset-6">{job.id}</p>
          </div>

          {isCancelled && flags.canceledOrdersFilter && (
            <span className="bg-status-destructive text-preset-6 ml-2 rounded-full px-2  py-1 font-bold text-white">
              Canceled
            </span>
          )}
        </div>

        <Pill status={job.staffed ? 'positive' : 'destructive'} className="h-6">
          {job.staffed ? 'All Staffed' : 'Non Staffed'}
        </Pill>
      </div>
      <a
        className="text-primary text-preset-6 hover:bg-primary-light hover:text-brand-50 rounded px-4 py-1.5"
        href={`${RETOOL_MESSAGING_APP_URL}#jobId=${job.id}`}
        target="_blank"
        rel="external noreferrer"
      >
        Message tenders in job
      </a>
      <p className="text-preset-6P text-ink-not-as-dark mb-6">
        {calculateDateTimes(startDateTime, endDateTime, timezone)}
      </p>

      <p className="text-preset-6P text-ink-dark mb-4">
        {clientAndLocationComponents.join(' • ')}
      </p>

      <div className="border-support-line flex items-center justify-between border-t pt-2">
        <div className="text-ink-not-as-dark text-preset-7 flex items-center space-x-6">
          <div className="flex items-center">
            <Toggle
              id={`${job.id}-client`}
              label={'Archived'}
              checked={job.hiddenFromClient}
              onClick={handleArchive}
            />
          </div>

          <Toggle
            id={`${job.id}-open-jobs`}
            label="Hide from open jobs"
            checked={job.hiddenFromOpenJobs}
            onClick={async () => {
              await handleShowJob(job.hiddenFromOpenJobs);
            }}
            className="ml-6"
          />

          <Toggle
            id={`${job.id}-published`}
            label="Published"
            checked={job.published}
            onClick={async () => {
              await handlePublishJob(job.published);
            }}
            className="ml-6"
          />

          {flags.jobAttachments && hasAttachments && (
            <div className="flex items-center">
              <PaperClip className="h-4" />
              <p className="ml-1.5">{`Attachments (x${job.attachments?.length})`}</p>
            </div>
          )}
        </div>

        <Button
          className="text-preset-5 py-2.5 px-6"
          primary
          onClick={() => {
            history.push(`/bookings/${job.id}`);
          }}
        >
          Edit details
        </Button>
      </div>
    </div>
  );
};

const Bookings: React.FC = () => {
  const flags = useFlags();
  const history = useHistory();

  const searchParams = useMemo(
    () => new URLSearchParams(history.location.search),
    [history.location.search],
  );

  const { data: superRegions, loading: loadingStaffingRegions } =
    useSuperRegionsQuery({
      fetchPolicy: 'network-only',
    });

  const handleFilters = useCallback(
    (newFilters: Record<string, string | string[] | null>) => {
      Object.entries(newFilters).forEach(([filterName, filterValue]) => {
        searchParams.delete(filterName);

        if (Array.isArray(filterValue)) {
          if (filterValue.length) {
            searchParams.set(filterName, filterValue.join(','));
          }
        } else if (filterValue) {
          searchParams.set(filterName, filterValue);
        }
      });

      history.replace({
        search: `?${searchParams}`,
      });
    },
    [history, searchParams],
  );

  const [userStaffingRegions, setUserStaffingRegions] = useLocalStorage(
    'staffingRegions',
    [],
  );

  const filters = getFilters(searchParams, {
    useStaffingRegions: flags.staffingRegions,
    useFlexpoolFilter: flags.flexpoolFilters,
    useCancelledOrdersFilter: flags.canceledOrdersFilter,
    useAttachmentsFilter: flags.jobAttachments,
  });

  const activeOriginFilterValue = useMemo(() => {
    if (flags.canceledOrdersFilter && filters.cancelled === true) {
      return 'CANCELLED' as ShiftStatus;
    }

    if (flags.jobAttachments && filters.hasAttachments === true) {
      return 'hasAttachments';
    }

    return filters.isFlexpoolOrder === null
      ? undefined
      : filters.isFlexpoolOrder;
  }, [filters, flags.canceledOrdersFilter, flags.jobAttachments]);

  const activePublishedFilterValue = useMemo(() => {
    if (filters.hiddenFromClient === true) {
      return 'hiddenFromClient';
    }

    return filters.published === null ? undefined : filters.published;
  }, [filters]);

  const handleDateChange = useCallback(
    (startDate?: Date, endDate?: Date) => {
      if (startDate && endDate) {
        handleFilters({
          shiftStartDate: startDate.toISOString(),
          shiftEndDate: endDate.toISOString(),
        });
      }
    },
    [handleFilters],
  );

  const handleClientsChange = useCallback(
    (clients: ClientItem[] | undefined) => {
      handleFilters({
        clientIds: clients?.map((client) => client.id) || [],
      });
    },
    [handleFilters],
  );

  const handleStaffingChange = useCallback(
    (value: string | undefined) =>
      handleFilters({
        staffingStatus: value || null,
      }),
    [handleFilters],
  );

  const handleOriginChange = useCallback(
    // NOTE: filter requires special handling because it includes values that need to filter by different fields (isFlexpoolShift and cancelled)
    (value: boolean | string | undefined) => {
      let filter = {};

      if (flags.canceledOrdersFilter && value === 'CANCELLED') {
        filter = {
          cancelled: true.toString(),
          isFlexpoolOrder: null,
          hasAttachments: null,
        };
      } else if (flags.jobAttachments && value === 'hasAttachments') {
        filter = {
          cancelled: null,
          isFlexpoolOrder: null,
          hasAttachments: true.toString(),
        };
      } else {
        filter = {
          isFlexpoolOrder: value !== undefined ? value.toString() : null,
          cancelled: null,
          hasAttachments: null,
        };
      }

      return handleFilters(filter);
    },
    [handleFilters, flags.canceledOrdersFilter, flags.jobAttachments],
  );

  const handlePublishedChange = useCallback(
    // NOTE: filter requires special handling because it includes values that need to filter by different fields (hiddenFromClient)
    (value: boolean | string | undefined) => {
      let filter = {};

      if (value === 'hiddenFromClient') {
        filter = {
          published: null,
          hiddenFromClient: true.toString(),
        };
      } else {
        filter = {
          published: value !== undefined ? value.toString() : null,
          hiddenFromClient: null,
        };
      }

      return handleFilters(filter);
    },
    [handleFilters],
  );

  const handleStaffingRegionsChange = useCallback(
    (staffingRegions: string[] | undefined) => {
      handleFilters({
        superRegionIds: staffingRegions || null,
      });
    },
    [handleFilters],
  );

  const handleStaffingRegionsReset = () => {
    setUserStaffingRegions([]);
  };

  // use useEffect hook to sync URL parameters and local storage data (source of truth)
  useEffect(() => {
    if (flags.staffingRegions) {
      handleStaffingRegionsChange(userStaffingRegions);
    }
  }, [handleStaffingRegionsChange, userStaffingRegions, flags.staffingRegions]);

  const hasDateFilters =
    !!filters.jobDates?.greaterThanOrEqual &&
    !!filters.jobDates.lessThanOrEqual;

  const {
    data: jobsData,
    loading: loadingJobs,
    error,
    fetchMore: fetchMoreJobs,
  } = useGetJobsQuery({
    variables: {
      filters,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    skip: !hasDateFilters,
  });

  useEffect(() => {
    const now = new Date();
    const defaultEndDate = addDays(now, 3);
    if (!hasDateFilters) {
      handleDateChange(now, defaultEndDate);
    }
  }, [handleDateChange, hasDateFilters]);

  const [sentryRef] = useInfiniteScroll({
    loading: loadingJobs,
    hasNextPage: jobsData?.jobs.pageInfo.hasNextPage || false,
    onLoadMore: () => {
      return fetchMoreJobs({
        variables: { after: jobsData?.jobs.pageInfo.endCursor, filters },
      });
    },
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: !!error,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: '0px 0px 400px 0px',
  });

  const superRegionOptions = superRegions?.superRegions.concat(
    UNASSIGNED_SUPER_REGION_OPTION,
  ) || [UNASSIGNED_SUPER_REGION_OPTION];

  return (
    <ViewContainer className="mt-10 mb-16 lg:px-0">
      <div className="mb-3 px-4">
        <h3 className="text-ink-dark text-preset-3 font-light">
          {jobsData?.jobs.pageInfo.totalCount} Bookings
        </h3>
      </div>

      <div className="border-support-line bg-background-surface z-10 mb-6 inline-block min-w-full overflow-x-visible rounded-lg border px-4 py-5">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-5">
            {hasDateFilters && (
              <DateRangeInput
                onChange={handleDateChange}
                startDate={filters.jobDates?.greaterThanOrEqual}
                endDate={filters.jobDates?.lessThanOrEqual}
              />
            )}

            {flags.staffingRegions && (
              <div className="w-50 ml-6">
                <MultipleSelectionDropdown
                  className="w-full"
                  buttonText={
                    userStaffingRegions.length
                      ? `Staffing Regions (${userStaffingRegions.length})`
                      : 'All Staffing Regions'
                  }
                  hasSelectionSummary
                  items={superRegionOptions}
                  itemsSelected={superRegionOptions.filter((sr) =>
                    userStaffingRegions.includes(sr.id),
                  )}
                  isLoadingItems={loadingStaffingRegions}
                  onItemSelect={(item) => {
                    if (userStaffingRegions.includes(item.id)) {
                      setUserStaffingRegions(
                        userStaffingRegions.filter(
                          (sr: string) => sr !== item.id,
                        ),
                      );
                    } else {
                      setUserStaffingRegions([...userStaffingRegions, item.id]);
                    }
                  }}
                  optionsHeader="Staffing Regions"
                  renderLoadingComponent={() => (
                    <div className="flex justify-center">
                      Loading regions...
                    </div>
                  )}
                  renderResetComponent={() => (
                    <button
                      className="text-preset-6 text-ink-dark border-b-support-line w-full border-b px-4 py-5 pt-2 text-left"
                      onClick={handleStaffingRegionsReset}
                    >
                      All Staffing Regions
                    </button>
                  )}
                />
              </div>
            )}

            <ClientsInput
              onChange={handleClientsChange}
              selectedClientsIds={filters.clientIds?.contains}
            />
            <StaffedInput
              value={filters.staffingStatus?.contains[0]}
              onChange={handleStaffingChange}
            />

            <PublishedInput
              value={activePublishedFilterValue}
              onChange={handlePublishedChange}
            />

            {flags.flexpoolFilters && (
              <OriginInput
                value={activeOriginFilterValue}
                onChange={handleOriginChange}
              />
            )}
          </div>
        </div>
      </div>

      <div className="flex max-w-full flex-wrap px-6">
        {jobsData?.jobs.edges.map(({ node: job }) => (
          <JobCard key={job.id} job={job} />
        ))}
      </div>

      {(loadingJobs || jobsData?.jobs.pageInfo.hasNextPage) && (
        <div ref={sentryRef}>Loading...</div>
      )}
    </ViewContainer>
  );
};

export default Bookings;
