import React, { Fragment, useMemo, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ApolloError } from '@apollo/client';
import Download from 'src/components/Icons/Download';
import DotsHorizontal from 'src/components/Icons/DotsHorizontal';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import { useRequestStaffingsCSVMutation } from 'src/graphql/mutations/RequestStaffingsCSV';
import { ShiftFilters } from 'src/__generated__/globalTypes';

const StaffingsMoreOptionsMenu: React.FC<{
  className?: string;
  shiftFilters: ShiftFilters;
}> = ({ className, shiftFilters }) => {
  const [operationError, setOperationError] = useState<string>();
  const [isCSVRequestFinished, setIsCSVRequestFinished] = useState(false);
  const [requestStaffingsCSV, { loading: requestingStaffingsCSV }] =
    useRequestStaffingsCSVMutation();
  const menuElements = useMemo(
    () => [
      {
        renderLabel: () => (
          <>
            <Download className="mr-2 inline-block h-4 w-4 text-black" />{' '}
            Download CSV
          </>
        ),
        clickHandler: async () => {
          try {
            await requestStaffingsCSV({ variables: { shiftFilters } });
            setIsCSVRequestFinished(true);
          } catch (err) {
            if (err instanceof ApolloError) {
              setOperationError(err.message);
            } else {
              throw err;
            }
          }
        },
        disabled: requestingStaffingsCSV,
      },
    ],
    [requestStaffingsCSV, requestingStaffingsCSV, shiftFilters],
  );

  return (
    <>
      <Menu as="div" className="relative">
        <Menu.Button className={`text-ink-not-as-dark ${className}`}>
          <DotsHorizontal className="h-6 w-6" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="bg-background-surface shadow-floating-menu text-ink-dark absolute right-0 mt-2 rounded py-2">
            {menuElements.map((element, index) => (
              <Menu.Item key={index} as={Fragment}>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-background-app' : ''
                    } flex items-center whitespace-nowrap px-4 py-2`}
                    onClick={element.clickHandler}
                    disabled={element.disabled}
                  >
                    {element.renderLabel()}
                  </button>
                )}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
      <ConfirmationDialog
        open={isCSVRequestFinished}
        title="CSV Requested"
        description="You will receive an email with the CSV export shortly"
        cancelText="Close"
        onClose={() => setIsCSVRequestFinished(false)}
      />
      <ConfirmationDialog
        open={Boolean(operationError)}
        title="Operation error"
        description={operationError || ''}
        cancelText="Close"
        onClose={() => setOperationError(undefined)}
      />
    </>
  );
};

export default StaffingsMoreOptionsMenu;
