import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import SHIFT_FOR_STAFFING_MUTATION from '../fragments/ShiftForStaffingMutation';
import {
  DenyTenderApplications,
  DenyTenderApplicationsVariables,
} from './__generated__/DenyTenderApplications';

const DENY_TENDER_APPLICATIONS = gql`
  mutation DenyTenderApplications($staffingIds: [ID!]!) {
    mutationPayload: cancelTenderApplications(staffingIds: $staffingIds) {
      modifiedStaffings {
        id
        status
        shift {
          ...ShiftForStaffingMutation
        }
      }
      errors {
        staffingId
        message
      }
    }
  }
  ${SHIFT_FOR_STAFFING_MUTATION}
`;

export const useDenyTenderApplicationsMutation = (
  options?: MutationHookOptions<
    DenyTenderApplications,
    DenyTenderApplicationsVariables
  >,
) =>
  useMutation<DenyTenderApplications, DenyTenderApplicationsVariables>(
    DENY_TENDER_APPLICATIONS,
    options,
  );

export default DENY_TENDER_APPLICATIONS;
