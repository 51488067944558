import { SVGProps } from 'react';

const StatUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 13 12"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.914 4.2a.6.6 0 0 1 0-1.2h3a.6.6 0 0 1 .6.6v3a.6.6 0 1 1-1.2 0V5.049L7.74 7.624a.6.6 0 0 1-.849 0L5.514 6.25 2.94 8.824a.6.6 0 1 1-.849-.848l3-3a.6.6 0 0 1 .849 0L7.314 6.35 9.466 4.2H7.914Z"
      clipRule="evenodd"
    />
  </svg>
);

export default StatUp;
