import React, { FC, useRef, useEffect, useState } from 'react';
import { format, utcToZonedTime } from 'date-fns-tz';
import {
  InvoicingStatus,
  OrderByDirectionEnum,
} from 'src/__generated__/globalTypes';
import { InvoiceStaffings } from 'src/graphql/queries/__generated__/InvoiceStaffings';
import Pagination from 'src/components/Pagination';
import TableHeaderCell from 'src/components/TableHeaderCell';
import { formatMoney } from 'src/utils/formatMoney';
import styles from './InvoiceCreate.module.css';

export enum SelectionMode {
  BLACKLIST = 'BLACKLIST',
  WHITELIST = 'WHITELIST',
}

type ScrollbarsOffset = {
  x: number;
  y: number;
};

interface Props {
  pageNumber: number;
  orderByField: string;
  orderByDirection: OrderByDirectionEnum;
  selectionMode: SelectionMode;
  flippedStaffings: Record<string, boolean>;
  onFlipStaffing: (id: string) => void;
  onPageChange: (pageNumber: number) => void;
  onSortChange: (field: string, direction: OrderByDirectionEnum) => void;
  onSelectAll: () => void;
  onUnselectAll: () => void;
  selectedAmount: number;
  data?: InvoiceStaffings;
  PaginatorContainer?: (children: {
    children: React.ReactNode;
  }) => React.ReactPortal;
}

const SimpleCell: FC<{ className?: string }> = ({ children, className }) => (
  <td
    className={`text-preset-6 text-ink-dark bg-background-surface px-4
      ${className || ''}`}
  >
    <p className="truncate">{children}</p>
  </td>
);

interface CompoundCellProps {
  label?: string;
  detail?: string;
  imageURL?: string;
  className?: string;
}

const CompoundCell: FC<CompoundCellProps> = ({
  label,
  detail,
  imageURL,
  className = '',
}) => (
  <td className={`bg-background-surface px-4 ${className}`}>
    {imageURL && (
      <div
        className="float-left mr-2 h-10 w-10 rounded-full bg-cover"
        style={{ backgroundImage: `url(${imageURL})` }}
      />
    )}
    {label && (
      <p className="text-preset-6 text-ink-dark mb-1 truncate">{label}</p>
    )}
    {detail && (
      <p className="text-preset-7 text-ink-not-as-dark truncate">{detail}</p>
    )}
  </td>
);

const Status: FC<{ status: InvoicingStatus | null }> = ({ status }) => {
  let text,
    backgroundClassName = '';

  switch (status) {
    case InvoicingStatus.LIBERATED:
      backgroundClassName = 'bg-status-warning';
      text = status.toLowerCase();
      break;

    case InvoicingStatus.INVOICED:
      backgroundClassName = 'bg-status-positive';
      text = status.toLowerCase();
      break;

    default:
      backgroundClassName = 'bg-ink-dark';
      text = 'No Invoice';
  }

  return (
    <p
      className={`text-ink-clear text-preset-6 inline-block rounded-full py-1.5 px-2 capitalize ${backgroundClassName}`}
    >
      {text}
    </p>
  );
};

const InvoiceStaffingsTable: FC<Props> = ({
  data,
  pageNumber,
  orderByField,
  orderByDirection,
  selectionMode,
  flippedStaffings,
  onPageChange,
  onSortChange,
  onFlipStaffing,
  onSelectAll,
  onUnselectAll,
  selectedAmount,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  PaginatorContainer,
}) => {
  const [showStickyBorder, setShowStickyBorder] = useState<boolean>(false);
  const [scrollbarsOffset, setScrollbarsOffset] = useState<ScrollbarsOffset>({
    x: 0,
    y: 0,
  });
  const containerRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    const element = containerRef.current;
    if (!element) {
      return;
    }
    const listener = (e: Event) => {
      const scrolled = (e.currentTarget as Element).scrollLeft > 0;
      if (scrolled && !showStickyBorder) {
        setShowStickyBorder(true);
      } else if (!scrolled && showStickyBorder) {
        setShowStickyBorder(false);
      }
    };
    element.addEventListener('scroll', listener);
    return () => element.removeEventListener('scroll', listener);
  }, [containerRef, showStickyBorder, setShowStickyBorder]);

  useEffect(() => {
    const div = containerRef.current;
    if (!div) {
      return;
    }
    const adjustScrollbarsOffset = () => {
      const x = div.offsetWidth - div.clientWidth;
      const y = div.offsetHeight - div.clientHeight;
      if (scrollbarsOffset.x !== x || scrollbarsOffset.y !== y) {
        setScrollbarsOffset({ x, y });
      }
    };
    adjustScrollbarsOffset();
    window.addEventListener('resize', adjustScrollbarsOffset);
    return () => window.removeEventListener('resize', adjustScrollbarsOffset);
  }, [containerRef, scrollbarsOffset, setScrollbarsOffset]);

  const pageInfo = data?.invoiceStaffings.meta;
  let showingStart, showingEnd, totalItems;
  if (pageInfo) {
    showingStart = (pageInfo.currentPage - 1) * pageInfo.itemsPerPage + 1;
    showingEnd = showingStart + pageInfo.itemCount - 1;
    totalItems = pageInfo.totalItems;
  }

  const sort = (field: string) => () =>
    onSortChange(
      field,
      field !== orderByField || orderByDirection === OrderByDirectionEnum.DESC
        ? OrderByDirectionEnum.ASC
        : OrderByDirectionEnum.DESC,
    );

  const isStaffingChecked = (id: string) =>
    selectionMode === SelectionMode.WHITELIST
      ? flippedStaffings[id] === true
      : flippedStaffings[id] === undefined;

  const partialSelection =
    totalItems && selectedAmount < totalItems && selectedAmount > 0;

  const TablePaginatorContainer = ({
    children,
  }: {
    children: React.ReactNode;
  }) =>
    PaginatorContainer ? (
      <PaginatorContainer>{children}</PaginatorContainer>
    ) : (
      <>{children}</>
    );

  return (
    <>
      <div
        className={`bg-background-app flex-grow overflow-auto ${
          selectedAmount ? 'pb-28' : 'pb-14'
        }`}
        ref={containerRef}
      >
        <table className="min-w-full table-fixed">
          <thead className="bg-background-app">
            <tr className="sticky top-0 z-10 h-12">
              <TableHeaderCell className="bg-background-app sticky left-0 w-24">
                <input
                  type="checkbox"
                  title="select all staffings"
                  className={`text-brand-50 border-ink-dark checked:border-ink-dark h-6 w-6 rounded-md border focus:outline-none focus:ring-0 ${
                    partialSelection ? 'checked:partial-check' : ''
                  }`}
                  checked={selectedAmount > 0}
                  onChange={
                    selectedAmount > 0 && !partialSelection
                      ? onUnselectAll
                      : onSelectAll
                  }
                />
              </TableHeaderCell>
              <TableHeaderCell
                className="bg-background-app sticky left-12 w-36"
                sortDirection={
                  orderByField === 'shift.startDateTime'
                    ? orderByDirection
                    : undefined
                }
                onClick={sort('shift.startDateTime')}
              >
                Date
              </TableHeaderCell>
              <TableHeaderCell
                className="bg-background-app sticky left-48 w-72"
                sortDirection={
                  orderByField === 'job.name' ? orderByDirection : undefined
                }
                onClick={sort('job.name')}
              >
                Job
              </TableHeaderCell>
              <TableHeaderCell
                className={`bg-background-app sticky left-96 w-72 ${
                  showStickyBorder ? styles.rightbordershadow : ''
                }`}
                sortDirection={
                  orderByField === 'client.id' ? orderByDirection : undefined
                }
                onClick={sort('client.id')}
              >
                Client
              </TableHeaderCell>
              <TableHeaderCell
                className="bg-background-app w-32"
                sortDirection={
                  orderByField === 'staffed_shift.invoicingStatus'
                    ? orderByDirection
                    : undefined
                }
                onClick={sort('staffed_shift.invoicingStatus')}
              >
                Status
              </TableHeaderCell>
              <TableHeaderCell
                className="bg-background-app w-64"
                sortDirection={
                  orderByField === 'tender.firstName'
                    ? orderByDirection
                    : undefined
                }
                onClick={sort('tender.firstName')}
              >
                Tender
              </TableHeaderCell>
              <TableHeaderCell
                className="bg-background-app w-36"
                sortDirection={
                  orderByField === 'position.name'
                    ? orderByDirection
                    : undefined
                }
                onClick={sort('position.name')}
              >
                <p className="inline w-28">Position</p>
              </TableHeaderCell>
              <TableHeaderCell
                className="bg-background-app w-28"
                sortDirection={
                  orderByField === 'staffed_shift.actualWorkedHours'
                    ? orderByDirection
                    : undefined
                }
                onClick={sort('staffed_shift.actualWorkedHours')}
              >
                <p className="inline w-24">
                  Worked
                  <br />
                  Hours
                </p>
              </TableHeaderCell>
              <TableHeaderCell
                className="bg-background-app w-28"
                sortDirection={
                  orderByField === 'shift.tipAmount'
                    ? orderByDirection
                    : undefined
                }
                onClick={sort('shift.tipAmount')}
              >
                <p className="inline w-16">Tip</p>
              </TableHeaderCell>
              <TableHeaderCell
                className="bg-background-app w-28"
                sortDirection={
                  orderByField === 'staffed_shift.tendAdjustment'
                    ? orderByDirection
                    : undefined
                }
                onClick={sort('staffed_shift.tendAdjustment')}
              >
                <p className="inline w-32">
                  Tend
                  <br />
                  Adjustment
                </p>
              </TableHeaderCell>
              <TableHeaderCell
                className="bg-background-app w-28"
                sortDirection={
                  orderByField === 'shift.rate' ? orderByDirection : undefined
                }
                onClick={sort('shift.rate')}
              >
                <p className="inline w-20">
                  Client
                  <br />
                  Rate
                </p>
              </TableHeaderCell>
              <TableHeaderCell className="bg-background-app w-28">
                Total Cost
              </TableHeaderCell>
            </tr>
          </thead>
          <tbody className="bg-white">
            {data?.invoiceStaffings.items.map((staffing, i) => (
              <tr key={i} className="border-support-line h-14 border-b">
                <SimpleCell className="sticky left-0">
                  <input
                    type="checkbox"
                    title={`select ${staffing.tenderEmail}`}
                    className="text-brand-50 border-ink-dark checked:border-ink-dark h-6 w-6 rounded-md border focus:outline-none focus:ring-0"
                    checked={isStaffingChecked(staffing.id)}
                    onChange={() => onFlipStaffing(staffing.id)}
                  />
                </SimpleCell>
                <CompoundCell
                  label={format(
                    utcToZonedTime(
                      new Date(staffing.dateTime),
                      staffing.jobTimezone || '',
                    ),
                    'MM-dd-yyyy',
                  )}
                  detail={staffing.jobTimezone || ''}
                  className="sticky left-12"
                />
                <CompoundCell
                  label={staffing.jobName || ''}
                  detail={staffing.jobAddress || ''}
                  className="sticky left-48 max-w-sm"
                />
                <CompoundCell
                  label={staffing.client?.id || 'No client Assigned'}
                  detail={staffing.client?.email || '-'}
                  className={`sticky left-96 ${
                    showStickyBorder ? styles.rightbordershadow : ''
                  }`}
                />
                <SimpleCell>
                  <Status status={staffing.invoicingStatus} />
                </SimpleCell>
                <CompoundCell
                  label={staffing.tenderName}
                  detail={staffing.tenderEmail || '-'}
                  imageURL={staffing.tenderAvatarURL || undefined}
                />
                <SimpleCell>{staffing.positionName}</SimpleCell>
                <SimpleCell>
                  {staffing.workedHours} hour
                  {staffing.workedHours !== 1 ? 's' : '-'}
                </SimpleCell>
                <SimpleCell>
                  {staffing.tip !== null ? formatMoney(staffing.tip) : '-'}
                </SimpleCell>
                <SimpleCell>
                  {staffing.tendAdjustment
                    ? formatMoney(staffing.tendAdjustment)
                    : '-'}
                </SimpleCell>
                <SimpleCell>
                  {staffing.clientRate ? formatMoney(staffing.clientRate) : '-'}
                </SimpleCell>
                <SimpleCell>
                  {typeof staffing.clientTotal === 'number'
                    ? formatMoney(staffing.clientTotal)
                    : '-'}
                </SimpleCell>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <TablePaginatorContainer>
        <div
          className={`border-support-line bg-background-surface flex w-full items-center justify-between border-t p-4 ${
            !PaginatorContainer && 'fixed bottom-0 left-0'
          }`}
          style={
            !PaginatorContainer
              ? {
                  right: scrollbarsOffset.x,
                  bottom: scrollbarsOffset.y,
                }
              : undefined
          }
        >
          <p className="text-ink-dark text-preset-6">
            Showing {showingStart} to {showingEnd} of {totalItems} results
          </p>

          <div className="mb-2">
            <Pagination
              currentPage={pageNumber}
              pageCount={data?.invoiceStaffings.meta.totalPages || 0}
              onPageChange={onPageChange}
            />
          </div>
        </div>
      </TablePaginatorContainer>
    </>
  );
};

export default InvoiceStaffingsTable;
