import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { isFuture, parseISO } from 'date-fns';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useState } from 'react';
import { ShiftStatus, TipType, VenueType } from 'src/__generated__/globalTypes';
import Check from 'src/components/Icons/Check';
import EyeOff from 'src/components/Icons/EyeOff';
import Human from 'src/components/Icons/Human';
import Pencil from 'src/components/Icons/Pencil';
import { googleMapStyles } from 'src/components/LocationInput/mapStyles';
import ShiftMoreOptionsMenu from 'src/components/ShiftMoreOptionsMenu';
import Tab from 'src/components/Tab';
import { GetJob_job as Job } from 'src/graphql/queries/__generated__/GetJob';
import MarkerIcon from 'src/images/Marker.svg';
import { formatAddress } from 'src/utils/address';
import {
  GOOGLE_MAPS_API_LIBRARIES,
  RETOOL_MESSAGING_APP_URL,
} from 'src/utils/constants';
import { calculateDateTimes } from 'src/utils/dates';
import { formatMoney } from 'src/utils/formatMoney';
import { calculateTotalRate } from 'src/utils/rates';
import { EditAttireModal } from './EditAttireModal';
import { EditJobLocationModal } from './EditLocationModal';

type Props = {
  job: Job;
};

const containerStyle = {
  width: '100%',
  height: '300px',
};

export const tipLabel = (tipType: TipType | null | undefined): string => {
  if (tipType === TipType.ALLOW_JAR) {
    return 'Allow jar';
  } else if (tipType === TipType.NO_TIP) {
    return 'No tip';
  } else if (tipType === TipType.ON_SITE) {
    return 'On site';
  }

  return 'No tip';
};
export const BookingDetailTabs: React.FC<Props> = ({ job }) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
    libraries: GOOGLE_MAPS_API_LIBRARIES,
  });

  const flags = useFlags();
  const [selectedTab, setSelectedTab] = useState<'Details' | 'Shifts'>(
    'Details',
  );
  const [editLocationModalOpen, setEditLocationModalOpen] = useState(false);
  const [selectedInstruction, setSelectedInstruction] = useState<string>();

  return (
    <div className="bg-background-surface border-support-line mt-4 border px-6 py-5 md:ml-2 md:mt-0 md:w-2/3">
      <EditJobLocationModal
        open={editLocationModalOpen}
        onClose={() => setEditLocationModalOpen(false)}
        job={job}
      />

      <ul className="inline-flex w-full px-1">
        <Tab
          onClick={() => {
            setSelectedTab('Details');
          }}
          label="Details"
          selected={selectedTab === 'Details'}
        />

        <Tab
          onClick={() => {
            setSelectedTab('Shifts');
          }}
          label="Shifts"
          selected={selectedTab === 'Shifts'}
        />
        <li className="border-support-lines w-full border-b-2" />
      </ul>
      <div className={`${selectedTab !== 'Details' && 'hidden'}`}>
        <div className="mt-8 flex items-center justify-between">
          <p className="text-preset-3 text-ink-dark font-medium">
            Location Details
          </p>
          <div
            onClick={() => setEditLocationModalOpen(true)}
            className="text-primary flex cursor-pointer items-center"
          >
            <Pencil className="h-4" />
            <p className="text-preset-5">Edit Location Details</p>
          </div>
        </div>
        {job.venue?.address && (
          <>
            <p className="text-preset-5P text-ink-dark pt-6 pb-4">
              {formatAddress(job.venue.address)}
            </p>

            {isLoaded && (
              <GoogleMap
                clickableIcons={false}
                options={{
                  streetViewControl: false,
                  fullscreenControl: false,
                  mapTypeControlOptions: {
                    mapTypeIds: [],
                  },
                  styles: googleMapStyles,
                  scrollwheel: false,
                }}
                mapContainerStyle={containerStyle}
                center={{
                  lat: job.venue.address.lat,
                  lng: job.venue.address.lng,
                }}
                zoom={15}
              >
                <Marker
                  icon={MarkerIcon}
                  position={{
                    lat: job.venue.address.lat,
                    lng: job.venue.address.lng,
                  }}
                />
              </GoogleMap>
            )}
          </>
        )}
        <div className="mt-8 flex items-center">
          {job.mealProvided && (
            <>
              <Check className="text-primary w-5" />
              <p className="preset-5 text-ink-dark pl-2 font-medium">
                Staff meal provided
              </p>
            </>
          )}
          {job.useBackDoor && (
            <>
              <Check className="text-primary ml-4 w-5 " />
              <p className="preset-5 text-ink-dark pl-2 font-medium">
                Use back door
              </p>
            </>
          )}
        </div>
        {job.venue?.type === VenueType.COMMERCIAL && (
          <>
            <p className="text-preset-5 text-ink-dark mt-6 mb-4 font-medium">
              Venue name
            </p>
            <p className="text-preset-5P text-ink-dark">{job.venue?.name}</p>
          </>
        )}
        {job.venue?.otherInstructions && (
          <div>
            <p className="text-preset-5 text-ink-dark mt-6 mb-4 font-medium">
              Other Location Details
            </p>
            <p className="text-preset-5P text-ink-dark whitespace-pre-line">
              {job.venue?.otherInstructions}
            </p>
          </div>
        )}
        <p className="text-preset-5 text-ink-dark mt-6 mb-4 font-medium">
          Parking
        </p>
        <ul className="ml-4 list-disc">
          <li className="text-preset-5P text-ink-dark">
            {job.venue?.parking.label}
          </li>
          <li className="text-preset-5P text-ink-dark">
            {job.venue?.parkingInstructions}
          </li>
        </ul>
        <div>
          <p className="text-preset-5 text-ink-dark mt-6 mb-4 font-medium">
            Location Contact
          </p>
          <p className="text-preset-5P text-ink-dark">
            {job.locationContact?.name}, {job.locationContact?.phoneNumber}
          </p>
        </div>
      </div>

      <div className={`${selectedTab !== 'Shifts' && 'hidden'}`}>
        <p className="text-preset-3 text-ink-dark my-6 font-medium">Shifts</p>
        {job.shifts?.map((shift) => {
          const isCancelledShift = shift.status === ShiftStatus.CANCELLED;
          return (
            <div
              key={shift.id}
              className="border-support-lines mb-6 rounded border-2 p-4"
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  {shift.hiddenFromOpenShifts && <EyeOff className="mr-4" />}

                  <div
                    className={`${
                      flags.canceledOrdersFilter && isCancelledShift
                        ? 'bg-status-destructive'
                        : 'bg-ink-dark'
                    } flex items-center rounded-full px-2`}
                  >
                    <Human className="text-ink-clear mr-1 h-4" />
                    <p className="text-ink-clear bold">
                      {shift.staffedSlots}/{shift.quantity}
                    </p>
                  </div>

                  <p className="text-preset-4 text-ink-dark ml-4 font-medium">
                    {shift.quantity} {shift.position.name}
                    {shift.quantity > 1 ? 's' : ''}
                  </p>

                  {flags.canceledOrdersFilter && isCancelledShift && (
                    <span className="bg-status-destructive text-preset-6 ml-4 rounded-full px-2 py-1 font-bold text-white">
                      Canceled
                    </span>
                  )}
                </div>
                {isFuture(new Date(shift.endDateTime)) && (
                  <ShiftMoreOptionsMenu
                    shift={shift}
                    jobId={job.id}
                    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
                    timezone={job.venue!.address.timezone}
                  />
                )}
              </div>
              <div className="m-1 flex items-center">
                <div>
                  <a
                    className="text-primary text-preset-6 hover:bg-primary-light hover:text-brand-50 m1 rounded px-4 py-1.5"
                    href={`${RETOOL_MESSAGING_APP_URL}#jobId=&shiftId=${shift.id}`}
                    target="_blank"
                    rel="external noreferrer"
                  >
                    Message tenders in shift
                  </a>
                </div>
                <div
                  className={`text-preset-6 m1 rounded px-4 py-1.5 ${
                    shift.hasAutoApprove
                      ? 'text-primary'
                      : 'text-ink-not-as-dark'
                  }`}
                >
                  Auto-approve {shift.hasAutoApprove ? 'on' : 'off'}
                </div>
              </div>
              <p className="text-preset-6 text-ink-not-as-dark my-3">
                {calculateDateTimes(
                  shift.startDateTime,
                  shift.endDateTime,
                  job.venue?.address.timezone,
                  false,
                )}{' '}
                ({shift.unpaidBreakMinutes} min break)
              </p>
              <div className="border-support-lines flex items-center justify-between border-b py-4">
                <p className="text-preset-6 text-ink-not-as-dark">
                  Client Rate
                </p>
                <p className="text-preset-6 text-ink-dark">
                  {formatMoney(shift.rate)}
                </p>
              </div>
              <div className="border-support-lines flex items-center justify-between border-b py-4">
                <p className="text-preset-6 text-ink-not-as-dark">
                  Tender Payment
                </p>
                <p className="text-preset-6 text-ink-dark">
                  {formatMoney(shift.rate - shift.margin)}
                </p>
              </div>
              <div className="border-support-lines flex items-center justify-between border-b py-4">
                <p className="text-preset-6 text-ink-not-as-dark">Tip</p>
                <p className="text-preset-6 text-ink-dark">
                  {shift.tip?.type === TipType.INCLUDE_TIP
                    ? formatMoney(shift.tip.amount || 0)
                    : tipLabel(shift.tip?.type)}
                </p>
              </div>
              <div className="flex items-center justify-between pt-4">
                <p className="text-preset-4 text-ink-dark font-medium">Total</p>
                <p className="text-preset-6 text-ink-dark font-medium">
                  {formatMoney(
                    calculateTotalRate(
                      shift.rate,
                      parseISO(shift.startDateTime),
                      parseISO(shift.endDateTime),
                      shift.unpaidBreakMinutes,
                      shift.quantity,
                      shift.tip?.type === TipType.INCLUDE_TIP
                        ? shift.tip?.amount
                        : null,
                    ),
                  )}
                </p>
              </div>
            </div>
          );
        })}
        <p className="text-preset-3 text-ink-dark my-6 font-medium">Attires</p>
        {job.instructions?.map((instruction) => (
          <div
            key={instruction.id}
            className="border-support-lines mb-6 rounded border-2 p-4"
          >
            <EditAttireModal
              open={selectedInstruction === instruction.id}
              onClose={() => setSelectedInstruction(undefined)}
              instruction={instruction}
              jobId={job.id}
            />
            <div className="flex items-center justify-between">
              <p className="text-preset-4 text-ink-dark font-medium">
                {instruction.position.name}
              </p>
              <div
                onClick={() => {
                  setSelectedInstruction(instruction.id);
                }}
                className="text-primary flex cursor-pointer items-center"
              >
                <Pencil className="h-4" />
                <p>Edit Attire</p>
              </div>
            </div>
            <ul className="ml-4 mt-4 list-disc">
              <li className="text-preset-5P text-ink-dark whitespace-pre-line">
                {instruction.attire}
              </li>
              {instruction.blackApron && (
                <li className="text-preset-5P text-ink-dark">Black Apron</li>
              )}
              {instruction.blackNonSlipShoes && (
                <li className="text-preset-5P text-ink-dark">
                  Black non-slip shoes
                </li>
              )}
              {instruction.barKit && (
                <li className="text-preset-5P text-ink-dark">Bar Kit</li>
              )}
              {instruction.knifeKit && (
                <li className="text-preset-5P text-ink-dark">Knife Kit</li>
              )}
              {instruction.maskRequired && (
                <li className="text-preset-5P text-ink-dark">Mask Required</li>
              )}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};
