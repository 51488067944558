import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  CreateSuperRegion,
  CreateSuperRegionVariables,
} from './__generated__/CreateSuperRegion';

const CREATE_SUPER_REGION = gql`
  mutation CreateSuperRegion($input: SuperRegionInput!) {
    createSuperRegion(input: $input) {
      id
      name
      regions {
        name
        superRegion {
          id
        }
      }
    }
  }
`;

export const useCreateSuperRegionMutation = (
  options?: MutationHookOptions<CreateSuperRegion, CreateSuperRegionVariables>,
) =>
  useMutation<CreateSuperRegion, CreateSuperRegionVariables>(
    CREATE_SUPER_REGION,
    options,
  );

export default CREATE_SUPER_REGION;
