import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  BulkCreateRosterDocuments,
  BulkCreateRosterDocumentsVariables,
} from './__generated__/BulkCreateRosterDocuments';

const CREATE_ROSTER_DOCUMENT_IN_BULK = gql`
  mutation BulkCreateRosterDocuments($rosterIds: [ID!]!) {
    bulkCreateRosterDocuments(rosterIds: $rosterIds) {
      success
    }
  }
`;

export const useBulkCreateRosterDocumentsMutation = (
  options?: MutationHookOptions<
    BulkCreateRosterDocuments,
    BulkCreateRosterDocumentsVariables
  >,
) =>
  useMutation<BulkCreateRosterDocuments, BulkCreateRosterDocumentsVariables>(
    CREATE_ROSTER_DOCUMENT_IN_BULK,
    options,
  );

export default CREATE_ROSTER_DOCUMENT_IN_BULK;
