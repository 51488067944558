import React from 'react';

const Check: React.FC<{
  className: string;
  displayBgCircle?: boolean;
  circleColor?: string;
}> = ({ className, displayBgCircle = true, circleColor = '#E7EEED' }) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    {displayBgCircle && (
      <circle id="Ellipse 3" cx="12" cy="12" r="12" fill={circleColor} />
    )}
    <path
      id="Vector"
      d="M17.687 6.14449C17.5254 6.0286 17.3243 5.98167 17.1281 6.01402C16.9318 6.04637 16.7564 6.15535 16.6405 6.31699L9.62305 16.101L7.28056 13.7565C7.21093 13.6868 7.12825 13.6315 7.03726 13.5938C6.94627 13.5561 6.84874 13.5367 6.75024 13.5367C6.65174 13.5366 6.5542 13.556 6.46319 13.5937C6.37218 13.6314 6.28948 13.6866 6.21981 13.7562C6.15015 13.8259 6.09488 13.9085 6.05716 13.9995C6.01945 14.0905 6.00002 14.188 6 14.2865C5.99998 14.385 6.01935 14.4826 6.05703 14.5736C6.0947 14.6646 6.14993 14.7473 6.21956 14.817L9.18706 17.784C9.26497 17.8589 9.35793 17.9165 9.45977 17.9528C9.56162 17.9891 9.67002 18.0033 9.77778 17.9945C9.88555 17.9857 9.99021 17.9541 10.0848 17.9018C10.1794 17.8494 10.2618 17.7776 10.3266 17.691L17.8595 7.19099C17.9754 7.02934 18.0224 6.82827 17.99 6.63202C17.9577 6.43576 17.8487 6.2604 17.687 6.14449V6.14449Z"
      fill="currentColor"
    />
  </svg>
);

export default Check;
