import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { REQUEST_PASSWORD_RESET_ROUTE } from 'src/routes';
import {
  formLabelClass,
  formInputClass,
  primaryButtonClass,
} from 'src/components/Forms';
import AuthScreenShell from 'src/components/AuthScreenShell';
import {
  serviceOutageMessage,
  unexpectedErrorMessage,
} from 'src/utils/errorDisplay';

type FormValues = {
  email: string;
};

const formSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email'),
});

const Form: React.FC = () => {
  const [submitError, setSubmitError] = useState(false);
  const [submitErrorMessage, setSubmitErrorMessage] = useState('');
  const [requested, setRequested] = useState(false);
  const { register, errors, handleSubmit, formState } = useForm<FormValues>({
    mode: 'onBlur',
    resolver: yupResolver(formSchema),
  });

  const onSubmit = async (values: FormValues) => {
    setSubmitError(false);
    try {
      const rawResponse = await fetch(REQUEST_PASSWORD_RESET_ROUTE, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });
      if (rawResponse.ok) {
        setRequested(true);
      } else {
        console.error(rawResponse);
        switch (rawResponse.status) {
          case 502: {
            setSubmitError(true);
            setSubmitErrorMessage(serviceOutageMessage);
            break;
          }
          default: {
            setSubmitError(true);
            setSubmitErrorMessage(unexpectedErrorMessage);
            break;
          }
        }
      }
    } catch (e) {
      console.error(e);
      setSubmitError(true);
      setSubmitErrorMessage(unexpectedErrorMessage);
    }
  };

  return requested ? (
    <div className="mt-6">
      <div>
        If the email address you provided has a registered account in our
        system, that email address will shortly receive a message with
        instructions on how to reset the account's password associated with it.
      </div>
      <NavLink
        to="/signin"
        className="text-primary my-8 block rounded-md bg-white py-2"
      >
        Go back to Sign in
      </NavLink>
    </div>
  ) : (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      <div>
        <label htmlFor="email" className={formLabelClass}>
          <span>Email address</span>
          {errors.email && (
            <span className="text-preset-5 ml-4 h-12 text-red-700">
              {errors.email.message}
            </span>
          )}
        </label>
        <div className="mt-1">
          <input
            ref={register}
            tabIndex={2}
            id="email"
            name="email"
            type="email"
            required
            className={formInputClass}
          />
        </div>
      </div>

      <div>
        <button
          tabIndex={4}
          type="submit"
          className={primaryButtonClass}
          disabled={formState.isSubmitting}
        >
          Request password reset
        </button>
      </div>

      <div className="text-preset-5 block h-12 text-red-700">
        {submitError && submitErrorMessage}
      </div>
    </form>
  );
};

const RequestPasswordResetView: React.FC = () => (
  <AuthScreenShell label="Admin">
    <div>
      <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
        Password reset
      </h2>
    </div>

    <div className="mt-8">
      <div className="mt-6">
        <Form />
      </div>
    </div>
  </AuthScreenShell>
);

export default RequestPasswordResetView;
