import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { GetAdmins, GetAdminsVariables } from './__generated__/GetAdmins';

// TODO: Is there a way to import this type definition from the backend? (backend/src/entities/admin.entity.ts:12)
export enum AdminRole {
  BUSINESS = 'BUSINESS',
  DEV = 'DEV',
  OTHER = 'OTHER',
}

const GET_ADMINS_QUERY = gql`
  query GetAdmins(
    $paginationOptions: PaginationOptions!
    $queryOptions: GetAdminsQueryOptions
  ) {
    getAdmins(
      paginationOptions: $paginationOptions
      queryOptions: $queryOptions
    ) {
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
      items {
        id
        firstName
        lastName
        email
        role
        clients {
          client {
            id
            firstName
            lastName
            email
          }
          region {
            id
            name
          }
        }
      }
    }
  }
`;

export const useGetAdminsQuery = (
  options?: QueryHookOptions<GetAdmins, GetAdminsVariables>,
) => useQuery<GetAdmins, GetAdminsVariables>(GET_ADMINS_QUERY, options);

export default GET_ADMINS_QUERY;
