import React from 'react';
import { Route, RouteProps } from 'react-router';
import usePageTitle from 'src/hooks/pageTitle';

const ViewRoute: React.FC<RouteProps & { title: string }> = ({
  children,
  title,
  ...rest
}) => {
  usePageTitle(title);

  return <Route {...rest}>{children}</Route>;
};

export default ViewRoute;
