import React, { useCallback, useState } from 'react';
import Modal from 'src/components/Modal';
import LocationInput from 'src/components/LocationInput/LocationInput';
import { formInputClass } from 'src/components/Forms';
import Pencil from 'src/components/Icons/Pencil';
import { getAddress, Address } from 'src/utils/address';
import { AdminTenderDetails_tender_address as TenderAddress } from 'src/graphql/queries/__generated__/AdminTenderDetails';
import { useAdminUpdateTenderAddressMutation } from 'src/graphql/mutations/UpdateTenderAddress';

const getFullAddress = (address: TenderAddress | null): string => {
  if (!address) {
    return '';
  }
  const { city, neighborhood, state, streetName, streetNumber, zip } = address;
  return [
    [streetNumber, streetName].filter((chunk) => !!chunk).join(' '),
    neighborhood,
    city,
    state,
    zip,
  ]
    .filter((chunk) => !!chunk)
    .join(', ');
};

type MarkerType = {
  lat: number;
  lng: number;
};
const containerStyle = {
  width: '100%',
  height: '300px',
};

const EditAddressModal: React.FC<{
  address: TenderAddress | null;
  tenderId: string;
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}> = ({ address, open, onClose, tenderId, onSubmit }) => {
  const [marker, setMarker] = useState<MarkerType | undefined>(
    address?.lat && address?.lng
      ? {
          lng: address.lng,
          lat: address.lat,
        }
      : undefined,
  );
  const [newAddress, setNewAddress] = useState<Address | undefined>(undefined);
  const [interiorNumber, setInteriorNumber] = useState<string>(
    address?.interiorNumber || '',
  );
  const [updateTenderAddress] = useAdminUpdateTenderAddressMutation();

  const handleSubmit = async () => {
    if (!newAddress?.lat || !newAddress?.lng) {
      return;
    }
    await updateTenderAddress({
      variables: {
        input: {
          ...newAddress,
          lat: newAddress.lat,
          lng: newAddress.lng,
          interiorNumber: interiorNumber,
        },
        tenderId,
      },
    });
    onSubmit();
    onClose();
  };

  const handleLocationChange = useCallback(
    (item: google.maps.places.PlaceResult) => {
      setNewAddress(getAddress(item));
    },
    [],
  );

  const handleMarkerChange = useCallback(
    (newMarker: MarkerType) => {
      setMarker(newMarker);
    },
    [setMarker],
  );

  const canSubmit =
    newAddress?.lat !== address?.lat ||
    newAddress?.lng !== address?.lng ||
    interiorNumber !== address?.interiorNumber;

  return (
    <Modal open={open} onClose={onClose} className="p-4">
      <p className="text-preset-3 text-ink-dark font-medium">Edit Address</p>
      <p className="text-preset-5P text-ink-dark mt-10 mb-4">
        Search for the Tender Address and select the correct one on the list
      </p>

      <div className="w-full">
        <LocationInput
          marker={marker}
          clearText=""
          onMarkerChange={handleMarkerChange}
          onLocationChange={handleLocationChange}
          containerStyle={containerStyle}
        />
      </div>

      <p className="text-ink-dark text-preset-6 mt-6 mb-4 font-medium">
        Apartment / Suite #
      </p>
      <input
        defaultValue={address?.interiorNumber || ''}
        className={formInputClass}
        onChange={(event) => setInteriorNumber(event.currentTarget.value)}
      />
      <div className="border-ink-dark-200 -mx-4 mt-6 h-1 border-t border-solid" />
      <div className="text-ink-dark flex justify-end py-2">
        <button
          className="border-ink-dark rounded-md border py-2 px-4"
          onClick={onClose}
        >
          Cancel
        </button>
        <button
          onClick={handleSubmit}
          disabled={!canSubmit}
          className="bg-primary text-ink-clear ml-4 rounded-md py-2 px-4 disabled:opacity-50"
        >
          Save Address
        </button>
      </div>
    </Modal>
  );
};

const AddressCard: React.FC<{
  address: TenderAddress | null;
  tenderId: string;
  refetch: () => void;
}> = ({ address, tenderId, refetch }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="bg-background-surface rounded">
      <EditAddressModal
        tenderId={tenderId}
        address={address}
        open={open}
        onSubmit={refetch}
        onClose={() => setOpen(false)}
      />
      <div className="mb-4 px-4 pt-6 pb-2">
        <div className="flex justify-between">
          <p className="text-preset-3 text-ink-dark mb-4 font-medium">
            Address
          </p>
          <div
            className="mt-2 flex cursor-pointer items-start"
            onClick={() => setOpen(true)}
          >
            <Pencil className="text-ink-primary h-4" />
            <p className="text-preset-6 text-ink-primary">Edit</p>
          </div>
        </div>
        {address ? (
          <>
            <div className="border-support-lines border-b">
              <p className="text-preset-6 text-ink-not-as-dark my-4 flex">
                {getFullAddress(address)}
              </p>
            </div>
            <div>
              <p className="text-preset-6 text-ink-not-as-dark my-4 flex">
                {['Apt / Suite #:', address?.interiorNumber || 'N/A'].join(' ')}
              </p>
            </div>
          </>
        ) : (
          <p className="text-preset-6 text-ink-not-as-dark my-4 flex">
            No address added yet
          </p>
        )}
      </div>
    </div>
  );
};

export default AddressCard;
