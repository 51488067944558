import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns-tz';
import pluralize from 'pluralize';

import { useSuperRegionsQuery } from 'src/graphql/queries/SuperRegions';
import Loading from 'src/components/Loading';
import Copy from 'src/components/Icons/Copy';

import { SuperRegions_superRegions } from 'src/graphql/queries/__generated__/SuperRegions';
import StaffingRegionsManageModal from './StaffingRegions.ManageModal';

const StaffingRegionsView: React.FC = () => {
  const {
    data: superRegions,
    loading,
    refetch,
  } = useSuperRegionsQuery({
    fetchPolicy: 'network-only',
  });

  const { push } = useHistory();
  const [modalOpen, setModalOpen] = useState(false);
  const [currentStaffingRegion, setCurrentStaffingRegion] =
    useState<SuperRegions_superRegions | null>(null);

  const totalSuperRegions = superRegions?.superRegions.length || 0;

  const handleManageSuccess = () => {
    setModalOpen(false);
    setCurrentStaffingRegion(null);
    refetch();
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setCurrentStaffingRegion(null);
  };

  return (
    <div className="text-align-right bg-background-app min-h-full min-w-full object-center pt-5 pb-8 sm:px-6 lg:px-8">
      <div
        className="bg-background-surface border-support-line mx-auto mb-6 flex max-w-screen-lg items-center justify-between rounded-md border px-4 py-5"
        data-cy="staffing-regions-header"
      >
        <p className="text-preset-3 text-ink-dark">
          {pluralize('Staffing Region', totalSuperRegions, true)}
        </p>

        <button
          className="text-preset-6 bg-primary text-ink-clear hover:bg-primary-active h-10 rounded px-5 font-medium"
          data-cy="staffing-regions-create-button"
          onClick={() => {
            setModalOpen(true);
          }}
        >
          Create Staffing Region
        </button>

        <StaffingRegionsManageModal
          isOpen={modalOpen}
          onClose={handleCloseModal}
          onSuccess={handleManageSuccess}
          staffingRegion={currentStaffingRegion}
        />
      </div>

      <div className="text-align-left w-100% mx-auto mt-5 max-w-screen-lg">
        {loading ? (
          <Loading />
        ) : !superRegions?.superRegions.length ? (
          <div className="rounded-lg bg-white py-14 px-8">
            <h1 className="text-preset-3 text-ink-not-as-dark text-center">
              There are no Staffing Regions
            </h1>
            <p className="text-ink-not-as-dark text-preset-5P mt-4 text-center">
              Your Staffing Regions will be shown here after they’re created.
              <br />
              Please proceed to create your first Staffing Region.
            </p>
          </div>
        ) : (
          <ul>
            {superRegions?.superRegions.map((superRegion, index) => {
              const isLast = index + 1 === superRegions.superRegions.length;
              return (
                <li
                  className={`border-support-line bg-background-surface flex flex-col justify-between rounded-t-lg border shadow-sm ${
                    !isLast && 'mb-6'
                  }`}
                  key={`${superRegion.id}`}
                >
                  <div className="px-4 pt-6 pb-4">
                    <div className="flex flex-1 items-center align-middle">
                      <div className="text-preset-4 text-ink-dark font-medium">
                        <span className="capitalize">{superRegion.name}</span>
                      </div>

                      <span className="bg-background-app ml-2 inline-flex rounded px-2 py-1">
                        {superRegion.id}
                        <span
                          onClick={async (ev) => {
                            ev.stopPropagation();
                            await navigator.clipboard.writeText(superRegion.id);
                          }}
                        >
                          <Copy className="ml-2 inline cursor-pointer opacity-80 hover:opacity-100 active:opacity-20" />
                        </span>
                      </span>

                      <button
                        type="button"
                        className="border-ink-dark text-preset-6 text-ink-dark bg-background-surface hover:text-ink-not-as-dark hover:border-ink-not-as-dark ml-auto items-center rounded-md border px-2.5 py-2 font-normal focus:outline-none"
                        onClick={(evt) => {
                          setCurrentStaffingRegion(superRegion);
                          setModalOpen(true);
                        }}
                        data-cy="apply-for-tender-button"
                      >
                        Manage Staffing Region
                      </button>
                    </div>

                    <div className="text-preset-6P text-ink-not-as-dark mt-4">
                      {pluralize('Regions', superRegion.regions.length, true)} •
                      Last edited{' '}
                      {format(
                        new Date(superRegion.updatedAt),
                        'MMMM dd, y hh:mmaaa',
                      )}
                    </div>
                  </div>

                  <div>
                    <table className="border-support-line min-w-full border-b">
                      <thead className="bg-background-app">
                        <tr>
                          <th className="text-preset-7 text-ink-not-as-dark border-t-support-line w-4/5 select-none whitespace-nowrap border-t px-4 py-3 text-left font-medium uppercase tracking-wider">
                            Region
                          </th>

                          <th className="text-preset-7 text-ink-not-as-dark border-t-support-line w-4/5 select-none whitespace-nowrap border-t px-4 py-3 text-left font-medium uppercase tracking-wider">
                            Actions
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {superRegion?.regions.length ? (
                          superRegion?.regions.map((region) => (
                            <tr key={region.id}>
                              <td
                                className={
                                  'text-dark text-preset-6 bg-background-surface border-b-support-line border-b px-4 py-4'
                                }
                              >
                                {region.name}
                              </td>

                              <td className="text-dark text-preset-6 bg-background-surface border-b-support-line border-b px-4 py-4">
                                <button
                                  type="button"
                                  className="text-primary text-preset-6 hover:bg-primary-light hover:text-brand-50 rounded px-4 py-1.5"
                                  onClick={() => {
                                    push(`region/${region.id}`);
                                  }}
                                >
                                  See Region Details
                                </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              className={
                                'text-dark text-preset-6 bg-background-surface border-b-support-line text-ink-not-as-dark border-b px-4 py-4 text-center'
                              }
                              colSpan={2}
                            >
                              No regions assigned
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
};

export default StaffingRegionsView;
