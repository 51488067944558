import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { DayPickerRangeController, FocusedInputShape } from 'react-dates';
import { usePopper } from 'react-popper';
import { default as moment, Moment } from 'moment';
import ChevronDown from 'src/components/Icons/ChevronDown';
import ChevronLeft from 'src/images/chevron-left.svg';
import ChevronRight from 'src/images/chevron-right.svg';
import './DateRangeInput.css';

type NullableDate = Moment | null;

interface Props {
  startDate?: Date;
  endDate?: Date;
  onChange?: (startDate: Date | undefined, endDate: Date | undefined) => void;
  className?: string;
  allowAnyDate?: boolean;
}

const isOutsideRange = () => false;

type InputProps = {
  placeholder: string;
  onFocus: () => void;
  date?: Moment | null;
  isFocused: boolean;
};

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ placeholder, date, isFocused, onFocus }, ref) => {
    return (
      <input
        readOnly
        ref={ref}
        className={`h-full w-24 border-b-4 pt-1 text-center text-base outline-none ${
          isFocused
            ? 'text-brand-50 border-brand-50'
            : 'text-ink-dark border-background-surface'
        }`}
        placeholder={placeholder}
        onFocus={onFocus}
        value={date?.format('MM/DD/YYYY') ?? ''}
      />
    );
  },
);

const DateRangeInput: FC<Props> = ({
  startDate,
  endDate,
  onChange,
  className = '',
  allowAnyDate = false,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
    null,
  );
  const [internalStartDate, setInternalStartDate] = useState<NullableDate>(
    startDate ? moment(startDate) : null,
  );
  const [internalEndDate, setInternalEndDate] = useState<NullableDate>(
    endDate ? moment(endDate) : null,
  );
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );
  const { styles, attributes } = usePopper(referenceElement, popperElement);
  const popperElementRef = useRef<HTMLDivElement>(null);
  const startDateInputRef = useRef<HTMLInputElement>(null);
  const endDateInputRef = useRef<HTMLInputElement>(null);
  const focusedClass = focusedInput ? `${focusedInput}-focused` : '';

  const openPopover = (inputToFocus?: FocusedInputShape) => {
    setIsPopoverOpen(true);
    setFocusedInput(inputToFocus || 'startDate');
  };

  const closePopover = () => {
    setIsPopoverOpen(false);
    setFocusedInput(null);
    startDateInputRef.current?.blur();
    endDateInputRef.current?.blur();
  };

  const handleResetDate = () => {
    const startOfToday = moment().startOf('day');
    const endOfToday = moment().endOf('day');
    setInternalStartDate(startOfToday);
    setInternalEndDate(endOfToday);
    onChange?.(startOfToday.toDate(), endOfToday.toDate());
    closePopover();
  };

  const handleAbortChanges = useCallback(
    (shouldClosePopover = true) => {
      setInternalStartDate(startDate ? moment(startDate) : null);
      setInternalEndDate(endDate ? moment(endDate) : null);
      shouldClosePopover && closePopover();
    },
    [startDate, endDate],
  );

  const handleSubmit = () => {
    onChange?.(internalStartDate?.toDate(), internalEndDate?.toDate());
    closePopover();
  };

  useEffect(() => {
    setInternalStartDate(startDate ? moment(startDate) : null);
    setInternalEndDate(endDate ? moment(endDate) : null);
  }, [startDate, endDate]);

  useEffect(() => {
    const closePanel = (evt: MouseEvent) => {
      if (
        !isPopoverOpen ||
        referenceElement?.contains(evt.target as HTMLElement) ||
        popperElementRef.current?.contains(evt.target as HTMLElement)
      ) {
        return;
      }

      handleAbortChanges();
    };

    window.addEventListener('mousedown', closePanel);

    return () => {
      window.removeEventListener('mousedown', closePanel);
    };
  }, [handleAbortChanges, isPopoverOpen, referenceElement, popperElementRef]);

  const initialVisibleMonth = () =>
    internalStartDate || moment().subtract(1, 'month');

  return (
    <div className={`${focusedClass} ${className}`}>
      <div
        ref={popperElementRef}
        tabIndex={0}
        onKeyDown={useCallback(
          (evt) => {
            if (evt.key === 'Escape' && isPopoverOpen) {
              handleAbortChanges();
            }
          },
          [handleAbortChanges, isPopoverOpen],
        )}
      >
        <div
          className="border-support-line-darker flex h-11 flex-shrink flex-grow items-center rounded border pl-1 pr-3 text-base"
          ref={setReferenceElement}
        >
          {internalEndDate || internalStartDate ? (
            <>
              <Input
                ref={startDateInputRef}
                placeholder="Start Date"
                isFocused={focusedInput === 'startDate'}
                onFocus={() => openPopover('startDate')}
                date={internalStartDate}
              />
              <span
                className="text-ink-not-as-dark text-sm"
                onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                  isPopoverOpen && event.stopPropagation();
                }}
              >
                →
              </span>
              <Input
                ref={endDateInputRef}
                placeholder="End Date"
                isFocused={focusedInput === 'endDate'}
                onFocus={() => openPopover('endDate')}
                date={internalEndDate}
              />
            </>
          ) : (
            <div className="w-48 p-2" onClick={() => openPopover()}>
              Any Date
            </div>
          )}
          <span
            className="flex h-full items-center pl-1"
            onClick={() =>
              !isPopoverOpen ? openPopover('startDate') : handleAbortChanges()
            }
          >
            <ChevronDown className="text-ink-dark h-3" />
          </span>
        </div>
        {isPopoverOpen && (
          <div
            className="bg-background-surface shadow-floating-menu z-10 rounded"
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            {allowAnyDate && (
              <div className="border-support-line-darker text-ink-dark m-4 flex border">
                <label
                  className="flex flex-1 items-center border-l p-4"
                  htmlFor="selectDateInput"
                >
                  <input
                    readOnly
                    id="selectDateInput"
                    className="text-brand-tend mr-3"
                    title="Select Date Range"
                    type="radio"
                    name="anyDate"
                    onClick={() => {
                      if (focusedInput) {
                        return;
                      }
                      handleAbortChanges(false);
                      setFocusedInput('startDate');
                    }}
                    checked={
                      !!((internalStartDate && internalEndDate) || focusedInput)
                    }
                  />
                  <span className="flex-1">Select Date range</span>
                </label>
                <label
                  className="flex flex-1 items-center border-l p-4"
                  htmlFor="anyDateInput"
                >
                  <input
                    readOnly
                    id="anyDateInput"
                    className="text-brand-tend mr-3"
                    title="any Date"
                    type="radio"
                    name="anyDate"
                    onClick={() => {
                      setFocusedInput(null);
                      setInternalStartDate(null);
                      setInternalEndDate(null);
                    }}
                    checked={!internalStartDate && !internalEndDate}
                  />
                  <span className="flex-1">Any Date</span>
                </label>
              </div>
            )}
            <DayPickerRangeController
              startDate={internalStartDate}
              endDate={internalEndDate}
              onDatesChange={({ startDate: start, endDate: end }) => {
                setInternalStartDate(start);
                if (!end) {
                  setInternalEndDate(start);
                } else {
                  setInternalEndDate(end);
                }
              }}
              focusedInput={focusedInput}
              onFocusChange={setFocusedInput}
              initialVisibleMonth={initialVisibleMonth}
              noBorder
              numberOfMonths={2}
              isOutsideRange={isOutsideRange}
              daySize={40}
              minimumNights={0}
              navPrev={
                <img
                  src={ChevronLeft}
                  alt="navigate-prev-month"
                  className="absolute top-7 left-9"
                />
              }
              navNext={
                <img
                  src={ChevronRight}
                  alt="navigate-next-month"
                  className="absolute top-7 right-9"
                />
              }
              keepOpenOnDateSelect
              hideKeyboardShortcutsPanel
            />
            <div className="flex px-6 pt-5 pb-6">
              <button
                type="button"
                className="text-preset-6 text-ink-secondary hover:text-primary-active py-2 pr-3 font-medium focus:outline-none"
                onClick={() => {
                  handleResetDate();
                }}
              >
                Today
              </button>
              <div className="flex flex-grow justify-end">
                <button
                  type="button"
                  className="text-preset-6 text-ink-secondary hover:text-primary-active mr-6 px-4 py-2 font-medium focus:outline-none"
                  onClick={() => {
                    handleAbortChanges();
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="text-preset-6 bg-primary hover:bg-primary-active inline-flex items-center rounded-md border border-transparent px-4 py-2 font-medium text-white shadow-sm focus:outline-none"
                  onClick={handleSubmit}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DateRangeInput;
