import React, { ChangeEvent, FC, useState } from 'react';
import { format } from 'date-fns-tz';
import isEqual from 'lodash.isequal';
import { parse as parseCSV, ParseResult as PapaparseResult } from 'papaparse';
import Loading from 'src/components/Loading';
import Modal from 'src/components/Modal';
import TableCell from 'src/components/TableCell';
import TableHeaderCell from 'src/components/TableHeaderCell';
import ViewContainer from 'src/components/ViewContainer';
import { useBulkCreateTenderPayoutsMutation } from 'src/graphql/mutations/BulkCreateTenderPayouts';
import GET_BATCH_OPERATIONS, {
  useGetBatchOperationsQuery,
} from 'src/graphql/queries/GetBatchOperations';
import {
  GetBatchOperations_getBatchOperations_items_errors as BatchOperationError,
  GetBatchOperations_getBatchOperations_items as BatchOperation,
} from 'src/graphql/queries/__generated__/GetBatchOperations';
import Pagination from 'src/components/Pagination';

type CSVRecord = Record<string, string>;
type ParseResult = PapaparseResult<CSVRecord>;

const PAGE_SIZE = 10;
const MAX_PREVIEW_RESULTS = 10;
const OPERATION_TYPE = 'bulk_create_tender_payments';

const expectedFields = [
  'Tender ID',
  'Staffing ID',
  'Amount',
  'Bill Client',
  'Description',
  'Sent At',
];

const previewHeaderClassNames: Record<string, string> = {
  Amount: 'w-28',
  'Bill Client': 'w-28',
  'Sent At': 'w-36',
};

const ErrorsTable: FC<{ errors: BatchOperationError[] }> = ({ errors }) => (
  <div className="border-support-line mb-10 overflow-hidden rounded-lg border">
    <p className="text-preset-4 border-support-line text-ink-dark border-b-2 p-4 pt-6 font-medium">
      Error Details
    </p>
    <table className="w-full table-fixed">
      <thead className="bg-background-app">
        <tr>
          <TableHeaderCell className="w-96">Error</TableHeaderCell>
          <TableHeaderCell>Tender ID</TableHeaderCell>
          <TableHeaderCell>Staffing ID</TableHeaderCell>
          <TableHeaderCell className="w-32">Amount</TableHeaderCell>
          <TableHeaderCell className="w-32">Bill Client</TableHeaderCell>
          <TableHeaderCell>Description</TableHeaderCell>
          <TableHeaderCell>Sent At</TableHeaderCell>
        </tr>
      </thead>
      <tbody>
        {errors.map((error) => {
          const errorData: Record<string, string> = JSON.parse(error.data);
          return (
            <tr
              key={error.id}
              className="border-support-line bg-background-surface h-12 border-b"
            >
              <TableCell>
                <span title={error.error}>{error.error}</span>
              </TableCell>
              <TableCell>{errorData.tenderId}</TableCell>
              <TableCell>{errorData.staffingId}</TableCell>
              <TableCell>{errorData.amount}</TableCell>
              <TableCell>{errorData.billClient}</TableCell>
              <TableCell>{errorData.description}</TableCell>
              <TableCell>{errorData.sentAt}</TableCell>
            </tr>
          );
        })}
      </tbody>
    </table>
    <div className="flex h-12 items-center justify-between px-4">
      <p className="text-ink-dark text-preset-6">
        Showing {errors.length} errors
      </p>
    </div>
  </div>
);

const BulkBonusPayments: FC = () => {
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [parseResult, setParseResult] = useState<ParseResult>();
  const [selectedOperation, setSelectedOperation] = useState<BatchOperation>();
  const [csvFileContents, setCsvFileContents] = useState<string>();
  const [page, setPage] = useState(1);

  const [bulkCreateTenderPayouts] = useBulkCreateTenderPayoutsMutation();

  const {
    loading,
    data: newData,
    previousData,
  } = useGetBatchOperationsQuery({
    variables: {
      paginationOptions: {
        limit: PAGE_SIZE,
        page,
      },
      type: OPERATION_TYPE,
    },
  });

  const data = newData || previousData;

  const displayError = (message: string) => {
    setErrorMessage(message);
    setErrorModalOpen(true);
  };

  const handleParseResult = async (result: ParseResult, file: File) => {
    if (!isEqual(result.meta.fields, expectedFields)) {
      return displayError(
        `The CSV headers do not match, they must be ${expectedFields
          .map((field) => `"${field}"`)
          .join(', ')}, in that order.`,
      );
    }
    setParseResult(result);
    setCsvFileContents(await file.text());
    setPreviewModalOpen(true);
  };

  const fileInputOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files?.[0];
    if (!file || file.type !== 'text/csv') {
      return displayError('The selected file is not of CSV type.');
    }

    parseCSV(file, {
      complete: handleParseResult,
      error: (error) => displayError(error.message),
      skipEmptyLines: true,
      header: true,
    });

    e.target.value = '';
  };

  const onPreviewModalClose = () => {
    setPreviewModalOpen(false);
  };

  const onErrorModalClose = () => {
    setErrorModalOpen(false);
  };

  const handleSubmit = async () => {
    if (!csvFileContents) {
      return;
    }

    const { errors } = await bulkCreateTenderPayouts({
      variables: {
        fileContents: csvFileContents,
      },
      refetchQueries: [
        {
          query: GET_BATCH_OPERATIONS,
          variables: {
            paginationOptions: {
              limit: PAGE_SIZE,
              page: 1,
            },
            type: OPERATION_TYPE,
          },
        },
      ],
    });

    setPreviewModalOpen(false);

    if (errors) {
      displayError(errors.map((e) => e.message).join('<br />'));
    }
  };

  const pageInfo = data?.getBatchOperations.meta;
  let showingStart, showingEnd, totalItems;
  if (pageInfo) {
    showingStart = (pageInfo.currentPage - 1) * PAGE_SIZE + 1;
    showingEnd = showingStart + pageInfo.itemCount - 1;
    totalItems = pageInfo.totalItems;
  }

  return (
    <>
      <ViewContainer>
        <div className="py-10">
          <div className="align-start mb-8 flex justify-between">
            <h1 className="text-preset-3">Bulk Bonus Payments</h1>
            <label className="bg-primary text-ink-clear hover:bg-primary-active cursor-pointer rounded px-4 py-1.5 font-normal">
              <input
                type="file"
                onChange={fileInputOnChange}
                className="hidden"
              />
              Create Bulk Bonus Payments
            </label>
          </div>
          {loading && !data ? (
            <Loading />
          ) : data?.getBatchOperations.items.length ? (
            <>
              <div className="border-support-line mb-10 overflow-hidden rounded-lg border">
                <p className="text-preset-4 border-support-line text-ink-dark border-b-2 p-4 pt-6 font-medium">
                  Operations
                </p>
                <table className="w-full table-fixed">
                  <thead className="bg-background-app">
                    <tr>
                      <TableHeaderCell>Created At</TableHeaderCell>
                      <TableHeaderCell>Total Payments</TableHeaderCell>
                      <TableHeaderCell>Successfully processed</TableHeaderCell>
                      <TableHeaderCell>Errors</TableHeaderCell>
                    </tr>
                  </thead>
                  <tbody>
                    {data.getBatchOperations.items.map((operation) => (
                      <tr
                        key={operation.id}
                        className={`border-support-line h-12 border-b ${
                          selectedOperation?.id === operation.id
                            ? 'bg-primary-light'
                            : 'bg-background-surface hover:bg-background-app cursor-pointer'
                        }`}
                        onClick={() =>
                          setSelectedOperation(
                            operation.id !== selectedOperation?.id
                              ? operation
                              : undefined,
                          )
                        }
                      >
                        <TableCell className="bg-transparent">
                          {format(
                            new Date(operation.createdAt),
                            'yyyy-MM-dd h:mm aa zzz',
                          )}
                        </TableCell>
                        <TableCell className="bg-transparent">
                          {operation.totalOperations}
                        </TableCell>
                        <TableCell className="bg-transparent">
                          {operation.affectedRows}
                        </TableCell>
                        <TableCell className="bg-transparent">
                          {operation.totalErrors}
                        </TableCell>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="flex h-12 items-center justify-between px-4">
                  <p className="text-ink-dark text-preset-6">
                    Showing {showingStart} to {showingEnd} of {totalItems}{' '}
                    results
                  </p>
                  <div className="-translate-y-3">
                    <Pagination
                      currentPage={page}
                      pageCount={data.getBatchOperations.meta.totalPages}
                      onPageChange={(p) => setPage(p)}
                    />
                  </div>
                </div>
              </div>
              {selectedOperation &&
                (selectedOperation?.errors.length ? (
                  <ErrorsTable errors={selectedOperation.errors} />
                ) : (
                  <p className="text-ink-not-as-dark text-preset-5">
                    No errors found for the selected operation.
                  </p>
                ))}
            </>
          ) : (
            <p className="text-ink-dark text-preset-5">
              No bulk bonus payments found
            </p>
          )}
        </div>
      </ViewContainer>
      <Modal
        open={previewModalOpen}
        onClose={onPreviewModalClose}
        className="max-w-6xl p-12"
      >
        {parseResult && (
          <>
            <h4 className="text-preset-3 mb-8 font-medium">
              Create Bonus Payments
            </h4>
            <div className="border-support-line mb-10 overflow-hidden rounded-lg border">
              <p className="text-preset-4 border-support-line text-ink-dark border-b-2 p-4 pt-6 font-medium">
                Data Preview
              </p>
              <table className="w-full table-fixed">
                <thead className="bg-background-app">
                  <tr>
                    {expectedFields.map((field) => (
                      <TableHeaderCell
                        key={field}
                        className={previewHeaderClassNames[field] || ''}
                      >
                        {field}
                      </TableHeaderCell>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {parseResult.data
                    .slice(0, MAX_PREVIEW_RESULTS)
                    .map((row, i) => (
                      <tr className="border-support-line h-12 border-b" key={i}>
                        {expectedFields.map((field) => (
                          <TableCell key={row[field]}>{row[field]}</TableCell>
                        ))}
                      </tr>
                    ))}
                </tbody>
              </table>
              <p className="text-ink-dark text-preset-6 px-4 py-4">
                Showing{' '}
                {parseResult.data.length < MAX_PREVIEW_RESULTS
                  ? parseResult.data.length
                  : MAX_PREVIEW_RESULTS}{' '}
                of {parseResult.data.length} records
              </p>
            </div>
            <div className="flex justify-end space-x-4">
              <button
                className="border-ink-dark text-preset-5 hover:bg-background-app w-24 rounded-md border py-2 px-4 hover:opacity-80 focus:outline-none"
                onClick={onPreviewModalClose}
              >
                Close
              </button>
              <button
                className="border-ink-primary text-preset-5 font text-ink-clear bg-primary hover:bg-primary-active rounded-md border-2 px-4 py-1 focus:outline-none"
                onClick={handleSubmit}
              >
                Create {parseResult.data.length} Bonus Payments
              </button>
            </div>
          </>
        )}
      </Modal>
      <Modal
        open={errorModalOpen}
        onClose={onErrorModalClose}
        className="text-ink-dark max-w-lg px-6 py-8"
      >
        <h4 className="text-preset-3 mb-6 font-medium">Error</h4>
        <p className="mb-6">{errorMessage}</p>
        <div className="text-right">
          <button
            className="border-ink-dark text-preset-6P hover:bg-background-app w-24 rounded-md border py-2 px-4 hover:opacity-80 focus:outline-none"
            onClick={onErrorModalClose}
          >
            Close
          </button>
        </div>
      </Modal>
    </>
  );
};

export default BulkBonusPayments;
