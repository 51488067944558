import gql from 'graphql-tag';
import { QueryHookOptions, useQuery } from '@apollo/client';
import { Tags } from './__generated__/Tags';

const GET_TAGS = gql`
  query Tags($whitelisted: Boolean!, $searchTerm: String, $skillTags: Boolean) {
    tags(
      whitelisted: $whitelisted
      searchTerm: $searchTerm
      skillTags: $skillTags
    )
  }
`;

export const useTagsQuery = (options?: QueryHookOptions<Tags>) =>
  useQuery<Tags>(GET_TAGS, options);

export default GET_TAGS;
