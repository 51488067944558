import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  UpdateJobVenueAsAdmin,
  UpdateJobVenueAsAdminVariables,
} from './__generated__/UpdateJobVenueAsAdmin';

const UPDATE_VENUE = gql`
  mutation UpdateJobVenueAsAdmin($jobId: ID!, $input: EditJobVenueInput!) {
    updateJobVenue(jobId: $jobId, input: $input) {
      __typename
      id
      venue {
        id
        name
        type
        parkingType
        parking {
          value
          label
        }
        parkingInstructions
        otherInstructions
        address {
          streetName
          streetNumber
          interiorNumber
          neighborhood
          city
          state
          country
          zip
          lat
          lng
          timezone
        }
      }
      locationContact {
        __typename
        id
        name
        phoneNumber
      }
    }
  }
`;

export const useUpdateJobVenue = (
  options?: MutationHookOptions<
    UpdateJobVenueAsAdmin,
    UpdateJobVenueAsAdminVariables
  >,
) =>
  useMutation<UpdateJobVenueAsAdmin, UpdateJobVenueAsAdminVariables>(
    UPDATE_VENUE,
    options,
  );

export default UPDATE_VENUE;
