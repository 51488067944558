import {
  gql,
  LazyQueryHookOptions,
  QueryHookOptions,
  useLazyQuery,
  useQuery,
} from '@apollo/client';
import {
  AdminTenderList,
  AdminTenderListVariables,
} from './__generated__/AdminTenderList';

const GET_TENDERS = gql`
  query AdminTenderList(
    $paginationOptions: PaginationOptions!
    $filters: [Filter!]
  ) {
    tenders: tenders(paginationOptions: $paginationOptions, filters: $filters) {
      meta {
        totalItems
        currentPage
        totalPages
      }
      items {
        id
        firstName
        lastName
        email
        avatarURL
        regionName: region
        address {
          city
          state
        }
        phoneNumber
        preferredName
        status
        hasCompletedOnboarding1099
        hasCompletedOnboardingW2
        blockedAt
      }
    }
  }
`;

export const useTendersQuery = (
  options?: QueryHookOptions<AdminTenderList, AdminTenderListVariables>,
) => useQuery<AdminTenderList, AdminTenderListVariables>(GET_TENDERS, options);
export const useLazyAdminTenderListQuery = (
  options?: LazyQueryHookOptions<AdminTenderList, AdminTenderListVariables>,
) =>
  useLazyQuery<AdminTenderList, AdminTenderListVariables>(GET_TENDERS, options);

export default GET_TENDERS;
