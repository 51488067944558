import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import debounce from 'lodash.debounce';
import {
  GoogleMap,
  Circle,
  useJsApiLoader,
  Marker,
} from '@react-google-maps/api';
import MarkerIcon from 'src/images/Marker.svg';
import Combobox from 'src/components/Combobox';
import Loading from 'src/components/Loading';
import {
  GOOGLE_MAPS_API_LIBRARIES,
  ONE_MILE_IN_METERS,
} from 'src/utils/constants';
import { googleMapStyles } from './mapStyles';

type MarkerType = {
  lat: number;
  lng: number;
};

type Props = {
  marker?: MarkerType;
  radius?: number;
  onMarkerChange: (marker: MarkerType) => void;
  onLocationChange?: (item: google.maps.places.PlaceResult) => void;
  displayCircle?: boolean;
  displayMarker?: boolean;
  onClear?: () => void;
  clearText?: string;
  containerStyle?: {
    width: string;
    height: string;
  };
  label?: string;
};

const searchInputId = 'google-places-autocomplete-input';

const defaultContainerStyle = {
  width: '394px',
  height: '300px',
};

const circleOptions = {
  strokeColor: '#2C6C53',
  strokeOpacity: 0,
  fillColor: '#2C6C53',
  fillOpacity: 0.15,
};

type PlaceResult = google.maps.places.PlaceResult & {
  id: string;
};

const ComboboxItemContainer: React.FC<{
  highlighted?: boolean;
  className?: string;
}> = ({ highlighted, className = '', children }) => (
  <div
    className={`relative cursor-default select-none py-2 px-4 ${
      highlighted && 'text-ink-not-as-dark bg-background-app'
    } ${className}`}
  >
    {children}
  </div>
);

// NOTE: Have in mind that this component shows the "place name" in addition to
// the address in the dropdown menu, but it actually handle only the address as
// the selected value.
const LocationInput: FC<Props> = ({
  marker,
  radius,
  onMarkerChange,
  onLocationChange,
  onClear,
  displayCircle = false,
  clearText = 'Clear Filter',
  containerStyle = defaultContainerStyle,
  label = 'Distance from a point in map',
}) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
    libraries: GOOGLE_MAPS_API_LIBRARIES,
  });

  const [zoom, setZoom] = useState(7);
  const [loading, setLoading] = useState(false);
  const [predictions, setPredictions] = useState<PlaceResult[]>([]);
  const [locationSelected, setLocationSelected] =
    useState<google.maps.places.PlaceResult | null>(null);
  const [searchText, setSearchText] = useState('');
  const [hiddenMap, setHiddenMap] = useState<google.maps.Map | null>(null);
  const [mapsPlacesService, setMapsPlacesService] =
    useState<google.maps.places.PlacesService | null>(null);

  const handleSave = (searchTerm: string) => {
    setLoading(true);
    handleTextChange(searchTerm);
  };

  const handleSaveRef = useRef(handleSave);

  const debouncedSave = useMemo(() => {
    const func = (value: string) => {
      handleSaveRef.current?.(value);
    };

    return debounce(func, 500);
  }, []);

  const handlePredictionsChange = (
    options: google.maps.places.PlaceResult[] | null,
    status: google.maps.places.PlacesServiceStatus,
  ) => {
    if (status !== google.maps.places.PlacesServiceStatus.OK || !options) {
      return;
    }
    setPredictions(
      options.map((option) => ({
        ...option,
        id: option.place_id,
      })) as PlaceResult[],
    );
    setLoading(false);
  };

  const handleTextChange = useCallback(
    (value: string) => {
      if (!isLoaded || !hiddenMap || !mapsPlacesService || !value) {
        return '';
      }

      mapsPlacesService.textSearch(
        { query: value, location: hiddenMap.getCenter() },
        handlePredictionsChange,
      );
    },
    [hiddenMap, isLoaded, mapsPlacesService],
  );

  useEffect(() => {
    // After loading the Googe Maps API, we need to create a hidden map
    // and a PlacesService to be able to use the Places API
    if (!isLoaded) {
      return;
    }

    const mapElement = document.getElementById('hidden-map');
    const map = mapElement
      ? new google.maps.Map(mapElement, {
          center: { lat: 37.775, lng: -122.418 },
          zoom: 10,
        })
      : null;

    if (map) {
      const placesService = map && new google.maps.places.PlacesService(map);
      setHiddenMap(map);
      setMapsPlacesService(placesService);
    }
  }, [isLoaded]);

  useEffect(() => {
    handleSaveRef.current = handleSave;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    if (!marker || !isLoaded) {
      setLocationSelected(null);
      setSearchText('');
      return;
    }

    const geoCoder = new google.maps.Geocoder();
    const location = new google.maps.LatLng(marker.lat, marker.lng);

    geoCoder.geocode({ location }, (results, status) => {
      if (status === 'OK' && results && results[0]) {
        setLocationSelected(results[0] as unknown as PlaceResult);
        onLocationChange &&
          onLocationChange(results[0] as unknown as PlaceResult);
        setSearchText(results[0].formatted_address);
      } else {
        console.error(
          'Geocode was not successful for the following reason: ' + status,
        );
      }
    });
  }, [marker, onLocationChange, isLoaded]);

  const handleInputChange = (text: string | undefined) => {
    setSearchText(text || '');
    debouncedSave(text || '');
  };

  const handleOnChange = (item: PlaceResult | null | undefined) => {
    if (item?.geometry?.location) {
      setZoom(15);
      setLocationSelected(item);
      onMarkerChange({
        lat: item.geometry.location.lat(),
        lng: item.geometry.location.lng(),
      });
    }
  };

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? (
    <>
      <div className="flex justify-between">
        <p className="text-preset-5 text-ink-dark mb-2 font-medium">{label}</p>
        <p
          className="text-preset-5 text-ink-not-as-dark mb-2 cursor-pointer font-medium underline"
          onClick={() => {
            onClear && onClear();
            setSearchText('');
          }}
        >
          {clearText}
        </p>
      </div>

      <Combobox
        id={searchInputId}
        label=""
        placeholder="Search address"
        inputValue={
          searchText !== undefined
            ? searchText
            : locationSelected?.formatted_address || ''
        }
        onChange={handleOnChange}
        onInputChange={handleInputChange}
        items={predictions}
        loadingItems={loading}
        renderLoadingComponent={() => (
          <ComboboxItemContainer className="flex justify-center">
            <Loading />
          </ComboboxItemContainer>
        )}
        renderNoResultsComponent={() => (
          <ComboboxItemContainer>No results</ComboboxItemContainer>
        )}
        renderItemComponent={({ item, highlighted }) => {
          const placeDescription = item.name
            ? `${item.name} - ${item.formatted_address}`
            : item.formatted_address;
          return (
            <ComboboxItemContainer highlighted={highlighted}>
              {placeDescription}
            </ComboboxItemContainer>
          );
        }}
      />
      {marker && (
        <GoogleMap
          clickableIcons={false}
          options={{
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeControlOptions: {
              mapTypeIds: [],
            },
            styles: googleMapStyles,
            scrollwheel: false,
          }}
          mapContainerStyle={containerStyle}
          center={marker}
          zoom={zoom}
        >
          {displayCircle && radius && (
            <Circle
              options={circleOptions}
              center={marker}
              // the circle in the map is drawn a ~7% bigger than what the backend
              // is calculating. The 0.935 is needed to make the circle way closer to
              // the backend results.
              radius={radius * ONE_MILE_IN_METERS * 0.935}
            />
          )}
          <Marker icon={MarkerIcon} position={marker} />
        </GoogleMap>
      )}

      <div id="hidden-map" className="hidden" />
    </>
  ) : (
    <div className="flex justify-center">
      <Loading />
    </div>
  );
};

export default React.memo(LocationInput);
