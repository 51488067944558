import { format, isSameDay, isToday, isYesterday } from 'date-fns';
import React, { useEffect } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useGetRostersForAdminQuery } from 'src/graphql/queries/GetRosters';
import RosterRow from './RosterRow';

const RostersTable: React.FC<{
  setTotalCount: (total: number) => void;
  newRosterIds: string[];
}> = ({ setTotalCount, newRosterIds }) => {
  const {
    data: rostersData,
    loading: loadingRosters,
    error,
    fetchMore: fetchMoreRosters,
  } = useGetRostersForAdminQuery();

  const [sentryRef] = useInfiniteScroll({
    loading: loadingRosters,
    hasNextPage: rostersData?.rosters.pageInfo.hasNextPage || false,
    onLoadMore: () => {
      return fetchMoreRosters({
        variables: {
          after: rostersData?.rosters.pageInfo.endCursor,
        },
      });
    },
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: !!error,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: '0px 0px 400px 0px',
  });

  useEffect(() => {
    setTotalCount(rostersData?.rosters.pageInfo.totalCount || 0);
  }, [rostersData, setTotalCount]);
  let previousDate: Date;
  let dateLabel: string;

  return (
    <div className="mt-6 mb-6">
      {rostersData?.rosters.edges.map((edge) => {
        const currentDate = new Date(edge.node.createdAt);
        const shouldDisplayNewDate = !isSameDay(currentDate, previousDate);
        if (shouldDisplayNewDate) {
          dateLabel = format(currentDate, 'MMM dd, yyyy');
          dateLabel = isYesterday(currentDate) ? 'Yesterday' : dateLabel;
          dateLabel = isToday(currentDate) ? 'Today' : dateLabel;
        }

        const row = (
          <div key={edge.node.id}>
            {shouldDisplayNewDate && (
              <div className="flex h-12 items-end">
                <span className="text-preset-5 text-ink-not-as-dark mb-1 text-left font-normal">
                  {dateLabel}
                </span>
              </div>
            )}
            <div
              className={`border-support-line border border-b-0 ${
                newRosterIds.includes(edge.node.id) ? 'bg-background-app' : ''
              }`}
            >
              <table className="w-full table-fixed">
                <tbody>
                  <RosterRow roster={edge.node} />
                </tbody>
              </table>
            </div>
          </div>
        );
        previousDate = currentDate;
        return row;
      })}
      {(loadingRosters || rostersData?.rosters.pageInfo.hasNextPage) && (
        <div ref={sentryRef}>Loading...</div>
      )}
    </div>
  );
};
export default RostersTable;
