import { gql } from '@apollo/client';

const SEARCH_TENDERS_FOR_SHIFT_ITEM = gql`
  fragment SearchTendersForShiftItem on Tender {
    address {
      city
      country
      interiorNumber
      neighborhood
      state
      streetName
      streetNumber
      timezone
      zip
    }
    avatarURL
    createdAt
    email
    firstName
    hardConflicts
    id
    lastName
    phoneNumber
    score
    softConflicts
    status
  }
`;

export default SEARCH_TENDERS_FOR_SHIFT_ITEM;
