import React from 'react';

const HamburgerIcon: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.5 3H7.5C6.67157 3 6 3.67157 6 4.5V5.5C6 6.32843 6.67157 7 7.5 7H22.5C23.3284 7 24 6.32843 24 5.5V4.5C24 3.67157 23.3284 3 22.5 3Z"
      fill="#333333"
    />
    <path
      d="M19.5 10H4.5C3.67157 10 3 10.6716 3 11.5V12.5C3 13.3284 3.67157 14 4.5 14H19.5C20.3284 14 21 13.3284 21 12.5V11.5C21 10.6716 20.3284 10 19.5 10Z"
      fill="#333333"
    />
    <path
      d="M16.5 17H1.5C0.671573 17 0 17.6716 0 18.5V19.5C0 20.3284 0.671573 21 1.5 21H16.5C17.3284 21 18 20.3284 18 19.5V18.5C18 17.6716 17.3284 17 16.5 17Z"
      fill="#333333"
    />
  </svg>
);

export default HamburgerIcon;
