import React, { FC, useState, useRef } from 'react';
import debounce from 'lodash.debounce';
import Combobox from 'src/components/Combobox';
import Drawer from 'src/components/Drawer';
import Search from 'src/components/Icons/Search';
import Pill from 'src/components/Pill';
import { AdminJobs_adminJobs_items as JobItem } from 'src/graphql/queries/__generated__/AdminJobs';
import { useAdminJobsQuery } from 'src/graphql/queries/AdminJobs';
import RegionFilter from 'src/components/Filters/RegionFilter';

type JobsMap = Record<string, JobItem>;

interface Props {
  onClose: () => void;
  onChange: (selectedJobs?: JobItem[], selectedRegionIds?: string[]) => void;
  open: boolean;
  selectedJobs?: JobItem[];
  selectedRegionIds: string[];
}

const JobResult: FC<{ item: JobItem; index: number; highlighted: boolean }> = ({
  item,
  index,
  highlighted,
}) => {
  const location =
    item.venue?.address.city && item.venue?.address.state
      ? `${item.venue.address.city}, ${item.venue.address.state}`
      : item.venue?.address.city ||
        item.venue?.address.state ||
        'Unknown location';
  return (
    <div className={`py-2 px-4 ${highlighted ? 'bg-background-app' : ''}`}>
      <p className="text-preset-6 text-ink-dark font-medium">{item.name}</p>
      <p className="text-preset-6 text-ink-not-as-dark">{location}</p>
    </div>
  );
};

const createJobsMap = (jobs: JobItem[]) =>
  jobs.reduce((map: JobsMap, job) => ({ ...map, [job.id]: job }), {});

const AdvancedFilters: FC<Props> = ({
  open,
  onClose,
  onChange,
  selectedJobs,
  selectedRegionIds,
}) => {
  const [temporarySelectedJobs, setTemporarySelectedJobs] = useState<JobsMap>(
    selectedJobs ? createJobsMap(selectedJobs) : {},
  );

  const [temporarySelectedRegions, setTemporarySelectedRegions] = useState<
    string[]
  >(selectedRegionIds || []);

  const [getJobs, { data, loading }] = useAdminJobsQuery();
  const jobInputChange = useRef(
    debounce((value) => {
      if (!value) {
        return;
      }
      getJobs({
        variables: {
          pagination: {
            limit: 10,
            page: 1,
          },
          options: {
            searchTerm: value,
          },
        },
      });
    }, 300),
  ).current;

  const addJob = (job: JobItem) => {
    !(job.id in temporarySelectedJobs) &&
      setTemporarySelectedJobs({ ...temporarySelectedJobs, [job.id]: job });
  };
  const removeJob = (job: JobItem) => {
    const newSelection = Object.keys(temporarySelectedJobs).reduce(
      (map: JobsMap, key) =>
        key !== job.id ? { ...map, [key]: temporarySelectedJobs[key] } : map,
      {},
    );
    setTemporarySelectedJobs(newSelection);
  };

  const handleClearAll = () => {
    setTemporarySelectedJobs({});
    setTemporarySelectedRegions([]);
    onChange(undefined);
  };

  return (
    <Drawer
      open={open}
      onClose={() => {
        onClose();
        setTemporarySelectedJobs(
          selectedJobs ? createJobsMap(selectedJobs) : {},
        );
      }}
      title="Advanced Filters"
      className="z-40"
    >
      <div className="flex h-full max-w-md flex-col justify-between overflow-hidden">
        <div className="content flex-grow overflow-y-auto">
          <div className="jobs border-support-line border-b px-4 py-6 pb-8">
            <h2 className="mb-2 font-medium">Jobs</h2>
            <Combobox
              id="jobsCombobox"
              label=""
              items={data?.adminJobs.items || []}
              loadingItems={loading}
              placeholder="Search by name or id"
              renderLoadingComponent={() => (
                <p className="py-2 px-4">loading...</p>
              )}
              renderNoResultsComponent={() => (
                <p className="py-2 px-4">No jobs found</p>
              )}
              renderLeadingComponent={() => (
                <Search className="text-ink-not-as-dark h-3 w-3" />
              )}
              onReset={() => null}
              renderItemComponent={JobResult}
              onChange={(selectedItem) => selectedItem && addJob(selectedItem)}
              onInputChange={jobInputChange}
            />
            <div className="selectedJobs">
              {Object.values(temporarySelectedJobs).map((job) => (
                <Pill
                  key={job.id}
                  onClose={() => removeJob(job)}
                  className="mr-2 mb-2"
                  toolTipText={job.id}
                >
                  {job.name}
                </Pill>
              ))}
            </div>
          </div>

          <RegionFilter
            className="px-4"
            selectedRegionsIds={temporarySelectedRegions}
            onChange={setTemporarySelectedRegions}
          />
        </div>
        <div className="footer space-x-4 px-4 py-4 pb-8 text-right">
          <button
            className="border-ink-dark text-preset-5 text-ink-dark hover:border-ink-not-as-dark hover:text-ink-not-as-dark h-9 rounded border-2 px-5 font-medium"
            onClick={handleClearAll}
          >
            Clear All
          </button>
          <button
            className="text-preset-5 bg-primary text-ink-clear hover:bg-primary-active h-9 rounded px-5 font-medium"
            onClick={() => {
              onChange(
                temporarySelectedJobs.length
                  ? Object.values(temporarySelectedJobs)
                  : undefined,
                temporarySelectedRegions.length
                  ? Object.values(temporarySelectedRegions)
                  : undefined,
              );
              onClose();
            }}
          >
            Apply filters
          </button>
        </div>
      </div>
    </Drawer>
  );
};

export default AdvancedFilters;
