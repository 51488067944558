import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { format, utcToZonedTime } from 'date-fns-tz';
import debounce from 'lodash.debounce';
import { NavLink, useHistory } from 'react-router-dom';
import { endOfDay, startOfDay } from 'date-fns';

import Loading from 'src/components/Loading';
import {
  OrderByDirectionEnum,
  InvoiceFilters,
} from 'src/__generated__/globalTypes';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import DateRangeInput from 'src/components/DateRangeInput';
import Pagination from 'src/components/Pagination';
import Pill from 'src/components/Pill';
import ClearableInput from 'src/components/ClearableInput/ClearableInput';
import TableHeaderCell from 'src/components/TableHeaderCell';
import { useInvoicesQuery } from 'src/graphql/queries/Invoices';
import { useCancelInvoiceMutation } from 'src/graphql/mutations/CancelInvoice';
import { formatMoney } from 'src/utils/formatMoney';

const NoInvoicesNotice = () => (
  <div className="mx-auto mt-16 max-w-lg text-center">
    <h2 className="text-ink-not-as-dark text-preset-2 mb-4">
      There are no invoices to show
    </h2>
    <p className="text-ink-placeholder text-preset-5">
      Invoices you create will be shown here. You can create a new invoice via
      the{' '}
      <span className="text-ink-link">
        <NavLink to="/invoice-create">create invoice</NavLink>
      </span>{' '}
      section.
    </p>
  </div>
);

const Cell: FC = ({ children }) => (
  <td className="text-preset-6 text-ink-dark bg-background-surface truncate px-4">
    {children}
  </td>
);

const getFilters = (searchParams: URLSearchParams): InvoiceFilters => {
  const startDateParam = searchParams.get('invoiceStartDate');
  const endDateParam = searchParams.get('invoiceEndDate');
  const invoiceDates =
    startDateParam && endDateParam
      ? {
          greaterThanOrEqual:
            startDateParam && startOfDay(new Date(startDateParam)),
          lessThanOrEqual: endDateParam && endOfDay(new Date(endDateParam)),
        }
      : null;

  return {
    invoiceDates,
    customId: searchParams.get('customId'),
    clientName: searchParams.get('clientName'),
  };
};

const Invoices: FC = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [orderByField, setOrderByField] = useState('invoice.createdAt');
  const [orderByDirection, setOrderByDirection] =
    useState<OrderByDirectionEnum>(OrderByDirectionEnum.DESC);
  const [cancelInvoiceModalOpen, setCancelInvoiceModalOpen] = useState(false);
  const [actionableInvoiceId, setActionableInvoiceId] = useState<string>();

  const history = useHistory();
  const searchParams = useMemo(
    () => new URLSearchParams(history.location.search),
    [history.location.search],
  );
  const filters = getFilters(searchParams);

  const [customIdFilter, setCustomIdFilter] = useState(filters.customId || '');
  const [clientNameFilter, setClientNameFilter] = useState(
    filters.clientName || '',
  );

  const handleFilters = useCallback(
    (newFilters: Record<string, string | string[] | null>) => {
      Object.entries(newFilters).forEach(([filterName, filterValue]) => {
        searchParams.delete(filterName);
        if (!Array.isArray(filterValue) && filterValue) {
          searchParams.set(filterName, filterValue);
        }
      });
      history.replace({
        search: `?${searchParams}`,
      });
    },
    [history, searchParams],
  );

  const handleDateChange = useCallback(
    (newStartDate?: Date, newEndDate?: Date) => {
      if (newStartDate && newEndDate) {
        handleFilters({
          invoiceStartDate: newStartDate.toISOString(),
          invoiceEndDate: newEndDate.toISOString(),
        });
      } else {
        handleFilters({
          invoiceStartDate: null,
          invoiceEndDate: null,
        });
      }
    },
    [handleFilters],
  );

  const handleSearchChange = useMemo(
    () =>
      debounce(
        (customId: string, clientName: string) =>
          handleFilters({
            customId,
            clientName,
          }),
        250,
      ),
    [handleFilters],
  );

  useEffect(() => {
    handleSearchChange(customIdFilter, clientNameFilter);
  }, [handleSearchChange, customIdFilter, clientNameFilter]);

  const { data, previousData, error, loading } = useInvoicesQuery({
    variables: {
      paginationOptions: {
        limit: 10,
        page: pageNumber,
      },
      orderByField,
      orderByDirection,
      filters,
    },
  });

  const [cancelInvoice] = useCancelInvoiceMutation();

  const pageInfo = (data ?? previousData)?.invoices.meta;
  let showingStart, showingEnd, totalItems;
  if (pageInfo) {
    showingStart = (pageInfo.currentPage - 1) * pageInfo.itemsPerPage + 1;
    showingEnd = showingStart + pageInfo.itemCount - 1;
    totalItems = pageInfo.totalItems;
  }

  const sort = (field: string) => () => {
    setOrderByDirection(
      orderByField === field
        ? orderByDirection === OrderByDirectionEnum.ASC
          ? OrderByDirectionEnum.DESC
          : OrderByDirectionEnum.ASC
        : OrderByDirectionEnum.ASC,
    );
    setOrderByField(field);
  };

  const getSortDirection = (field: string) =>
    orderByField === field ? orderByDirection : undefined;

  return (
    <div className="bg-background-surface h-full overflow-scroll p-10 pb-10">
      {loading && !previousData ? (
        <Loading width={60} />
      ) : error ? (
        <p>There was an error when requesting your data.</p>
      ) : (
        <>
          <div className="bg-background-surface border-support-line mb-6 flex items-center justify-between border px-4 py-5">
            <p className="text-preset-2 text-ink-dark">
              {totalItems || 0} Invoice{totalItems !== 1 ? 's' : ''}
            </p>
            <div className="flex flex-row items-center space-x-4">
              <DateRangeInput
                startDate={filters.invoiceDates?.greaterThanOrEqual}
                endDate={filters.invoiceDates?.lessThanOrEqual}
                onChange={(start, end) => {
                  handleDateChange(start, end);
                }}
                allowAnyDate
              />
              <ClearableInput
                className="border-support-line-darker h-11 rounded border py-1.5"
                onClear={() => setCustomIdFilter('')}
              >
                <input
                  className="w-64 rounded-md px-8 py-1 outline-none"
                  value={customIdFilter}
                  onChange={(e) => setCustomIdFilter(e.currentTarget.value)}
                  placeholder="Search by invoice number"
                />
              </ClearableInput>
              <ClearableInput
                className="border-support-line-darker h-11 rounded border py-1.5"
                onClear={() => setClientNameFilter('')}
              >
                <input
                  className="w-60 rounded-md px-8 py-1 outline-none"
                  value={clientNameFilter}
                  onChange={(e) => setClientNameFilter(e.currentTarget.value)}
                  placeholder="Search by client name"
                />
              </ClearableInput>
            </div>
          </div>
          {totalItems === 0 ? (
            <NoInvoicesNotice />
          ) : (
            <div className="border-support-line rounded-lg border">
              <div className="border-support-line rounded-t-lg rounded-r-lg border-b bg-white p-4 pt-6">
                <h2 className="text-preset-4 text-ink-dark">Invoices</h2>
              </div>
              <div className="overflow-x-auto">
                <table className="min-w-full table-fixed">
                  <thead className="bg-background-app">
                    <tr className="h-12">
                      <TableHeaderCell
                        onClick={sort('invoice.customId')}
                        sortDirection={getSortDirection('invoice.customId')}
                      >
                        Invoice Number
                      </TableHeaderCell>
                      <TableHeaderCell
                        onClick={sort('invoice.clientName')}
                        sortDirection={getSortDirection('invoice.clientName')}
                      >
                        Client Name
                      </TableHeaderCell>
                      <TableHeaderCell
                        onClick={sort('invoice.createdAt')}
                        sortDirection={getSortDirection('invoice.createdAt')}
                      >
                        Creation Date
                      </TableHeaderCell>
                      <TableHeaderCell
                        onClick={sort('invoice.dueDate')}
                        sortDirection={getSortDirection('invoice.dueDate')}
                      >
                        Due Date
                      </TableHeaderCell>
                      <TableHeaderCell
                        onClick={sort('invoice.total')}
                        sortDirection={getSortDirection('invoice.total')}
                      >
                        Total
                      </TableHeaderCell>
                      <TableHeaderCell
                        onClick={sort('invoice.status')}
                        sortDirection={getSortDirection('invoice.status')}
                      >
                        Status
                      </TableHeaderCell>
                      <TableHeaderCell>Actions</TableHeaderCell>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {(data ?? previousData)?.invoices.items.map((invoice) => (
                      <tr
                        key={invoice.id}
                        className="border-support-line h-14 border-b"
                      >
                        <Cell>{invoice.customId}</Cell>
                        <Cell>{invoice.clientName}</Cell>
                        <Cell>
                          {format(
                            utcToZonedTime(
                              new Date(invoice.createdAt),
                              'America/Los_Angeles',
                            ),
                            'yyyy-MM-dd',
                          )}
                        </Cell>
                        <Cell>
                          {format(
                            utcToZonedTime(
                              new Date(invoice.dueDate),
                              'America/Los_Angeles',
                            ),
                            'yyyy-MM-dd',
                          )}
                        </Cell>
                        <Cell>{formatMoney(invoice.total)}</Cell>
                        <Cell>
                          <Pill
                            status={
                              invoice.status === 'CANCELLED'
                                ? 'destructive'
                                : 'info'
                            }
                          >
                            <span className="capitalize">
                              {invoice.status.toLowerCase()}
                            </span>
                          </Pill>
                        </Cell>
                        <Cell>
                          {invoice.documentURL ? (
                            <>
                              <a
                                className="text-ink-primary font-semibold"
                                href={invoice.documentURL}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Download
                              </a>
                              {invoice.status !== 'CANCELLED' ? (
                                <button
                                  className="text-status-destructive ml-6 font-semibold"
                                  onClick={() => {
                                    setActionableInvoiceId(invoice.id);
                                    setCancelInvoiceModalOpen(true);
                                  }}
                                >
                                  Cancel
                                </button>
                              ) : (
                                <p className="text-support-line-darker ml-6 inline">
                                  Cancel
                                </p>
                              )}
                            </>
                          ) : (
                            <span className="text-support-line-darker">
                              Pending
                            </span>
                          )}
                        </Cell>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex items-center justify-between p-4">
                <p className="text-ink-dark text-preset-6">
                  Showing {showingStart} to {showingEnd} of {totalItems} results
                </p>
                <Pagination
                  currentPage={pageNumber}
                  pageCount={pageInfo?.totalPages || 0}
                  onPageChange={(p) => setPageNumber(p)}
                />
              </div>
            </div>
          )}
        </>
      )}
      <ConfirmationDialog
        title="Cancel Invoice?"
        description="Once an invoice is cancelled, staffings will be free to be included in a new invoice again."
        open={cancelInvoiceModalOpen}
        onClose={() => setCancelInvoiceModalOpen(false)}
        onSubmit={() => {
          cancelInvoice({
            variables: {
              id: actionableInvoiceId || '',
            },
          });
          setCancelInvoiceModalOpen(false);
        }}
        submitText="Cancel invoice"
        submitClassName="text-preset-5 text-status-destructive ml-6"
      />
    </div>
  );
};

export default Invoices;
