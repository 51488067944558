const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
}).format;

export const formatMoney = (value: number) => formatter(value / 100);

const formatNumber = (n: string): string => {
  return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatCurrency = (value: string): string => {
  let result;
  if (value === '') {
    return '';
  }
  if (value.indexOf('.') >= 0) {
    const decimalPos = value.indexOf('.');
    let leftSide = value.substring(0, decimalPos);
    let rightSide = value.substring(decimalPos);
    leftSide = formatNumber(leftSide);
    rightSide = formatNumber(rightSide);
    rightSide = rightSide.substring(0, 2);
    result = '$' + leftSide + '.' + rightSide;
  } else {
    result = '$' + formatNumber(value);
  }
  return result;
};
