import React from 'react';

const Message: React.FC<{ className?: string; height?: string }> = ({
  className,
  height = '20px',
}) => (
  <svg
    width="12"
    height={height}
    viewBox="0 0 12 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M1 0C0.447715 0 0 0.447715 0 1V13C0 13.5523 0.447715 14 1 14H9L12 17V1C12 0.447715 11.5523 0 11 0H1ZM11 2V14.828L9.828 13H1V2H11Z"
      fill="#6D727C"
    />
  </svg>
);

export default Message;
