import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  PublishJobAsAdmin,
  PublishJobAsAdminVariables,
} from './__generated__/PublishJobAsAdmin';

const PUBLISH_JOB = gql`
  mutation PublishJobAsAdmin($jobId: ID!) {
    publishJob(jobId: $jobId) {
      __typename
      id
      published
    }
  }
`;

export const usePublishJobAsAdminMutation = (
  options?: MutationHookOptions<PublishJobAsAdmin, PublishJobAsAdminVariables>,
) =>
  useMutation<PublishJobAsAdmin, PublishJobAsAdminVariables>(
    PUBLISH_JOB,
    options,
  );

export default PUBLISH_JOB;
