import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  ShowAtOpenJobs,
  ShowAtOpenJobsVariables,
} from './__generated__/ShowAtOpenJobs';

const SHOW_AT_OPEN_JOBS = gql`
  mutation ShowAtOpenJobs($jobId: ID!) {
    showAtOpenJobs(jobId: $jobId) {
      __typename
      id
      hiddenFromOpenJobs
    }
  }
`;

export const useShowAtOpenJobsMutation = (
  options?: MutationHookOptions<ShowAtOpenJobs, ShowAtOpenJobsVariables>,
) =>
  useMutation<ShowAtOpenJobs, ShowAtOpenJobsVariables>(
    SHOW_AT_OPEN_JOBS,
    options,
  );

export default SHOW_AT_OPEN_JOBS;
