import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  MarkShiftNotesAsRead,
  MarkShiftNotesAsReadVariables,
} from './__generated__/MarkShiftNotesAsRead';

const MARK_SHIFT_NOTES_AS_READ = gql`
  mutation MarkShiftNotesAsRead($shiftId: ID!) {
    markShiftNotesAsRead(shiftId: $shiftId) {
      id
      shiftId
      # adminId
      readAt
      createdAt
      updatedAt
    }
  }
`;

export const useMarkShiftNotesAsRead = (
  options?: MutationHookOptions<
    MarkShiftNotesAsRead,
    MarkShiftNotesAsReadVariables
  >,
) =>
  useMutation<MarkShiftNotesAsRead, MarkShiftNotesAsReadVariables>(
    MARK_SHIFT_NOTES_AS_READ,
    options,
  );

export default MARK_SHIFT_NOTES_AS_READ;
