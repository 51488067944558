import { format, utcToZonedTime } from 'date-fns-tz';
const DATE_FORMAT = 'MMMM d, yyyy';
const HOUR_FORMAT = ' hh:mmaaa';
const HOUR_FORMAT_WITH_TIMEZONE = ' h:mm aa zzz';
const DEFAULT_TIMEZONE = 'America/Los_Angeles';

const calculateDateTimes = (
  startDateTime: Date,
  endDateTime: Date,
  timeZone: string = DEFAULT_TIMEZONE,
  addTimeZone = true,
): string => {
  const startDateUTC = new Date(startDateTime);
  const zonedStartDate = timeZone
    ? utcToZonedTime(startDateUTC, timeZone)
    : startDateUTC;
  const formattedStartDate = format(zonedStartDate, DATE_FORMAT, {
    timeZone,
  });
  const formattedStartTime = format(
    zonedStartDate,
    addTimeZone ? HOUR_FORMAT_WITH_TIMEZONE : HOUR_FORMAT,
    {
      timeZone,
    },
  );
  const endDateUTC = new Date(endDateTime);
  const zonedEndDate = timeZone
    ? utcToZonedTime(endDateUTC, timeZone)
    : endDateUTC;
  const formattedEndDate = format(zonedEndDate, DATE_FORMAT, {
    timeZone,
  });
  const formattedEndTime = format(
    zonedEndDate,
    addTimeZone ? HOUR_FORMAT_WITH_TIMEZONE : HOUR_FORMAT,
    {
      timeZone,
    },
  );
  const startDateString = `${formattedStartDate} • ${formattedStartTime}`;
  const endDateString =
    formattedEndDate === formattedStartDate
      ? formattedEndTime
      : `${formattedEndDate} ${formattedEndTime}`;
  return `${startDateString} - ${endDateString}`;
};

// https://natclark.com/tutorials/javascript-relative-time/
const calculateRelativeTime = (fromDate: Date): string => {
  const difference =
    Math.floor(new Date().getTime() / 1000) -
    Math.floor(new Date(fromDate).getTime() / 1000);
  let output = '';
  if (difference < 60) {
    // less than one minute, show seconds
    output += `${difference}s`;
  } else if (difference < 3600) {
    // less than one hour, show minutes
    output += `${Math.floor(difference / 60)}m`;
  } else if (difference < 86400) {
    // less than one day, show hours
    output += `${Math.floor(difference / 3600)}h`;
  } else if (difference < 2620800) {
    // less than one month, show days
    output += `${Math.floor(difference / 86400)}d`;
  } else if (difference < 31449600) {
    // less than one year, show months
    output += `${Math.floor(difference / 2620800)}mo`;
  } else {
    // show years
    output += `${Math.floor(difference / 31449600)}y`;
  }

  return output;
};

export { calculateDateTimes, calculateRelativeTime };
