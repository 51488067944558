import { gql, QueryHookOptions, useLazyQuery } from '@apollo/client';
import { Region, RegionVariables } from './__generated__/Region';

const GET_REGION = gql`
  query Region($id: ID!) {
    region(id: $id) {
      id
      name
      polygon {
        lat
        lng
      }
    }
  }
`;

export const useRegionQuery = (
  options?: QueryHookOptions<Region, RegionVariables>,
) => useLazyQuery<Region, RegionVariables>(GET_REGION, options);

export default GET_REGION;
