import React from 'react';
import { useParams } from 'react-router-dom';

import Loading from 'src/components/Loading';
import ViewContainer from 'src/components/ViewContainer';
import { useGetRosterForAdminQuery } from 'src/graphql/queries/Roster';
import RosterHeader from './Components/RosterDetailHeader';
import TendersInRosterTable from './Components/TendersInRosterTable';
import RosterShiftsCard from './Components/RosterShiftsCard';
import RosterJobCard from './Components/RosterJobCard';

const RosterDetail: React.FC = () => {
  const { id: rosterId } = useParams<{ id: string }>();
  const { data: rosterData } = useGetRosterForAdminQuery({
    variables: { id: rosterId },
  });
  if (!rosterData) {
    return <Loading className="m-auto" />;
  }
  const { roster } = rosterData;
  const timeZone = roster.job.venue?.address.timezone;

  return (
    <ViewContainer className="mt-6 mb-6">
      <RosterHeader
        jobName={roster.job.name}
        rosterId={roster.id}
        venueName={
          roster.job.venue?.name ??
          `${roster.client.firstName} ${roster.client.lastName}`
        }
      />
      <div className="flex">
        <div className="w-2/3 px-4 py-6">
          <TendersInRosterTable
            rosterId={rosterId}
            timeZone={timeZone}
            shifts={roster.job.shifts}
          />
        </div>
        <div className="mt-5 w-1/3">
          <RosterJobCard roster={roster} timeZone={timeZone} />
          <RosterShiftsCard
            shifts={roster.job.shifts}
            timeZone={timeZone}
            rosterId={roster.id}
          />
        </div>
      </div>
    </ViewContainer>
  );
};

export default RosterDetail;
