import React, { FC } from 'react';
import Combobox from 'src/components/Combobox';
import ChevronDown from 'src/components/Icons/ChevronDown';

type StaffingStatus = {
  label: string;
  id: string;
};

const ClientResultComponent: FC<{
  item: StaffingStatus;
  index: number;
  highlighted: boolean;
}> = ({ item }) => (
  <div className="hover:bg-background-app cursor-pointer py-2 px-4">
    <p className="text-preset-6 text-ink-dark font-medium">{item.label}</p>
  </div>
);

type Props = {
  staffingStatus?: string;
  onChange: (staffingStatus: string) => void;
  className?: string;
};

const staffingStatusList: StaffingStatus[] = [
  {
    label: 'All',
    id: 'all',
  },
  {
    label: 'Not Staffed',
    id: 'notStaffed',
  },
  {
    label: 'Staffed',
    id: 'staffed',
  },
  {
    label: 'Overstaffed',
    id: 'overstaffed',
  },
];

const ShiftStatusInput: FC<Props> = ({
  staffingStatus,
  onChange,
  className,
}) => {
  const defaultStaffingStatus =
    staffingStatusList.find((status) => status.id === staffingStatus) ||
    staffingStatusList[0];

  return (
    <div className={className}>
      <Combobox
        id="staffingStatusCombobox"
        inputValue={defaultStaffingStatus.label}
        inputClassName="focus:ring-primary focus:border-primary sm:text-preset-6 border-support-line-darker rounded cursor-pointer"
        inputReadOnly
        itemToString={() => defaultStaffingStatus.label}
        items={staffingStatusList || []}
        label="Staffing status"
        loadingItems={false}
        onChange={(selectedItem?: StaffingStatus | null) => {
          selectedItem && onChange(selectedItem.id);
        }}
        onInputChange={() => null}
        onReset={() => onChange(staffingStatusList[0].id)}
        openMenuOnFocus
        renderItemComponent={ClientResultComponent}
        renderLoadingComponent={() => null}
        renderNoResultsComponent={() => null}
        renderTrailingComponent={() => (
          <ChevronDown className="text-ink-not-as-dark h-3 w-3" />
        )}
      />
    </div>
  );
};

export default ShiftStatusInput;
