import React, { useEffect } from 'react';
import { format } from 'date-fns';
import { useGetTenderQuery } from 'src/graphql/queries/GetTender';
import Favorite from 'src/components/Icons/Heart';
import Dislike from 'src/components/Icons/ThumbDown';
import Block from 'src/components/Icons/Ban';
import { Can } from 'src/contexts/AbilityContext';
import { RETOOL_TENDERS_APP_URL } from 'src/utils/constants';

interface Props {
  tenderId: string;
  popperUpdate?: (() => void) | null;
}

const TenderCard: React.FC<Props> = ({ tenderId, popperUpdate }) => {
  const { data, loading } = useGetTenderQuery({
    variables: { id: tenderId },
  });

  useEffect(() => {
    popperUpdate?.();
  }, [popperUpdate, data]);

  if (!data || loading) {
    return null;
  }

  const { tender } = data;

  if (!tender) {
    return null;
  }

  const {
    id,
    firstName,
    lastName,
    avatarURL,
    applicationsAfterLastShiftWorked,
    lastWorkedShift,
    shiftsFaultedCount,
    shiftsCalledOutCount,
    positions,
    shiftsWorked,
    tags,
    blockedCount,
    lessPreferredCount,
    favoritedCount,
    email,
    phoneNumber,
    unpaidShiftsCount,
  } = tender;

  return (
    <div className="z-50 max-h-screen overflow-auto rounded-lg border-0 shadow-lg">
      <div className="flex w-96 flex-col items-center bg-white p-6">
        <div className="h-16 w-16 overflow-hidden rounded-full">
          {avatarURL ? (
            <img
              className="inline-block h-full w-full "
              src={avatarURL}
              alt="avatar"
            />
          ) : (
            <div className="bg-primary-light text-ink-primary text-preset-2 flex h-full w-full flex-col justify-center text-center font-semibold">
              {firstName && firstName[0]}
              {lastName && lastName[0]}
            </div>
          )}
        </div>
        <p className="text-ink text-preset-2 mt-3 font-medium">
          {firstName} {lastName}
        </p>
        {email && (
          <p className="text-ink-not-as-dark text-preset-6 mt-1.5 mb-1 text-center">
            {email}
          </p>
        )}
        <p className="text-ink-not-as-dark text-preset-6 mt-1 text-center">
          {positions?.map((position) => position.name)?.join(', ')}
        </p>
        <div className="mb-4 mt-3 flex flex-wrap justify-center">
          {tags?.map((tag) => {
            return (
              <div
                key={tag}
                className="text-preset-6 bg-status-info-light text-ink-secondary mt-2 mr-2 inline-flex items-center rounded-full px-3 py-1.5 font-medium"
              >
                {tag}
              </div>
            );
          })}
        </div>
        <div className="mt-3 mb-4 flex w-full justify-between">
          <div className="bg-background-app rounded-md px-8 pt-2 pb-1">
            <div className="bg-primary mb-3 rounded-full p-2">
              <Favorite className="h-4 w-4 text-white" />
            </div>
            <p className="text-ink text-center">{favoritedCount}</p>
          </div>
          <div className="bg-background-app ml-8 rounded-md px-8 pt-2 pb-1">
            <div className="bg-ink-dark mb-3 rounded-full p-2">
              <Dislike className="h-4 w-4 text-white" />
            </div>
            <p className="text-ink text-center">{lessPreferredCount}</p>
          </div>
          <div className="bg-background-app ml-8 rounded-md px-8 pt-2 pb-1">
            <div className="bg-status-destructive mb-3 rounded-full p-2">
              <Block className="h-4 w-4 text-white" />
            </div>
            <p className="text-ink text-center">{blockedCount}</p>
          </div>
        </div>
        <div className="flex w-full justify-between border-b py-2">
          <p className="text-ink-not-as-dark">Job Worked</p>
          <p>{shiftsWorked}</p>
        </div>
        <div className="flex w-full justify-between border-b py-2">
          <p className="text-ink-not-as-dark">Call Outs</p>
          <p>{shiftsCalledOutCount}</p>
        </div>
        <div className="flex w-full justify-between border-b py-2">
          <p className="text-ink-not-as-dark">Faults</p>
          <p>{shiftsFaultedCount}</p>
        </div>
        <div className="flex w-full justify-between border-b py-2">
          <p className="text-ink-not-as-dark">Denial Streak</p>
          <p>{applicationsAfterLastShiftWorked}</p>
        </div>
        {unpaidShiftsCount > 0 && (
          <div className="flex w-full justify-between border-b py-2">
            <p className="text-status-destructive">Unpaid Shifts</p>
            <p className="text-status-destructive">{unpaidShiftsCount}</p>
          </div>
        )}
        {lastWorkedShift && (
          <div className="mt-7 mb-2 w-full">
            <p className="text-ink-not-as-dark pb-2 uppercase">
              Last Worked Job
            </p>
            <div className="rounded-lg border p-2">
              <p className="pb-1 font-medium">
                {lastWorkedShift.job.client?.firstName}{' '}
                {lastWorkedShift.job.client?.lastName}
              </p>
              <div className="flex justify-between">
                <p>{lastWorkedShift.position.name}</p>
                <p className="text-ink-not-as-dark">
                  {format(new Date(lastWorkedShift.endDateTime), 'MMM do y')}
                </p>
              </div>
            </div>
          </div>
        )}
        <Can I="update" a="Tender">
          <a
            className="inline-flex w-full items-center"
            href={`tender/${id}`}
            target="_blank"
            rel="noopener noreferrer"
            data-cy="open-full-profile-button"
          >
            <button className="border-ink-dark text-preset-6 text-ink-dark bg-background-surface mt-8 w-full rounded-md border px-2.5 py-2 font-normal focus:outline-none">
              Open Full Profile In New Tab
            </button>
          </a>
        </Can>

        <Can I="read" a="Frontapp">
          <a
            className="inline-flex w-full items-center"
            href={`https://app.frontapp.com/contacts/search/${phoneNumber}`}
            target="_blank"
            rel="noopener noreferrer"
            data-cy="open-front-conversations-link"
          >
            <button className="border-ink-dark text-preset-6 text-ink-dark bg-background-surface mt-4 w-full rounded-md border px-2.5 py-2 font-normal focus:outline-none">
              Open Front Conversations
            </button>
          </a>
        </Can>

        <Can I="read" a="Retool">
          <a
            className="text-preset-6 text-ink-link bg-background-surface hover:bg-background-app mt-4 block w-full items-center rounded-md border border-none px-2.5 py-2 text-center font-normal focus:outline-none"
            href={`${RETOOL_TENDERS_APP_URL}#search=${id}`}
            target="_blank"
            rel="noopener noreferrer"
            data-cy="open-retool-profile-link"
          >
            View in Retool
          </a>
        </Can>
      </div>
    </div>
  );
};

export default TenderCard;
