import React, { useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { validateUUID } from 'src/utils/uuid';
import Combobox from 'src/components/Combobox';
import Search from 'src/components/Icons/Search';
import Pill from 'src/components/Pill';

const SingleValueUUIDComboboxField: React.FC<{
  control: Control;
  name: string;
  label: string;
  noResultLabel: string;
  defaultValue?: string | null;
}> = ({ control, name, label, noResultLabel, defaultValue }) => {
  const [option, setOption] = useState<string>();
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={{ validate: validateUUID }}
      render={(field, state) => (
        <>
          <Combobox
            id={name}
            label={label}
            items={option ? [{ id: option }] : []}
            onInputChange={(value) => {
              setOption(value);
            }}
            onChange={(item) => {
              field.onChange(item?.id);
            }}
            onReset={() => {
              setOption(undefined);
            }}
            loadingItems={false}
            renderItemComponent={({ item, highlighted }) => (
              <Combobox.HighlightableItem highlighted={highlighted}>
                {item.id}
              </Combobox.HighlightableItem>
            )}
            renderNoResultsComponent={() => (
              <Combobox.HighlightableItem>
                {noResultLabel}
              </Combobox.HighlightableItem>
            )}
            renderLoadingComponent={() => null}
            renderLeadingComponent={() => (
              <Search className="text-ink-not-as-dark h-3 w-3" />
            )}
          />
          {state.invalid && (
            <p className="text-preset-6 text-status-destructive -mt-4">
              Please use a valid ID
            </p>
          )}
          {field.value && (
            <Pill onClose={() => field.onChange(undefined)}>{field.value}</Pill>
          )}
        </>
      )}
    />
  );
};

export default SingleValueUUIDComboboxField;
