import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  DeleteRosterAsAdmin,
  DeleteRosterAsAdminVariables,
} from './__generated__/DeleteRosterAsAdmin';

const DELETE_ROSTER = gql`
  mutation DeleteRosterAsAdmin($rosterId: ID!) {
    deleteRoster(rosterId: $rosterId) {
      id
      __typename
    }
  }
`;

export const useDeleteRosterAsAdminMutation = (
  options?: MutationHookOptions<
    DeleteRosterAsAdmin,
    DeleteRosterAsAdminVariables
  >,
) =>
  useMutation<DeleteRosterAsAdmin, DeleteRosterAsAdminVariables>(
    DELETE_ROSTER,
    {
      update(cache, { data }) {
        const normalizedId = cache.identify({
          id: data?.deleteRoster.id,
          __typename: 'Roster',
        });
        cache.evict({ id: normalizedId });
        cache.gc();
      },
      ...options,
    },
  );

export default DELETE_ROSTER;
