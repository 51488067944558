import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { GET_ADMIN_FROM_INVITATION_ROUTE } from 'src/routes';
import {
  formLabelClass,
  formInputClass,
  primaryButtonClass,
} from 'src/components/Forms';
import useQueryParams from 'src/hooks/queryParams';
import {
  serviceOutageMessage,
  unexpectedErrorMessage,
} from 'src/utils/errorDisplay';

type FormValues = {
  password: string;
  passwordConfirmation: string;
};

type Admin = {
  email: string;
};

type Props = {
  submitTo: string;
};

const formSchema = Yup.object().shape({
  password: Yup.string().min(8, 'must be at least 8 characters long'),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'must match the password',
  ),
});

const SetNewPasswordForm: React.FC<Props> = ({ submitTo }) => {
  const history = useHistory();
  const [submitError, setSubmitError] = useState(false);
  const [submitErrorMessage, setSubmitErrorMessage] = useState('');
  const [admin, setAdmin] = useState<Admin | null>(null);
  const { register, errors, handleSubmit, formState, reset } =
    useForm<FormValues>({
      mode: 'onBlur',
      resolver: yupResolver(formSchema),
    });
  const queryParams = useQueryParams();
  const invitationToken = queryParams.get('token');
  useEffect(() => {
    const getAdmin = async () => {
      const response = await fetch(GET_ADMIN_FROM_INVITATION_ROUTE, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${invitationToken}`,
        },
      });
      if (!response) {
        return;
      }
      const result = await response.json();
      if (!response.ok) {
        console.error(result);
        setSubmitError(true);
        setSubmitErrorMessage(unexpectedErrorMessage);
      }
      setAdmin(result);
    };
    getAdmin();
  }, [invitationToken, reset]);

  const onSubmit = async (values: FormValues) => {
    setSubmitError(false);
    try {
      const rawResponse = await fetch(submitTo, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${invitationToken}`,
        },
        body: JSON.stringify({ password: values.password }),
      });
      if (rawResponse.ok) {
        history.push('/password-reset-success');
      } else {
        console.error(rawResponse);
        switch (rawResponse.status) {
          case 502: {
            setSubmitError(true);
            setSubmitErrorMessage(serviceOutageMessage);
            break;
          }
          default: {
            setSubmitError(true);
            setSubmitErrorMessage(unexpectedErrorMessage);
            break;
          }
        }
      }
    } catch (e) {
      console.error(e);
      setSubmitError(true);
      setSubmitErrorMessage(unexpectedErrorMessage);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      {admin?.email && (
        <div>
          <div className="mt-1">Your email address: {admin.email}</div>
        </div>
      )}

      <div>
        <label htmlFor="password" className={formLabelClass}>
          <span>Password</span>
          {errors.password && (
            <span className="text-preset-5 ml-4 h-12 text-red-700">
              {errors.password.message}
            </span>
          )}
        </label>
        <div className="mt-1">
          <input
            ref={register}
            tabIndex={2}
            id="password"
            name="password"
            type="password"
            required
            className={formInputClass}
          />
        </div>
      </div>

      <div>
        <label htmlFor="passwordConfirmation" className={formLabelClass}>
          <span>Password Confirmation</span>
          {errors.passwordConfirmation && (
            <span className="text-preset-5 ml-4 h-12 text-red-700">
              {errors.passwordConfirmation.message}
            </span>
          )}
        </label>
        <div className="mt-1">
          <input
            ref={register}
            tabIndex={3}
            id="passwordConfirmation"
            name="passwordConfirmation"
            type="password"
            required
            className={formInputClass}
          />
        </div>
      </div>

      <div>
        <button
          tabIndex={4}
          type="submit"
          className={primaryButtonClass}
          disabled={formState.isSubmitting}
        >
          Set password
        </button>
      </div>

      <div className="text-preset-5 block h-12 text-red-700">
        {submitError && submitErrorMessage}
      </div>
    </form>
  );
};

export default SetNewPasswordForm;
