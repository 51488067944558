import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 22"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="currentColor"
      d="M16.041 1.57h-1.39V.73c0-.403-.32-.73-.714-.73a.723.723 0 0 0-.715.73v.84H6.778V.73c0-.403-.32-.73-.715-.73a.723.723 0 0 0-.715.73v.84h-1.39C1.777 1.57 0 3.384 0 5.614v12.343C0 20.187 1.776 22 3.959 22H16.04c2.184 0 3.96-1.814 3.96-4.043V5.614c0-2.23-1.776-4.044-3.958-4.044ZM3.96 3.031h1.39v.84c0 .404.32.73.714.73a.723.723 0 0 0 .715-.73v-.84h6.444v.84c0 .404.32.73.715.73a.723.723 0 0 0 .715-.73v-.84h1.39c1.393 0 2.528 1.159 2.528 2.583V6.69H1.43V5.613c0-1.424 1.135-2.583 2.529-2.583ZM16.04 20.54H3.96c-1.395 0-2.529-1.158-2.529-2.583V8.15h17.14v9.808c0 1.424-1.134 2.582-2.528 2.582Zm.294-8.568c0 .404-.32.73-.715.73H4.38a.723.723 0 0 1-.715-.73c0-.403.32-.73.715-.73h11.24c.396 0 .716.327.716.73Zm0 4.199c0 .403-.32.73-.715.73H4.38a.723.723 0 0 1-.715-.73c0-.403.32-.73.715-.73h11.24c.396 0 .716.327.716.73Z"
    />
  </svg>
);
export default SvgComponent;
