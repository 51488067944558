import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import {
  GetClientTendersForAdmin,
  GetClientTendersForAdminVariables,
} from './__generated__/GetClientTendersForAdmin';

const GET_CLIENT_TENDERS = gql`
  query GetClientTendersForAdmin(
    $tenderId: ID!
    $status: ClientTenderStatus
    $pagination: PaginationOptions
  ) {
    tenderClientByOffset(
      tenderId: $tenderId
      status: $status
      paginationOptions: $pagination
    ) {
      meta {
        currentPage
        totalItems
        itemCount
      }
      items {
        id
        client {
          email
          firstName
          lastName
        }
        status
      }
    }
  }
`;

export const useGetClientTendersForAdminQuery = (
  options?: QueryHookOptions<
    GetClientTendersForAdmin,
    GetClientTendersForAdminVariables
  >,
) =>
  useQuery<GetClientTendersForAdmin, GetClientTendersForAdminVariables>(
    GET_CLIENT_TENDERS,
    options,
  );

export default GET_CLIENT_TENDERS;
