import React, { FC, useCallback, useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { InvoicingStatus } from 'src/__generated__/globalTypes';
import { PaidStaffedShifts_paidStaffedShifts_items } from 'src/graphql/queries/__generated__/PaidStaffedShifts';
import { useAdjustPaidStaffingEstimateQuery } from 'src/graphql/queries/AdjustPaidStaffingEstimate';
import { AdjustPaidStaffingEstimate_adjustPaidStaffingEstimate as EstimateData } from 'src/graphql/queries/__generated__/AdjustPaidStaffingEstimate';
import Modal from 'src/components/Modal';
import DateInput from 'src/components/DateInput';
import TimeInput from 'src/components/TimeInput';
import Loading from 'src/components/Loading';
import { formatMoney } from 'src/utils/formatMoney';
import { useAdjustPaidStaffingMutation } from 'src/graphql/mutations/AdjustPaidStaffing';
import { getStaffedShiftDescription } from 'src/views/PaidStaffings/PaidStaffings';

type FormValues = {
  id?: string;
  actualStartDateTime?: Date;
  actualEndDateTime?: Date;
  actualUnpaidBreakMinutes?: number;
  cancelRelatedInvoice: boolean;
};
const formSchema = Yup.object().shape({
  id: Yup.string(),
  actualStartDateTime: Yup.date(),
  actualEndDateTime: Yup.date().min(
    Yup.ref('actualStartDateTime'),
    "End date can't be before Start date",
  ),
  actualUnpaidBreakMinutes: Yup.number(),
});

const Estimate: FC<{ data: EstimateData }> = ({ data }) => {
  const {
    tenderPayoutDifference,
    clientPaymentDifference,
    clientTotalEstimate,
  } = data;

  return (
    <div className="text-ink text-preset-6 font-medium">
      <p>
        The
        {clientPaymentDifference !== 0
          ? ' new client total will be'
          : ' client total is'}
        <span className="font-bold"> {formatMoney(clientTotalEstimate)}</span>
        {clientPaymentDifference !== 0 && (
          <>
            , which is{' '}
            <span className="font-bold">
              {formatMoney(Math.abs(clientPaymentDifference))}
            </span>{' '}
            {clientPaymentDifference > 0 ? ' more ' : ' less '}
            than current total
          </>
        )}
        .
      </p>
      {tenderPayoutDifference > 0 && (
        <p className="mt-2">
          A new payment of{' '}
          <span className="font-bold">{` ${formatMoney(
            tenderPayoutDifference,
          )} `}</span>{' '}
          will be generated for the Tender.
        </p>
      )}
      {tenderPayoutDifference < 0 && (
        <p className="mt-2">
          A negative payout difference of
          <span className="font-bold">{` ${formatMoney(
            Math.abs(tenderPayoutDifference),
          )} `}</span>
          will not affect the Tender in any way.
        </p>
      )}
    </div>
  );
};

const EditPaidStaffingModal: React.FC<{
  staffedShiftModalData: PaidStaffedShifts_paidStaffedShifts_items | null;
  setStaffedShiftModalData: (
    value: PaidStaffedShifts_paidStaffedShifts_items | null,
  ) => void;
  onStaffingChanged: () => void;
}> = ({
  staffedShiftModalData,
  setStaffedShiftModalData,
  onStaffingChanged,
}) => {
  const timeZone =
    staffedShiftModalData?.staffing.shift?.job.venue?.address.timezone ||
    'America/Los_Angeles';
  const tender = staffedShiftModalData?.staffing.tender;
  const shift = staffedShiftModalData?.staffing.shift;
  const [pickerOpen, setPickerOpen] = useState<boolean | undefined>(false);
  const [unpaidBreakMinutes, setUnpaidBreakMinutes] = useState(
    staffedShiftModalData?.actualUnpaidBreakMinutes,
  );
  const [startDate, setStartDate] = useState<Moment | null>();
  const [endDate, setEndDate] = useState<Moment | null>();
  const [startTime, setStartTime] = useState<Moment | null>();
  const [endTime, setEndTime] = useState<Moment | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [getEstimate, { data: estimateData, loading: estimateLoading }] =
    useAdjustPaidStaffingEstimateQuery();

  const initValues = {
    id: staffedShiftModalData?.id || '',
    actualStartDateTime: staffedShiftModalData?.actualStartDateTime,
    actualEndDateTime: staffedShiftModalData?.actualEndDateTime,
    actualUnpaidBreakMinutes:
      staffedShiftModalData?.actualUnpaidBreakMinutes || 0,
  };

  const [adjustPaidStaffing] = useAdjustPaidStaffingMutation({
    variables: {
      input: initValues,
    },
  });

  const { errors, register, handleSubmit, trigger, setValue } =
    useForm<FormValues>({
      mode: 'onChange',
      resolver: yupResolver(formSchema),
      defaultValues: initValues,
    });

  useEffect(() => {
    if (staffedShiftModalData) {
      const {
        actualStartDateTime,
        actualEndDateTime,
        actualUnpaidBreakMinutes,
      } = staffedShiftModalData;

      setUnpaidBreakMinutes(actualUnpaidBreakMinutes);
      if (actualStartDateTime) {
        const zonedActualStartDateTime = moment(
          utcToZonedTime(new Date(actualStartDateTime), timeZone).toISOString(),
        );
        setStartDate(zonedActualStartDateTime);
        setStartTime(zonedActualStartDateTime);
      }
      if (actualEndDateTime) {
        const zonedActualEndDateTime = moment(
          utcToZonedTime(new Date(actualEndDateTime), timeZone).toISOString(),
        );
        setEndDate(zonedActualEndDateTime);
        setEndTime(zonedActualEndDateTime);
      }
    }
  }, [staffedShiftModalData, timeZone]);

  const getFormData = useCallback(() => {
    const newStartDateTime = moment(startDate);
    newStartDateTime.set({
      hours: startTime?.hours(),
      minutes: startTime?.minutes(),
      seconds: startTime?.seconds(),
    });
    const newEndDateTime = moment(endDate);
    newEndDateTime.set({
      hours: endTime?.hours(),
      minutes: endTime?.minutes(),
      seconds: endTime?.seconds(),
    });

    register('id');
    register('actualStartDateTime');
    register('actualEndDateTime');
    register('actualUnpaidBreakMinutes');

    return {
      id: staffedShiftModalData?.id,
      actualStartDateTime: zonedTimeToUtc(newStartDateTime.toDate(), timeZone),
      actualEndDateTime: zonedTimeToUtc(newEndDateTime.toDate(), timeZone),
      actualUnpaidBreakMinutes: unpaidBreakMinutes,
    };
  }, [
    endDate,
    endTime,
    staffedShiftModalData?.id,
    startDate,
    startTime,
    timeZone,
    unpaidBreakMinutes,
    register,
  ]);

  const onSubmit =
    (cancelRelatedInvoice = false) =>
    async ({
      id,
      actualStartDateTime,
      actualEndDateTime,
      actualUnpaidBreakMinutes,
    }: FormValues) => {
      setIsLoading(true);
      id &&
        (await adjustPaidStaffing({
          variables: {
            input: {
              id,
              actualStartDateTime,
              actualEndDateTime,
              actualUnpaidBreakMinutes,
              cancelRelatedInvoice,
            },
          },
        }));
      setStaffedShiftModalData(null);
      onStaffingChanged();
      setIsLoading(false);
    };

  useEffect(() => {
    const {
      id,
      actualStartDateTime,
      actualEndDateTime,
      actualUnpaidBreakMinutes,
    } = getFormData();
    if (id && actualStartDateTime && actualEndDateTime) {
      setValue('id', id);
      setValue('actualStartDateTime', actualStartDateTime);
      setValue('actualEndDateTime', actualEndDateTime);
      setValue('actualUnpaidBreakMinutes', actualUnpaidBreakMinutes);
      trigger('actualEndDateTime');

      getEstimate({
        variables: {
          input: {
            id,
            actualStartDateTime,
            actualEndDateTime,
            actualUnpaidBreakMinutes,
          },
        },
      });
    }
  }, [
    staffedShiftModalData,
    unpaidBreakMinutes,
    getEstimate,
    getFormData,
    setValue,
    trigger,
  ]);

  const canCancelInvoice =
    staffedShiftModalData?.invoicingStatus === InvoicingStatus.INVOICED;

  const tenderPayoutDifference =
    estimateData?.adjustPaidStaffingEstimate.tenderPayoutDifference;

  const willCreateExtraPayment =
    tenderPayoutDifference && tenderPayoutDifference > 0;

  const keepInvoiceTitle = willCreateExtraPayment
    ? 'Save and Create New Payment'
    : 'Save Without Cancelling Invoice';

  return (
    <Modal
      open={staffedShiftModalData !== null}
      onClose={() => !pickerOpen && setStaffedShiftModalData(null)}
      className="h-5/6 w-1/2"
      fullHeight
    >
      <div className="flex h-full flex-col">
        <div className="border-support-line border-b px-6 py-4">
          {/* Prevent the calendar input to force autofocus */}
          <input type="text" className="h-0 p-0 opacity-0" autoFocus={true} />

          <h2 className="text-preset-3 text-ink mb-2.5">
            Edit Staffing Payment Information
          </h2>
          <p className="text-preset-5P text-ink mb-2">
            Edit the payment information
          </p>
        </div>
        <div className="flex overflow-x-hidden overflow-y-scroll">
          <div className="w-full py-4">
            <div className="border-support-line border-b px-4 pt-1 pb-2">
              <p className="text-preset-5 text-ink-dark mb-2.5 pl-2">
                Tender Info
              </p>
            </div>

            <div className="p-6">
              <div className=" border-support-line-darker bg-background-app flex w-full items-center rounded-lg border p-6">
                <div className="mr-3 h-12 w-12 overflow-hidden rounded-full">
                  {tender?.avatarURL ? (
                    <img
                      className="inline-block h-full w-full "
                      src={tender?.avatarURL}
                      alt="avatar"
                    />
                  ) : (
                    <div className="bg-primary-light text-ink-primary text-preset-4 flex h-full w-full flex-col justify-center text-center font-semibold">
                      {tender?.firstName && tender?.firstName[0]}
                      {tender?.lastName && tender?.lastName[0]}
                    </div>
                  )}
                </div>
                <div>
                  <p className="text-ink text-preset-6 font-medium ">
                    {tender?.firstName} {tender?.lastName}
                    {tender?.address?.city || tender?.address?.state ? '•' : ''}
                    {tender?.address?.city ? `${tender?.address?.city}, ` : ''}
                    {tender?.address?.state ? tender?.address?.state : ''}
                  </p>
                  {tender?.email && (
                    <p className="text-ink-not-as-dark text-preset-6 mt-1.5">
                      {tender?.email}
                    </p>
                  )}
                  <p className="text-ink-not-as-dark text-preset-6 mt-1.5 mb-1">
                    {tender?.id}
                  </p>
                </div>
              </div>
            </div>

            <div className=" border-support-line mb-3 border-b pt-2" />
            <div className="border-support-line border-b px-4 pt-1.5 pb-2">
              <p className="text-preset-5 text-ink-dark mb-2.5 pl-2">
                Shift Info
              </p>
            </div>
            <div className="p-6">
              <div className=" border-support-line-darker bg-background-app flex w-full items-center rounded-lg border p-6">
                <div>
                  <p className="text-ink text-preset-6 font-medium ">
                    {shift?.position.name}
                  </p>
                  <p className="text-ink-not-as-dark text-preset-6 mt-1.5">
                    {shift?.id}
                  </p>
                  <p className="text-ink-not-as-dark text-preset-6 mt-1.5 mb-1">
                    {`${shift?.job.name} • ${shift?.job.id}`}
                  </p>
                  <p className="text-ink-not-as-dark text-preset-6 mt-1.5 mb-1">
                    {shift?.job.client?.email}
                  </p>
                </div>
              </div>
            </div>

            <div className=" border-support-line mb-3 border-b pt-2" />
            <div className="border-support-line border-b px-4 pt-1.5 pb-2">
              <p className="text-preset-5 text-ink-dark mb-2.5 pl-2">
                Payment Info
              </p>
            </div>
            <div className="p-2 px-6">
              <p className="text-ink text-preset-6 mt-3 mb-2 font-medium">
                Actual Start Date
              </p>
              <div className="inline">
                {startDate && (
                  <DateInput
                    date={startDate}
                    onDateChange={(date) => setStartDate(date)}
                  />
                )}
              </div>
              <div className="ml-2 inline">
                <TimeInput
                  value={moment(startTime)}
                  onChange={setStartTime}
                  onOpen={() => setPickerOpen(true)}
                  onClose={() => setPickerOpen(false)}
                  minuteStep={1}
                />
              </div>
            </div>
            <div className="p-2 px-6">
              <p className="text-ink text-preset-6 mt-3 mb-2 font-medium">
                Actual End Date
              </p>
              <div className="inline">
                {endDate && (
                  <DateInput
                    date={endDate}
                    onDateChange={(date) => setEndDate(date)}
                  />
                )}
              </div>
              <div className="ml-2 inline">
                <TimeInput
                  value={moment(endTime)}
                  onChange={setEndTime}
                  onOpen={() => setPickerOpen(true)}
                  onClose={() => setPickerOpen(false)}
                  minuteStep={1}
                />
              </div>
              {errors.actualEndDateTime && (
                <div className="text-preset-6 text-status-destructive ml-2 mt-2">
                  {errors.actualEndDateTime?.message}
                </div>
              )}
            </div>

            <div className="p-2 px-6">
              <p className="text-ink text-preset-6 mt-3 mb-2 font-medium">
                Actual Unpaid Break Minutes
              </p>
              <select
                onChange={(ev) =>
                  setUnpaidBreakMinutes(parseInt(ev.currentTarget.value, 10))
                }
                className="focus:ring-primary focus:border-primary text-preset-5 border-support-line-darker rounded"
                value={unpaidBreakMinutes || 0}
              >
                <option value={0}>No break</option>
                {[...Array(60).keys()].map((n) => (
                  <option value={n + 1}>{n + 1} min</option>
                ))}
              </select>
            </div>

            <div className="border-support-line mb-3 border-b p-2 px-6 pb-6">
              <p className="text-ink text-preset-6 mt-3 mb-2 font-medium">
                Staffed Shift Description
              </p>
              <p>
                {getStaffedShiftDescription(staffedShiftModalData?.description)}
              </p>
            </div>

            <div className="border-support-line border-b px-4 pt-1.5 pb-2">
              <p className="text-preset-5 text-ink-dark mb-2.5 pl-2">Summary</p>
            </div>

            <div className="p-6">
              <div className="border-support-line-darker bg-background-app flex w-full items-center rounded-lg border p-6">
                {estimateLoading && <Loading className="m-auto" />}
                {estimateData && (
                  <Estimate data={estimateData.adjustPaidStaffingEstimate} />
                )}
              </div>
            </div>
            {canCancelInvoice && (
              <div className="p-6 pt-0">
                <div className="bg-status-warning-light text-ink-dark rounded-md p-4">
                  <p className="text-preset-5 mb-6">
                    This staffing is already part of an invoice. Please select
                    how to proceed:
                  </p>
                  <div className="bg-background-app border-ink-not-as-dark mb-6 flex items-center space-x-6 rounded-md border p-4">
                    <div className="text-preset-5P">
                      <p className="mb-4">Save and Cancel Invoice</p>
                      <p>
                        This will <b>cancel the invoice</b> and all staffings
                        that are part of it will be able to be re-invoiced, now
                        with the status <b>LIBERATED</b>.
                      </p>
                    </div>
                    <div>
                      <button
                        className={`text-preset-5 bg-primary text-ink-clear hover:bg-primary-active h-9 w-56 rounded px-5 ${
                          Object.keys(errors).length > 0
                            ? 'pointer-events-none opacity-50'
                            : ''
                        }`}
                        onClick={handleSubmit(onSubmit(true))}
                        disabled={Object.keys(errors).length > 0}
                      >
                        Save and Cancel Invoice
                      </button>
                    </div>
                  </div>
                  <div className="bg-background-app text-preset-5P border-ink-not-as-dark flex items-center space-x-6 rounded-md border p-4">
                    <div className="text-preset-5P">
                      <p className="mb-4">{keepInvoiceTitle}</p>
                      <p>
                        This <b>will not cancel the invoice. </b>
                        {willCreateExtraPayment ? (
                          <>
                            The staffing will have the status <b>LIBERATED</b>{' '}
                            and can be included in a new invoice in the future.
                          </>
                        ) : (
                          <>
                            The staffing will keep its <b>INVOICED</b> status.
                          </>
                        )}
                      </p>
                    </div>
                    <div>
                      <button
                        className={`text-preset-5 bg-primary text-ink-clear hover:bg-primary-active h-9 w-72 rounded px-5 ${
                          Object.keys(errors).length > 0
                            ? 'pointer-events-none opacity-50'
                            : ''
                        }`}
                        onClick={handleSubmit(onSubmit())}
                        disabled={Object.keys(errors).length > 0}
                      >
                        {keepInvoiceTitle}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="border-support-line flex w-full justify-end space-x-2 border-t p-4">
          <button
            className="text-preset-5 border-support-line rounded-md px-4 py-2 font-medium"
            onClick={() => {
              setStaffedShiftModalData(null);
            }}
          >
            Cancel
          </button>
          {!canCancelInvoice && (
            <button
              className={
                'text-preset-5 bg-primary text-ink-clear hover:bg-primary-active h-9 rounded px-5 font-medium'
              }
              onClick={handleSubmit(onSubmit())}
              disabled={Object.keys(errors).length > 0 || isLoading}
            >
              Save Changes
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default EditPaidStaffingModal;
