import React, { FC, useState, useEffect, useCallback } from 'react';
import debounce from 'lodash.debounce';
import Combobox from 'src/components/Combobox';
import Search from 'src/components/Icons/Search';
import Loading from 'src/components/Loading';
import Pill from 'src/components/Pill';
import { useLazyGetShiftQuery } from 'src/graphql/queries/GetShift';
import { GetShift_shift as ShiftItem } from '../../graphql/queries/__generated__/GetShift';

const ShiftResult: FC<{
  item: ShiftItem;
  index: number;
  highlighted: boolean;
}> = ({ item, highlighted }) => {
  return (
    <div
      className={`py-2 px-4 ${highlighted ? 'bg-background-app' : ''}`}
      data-cy={`shift-result-${item.id}`}
    >
      <p className="text-ink-not-as-dark text-sm">{item.id}</p>
    </div>
  );
};

type Props = {
  selectedShiftId: string | null;
  onChange: (selectedShiftId: string | null) => void;
  className: string;
};

const ShiftInput: FC<Props> = ({ selectedShiftId, onChange, className }) => {
  const [getShift, { data: shiftData, loading: shiftLoading }] =
    useLazyGetShiftQuery();
  const [selectedShift, setSelectedShift] = useState<ShiftItem | null>(null); // Adjusted to handle a single shift

  useEffect(() => {
    const fetchSelectedShift = async () => {
      if (!selectedShiftId) {
        return;
      }
      const response = await getShift({
        variables: {
          id: selectedShiftId,
        },
      });

      if (response?.data?.shift) {
        setSelectedShift(response.data.shift);
        onChange(response.data.shift.id);
      }
    };

    fetchSelectedShift();
  }, [selectedShiftId, onChange, getShift, setSelectedShift]);

  const addShift = (shift: ShiftItem) => {
    setSelectedShift(shift);
    onChange(shift.id);
  };

  const removeShift = () => {
    setSelectedShift(null);
    onChange(null);
  };

  //eslint-disable-next-line react-hooks/exhaustive-deps
  const handleInputChange = useCallback(
    debounce((value) => {
      if (!value) {
        return;
      }
      getShift({
        variables: {
          id: value,
        },
      });
    }, 300),
    [getShift],
  );

  return (
    <div className={className}>
      <Combobox
        id="shiftsCombobox"
        label="Shifts"
        description="Search by Shift ID"
        items={shiftData ? [shiftData.shift] : []}
        loadingItems={shiftLoading}
        placeholder="Search by ID"
        renderLoadingComponent={() => (
          <div className="flex justify-center">
            <Loading />
          </div>
        )}
        renderNoResultsComponent={() => (
          <p className="py-2 px-4">No shifts found</p>
        )}
        renderLeadingComponent={() => (
          <Search className="text-ink-not-as-dark h-3 w-3" />
        )}
        onReset={() => null}
        renderItemComponent={ShiftResult}
        onChange={(selectedItem) => selectedItem && addShift(selectedItem)}
        onInputChange={handleInputChange}
        data-cy="shift-filter"
      />{' '}
      {selectedShift && (
        <div className="selectedShifts flex flex-wrap">
          <Pill
            key={selectedShift.id}
            onClose={() => removeShift()}
            className="mr-2 mb-2"
            toolTipText={selectedShift.id}
            data-cy={`pill-${selectedShift.id}`}
          >
            {selectedShift.id}
          </Pill>
        </div>
      )}
    </div>
  );
};

export default ShiftInput;
