import React, { useContext, useEffect, useState } from 'react';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import ViewContainer from 'src/components/ViewContainer';
import Button from 'src/components/Button';
import { RosterSelectionContext } from 'src/contexts/RostersSelectionContext';
import { useBulkCreateRosterDocumentsMutation } from 'src/graphql/mutations/CreateRosterDocumentInBulk';
import { useBulkDeleteRosterAsAdminMutation } from 'src/graphql/mutations/DeleteRosterInBulk';

const RostersSelectionToolbar: React.FC = () => {
  const [activeDialog, setActiveDialog] = useState<
    'deleteAllConfirmation' | 'downloadAllConfirmation'
  >();
  const closeDialog = () => setActiveDialog(undefined);
  const selectedRostersContext = useContext(RosterSelectionContext);
  const {
    selectionCount,
    dispatch: selectedRostersDispatch,
    state: selectedRosterIds,
  } = selectedRostersContext || {};
  const [bulkOperationErrors, setBulkOperationErrors] = useState<string[]>();

  const [deleteInBulkMutation] = useBulkDeleteRosterAsAdminMutation();
  const [downloadInBulkMutation] = useBulkCreateRosterDocumentsMutation();
  const handleOperationInBulk = async (
    operation: 'downloadRosters' | 'deleteRosters',
  ) => {
    if (!selectedRosterIds || selectedRosterIds.size <= 0) {
      return;
    }
    if (operation === 'deleteRosters') {
      await deleteInBulkMutation({
        variables: { rosterIds: Array.from(selectedRosterIds) },
      });
    }
    if (operation === 'downloadRosters') {
      await downloadInBulkMutation({
        variables: { rosterIds: Array.from(selectedRosterIds) },
      });
    }
    selectedRostersDispatch?.({ type: 'clear' });
  };

  /**
   * clear selected rosters when moving out of the view
   */
  useEffect(
    () => () => selectedRostersDispatch?.({ type: 'clear' }),
    [selectedRostersDispatch],
  );

  if (!selectionCount) {
    return null;
  }

  return (
    <div className="bg-background-surface border-support-line shadow-bottom-toolbar fixed bottom-0 w-full border-t">
      <ViewContainer className="py-2">
        <div className="flex">
          <div className="flex flex-1 items-center">
            <div className="text-ink-not-as-dark text-preset-6 ml-2 mr-6">
              {selectionCount} Selected
            </div>
            <Button
              onClick={() => selectedRostersDispatch?.({ type: 'clear' })}
            >
              Unselect all
            </Button>
          </div>
          <div className="flex">
            <Button
              className="mr-4"
              primary
              onClick={() => setActiveDialog('downloadAllConfirmation')}
            >
              Download all
            </Button>
            <Button
              destructive
              noBackground
              onClick={() => setActiveDialog('deleteAllConfirmation')}
            >
              Delete All
            </Button>
          </div>
        </div>
      </ViewContainer>
      <ConfirmationDialog
        title="Download selected Rosters?"
        description="All selected Rosters will generate a PDF document"
        open={activeDialog === 'downloadAllConfirmation'}
        submitText="Download all"
        onSubmit={async () => {
          await handleOperationInBulk('downloadRosters');
          closeDialog();
        }}
        onClose={closeDialog}
      />
      <ConfirmationDialog
        title="Delete Rosters?"
        description="All selected Rosters will be permanently deleted."
        open={activeDialog === 'deleteAllConfirmation'}
        submitText="Delete all"
        onSubmit={async () => {
          await handleOperationInBulk('deleteRosters');
          closeDialog();
        }}
        submitClassName="text-status-destructive"
        onClose={closeDialog}
      />
      <ConfirmationDialog
        title="Operation Errors"
        description={
          bulkOperationErrors
            ?.map((errorMessage) => `- ${errorMessage}`)
            .join('\n') ?? ''
        }
        open={Boolean(bulkOperationErrors?.length)}
        cancelText="Close"
        onClose={() => setBulkOperationErrors(undefined)}
      />
    </div>
  );
};

export default RostersSelectionToolbar;
