import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  UpdateShiftAsAdmin,
  UpdateShiftAsAdminVariables,
} from './__generated__/UpdateShiftAsAdmin';

const UPDATE_SHIFT = gql`
  mutation UpdateShiftAsAdmin($input: UpdateShiftInput!) {
    updateShift(input: $input) {
      __typename
      id
      quantity
      rate
      margin
      staffed
      startDateTime
      endDateTime
      tip {
        type
        amount
      }
    }
  }
`;

export const useUpdateShiftAsAdminMutation = (
  options?: MutationHookOptions<
    UpdateShiftAsAdmin,
    UpdateShiftAsAdminVariables
  >,
) =>
  useMutation<UpdateShiftAsAdmin, UpdateShiftAsAdminVariables>(
    UPDATE_SHIFT,
    options,
  );

export default UPDATE_SHIFT;
