import React, { useState } from 'react';
import { format } from 'date-fns-tz';
import ReactJson from 'react-json-view';

import Button from 'src/components/Button';
import Pill from 'src/components/Pill';
import ShareIcon from 'src/components/Icons/Share';
import Loading from 'src/components/Loading';
import Modal from 'src/components/Modal';
import GET_TENDER_CHANGES, {
  useGetTenderChangesForAdminQuery,
} from 'src/graphql/queries/GetTenderChangeLogs';
import {
  GetTenderChangesForAdmin,
  GetTenderChangesForAdmin_tenderChangesPaginated_edges_node as TenderChanges,
} from 'src/graphql/queries/__generated__/GetTenderChangesForAdmin';

const getAuthorPill = (role: string, email: string) => {
  if (role === 'TENDER') {
    return (
      <Pill bgColor="bg-purple-100" textColor="text-purple-800">
        {role}
      </Pill>
    );
  } else {
    return (
      <Pill bgColor="bg-pink-100" textColor="text-pink-800">
        {`${role} (${email})`}
      </Pill>
    );
  }
};

type ChangeSet = {
  after?: JSON;
  before?: JSON;
  entityName: string;
  id: string;
};

const ChangeSetModal: React.FC<{
  open: boolean;
  onClose: () => void;
  changeSets: ChangeSet[];
}> = ({ open, changeSets, onClose }) => {
  return (
    <Modal
      className="h-6/12 w-6/12 p-4"
      open={open}
      onClose={onClose}
      fullHeight
    >
      {changeSets.map((changeSet) => (
        <div className="flex" key={changeSet.id}>
          <div className="w-6/12">
            <p className="text-ink-dark text-preset-5">
              Changes on {changeSet.entityName} entity
            </p>
            <ReactJson
              displayDataTypes={false}
              enableClipboard={false}
              name="before"
              src={changeSet.before || { info: 'Object was created' }}
            />
          </div>
          <div className="mt-4 w-6/12">
            <ReactJson
              displayDataTypes={false}
              enableClipboard={false}
              name="after"
              src={changeSet.after || { info: 'Object was deleted' }}
            />
          </div>
        </div>
      ))}
      <div className="border-support-line-darker -mx-4 my-4 border-t" />
      <div className="flex justify-end">
        <Button onClick={onClose}>Close</Button>
      </div>
    </Modal>
  );
};

const TenderChangeSet: React.FC<{ tenderChanges: TenderChanges }> = ({
  tenderChanges,
}) => {
  const [open, setIsOpen] = useState(false);

  return (
    <div className="relative flex">
      <ChangeSetModal
        onClose={() => setIsOpen(false)}
        open={open}
        changeSets={tenderChanges.changeSets}
      />
      <div className="bg-primary-light absolute top-0 left-1.5 h-16 w-0.5" />
      <div className="bg-brand-50 absolute top-5 left-px h-3 w-3 rounded-full" />
      <div className="ml-6">
        <div className="mb-1 flex items-center">
          <p className="text-preset-6 text-ink-not-as-dark mr-2">
            {format(new Date(tenderChanges.createdAt), 'MM/dd/yyyy, hh:mm')}
          </p>
          {getAuthorPill(tenderChanges.authorRole, tenderChanges.authorEmail)}
          <div onClick={() => setIsOpen(true)}>
            <ShareIcon className="text-ink-not-as-dark ml-3 cursor-pointer" />
          </div>
        </div>
        <p className="text-preset-6 text-ink-not-as-dark mb-4">
          {tenderChanges.operation}
        </p>
      </div>
    </div>
  );
};

const ChangeLogCard: React.FC<{ tenderId: string }> = ({ tenderId }) => {
  const { data, loading, fetchMore } = useGetTenderChangesForAdminQuery({
    variables: {
      tenderId,
    },
  });
  const updateQuery = (
    prev: GetTenderChangesForAdmin,
    { fetchMoreResult }: { fetchMoreResult?: GetTenderChangesForAdmin },
  ) => {
    if (!fetchMoreResult) {
      return prev;
    }
    return Object.assign({}, prev, {
      tenderChangesPaginated: {
        ...fetchMoreResult.tenderChangesPaginated,
        edges: [
          ...prev.tenderChangesPaginated.edges,
          ...fetchMoreResult.tenderChangesPaginated.edges,
        ],
      },
    });
  };
  const handleLoadMoreClick = async () => {
    await fetchMore({
      query: GET_TENDER_CHANGES,
      variables: {
        tenderId,
        after: data?.tenderChangesPaginated.pageInfo.endCursor,
      },
      updateQuery,
    });
  };
  return (
    <div className="mr-4 mb-4">
      <div className="mb-4 px-4 py-6">
        <p className="text-preset-3 text-ink-dark mb-4 font-medium">
          Change Log
        </p>
        <div>
          {!data || loading ? (
            <Loading />
          ) : (
            <>
              {data.tenderChangesPaginated.edges?.map((tenderChange) => {
                if (tenderChange.node.detailsChanged === 'TENDER_PAYMENT') {
                  return null;
                }
                return (
                  <TenderChangeSet
                    tenderChanges={tenderChange.node}
                    key={tenderChange.node.id}
                  />
                );
              })}
              {data.tenderChangesPaginated.pageInfo.hasNextPage && (
                <div
                  className="border-support-lines mt-4 cursor-pointer border-t pt-6"
                  onClick={handleLoadMoreClick}
                >
                  <p className="text-preset-6 text-primary font-medium">
                    Load more
                  </p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChangeLogCard;
