import React from 'react';

const ThumbDown: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={className}
    viewBox="0 -1 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#thumb-down-clip)">
      <path
        d="M0.642515 7.06536C0.569393 7.30509 0.553484 7.55863 0.596065 7.80562C0.638645 8.05262 0.738529 8.28619 0.887702 8.4876C1.03687 8.68901 1.23118 8.85265 1.45503 8.96539C1.67888 9.07813 1.92604 9.13683 2.17668 9.13678H5.43168C5.55481 9.13675 5.67594 9.16792 5.78376 9.2274C5.89157 9.28687 5.98255 9.37269 6.04821 9.47686C6.11387 9.58102 6.15205 9.70013 6.1592 9.82305C6.16635 9.94597 6.14223 10.0687 6.0891 10.1798L5.2561 11.9257C5.17971 12.1525 5.16212 12.395 5.20496 12.6305C5.24781 12.8659 5.3497 13.0867 5.5011 13.272C5.641 13.4444 5.81892 13.582 6.02091 13.674C6.2229 13.7661 6.44346 13.8101 6.66532 13.8027C6.88717 13.7952 7.10428 13.7364 7.29963 13.631C7.49498 13.5256 7.66325 13.3764 7.79126 13.195L10.6438 9.15369C10.7112 9.05845 10.8005 8.98073 10.9041 8.92704C11.0077 8.87336 11.1226 8.84526 11.2393 8.84511C11.3111 8.84511 11.38 8.81659 11.4307 8.76583C11.4815 8.71507 11.51 8.64623 11.51 8.57444V1.97519C11.51 1.9204 11.4946 1.86672 11.4655 1.8203C11.4364 1.77387 11.3948 1.73658 11.3455 1.71269C9.1866 0.668523 8.47026 0.530273 6.71268 0.530273C6.51026 0.530273 5.35935 0.54194 4.93877 0.54194C3.37543 0.54194 2.33535 1.45019 1.7561 3.32969L0.647765 7.04494L0.642515 7.06536Z"
        fill="currentColor"
      />
      <path
        d="M12.3848 8.84505H12.3999C12.4343 9.04845 12.5394 9.23315 12.6968 9.36657C12.8541 9.49999 13.0535 9.57353 13.2598 9.57422H14.1348C14.2121 9.57422 14.2863 9.54349 14.341 9.48879C14.3957 9.4341 14.4264 9.35991 14.4264 9.28255V1.11589C14.4264 1.03853 14.3957 0.964344 14.341 0.909646C14.2863 0.854948 14.2121 0.824219 14.1348 0.824219H13.2598C13.0277 0.824219 12.8051 0.916406 12.641 1.0805C12.477 1.24459 12.3848 1.46715 12.3848 1.69922V8.84505Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="thumb-down-clip">
        <rect
          width="14"
          height="14"
          fill="currentColor"
          transform="translate(0.5 0.166992)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ThumbDown;
