import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  CreatePlaceholderTender,
  CreatePlaceholderTenderVariables,
} from './__generated__/CreatePlaceholderTender';

const PUBLISH_JOB = gql`
  mutation CreatePlaceholderTender($input: CreatePlaceholderTenderInput!) {
    createPlaceholderTender(input: $input) {
      id
      __typename
    }
  }
`;

export const useCreatePlaceholderTenderMutation = (
  options?: MutationHookOptions<
    CreatePlaceholderTender,
    CreatePlaceholderTenderVariables
  >,
) =>
  useMutation<CreatePlaceholderTender, CreatePlaceholderTenderVariables>(
    PUBLISH_JOB,
    options,
  );

export default PUBLISH_JOB;
