import React from 'react';

const Copy: React.FC<{ className?: string; height?: string }> = ({
  className,
  height = '20px',
}) => (
  <svg
    width="12"
    height={height}
    viewBox="0 0 12 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M11.6547 3.21267L9.746 1.19201C9.63257 1.0777 9.49752 0.987121 9.34873 0.925542C9.19993 0.863964 9.04037 0.832621 8.87933 0.83334H3.55533C3.23135 0.833693 2.92073 0.962553 2.69164 1.19165C2.46255 1.42074 2.33369 1.73135 2.33333 2.05534V3.16667H1.222C0.898014 3.16703 0.587398 3.29589 0.358305 3.52498C0.129212 3.75407 0.000352926 4.06469 0 4.38867V13.9447C0.000352926 14.2687 0.129212 14.5793 0.358305 14.8084C0.587398 15.0375 0.898014 15.1663 1.222 15.1667H8.44467C8.76865 15.1663 9.07927 15.0375 9.30836 14.8084C9.53745 14.5793 9.66631 14.2687 9.66667 13.9447V12.8333H10.778C11.102 12.833 11.4126 12.7041 11.6417 12.475C11.8708 12.2459 11.9996 11.9353 12 11.6113V4.06667C12.0015 3.9086 11.9718 3.75177 11.9125 3.60522C11.8533 3.45866 11.7656 3.32526 11.6547 3.21267ZM8 13.8333H1.66667C1.57826 13.8333 1.49348 13.7982 1.43096 13.7357C1.36845 13.6732 1.33333 13.5884 1.33333 13.5V4.83334C1.33333 4.74494 1.36845 4.66015 1.43096 4.59764C1.49348 4.53513 1.57826 4.50001 1.66667 4.50001H2.16667C2.21087 4.50001 2.25326 4.51757 2.28452 4.54882C2.31577 4.58008 2.33333 4.62247 2.33333 4.66667V11.6113C2.33333 11.9354 2.46208 12.2463 2.69125 12.4754C2.92042 12.7046 3.23124 12.8333 3.55533 12.8333H8.16667C8.21087 12.8333 8.25326 12.8509 8.28452 12.8822C8.31577 12.9134 8.33333 12.9558 8.33333 13V13.5C8.33333 13.5884 8.29821 13.6732 8.2357 13.7357C8.17319 13.7982 8.08841 13.8333 8 13.8333ZM10.3333 11.5H4C3.91159 11.5 3.82681 11.4649 3.7643 11.4024C3.70179 11.3399 3.66667 11.2551 3.66667 11.1667V2.50001C3.66667 2.4116 3.70179 2.32682 3.7643 2.2643C3.82681 2.20179 3.91159 2.16667 4 2.16667H8.69C8.73534 2.16675 8.78018 2.17607 8.82179 2.19406C8.8634 2.21206 8.9009 2.23835 8.932 2.27134L10.5753 4.01134C10.6338 4.07311 10.6665 4.15492 10.6667 4.24001V11.1667C10.6667 11.2551 10.6315 11.3399 10.569 11.4024C10.5065 11.4649 10.4217 11.5 10.3333 11.5Z"
      fill="#6D727C"
    />
  </svg>
);

export default Copy;
