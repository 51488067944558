import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  split,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import fetch from 'cross-fetch';
import introspectionQueryResultData from 'src/fragmentTypes.json';
import { GRAPHQL_ROUTE, GRAPHQL_SUBSCRIPTIONS_ROUTE } from 'src/routes';

import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';

const httpLink = new HttpLink({
  uri: GRAPHQL_ROUTE,
  fetch: process.env.NODE_ENV === 'test' ? fetch : undefined,
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: GRAPHQL_SUBSCRIPTIONS_ROUTE,
    connectionParams: () => ({
      authToken: localStorage.getItem('authToken'),
    }),
  }),
);

const authLink = setContext((_, { headers }) => {
  const authToken = localStorage.getItem('authToken');
  return {
    headers: {
      ...headers,
      authorization: authToken ? `Bearer ${authToken}` : '',
    },
  };
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  authLink.concat(wsLink),
  authLink.concat(httpLink),
);

const fieldPolicyForSortedListsWithoutInfiniteScroll = {
  /**
   * This is a workaround for the UI to not jump when sorting the staffings
   * sub-lists, it is sort of an optimistic sort strategy, as it bypasses the
   * default Apollo behavior to create a new, empty store for a request, by
   * signaling that we want to reuse the previous request results. We do not
   * use the previous results here, but we avoid getting a new and empty cache
   * store for the sort request.
   *
   * Be careful if implementing pagination for these same queries, as they
   * will not work as expected, there needs to be a true pagination strategy
   * for that on the "merge" method
   **/
  keyArgs: ['shiftId'],
  /** Replace the data with the new, sorted data */
  merge(_: unknown, incoming: unknown) {
    return incoming;
  },
};

export const apolloClientCache = new InMemoryCache({
  possibleTypes: introspectionQueryResultData.possibleTypes,
  typePolicies: {
    Query: {
      fields: {
        shifts: {
          // Don't cache separate results based on
          // any of this field's arguments.
          keyArgs: ['filters'],
          // Concatenate the incoming list items with
          // the existing list items.
          merge(existing = {}, incoming, options) {
            // ignore existing when no cursor is set
            if (!options.args?.after) {
              return incoming;
            }

            return {
              ...incoming,
              edges: [...(existing?.edges || []), ...incoming.edges],
            };
          },
        },
        jobs: {
          // Don't cache separate results based on
          // any of this field's arguments.
          keyArgs: ['filters'],
          // Merge the incoming list items with
          // the existing list items.
          merge(existing = {}, incoming, options) {
            // ignore existing when no cursor is set
            if (!options.args?.after) {
              return incoming;
            }

            return {
              ...incoming,
              edges: [...(existing?.edges || []), ...incoming.edges],
            };
          },
        },
        tenders: {
          // Don't cache separate results based on
          // any of this field's arguments.
          keyArgs: ['filters'],
          // Concatenate the incoming list items with
          // the existing list items.
          merge(existing = {}, incoming, options) {
            // ignore existing when no cursor is set
            if (options.args?.paginationOptions.page === 1) {
              return incoming;
            }
            return {
              ...incoming,
              items: [...(existing?.items || []), ...incoming.items],
            };
          },
        },
        tender: {
          merge(existing, incoming, options) {
            return {
              ...(existing || {}),
              ...incoming,
            };
          },
        },
        rosters: {
          keyArgs: [],
          merge(existing, incoming, options) {
            // ignore existing when no cursor is set
            if (!options.args?.after) {
              return incoming;
            }

            return {
              ...incoming,
              edges: [...(existing?.edges || []), ...incoming.edges],
            };
          },
        },
        tenderRosters: {
          // Don't cache separate results based on
          // any of this field's arguments.
          keyArgs: ['rosterId', 'filters'],
          merge(existing = {}, incoming, options) {
            // ignore existing when no cursor is set
            if (!options.args?.after) {
              return incoming;
            }

            return {
              ...incoming,
              edges: [...(existing?.edges || []), ...incoming.edges],
            };
          },
        },
        updateRegion: {
          // when updating a region's polygon, don't merge and just accept
          // incoming as result
          merge(exiting, incoming) {
            return incoming;
          },
        },
        tenderPayments: {
          // Don't cache separate results based on
          // any of this field's arguments.
          keyArgs: ['filters', 'orderByDirection', 'orderByField'],
          // Concatenate the incoming list items with
          // the existing list items.
          merge(existing = {}, incoming, options) {
            // ignore existing when no cursor is set
            if (!options.args?.after) {
              return incoming;
            }

            return {
              ...incoming,
              edges: [...(existing?.edges || []), ...incoming.edges],
            };
          },
        },
        adminTenderNotifications: {
          keyArgs: ['filters'],
          merge(existing, incoming, options) {
            // ignore existing when no cursor is set
            if (!options.args?.after) {
              return incoming;
            }

            return {
              ...incoming,
              edges: [...(existing?.edges || []), ...incoming.edges],
            };
          },
        },
        approvedJobApplicationsPaginated:
          fieldPolicyForSortedListsWithoutInfiniteScroll,
        pendingJobApplicationsPaginated:
          fieldPolicyForSortedListsWithoutInfiniteScroll,
        removedJobApplicationsPaginated:
          fieldPolicyForSortedListsWithoutInfiniteScroll,
      },
    },
  },
});

const client = new ApolloClient({
  connectToDevTools: true,
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path, extensions }) => {
          console.error(
            `[GraphQL error]: Message: ${JSON.stringify(
              message,
            )}, Location: ${JSON.stringify(locations)}, Path: ${JSON.stringify(
              path,
            )}`,
          );
          if (!extensions) {
            return;
          }
          switch (extensions.code) {
            case 'AUTHORIZATION_ERROR':
              localStorage.removeItem('authToken');
              window.location.replace('/signin');
          }
        });
      }
      if (networkError) {
        console.error(`[Network error]: ${networkError}`);
      }
    }),
    splitLink,
  ]),
  cache: apolloClientCache,
});

export default client;
