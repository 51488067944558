import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  BulkCreateTenderPayouts,
  BulkCreateTenderPayoutsVariables,
} from './__generated__/BulkCreateTenderPayouts';

const BULK_CREATE_TENDER_PAYOUTS_MUTATION = gql`
  mutation BulkCreateTenderPayouts($fileContents: String!) {
    bulkCreateTenderPayouts(fileContents: $fileContents)
  }
`;

export const useBulkCreateTenderPayoutsMutation = (
  options?: MutationHookOptions<
    BulkCreateTenderPayouts,
    BulkCreateTenderPayoutsVariables
  >,
) =>
  useMutation<BulkCreateTenderPayouts, BulkCreateTenderPayoutsVariables>(
    BULK_CREATE_TENDER_PAYOUTS_MUTATION,
    options,
  );

export default BULK_CREATE_TENDER_PAYOUTS_MUTATION;
