import React, { useEffect, useState } from 'react';
import { useParams, useHistory, Link, useLocation } from 'react-router-dom';

import Loading from 'src/components/Loading';
import { useRegionQuery } from 'src/graphql/queries/Region';
import { useUpdateRegionMutation } from 'src/graphql/mutations/UpdateRegion';
import { useCreateRegionMutation } from 'src/graphql/mutations/CreateRegion';
import ChevronUp from 'src/components/Icons/ChevronUp';
import Pencil from 'src/components/Icons/Pencil';
import RegionMap from './RegionMap';

const defaultRegion = [
  { lat: 40.07288188259529, lng: -101.72045654296875 },
  { lat: 35.53927406483401, lng: -94.90006323242186 },
  { lat: 35.41934126143559, lng: -107.98266357421875 },
];

interface Coordinates {
  lat?: number;
  lng?: number;
}

const getDefaultPolygon = (point: Coordinates) => {
  if (!point.lat || !point.lng) {
    return defaultRegion;
  }
  return [
    { lat: point.lat + 0.1, lng: point.lng },
    { lat: point.lat - 0.1, lng: point.lng + 0.13 },
    { lat: point.lat - 0.1, lng: point.lng - 0.13 },
  ];
};

const RegionDetailView: React.FC = () => {
  const { replace } = useHistory();
  const { state = { regionName: '', coordinates: {} } } = useLocation<{
    regionName?: string;
    coordinates?: Coordinates;
  }>();
  const defaultRegionName = state.regionName ?? '';
  const defaultCoordinates = state.coordinates ?? {};

  const { regionId } = useParams<{ regionId: string }>();
  const [getRegionQuery, { data: regionData, loading }] = useRegionQuery({
    variables: { id: regionId },
  });

  const [regionName, setRegionName] = useState(defaultRegionName);
  const [regionPolygon, setRegionPolygon] = useState<
    Array<google.maps.LatLngLiteral>
  >(getDefaultPolygon(defaultCoordinates));
  const [disableSave, setDisableSave] = useState(true);
  const [isEditing, setIsEditing] = useState(false);

  const [createRegion] = useCreateRegionMutation({
    variables: {
      input: {
        name: regionName,
        polygon: regionPolygon,
      },
    },
    onCompleted(data) {
      if (data.createRegion) {
        replace(`/region/${data.createRegion.id}`);
      }
    },
  });
  const [updateRegion] = useUpdateRegionMutation({
    variables: {
      input: {
        id: regionId,
        name: regionName,
        polygon: regionPolygon,
      },
    },
  });

  const onRegionNameChange = (e: React.FormEvent<HTMLInputElement>) => {
    setRegionName(e.currentTarget.value);
    setDisableSave(!e.currentTarget.value);
  };

  const onCoordinatesChange = (regionCoords: Array<google.maps.LatLng>) => {
    setDisableSave(false);
    const newPolygon = regionCoords.map((point) => ({
      lat: point.lat(),
      lng: point.lng(),
    }));
    setRegionPolygon(newPolygon);
  };

  const onRegionSave = async () => {
    setIsEditing(false);
    setDisableSave(true);
    regionId === 'new' ? await createRegion() : await updateRegion();
  };

  useEffect(() => {
    if (!regionData?.region) {
      return;
    }
    setRegionName(regionData.region.name);
    setRegionPolygon(
      regionData.region.polygon.map(
        // eslint-disable-next-line @typescript-eslint/naming-convention
        ({ __typename, ...coordinates }) => coordinates,
      ),
    );
  }, [regionData]);
  useEffect(() => {
    regionId !== 'new' && getRegionQuery();
  }, [regionId, getRegionQuery]);

  return (
    <div className="relative h-full">
      {!loading ? (
        <RegionMap
          regionCoords={regionPolygon}
          onCoordinatesChange={onCoordinatesChange}
        />
      ) : (
        <Loading />
      )}
      <div className="absolute mt-6 w-full">
        <div className="flex">
          <div className="sm:display-none md:w-2/12" />
          <div className="bg-background-surface border-color-gray-200 mx-8 items-center justify-between rounded-md p-4 shadow-2xl md:flex md:w-8/12">
            <div className="items-center md:flex">
              <Link to="/regions">
                <ChevronUp className="text-ink ml-2 h-4 w-4 -rotate-90 transform" />
              </Link>
              <p className="text-preset-3 text-ink ml-8">Regions /</p>
              {isEditing ? (
                <input
                  className="bg-background-surface ml-2 w-80 rounded-lg border border-gray-300"
                  type="text"
                  value={regionName}
                  onInput={onRegionNameChange}
                  autoFocus
                  onBlur={() => {
                    setIsEditing(false);
                  }}
                />
              ) : (
                <div
                  className="item-center bg-background-app ml-2 flex cursor-pointer px-2"
                  onClick={() => setIsEditing(true)}
                >
                  <p className="text-preset-3 text-ink">{regionName}</p>
                  <Pencil className="w-6 pl-2" />
                </div>
              )}
            </div>
            <button
              className="text-preset-5 bg-primary text-ink-clear hover:bg-primary-active h-9 rounded px-5 font-medium"
              onClick={onRegionSave}
              disabled={disableSave}
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>
      <div className="bg-background-surface absolute bottom-0 right-20 mb-10 ml-8 rounded-md p-4 shadow-2xl">
        <p className="text-preset-7 text-ink">
          + Right click in-between points to add a point
        </p>
        <p className="text-preset-7 text-ink">- Left click to remove a point</p>
      </div>
    </div>
  );
};

export default RegionDetailView;
