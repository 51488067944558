import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { GetJobs, GetJobsVariables } from './__generated__/GetJobs';

const GET_JOBS = gql`
  query GetJobs($after: String, $filters: JobsFilters!) {
    jobs(first: 10, after: $after, filters: $filters) {
      pageInfo {
        hasNextPage
        endCursor
        totalCount
      }
      edges {
        node {
          id
          name
          startDateTime
          endDateTime
          name
          hiddenFromOpenJobs
          hiddenFromClient
          published
          total
          staffed
          isFlexpoolOrder
          isNowsta2
          status
          attachments {
            fileName
            url
          }
          venue {
            id
            name
            address {
              timezone
              city
            }
          }
          shifts {
            id
            quantity
            staffedSlots
          }
          client {
            firstName
            lastName
            email
          }
        }
      }
    }
  }
`;

export const useGetJobsQuery = (
  options?: QueryHookOptions<GetJobs, GetJobsVariables>,
) => useQuery<GetJobs, GetJobsVariables>(GET_JOBS, options);

export default GET_JOBS;
