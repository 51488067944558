import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  CreateShiftNote,
  CreateShiftNoteVariables,
} from './__generated__/CreateShiftNote';

const CREATE_SHIFT_NOTE = gql`
  mutation CreateShiftNote($input: CreateShiftNoteInput!) {
    createShiftNote(input: $input) {
      __typename
      id
      note
      updatedAt
      creator {
        email
      }
    }
  }
`;

export const useCreateShiftNote = (
  options?: MutationHookOptions<CreateShiftNote, CreateShiftNoteVariables>,
) =>
  useMutation<CreateShiftNote, CreateShiftNoteVariables>(
    CREATE_SHIFT_NOTE,
    options,
  );

export default CREATE_SHIFT_NOTE;
