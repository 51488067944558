import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import {
  GetTenderAssessmentsForAdmin,
  GetTenderAssessmentsForAdminVariables,
} from './__generated__/GetTenderAssessmentsForAdmin';

const GET_TENDER_ASSESSMENTS = gql`
  query GetTenderAssessmentsForAdmin(
    $pagination: PaginationOptions!
    $filters: TenderAssessmentFilters
  ) {
    tenderAssessments(filters: $filters, pagination: $pagination) {
      items {
        id
        finalNotes
        lastScheduledInterview {
          startDateTime
          interviewer {
            email
          }
          assessment {
            id
            processedAt
            completedAt
            positionApplications {
              status
              createdAt
              position {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const useGetTenderAssessmentsForAdminQuery = (
  options?: QueryHookOptions<
    GetTenderAssessmentsForAdmin,
    GetTenderAssessmentsForAdminVariables
  >,
) =>
  useQuery<GetTenderAssessmentsForAdmin, GetTenderAssessmentsForAdminVariables>(
    GET_TENDER_ASSESSMENTS,
    options,
  );

export default GET_TENDER_ASSESSMENTS;
