import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import debounce from 'lodash.debounce';
import { usePopper } from 'react-popper';
import apolloClient from 'src/ApolloClient';

import Combobox from 'src/components/Combobox';
import Pill from 'src/components/Pill';
import ChevronDown from 'src/components/Icons/ChevronDown';
import Search from 'src/components/Icons/Search';
import CLIENTS_QUERY, { useClientsQuery } from 'src/graphql/queries/Clients';
import {
  Clients_getClients_items as Client,
  Clients_getClients_items as ClientItem,
} from 'src/graphql/queries/__generated__/Clients';
import pluralize from 'pluralize';

type ClientsMap = Record<string, ClientItem>;

interface Props {
  selectedClients?: ClientItem[];
  onChange?: (selectedClients: ClientItem[] | undefined) => void;
}

interface WrappedInputProps {
  selectedClientsIds?: string[];
  onChange?: (selectedClients: ClientItem[] | undefined) => void;
}

export const WrappedClientsInput: FC<WrappedInputProps> = ({
  selectedClientsIds,
  onChange,
}) => {
  const [selectedClients, setSelectedClients] = useState<Client[]>([]);
  const getAllSelectedClients = useCallback(async () => {
    if (!selectedClientsIds) {
      setSelectedClients([]);
      return;
    }

    const clients = await Promise.all(
      selectedClientsIds.map(async (clientId) => {
        const { data: clientData } = await apolloClient.query({
          query: CLIENTS_QUERY,
          variables: {
            paginationOptions: {
              limit: 1,
              page: 1,
            },
            queryOptions: {
              searchTerm: clientId,
            },
          },
        });
        return clientData?.getClients.items[0];
      }),
    );
    setSelectedClients(clients);
  }, [selectedClientsIds]);

  useEffect(() => {
    getAllSelectedClients();
  }, [getAllSelectedClients]);

  return <ClientsInput selectedClients={selectedClients} onChange={onChange} />;
};

const ClientResult: FC<{
  item: ClientItem;
  index: number;
  highlighted: boolean;
}> = ({ item, index, highlighted }) => (
  <div
    className="hover:bg-background-app py-2 px-4"
    data-cy={`client-result-${item.email?.split('@')[0]}`}
  >
    <p className="text-preset-6 text-ink-dark font-medium">{item.email}</p>
    <p className="text-preset-6 text-ink-not-as-dark">{item.id}</p>
  </div>
);

const createClientsMap = (clients: ClientItem[]) =>
  clients.reduce(
    (map: ClientsMap, client) => ({ ...map, [client.id]: client }),
    {},
  );

export const ClientFilter: FC<{
  onChange: (client: ClientItem) => void;
  onReset?: () => void;
  placeholder?: string;
}> = ({
  onChange,
  onReset = () => null,
  placeholder = 'Search by id, email or org name',
}) => {
  const [getClients, { loading, data }] = useClientsQuery();

  const inputChange = useRef(
    debounce((value) => {
      if (!value) {
        return;
      }
      getClients({
        variables: {
          paginationOptions: {
            limit: 10,
            page: 1,
          },
          queryOptions: {
            searchTerm: value,
          },
        },
      });
    }, 300),
  ).current;

  return (
    <Combobox
      id="clientsCombobox"
      label=""
      items={data?.getClients.items || []}
      loadingItems={loading}
      placeholder={placeholder}
      renderLoadingComponent={() => <p className="py-2 px-4">loading...</p>}
      renderNoResultsComponent={() => (
        <p className="py-2 px-4">No clients found</p>
      )}
      renderLeadingComponent={() => (
        <Search className="text-ink-not-as-dark h-3 w-3" />
      )}
      onReset={onReset}
      renderItemComponent={ClientResult}
      onChange={(selectedItem) => selectedItem && onChange(selectedItem)}
      onInputChange={inputChange}
      data-cy="client-filter"
    />
  );
};

const ClientsInput: FC<Props> = ({ selectedClients, onChange }) => {
  const [isActive, setIsActive] = useState(false);
  const baseElement = useRef<HTMLButtonElement>(null);
  const popElement = useRef<HTMLDivElement>(null);
  const [temporarySelectedClients, setTemporarySelectedClients] =
    useState<ClientsMap>({});

  const hasUnappliedClientFilters =
    Object.keys(temporarySelectedClients).length !== selectedClients?.length;
  const shouldDisplayClientFilterActions =
    selectedClients?.length || hasUnappliedClientFilters;

  const addClient = (client: ClientItem) =>
    !(client.id in temporarySelectedClients) &&
    setTemporarySelectedClients({
      ...temporarySelectedClients,
      [client.id]: client,
    });

  const removeClient = (id: string) => {
    const newSelection = Object.keys(temporarySelectedClients).reduce(
      (map: ClientsMap, key) =>
        key !== id ? { ...map, [key]: temporarySelectedClients[key] } : map,
      {},
    );
    setTemporarySelectedClients(newSelection);
  };

  const handleClearAllClients = () => {
    setTemporarySelectedClients({});
    onChange?.(undefined);
    setIsActive(false);
  };

  const { styles, attributes, update } = usePopper(
    baseElement.current,
    popElement.current,
    {
      placement: 'bottom-start',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [-16, 0],
          },
        },
      ],
    },
  );

  // TODO: Figure out a way to reutilize this like in a hook or using headlessUI components
  useEffect(() => {
    const closeFilter = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (
        !baseElement.current?.contains(target) &&
        !popElement.current?.contains(target)
      ) {
        setIsActive(false);
      }
    };

    window.addEventListener('mousedown', (e) => closeFilter(e));
    return () => window.removeEventListener('mousedown', closeFilter);
  });

  useEffect(() => {
    setTemporarySelectedClients(
      selectedClients ? createClientsMap(selectedClients) : {},
    );
  }, [selectedClients]);

  useEffect(() => {
    const updatePopper = async () => {
      update && (await update());
    };
    updatePopper();
  }, [isActive, update]);

  return (
    <>
      <button
        ref={baseElement}
        className={`flex h-11 shrink-0 items-center rounded px-3 ${
          isActive
            ? 'border-3 border-brand-50 bg-primary-light'
            : 'border-support-line-darker border'
        }`}
        onClick={() => setIsActive(!isActive)}
      >
        <p className="text-ink-dark inline-block shrink-0 grow text-left font-medium">
          {selectedClients && selectedClients.length > 0
            ? pluralize('Clients', selectedClients.length, true)
            : 'All Clients'}
        </p>

        <ChevronDown className="ml-2 w-3 shrink-0 grow" />
      </button>
      <div
        ref={popElement}
        className={`bg-background-surface w-96 rounded shadow-lg ${
          isActive ? '' : 'hidden'
        }`}
        style={styles.popper}
        {...attributes.popper}
      >
        <div className="px-2 pt-2">
          <ClientFilter onChange={addClient} />
        </div>

        {shouldDisplayClientFilterActions ? (
          <>
            <div className="selected border-support-line border-t border-b px-2 pt-4 pb-2">
              {Object.values(temporarySelectedClients).map((client) => (
                <Pill
                  key={client.id}
                  className="mr-2 mb-2"
                  onClose={() => removeClient(client.id)}
                  toolTipText={client.id}
                >
                  {client.email}
                </Pill>
              ))}
            </div>

            <div className="actions p-3 text-right">
              {hasUnappliedClientFilters && (
                <span className="text-preset-7 text-ink-not-as-dark mr-4 italic">
                  Unapplied changes
                </span>
              )}

              <button
                className="text-preset-6 text-ink-dark hover:border-ink-not-as-dark hover:text-ink-not-as-dark h-8 rounded px-8 font-medium"
                onClick={handleClearAllClients}
              >
                Clear All
              </button>

              <button
                className="text-preset-6 bg-primary text-ink-clear hover:bg-primary-active h-8 rounded px-8 font-medium"
                onClick={() => {
                  onChange?.(
                    shouldDisplayClientFilterActions
                      ? Object.values(temporarySelectedClients)
                      : undefined,
                  );
                  setIsActive(false);
                }}
              >
                Apply
              </button>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default ClientsInput;
