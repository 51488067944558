import React from 'react';

const Stopwatch: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1940_10569)">
      <path
        d="M8.50002 14.5882C11.577 14.5882 14.0714 12.06 14.0714 8.94117C14.0714 5.82239 11.577 3.29411 8.50002 3.29411C5.423 3.29411 2.92859 5.82239 2.92859 8.94117C2.92859 12.06 5.423 14.5882 8.50002 14.5882Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2142 4.70589L13.3749 3.52942"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.1428 3.29411L13.6071 3.7647"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 3.29411V0.470581"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.89289 0.470581H7.10718"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.50002 9.41177L6.17859 6.80722"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1940_10569">
        <rect width="13" height="16" fill="white" transform="translate(2)" />
      </clipPath>
    </defs>
  </svg>
);

export default Stopwatch;
