import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { AdminProfile } from './__generated__/AdminProfile';

const GET_ADMIN = gql`
  query AdminProfile {
    admin {
      firstName
      lastName
      email
      permissions
      preferences {
        showDayOfStats
      }
      role
    }
  }
`;

export const useAdminProfileQuery = (
  options?: QueryHookOptions<AdminProfile>,
) => useQuery<AdminProfile>(GET_ADMIN, options);

export default GET_ADMIN;
