import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  UpdateRegion,
  UpdateRegionVariables,
} from './__generated__/UpdateRegion';

const UPDATE_REGION = gql`
  mutation UpdateRegion($input: RegionUpdateInput!) {
    updateRegion(input: $input) {
      id
      name
      polygon {
        lat
        lng
      }
    }
  }
`;

export const useUpdateRegionMutation = (
  options?: MutationHookOptions<UpdateRegion, UpdateRegionVariables>,
) => useMutation<UpdateRegion, UpdateRegionVariables>(UPDATE_REGION, options);

export default UPDATE_REGION;
