import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import {
  GetBatchOperations,
  GetBatchOperationsVariables,
} from './__generated__/GetBatchOperations';

const GET_BATCH_OPERATIONS = gql`
  query GetBatchOperations(
    $paginationOptions: PaginationOptions!
    $type: String!
  ) {
    getBatchOperations(paginationOptions: $paginationOptions, type: $type) {
      items {
        id
        createdAt
        totalOperations
        affectedRows
        totalErrors
        errors {
          id
          data
          error
        }
      }
      meta {
        currentPage
        itemCount
        totalItems
        totalPages
      }
    }
  }
`;

export const useGetBatchOperationsQuery = (
  options?: QueryHookOptions<GetBatchOperations, GetBatchOperationsVariables>,
) =>
  useQuery<GetBatchOperations, GetBatchOperationsVariables>(
    GET_BATCH_OPERATIONS,
    options,
  );

export default GET_BATCH_OPERATIONS;
