import {
  gql,
  LazyQueryHookOptions,
  QueryHookOptions,
  useLazyQuery,
  useQuery,
} from '@apollo/client';
import {
  AdminTenders,
  AdminTendersVariables,
} from './__generated__/AdminTenders';

const GET_TENDERS = gql`
  query AdminTenders(
    $paginationOptions: PaginationOptions!
    $filters: [Filter!]
    $excludeTendersWithoutAgreements: Boolean
  ) {
    tenders: tenders(
      paginationOptions: $paginationOptions
      filters: $filters
      excludeTendersWithoutAgreements: $excludeTendersWithoutAgreements
    ) {
      items {
        id
        firstName
        lastName
        email
        avatarURL
        regionName: region
        address {
          city
          state
        }
      }
    }
  }
`;

export const useTendersQuery = (
  options?: QueryHookOptions<AdminTenders, AdminTendersVariables>,
) => useQuery<AdminTenders, AdminTendersVariables>(GET_TENDERS, options);
export const useLazyTendersQuery = (
  options?: LazyQueryHookOptions<AdminTenders, AdminTendersVariables>,
) => useLazyQuery<AdminTenders, AdminTendersVariables>(GET_TENDERS, options);

export default GET_TENDERS;
