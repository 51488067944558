import gql from 'graphql-tag';
import { QueryHookOptions, useQuery } from '@apollo/client';
import { SuperRegions } from './__generated__/SuperRegions';

const GET_SUPER_REGIONS = gql`
  query SuperRegions {
    superRegions {
      id
      name
      updatedAt
      regions {
        id
        name
      }
    }
  }
`;

export const useSuperRegionsQuery = (
  options?: QueryHookOptions<SuperRegions>,
) => useQuery<SuperRegions>(GET_SUPER_REGIONS, options);

export default GET_SUPER_REGIONS;
