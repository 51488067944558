import React from 'react';

const Pencil: React.FC<{ className?: string; fill?: boolean }> = ({
  className,
  fill = false,
}) => {
  return fill ? (
    <svg
      className={className}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5858 0.585786C11.3669 -0.195262 12.6332 -0.195262 13.4143 0.585786C14.1953 1.36683 14.1953 2.63316 13.4143 3.41421L12.6214 4.20711L9.79295 1.37868L10.5858 0.585786Z"
        fill="currentColor"
      />
      <path
        d="M8.37874 2.79289L6.10352e-05 11.1716V14H2.82849L11.2072 5.62132L8.37874 2.79289Z"
        fill="currentColor"
      />
    </svg>
  ) : (
    <svg
      className={className}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M25.8883 2.11171C25.4909 1.71598 25.019 1.40292 24.4999 1.19061C23.9808 0.978294 23.4248 0.870934 22.864 0.874731C22.3032 0.878528 21.7486 0.993405 21.2325 1.21273C20.7163 1.43205 20.2487 1.75147 19.8567 2.15254L2.94 19.0692L0.875 27.125L8.93083 25.0589L25.8475 8.14221C26.2487 7.75037 26.5682 7.28289 26.7876 6.7668C27.007 6.25071 27.1219 5.69625 27.1257 5.13548C27.1295 4.5747 27.0221 4.01874 26.8097 3.49972C26.5973 2.98071 26.2842 2.50895 25.8883 2.11171V2.11171Z"
          stroke="currentColor"
          strokeWidth="1.65278"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.3737 2.63672L25.3633 8.62639"
          stroke="currentColor"
          strokeWidth="1.65278"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.94116 19.0681L8.93666 25.0531"
          stroke="currentColor"
          strokeWidth="1.65278"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Pencil;
