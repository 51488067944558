import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import {
  GetTenderPayments,
  GetTenderPaymentsVariables,
} from './__generated__/GetTenderPayments';

const GET_TENDER_PAYMENTS = gql`
  query GetTenderPayments(
    $after: String
    $filters: TenderPaymentFilters!
    $orderByField: String
    $orderByDirection: OrderByDirectionEnum
  ) {
    tenderPayments(
      first: 20
      after: $after
      filters: $filters
      orderByField: $orderByField
      orderByDirection: $orderByDirection
    ) {
      pageInfo {
        hasNextPage
        endCursor
        totalCount
      }
      edges {
        node {
          id
          status
          type
          description
          createdAt
          updatedAt
          amount
          tender {
            id
            firstName
            lastName
            email
            avatarURL
            phoneNumber
          }
          staffing {
            id
            shift {
              id
              status
              position {
                name
              }
              job {
                client {
                  id
                  firstName
                  lastName
                  email
                }
                id
                name
                venue {
                  id
                  region {
                    id
                    name
                  }
                  address {
                    city
                    state
                    timezone
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const useGetTenderPaymentsQuery = (
  options?: QueryHookOptions<GetTenderPayments, GetTenderPaymentsVariables>,
) =>
  useQuery<GetTenderPayments, GetTenderPaymentsVariables>(
    GET_TENDER_PAYMENTS,
    options,
  );

export default GET_TENDER_PAYMENTS;
