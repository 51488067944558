import React from 'react';

const TenderSideBarCard: React.FC<{
  title: string;
}> = ({ title, children }) => {
  return (
    <div className="bg-background-surface mb-4 rounded">
      <div className="p-4">
        <div className="flex justify-between">
          <p className="text-preset-3 text-ink-dark mb-4 font-medium">
            {title}
          </p>
        </div>
        {children}
      </div>
    </div>
  );
};

export default TenderSideBarCard;
