import { gql, LazyQueryHookOptions, useLazyQuery } from '@apollo/client';
import STAFFING_LIST_ITEM from '../fragments/StaffingListItem';
import {
  PendingJobApplicationsPaginated,
  PendingJobApplicationsPaginatedVariables,
} from './__generated__/PendingJobApplicationsPaginated';

const PENDING_JOB_APPLICATIONS_PAGINATED = gql`
  query PendingJobApplicationsPaginated(
    $shiftId: ID!
    $options: ListOptions
    $pagination: PaginationOptions
  ) {
    pendingJobApplicationsPaginated(
      shiftId: $shiftId
      listOptions: $options
      pagination: $pagination
    ) {
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
      items {
        ...StaffingListItem
      }
    }
  }
  ${STAFFING_LIST_ITEM}
`;

export const useLazyPendingJobApplicationsPaginatedQuery = (
  options?: LazyQueryHookOptions<
    PendingJobApplicationsPaginated,
    PendingJobApplicationsPaginatedVariables
  >,
) =>
  useLazyQuery<
    PendingJobApplicationsPaginated,
    PendingJobApplicationsPaginatedVariables
  >(PENDING_JOB_APPLICATIONS_PAGINATED, options);

export default PENDING_JOB_APPLICATIONS_PAGINATED;
