import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  UpdateSuperRegion,
  UpdateSuperRegionVariables,
} from './__generated__/UpdateSuperRegion';

const UPDATE_SUPER_REGION = gql`
  mutation UpdateSuperRegion($input: SuperRegionUpdateInput!) {
    updateSuperRegion(input: $input) {
      id
      name
      regions {
        name
        superRegion {
          name
        }
      }
    }
  }
`;

export const useUpdateStaffingRegionMutation = (
  options?: MutationHookOptions<UpdateSuperRegion, UpdateSuperRegionVariables>,
) =>
  useMutation<UpdateSuperRegion, UpdateSuperRegionVariables>(
    UPDATE_SUPER_REGION,
    options,
  );

export default UPDATE_SUPER_REGION;
