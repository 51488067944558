import { Transition } from '@headlessui/react';
import { Options } from '@popperjs/core';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { usePopper } from 'react-popper';

export interface PopoverProps {
  isOpen: boolean;
  onDismiss: () => void;
  panelElementRef: React.RefObject<HTMLDivElement>; // TODO: could this be optional?
  popoverOptions?: Options;
  triggerElement: HTMLElement | null;
}

const Popover: React.FC<PopoverProps> = ({
  children,
  isOpen,
  onDismiss,
  panelElementRef,
  popoverOptions: popOverOptions = { placement: 'bottom-end' },
  triggerElement,
  ...rest
}) => {
  const [panelElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );

  const [open, setOpen] = useState(false);

  const { styles, attributes } = usePopper(
    triggerElement,
    panelElement,
    popOverOptions,
  );

  const handleClose = useCallback(() => {
    setOpen(false);
    onDismiss();
  }, [onDismiss]);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    const detectClickOutside = (evt: MouseEvent) => {
      if (
        open &&
        !triggerElement?.contains(evt.target as HTMLElement) &&
        !panelElementRef.current?.contains(evt.target as HTMLElement)
      ) {
        handleClose();
      }
    };

    document.body.addEventListener('mousedown', detectClickOutside);

    return () => {
      document.body.removeEventListener('mousedown', detectClickOutside);
    };
  }, [onDismiss, isOpen, triggerElement, panelElementRef, open, handleClose]);

  const handleOnClick = (evt: React.MouseEvent<HTMLDivElement>) => {
    evt.stopPropagation();
  };

  return (
    <div
      className="z-50 cursor-default"
      onClick={handleOnClick}
      ref={panelElementRef}
      style={styles.popper}
      {...rest}
      {...attributes.popper}
    >
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
        show={open}
        beforeEnter={() => setPopperElement(panelElementRef.current)}
        afterLeave={() => setPopperElement(null)}
      >
        <div className="bg-background-surface border-support-line mt-1 translate-y-1 transform rounded-lg border border-b opacity-0 shadow-xl">
          {children}
        </div>
      </Transition>
    </div>
  );
};

export default Popover;
