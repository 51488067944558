import { gql } from '@apollo/client';

const STAFFING_LIST_ITEM = gql`
  fragment StaffingListItem on JobApplication {
    id
    tenderId
    avatarURL
    firstName
    lastName
    email
    phoneNumber
    clientTenderStatus
    drivingDistanceInMiles: drivingDistance(unit: MILES)
    drivingTime
    status
    softConflicts
    hardConflicts
    score
    tenderHasCompletedOnboardingW2
    tenderHasCompletedOnboarding1099
    createdAt
    tenderPositionTagGroupMatch
    tagGroups {
      id
      name
    }
    reliabilityPrediction
    reliabilityPredictionTimestamp
    staffedByAutomationStepId
  }
`;

export default STAFFING_LIST_ITEM;
