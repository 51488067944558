import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  PartiallyRejectTenderCertification,
  PartiallyRejectTenderCertificationVariables,
} from './__generated__/PartiallyRejectTenderCertification';

const PARTIALLY_REJECT_TENDER_CERTIFICATION = gql`
  mutation PartiallyRejectTenderCertification($id: ID!) {
    partiallyRejectTenderCertification(id: $id) {
      id
      type {
        name
      }
      tenderId
      issueDate
      expirationDate
      certificateNumber
      updatedAt
      createdAt
      status
    }
  }
`;

export const usePartiallyRejectTenderCertificationMutation = (
  options?: MutationHookOptions<
    PartiallyRejectTenderCertification,
    PartiallyRejectTenderCertificationVariables
  >,
) =>
  useMutation<
    PartiallyRejectTenderCertification,
    PartiallyRejectTenderCertificationVariables
  >(PARTIALLY_REJECT_TENDER_CERTIFICATION, options);

export default PARTIALLY_REJECT_TENDER_CERTIFICATION;
