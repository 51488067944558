import React, { useState } from 'react';
import { format } from 'date-fns-tz';

import ConfirmationDialog from 'src/components/ConfirmationDialog';
import Loading from 'src/components/Loading';
import TableHeaderCell from 'src/components/TableHeaderCell';
import TenderCell from 'src/components/StaffingList/TenderCell';
import { formatMoney } from 'src/utils/formatMoney';
import { GetTenderPayments_tenderPayments_edges as TenderPayment } from 'src/graphql/queries/__generated__/GetTenderPayments';
import { useMarkTenderPayoutAsSent } from 'src/graphql/mutations/MarkTenderPayoutAsSent';
import GET_TENDER_PAYMENTS from 'src/graphql/queries/GetTenderPayouts';
import { useMarkTenderPayoutAsCancelled } from 'src/graphql/mutations/MarkTenderPayoutAsCancelled';
import {
  OrderByDirectionEnum,
  TenderPaymentStatus,
} from 'src/__generated__/globalTypes';
import TenderPayoutsSelectionToolbar from './PayoutsSelectionToolbar';

type Props = {
  tenderPayments?: TenderPayment[];
  orderByField: string;
  orderByDirection: OrderByDirectionEnum;
  sort: (value: string) => void;
  totalCount?: number;
};

const Cell: React.FC<{ className?: string }> = ({ children, className }) => (
  <td
    className={`text-preset-6 text-ink-dark bg-background-surface px-4 ${className}`}
  >
    <p className="truncate">{children}</p>
  </td>
);

const ActionsComponent: React.FC<{
  id: string;
  orderByField: string;
  orderByDirection: OrderByDirectionEnum;
}> = ({ id, orderByField, orderByDirection }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [markAsSentMutation] = useMarkTenderPayoutAsSent({
    variables: {
      id,
    },
    refetchQueries: [
      {
        query: GET_TENDER_PAYMENTS,
        variables: {
          filters: { status: { contains: [TenderPaymentStatus.PENDING] } },
          orderByField,
          orderByDirection,
        },
      },
    ],
  });
  const [markAsCancelledMutation] = useMarkTenderPayoutAsCancelled({
    variables: {
      id,
    },
    refetchQueries: [
      {
        query: GET_TENDER_PAYMENTS,
        variables: {
          filters: { status: { contains: [TenderPaymentStatus.PENDING] } },
          orderByField,
          orderByDirection,
        },
      },
    ],
  });
  return (
    <>
      <ConfirmationDialog
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={async () => {
          setLoading(true);
          await markAsCancelledMutation();
        }}
        description="Are you sure you want to delete the payment? There's no way to undo this action"
        title="Delete payment"
        submitText="Delete"
        submitClassName="bg-status-destructive text-ink-clear"
      />
      {loading ? (
        <Loading />
      ) : (
        <>
          <button
            disabled={loading}
            onClick={async () => {
              setLoading(true);
              await markAsSentMutation();
            }}
          >
            Mark as Sent
          </button>
          <button
            className="text-status-destructive pl-4"
            disabled={loading}
            onClick={() => {
              setOpen(true);
            }}
          >
            Delete
          </button>
        </>
      )}
    </>
  );
};

const RowCheckbox: React.FC<{
  id: string;
  onChange: (id: string) => void;
  checked: boolean;
}> = ({ id, onChange, checked }) => {
  return (
    <input
      type="checkbox"
      className="text-brand-50 border-ink-dark block h-6 w-6 rounded border-2 focus:ring-0"
      onChange={(_) => onChange(id)}
      checked={checked}
    />
  );
};

const PendingPayouts: React.FC<Props> = ({
  tenderPayments,
  orderByDirection,
  orderByField,
  sort,
  totalCount,
}) => {
  const [selectAll, setSelectAll] = useState(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const handleSelectedIdsChange = (newId: string) => {
    const index = selectedIds.indexOf(newId);
    const selectedIdsCopy = [...selectedIds];
    if (index !== -1) {
      selectedIdsCopy.splice(index, 1);
    } else {
      selectedIdsCopy.push(newId);
    }
    setSelectedIds(selectedIdsCopy);
  };
  const selectionCount = selectAll
    ? (totalCount || 0) - selectedIds.length
    : selectedIds.length;
  return (
    <div className="border-support-line flex flex-col rounded-lg border">
      <TenderPayoutsSelectionToolbar
        orderByField={orderByField}
        orderByDirection={orderByDirection}
        tenderPayoutIds={selectedIds}
        onClearSelection={() => {
          setSelectedIds([]);
          setSelectAll(false);
        }}
        selectionCount={selectionCount}
        hasAllSelected={selectAll}
      />
      <div className="bg-background-app mb-8 flex-grow overflow-auto">
        <table className="w-full table-fixed">
          <thead>
            <tr className="sticky z-10 h-12">
              <TableHeaderCell className="bg-background-app w-16">
                <input
                  type="checkbox"
                  className="text-brand-50 border-ink-dark block h-6 w-6 rounded border-2 focus:ring-0"
                  onChange={(evt) => {
                    setSelectAll(evt.target.checked);
                    setSelectedIds([]);
                  }}
                  checked={selectAll}
                  data-cy="bulk-checkbox"
                />
              </TableHeaderCell>
              <TableHeaderCell
                className="bg-background-app w-48"
                sortDirection={
                  orderByField === 'createdAt' ? orderByDirection : undefined
                }
                onClick={() => sort('createdAt')}
              >
                Created at
              </TableHeaderCell>
              <TableHeaderCell
                className="bg-background-app w-40"
                sortDirection={
                  orderByField === 'description' ? orderByDirection : undefined
                }
                onClick={() => sort('description')}
              >
                Description
              </TableHeaderCell>
              <TableHeaderCell
                className="bg-background-app top-0 left-0 w-64"
                sortDirection={
                  orderByField === 'tenderName' ? orderByDirection : undefined
                }
                onClick={() => sort('tenderName')}
              >
                Tender
              </TableHeaderCell>
              <TableHeaderCell
                className="bg-background-app w-96"
                sortDirection={
                  orderByField === 'shift' ? orderByDirection : undefined
                }
                onClick={() => sort('shift')}
              >
                Shift id
              </TableHeaderCell>
              <TableHeaderCell
                className="bg-background-app w-96"
                sortDirection={
                  orderByField === 'job' ? orderByDirection : undefined
                }
                onClick={() => sort('job')}
              >
                Job
              </TableHeaderCell>
              <TableHeaderCell
                className="bg-background-app left-96 w-24"
                sortDirection={
                  orderByField === 'amount' ? orderByDirection : undefined
                }
                onClick={() => sort('amount')}
              >
                Amount
              </TableHeaderCell>
              <TableHeaderCell className="bg-background-app left-96 w-56">
                Actions
              </TableHeaderCell>
            </tr>
          </thead>
          <tbody className="bg-white">
            {tenderPayments &&
              tenderPayments.map((payment) => (
                <tr
                  key={payment.node.id}
                  className="border-support-line h-14 border-b"
                >
                  <Cell className="z-2 text-status-positive">
                    <RowCheckbox
                      id={payment.node.id}
                      onChange={(id) => {
                        handleSelectedIdsChange(id);
                      }}
                      checked={
                        selectAll !== selectedIds.includes(payment.node.id)
                      }
                    />
                  </Cell>
                  <Cell>
                    {format(
                      new Date(payment.node.createdAt),
                      'MMM dd y hh:mmaaa',
                    )}
                  </Cell>
                  <Cell>{payment.node.description || '-'}</Cell>
                  <TenderCell
                    className="z-2 left-0 text-left"
                    lastName={payment.node.tender.lastName || ''}
                    firstName={payment.node.tender.firstName || ''}
                    tenderId={payment.node.tender.id}
                    avatarURL={payment.node.tender.avatarURL}
                    detailText={payment.node.tender.phoneNumber}
                    truncateText
                  />
                  <Cell className="text-left">
                    {payment.node.staffing?.shift?.id || '-'}
                  </Cell>
                  <Cell>
                    <span className="block text-left">
                      {payment.node.staffing?.shift?.job.name || '-'}
                    </span>
                    <span className="text-ink-not-as-dark block text-left">
                      {payment.node.staffing?.shift?.job.id}
                    </span>
                  </Cell>
                  <Cell className="z-2 left-96 text-right">
                    {formatMoney(payment.node.amount)}
                  </Cell>
                  <Cell className="z-2 text-status-positive left-96">
                    <ActionsComponent
                      id={payment.node.id}
                      orderByField={orderByField}
                      orderByDirection={orderByDirection}
                    />
                  </Cell>
                </tr>
              ))}
          </tbody>
        </table>
        <span className="border-support-lines border-b" />
      </div>
    </div>
  );
};

export default PendingPayouts;
