import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  changeAdminPreferences,
  changeAdminPreferencesVariables,
} from './__generated__/changeAdminPreferences';

const CHANGE_ADMIN_PREFERENCES_MUTATION = gql`
  mutation changeAdminPreferences($input: AdminPreferencesInput!) {
    changeAdminPreferences(input: $input) {
      showDayOfStats
    }
  }
`;

export const useChangeAdminPreferences = (
  options?: MutationHookOptions<
    changeAdminPreferences,
    changeAdminPreferencesVariables
  >,
) =>
  useMutation<changeAdminPreferences, changeAdminPreferencesVariables>(
    CHANGE_ADMIN_PREFERENCES_MUTATION,
    options,
  );

export default CHANGE_ADMIN_PREFERENCES_MUTATION;
