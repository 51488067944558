import React, { useCallback, useEffect, useState } from 'react';
import { GoogleMap, Polygon, useJsApiLoader } from '@react-google-maps/api';
import { googleMapStyles } from 'src/components/LocationInput/mapStyles';
import { GOOGLE_MAPS_API_LIBRARIES } from 'src/utils/constants';

const polygonColor = '#216C2A';

const polygonOptions = {
  strokeColor: polygonColor,
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: polygonColor,
  fillOpacity: 0.35,
  editable: true,
};

interface RegionMapProps {
  regionCoords: google.maps.LatLngLiteral[];
  onCoordinatesChange: (path: google.maps.LatLng[]) => void;
}

const RegionMap: React.FC<RegionMapProps> = ({
  regionCoords,
  onCoordinatesChange,
}) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
    libraries: GOOGLE_MAPS_API_LIBRARIES,
  });

  const [mapZoom, setMapZoom] = useState(8);
  const [mapCenter, setMapCenter] = useState<
    google.maps.LatLngLiteral | undefined
  >();
  const [polygonRef, setPolygonRef] = useState<google.maps.Polygon>();
  const [paths, setPaths] = useState<google.maps.LatLngLiteral[]>();

  const removeCoordinate = ({ lat, lng }: google.maps.LatLngLiteral) => {
    const allPaths = polygonRef?.getPath().getArray();
    const minPolygonPaths = 3;
    if (!allPaths || allPaths?.length <= minPolygonPaths) {
      return;
    }
    const newPaths = allPaths.filter(
      (point) => !(point.lng() === lng && point.lat() === lat),
    );
    if (newPaths && newPaths.length !== allPaths.length) {
      onCoordinatesChange(newPaths);
    }
  };
  const calculateZoom = (bounds: google.maps.LatLngBounds): number => {
    // Calculating the zoom on the fly is not exact. This is the best approximation
    // https://stackoverflow.com/questions/6048975/google-maps-v3-how-to-calculate-the-zoom-level-for-a-given-bounds
    const GLOBE_WIDTH = 256; // a constant in Google's map projection
    const west = bounds.getSouthWest().lng();
    const east = bounds.getNorthEast().lng();
    let angle = east - west;
    if (angle < 0) {
      angle += 360;
    }
    const currentWidth = window.innerWidth * 0.3;
    return Math.floor(
      Math.log((currentWidth * 360) / angle / GLOBE_WIDTH) / Math.LN2,
    );
  };
  const setMapNewValues = useCallback(() => {
    if (!isLoaded) {
      return;
    }

    //calculate polygon center
    const bounds = new google.maps.LatLngBounds();

    for (const polygonVertex of regionCoords) {
      bounds.extend(
        new google.maps.LatLng(polygonVertex.lat, polygonVertex.lng),
      );
    }
    setMapZoom(calculateZoom(bounds));
    setMapCenter({
      lat: bounds.getCenter().lat(),
      lng: bounds.getCenter().lng(),
    });
  }, [regionCoords, isLoaded]);

  useEffect(() => {
    setMapNewValues();
    setPaths(regionCoords);
  }, [regionCoords, setMapNewValues]);

  return isLoaded ? (
    <GoogleMap
      mapContainerClassName="absolute w-full h-full"
      clickableIcons={false}
      options={{
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControlOptions: {
          mapTypeIds: [],
        },
        styles: googleMapStyles,
        scrollwheel: false,
      }}
      center={mapCenter}
      zoom={mapZoom}
    >
      <Polygon
        onLoad={(polygon: google.maps.Polygon) => setPolygonRef(polygon)}
        onUnmount={() => setPolygonRef(undefined)}
        paths={paths}
        editable
        options={polygonOptions}
        onMouseUp={() => {
          polygonRef && onCoordinatesChange(polygonRef.getPath().getArray());
        }}
        onRightClick={(event) => {
          if (event.latLng) {
            removeCoordinate({
              lat: event.latLng.lat(),
              lng: event.latLng.lng(),
            });
          }
        }}
      />
    </GoogleMap>
  ) : null;
};

export default RegionMap;
