import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  UpdateShiftAutoApprove,
  UpdateShiftAutoApproveVariables,
} from './__generated__/UpdateShiftAutoApprove';

const UPDATE_SHIFT_AUTO_APPROVE = gql`
  mutation UpdateShiftAutoApprove($id: ID!, $hasAutoApprove: Boolean!) {
    updateShiftAutoApprove(id: $id, hasAutoApprove: $hasAutoApprove) {
      id
      hasAutoApprove
    }
  }
`;

export const useUpdateShiftAutoApproveMutation = (
  options: MutationHookOptions<
    UpdateShiftAutoApprove,
    UpdateShiftAutoApproveVariables
  >,
) => {
  return useMutation<UpdateShiftAutoApprove, UpdateShiftAutoApproveVariables>(
    UPDATE_SHIFT_AUTO_APPROVE,
    options,
  );
};

export default UPDATE_SHIFT_AUTO_APPROVE;
