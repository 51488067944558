import React from 'react';

const Tab: React.FC<{
  label: string;
  selected: boolean;
  onClick: () => void;
  className?: string;
  icon?: React.ReactNode;
}> = ({ label, selected, onClick, className, icon }) => {
  const textClassName = `preset-5 font-medium cursor-pointer ${
    selected ? 'text-primary' : 'text-ink-not-as-dark'
  }`;
  return (
    <li
      className={`flex border-b-2 px-4 py-2 font-semibold ${
        selected ? 'border-primary' : 'border-support-lines'
      } ${className}`}
    >
      {icon ? (
        <span className={`mr-2.5 mt-1 ${textClassName}`}>{icon}</span>
      ) : null}
      <p onClick={onClick} className={textClassName}>
        {label}
      </p>
    </li>
  );
};

export default Tab;
