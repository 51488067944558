import React from 'react';

import Combobox from 'src/components/Combobox';
import ChevronDown from 'src/components/Icons/ChevronDown';
import Pill from 'src/components/Pill';
import { StaffingStatus } from 'src/__generated__/globalTypes';

const StatusOptionComponent: React.FC<{
  item: { id: string };
  index: number;
  highlighted: boolean;
}> = ({ item, highlighted }) => {
  return (
    <div
      className={`py-2 px-4 ${highlighted ? 'bg-background-app' : ''}`}
      data-cy={`app-status-positions-result-${item.id.toLowerCase()}`}
    >
      <p className="text-preset-6 text-ink-dark font-medium">{item.id}</p>
    </div>
  );
};

interface Props {
  className?: string;
  defaultValue?: string;
  onChange?: (selectedStatuses: string[]) => void;
  selectedStatuses: string[];
  title?: string;
}

const HIDDEN_STATUS_OPTIONS = [
  StaffingStatus.CANCELLED,
  StaffingStatus.COMPLETED,
  StaffingStatus.ONCALL,
  StaffingStatus.OPEN,
  StaffingStatus.STAFFED,
  StaffingStatus.UNCONFIRMED,
];

const STATUS_OPTIONS = Object.values(StaffingStatus)
  .filter((status) => !HIDDEN_STATUS_OPTIONS.includes(status))
  .map((status) => ({ id: status }));

const ApplicantStatusFilter: React.FC<Props> = ({
  className,
  defaultValue = 'Select your desired application statuses',
  onChange,
  selectedStatuses,
  title = 'Application Status',
}) => {
  const onDropdownChange = (status: { id: string } | null | undefined) => {
    if (
      !status ||
      selectedStatuses.some((selected) => selected === status.id)
    ) {
      return;
    }

    onChange && onChange([...selectedStatuses, status.id]);
  };

  const onStatusPillRemoved = (status: string) => {
    const statusIndexToRemove = selectedStatuses.indexOf(status);
    const newSelectedStatuses = [...selectedStatuses];
    newSelectedStatuses.splice(statusIndexToRemove, 1);

    onChange && onChange(newSelectedStatuses);
  };

  return (
    <div className={className}>
      <Combobox
        id="applicantStatusCombobox"
        inputClassName="focus:ring-primary focus:border-primary sm:text-preset-6 border-support-line-darker rounded cursor-pointer"
        inputValue={defaultValue}
        inputReadOnly
        items={STATUS_OPTIONS}
        label={title}
        loadingItems={false}
        onChange={onDropdownChange}
        onInputChange={() => null}
        onReset={() => null}
        openMenuOnFocus
        renderItemComponent={StatusOptionComponent}
        renderLoadingComponent={() => null}
        renderNoResultsComponent={() => (
          <p className="py-2 px-4">No positions found</p>
        )}
        renderTrailingComponent={() => (
          <ChevronDown className="text-ink-not-as-dark h-3 w-3" />
        )}
      />
      <div className="selectedPositions flex flex-wrap">
        {selectedStatuses.map((status) => {
          return (
            <Pill
              key={status}
              onClose={() => onStatusPillRemoved(status)}
              className="mr-2 mb-2"
              data-cy={`pill-${status.toLowerCase()}`}
            >
              {status}
            </Pill>
          );
        })}
      </div>
    </div>
  );
};

export default ApplicantStatusFilter;
