import React, { useState } from 'react';
import { ApolloError } from '@apollo/client';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import APPROVED_JOB_APPLICATIONS from 'src/graphql/queries/ApprovedJobApplicationsPaginated';
import PENDING_JOB_APPLICATIONS from 'src/graphql/queries/PendingJobApplicationsPaginated';
import REMOVED_JOB_APPLICATIONS from 'src/graphql/queries/RemovedJobApplicationsPaginated';
import { useApproveTenderApplicationsMutation } from 'src/graphql/mutations/ApproveTenderApplications';
import { useDenyTenderApplicationsMutation } from 'src/graphql/mutations/DenyTenderApplications';
import { useRemoveTenderApplicationsMutation } from 'src/graphql/mutations/RemoveTenderApplications';
import { useFaultTenderApplicationsMutation } from 'src/graphql/mutations/FaultTenderApplications';
import { StaffingStatus } from 'src/__generated__/globalTypes';
import { Await } from 'src/utilityTypes';
import { STAFFINGS_DEFAULT_PAGE_SIZE } from 'src/utils/constants';
import DataCell from './DataCell';

const ApproveModal: React.FC<{
  handleClick: () => void;
  isOpen: boolean;
  closeModal: () => void;
  tenderName: string;
}> = ({ handleClick, isOpen, closeModal, tenderName }) => {
  return (
    <ConfirmationDialog
      title="Approve Tender?"
      open={isOpen}
      onClose={closeModal}
      onSubmit={handleClick}
      description={`${tenderName} will be notified about this and will be able to see all the job details.`}
      cancelClassName="bg-transparent border border-ink-dark text-ink-dark-500 py-2 px-4 rounded"
      submitClassName="bg-primary text-ink-clear py-2 px-4 rounded ml-4"
      submitText="Approve"
    />
  );
};

const DenyModal: React.FC<{
  handleClick: () => void;
  isOpen: boolean;
  closeModal: () => void;
}> = ({ handleClick, isOpen, closeModal }) => {
  return (
    <ConfirmationDialog
      title="Deny Tender?"
      open={isOpen}
      onClose={closeModal}
      onSubmit={handleClick}
      description='Denied Tenders will see a "not selected" tag in their applications.'
      submitClassName="text-status-destructive py-2 px-4 ml-4"
      submitText="Deny"
    />
  );
};

const RemoveModal: React.FC<{
  handleClick: () => void;
  isOpen: boolean;
  closeModal: () => void;
}> = ({ handleClick, isOpen, closeModal }) => {
  return (
    <ConfirmationDialog
      title="Remove Tender?"
      open={isOpen}
      onClose={closeModal}
      onSubmit={handleClick}
      description="Removed Tenders won't be able to see this shift in their app anymore."
      submitClassName="text-status-destructive py-2 px-4 ml-4"
      submitText="Remove"
    />
  );
};

const FaultModal: React.FC<{
  handleClick: () => void;
  isOpen: boolean;
  closeModal: () => void;
}> = ({ handleClick, isOpen, closeModal }) => {
  return (
    <ConfirmationDialog
      title="Remove Tender with Fault?"
      open={isOpen}
      onClose={closeModal}
      onSubmit={handleClick}
      description="Tenders that are removed with fault will show an (F) on the records."
      submitClassName="text-status-destructive py-2 px-4 ml-4"
      submitText="Remove (F)"
    />
  );
};

const ActionsCell: React.FC<{
  status: StaffingStatus;
  staffingId: string;
  shiftId: string;
  tenderName: string;
  onAction: () => void;
}> = ({ status, staffingId, shiftId, tenderName, onAction }) => {
  const [dialogState, setDialogState] = useState<
    'approve' | 'deny' | 'fault' | 'remove' | string[]
  >();
  const mutationOptions = {
    variables: {
      staffingIds: [staffingId],
    },
    refetchQueries: [
      {
        query: APPROVED_JOB_APPLICATIONS,
        variables: {
          shiftId,
          pagination: { limit: STAFFINGS_DEFAULT_PAGE_SIZE, page: 1 },
        },
      },
      {
        query: PENDING_JOB_APPLICATIONS,
        variables: {
          shiftId,
          pagination: { limit: STAFFINGS_DEFAULT_PAGE_SIZE, page: 1 },
        },
      },
      {
        query: REMOVED_JOB_APPLICATIONS,
        variables: {
          shiftId,
          pagination: { limit: STAFFINGS_DEFAULT_PAGE_SIZE, page: 1 },
        },
      },
    ],
  };
  const [approveTender] = useApproveTenderApplicationsMutation(mutationOptions);
  const [denyTender] = useDenyTenderApplicationsMutation(mutationOptions);
  const [faultTender] = useFaultTenderApplicationsMutation(mutationOptions);
  const [removeTender] = useRemoveTenderApplicationsMutation(mutationOptions);
  const handleAction =
    (
      callback:
        | typeof approveTender
        | typeof denyTender
        | typeof removeTender
        | typeof faultTender,
    ) =>
    async () => {
      let result: Await<ReturnType<typeof callback>> | undefined;
      let exception: ApolloError | undefined;

      try {
        result = await callback();
      } catch (err) {
        if (err instanceof ApolloError) {
          exception = err;
        } else {
          throw err;
        }
      } finally {
        const mutationErrors =
          result?.data?.mutationPayload.errors?.map(
            (mutationError) => mutationError.message,
          ) ?? [];
        const requestErrors =
          exception?.graphQLErrors?.map(
            (requestError) => requestError.message,
          ) ?? [];
        const errors = mutationErrors.concat(requestErrors);
        setDialogState(errors.length ? errors : undefined);
      }

      onAction();
    };

  return (
    <DataCell className="whitespace-nowrap">
      {status === StaffingStatus.PENDING && (
        <>
          <ApproveModal
            tenderName={tenderName}
            handleClick={handleAction(approveTender)}
            isOpen={dialogState === 'approve'}
            closeModal={() => setDialogState(undefined)}
          />
          <DenyModal
            handleClick={handleAction(denyTender)}
            isOpen={dialogState === 'deny'}
            closeModal={() => setDialogState(undefined)}
          />
          <button
            className="text-ink-primary px-2"
            onClick={() => setDialogState('approve')}
            data-cy="approve-tender-button"
          >
            Approve
          </button>
          <button
            className="text-status-destructive px-2"
            onClick={() => setDialogState('deny')}
            data-cy="deny-tender-button"
          >
            Deny
          </button>
        </>
      )}
      {[
        StaffingStatus.CONFIRMED,
        StaffingStatus.APPROVED,
        StaffingStatus.CLOCKEDIN,
        StaffingStatus.CLOCKEDOUT,
      ].includes(status) && (
        <>
          <RemoveModal
            handleClick={handleAction(removeTender)}
            isOpen={dialogState === 'remove'}
            closeModal={() => setDialogState(undefined)}
          />
          <FaultModal
            handleClick={handleAction(faultTender)}
            isOpen={dialogState === 'fault'}
            closeModal={() => setDialogState(undefined)}
          />
          <button
            className="text-ink-secondary px-2"
            onClick={() => setDialogState('remove')}
            data-cy="remove-tender-button"
          >
            Remove
          </button>
          <button
            className="text-status-destructive px-2"
            onClick={() => setDialogState('fault')}
            data-cy="fault-tender-button"
          >
            Remove (F)
          </button>
        </>
      )}
      {Array.isArray(dialogState) && (
        <ConfirmationDialog
          title="Operation Errors"
          description={
            dialogState
              ?.map((errorMessage) => `- ${errorMessage}`)
              .join('\n') ?? ''
          }
          open={Boolean(dialogState.length)}
          cancelText="Close"
          onClose={() => setDialogState(undefined)}
        />
      )}
    </DataCell>
  );
};

export default ActionsCell;
