import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  HideFromOpenJobsAsAdmin,
  HideFromOpenJobsAsAdminVariables,
} from './__generated__/HideFromOpenJobsAsAdmin';

const HIDE_FROM_OPEN_JOBS = gql`
  mutation HideFromOpenJobsAsAdmin($jobId: ID!) {
    hideFromOpenJobs(jobId: $jobId) {
      __typename
      id
      hiddenFromOpenJobs
    }
  }
`;

export const useHideJobAsAdminMutation = (
  options?: MutationHookOptions<
    HideFromOpenJobsAsAdmin,
    HideFromOpenJobsAsAdminVariables
  >,
) =>
  useMutation<HideFromOpenJobsAsAdmin, HideFromOpenJobsAsAdminVariables>(
    HIDE_FROM_OPEN_JOBS,
    options,
  );

export default HIDE_FROM_OPEN_JOBS;
