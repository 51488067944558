import { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url';
import { SuperRegions_superRegions as SuperRegionItem } from 'src/graphql/queries/__generated__/SuperRegions';

export const GOOGLE_MAPS_API_LIBRARIES: Libraries = ['places'];
export const ONE_MILE_IN_METERS = 1609.344;
export const STAFFINGS_DEFAULT_PAGE_SIZE = 150;
export const RETOOL_TENDERS_APP_URL = 'https://tend.retool.com/apps/Tenders';
export const RETOOL_MESSAGING_APP_URL =
  'https://tend.retool.com/apps/Tender%20Messaging';
export const UNASSIGNED_SUPER_REGION_OPTION: SuperRegionItem = {
  __typename: 'SuperRegion',
  updatedAt: null,
  regions: [],
  id: 'unassigned',
  name: 'Unassigned Region',
};
export const RETOOL_STAFFING_AUTOMATION_APP_URL =
  'https://tend.retool.com/app/staffing-automation';
