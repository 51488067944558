import React, { FC } from 'react';
import Combobox from 'src/components/Combobox';
import ChevronDown from 'src/components/Icons/ChevronDown';
import { TenderPaymentStatus } from 'src/__generated__/globalTypes';

type TenderPaymentStatusOption = {
  label: string;
  id: TenderPaymentStatus | 'any';
};

const ResultComponent: FC<{
  item: TenderPaymentStatusOption;
  index: number;
  highlighted: boolean;
}> = ({ item }) => (
  <div className="hover:bg-background-app cursor-pointer py-2 px-4">
    <p className="text-preset-6 text-ink-dark font-medium">{item.label}</p>
  </div>
);

type Props = {
  tenderPaymentStatus?: string;
  onChange: (tenderPaymentStatus: string) => void;
  className?: string;
};

const tenderPaymentStatusList: TenderPaymentStatusOption[] = [
  {
    label: 'Any',
    id: 'any',
  },
  {
    label: 'Sent',
    id: TenderPaymentStatus.SENT,
  },
  {
    label: 'Pending',
    id: TenderPaymentStatus.PENDING,
  },
  {
    label: 'Cancelled',
    id: TenderPaymentStatus.CANCELLED,
  },
];

const TenderPaymentStatusInput: FC<Props> = ({
  tenderPaymentStatus,
  onChange,
  className,
}) => {
  const defaultItem =
    tenderPaymentStatusList.find(
      (status) => status.id === tenderPaymentStatus,
    ) || tenderPaymentStatusList[0];

  return (
    <div className={className}>
      <Combobox
        id="tenderPaymentCombobox"
        inputValue={defaultItem.label}
        inputClassName="focus:ring-primary focus:border-primary sm:text-preset-6 border-support-line-darker rounded cursor-pointer"
        inputReadOnly
        itemToString={() => defaultItem.label}
        items={tenderPaymentStatusList}
        label="Tender payment status"
        loadingItems={false}
        onChange={(selectedItem?: TenderPaymentStatusOption | null) => {
          selectedItem && onChange(selectedItem.id);
        }}
        onInputChange={() => null}
        onReset={() => onChange('any')}
        openMenuOnFocus
        renderItemComponent={ResultComponent}
        renderLoadingComponent={() => null}
        renderNoResultsComponent={() => null}
        renderTrailingComponent={() => (
          <ChevronDown className="text-ink-not-as-dark h-3 w-3" />
        )}
      />
    </div>
  );
};

export default TenderPaymentStatusInput;
