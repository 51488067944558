import React from 'react';

const Human: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={className}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99984 9.79167C12.5311 9.79167 14.5832 7.73964 14.5832 5.20833C14.5832 2.67703 12.5311 0.625 9.99984 0.625C7.46853 0.625 5.4165 2.67703 5.4165 5.20833C5.4165 7.73964 7.46853 9.79167 9.99984 9.79167Z"
      fill="currentColor"
    />
    <path
      d="M10.0002 11.042C7.90128 11.0444 5.88905 11.8793 4.40491 13.3634C2.92078 14.8475 2.08592 16.8598 2.0835 18.9587C2.0835 19.0692 2.12739 19.1751 2.20554 19.2533C2.28368 19.3314 2.38966 19.3753 2.50016 19.3753H17.5002C17.6107 19.3753 17.7167 19.3314 17.7948 19.2533C17.8729 19.1751 17.9168 19.0692 17.9168 18.9587C17.9144 16.8598 17.0796 14.8475 15.5954 13.3634C14.1113 11.8793 12.099 11.0444 10.0002 11.042Z"
      fill="currentColor"
    />
  </svg>
);

export default Human;
