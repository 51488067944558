import React, { FC, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import CloseIcon from 'src/components/Icons/Close';

interface Props {
  open: boolean;
  title?: string;
  onClose?: () => void;
  className?: string;
  'data-cy'?: string;
}

const Drawer: FC<Props> = ({
  children,
  open,
  onClose,
  title,
  className,
  ...props
}) => (
  <Transition.Root show={open} as={Fragment}>
    <Dialog
      as="div"
      className={`w-106 fixed bottom-0 top-16 right-0 z-40 ${className || ''}`}
      onClose={() => onClose?.()}
    >
      <Transition.Child
        as={Fragment}
        enter="transform transition ease-in-out duration-300"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transform transition ease-in-out duration-300"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        <div className="bg-background-surface flex h-full flex-col shadow-xl">
          <div className="bg-background-app border-support-line flex flex-shrink-0 border-b px-4 py-6">
            <button
              type="button"
              title="Close"
              className="mr-6 focus:outline-none"
              onClick={onClose}
            >
              <CloseIcon />
            </button>
            <p
              className="text-preset-5 text-ink-dark m-0 font-bold"
              data-cy={props['data-cy']}
            >
              {title}
            </p>
          </div>
          <div className="h-full w-full overflow-y-auto">{children}</div>
        </div>
      </Transition.Child>
    </Dialog>
  </Transition.Root>
);

export default Drawer;
