import React from 'react';
import DataCell from './DataCell';

const DrivingDistanceCell: React.FC<{
  distanceInMiles: number;
  // this number is in seconds
  duration?: number | null;
}> = ({ distanceInMiles, duration, ...props }) => {
  const durationInMinutes = duration && Math.round(duration / 60);

  return (
    <DataCell data-testid="driving-distance-cell" {...props}>
      <div className="flex items-center">
        <div>
          <p className="text-preset-6 mb-1 font-normal">
            {distanceInMiles ? `${distanceInMiles.toPrecision(3)} miles` : '-'}
          </p>
          {Boolean(duration) && (
            <p className="text-preset-7 text-ink-not-as-dark font-normal">
              {durationInMinutes} minutes approx.
            </p>
          )}
        </div>
      </div>
    </DataCell>
  );
};

export default DrivingDistanceCell;
