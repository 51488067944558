import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns-tz';

import ChevronUp from 'src/components/Icons/ChevronUp';
import { useRegionsQuery } from 'src/graphql/queries/Regions';
import Loading from 'src/components/Loading';
import Modal from 'src/components/Modal';
import LocationInput from 'src/components/LocationInput';

type MarkerType = {
  lat: number;
  lng: number;
};

const RegionsView: React.FC = () => {
  const { data, loading } = useRegionsQuery({ fetchPolicy: 'network-only' });
  const { push } = useHistory();
  const [modalOpen, setModalOpen] = useState(false);
  const totalRegions = data?.regions.length;
  const [regionName, setRegionName] = useState('');
  const [defaultLocation, setDefaultLocation] = useState<MarkerType>();

  return (
    <div className="text-align-right bg-background-app min-h-full min-w-full object-center pt-5 pb-8 sm:px-6 lg:px-8">
      <div className="bg-background-surface border-support-line mx-auto mb-6 flex max-w-screen-lg items-center justify-between border px-4 py-5">
        <p className="text-preset-2 text-ink-dark">
          {totalRegions || 0} Region{totalRegions !== 1 ? 's' : ''}
        </p>
        <button
          className="text-preset-5 bg-primary text-ink-clear hover:bg-primary-active h-9 rounded px-5 font-medium"
          onClick={() => {
            setModalOpen(true);
          }}
        >
          Create region
        </button>
        <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
          <div className="px-6 py-4">
            <h2 className="text-preset-3 text-ink mb-8">Create New Region</h2>
            <form>
              <label className="text-preset-5 text-ink mb-2.5 block">
                Region Name
              </label>
              <input
                type="text"
                name="regionName"
                id="customId"
                className="mb-2 block h-12 w-80 rounded"
                required
                onChange={(event) => {
                  setRegionName(event.currentTarget.value);
                }}
              />
              <p className="text-preset-5 mt-12 font-semibold">
                Where would you like to start to create your region?
              </p>
              <p className="text-preset-5 text-ink my-6">
                This is where we will center the map initially so you can start
                start creating your Region more easily
              </p>
              <LocationInput onMarkerChange={setDefaultLocation} />
            </form>
          </div>
          <div className="border-support-line mt-80 flex w-full justify-end border-t-2 p-4">
            <button
              className="border-support-line rounded-md px-4 py-2"
              onClick={() => {
                setModalOpen(false);
              }}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                push('region/new', {
                  regionName,
                  coordinates: {
                    lat: defaultLocation?.lat,
                    lng: defaultLocation?.lng,
                  },
                });
              }}
              className="text-preset-5 bg-primary text-ink-clear hover:bg-primary-active h-9 rounded px-5 font-medium"
            >
              Create Region
            </button>
          </div>
        </Modal>
      </div>
      <div className="text-align-left w-100% bg-background-surface border-support-line mx-auto mt-5 max-w-screen-lg rounded border">
        {loading ? (
          <Loading />
        ) : (
          <ul className="shadow-sm">
            {data?.regions.map((region, index) => (
              <li
                className={`border-color-gray-200 flex cursor-pointer items-center justify-between ${
                  index > 0 ? ' border-t' : ''
                }`}
                onClick={() => {
                  push(`region/${region.id}`);
                }}
                key={`${region.id}`}
              >
                <div className="p-6 pl-8">
                  <p className="text-preset-5 text-ink-dark">{region.name}</p>
                  <p className="text-preset-5 text-ink-not-as-dark mt-1">
                    Last updated on{' '}
                    {format(new Date(region.updatedAt), 'MMMM dd, yyyy')}
                  </p>
                </div>
                <ChevronUp className="mt-1 mr-8 h-3 w-3 rotate-90 transform text-gray-400" />
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default RegionsView;
