const Circle: React.FC<{ className?: string }> = ({ className }) => (
  <div
    data-testid="conflicts-indicator-circle"
    className={`inline-block h-3 w-3 rounded-full ${className}`}
  />
);

const ConflictsIndicator: React.FC<{ type?: 'soft' | 'hard' }> = ({ type }) => (
  <div data-testid={'conflicts-indicator'} data-type={type}>
    {type &&
      (type === 'hard' ? (
        <>
          <Circle className="bg-status-destructive mr-0.5" />
          <Circle className="bg-status-destructive" />
        </>
      ) : (
        <Circle className="bg-status-warning" />
      ))}
  </div>
);

export default ConflictsIndicator;
