import gql from 'graphql-tag';
import { QueryHookOptions, useQuery } from '@apollo/client';
import { Regions } from './__generated__/Regions';

const GET_REGIONS = gql`
  query Regions {
    regions {
      id
      name
      superRegion {
        id
        name
      }
      updatedAt
      polygon {
        lat
        lng
      }
    }
  }
`;

export const useRegionsQuery = (options?: QueryHookOptions<Regions>) =>
  useQuery<Regions>(GET_REGIONS, options);

export default GET_REGIONS;
