import { Crop } from 'react-image-crop';

export const getCroppedImg = (
  image: HTMLImageElement | null,
  crop: Crop | null,
): string | null => {
  if (!image || !crop?.width || !crop.height) {
    return null;
  }
  const canvas = document.createElement('canvas');

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const ctx = canvas.getContext('2d');
  const pixelRatio = window.devicePixelRatio;

  canvas.width = crop.width * pixelRatio * scaleX;
  canvas.height = crop.height * pixelRatio * scaleY;

  ctx?.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  ctx.imageSmoothingQuality = 'high';

  ctx?.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width * scaleX,
    crop.height * scaleY,
  );

  // As Base64 string
  return canvas.toDataURL('image/jpeg');
};

export const getRoundedImage = (
  img: HTMLImageElement | null,
  avatarLetters?: string,
): string => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (!context) {
    return '';
  }
  const width = img ? img.width : 300;
  const height = img ? img.height : 300;
  const max = Math.max(width, height);
  canvas.width = max;
  canvas.height = max;
  context.font = '102px Arial';
  context.strokeStyle = 'white';
  context.save();
  context.lineWidth = width / 5;
  context.beginPath();
  context.arc(
    width / 2,
    height / 2,
    Math.min(width - width / 5, height - width / 5) / 2,
    0,
    2 * Math.PI,
    true,
  );
  if (!img) {
    context.lineWidth = width / 10;
    context.fillStyle = '#E7EEED';
    context.fill();
    context.fillStyle = '#045445';
    context.fillText(avatarLetters || '', 75, 190);
  }
  context.stroke();
  context.clip();
  img && context.drawImage(img, 0, 0, width, height);
  context.restore();

  return canvas.toDataURL('image/png');
};
