import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  CancelShiftRequest,
  CancelShiftRequestVariables,
} from './__generated__/CancelShiftRequest';

const CANCEL_SHIFT_REQUEST = gql`
  mutation CancelShiftRequest($id: ID!) {
    cancelShiftRequest(id: $id) {
      id
      shiftId
      tenderId
    }
  }
`;

export const useCancelShiftRequestMutation = (
  options?: MutationHookOptions<
    CancelShiftRequest,
    CancelShiftRequestVariables
  >,
) =>
  useMutation<CancelShiftRequest, CancelShiftRequestVariables>(
    CANCEL_SHIFT_REQUEST,
    options,
  );

export default CANCEL_SHIFT_REQUEST;
