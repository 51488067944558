import { gql, QueryHookOptions, useLazyQuery } from '@apollo/client';
import {
  GetOrganizationsPaginated,
  GetOrganizationsPaginatedVariables,
} from './__generated__/GetOrganizationsPaginated';

const GET_ORGANIZATIONS_PAGINATED_QUERY = gql`
  query GetOrganizationsPaginated(
    $paginationOptions: PaginationOptions!
    $queryOptions: OptionalSearchTerm
  ) {
    getOrganizationsPaginated(
      paginationOptions: $paginationOptions
      queryOptions: $queryOptions
    ) {
      items {
        id
        name
      }
    }
  }
`;

export const useGetOrganizationsPaginatedQuery = (
  options?: QueryHookOptions<
    GetOrganizationsPaginated,
    GetOrganizationsPaginatedVariables
  >,
) =>
  useLazyQuery<GetOrganizationsPaginated, GetOrganizationsPaginatedVariables>(
    GET_ORGANIZATIONS_PAGINATED_QUERY,
    options,
  );

export default GET_ORGANIZATIONS_PAGINATED_QUERY;
