import { FC, Fragment, MutableRefObject } from 'react';
import { Dialog, Transition } from '@headlessui/react';

interface Props {
  open?: boolean;
  onClose?: (value: boolean) => void;
  className?: string;
  containerClassName?: string;
  dialogClassName?: string;
  initialFocusRef?: MutableRefObject<HTMLElement | null>;
  wrapperClassName?: string;
  fullHeight?: boolean;
}

const Modal: FC<Props> = ({
  children,
  open = false,
  onClose = () => null,
  className,
  dialogClassName = 'fixed inset-0 z-30 overflow-y-auto',
  containerClassName = 'inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:align-middle',
  wrapperClassName = 'flex items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0',
  fullHeight,
  initialFocusRef,
  ...props
}) => (
  <Transition.Root show={open} as={Fragment}>
    <Dialog
      as="div"
      static
      className={dialogClassName}
      initialFocus={initialFocusRef}
      open={open}
      onClose={onClose}
      {...props}
    >
      <div
        className={`${wrapperClassName} ${
          fullHeight ? 'h-full' : 'min-h-screen'
        }`}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="bg-ink-dark fixed inset-0 bg-opacity-60 transition-opacity" />
        </Transition.Child>

        {/* This element is to trick the browser into centering the modal contents. */}
        <span
          className="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div className={`${containerClassName} ${className}`}>{children}</div>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition.Root>
);

export default Modal;
