import React from 'react';

const NotFound: React.FC = () => (
  <div className="bg-brand-50 relative h-full">
    <div className="absolute inset-0 flex flex-col justify-center">
      <div className="text-center text-8xl text-white">404</div>
      <div className="text-center text-4xl text-white">Nothing here!</div>
    </div>
  </div>
);

export default NotFound;
