import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  BulkMarkChangeTenderPayoutStatusAsSent,
  BulkMarkChangeTenderPayoutStatusAsSentVariables,
} from './__generated__/BulkMarkChangeTenderPayoutStatusAsSent';

const BULK_MARK_CHANGE_TENDER_PAYOUT_STATUS_AS_SENT = gql`
  mutation BulkMarkChangeTenderPayoutStatusAsSent(
    $filters: BulkTenderPayoutFilters!
  ) {
    bulkMarkTenderPayoutAsSent(filters: $filters) {
      id
      status
    }
  }
`;

export const useBulkMarkChangeTenderPayoutStatusAsSent = (
  options?: MutationHookOptions<
    BulkMarkChangeTenderPayoutStatusAsSent,
    BulkMarkChangeTenderPayoutStatusAsSentVariables
  >,
) =>
  useMutation<
    BulkMarkChangeTenderPayoutStatusAsSent,
    BulkMarkChangeTenderPayoutStatusAsSentVariables
  >(BULK_MARK_CHANGE_TENDER_PAYOUT_STATUS_AS_SENT, options);

export default BULK_MARK_CHANGE_TENDER_PAYOUT_STATUS_AS_SENT;
