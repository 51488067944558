import React from 'react';
import DataCell from './DataCell';

const ScoreCell: React.FC<{ score?: number | null }> = ({
  score,
  ...props
}) => (
  <DataCell data-testid="score-cell" {...props}>
    {score ? Math.round(score * 10) / 10 : '-'}
  </DataCell>
);

export default ScoreCell;
