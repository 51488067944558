import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  RemoveShiftFromRoster,
  RemoveShiftFromRosterVariables,
} from './__generated__/RemoveShiftFromRoster';

const REMOVE_SHIFT_FROM_ROSTER = gql`
  mutation RemoveShiftFromRoster($rosterId: ID!, $shiftId: ID!) {
    removeShiftFromRoster(rosterId: $rosterId, shiftId: $shiftId) {
      __typename
      id
    }
  }
`;

export const useRemoveShiftFromRosterMutation = (
  options?: MutationHookOptions<
    RemoveShiftFromRoster,
    RemoveShiftFromRosterVariables
  >,
) =>
  useMutation<RemoveShiftFromRoster, RemoveShiftFromRosterVariables>(
    REMOVE_SHIFT_FROM_ROSTER,
    {
      update(cache, { data }) {
        data?.removeShiftFromRoster.forEach((removedTenderRoster) => {
          const normalizedId = cache.identify({
            id: removedTenderRoster.id,
            __typename: 'RosterTender',
          });
          cache.evict({ id: normalizedId });
          cache.gc();
        });
      },
      ...options,
    },
  );

export default REMOVE_SHIFT_FROM_ROSTER;
