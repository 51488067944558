import React from 'react';
import { format } from 'date-fns-tz';

import Pill from 'src/components/Pill';
import ShareIcon from 'src/components/Icons/Share';
import TableHeaderCell from 'src/components/TableHeaderCell';
import { useGetTenderAssessmentsForAdminQuery } from 'src/graphql/queries/GetTenderAssessments';
import Loading from 'src/components/Loading';
import { PositionApplicationStatus } from 'src/__generated__/globalTypes';
import {
  GetTenderAssessmentsForAdmin_tenderAssessments_items_lastScheduledInterview_assessment as LastAssessment,
  GetTenderAssessmentsForAdmin_tenderAssessments_items as Assessment,
} from 'src/graphql/queries/__generated__/GetTenderAssessmentsForAdmin';
import { RETOOL_TENDERS_APP_URL } from 'src/utils/constants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Cell: React.FC<{ className?: string; as?: React.ElementType<any> }> = ({
  children,
  className,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  as: Component = 'div',
}) => (
  <td
    className={`text-preset-6 text-ink-dark bg-background-surface px-4 ${className}`}
  >
    <Component className="truncate">{children}</Component>
  </td>
);

const getPillColor = (status?: PositionApplicationStatus) => {
  switch (status) {
    case PositionApplicationStatus.APPROVED:
      return 'positive';
    case PositionApplicationStatus.PENDING:
      return 'warning';
    case PositionApplicationStatus.REJECTED:
      return 'destructive';
    case PositionApplicationStatus.WITHDRAWN:
    default:
      return 'info';
  }
};

const getAssessmentPill = (assessment?: LastAssessment) => {
  if (assessment?.processedAt) {
    return <Pill status="positive">PROCESSED</Pill>;
  }
  if (assessment?.completedAt) {
    return <Pill status="warning">PENDING</Pill>;
  }
  return (
    <Pill bgColor="bg-ink-dark" textColor="text-ink-clear">
      NOT FOUND
    </Pill>
  );
};

const TenderAssessmentTable: React.FC<{
  assessments: Assessment[];
  tenderId: string;
}> = ({ assessments, tenderId }) => {
  const displayAssesments = assessments.filter(
    (assesment) =>
      assesment.lastScheduledInterview?.assessment.positionApplications.length,
  );

  return displayAssesments.length ? (
    <table className="mt-4 min-w-full table-fixed">
      <thead className="bg-background-app">
        <tr className="sticky top-0 z-10 h-12">
          <TableHeaderCell className="bg-background-app">
            Application Date
          </TableHeaderCell>
          <TableHeaderCell className="bg-background-app">
            Position
          </TableHeaderCell>
          <TableHeaderCell className="bg-background-app">
            Status
          </TableHeaderCell>
        </tr>
      </thead>
      <tbody className="bg-white">
        {displayAssesments.map((assessment) => {
          const status =
            assessment.lastScheduledInterview?.assessment
              .positionApplications[0]?.status;

          return (
            <tr
              key={assessment.id}
              className="border-support-line h-14 border-b"
            >
              <Cell className="border-support-lines border-b">
                {assessment.lastScheduledInterview
                  ? format(
                      new Date(assessment.lastScheduledInterview.startDateTime),
                      'MM/dd/yyyy',
                    )
                  : 'No date found'}
              </Cell>
              <Cell className="border-support-lines border-b">
                {
                  assessment.lastScheduledInterview?.assessment
                    .positionApplications[0]?.position.name
                }
              </Cell>
              <Cell as="span" className="border-support-lines border-b">
                <Pill status={getPillColor(status)}>{status}</Pill>
              </Cell>
            </tr>
          );
        })}
      </tbody>
    </table>
  ) : (
    <p className="text-preset-6 text-ink-not-as-dark mt-6">
      This tender is missing assessments.{' '}
      <a
        className="text-ink-link"
        href={`${RETOOL_TENDERS_APP_URL}#search=${tenderId}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        See retool
        <ShareIcon className="mx-1 inline w-3 align-text-bottom" />
      </a>{' '}
      to address missing assessments for this tender.
    </p>
  );
};

const AssessmentCard: React.FC<{ tenderId: string }> = ({ tenderId }) => {
  const { data, loading } = useGetTenderAssessmentsForAdminQuery({
    variables: {
      filters: {
        searchTerm: tenderId,
      },
      pagination: {
        page: 1,
        limit: 25,
      },
    },
  });
  const tenderAssessments = data?.tenderAssessments.items;
  const emptyAssessment = {
    lastScheduledInterview: undefined,
    finalNotes: undefined,
  };
  const firstAssessment = tenderAssessments?.length
    ? tenderAssessments[0]
    : emptyAssessment;
  const interview = firstAssessment.lastScheduledInterview
    ? format(
        new Date(firstAssessment.lastScheduledInterview.startDateTime),
        'MM/dd/yyyy hh:mm a',
      )
    : 'TBD';
  const interviewerEmail = firstAssessment.lastScheduledInterview
    ? firstAssessment.lastScheduledInterview.interviewer.email
    : 'TBD';
  return (
    <div className="bg-background-surface mr-4 mb-4 rounded">
      <div className="mb-4 px-4 py-6">
        <div className="mb-4 flex">
          <p className="text-preset-3 text-ink-dark mr-2 font-medium">
            Assessment
          </p>
          {getAssessmentPill(
            firstAssessment.lastScheduledInterview?.assessment,
          )}
        </div>
        <p className="text-preset-6 text-ink-not-as-dark">
          {`Interview: ${interview} • Interviewer: ${interviewerEmail} `}
        </p>
        {(!data || loading) && <Loading />}
        {!!tenderAssessments?.length && (
          <TenderAssessmentTable
            assessments={tenderAssessments}
            tenderId={tenderId}
          />
        )}
        {firstAssessment.finalNotes && (
          <>
            <p className="text-preset-4 text-ink-dark pt-6 pb-4">
              Assessment Notes
            </p>
            <p className="text-preset-6P text-ink-not-as-dark">
              {firstAssessment.finalNotes}
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default AssessmentCard;
