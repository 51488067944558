import React, { useState, useEffect, SyntheticEvent } from 'react';
import { RadioGroup } from '@headlessui/react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { ClientFilter } from 'src/components/ClientsInput/ClientsInput';
import { Clients_getClients_items as Client } from 'src/graphql/queries/__generated__/Clients';
import { useMarkTenderStatusAsFavoriteAsAdminMutation } from 'src/graphql/mutations/MarkTenderStatusAsFavoriteAsAdmin';
import { useMarkTenderStatusAsLessPreferredAsAdminMutation } from 'src/graphql/mutations/MarkTenderStatusAsLessPreferredAsAdmin';
import { useResetTenderStatusAsAdminMutation } from 'src/graphql/mutations/ResetTenderStatusAsAdmin';
import { useMarkTenderStatusAsBlockedAsAdminMutation } from 'src/graphql/mutations/MarkTenderStatusAsBlockedAsAdmin';
import GET_CLIENT_TENDERS from 'src/graphql/queries/GetClientTenders';
import { yupResolver } from '@hookform/resolvers/yup';
import { ClientTenderStatus } from 'src/__generated__/globalTypes';
import Modal from 'src/components/Modal';
import RadioOption from 'src/components/RadioOption';
import Button from 'src/components/Button';

enum AlternativeTenderStatus {
  NONE = 'NONE',
}

export type PreferenceStatus =
  | keyof typeof ClientTenderStatus
  | AlternativeTenderStatus.NONE;

type FormValues = {
  preference: PreferenceStatus;
  client: Client | null;
};

const validationSchema = Yup.object().shape({
  client: Yup.object()
    .shape({
      id: Yup.string().required(),
      email: Yup.string().email().required(),
      firstName: Yup.string().required(),
      lastName: Yup.string().required(),
    })
    .test('client', 'Please select a client', (value) => {
      return Boolean(
        value && (value.id || value.email || value.firstName || value.lastName),
      );
    })
    .required('Must select a client'),
  preference: Yup.string()
    .oneOf<PreferenceStatus>(
      [
        ClientTenderStatus.FAVORITE,
        ClientTenderStatus.LESS_PREFERRED,
        ClientTenderStatus.BLOCKED,
        AlternativeTenderStatus.NONE,
      ],
      'Please select a preference',
    )
    .required('Please select a preference'),
});

const preferences = [
  {
    id: 1,
    value: ClientTenderStatus.FAVORITE,
    label: 'Favorite',
  },
  {
    id: 2,
    value: ClientTenderStatus.LESS_PREFERRED,
    label: 'Less preferred',
  },
  {
    id: 3,
    value: ClientTenderStatus.BLOCKED,
    label: 'Block',
  },
  {
    id: 4,
    value: AlternativeTenderStatus.NONE,
    label: 'None',
  },
];

const ClientPreferenceModal: React.FC<{
  modalOpen: boolean;
  handleCloseModal: (e?: SyntheticEvent) => void;
  tenderId: string;
  page: number;
}> = ({ modalOpen, handleCloseModal, tenderId, page }) => {
  const [client, setClient] = useState<Client | null>(null);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [preference, setPreference] = useState<PreferenceStatus>();
  const [markAsFavorite] = useMarkTenderStatusAsFavoriteAsAdminMutation();
  const [markAsLessPreferred] =
    useMarkTenderStatusAsLessPreferredAsAdminMutation();
  const [markAsReset] = useResetTenderStatusAsAdminMutation();
  const [markAsBlocked] = useMarkTenderStatusAsBlockedAsAdminMutation();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    clearInput();
  }, [modalOpen]);

  const clearInput = () => {
    setShowMessage(false);
    setClient(null);
  };

  const radioOptions = () =>
    preferences?.map(
      ({
        id,
        value,
        label,
      }: {
        id: number;
        value: PreferenceStatus | string;
        label: string;
      }) => <RadioOption key={id} value={value} label={label} />,
    );

  const onSubmit: SubmitHandler<FormValues> = async () => {
    const inputParams = {
      clientId: client?.id as string,
      tenderId,
    };

    const getRefetchQueryOptions = (status: PreferenceStatus) => ({
      query: GET_CLIENT_TENDERS,
      variables: {
        tenderId,
        status,
        pagination: {
          page,
          limit: 5,
        },
      },
    });

    const payload = {
      variables: {
        input: inputParams,
      },
      refetchQueries: [
        getRefetchQueryOptions(ClientTenderStatus.FAVORITE),
        getRefetchQueryOptions(ClientTenderStatus.BLOCKED),
        getRefetchQueryOptions(ClientTenderStatus.LESS_PREFERRED),
      ],
    };

    try {
      switch (preference) {
        case ClientTenderStatus.FAVORITE:
          await markAsFavorite(payload);
          break;
        case ClientTenderStatus.BLOCKED:
          await markAsBlocked(payload);
          break;
        case ClientTenderStatus.LESS_PREFERRED:
          await markAsLessPreferred(payload);
          break;
        default:
          await markAsReset(payload);
          break;
      }
    } catch (err) {
      console.error(err);
    } finally {
      handleCloseModal();
    }
  };

  return (
    <Modal
      open={modalOpen}
      onClose={() => handleCloseModal()}
      className="w-3/12"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="text-preset-5 my-4 mx-6">
          <div className="mx-2 pb-4">
            <p className="text-preset-3 text-ink-dark pb-2 font-medium">
              Client Preference
            </p>
            <p className="pt-2">Select client&apos;s preference type.</p>
          </div>
          <hr className="border-ink-dark-200 -mx-6 h-1 border-t border-solid" />
          <p className="text-preset-4 pt-2">Client</p>
          <p className="py-2 text-gray-400">
            Search by Client ID, Client Email or Client Org Name
          </p>
          <Controller
            name="client"
            control={control}
            render={({ onChange }) => (
              <ClientFilter
                onChange={(newClient) => {
                  onChange(newClient);
                  setClient(newClient);
                }}
                onReset={() => setClient(null)}
                placeholder={
                  client
                    ? `${client.firstName} ${client.lastName}`
                    : 'Search by ID, email or organization name'
                }
              />
            )}
          />
          {errors.client?.message && (
            <p className="text-preset-6 text-red-700">
              {errors.client.message}
            </p>
          )}
          <Controller
            name="preference"
            control={control}
            render={({ onChange, value }) => (
              <RadioGroup
                value={value}
                onChange={(newPreference) => {
                  onChange(newPreference);
                  setShowMessage(true);
                  setPreference(newPreference);
                }}
              >
                <div className="mt-4 flex flex-col">{radioOptions()}</div>
              </RadioGroup>
            )}
          />
          {errors.preference?.message && (
            <p className="text-preset-6 pb-4 text-red-700">
              {errors.preference.message}
            </p>
          )}
          {showMessage && (
            <p className="text-preset-6 pb-4 text-red-700">
              This action will overwrite previous preference.
            </p>
          )}
          <hr className="border-ink-dark-200 -mx-6 h-1 border-t border-solid" />
          <div className="flex justify-end pt-4">
            <Button
              onClick={handleCloseModal}
              className="border-ink-dark rounded-md border py-2 px-4"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="bg-primary text-ink-clear ml-4 rounded-md py-2 px-4"
            >
              Submit
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default ClientPreferenceModal;
