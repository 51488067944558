import React, { FC, useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import { Moment } from 'moment';
import ChevronDown from 'src/components/Icons/ChevronDown';
import ChevronLeft from 'src/images/chevron-left.svg';
import ChevronRight from 'src/images/chevron-right.svg';
import './DateInput.css';

interface Props {
  date: Moment | null;
  onDateChange: (newDate: Moment | null) => void;
}

const DateInput: FC<Props> = ({ date, onDateChange }) => {
  const [focused, setFocused] = useState(false);
  return (
    <SingleDatePicker
      id="single_date_picker"
      date={date}
      onDateChange={(newDate) => onDateChange(newDate)}
      focused={focused}
      onFocusChange={(ev) => setFocused(ev.focused)}
      numberOfMonths={1}
      customInputIcon={
        <ChevronDown
          className={`h-3 w-3 ${focused ? 'text-primary' : 'text-ink-dark'}`}
        />
      }
      inputIconPosition="after"
      horizontalMargin={10}
      orientation="horizontal"
      anchorDirection="left"
      navPrev={
        <img
          src={ChevronLeft}
          alt="navigate-prev-month"
          className="absolute top-7 left-9"
        />
      }
      navNext={
        <img
          src={ChevronRight}
          alt="navigate-next-month"
          className="absolute top-7 right-9"
        />
      }
      hideKeyboardShortcutsPanel
      isOutsideRange={() => false}
    />
  );
};

export default DateInput;
