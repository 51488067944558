import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  CreateBonusPayout,
  CreateBonusPayoutVariables,
} from './__generated__/CreateBonusPayout';

const CREATE_BONUS_PAYOUT = gql`
  mutation CreateBonusPayout($input: CreateTenderPayoutInput!) {
    createTenderPayout(input: $input) {
      id
      description
      createdAt
      amount
      chargeToClient
      tender {
        id
      }
      staffing {
        id
        shift {
          id
        }
      }
    }
  }
`;

export const useCreateBonusPayoutMutation = (
  options?: MutationHookOptions<CreateBonusPayout, CreateBonusPayoutVariables>,
) =>
  useMutation<CreateBonusPayout, CreateBonusPayoutVariables>(
    CREATE_BONUS_PAYOUT,
    options,
  );

export default CREATE_BONUS_PAYOUT;
