import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  MarkTenderStatusAsLessPreferredAsAdmin,
  MarkTenderStatusAsLessPreferredAsAdminVariables,
} from './__generated__/MarkTenderStatusAsLessPreferredAsAdmin';

export const MARK_TENDER_STATUS_AS_LESS_PREFERRED_AS_ADMIN = gql`
  mutation MarkTenderStatusAsLessPreferredAsAdmin(
    $input: ChangeTenderStatusAsAdminInput!
  ) {
    markTenderStatusAsLessPreferredAsAdmin(input: $input) {
      tender {
        id
      }
    }
  }
`;

export const useMarkTenderStatusAsLessPreferredAsAdminMutation = (
  options?: MutationHookOptions<
    MarkTenderStatusAsLessPreferredAsAdmin,
    MarkTenderStatusAsLessPreferredAsAdminVariables
  >,
) =>
  useMutation<
    MarkTenderStatusAsLessPreferredAsAdmin,
    MarkTenderStatusAsLessPreferredAsAdminVariables
  >(MARK_TENDER_STATUS_AS_LESS_PREFERRED_AS_ADMIN, options);

export default MARK_TENDER_STATUS_AS_LESS_PREFERRED_AS_ADMIN;
