import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  AdminUpdateTender,
  AdminUpdateTenderVariables,
} from './__generated__/AdminUpdateTender';

const UPDATE_TENDER = gql`
  mutation AdminUpdateTender($input: TenderUpdateInput!) {
    updateTender(input: $input) {
      __typename
      id
      email
      firstName
      lastName
      preferredName
      avatarURL
      phoneNumber
    }
  }
`;

export const useUpdateTenderMutation = (
  options?: MutationHookOptions<AdminUpdateTender, AdminUpdateTenderVariables>,
) =>
  useMutation<AdminUpdateTender, AdminUpdateTenderVariables>(
    UPDATE_TENDER,
    options,
  );

export default UPDATE_TENDER;
