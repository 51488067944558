import StatDown from '../Icons/StatDown';
import StatUp from '../Icons/StatUp';
import Loading from '../Loading';

type DeltaType = 'positive' | 'negative' | 'neutral' | 'none' | undefined;

export interface Stat {
  delta?: { type: DeltaType; value?: string };
  label?: string;
  onClick?: () => void;
  value: string;
}

export interface StatsCardProps {
  group?: string;
  isLoading?: boolean;
  order: number;
  stats: Stat[];
  subtitle?: string;
  title: string;
}

const StatsDeltaIcon: React.FC<{
  type: DeltaType;
}> = ({ type }) => {
  switch (type) {
    case 'positive':
      return <StatDown className="w-3" />;
    case 'negative':
      return <StatUp className="w-3" />;
    case 'neutral':
      return <span className="w-3">=</span>;
    default:
      return null;
  }
};

const getDeltaPillColor = (type: DeltaType) => {
  switch (type) {
    case 'positive':
      return 'bg-status-positive';
    case 'negative':
      return 'bg-status-destructive';
    case 'neutral':
      return 'bg-ink-not-as-dark';
    default:
      return '';
  }
};

const StatsCard: React.FC<StatsCardProps> = ({
  order,
  isLoading = false,
  title,
  subtitle,
  stats,
}) => {
  const [mainStat, ...otherStats] = stats;
  const hasDelta = mainStat.delta && mainStat.delta?.type !== 'none';
  const hasClickBehavior = mainStat.onClick;

  const clickeableClasses = hasClickBehavior
    ? 'hover:outline-primary/30 hover:outline hover:outline-[3px] cursor-pointer'
    : '';

  return (
    <section
      className={`bg-brand-5 border-support-line flex min-h-[112px] min-w-[158px] flex-col justify-between rounded border p-3 ${clickeableClasses}`}
      data-cy="stats-card"
      data-testid="stats-card"
      onClick={mainStat.onClick}
    >
      <header data-testid="stats-card-header" data-cy="stats-card-header">
        <h3
          className="text-preset-6P text-ink-dark"
          data-testid="stats-card-title"
          data-cy="stats-card-title"
        >
          {title}
        </h3>
        <h4
          className="text-preset-7 text-ink-not-as-dark"
          data-testid="stats-card-subtitle"
          data-cy="stats-card-subtitle"
        >
          {subtitle}
        </h4>
      </header>

      <div
        className="flex justify-between gap-2"
        data-testid="stats-card-content"
        data-cy="stats-card-content"
      >
        {isLoading && (
          <Loading
            data-cy="stats-card-loading"
            data-testid="stats-card-loading"
          />
        )}

        {!isLoading && (
          <>
            <div
              className="flex items-end gap-1"
              data-cy="stats-card-stat-main"
              data-testid="stats-card-stat-main"
            >
              <span className="text-preset-2 text-primary flex gap-1 font-bold leading-none">
                {mainStat.value}
              </span>

              {mainStat.label && (
                <span className="text-preset-7 text-ink-not-as-dark">
                  {mainStat.label}
                </span>
              )}
            </div>

            {hasDelta && (
              <div
                className="flex items-end"
                data-cy={`stats-card-stat-delta-${mainStat.delta?.type}`}
                data-testid={`stats-card-stat-delta-${mainStat.delta?.type}`}
              >
                <span
                  className={`text-preset-7 flex items-center gap-1 rounded-full py-[3px] px-2 font-semibold leading-none text-white ${getDeltaPillColor(
                    mainStat.delta?.type,
                  )}`}
                >
                  <StatsDeltaIcon type={mainStat.delta?.type} />
                  <span>{mainStat.delta?.value}</span>
                </span>
              </div>
            )}

            {otherStats.length > 0 && (
              <>
                <span className="border-support-line-darker w-[1px] border-l border-solid" />

                <div
                  className="flex shrink-0 flex-col gap-1"
                  data-cy="stats-card-stat-group"
                  data-testid="stats-card-stat-group"
                >
                  {otherStats.map((stat, index) => (
                    <div
                      className="flex items-end gap-1"
                      data-cy="stats-card-stat-group-item"
                      data-testid="stats-card-stat-group-item"
                      key={index}
                    >
                      <span className="text-preset-5 text-primary font-bold">
                        {stat.value}
                      </span>

                      <span className="text-preset-7 text-ink-not-as-dark">
                        {stat.label}
                      </span>
                    </div>
                  ))}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </section>
  );
};

export default StatsCard;
