import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  SetJobVisibilityForClient,
  SetJobVisibilityForClientVariables,
} from './__generated__/SetJobVisibilityForClient';

const SET_JOB_VISIBILITY_FOR_CLIENT = gql`
  mutation SetJobVisibilityForClient($jobId: ID!, $isVisible: Boolean!) {
    setJobVisibilityForClient(jobId: $jobId, isVisible: $isVisible) {
      __typename
      id
      hiddenFromClient
    }
  }
`;

export const useSetJobVisibilityForClientMutation = (
  options?: MutationHookOptions<
    SetJobVisibilityForClient,
    SetJobVisibilityForClientVariables
  >,
) =>
  useMutation<SetJobVisibilityForClient, SetJobVisibilityForClientVariables>(
    SET_JOB_VISIBILITY_FOR_CLIENT,
    options,
  );

export default SET_JOB_VISIBILITY_FOR_CLIENT;
