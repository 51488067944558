import React, { ChangeEvent, useCallback, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import parsePhoneNumber from 'libphonenumber-js';
import ReactCrop, { Crop } from 'react-image-crop';
import * as Yup from 'yup';

import Modal from 'src/components/Modal';
import Check from 'src/components/Icons/Check';
import Copy from 'src/components/Icons/Copy';
import MailIcon from 'src/components/Icons/Mail';
import Pencil from 'src/components/Icons/Pencil';
import PhoneIcon from 'src/components/Icons/Phone';
import QRIcon from 'src/components/Icons/QR';
import Identification from 'src/components/Icons/Identification';
import { AdminTenderDetails_tender as Tender } from 'src/graphql/queries/__generated__/AdminTenderDetails';
import CameraIcon from 'src/components/Icons/Camera';
import { formInputClass } from 'src/components/Forms';
import { useUpdateTenderMutation } from 'src/graphql/mutations/UpdateTender';
import Loading from 'src/components/Loading';
import { useUpdateTenderPhotoIdMutation } from 'src/graphql/mutations/UpdateTenderFrontPhotoId';
import { getCroppedImg } from 'src/utils/images';

type Props = Pick<
  Tender,
  | 'avatarURL'
  | 'firstName'
  | 'lastName'
  | 'preferredName'
  | 'email'
  | 'phoneNumber'
  | 'id'
  | 'frontPhotoIdURL'
>;

type ImageFile = {
  preview: string;
  raw?: File;
  base64?: string | null;
};
type FormValues = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  preferredName?: string;
  avatarURL?: ImageFile;
  frontPhotoId?: ImageFile;
};

const formSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  phoneNumber: Yup.string().required('Phone number is required'),
  email: Yup.string()
    .email('Must be a valid email')
    .required('Email is required'),
});

type EditTenderProps = Props & {
  onClose: () => void;
};
const gradient = 'bg-gradient-to-t from-black to-transparent opacity-75';

const TenderPicture: React.FC<
  Pick<Props, 'avatarURL' | 'firstName' | 'lastName'> & {
    editable?: boolean;
    className?: string;
  }
> = ({ avatarURL, firstName, lastName, editable, className }) => {
  return (
    <div className={`text-center ${className}`}>
      <div className="relative m-auto h-24 w-24 overflow-hidden rounded-full">
        {avatarURL ? (
          <>
            {editable && (
              <div className={`absolute top-0 left-0 h-24 w-24 ${gradient}`} />
            )}
            <img
              className="inline-block h-full w-full"
              src={avatarURL}
              alt="avatar"
            />
          </>
        ) : (
          <div
            className={`text-preset-2 text-ink-primary bg-primary-light flex h-full w-full flex-col justify-center text-center ${
              editable && gradient
            }`}
          >
            {firstName?.[0] || ''}
            {lastName?.[0] || ''}
          </div>
        )}
        {editable && (
          <div className="text-ink-clear absolute top-12 left-2 cursor-pointer text-center">
            <CameraIcon className="ml-8 w-4" />
            <p className="text-preset-6 ml-4">Replace</p>
          </div>
        )}
      </div>
    </div>
  );
};

const EditTenderPersonalInfo: React.FC<EditTenderProps> = (props) => {
  const {
    avatarURL,
    frontPhotoIdURL,
    phoneNumber,
    lastName,
    firstName,
    email,
    id,
    preferredName,
    onClose,
  } = props;
  const { register, handleSubmit, errors } = useForm<FormValues>({
    mode: 'onBlur',
    resolver: yupResolver(formSchema),
  });
  const [frontPhoto, setFrontPhoto] = useState<ImageFile>({
    preview: '',
    raw: undefined,
  });
  const [avatarUrlPhoto, setAvatarUrlPhoto] = useState<ImageFile>({
    preview: '',
    raw: undefined,
  });
  const [crop, setCrop] = useState<Crop | undefined>();
  const [editPhotoDisabled, setEditPhotoDisabled] = useState(true);
  const [croppedImage, setCroppedImage] = useState<string>();
  const [completedCrop, setCompletedCrop] = useState<Crop | null>(null);
  const imgRef = useRef<HTMLImageElement | null>(null);
  const onLoad = useCallback((img) => {
    imgRef.current = img;
    setCompletedCrop(null);
  }, []);
  const [updateTenderMutation, { loading }] = useUpdateTenderMutation();
  const [updateTenderPhotoIdMutation, { loading: loadingFrontPhoto }] =
    useUpdateTenderPhotoIdMutation();

  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    setPicture: (image: ImageFile) => void,
  ) => {
    if (event.target.files?.length) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPicture({
          preview: URL.createObjectURL(event.target.files?.[0] as File),
          raw: event.target.files?.[0],
          base64: reader.result?.toString(),
        });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleForm = async (form: FormValues) => {
    await updateTenderMutation({
      variables: {
        input: {
          id,
          email: form.email,
          firstName: form.firstName,
          lastName: form.lastName,
          preferredName: form.preferredName,
          avatarURL: avatarUrlPhoto.base64 || avatarURL,
          phoneNumber: parsePhoneNumber(form.phoneNumber)?.number,
        },
      },
    });
    if (croppedImage || frontPhoto.base64) {
      let newPhoto: string | undefined | null = croppedImage;
      if (!newPhoto) {
        newPhoto = frontPhoto.base64;
      }
      if (!newPhoto) {
        return;
      }
      await updateTenderPhotoIdMutation({
        variables: {
          tenderId: id,
          input: {
            front: newPhoto,
          },
        },
      });
    }
    onClose();
  };
  const imageCSS = { width: '100%', opacity: '1' };

  return (
    <div className="my-4 mx-6">
      <p className="text-preset-3 text-ink-dark font-medium">
        Edit Personal Information
      </p>
      <form onSubmit={handleSubmit(handleForm)}>
        <input
          ref={register}
          name="avatarURL"
          type="file"
          id="avatarURL"
          className="hidden"
          onChange={(event) => {
            handleChange(event, setAvatarUrlPhoto);
          }}
        />
        <label htmlFor="avatarURL">
          <TenderPicture
            avatarURL={avatarUrlPhoto.preview || avatarURL}
            firstName={firstName}
            lastName={lastName}
            className="mt-10 mb-6"
            editable
          />
        </label>
        <div className="flex">
          <div className="mr-2">
            <p className="text-ink-dark text-preset-6 mt-6 mb-4 font-medium">
              First Name
            </p>
            <input
              ref={register}
              name="firstName"
              placeholder="Enter first name"
              defaultValue={firstName || ''}
              className={formInputClass}
              data-cy="tender-first-name-input"
            />
            {errors.firstName && (
              <span className="text-preset-5 ml-4 h-12 text-red-700">
                {errors.firstName.message}
              </span>
            )}
          </div>
          <div>
            <p className="text-ink-dark text-preset-6 mt-6 mb-4 font-medium">
              Last Name
            </p>
            <input
              ref={register}
              name="lastName"
              placeholder="Enter last name"
              defaultValue={lastName || ''}
              className={formInputClass}
              data-cy="tender-last-name-input"
            />
            {errors.lastName && (
              <span className="text-preset-5 ml-4 h-12 text-red-700">
                {errors.lastName.message}
              </span>
            )}
          </div>
        </div>
        <p className="text-ink-dark text-preset-6 mt-6 mb-4 font-medium">
          Preferred Name (optional)
        </p>
        <input
          ref={register}
          name="preferredName"
          placeholder="Enter preferred name"
          defaultValue={preferredName || ''}
          className={formInputClass}
          data-cy="tender-preferred-name-input"
        />

        <div className="flex">
          <div className="mr-2">
            <p className="text-ink-dark text-preset-6 mt-6 mb-4 font-medium">
              Phone number
            </p>
            <input
              ref={register}
              name="phoneNumber"
              placeholder="Enter a phone number"
              defaultValue={
                parsePhoneNumber(phoneNumber)?.formatInternational() || ''
              }
              className={formInputClass}
              data-cy="tender-phone-number-input"
            />
            {errors.phoneNumber && (
              <span className="text-preset-5 ml-4 h-12 text-red-700">
                {errors.phoneNumber.message}
              </span>
            )}
          </div>
          <div>
            <p className="text-ink-dark text-preset-6 mt-6 mb-4 font-medium">
              Email Address
            </p>
            <input
              ref={register}
              name="email"
              placeholder="Enter an email address"
              defaultValue={email || ''}
              className={formInputClass}
              data-cy="tender-email-input"
            />
            {errors.email && (
              <span className="text-preset-5 ml-4 h-12 text-red-700">
                {errors.email.message}
              </span>
            )}
          </div>
        </div>
        <p className="text-ink-dark text-preset-6 mt-6 mb-4 font-medium">
          ID Photo
        </p>
        <input
          ref={register}
          name="frontPhotoId"
          type="file"
          id="frontPhoto"
          className="hidden"
          onChange={(event) => handleChange(event, setFrontPhoto)}
        />
        {frontPhoto.preview || frontPhotoIdURL ? (
          <>
            <div className="mx-auto mb-5 w-56">
              <ReactCrop
                crop={crop || {}}
                disabled={editPhotoDisabled}
                src={(
                  croppedImage ||
                  frontPhoto.preview ||
                  frontPhotoIdURL ||
                  ''
                ).replace(/^https:\/\//i, 'http://')}
                // the replace is needed because neither Chrome nor Safari will
                // be able to resolve the https image. You can find more info here
                // https://zyst.io/how-to-fix-aws-s3-chrome-and-safari-cors-on-images;
                crossorigin="anonymous"
                onImageLoaded={onLoad}
                imageStyle={imageCSS}
                style={imageCSS}
                onChange={setCrop}
                onComplete={setCompletedCrop}
              />
            </div>
            <div className="mb-10 flex justify-center text-center">
              {editPhotoDisabled ? (
                <>
                  <label
                    htmlFor="frontPhoto"
                    className="text-ink-clear bottom-4 left-48 cursor-pointer text-center"
                  >
                    <div className="text-preset-7 text-ink-dark mr-10 cursor-pointer">
                      <CameraIcon className="text-ink-not-as-dark mx-auto w-4" />
                      <p>Replace</p>
                    </div>
                  </label>
                  <div
                    onClick={() => {
                      setEditPhotoDisabled(false);
                    }}
                    className="text-preset-7 text-ink-dark cursor-pointer"
                  >
                    <Pencil
                      fill
                      className="text-ink-not-as-dark mx-auto h-3.5 w-3.5"
                    />
                    <p>Edit Photo</p>
                  </div>
                </>
              ) : (
                <div
                  onClick={() => {
                    const newImage = getCroppedImg(
                      imgRef.current,
                      completedCrop,
                    );
                    if (newImage) {
                      setCroppedImage(newImage);
                    }
                    setCrop(undefined);
                    setEditPhotoDisabled(true);
                  }}
                  className="text-preset-7 text-ink-dark cursor-pointer"
                >
                  <Check className="text-ink-not-as-dark mx-auto h-3.5 w-3.5" />
                  <p>Done</p>
                </div>
              )}
            </div>
          </>
        ) : (
          <label htmlFor="frontPhoto" className="cursor-pointer">
            <div className="bg-primary-light border-primary mx-auto mb-5 h-36 w-56 rounded border border-dashed">
              <CameraIcon className="text-primary mx-auto mt-12 w-12" />
            </div>
          </label>
        )}
        <div className="border-ink-dark-200 -mx-6 border-t border-solid" />
        <div className="text-ink-dark flex justify-end pt-4">
          <button
            className="rounded-md py-2 px-4"
            onClick={(event) => {
              event.preventDefault();
              onClose();
            }}
          >
            Cancel
          </button>
          <button
            className="bg-primary text-ink-clear ml-4 rounded-md py-2 px-4"
            type="submit"
            disabled={loading || loadingFrontPhoto}
            data-cy="save-personal-information-button"
          >
            {loading || loadingFrontPhoto ? <Loading /> : 'Save Information'}
          </button>
        </div>
      </form>
    </div>
  );
};

const TenderCard: React.FC<Props> = (props) => {
  const {
    avatarURL,
    frontPhotoIdURL,
    phoneNumber,
    lastName,
    firstName,
    email,
    id,
    preferredName,
  } = props;
  const [open, setOpen] = useState(false);
  const [identificationOpen, setIdentificationOpen] = useState(false);
  return (
    <div className="bg-background-surface rounded">
      <Modal open={open} onClose={() => setOpen(false)}>
        <EditTenderPersonalInfo {...props} onClose={() => setOpen(false)} />
      </Modal>
      <div className="mb-4 px-4 pt-6 pb-2">
        <div className="flex justify-between">
          <p className="text-preset-3 text-ink-dark mb-4 font-medium">
            Personal Information
          </p>
          <div
            onClick={() => setOpen(true)}
            className="mt-2 flex cursor-pointer items-start"
          >
            <Pencil className="text-ink-primary h-4" />
            <p
              className="text-preset-6 text-ink-primary"
              data-cy="edit-tender-personal-info-button"
            >
              Edit
            </p>
          </div>
        </div>
        <TenderPicture
          avatarURL={avatarURL}
          firstName={firstName}
          lastName={lastName}
        />
        <p className="text-preset-5 text-ink-dark mt-4 mb-3 text-center">{`${firstName} ${lastName} ${
          preferredName ? `(${preferredName})` : ''
        }`}</p>
        <div className="border-support-lines border-b">
          <p className="text-preset-6 text-ink-not-as-dark my-4 flex">
            <PhoneIcon className="mr-3" />
            {phoneNumber &&
              parsePhoneNumber(phoneNumber)?.formatInternational()}
          </p>
        </div>
        <div className="border-support-lines border-b">
          <p className="text-preset-6 text-ink-not-as-dark my-4 flex items-center">
            <MailIcon className="mr-3" />
            <span className="truncate">{email}</span>
          </p>
        </div>
        <div
          className={`text-ink-not-as-dark flex items-center justify-between ${
            frontPhotoIdURL ? 'border-support-lines border-b' : ''
          }`}
        >
          <p className="text-preset-6 my-4 flex">
            <QRIcon className="mr-3" />
            {id}
          </p>
          <span
            className="cursor-pointer"
            onClick={async () => {
              await navigator.clipboard.writeText(id);
            }}
          >
            <Copy />
          </span>
        </div>
        {frontPhotoIdURL && (
          <>
            <Modal
              open={identificationOpen}
              onClose={() => setIdentificationOpen(false)}
            >
              <button onClick={() => setIdentificationOpen(false)}>
                <img src={frontPhotoIdURL} alt="tender-id" className="h-80" />
              </button>
            </Modal>
            <p
              onClick={() => setIdentificationOpen(true)}
              className="text-preset-6 text-ink-not-as-dark my-4 flex cursor-pointer"
            >
              <Identification className="mr-3 w-4" />
              <img src={frontPhotoIdURL} alt="tender-id" className="h-8" />
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default TenderCard;
