import React, { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import ApplicantStatusFilter from 'src/components/Filters/ApplicantStatusFilter';
import Drawer from 'src/components/Drawer';
import LocationInput from 'src/components/LocationInput';
import JobFilter from 'src/components/Filters/JobFilter';
import ShiftFilter from 'src/components/Filters/ShiftFilter';
import RegionFilter from 'src/components/Filters/RegionFilter';
import ClientsFilter from 'src/components/Filters/ClientsFilter';
import DefaultPositionsInput from 'src/components/Filters/DefaultPositionsFilter';
import CustomPositionsInput from 'src/components/Filters/CustomPositionsFilter';
import ShiftStatusFilter from 'src/components/Filters/ShiftStatusFilter';
import OrganizationsFilter from 'src/components/Filters/OrganizationsFilter';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import { StaffingSelectionContext } from 'src/contexts/StaffingsSelectionContext';
import { Can } from 'src/contexts/AbilityContext';

interface Props {
  onClose: () => void;
  open: boolean;
  onChange: (filter: Record<string, string | string[] | null>) => void;
}
type MarkerType = {
  lat: number;
  lng: number;
};
const DEFAULT_DISTANCE = '25';

const AdvancedShiftFilters: FC<Props> = ({ open, onClose, onChange }) => {
  const selectedStaffingsContext = useContext(StaffingSelectionContext);
  const { selectionCount } = selectedStaffingsContext || {};
  const prevLocation = useLocation().search;
  const [dialogOpen, setDialogOpen] = useState(false);
  const queryParams = useMemo(
    () => new URLSearchParams(prevLocation),
    [prevLocation],
  );
  const getDefaultMaker = useCallback(() => {
    return queryParams.get('lat') && queryParams.get('lng')
      ? {
          lat: parseFloat(queryParams.get('lat') || ''),
          lng: parseFloat(queryParams.get('lng') || ''),
        }
      : undefined;
  }, [queryParams]);
  const [marker, setMarker] = useState<MarkerType | undefined>(
    getDefaultMaker(),
  );
  const [shouldFilterMap, setShouldFilterMap] = useState(
    !!queryParams.get('lat') &&
      !!queryParams.get('lng') &&
      !!queryParams.get('distance'),
  );
  const [radius, setRadius] = useState(
    parseInt(queryParams.get('distance') || DEFAULT_DISTANCE, 10),
  );
  const paramIds = queryParams.get('jobIds');
  const paramShiftId = queryParams.get('shiftIds');
  const paramPositions = queryParams.get('defPosIds');
  const paramCustomPositions = queryParams.get('cusPosIds');
  const paramClients = queryParams.get('clientIds');
  const paramOrganizations = queryParams.get('organizationIds');
  const paramRegions = queryParams.get('regionIds');
  const paramStatus = queryParams.get('status');
  const paramApplicantStatuses = queryParams.get('applicantStatus');
  const [selectedJobsIds, setSelectedJobIds] = useState<string[]>(
    paramIds ? paramIds.split(',') : [],
  );
  const [selectedShiftId, setSelectedShiftId] = useState<string | null>(
    paramShiftId,
  );
  const [selectedRegionIds, setSelectedRegionIds] = useState<string[]>(
    paramRegions ? paramRegions.split(',') : [],
  );
  const [selectedPositionsIds, setSelectedPositionsIds] = useState<string[]>(
    paramPositions ? paramPositions.split(',') : [],
  );
  const [selectedCustomPositionsIds, setSelectedCustomPositionsIds] = useState<
    string[]
  >(paramCustomPositions ? paramCustomPositions.split(',') : []);
  const [selectedClientsIds, setSelectedClientsIds] = useState<string[]>(
    paramClients ? paramClients.split(',') : [],
  );
  const [selectedOrganizationsIds, setSelectedOrganizationsIds] = useState<
    string[]
  >(paramOrganizations ? paramOrganizations.split(',') : []);
  const [selectedStatus, setSelectedStatus] = useState<string | undefined>(
    paramStatus || undefined,
  );
  const [selectedApplicantStatuses, setSelectedApplicantStatuses] = useState<
    string[]
  >(paramApplicantStatuses ? paramApplicantStatuses.split(',') : []);

  const handleMarkerChange = (newMarker: MarkerType) => {
    setShouldFilterMap(true);
    setMarker(newMarker);
  };

  const handleApplyFilters = () => {
    onChange({
      lat: shouldFilterMap ? marker?.lat.toString() || null : null,
      lng: shouldFilterMap ? marker?.lng.toString() || null : null,
      distance: shouldFilterMap ? radius.toString() : null,
      jobIds: selectedJobsIds,
      shiftIds: selectedShiftId,
      defPosIds: selectedPositionsIds,
      cusPosIds: selectedCustomPositionsIds,
      clientIds: selectedClientsIds,
      organizationIds: selectedOrganizationsIds,
      status: selectedStatus || 'all',
      regionIds: selectedRegionIds,
      applicantStatus: selectedApplicantStatuses,
    });
  };

  const clearFilters = () => {
    setShouldFilterMap(false);
    setMarker(undefined);
    setRadius(parseInt(DEFAULT_DISTANCE, 10));
    setSelectedJobIds([]);
    setSelectedShiftId(null);
    setSelectedClientsIds([]);
    setSelectedOrganizationsIds([]);
    setSelectedPositionsIds([]);
    setSelectedCustomPositionsIds([]);
    setSelectedStatus(undefined);
    setSelectedRegionIds([]);
    setSelectedApplicantStatuses([]);
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title="Advanced Filters"
      data-cy="advanced-filters-title"
    >
      <div className="flex h-full max-w-md flex-col justify-between overflow-hidden">
        <div className="content flex-grow overflow-y-auto">
          <div className="jobs border-support-line border-b py-6 pb-8">
            <Can I="read" a="PositionFilter">
              <DefaultPositionsInput
                className="px-4"
                selectedPositionsIds={selectedPositionsIds}
                onChange={setSelectedPositionsIds}
              />
              <div className="border-support-line mb-3 border-b pt-2" />
              <CustomPositionsInput
                className="px-4"
                selectedPositionsIds={selectedCustomPositionsIds}
                onChange={setSelectedCustomPositionsIds}
              />
              <div className="border-support-line mb-3 border-b pt-2" />
            </Can>
            <ClientsFilter
              className="px-4"
              selectedClientsIds={selectedClientsIds}
              onChange={setSelectedClientsIds}
            />
            <div className="border-support-line mb-3 border-b pt-2" />
            <Can I="read" a="ShiftStatusFilter">
              <ShiftStatusFilter
                className="px-4"
                staffingStatus={selectedStatus}
                onChange={setSelectedStatus}
              />
              <div className="border-support-line mb-3 border-b pt-2" />
            </Can>
            <OrganizationsFilter
              className="px-4"
              selectedOrganizationsIds={selectedOrganizationsIds}
              onChange={setSelectedOrganizationsIds}
            />
            <div className="border-support-line mb-3 border-b pt-2" />
            <Can I="read" a="ApplicantStatusFilter">
              <ApplicantStatusFilter
                className="px-4"
                selectedStatuses={selectedApplicantStatuses}
                onChange={setSelectedApplicantStatuses}
              />
              <div className="border-support-line mb-3 border-b pt-2" />
            </Can>
            <Can I="read" a="JobFilter">
              <JobFilter
                className="px-4"
                selectedJobsIds={selectedJobsIds}
                onChange={setSelectedJobIds}
              />
              <div className="border-support-line mb-3 border-b pt-2" />
            </Can>
            <RegionFilter
              className="px-4"
              selectedRegionsIds={selectedRegionIds}
              onChange={setSelectedRegionIds}
            />
            <div className="border-support-line mb-3 border-b pt-2" />
            <Can I="read" a="LocationFilter">
              <div className="px-4">
                <LocationInput
                  marker={marker}
                  radius={radius}
                  onMarkerChange={handleMarkerChange}
                  onClear={() => {
                    setShouldFilterMap(false);
                    setMarker(undefined);
                  }}
                  displayCircle={shouldFilterMap}
                  displayMarker={shouldFilterMap}
                />{' '}
                {marker && (
                  <>
                    {' '}
                    <p className="mt-4 mb-2"> Distance</p>{' '}
                    <div className="flex">
                      {' '}
                      <input
                        title="Distance range"
                        className="bg-brand-50 slider slider-thumb mt-3 h-1.5 w-full rounded-full"
                        type="range"
                        min="1"
                        max="50"
                        value={shouldFilterMap ? radius : 0}
                        onChange={(event) =>
                          setRadius(parseInt(event.target.value, 10))
                        }
                      />
                      <p className="text-ink-not-as-dark w-36 pl-4">
                        {shouldFilterMap ? radius : 0}
                        mile(s)
                      </p>
                    </div>
                  </>
                )}
              </div>
            </Can>
            <ShiftFilter
              className="px-4"
              selectedShiftId={selectedShiftId}
              onChange={setSelectedShiftId}
            />
          </div>
        </div>
        <div className="footer space-x-4 px-4 py-4 pb-8 text-right">
          <button
            onClick={clearFilters}
            className="border-ink-dark text-preset-6 text-ink-dark bg-background-surface inline-flex items-center rounded-md border px-2.5 py-2 font-normal"
          >
            Clear All
          </button>
          <button
            onClick={() => {
              if (selectionCount && selectionCount > 0) {
                setDialogOpen(true);
              } else {
                handleApplyFilters();
                onClose();
              }
            }}
            className="text-preset-5 bg-primary text-ink-clear hover:bg-primary-active h-9 rounded px-5 font-medium"
            data-cy="apply-filters-button"
          >
            Apply
          </button>
        </div>
      </div>
      {dialogOpen && (
        <ConfirmationDialog
          title="Unselect Tenders?"
          description="Changing filters will remove the selection of tenders"
          submitClassName="text-status-destructive"
          open={dialogOpen}
          submitText="Unselect"
          onSubmit={() => {
            handleApplyFilters();
            onClose();
            setDialogOpen(false);
          }}
          onClose={() => setDialogOpen(false)}
        />
      )}
    </Drawer>
  );
};

export default AdvancedShiftFilters;
