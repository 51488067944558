import * as React from 'react';
import { SVGProps } from 'react';

const PreferenceMatchNone = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 28 28"
    width="1em"
    height="1em"
    {...props}
  >
    <path stroke="#045445" strokeLinecap="round" d="M10 14.5h9" />
    <circle cx={14} cy={14} r={13.4} stroke="#045445" strokeWidth={1.2} />
  </svg>
);

export default PreferenceMatchNone;
