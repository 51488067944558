import React, { Fragment, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { useHistory, useParams } from 'react-router-dom';

import { useTenderDetailsQuery } from 'src/graphql/queries/GetTenderDetails';
import ViewContainer from 'src/components/ViewContainer';
import Loading from 'src/components/Loading';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import DotsHorizontal from 'src/components/Icons/DotsHorizontal';
import BanIcon from 'src/components/Icons/Ban';
import HumanIcon from 'src/components/Icons/Human';
import Pill from 'src/components/Pill';
import { AdminTenderDetails_tender as Tender } from 'src/graphql/queries/__generated__/AdminTenderDetails';
import { useBlockTenderAsAdminMutation } from 'src/graphql/mutations/BlockTender';
import { useUnblockTenderAsAdminMutation } from 'src/graphql/mutations/UnblockTender';
import { getTenderStatus } from 'src/utils/tender';
import ImpersonateTenderCard from 'src/components/ImpersonateTenderCard';
import {
  AddressCard,
  AssessmentCard,
  ChangeLogCard,
  ClientPreferenceCard,
  DevicesCard,
  NotesCard,
  NotificationsCard,
  PositionsCard,
  StaffingCard,
  TenderCard,
  TenderTagsCard,
  WorkerClassificationCard,
} from './Components';

const TenderTitle: React.FC<{ tender: Tender }> = ({ tender }) => {
  return (
    <span>
      {` / ${tender.firstName} ${tender.lastName}
        ${tender.preferredName ? `(${tender.preferredName})` : ''}
      `}
      {tender.hasCompletedOnboardingW2 && (
        <Pill className="mx-2" status="none">
          W-2
        </Pill>
      )}
      {tender.hasCompletedOnboarding1099 && (
        <Pill className="mr-2" status="none">
          1099
        </Pill>
      )}
      <Pill status={tender.blockedAt ? 'destructive' : 'positive'}>
        {getTenderStatus(tender)}
      </Pill>
    </span>
  );
};

const NumberCard: React.FC<{ title: string; value?: number; color?: string }> =
  ({ title, value, color = 'text-ink-dark' }) => {
    return (
      <div className="bg-background-surface mr-4 flex h-28 w-1/5 flex-col justify-between rounded p-4">
        <p className="text-ink-not-as-dark text-preset-7 font-semibold uppercase">
          {title}
        </p>
        <p className={`${color} text-preset-1 font-bold`}>
          {value !== undefined ? value : '-'}
        </p>
      </div>
    );
  };

const TenderDetailView: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [blockOpen, setBlockOpen] = useState(false);
  const [unblockOpen, setUnblockOpen] = useState(false);
  const { data, loading, refetch } = useTenderDetailsQuery({
    variables: { id },
  });
  const [blockTenderMutation] = useBlockTenderAsAdminMutation({
    variables: { id },
  });
  const [unblockTenderMutation] = useUnblockTenderAsAdminMutation({
    variables: { id },
  });
  if (!data?.tender || loading) {
    return (
      <div className="h-5 w-full">
        <Loading />
      </div>
    );
  }
  const isTenderBlocked = !!data.tender.blockedAt;
  const tenderInitials = `${
    data.tender.firstName ? data.tender.firstName[0] : ''
  }${data.tender.lastName ? data.tender.lastName[0] : ''}`;
  return (
    <div className="bg-background-app pt-10" data-cy="view-tender-detail">
      <ViewContainer>
        <ConfirmationDialog
          open={unblockOpen}
          onSubmit={async () => {
            await unblockTenderMutation();
            setUnblockOpen(false);
          }}
          onClose={() => setUnblockOpen(false)}
          description="This Tender will be able to see and apply to any published job."
          title="Unblock tender?"
          submitText="Unblock Tender"
        />
        <ConfirmationDialog
          open={blockOpen}
          onSubmit={async () => {
            await blockTenderMutation();
            setBlockOpen(false);
          }}
          submitClassName="text-status-destructive"
          onClose={() => setBlockOpen(false)}
          description="This Tender won’t be able to see or apply to any published job. You can unblock this Tender any time."
          title="Block tender?"
          submitText="Block Tender"
        />
        <div className="-my-2 mb-6 overflow-x-visible sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="border-support-line border sm:rounded-lg">
              <div className="bg-background-surface flex items-center px-4 py-5">
                <h3 className="text-ink-dark flex-1 text-2xl font-light leading-7">
                  <span
                    className="text-ink-link cursor-pointer"
                    onClick={() => {
                      history.push('/tenders');
                    }}
                  >
                    Tenders
                  </span>
                  <TenderTitle tender={data.tender} />
                </h3>
                <Menu as="div" className="relative h-6">
                  <Menu.Button className="text-ink-not-as-dark">
                    <DotsHorizontal className="h-6 w-6" />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="bg-background-surface shadow-floating-menu text-ink-dark absolute right-0 mt-2 rounded py-2">
                      <Menu.Item as={Fragment}>
                        {isTenderBlocked ? (
                          <button
                            className="flex items-center whitespace-nowrap px-4 py-2"
                            onClick={() => setUnblockOpen(true)}
                          >
                            <p className="text-ink-dark text-preset-6">
                              <HumanIcon className="mr-2 inline-block h-4 w-4" />
                              Unblock Tender
                            </p>
                          </button>
                        ) : (
                          <>
                            <button
                              className="flex items-center whitespace-nowrap px-4 py-2"
                              onClick={() => setBlockOpen(true)}
                            >
                              <p className="text-status-destructive text-preset-6">
                                <BanIcon className="mr-2 inline-block h-4 w-4" />
                                Block Tender
                              </p>
                            </button>
                          </>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
        </div>
        <div className="flex">
          <div className="w-8/12">
            <div className="mb-4 flex justify-between">
              <NumberCard
                title="jobs worked"
                value={data.tender.shiftsWorked}
              />
              <NumberCard
                title="call outs"
                value={data.tender.shiftsCalledOutCount}
              />
              <NumberCard
                title="faults"
                value={data.tender.shiftsFaultedCount}
              />
              <NumberCard
                title="denial streak"
                value={data.tender.applicationsAfterLastShiftWorked}
              />
              <NumberCard
                title="unpaid shifts"
                value={data.tender.unpaidShiftsCount}
                color={
                  data.tender.unpaidShiftsCount
                    ? 'text-status-destructive'
                    : undefined
                }
              />
              <NumberCard
                title="tender score"
                value={
                  data.tender.score !== null
                    ? Math.round(data.tender.score * 10) / 10
                    : undefined
                }
              />
            </div>
            <ClientPreferenceCard tenderId={data.tender.id} />
            <StaffingCard
              tenderId={data.tender.id}
              tenderInitials={tenderInitials}
              avatarUrl={data.tender.avatarURL}
            />
            <AssessmentCard tenderId={data.tender.id} />
            <NotesCard tenderId={data.tender.id} />
            <NotificationsCard tenderId={data.tender.id} />
            <ChangeLogCard tenderId={data.tender.id} />
          </div>
          <div className="mb-4 w-4/12">
            <TenderCard {...data?.tender} />
            <AddressCard
              refetch={refetch}
              address={data.tender.address}
              tenderId={data.tender.id}
            />
            <DevicesCard
              tenderId={data.tender.id}
              device={data.tender.lastDevice}
            />
            <PositionsCard
              tenderId={data.tender.id}
              positions={data.tender.positions}
            />
            <WorkerClassificationCard
              onboarding1099={data.tender.hasCompletedOnboarding1099}
              onboardingW2={data.tender.hasCompletedOnboardingW2}
            />
            <TenderTagsCard
              tenderId={data.tender.id}
              title="Skill Tags"
              skillTags
            />
            <TenderTagsCard tenderId={data.tender.id} title="All Tags" />
            <ImpersonateTenderCard
              tenderId={data.tender.id}
              fullName={`${data.tender.firstName} ${data.tender.lastName}`}
              isImpersonator={data.tender.isImpersonator}
            />
          </div>
        </div>
      </ViewContainer>
    </div>
  );
};

export default TenderDetailView;
