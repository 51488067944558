import { gql } from '@apollo/client';

const SHIFT_FOR_STAFFING_MUTATION = gql`
  fragment ShiftForStaffingMutation on Shift {
    id
    quantity
    staffedSlots
    applicantsQuantity
    removedQuantity
  }
`;

export default SHIFT_FOR_STAFFING_MUTATION;
