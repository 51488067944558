import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import {
  GetTenderChangesForAdmin,
  GetTenderChangesForAdminVariables,
} from './__generated__/GetTenderChangesForAdmin';

const GET_TENDER_CHANGES = gql`
  query GetTenderChangesForAdmin($tenderId: ID!, $first: Int, $after: String) {
    tenderChangesPaginated(tenderId: $tenderId, first: $first, after: $after) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          authorRole
          authorEmail
          operation
          createdAt
          detailsChanged
          changeSets {
            id
            entityName
            before
            after
          }
        }
      }
    }
  }
`;

export const useGetTenderChangesForAdminQuery = (
  options?: QueryHookOptions<
    GetTenderChangesForAdmin,
    GetTenderChangesForAdminVariables
  >,
) =>
  useQuery<GetTenderChangesForAdmin, GetTenderChangesForAdminVariables>(
    GET_TENDER_CHANGES,
    options,
  );

export default GET_TENDER_CHANGES;
