import React, { FC, useEffect, useRef, useState } from 'react';
import { format, utcToZonedTime } from 'date-fns-tz';
import { OrderByDirectionEnum, TipType } from 'src/__generated__/globalTypes';
import { formatMoney } from 'src/utils/formatMoney';
import TableHeaderCell from 'src/components/TableHeaderCell';
import TenderCell from 'src/components/StaffingList/TenderCell';
import Pagination from 'src/components/Pagination';
import {
  PaidStaffedShifts,
  PaidStaffedShifts_paidStaffedShifts_items,
} from 'src/graphql/queries/__generated__/PaidStaffedShifts';
import { getStaffedShiftDescription } from 'src/views/PaidStaffings/PaidStaffings';
import styles from './PaidStaffings.module.css';
import EditPaidStaffingModal from './EditPaidStaffingModal';

export type PaidStaffingsTableProps = {
  paidStaffingsData?: PaidStaffedShifts;
  pageNumber: number;
  orderByField: string;
  orderByDirection: OrderByDirectionEnum;
  onPageChange: (pageNumber: number) => void;
  onSortChange: (field: string, direction: OrderByDirectionEnum) => void;
  onStaffingChanged: () => void;
};

type ScrollbarsOffset = {
  x: number;
  y: number;
};

const Cell: FC<{ className?: string }> = ({ children, className }) => (
  <td
    className={`text-preset-6 text-ink-dark bg-background-surface px-4 ${className}`}
  >
    <p className="truncate">{children}</p>
  </td>
);

const PaidStaffingsTable: React.FC<PaidStaffingsTableProps> = ({
  paidStaffingsData,
  pageNumber,
  orderByField,
  orderByDirection,
  onPageChange,
  onSortChange,
  onStaffingChanged,
}) => {
  const [showStickyBorder, setShowStickyBorder] = useState<boolean>(false);
  const [scrollbarsOffset, setScrollbarsOffset] = useState<ScrollbarsOffset>({
    x: 0,
    y: 0,
  });
  const containerRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [staffedShiftModalData, setStaffedShiftModalData] =
    useState<PaidStaffedShifts_paidStaffedShifts_items | null>(null);

  useEffect(() => {
    const element = containerRef.current;
    if (!element) {
      return;
    }
    const listener = (e: Event) => {
      const scrolled = (e.currentTarget as Element).scrollLeft > 0;
      if (scrolled && !showStickyBorder) {
        setShowStickyBorder(true);
      } else if (!scrolled && showStickyBorder) {
        setShowStickyBorder(false);
      }
    };
    element.addEventListener('scroll', listener);
    return () => element.removeEventListener('scroll', listener);
  }, [containerRef, showStickyBorder, setShowStickyBorder]);

  useEffect(() => {
    const div = containerRef.current;
    if (!div) {
      return;
    }
    const adjustScrollbarsOffset = () => {
      const x = div.offsetWidth - div.clientWidth;
      const y = div.offsetHeight - div.clientHeight;
      if (scrollbarsOffset.x !== x || scrollbarsOffset.y !== y) {
        setScrollbarsOffset({ x, y });
      }
    };
    adjustScrollbarsOffset();
    window.addEventListener('resize', adjustScrollbarsOffset);
    return () => window.removeEventListener('resize', adjustScrollbarsOffset);
  }, [containerRef, scrollbarsOffset, setScrollbarsOffset]);

  const pageInfo = paidStaffingsData?.paidStaffedShifts.meta;
  let showingStart, showingEnd, totalItems;
  if (pageInfo) {
    showingStart = (pageInfo.currentPage - 1) * pageInfo.itemsPerPage + 1;
    showingEnd = showingStart + pageInfo.itemCount - 1;
    totalItems = pageInfo.totalItems;
  }

  const sort = (field: string) => () =>
    onSortChange(
      field,
      field !== orderByField || orderByDirection === OrderByDirectionEnum.DESC
        ? OrderByDirectionEnum.ASC
        : OrderByDirectionEnum.DESC,
    );

  return (
    <>
      <div
        className="bg-background-app flex-grow overflow-auto pb-14"
        ref={containerRef}
      >
        <table className="min-w-full table-fixed">
          <thead className="bg-background-app">
            <tr className="sticky top-0 h-12">
              <TableHeaderCell
                className="bg-background-app sticky left-0 w-72"
                sortDirection={
                  orderByField === 'tenderName' ? orderByDirection : undefined
                }
                onClick={sort('tenderName')}
              >
                Tender
              </TableHeaderCell>
              <TableHeaderCell
                className="bg-background-app sticky left-56 w-32"
                sortDirection={
                  orderByField === 'staffingStatus'
                    ? orderByDirection
                    : undefined
                }
                onClick={sort('staffingStatus')}
              >
                <p className="inline w-32">
                  Staffing
                  <br />
                  status
                </p>
              </TableHeaderCell>
              <TableHeaderCell
                className="bg-background-app sticky left-72 w-32"
                sortDirection={
                  orderByField === 'positionName' ? orderByDirection : undefined
                }
                onClick={sort('positionName')}
              >
                Position
              </TableHeaderCell>
              <TableHeaderCell
                className={`bg-background-app sticky left-96 w-32 ${
                  showStickyBorder && styles.rightbordershadow
                }`}
              >
                Amount
              </TableHeaderCell>
              <TableHeaderCell className="bg-background-app w-72">
                Updated At
              </TableHeaderCell>
              <TableHeaderCell className="bg-background-app w-72">
                Staffing
              </TableHeaderCell>
              <TableHeaderCell
                className="bg-background-app w-72"
                sortDirection={
                  orderByField === 'jobId' ? orderByDirection : undefined
                }
                onClick={sort('jobId')}
              >
                Job
              </TableHeaderCell>
              <TableHeaderCell className="bg-background-app w-72">
                Client
              </TableHeaderCell>
              <TableHeaderCell className="bg-background-app w-72">
                Staffing ID
              </TableHeaderCell>
              <TableHeaderCell
                className="bg-background-app w-72"
                sortDirection={
                  orderByField === 'shiftId' ? orderByDirection : undefined
                }
                onClick={sort('shiftId')}
              >
                Shift id
              </TableHeaderCell>
              <TableHeaderCell
                className="bg-background-app w-24"
                sortDirection={
                  orderByField === 'shiftStartDateTime'
                    ? orderByDirection
                    : undefined
                }
                onClick={sort('shiftStartDateTime')}
              >
                <p className="inline w-24">Shift Start</p>
              </TableHeaderCell>
              <TableHeaderCell
                className="bg-background-app w-24"
                sortDirection={
                  orderByField === 'shiftEndDateTime'
                    ? orderByDirection
                    : undefined
                }
                onClick={sort('shiftEndDateTime')}
              >
                Shift End
              </TableHeaderCell>
              <TableHeaderCell className="bg-background-app w-24">
                <p className="inline w-24">
                  Unpaid
                  <br />
                  Break
                </p>
              </TableHeaderCell>
              <TableHeaderCell className="bg-background-app w-24">
                Tip
              </TableHeaderCell>
              <TableHeaderCell
                className="bg-background-app w-24"
                sortDirection={
                  orderByField === 'tenderHourlyPayout'
                    ? orderByDirection
                    : undefined
                }
                onClick={sort('tenderHourlyPayout')}
              >
                Hourly Rate
              </TableHeaderCell>
              <TableHeaderCell className="bg-background-app w-24">
                <p className="inline w-24">
                  Actual
                  <br />
                  Start
                </p>
              </TableHeaderCell>
              <TableHeaderCell className="bg-background-app w-24">
                <p className="inline w-24">
                  Actual
                  <br />
                  End
                </p>
              </TableHeaderCell>
              <TableHeaderCell className="bg-background-app w-24">
                <p className="inline w-24">
                  Actual
                  <br />
                  Hours
                </p>
              </TableHeaderCell>
              <TableHeaderCell className="bg-background-app w-24">
                <p className="inline w-24">
                  Actual
                  <br />
                  Unpaid Break
                </p>
              </TableHeaderCell>
              <TableHeaderCell className="bg-background-app w-24">
                <p className="inline w-24">
                  Tend
                  <br />
                  Adjustment
                </p>
              </TableHeaderCell>
              <TableHeaderCell className="bg-background-app w-24">
                <p className="inline w-24">
                  Tender
                  <br />
                  Adjustment
                </p>
              </TableHeaderCell>
              <TableHeaderCell className="bg-background-app w-24">
                <p className="inline w-24">
                  Staffed Shift
                  <br />
                  Description
                </p>
              </TableHeaderCell>
              <TableHeaderCell className="bg-background-app w-24">
                &nbsp;
              </TableHeaderCell>
            </tr>
          </thead>
          <tbody className="bg-white">
            {paidStaffingsData &&
              paidStaffingsData.paidStaffedShifts.items.map((staffedShift) => {
                const timeZone =
                  staffedShift.staffing?.shift?.job.venue?.address.timezone ||
                  'America/Los_Angeles';
                const dateFormat = 'MMM do, yyyy HH:mmaaa z';
                const staffedShiftDescriptionTruncated =
                  getStaffedShiftDescription(staffedShift?.description, true);
                const staffedShiftDescription = getStaffedShiftDescription(
                  staffedShift?.description,
                );
                return (
                  <tr
                    key={staffedShift.id}
                    className="border-support-line h-14 border-b"
                  >
                    <TenderCell
                      className="bg-background-surface sticky left-0 w-72 text-left"
                      lastName={staffedShift.staffing?.tender?.lastName || ''}
                      firstName={staffedShift.staffing?.tender?.firstName || ''}
                      tenderId={staffedShift.staffing?.tender?.id || ''}
                      avatarURL={staffedShift.staffing?.tender?.avatarURL}
                      detailText={staffedShift.staffing?.tender?.id}
                      truncateText
                    />
                    <Cell className="border-support-lines sticky left-56 w-32 border-b">
                      {staffedShift.staffing.status}
                    </Cell>
                    <Cell className="border-support-lines sticky left-72 w-32 border-b">
                      {staffedShift.staffing.shift?.position.name}
                    </Cell>
                    <Cell
                      className={`border-support-lines sticky left-96 w-32 border-b text-right ${
                        showStickyBorder && styles.rightbordershadow
                      }`}
                    >
                      {formatMoney(staffedShift.actualTenderPayout)}
                    </Cell>
                    <Cell>
                      {format(new Date(staffedShift.updatedAt), dateFormat)}
                    </Cell>
                    <Cell>{staffedShift.staffing?.id}</Cell>
                    <Cell>
                      <span className="block text-left">
                        {staffedShift.staffing?.shift?.job.name || '-'}
                      </span>
                      <span className="text-ink-not-as-dark block text-left">
                        {staffedShift.staffing?.shift?.job.id}
                      </span>
                    </Cell>
                    <Cell>
                      <span className="block text-left">
                        {staffedShift.staffing?.shift?.job.client?.email || '-'}
                      </span>
                      <span className="text-ink-not-as-dark block text-left">
                        {staffedShift.staffing?.shift?.job.client?.id}
                      </span>
                    </Cell>
                    <Cell>{staffedShift.staffing?.id}</Cell>
                    <Cell className="text-left">
                      {staffedShift.staffing?.shift?.id || '-'}
                    </Cell>
                    <Cell>
                      {staffedShift.staffing.shift?.startDateTime
                        ? format(
                            utcToZonedTime(
                              new Date(
                                staffedShift.staffing.shift?.startDateTime,
                              ),
                              timeZone,
                            ),
                            dateFormat,
                            { timeZone },
                          )
                        : '-'}
                    </Cell>
                    <Cell>
                      {staffedShift.staffing.shift?.endDateTime
                        ? format(
                            utcToZonedTime(
                              new Date(
                                staffedShift.staffing.shift?.endDateTime,
                              ),
                              timeZone,
                            ),
                            dateFormat,
                            { timeZone },
                          )
                        : '-'}
                    </Cell>
                    <Cell className="text-left">
                      {staffedShift.staffing?.shift?.unpaidBreakMinutes
                        ? `${staffedShift.staffing?.shift?.unpaidBreakMinutes} minutes`
                        : '-'}
                    </Cell>
                    <Cell className="text-left">
                      {staffedShift.staffing?.shift?.tip?.type ===
                      TipType.INCLUDE_TIP
                        ? formatMoney(
                            staffedShift.staffing?.shift?.tip.amount || 0,
                          )
                        : 'No tip'}
                    </Cell>
                    <Cell className="text-left">
                      {staffedShift.staffing.shift?.tenderHourlyPayout
                        ? formatMoney(
                            staffedShift.staffing.shift?.tenderHourlyPayout ||
                              0,
                          )
                        : '-'}
                    </Cell>
                    <Cell>
                      {staffedShift.actualStartDateTime
                        ? format(
                            utcToZonedTime(
                              new Date(staffedShift.actualStartDateTime),
                              timeZone,
                            ),
                            dateFormat,
                            { timeZone },
                          )
                        : '-'}
                    </Cell>
                    <Cell>
                      {staffedShift.actualEndDateTime
                        ? format(
                            utcToZonedTime(
                              new Date(staffedShift.actualEndDateTime),
                              timeZone,
                            ),
                            dateFormat,
                            { timeZone },
                          )
                        : '-'}
                    </Cell>
                    <Cell className="text-left">
                      {staffedShift.actualWorkedHours
                        ? `${staffedShift.actualWorkedHours} hour${
                            staffedShift.actualWorkedHours > 1 ? 's' : ''
                          }`
                        : '-'}
                    </Cell>
                    <Cell className="text-left">
                      {staffedShift.actualUnpaidBreakMinutes
                        ? `${staffedShift.actualUnpaidBreakMinutes} min`
                        : '-'}
                    </Cell>
                    <Cell className="text-left">
                      {staffedShift.tendAdjustment
                        ? formatMoney(staffedShift.tendAdjustment)
                        : '-'}
                    </Cell>
                    <Cell className="text-left">
                      {staffedShift.tenderAdjustment
                        ? formatMoney(staffedShift.tenderAdjustment)
                        : '-'}
                    </Cell>
                    <Cell className="text-left">
                      {staffedShiftDescription ? (
                        <span title={staffedShiftDescription}>
                          {staffedShiftDescriptionTruncated}
                        </span>
                      ) : (
                        '-'
                      )}
                    </Cell>
                    <Cell className="text-left">
                      <button
                        onClick={() => {
                          setStaffedShiftModalData(staffedShift);
                        }}
                        className="text-preset-6 bg-primary text-ink-clear hover:bg-primary-active h-9 rounded px-2.5 font-medium"
                      >
                        EDIT
                      </button>
                      <a
                        href={`/tender-payments?staffingIds=${staffedShift.staffing?.id}`}
                        className="text-preset-6 text-ink-secondary ml-4 h-9 font-medium"
                      >
                        SEE ALL PAYMENTS
                      </a>
                    </Cell>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <div
          className="border-support-line bg-background-surface fixed left-0 flex items-center justify-between border-t p-4"
          style={{ right: scrollbarsOffset.x, bottom: scrollbarsOffset.y }}
        >
          <p className="text-ink-dark text-preset-6">
            Showing {showingStart} to {showingEnd} of {totalItems} results
          </p>
          <Pagination
            currentPage={pageNumber}
            pageCount={
              paidStaffingsData?.paidStaffedShifts.meta.totalPages || 0
            }
            onPageChange={onPageChange}
          />
        </div>
      </div>
      <EditPaidStaffingModal
        staffedShiftModalData={staffedShiftModalData}
        setStaffedShiftModalData={setStaffedShiftModalData}
        onStaffingChanged={onStaffingChanged}
      />
    </>
  );
};

export default PaidStaffingsTable;
