import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  BlockTenderAsAdmin,
  BlockTenderAsAdminVariables,
} from './__generated__/BlockTenderAsAdmin';

const BLOCK_TENDER = gql`
  mutation BlockTenderAsAdmin($id: ID!) {
    blockTender(id: $id) {
      __typename
      id
      blockedAt
    }
  }
`;

export const useBlockTenderAsAdminMutation = (
  options?: MutationHookOptions<
    BlockTenderAsAdmin,
    BlockTenderAsAdminVariables
  >,
) =>
  useMutation<BlockTenderAsAdmin, BlockTenderAsAdminVariables>(
    BLOCK_TENDER,
    options,
  );

export default BLOCK_TENDER;
